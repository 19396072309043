import React, { useState, useEffect, useContext } from "react";

import { Grid } from "@mui/material";

import { CardGraph } from "../Card/CardGraph";
import { theme } from "../../utils/theme";

const options = {
  legend: { position: "left", alignment: "center" },
  pieSliceText: "percent",
  tooltip: { trigger: "selection" },
  pieStartAngle: 90,
  chartArea: { width: "80%", height: "80%" },
  slices: {
    2: { offset: 0.1 },
  },
};

const modalOption = {
  titleTextStyle: {
    fontSize: 20,
  },
};

export const TypeOfOrdersGraph = ({
  data,
  isLoading,
  total,
  client,
  filters,
}) => {
  return (
    <Grid item xs={4}>
      <CardGraph
        title={"Tipo De Ventas"}
        type={"PieChart"}
        data={data}
        options={{ ...options, title: `Total: ${total}` }}
        modalOption={modalOption}
        isLoading={isLoading}
        client={client}
        filters={filters}
      />
    </Grid>
  );
};
