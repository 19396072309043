import React, { useContext, useState, useEffect } from "react";
import { BusinessContext } from "../../../context/BusinessContext";
import { FilterContext } from "../../../context/FilterContext";
import { useMediaQuery, Popover } from "@mui/material";
import { ModalFilter2 } from "../../../components/Modal/ModalFilter2";
import { Search } from "../../../components/Search";
import { DownloadButtonExcel } from "../../../components/Excel/DownloadButtonExcel";
import { SkeletonTable } from "../../../components/Skeleton/SkeletonTable";
import { ToggleView } from "../../../components/ToggleView";
import {
  BusinessHeadersAdmin,
  BusinessExcelAdmin,
} from "../../../components/headers";
import { BusinessTable } from "../../../components/Tables/BusinessTable";
import { ListView } from "../../../components/ListView";

export function Business() {
  /* IF TRUE TABLE VIEW IS ACTIVE, OTHERWISE CARD VIEW IS ACTIVE */
  const [view, setView] = useState(true);
  const phone = useMediaQuery("(max-width:480px)"); // FOR RESPONSIVE DESIGN
  const tablet = useMediaQuery("(max-width:750px)"); // FOR RESPONSIVE DESIGN
  const [request, setRequest] = useState(false);

  //------------------------------------------------
  //DATA STATES AND FUNCTIONS
  //------------------------------------------------
  const {
    page,
    data,
    setPage,
    total,
    isLoading,
    isLoadingExcel,
    excelData,
    count,
    getBusinessData,
    getBusinessExcelData,
  } = useContext(BusinessContext);

  //------------------------------------------------
  //FILTER STATES AND FUNCTIONS
  //------------------------------------------------
  const { search, setSearch, resetFilter, invisible, getClientsByAdmin } =
    useContext(FilterContext);

  //------------------------------------------------
  //EFFECTS
  //------------------------------------------------

  useEffect(() => {
    setPage(0);
    setSearch("");
    resetFilter();
    setRequest(true);
    getClientsByAdmin();
  }, []);

  //------------------------------------------------
  //DATA REQUEST
  //------------------------------------------------

  const requestData = () => {
    getBusinessData(true);
  };

  useEffect(() => {
    requestData();
  }, [page]);

  //------------------------------------------------
  // MODAL HANDLERS
  //------------------------------------------------
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleCloseModal = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="w-100 px-3 mt-2">
        {tablet ? (
          <div className="mt-2 w-100 p-1 r-15">
            <div className="mb-2">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                requestData={requestData}
                setPage={setPage}
              />
            </div>

            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center flex-wrap">
                <DownloadButtonExcel
                  text={""}
                  disabled={data.length > 0 ? false : true}
                  getData={() => getBusinessExcelData(true)}
                  headers={BusinessHeadersAdmin}
                  data={excelData}
                  sheetName={`Negocios`}
                  multiple={true}
                  isLoading={isLoadingExcel}
                />
              </div>

              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        ) : (
          <div className="mt-2 d-flex justify-content-between p-1 r-15">
            <div className="d-flex align-items-center">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                requestData={requestData}
                setPage={setPage}
              />
              <div className="d-flex align-items-center ml-1">
                <DownloadButtonExcel
                  text={"Descargar"}
                  disabled={data.length > 0 ? false : true}
                  getData={() => getBusinessExcelData(true)}
                  headers={BusinessExcelAdmin}
                  data={excelData}
                  sheetName={`Negocios`}
                  multiple={true}
                  isLoading={isLoadingExcel}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        )}
        {view ? (
          // Vista de tabla
          isLoading ? (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <SkeletonTable num={3} headers={BusinessHeadersAdmin} />
            </div>
          ) : (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <BusinessTable
                data={data}
                search={search}
                setPage={setPage}
                page={page}
                total={total}
                headers={BusinessHeadersAdmin}
                requestData={requestData}
              />
            </div>
          )
        ) : (
          <ListView
            data={data}
            search={search}
            setPage={setPage}
            page={page}
            total={total}
            count={count}
            headers={BusinessHeadersAdmin}
            requestData={requestData}
            isLoading={isLoading}
            request={request}
            setRequest={setRequest}
          />
        )}
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseModal}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <ModalFilter2
          handleCloseModal={handleCloseModal}
          fields={["regimeType", "businessStatus", "adminClient"]}
          requestData={requestData}
          view={view}
          setPage={setPage}
        />
      </Popover>
    </>
  );
}
