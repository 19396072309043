import React, { useState, useEffect } from "react";

import { Grid, useMediaQuery, Popover } from "@mui/material";

import { Search } from "../../components/Search";
import { ToggleView } from "../../components/ToggleView";

import { AddButton } from "../../components/AddButton";

import { DownloadButtonExcel } from "../../components/Excel/DownloadButtonExcel";
import { usersHeaders } from "../../components/headers";

import { getDayjsFormatted } from "../../helpers/getDateCleaned";
import { ModalFilters } from "../../components/Modal/ModalFilters";

import { SkeletonTableUsers } from "../../components/Skeleton/SkeletonTableUsers";
import { TableUsers } from "../../components/Tables/TableUsers";
import { ListViewUsers } from "../../components/ListViewUsers";
import { SkeletonCardEntry } from "../../components/Skeleton/SkeletonCardEntry";
import { useMemo } from "react";
import { ClientAPI } from "../../api/Client";

export const Users = () => {
  const phone = useMediaQuery("(max-width:750px)");
  const [view, setView] = useState(true);

  //DATA STATES
  //------------------------------------------------

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(); /*Total de paginas enviado desde api*/
  const [page, setPage] = useState(0); /*Estado p/paginacion*/
  const [count, setCount] = useState(); /*Paginacion listas */
  const [search, setSearch] = useState("");
  const [ready, setReady] = useState(false);

  //------------------------------------------------

  //ERRORES
  //------------------------------------------------

  const [errors, setErrors] = useState({
    error: false,
    errorNullStart: false,
    errorNullEnd: false,
  });

  //------------------------------------------------

  //MODAL STATES
  //------------------------------------------------

  const [invisible, setInvisible] = useState(true);
  const [countFilter, setCountFilter] = useState([false, false]);

  const [openFilter, setOpenFilter] = useState(false);

  //------------------------------------------------

  //LOADERS STATES
  //------------------------------------------------

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  //------------------------------------------------

  //FILTERS STATES
  //------------------------------------------------

  const roles = [
    { rol: "Todos", id: 0 },
    { rol: "Inventario", id: 4 },
    { rol: "Ventas", id: 5 },
  ];

  const [rol, setRol] = useState("Todos");

  const today = new Date();
  const [startFilterDate, setStartFilterDate] = useState(null);
  const [endFilterDate, setEndFilterDate] = useState(null);

  const [prevData, setPrevData] = useState({
    rol: "Todos",
    startFilterDate: null,
    endFilterDate: null,
  });

  //------------------------------------------------

  //EFFECTS
  //------------------------------------------------

  useEffect(() => {
    setPage(0);
    setSearch("");
    setReady(true);
  }, []);

  useEffect(() => {
    if (ready) {
      RequestData();
    }
  }, [ready]);
  //------------------------------------------------

  //EXCEL STATES AND DATA
  //------------------------------------------------

  const [dataExcel, setDataExcel] = useState([]);

  //------------------------------------------------

  //DATA REQUEST
  //------------------------------------------------

  const RequestData = () => {
    // GetCategories();
    GetUsersList();
  };

  //apis
  const clientAPI = useMemo(() => new ClientAPI(), []);

  const GetExcelUserList = async () => {
    setIsLoadingExcel(true);
    let data = new FormData();
    data.append("page", page + 1);
    data.append("excel", true);

    if (startFilterDate != null) {
      data.append("startDate", getDayjsFormatted(startFilterDate));
    }
    if (endFilterDate != null) {
      data.append("endDate", getDayjsFormatted(endFilterDate));
    }
    if (search != "") {
      data.append("search", search);
    }
    if (rol != "Todos") {
      const rolToSend = roles.find((el) => el.rol == rol);
      data.append("rol", rolToSend.id);
    }

    const response = await clientAPI.usersExcelListEP(data);
    if (response.status == 200) {
      const aux = response.data.excel.map((el) => ({
        apellidos: el.apellidos,
        email: el.email,
        employee_position: el.employee_position,
        estatus: el.estatus,
        fecha_registro: el.fecha_registro,
        nombre: el.nombre,
        rol: el.rol == 4 ? "Inventario" : "Ventas",
        telefono: el.telefono,
      }));

      setDataExcel(aux);
      setIsLoadingExcel(false);
    } else {
      console.log(response);
    }
  };

  const GetUsersList = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("page", page + 1);

    if (startFilterDate != null) {
      data.append("startDate", getDayjsFormatted(startFilterDate));
    }
    if (endFilterDate != null) {
      data.append("endDate", getDayjsFormatted(endFilterDate));
    }
    if (search != "") {
      data.append("search", search);
    }
    if (rol != "Todos") {
      const rolToSend = roles.find((el) => el.rol == rol);
      data.append("rol", rolToSend.id);
    }

    const response = await clientAPI.usersListEP(data);
    if (response.status == 200) {
      setData(response.data.usuarios);
      setTotal(response.data.total);
      setCount(Math.ceil(response.data.total / 10));
      setIsLoading(false);

      if (
        rol != "Todos" ||
        (startFilterDate != null && endFilterDate != null)
      ) {
        setInvisible(false);
      } else {
        setInvisible(true);
      }
    } else {
      console.log(response);
    }
  };

  //------------------------------------------------

  //HANDLERS
  //------------------------------------------------

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleCloseFilterModal = () => setAnchorEl(null);

  const handleCloseModal = () => {
    setAnchorEl(null);
    settingFilters(
      prevData.rol,
      prevData.startFilterDate,
      prevData.endFilterDate
    );
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  const handleChangeRol = (e) => {
    setRol(e.target.value);
  };

  const handleFilter = () => {
    if (startFilterDate != null || endFilterDate != null) {
      if (startFilterDate != null && endFilterDate == null) {
        setErrors({ errorNullEnd: true });
      } else if (endFilterDate != null && startFilterDate == null) {
        setErrors({ errorNullStart: true });
      } else if (endFilterDate < startFilterDate) {
        setErrors({ error: true });
      } else {
        handleCloseFilterModal();
        setErrors({
          error: false,
          errorNullStart: false,
          errorNullEnd: false,
        });
        RequestData();
        setPrevData({
          rol: rol,
          startFilterDate: startFilterDate,
          endFilterDate: endFilterDate,
        });
      }
    } else {
      handleCloseFilterModal();
      RequestData();
      setPrevData({
        rol: rol,
        startFilterDate: startFilterDate,
        endFilterDate: endFilterDate,
      });
    }
  };

  const handleResetFilter = () => {
    settingFilters("Todos", null, null);
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  //------------------------------------------------

  //FILTERS SETTING
  //------------------------------------------------

  const settingFilters = (rol, firstDay, endDay) => {
    setRol(rol);
    setStartFilterDate(firstDay);
    setEndFilterDate(endDay);
  };

  //------------------------------------------------

  return (
    <>
      <div className="w-100 px-3 mt-2">
        {phone ? (
          <div className="mt-2 w-100 p-1 mb-2 r-15">
            <div className="mb-2">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                requestData={RequestData}
                setPage={setPage}
              />
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <AddButton text={""} link={"nuevo/"} />
                <DownloadButtonExcel
                  text={""}
                  disabled={data.length > 0 ? false : true}
                  data={dataExcel}
                  headers={usersHeaders}
                  sheetName={"Usuarios"}
                  isLoading={isLoadingExcel}
                  getData={() => GetExcelUserList()}
                />
              </div>

              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        ) : (
          <div className="mt-2 w-100 d-flex justify-content-between mb-3 p-1 r-15">
            <div className="d-flex align-items-center">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                requestData={RequestData}
                setPage={setPage}
              />

              <div className="d-flex align-items-center ml-1">
                <AddButton text={"Usuario nuevo"} link={"nuevo/"} />
                <DownloadButtonExcel
                  text={"Descargar"}
                  disabled={data.length > 0 ? false : true}
                  data={dataExcel}
                  headers={usersHeaders}
                  sheetName={"Usuarios"}
                  isLoading={isLoadingExcel}
                  getData={() => GetExcelUserList()}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        )}

        {view ? (
          // Vista de tabla
          isLoading ? (
            // data.length == 0 ? (
            <div className="mt-1 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <SkeletonTableUsers
                num={3}
                type={roles.CLIENT}
                total={total}
                page={page}
                setPage={setPage}
              />
            </div>
          ) : (
            <div className="mt-1 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <TableUsers
                RequestData={RequestData}
                data={data}
                search={search}
                type={roles.CLIENT}
                setPage={setPage}
                page={page}
                total={total}
                count={count}
              />
            </div>
          )
        ) : isLoading ? (
          // ) : data.length == 0 ? (

          <div className=" w-100 r-15">
            <Grid
              container
              alignItems="center"
              direction="row"
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
              columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
            >
              <SkeletonCardEntry num={5} icon={true} rows={6} />
            </Grid>
          </div>
        ) : (
          <ListViewUsers
            RequestData={RequestData}
            height={330}
            data={data}
            search={search}
            type={roles.CLIENT}
            total={total}
            page={page}
            setPage={setPage}
            count={count}
            requestData={RequestData}
          />
        )}
      </div>

      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseFilterModal}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ModalFilters
            errors={errors}
            setErrors={setErrors}
            handleClose={handleCloseModal}
            setInvisible={setInvisible}
            dates={{
              setEnd: setEndFilterDate,
              end: endFilterDate,
              setStart: setStartFilterDate,
              start: startFilterDate,
            }}
            fields={[
              {
                array: roles,
                prop: "rol",
                type: "Rol",
                value: rol,
                handleOnChange: handleChangeRol,
              },
            ]}
            onClickFilter={handleFilter}
            onClickResetFilter={handleResetFilter}
          />
        </Popover>
      </>
    </>
  );
};
