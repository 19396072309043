import { HttpRequest } from "./HttpRequest";
import { getHost } from "../helpers/getHost";

export class LoginAPI {
  constructor() {
    this.http = new HttpRequest();
    this.host = getHost();
    this.token = localStorage.getItem("token");
    this.config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${this.token}`,
      },
    };
  }

  LoginEP(data) {
    return this.http.post(`${this.host}/ws/login/`, data);
  }

  LogoutEP(data) {
    return this.http.post(`${this.host}/ws/logout/`, data, this.config);
  }

  RecoveryEP(data) {
    return this.http.post(`${this.host}/ws/resetPassword/`, data);
  }

  ChangePasswordEP(data) {
    return this.http.post(`${this.host}/ws/updatePassword/`, data, this.config);
  }
}
