import React, { useContext, useState, useEffect } from "react";
import { BusinessContext } from "../../context/BusinessContext";
import {
  useMediaQuery,
  IconButton,
  Paper,
  Collapse,
  Tooltip,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import LocationMap from "../../components/Map/LocationMap";
import {
  Add,
  ArrowBackIos,
  EditTwoTone,
  Email,
  KeyboardArrowDown,
  LocationOn,
  OpenInNew,
  Phone,
} from "@mui/icons-material";

import { roles } from "../../helpers/enums";
import { AddFormButton } from "../../components/Buttons/AddFormButton";

export function BusinessDetails() {
  /* IF TRUE TABLE VIEW IS ACTIVE, OTHERWISE CARD VIEW IS ACTIVE */
  const [view, setView] = useState(true);
  const phone = useMediaQuery("(max-width:480px)"); // FOR RESPONSIVE DESIGN
  const tablet = useMediaQuery("(max-width:750px)"); // FOR RESPONSIVE DESIGN

  const rol = localStorage.getItem("rol");

  const navigate = useNavigate();
  const business = useLocation().state;

  if (!business) {
    if (rol != roles.ADMIN) {
      navigate("/cliente/ventas/B2B/negocios/");
    } else {
      navigate("/administrador/ventas/B2B/negocios/");
    }
  }

  //------------------------------------------------
  //DATA STATES AND FUNCTIONS
  //------------------------------------------------
  const [openCards, setOpenCards] = useState([]);
  const { locations, setLocations, isLoading, getLocations } =
    useContext(BusinessContext);
  const [ready, setReady] = useState(false);

  //------------------------------------------------
  //EFFECTS
  //------------------------------------------------

  useEffect(() => {
    setLocations([]);
    setReady(true);
  }, []);

  useEffect(() => {
    if (ready) {
      if (business) {
        requestData();
      }
      setReady(false);
    }
  }, [ready]);

  //------------------------------------------------
  //DATA REQUEST
  //------------------------------------------------

  const requestData = () => {
    getLocations(business.id);
  };

  return (
    business && (
      <div className="w-100 py-3 px-2" style={{ height: "85vh" }}>
        {/* HEADER */}
        <Paper
          className={`w-100 p-1 r-15 d-flex align-items-center ${
            tablet && "justify-content-center"
          }`}
        >
          <Tooltip title="Volver" placement="top" arrow>
            <IconButton
              className="cursor-pointer ds-yellow-font hover-link-blue transition-2"
              onClick={() =>
                rol != roles.ADMIN
                  ? navigate("/cliente/ventas/B2B/negocios/")
                  : navigate("/administrador/ventas/B2B/negocios/")
              }
              disabled={false}
            >
              <ArrowBackIos fontSize={tablet ? "small" : "medium"} />
            </IconButton>
          </Tooltip>
          <div
            className={`my-1 ml-2 ds-blue-font font-weight-bold ${
              tablet ? "h5" : "h4"
            }`}
          >
            <span className="ds-yellow-font">
              {business.business.substring(0, 1)}
            </span>
            {business.business.substring(1, business.business.length)}
          </div>
          <Tooltip title="Visitar el sitio" placement="top" arrow>
            <a
              className="my-1 ml-2 ds-blue-font font-weight-bold"
              href={business.website}
              target="_blank"
            >
              <IconButton
                disabled={false}
                sx={{ "&:hover": { color: "blue" } }}
              >
                <OpenInNew fontSize={tablet ? "small" : "medium"} />
              </IconButton>
            </a>
          </Tooltip>
          {rol != roles.ADMIN && (
            <Tooltip title="Editar datos" arrow placement="top">
              <IconButton
                disabled={false}
                onClick={() =>
                  navigate("/cliente/ventas/B2B/negocios/nuevo/", {
                    state: { business: business },
                  })
                }
                className="p-1 ml-2"
                sx={{ "&:hover": { color: "green" } }}
              >
                <EditTwoTone fontSize={tablet ? "small" : "medium"} />
              </IconButton>
            </Tooltip>
          )}
        </Paper>
        {/* MAP AND openCARDS CONTAINER */}
        <div className={`d-flex ${tablet ? "flex-column" : ""}`}>
          {/* MAP CONTAINER*/}
          <Paper
            className={`mr-3 p-2 r-15 mb-2 ${
              tablet ? "w-100 mt-2" : "w-75 mt-3"
            }`}
          >
            <LocationMap
              data={locations}
              isLoading={isLoading}
              requestData={requestData}
              business={business}
              title={
                <div className="d-flex justify-content-between align-items-center pb-1 mx-2">
                  <p
                    className={`font-weight-bold ds-blue-font m-0 ${
                      tablet ? "h5" : "h4"
                    }`}
                  >
                    Ubicaciones
                  </p>
                  {rol != roles.ADMIN && (
                    <AddFormButton
                      text="Agregar ubicación"
                      onClick={() =>
                        navigate(
                          "/cliente/ventas/B2B/negocios/agregar_ubicacion/",
                          {
                            state: {
                              business: { ...business },
                            },
                          }
                        )
                      }
                    />
                  )}
                </div>
              }
            />
          </Paper>
          {/* CARDS CONTAINER*/}
          <div
            className={`r-15 ${tablet ? "w-100 mt-2" : "w-25 mt-3 "}`}
            style={{ overflowY: "auto" }}
          >
            <Paper
              className={`px-3 py-3 ${
                tablet ? "xs-font mb-2" : "small-font mb-3"
              }`}
            >
              {/* HEADER*/}
              <div className="d-flex justify-content-between">
                <p
                  className={`ds-blue-font font-weight-bold ${
                    phone ? "medium-font" : "h6"
                  }`}
                >
                  Contacto
                </p>
                <KeyboardArrowDown
                  className="cursor-pointer"
                  onClick={() => {
                    setOpenCards(
                      openCards.includes("contact")
                        ? openCards.filter((el) => el != "contact")
                        : [...openCards, "contact"]
                    );
                  }}
                  sx={{
                    mr: -1,
                    transform: openCards.includes("contact")
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "0.5s",
                  }}
                />
              </div>
              <Collapse in={openCards.includes("contact")}>
                <p className="font-weight-bold m-0">
                  {`${business.first_name_contact} ${business.last_name_contact}`}
                </p>
                <p className="m-0">
                  <Phone
                    className="ds-yellow-font align-middle"
                    fontSize="inherit"
                  />{" "}
                  {business.cell_phone_contact}
                </p>

                <p className="m-0 text-break">
                  {" "}
                  <Email
                    className="ds-yellow-font p-0 align-middle"
                    fontSize="inherit"
                  />{" "}
                  {`${business.email_contact}`}
                </p>
              </Collapse>
            </Paper>
            <Paper
              className={`px-3 py-3 ${
                tablet ? "xs-font mb-2" : "small-font mb-3 "
              }`}
            >
              {/* HEADER*/}
              <div className="d-flex justify-content-between">
                <p
                  className={`ds-blue-font font-weight-bold text-break ${
                    phone ? "medium-font" : "h6"
                  }`}
                >
                  Oficina
                </p>
                <KeyboardArrowDown
                  className="cursor-pointer"
                  onClick={() => {
                    setOpenCards(
                      openCards.includes("office")
                        ? openCards.filter((el) => el != "office")
                        : [...openCards, "office"]
                    );
                  }}
                  sx={{
                    mr: -1,
                    transform: openCards.includes("office")
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "0.5s",
                  }}
                />
              </div>
              <Collapse in={openCards.includes("office")}>
                <p className="m-0">
                  <Phone
                    className="ds-yellow-font align-middle"
                    fontSize="small"
                  />{" "}
                  {business.office_phone}
                </p>
                <p className="m-0">
                  <LocationOn
                    className="ds-yellow-font p-0 align-middle"
                    fontSize="small"
                  />{" "}
                  {`${business.street} ${business.number}, ${business.neighborhood} CP ${business.postal_code}, ${business.municipality}, ${business.state}, ${business.country} `}
                </p>{" "}
              </Collapse>
            </Paper>
            <Paper
              className={`px-3 py-3 ${tablet ? "xs-font mb-2" : "small-font"}`}
            >
              {/* HEADER*/}
              <div className="d-flex justify-content-between">
                <p
                  className={`ds-blue-font font-weight-bold ${
                    phone ? "medium-font" : "h6"
                  }`}
                >
                  Datos fiscales
                </p>
                <KeyboardArrowDown
                  className="cursor-pointer"
                  onClick={() => {
                    setOpenCards(
                      openCards.includes("fiscal")
                        ? openCards.filter((el) => el != "fiscal")
                        : [...openCards, "fiscal"]
                    );
                  }}
                  sx={{
                    mr: -1,
                    transform: openCards.includes("fiscal")
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "0.5s",
                  }}
                />
              </div>
              <Collapse in={openCards.includes("fiscal")}>
                <Tooltip title="Razón social" placement="top" arrow>
                  <p className="m-0 ">{business.business_name}</p>
                </Tooltip>
                <Tooltip title="Representante legal" placement="top" arrow>
                  <p className="m-0 ">{business.legal_representative}</p>
                </Tooltip>
                <Tooltip title="RFC" placement="top" arrow>
                  <p className="m-0 text-break">{business.rfc}</p>
                </Tooltip>
                <Tooltip title="Tipo de régimen" placement="top" arrow>
                  <p className="m-0 ">{business.regime_type}</p>
                </Tooltip>
                <Tooltip title="Régimen fiscal" placement="top" arrow>
                  <p className="m-0 ">{business.tax_regime.value}</p>
                </Tooltip>
              </Collapse>
            </Paper>
          </div>
        </div>
      </div>
    )
  );
}
