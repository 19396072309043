import React, { useState, useEffect, useMemo } from "react";

import { Popover, useMediaQuery } from "@mui/material";

import { ToggleView } from "../../../components/ToggleView";
import { Search } from "../../../components/Search";
import { RefreshButton } from "../../../components/RefreshButton";
import { TableInventoryEntries } from "../../../components/Tables/TableInventoryEntries";

import { SkeletonTableInventoryEntries } from "../../../components/Skeleton/SkeletonTableInventoryEntries";
import { ListViewInventoryEntries } from "../../../components/ListViewInventoryEntries";

import { roles } from "../../../helpers/enums";

import {
  getDateCleaned,
  getDayjsFormatted,
} from "../../../helpers/getDateCleaned";
import { ModalFilters } from "../../../components/Modal/ModalFilters";
import { WarehouseAPI } from "../../../api/Warehouse";
import { InventoryAPI } from "../../../api/Inventory";
import { AdminAPI } from "../../../api/Admin";

export const Entries = () => {
  const phone = useMediaQuery("(max-width:710px)");
  const [view, setView] = useState(true);
  const [listIndex, setListIndex] = useState(0);

  //------------------------------------------------

  //DATA STATES
  //------------------------------------------------

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0); /*Estado p/paginacion*/
  const [total, setTotal] = useState(); /*Total de paginas enviado desde api*/
  const [count, setCount] = useState(); /*Paginacion listas */
  const [search, setSearch] = useState("");
  const [ready, setReady] = useState(false);

  //------------------------------------------------

  //ERRORES
  //------------------------------------------------

  const [errors, setErrors] = useState({
    error: false,
    errorNullStart: false,
    errorNullEnd: false,
  });

  //------------------------------------------------

  //MODAL STATES
  //------------------------------------------------

  const [openFilter, setOpenFilter] = useState(false);

  //------------------------------------------------

  //LOADERS STATES
  //------------------------------------------------

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [invisible, setInvisible] = useState(false);

  //------------------------------------------------

  //FILTERS STATES
  //------------------------------------------------

  const [isTable, setIsTable] = useState(null);

  const [status, setStatus] = useState("Todos");

  const [clients, setClients] = useState([]);
  const [client, setClient] = useState("Todos");

  const [cedi, setCedi] = useState("Todos");
  const [cedis, setCedis] = useState([]);

  const [branch, setBranch] = useState("Todas");
  const [branches, setBranches] = useState([]);

  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

  const [startFilterDate, setStartFilterDate] = useState(firstDay);
  const [endFilterDate, setEndFilterDate] = useState(today);

  const [prevData, setPrevData] = useState({
    startFilterDate: firstDay,
    endFilterDate: today,
    //  category: "Todas",
    cedi: "Todos",
    branch: "Todas",
    status: "Todos",
    client: "Todos",
  });

  //------------------------------------------------

  //EFFECTS
  //------------------------------------------------

  useEffect(() => {
    RequestData();
  }, [isTable]);

  useEffect(() => {
    if (ready) {
      RequestData();
    }
  }, [ready]);

  useEffect(() => {
    setPage(0);
    setSearch("");
    setReady(true);
    requestFilterData();
  }, []);

  //------------------------------------------------

  //EXCEL STATES AND DATA
  //------------------------------------------------

  const [dataExcel, setDataExcel] = useState([]);

  const statusObj = [
    { id: 0, status: "Todos" },
    { id: 1, status: "Por validar" },
    { id: 2, status: "Validado" },
    { id: 3, status: "Incompleto" },
    { id: 4, status: "Cancelado" },
  ];

  //------------------------------------------------

  //APIS
  //------------------------------------------------
  const warehouseAPI = useMemo(() => new WarehouseAPI(), []);
  const inventoryAPI = useMemo(() => new InventoryAPI(), []);
  const adminAPI = useMemo(() => new AdminAPI(), []);

  //------------------------------------------------

  //DATA REQUEST
  //------------------------------------------------

  const RequestData = () => {
    getInventoryEntries();
    setReady(false);
    //GetEntriesExcel();
  };

  const requestFilterData = () => {
    GetClients();
    //  GetCategories();
    GetCedis();
    getBranchesList();
  };

  const handleRefresh = () => {
    getInventoryEntries();
  };

  const GetCedis = async () => {
    let data = new FormData();

    const response = await warehouseAPI.cedisList(data);
    if (response.status == 200) {
      setCedis([{ id: 0, cedi: "Todos" }, ...response.data.cedis]);
    } else {
      console.log(response);
    }
  };

  const getBranchesList = async (client) => {
    let data = new FormData();

    if(client){
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }

    const response = await warehouseAPI.getBranchesList(data);
    if (response.status == 200) {
      setBranches([{ id: 0, sucursal: "Todas" }, ...response.data.branches]);
    } else {
      console.log(response);
    }
  };

  const GetClients = async () => {
    let data = new FormData();

    const response = await adminAPI.clientsListCleanedEP(data);
    if (response.status == 200) {
      setClients([{ id: 0, razon_social: "Todos" }, ...response.data.clients]);
    } else {
      console.log(response);
    }
  };

  const GetEntriesExcel = async () => {
    let data = new FormData();
    data.append("page", page + 1);
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }
    if (branch != "Todas") {
      const auxBranch = branches.find((el) => el.sucursal == branch);
      data.append("branch", auxBranch.id);
    }
    if (status != "Todos") {
      const auxStatus = statusObj.find((el) => el.status == status);
      data.append("status", auxStatus.id);
    }
    if (cedi != "Todos") {
      const cediToSend = cedis.find((el) => el.cedi == cedi);
      data.append("cedi", cediToSend.id);
    }
    if (search != "") {
      data.append("search", search);
    }

    const response = await inventoryAPI.entriesExcelEP(data);
    if (response.status == 200) {
      const aux = response.data.entries;
      const auxMap = aux.map((el) => ({
        cliente: el.cliente,
        almacen:
          el.branch != null ? el.branch : el.cedi != null ? el.cedi : "N/A",
        cliente: el.cliente,
        creation_date: el.creation_date,
        description: el.description == null ? "N/A" : el.description,
        expected_date: el.expected_date,
        expiration_date:
          el.expiration_date == null ? "N/A" : el.expiration_date,
        folio: el.folio,
        inventory_entry: el.inventory_entry,
        pieces: el.pieces,
        product: el.product,
        reception_date: el.reception_date == null ? "N/A" : el.reception_date,
        sku: el.sku,
        status:
          el.status == 1
            ? "Por validar"
            : el.status == 2
            ? "Incompleto"
            : el.status == 3
            ? "Validado"
            : el.status == 4
            ? "Cancelado"
            : "Otro",
      }));
      setDataExcel(auxMap);
    } else {
      console.log(response);
    }
  };

  const getInventoryEntries = async () => {
    setIsLoading(true);

    const data = new FormData();
    data.append("page", page + 1);
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }
    if (cedi != "Todos") {
      const cediToSend = cedis.find((el) => el.cedi == cedi);
      data.append("cedi", cediToSend.id);
    }
    if (branch != "Todas") {
      const auxBranch = branches.find((el) => el.sucursal == branch);
      data.append("branch", auxBranch.id);
    }
    if (status != "Todos") {
      const auxStatus = statusObj.find((el) => el.status == status);
      data.append("status", auxStatus.id);
    }
    if (search != "") {
      data.append("search", search);
    }

    const response = await inventoryAPI.inventoryEntriesEP(data);
    if (response.status == 200) {
      setData(response.data.entries);
      setIsLoading(false);
      setTotal(response.data.total);
      setCount(Math.ceil(response.data.total / 10));
    } else {
      console.log(response);
    }
  };

  //------------------------------------------------

  //HANDLERS
  //------------------------------------------------

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleCloseFilterModal = () => setAnchorEl(null);

  const handleCloseModal = () => {
    // setOpenFilter(false);
    setAnchorEl(null);
    if (!view) {
      settingFilters(
        //  prevData.category,
        prevData.cedi,
        prevData.branch,
        status,
        prevData.client,
        prevData.startFilterDate,
        prevData.endFilterDate
      );
    } else {
      settingFilters(
        //  prevData.category,
        prevData.cedi,
        prevData.branch,
        prevData.status,
        prevData.client,
        prevData.startFilterDate,
        prevData.endFilterDate
      );
    }
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  /* const handleChangeCategory = (e) => {
    setCategory(e.target.value);
  };
 */
  const handleChangeClient = (e) => {
    setClient(e.target.value);
    getBranchesList(e.target.value);
  };

  const handleChangeCedi = (e) => {
    setCedi(e.target.value);
  };

  const handleChangeBranch = (e) => {
    setBranch(e.target.value);
  };

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleFilter = () => {
    if (endFilterDate < startFilterDate) {
      setErrors({ error: true });
    } else {
      handleCloseFilterModal();
      setErrors({
        error: false,
        errorNullStart: false,
        errorNullEnd: false,
      });
      RequestData();
      setPrevData({
        //  category: category,
        cedi: cedi,
        branch: branch,
        status: status,
        client: client,
        startFilterDate: startFilterDate,
        endFilterDate: endFilterDate,
      });
    }
  };

  const handleResetFilter = () => {
    if (!view) {
      settingFilters("Todos", "Todas", status, "Todos", firstDay, today);
    } else {
      settingFilters("Todos", "Todas", "Todos", "Todos", firstDay, today);
    }
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  //------------------------------------------------

  //FILTERS SETTING
  //------------------------------------------------

  const settingFilters = (
    //  category,
    cedi,
    branch,
    status,
    client,
    firstDay,
    endDay
  ) => {
    //  setCategory(category);
    setCedi(cedi);
    setBranch(branch);
    setStatus(status);
    setClient(client);
    setStartFilterDate(firstDay);
    setEndFilterDate(endDay);
  };

  //------------------------------------------------

  return (
    <>
      <div className="w-100 px-3 mt-2">
        {phone ? (
          <div className="mt-2 w-100 p-1 r-15">
            <div className="mb-2">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                requestData={RequestData}
                setPage={setPage}
              />
            </div>

            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <RefreshButton text={""} onClick={handleRefresh} />
                {/* <DownloadButtonExcel
                  text={""}
                  disabled={true}
                  data={dataExcel}
                  headers={entriesHeadersAdmin}
                  sheetName={`Entradas ${client}`}
                  isLoading={isLoadingExcel}
                /> */}
              </div>

              <ToggleView
                setView={setView}
                view={view}
                setStatus={setStatus}
                entries={true}
                setIsTable={setIsTable}
              />
            </div>
          </div>
        ) : (
          <div className="mt-2 w-100 d-flex justify-content-between p-1 r-15">
            <div className="d-flex align-items-center">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                requestData={RequestData}
                setPage={setPage}
              />
              <div className="d-flex align-items-center ">
                <RefreshButton text={"Actualizar"} onClick={handleRefresh} />
                {/* <DownloadButtonExcel
                  text={"Descargar"}
                  disabled={true}
                  data={dataExcel}
                  headers={entriesHeadersAdmin}
                  sheetName={`Entradas ${client}`}
                  isLoading={isLoadingExcel}
                /> */}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <ToggleView
                setView={setView}
                view={view}
                setStatus={setStatus}
                entries={true}
                setIsTable={setIsTable}
              />
            </div>
          </div>
        )}

        {view ? (
          // Vista de tabla
          isLoading ? (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <SkeletonTableInventoryEntries
                num={3}
                type={roles.ADMIN}
                total={total}
                page={page}
                setPage={setPage}
              />
            </div>
          ) : (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <TableInventoryEntries
                search={search}
                data={data}
                type={roles.ADMIN}
                total={total}
                page={page}
                setPage={setPage}
                RequestData={RequestData}
                count={count}
              />
            </div>
          )
        ) : (
          <ListViewInventoryEntries
            icon={false}
            entries={true}
            setStatus={setStatus}
            status={status}
            getInventoryEntries={getInventoryEntries}
            startFilterDate={startFilterDate}
            endFilterDate={endFilterDate}
            clients={clients}
            client={client}
            search={search}
            data={data}
            listIndex={listIndex}
            setListIndex={setListIndex}
            isLoading={isLoading}
            type={roles.ADMIN}
            page={page}
            setPage={setPage}
            count={count}
            total={total}
          />
        )}
      </div>

      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseFilterModal}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ModalFilters
            view={view}
            errors={errors}
            setErrors={setErrors}
            handleClose={handleCloseModal}
            dates={{
              setEnd: setEndFilterDate,
              end: endFilterDate,
              setStart: setStartFilterDate,
              start: startFilterDate,
            }}
            fields={[
              {
                array: statusObj,
                prop: "status",
                type: "Estatus",
                value: status,
                handleOnChange: handleChangeStatus,
              },
              {
                array: clients,
                prop: "razon_social",
                type: "Cliente",
                value: client,
                handleOnChange: handleChangeClient,
              },
              /* {
                array: categories,
                prop: "categoria",
                type: "Categoría",
                value: category,
                handleOnChange: handleChangeCategory,
              }, */
              {
                array: cedis,
                prop: "cedi",
                type: "Cedis",
                value: cedi,
                handleOnChange: handleChangeCedi,
              },
              {
                array: branches,
                prop: "sucursal",
                type: "Sucursal",
                value: branch,
                handleOnChange: handleChangeBranch,
              },
            ]}
            onClickFilter={handleFilter}
            onClickResetFilter={handleResetFilter}
          />
        </Popover>
      </>
    </>
  );
};
