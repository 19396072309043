import { HttpRequest } from "./HttpRequest";
import { getHost } from "../helpers/getHost";

export class GraphsAPI {
  constructor() {
    this.http = new HttpRequest();
    this.host = getHost();
    this.token = localStorage.getItem("token");
    this.config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${this.token}`,
      },
    };
  }

  moreStockEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/moreInventory/`,
      data,
      this.config
    );
  }

  lessStockEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/lessInventory/`,
      data,
      this.config
    );
  }

  inventoryWarehousesEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/inventoryWarehouses/`,
      data,
      this.config
    );
  }

  entriesGraphEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/entriesGraph/`,
      data,
      this.config
    );
  }

  transferGraphEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/transferGraph/`,
      data,
      this.config
    );
  }

  productsCleanedEP(data) {
    return this.http.post(
      `${this.host}/sale/ws/productsOnOrders/`,
      data,
      this.config
    );
  }

  salesAmountPerDayEP(data) {
    return this.http.post(`${this.host}/sale/ws/sales/`, data, this.config);
  }

  salesByMarketPlaceEP(data) {
    return this.http.post(
      `${this.host}/sale/ws/marketplace/`,
      data,
      this.config
    );
  }

  salesTypeOfOrderEP(data) {
    return this.http.post(`${this.host}/sale/ws/order/`, data, this.config);
  }

  topSalesEP(data) {
    return this.http.post(
      `${this.host}/sale/ws/topsProducts/`,
      data,
      this.config
    );
  }

  ordersByWarehouseEP(data) {
    return this.http.post(
      `${this.host}/sale/ws/ordersByWarehouse/`,
      data,
      this.config
    );
  }

  salesByProductEP(data) {
    return this.http.post(
      `${this.host}/sale/ws/salesByProduct/`,
      data,
      this.config
    );
  }

  channelsListEP(data) {
    return this.http.post(
      `${this.host}/sale/ws/channelsList/`,
      data,
      this.config
    );
  }

  branchesListEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/branchesWithInventory/`,
      data,
      this.config
    );
  }

  branchesListAllEP(data) {
    return this.http.post(
      `${this.host}/warehouse/ws/branchesList/`,
      data,
      this.config
    );
  }

  salesByCityEP(data) {
    return this.http.post(
      `${this.host}/sale/ws/salesByCity/`,
      data,
      this.config
    );
  }
}
