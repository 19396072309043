import React from "react";

import { Grid, Paper, IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { EditTwoTone } from "@mui/icons-material";
import { Navigate, useNavigate } from "react-router-dom";
import { roles } from "../../helpers/enums";
import NoImage from "../../assets/NoImage.svg";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: 10,
  minHeight: 330,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const CardProduct = ({ info, type }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/cliente/productos/editar/", {
      state: {
        id: info.id == null ? "" : info.id,
        description: info.descripcion == null ? "" : info.descripcion,
        sku: info.sku == null ? "" : info.sku,
        barCode: info.codigo_barras == null ? "" : info.codigo_barras,
        price: info.precio_por_unidad == null ? "" : info.precio_por_unidad,
        category_id: info.categoria == null ? "" : info.categoria,
        weight: info.peso == null ? "" : info.peso,
        height: info.altura == null ? "" : info.altura,
        width: info.ancho == null ? "" : info.ancho,
        depth: info.profundidad == null ? "" : info.profundidad,
        image: info.imagen == null ? "" : info.imagen,
        imageFile: info.imagen == null ? false : true,
        // id: info.id,
        // description: info.descripcion,
        // sku: info.sku,
        // barCode: info.codigo_barras,
        // price: info.precio_por_unidad,
        // category_id: info.categoria,
        // weight: info.peso,
        // height: info.altura,
        // width: info.ancho,
        // depth: info.profundidad,
        // image: info.imagen,
      },
    });
  };

  return (
    <Grid item xs={6} sm={3} md={2.4} lg={2.4} xl={2.4}>
      <Item
        className={`mb-3 ${
          info.editable && type == roles.CLIENT && "cursor-pointer hover-card"
        }`}
        onClick={info.editable && type == roles.CLIENT ? handleClick : null}
      >
        {/* <div className="d-flex flex-column align-items-center justify-content-between bg-success h-100"> */}

        {/* --------------------------------Boton editar-------------------------------- */}
        {type == roles.CLIENT ? (
          <div className="d-flex justify-content-end w-100">
            <Tooltip
              title={
                info.editable
                  ? "Editar"
                  : "No se puede editar porque tiene stock"
              }
              arrow
              placement="top"
            >
              <div className={`${info.editable && "hover-icon"}`}>
                <IconButton
                  disabled={!info.editable}
                  onClick={info.editable ? null : handleClick}
                >
                  <EditTwoTone />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        ) : null}

        {/* --------------------------------Imagen-------------------------------- */}
        <div className="d-flex align-items-center">
          <img
            src={info.imagen != null ? info.imagen : NoImage}
            className={info.imagen && "img-thumbnail"}
            alt=""
            maxHeight={120}
            width={100}
            length={100}
          />
        </div>

        {/* --------------------------------Detalles producto-------------------------------- */}

        <div>
          <Tooltip
            title={info.descripcion.length > 15 ? info.descripcion : ""}
            placement="top"
            arrow
          >
            <div>
              <strong>
                {info.descripcion.substring(0, 15)}
                {info.descripcion.length > 15 && "..."}
              </strong>
            </div>
          </Tooltip>
          <Tooltip
            title={info.sku.length > 10 ? info.sku : ""}
            placement="top"
            arrow
          >
            <div>
              SKU: {info.sku.substring(0, 10)}
              {info.sku.length > 10 && "..."}
            </div>
          </Tooltip>
          {type == roles.ADMIN && <div>Cliente: {info.cliente}</div>}
          <div>Código de barras: {info.codigo_barras}</div>
          <div>Precio: {info.precio_por_unidad}</div>
          <div>Categoría: {info.categoria}</div>
        </div>

        {/* </div> */}
      </Item>
    </Grid>
  );
};
