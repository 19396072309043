import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Skeleton,
  Pagination,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const SkeletonTableUsers = ({ num, total, page, setPage }) => {

  const a = Array(num).fill(true);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 400 }}>
          <Table sx={{ minWidth: 750 }} aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell align="center">Nombre</StyledTableCell>
                <StyledTableCell align="center">Teléfono</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">Rol</StyledTableCell>
                <StyledTableCell align="center">Puesto</StyledTableCell>
                <StyledTableCell align="center">Fecha</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                a.map((row, index) => {

                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>

                      <TableCell padding="checkbox">
                        <div className="d-flex">
                          <Skeleton sx={{ marginRight: 1 }} variant="circular" width={30} height={30} />
                          <Skeleton variant="circular" width={30} height={30} />
                        </div>
                      </TableCell>
                      <TableCell component="th" id={labelId} align="center"><Skeleton variant="text" /></TableCell>
                      <TableCell align="center"><Skeleton variant="text" /></TableCell>
                      <TableCell align="center"><Skeleton variant="text" /></TableCell>
                      <TableCell align="center"><Skeleton variant="text" /></TableCell>
                      <TableCell align="center"><Skeleton variant="text" /></TableCell>
                      <TableCell align="center"><Skeleton variant="text" /></TableCell>

                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* {total == undefined ? 
          ( */}
        <div className="d-flex justify-content-start align-items-center p-3">
          <Typography variant="caption" className="ml-2"><Skeleton width={50} /></Typography>
          <Skeleton variant="circular" className="ml-4" width={20} />
          <Skeleton variant="circular" className="ml-2" width={20} />
        </div>
        {/* ): 
          (<Pagination setPage={setPage} page={page} total={total} />
          )} */}
      </Paper>
    </Box>
  );
};
