import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  useMediaQuery,
  IconButton,
  Modal,
  InputAdornment,
  Backdrop,
} from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";

import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";

import { ModalError } from "../Modal/ModalError";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ModalSuccessPassword } from "../Modal/ModalSuccessPassword";
import LoaderDS from "../../assets/LoaderDS.svg";
import { useMemo } from "react";
import { LoginAPI } from "../../api/Login";

export const PasswordForm = ({ rol }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openBD, setOpenBD] = useState(false);

  const [errorPass, setErrorPass] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const navigate = useNavigate();

  const phone = useMediaQuery("(max-width:768px)");

  const fields = [
    { keyName: "Contraseña anterior", value: "password", type: true },
    {
      keyName: "Nueva contraseña",
      value: "new_Password",
      type: true,
    } /*type: true para validaciones */,
    { keyName: "Confirma tu contraseña", value: "confirmPassword", type: true },
  ];

  // apis
  const loginAPI = useMemo(() => new LoginAPI(), []);

  const ChangePassword = async (obj) => {
    setOpenBD(true);
    let data = new FormData();
    data.append("new_password", obj.new_Password);
    data.append("password", obj.password);

    const response = await loginAPI.ChangePasswordEP(data);
    if (response.status == 200) {
      if (response.data.result == 1) {
        setOpenBD(false);
        setOpenSuccess(true);
        setErrorPass(true);
        setTimeout(() => {
          setOpenSuccess(false);
          localStorage.removeItem("token");
          window.location.reload();
        }, 1500);
      } else {
        setOpenError(true);
      }
    } else {
      if (response.response.data.msg) {
        setErrorMsg(response.response.data.msg);
        setErrorPass(true);
      }
      setOpenBD(false);
      setOpenError(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      new_Password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Campo Obligatorio"),
      new_Password: Yup.string()
        .required("Campo Obligatorio")
        .max(30, "Demasiado largo")
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/,
          "Mínimo 8 caracteres, una mayúscula, una minúscula, un número y un carácter especial"
        )
        .when("password", (password, schema) => {
          return schema.test({
            test: (new_Password) => new_Password !== password,
            message: "La contraseña anterior debe ser diferente a la nueva",
          });
        }),
      confirmPassword: Yup.string()
        .required("Campo Obligatorio")
        .max(30, "Demasiado largo")
        .when("new_Password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("new_Password")],
            "Contraseñas deben coincidir"
          ),
        }),
    }),

    onSubmit: (values) => {
      ChangePassword(values);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-100 d-flex justify-content-center">
          <div
            className={`d-flex flex-column align-items-center justify-content-center border bg-white r-15 p-4 ${
              phone ? "w-100" : "w-75"
            }`}
          >
            <div className="text-center mb-4">
              <p className={`ds-blue-font ${phone ? "h5" : "h5"}`}>
                *Genere una contraseña que no haya utilizado antes
              </p>
            </div>

            <div className={`w-100 `}>
              {fields.map((field) => (
                // <Grid item xs={6}>
                <div
                  key={field.keyName}
                  className={` d-flex mb-3  ${
                    phone ? "px-2 flex-column" : "px-4"
                  }  `}
                >
                  <div
                    className={`d-flex align-items-center mr-2`}
                    style={{ minWidth: `${phone ? "" : "200px"}` }}
                  >
                    <p className=" h6 w-100" htmlFor={field.value}>{`${
                      field.keyName
                    }: ${field.type ? "*" : ""}`}</p>
                  </div>
                  <div className="d-flex align-items-center w-100 ">
                    <div className="d-flex flex-column w-100 ">
                      {field.value == "password" ? (
                        <TextField
                          error={
                            formik.touched[field.value] &&
                            formik.errors[field.value]
                          }
                          helperText={
                            formik.touched[field.value] &&
                            formik.errors[field.value] ? (
                              <div>{formik.errors[field.value]}</div>
                            ) : null ||
                              (errorPass && field.value == "password") ? (
                              <div class="text-danger">{errorMsg}</div>
                            ) : null
                          }
                          size="small"
                          type={showOldPassword ? "text" : "password"}
                          label={field.keyName}
                          variant="outlined"
                          fullWidth
                          onChange={formik.handleChange}
                          value={formik.values[field.value]}
                          name={field.value}
                          id={field.value}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowOldPassword}
                                  edge="end"
                                >
                                  {showOldPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      ) : (
                        <TextField
                          error={
                            formik.touched[field.value] &&
                            formik.errors[field.value]
                          }
                          helperText={
                            formik.touched[field.value] &&
                            formik.errors[field.value] ? (
                              <div>{formik.errors[field.value]}</div>
                            ) : null
                          }
                          size="small"
                          type={showPassword ? "text" : "password"}
                          label={field.keyName}
                          variant="outlined"
                          fullWidth
                          onChange={formik.handleChange}
                          value={formik.values[field.value]}
                          name={field.value}
                          id={field.value}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className={`${
            !phone && "d-flex justify-content-end margin-right"
          } mt-2`}
        >
          <div className={`${phone && "mb-2"}`}>
            <CancelButton
              text={"Cancelar"}
              onClick={() => {
                navigate(`/${rol}/cuenta/`);
              }}
              width={phone ? "100%" : ""}
            />
          </div>
          <AcceptButton
            text={"Actualizar"}
            type={"submit"}
            width={phone ? "100%" : ""}
          />
        </div>
      </form>

      <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalSuccessPassword />
        </div>
      </Modal>

      <Modal open={openError} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalError
            text={"Error al actualizar contraseña."}
            handleClose={() => setOpenError(false)}
            error={errorMsg}
            errorPassWord={true}
          />
        </div>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
