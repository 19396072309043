import { useState } from 'react';
import '../App.css';
import IntegrationNotistack, { LayoutSucursal } from './LayoutSucursal';

export function AppSucursal() {
  const [variant, setVariant] = useState("");

  return (
    <IntegrationNotistack variant={variant} setVariant={setVariant} />

  );
}

//-------------------NOTISTACK HERE-------------------------
// export default function IntegrationNotistack() {
//   return (

//     <SnackbarProvider maxSnack={5}>
//       <AppSucursal />
//     </SnackbarProvider>
//   );
// }