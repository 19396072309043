import React from "react";

import { Paper, IconButton, Grid, Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

import { AddOutlined, HideImageTwoTone } from "@mui/icons-material";
import NoImage from "../../assets/NoImage.svg"

import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: 10,
  minHeight: 250,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const CardProductEntry = ({ info }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/cliente/inventario/busqueda/agregar/`, { state: info });
  };

  return (
    <Grid item xs={6} sm={3} lg={2.4} xl={2.4} >

      <Item className="mb-3 cursor-pointer hover-card" onClick={handleClick}>

        {/*------------------------------BOTON------------------------------- */}
        <div className="d-flex align-items-center w-100 justify-content-end">
          <IconButton onClick={handleClick}>
            <AddOutlined />
          </IconButton>
        </div>

        {/*------------------------------IMAGEN------------------------------- */}
        <div>
          {info.imagen != null ?
            <img src={info.imagen} alt="preview" style={{ maxWidth: 100, maxHeight: 100, minHeight: 100, }} /> :
            <div className="d-flex flex-column align-items-center w-100 mt-2">
              <img
                src={NoImage}
                alt="No image"
                width={80}
                height={80}
              />
              <Typography>Imagen no disponible</Typography>
            </div>
          }
        </div>

        {/*------------------------------INFO------------------------------- */}
        <div className="text-center w-100">
          <Tooltip title={info.descripcion.length > 20 ? info.descripcion : ""} placement="top" arrow>
            <div>{info.descripcion.substring(0, 20)}{info.descripcion.length > 20 && "..."}</div>
          </Tooltip>
          <p className="m-0">{info.sku}</p>
        </div>
 
      </Item>
    </Grid>
  );
};
