import React, { useEffect, useRef, useState, createContext } from "react";

import {
  Grid,
  useMediaQuery,
  Tooltip,
  IconButton,
  Badge,
  Popover,
} from "@mui/material";
import { FilterAlt } from "@mui/icons-material";

import { DownloadButton } from "../../components/Buttons/DownloadButton";
import { SalesChannelGraph } from "../../components/Graphs/SalesChannelGraph";
import { TopSalesGraph } from "../../components/Graphs/TopSalesGraph";
import { LessSalesGraph } from "../../components/Graphs/LessSalesGraph";
import { OrdersByWarehouseGraph } from "../../components/Graphs/OrdersByWarehouseGraph";
import { OrdersByCityGraph } from "../../components/Graphs/OrdersByCityGraph";
import { TypeOfOrdersGraph } from "../../components/Graphs/TypeOfOrdersGraph";
import { PiecesSoldByProductGraph } from "../../components/Graphs/PiecesSoldByProductGraph";
import { PiecesSoldByDayGraph } from "../../components/Graphs/PiecesSoldByDayGraph";

import { ModalFilters } from "../../components/Modal/ModalFilters";

import {
  getDateCleaned,
  getDayjsFormatted,
} from "../../helpers/getDateCleaned";

import {
  graphByCityHeaders,
  graphByProdHeaders,
  graphChannelHeaders,
  graphDuringByDayHeaders,
  graphLessSalesHeaders,
  graphSalesByDayHeaders,
  graphTopSalesHeaders,
  graphTypeHeaders,
  graphWhsHeaders,
} from "../../components/Excel/headersGraphs";
import { ByTimeGraph } from "../../components/Graphs/ByTimeGraph";
import FilterIcon from "../../assets/SVGIcons/Filter.svg";
import { useMemo } from "react";
import { SalesB2CAPI } from "../../api/SalesB2C";
import { GraphsAPI } from "../../api/Graphs";
import { AdminAPI } from "../../api/Admin";
import { theme } from "../../utils/theme";
import { ReportHeader } from "../../pages/Reports/ReportHeader";

export const ReportsSales = () => {
  const phone = useMediaQuery("(max-width:480px)");
  const htmlElement = useRef(null);

  //DATA STATES
  //------------------------------------------------
  const [dataTypeOfOrders, setDataTypeOfOrders] = useState([]);
  const [dataSalesChannel, setDataSalesChannel] = useState([]);
  const [dataByCity, setDataByCity] = useState([]);
  const [dataPiecesSoldByProduct, setDataPiecesSoldByProduct] = useState([]);
  const [dataTopSales, setDataTopSales] = useState([]);
  const [dataLessSales, setDataLessSales] = useState([]);
  const [dataOrdersByWarehouse, setDataOrdersByWarehouse] = useState([]);
  const [totalTypeOfOrders, setTotalTypeOfOrders] = useState(0);
  const [totalSalesChannel, setTotalSalesChannel] = useState(0);
  const [totalOrdersByWarehouse, setTotalOrdersByWarehouse] = useState(0);
  const [totalByCity, setTotalByCity] = useState(0);
  const [dataPiecesSoldByDay, setDataPiecesSoldByDay] = useState([]);
  const [dataSalesDuringDay, setDataSalesDuringDay] = useState([]);
  const [maxValueforDuringDay, setMaxValueforDuringDay] = useState(0);

  //------------------------------------------------

  //ERRORES
  //------------------------------------------------

  const [errors, setErrors] = useState({
    error: false,
    errorNullStart: false,
    errorNullEnd: false,
  });

  //------------------------------------------------

  //MODAL STATES
  //------------------------------------------------

  // const [openFilter, setOpenFilter] = useState(false);
  const [invisible, setInvisible] = useState(false);

  //------------------------------------------------

  //LOADERS STATES
  //------------------------------------------------

  const [loaderTypes, setLoaderTypes] = useState(true);
  const [loaderChannels, setLoaderChannels] = useState(true);
  const [loaderAll, setLoaderAll] = useState(true);
  const [loaderMore, setLoaderMore] = useState(true);
  const [loaderLess, setLoaderLess] = useState(true);
  const [loaderBranch, setLoaderBranch] = useState(true);
  const [lodaderByCity, setLodaderByCity] = useState(true);
  const [lodaderSalesByDay, setLodaderSalesByDay] = useState(true);
  const [loaderSalesDuringDay, setLoaderSalesDuringDay] = useState(true);
  const [productOk, setProductOk] = useState(false);

  //------------------------------------------------

  //FILTERS STATES
  //------------------------------------------------

  const [product, setProduct] = useState("");
  const [products, setProducts] = useState([]);
  const [idProduct, setIdProduct] = useState(0);

  const [client, setClient] = useState("Todos");
  const [clients, setClients] = useState([]);

  const [channel, setChannel] = useState("Todos");
  const [channels, setChannels] = useState([]);

  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

  const [startFilterDate, setStartFilterDate] = useState(firstDay);
  const [endFilterDate, setEndFilterDate] = useState(today);

  const [prevData, setPrevData] = useState({
    startFilterDate: firstDay,
    endFilterDate: today,
    client: "Todos",
    channel: "",
  });

  //------------------------------------------------

  //EFFECTS
  //------------------------------------------------

  useEffect(() => {
    RequestData();
  }, []);

  useEffect(() => {
    GetDataChannel();
    GetClients();
  }, []);

  useEffect(() => {
    GetDataSalesByDay();
  }, [idProduct]);

  //------------------------------------------------

  //EXCEL STATES AND DATA
  //------------------------------------------------

  const [excelType, setExcelType] = useState([]);
  const [excelChannel, setExcelChannel] = useState([]);
  const [excelWhs, setExcelWhs] = useState([]);
  const [excelByProd, setExcelByProd] = useState([]);
  const [excelMore, setExcelMore] = useState([]);
  const [excelLess, setExcelLess] = useState([]);
  const [excelByCity, setExcelByCity] = useState([]);
  const [excelByDay, setExcelByDay] = useState([]);
  const [excelDuringDay, setExcelDuringDay] = useState([]);

  const info = [
    { name: "Tipo de pedidos", data: excelType, headers: graphTypeHeaders },
    {
      name: "Pedidos por canal",
      data: excelChannel,
      headers: graphChannelHeaders,
    },
    {
      name: "Pedidos por día",
      data: excelByDay,
      headers: graphSalesByDayHeaders,
    },
    {
      name: "Pedidos por producto y monto",
      data: excelByProd,
      headers: graphByProdHeaders,
    },
    { name: "Más vendidos", data: excelMore, headers: graphTopSalesHeaders },
    { name: "Menos vendidos", data: excelLess, headers: graphLessSalesHeaders },
    { name: "Pedidos por sucursal", data: excelWhs, headers: graphWhsHeaders },
    {
      name: "Pedidos por ciudad",
      data: excelByCity,
      headers: graphByCityHeaders,
    },
    {
      name: "Ventas por semana",
      data: excelDuringDay,
      headers: graphDuringByDayHeaders,
    },
  ];

  //------------------------------------------------

  //DATA REQUEST
  //------------------------------------------------

  const RequestData = () => {
    GetDataTypeOfOrders();
    GetDataSalesChannel();
    GetDataSalesAndTops();
    GetDataOrdersByWarehouse();
    GetsalesByCity();
    GetDataSalesByDay();
    GetProducts();
    GetSalesDuringDay();
  };

  //APIS
  const salesB2CAPI = useMemo(() => new SalesB2CAPI(), []);
  const graphAPI = useMemo(() => new GraphsAPI(), []);
  const adminAPI = useMemo(() => new AdminAPI(), []);

  //INPUT DATA
  const GetClients = async () => {
    let data = new FormData();

    const response = await adminAPI.clientsListCleanedEP(data);

    if (response.status == 200) {
      setClients([{ id: 0, razon_social: "Todos" }, ...response.data.clients]);
    } else {
      console.log(response);
    }
  };

  const GetDataChannel = async () => {
    let data = new FormData();

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }

    const response = await graphAPI.channelsListEP(data);
    if (response.status == 200) {
      const aux = response.data.channels;
      setChannels([{ id: 0, canal: "Todos" }, ...aux]);
      setPrevData({
        ...prevData,
        channel: [{ id: 0, canal: "Todos" }, ...aux],
      });
    } else {
      console.log(response);
    }
  };

  const GetDataTypeOfOrders = async () => {
    setLoaderTypes(true);
    let data = new FormData();
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }
    if (channel != "Todos") {
      const channelToSend = channels.find((el) => el.canal == channel);
      data.append("channel", channelToSend.id);
    }

    const response = await graphAPI.salesTypeOfOrderEP(data);
    if (response.status == 200) {
      const aux = response.data.orders;
      if (response.data.total != 0) {
        const auxExcel = response.data.excel.map((el) => ({
          type: el.type,
          total: el.total.toString(),
        }));
        setDataTypeOfOrders([["Tipo", "Pedidos"], ...aux]);
        setExcelType(auxExcel);
      }
      setTotalTypeOfOrders(response.data.total);
      setLoaderTypes(false);
    }
  };

  const GetDataSalesChannel = async () => {
    setLoaderChannels(true);
    let data = new FormData();
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }
    if (channel != "Todos") {
      const channelToSend = channels.find((el) => el.canal == channel);
      data.append("channel", channelToSend.id);
    }

    const response = await graphAPI.salesByMarketPlaceEP(data);
    if (response.status == 200) {
      const aux = response.data.participation;

      if (response.data.total != 0) {
        const auxExcel = response.data.excel.map((el) => ({
          channel: el.channel,
          total: el.total.toString(),
        }));
        setDataSalesChannel([["Canal", "Ventas"], ...aux]);
        setExcelChannel(auxExcel);
        // setExcelChannel(response.data.excel);
      }

      setTotalSalesChannel(response.data.total);
      setLoaderChannels(false);
    }
  };

  const GetProducts = async () => {
    setLodaderSalesByDay(true);
    let data = new FormData();
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }
    if (channel != "Todos") {
      const channelToSend = channels.find((el) => el.canal == channel);
      data.append("channel", channelToSend.id);
    }

    const response = await graphAPI.productsCleanedEP(data);
    if (response.status == 200) {
      const aux = response.data.products;
      setProducts(aux);
      if (response.data.products.length != 0) {
        setIdProduct(aux[0].id);
        setProduct(aux[0].descripcion);
      }
      setLodaderSalesByDay(false);
    } else {
      console.log(response);
      setLodaderSalesByDay(false);
    }
  };

  //FORMATEAR FECHA A DD/MM/YYYY PARA GRÁFICA
  //------------------------------------------------

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, 0);
  };

  const formatDate = (date) => {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  };
  //------------------------------------------------

  const GetDataSalesByDay = async () => {
    setProductOk(true);
    let data = new FormData();
    data.append("product", idProduct);
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }
    if (channel != "Todos") {
      const channelToSend = channels.find((el) => el.canal == channel);
      data.append("channel", channelToSend.id);
    }

    const response = await graphAPI.salesByProductEP(data);
    if (response.status == 200) {
      const sales = response.data.sales;

      if (products.length != 0) {
        const auxExcel = response.data.excel.map((el) => ({
          date: el.date.join("/"),
          pieces: el.pieces.toString(),
          total: el.total.toString(),
        }));
        const arrWithDate = [...sales].map((el) => [
          formatDate(new Date(...el[0])),
          el[1],
          el[2],
        ]);
        setDataPiecesSoldByDay([["Día", "Piezas", "Monto"], ...arrWithDate]);
        setExcelByDay(auxExcel);
      }
      setProductOk(false);
    }
  };

  const GetDataSalesAndTops = async () => {
    setLoaderLess(true);
    setLoaderMore(true);
    setLoaderAll(true);

    let data = new FormData();
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }

    if (channel != "Todos") {
      const channelToSend = channels.find((el) => el.canal == channel);
      data.append("channel", channelToSend.id);
    }

    const response = await graphAPI.topSalesEP(data);
    if (response.status == 200) {
      const allSales = response.data.all;
      if (allSales.length != 0) {
        const auxAllExcel = response.data.all_excel.map((el) => ({
          decripcion: el.decripcion,
          sales: el.sales,
          total: el.total.toString(),
        }));
        //  sales formatting:
        allSales.forEach((el) => {
          const sales = el[2].replace("$", "");
          el[2] = `$ ${new Intl.NumberFormat("es-MX").format(sales)}`;
        });
        setDataPiecesSoldByProduct([
          ["Producto", "Piezas", { role: "annotation" }],
          ...allSales,
        ]);
        setExcelByProd(auxAllExcel);
        // setExcelByProd(response.data.all_excel);
      }

      const moreSales = response.data.more_sales;
      if (moreSales.length != 0) {
        const auxMoreExcel = response.data.more_sales_excel.map((el) => ({
          decripcion: el.decripcion,
          sales: el.sales,
          total: el.total.toString(),
        }));
        //  sales formatting:
        moreSales.forEach((el) => {
          const sales = el[2].replace("$", "");
          el[2] = `$ ${new Intl.NumberFormat("es-MX").format(sales)}`;
        });
        setDataTopSales([
          ["Producto", "Piezas", { role: "annotation" }],
          ...moreSales,
        ]);
        setExcelMore(auxMoreExcel);
        // setExcelMore(response.data.more_sales_excel);
      }

      const lessSales = response.data.less_sales;
      if (lessSales.length != 0) {
        const auxLessExcel = response.data.less_sales_excel.map((el) => ({
          decripcion: el.decripcion,
          sales: el.sales,
          total: el.total.toString(),
        }));
        //  sales formatting:
        lessSales.forEach((el) => {
          const sales = el[2].replace("$", "");
          el[2] = `$ ${new Intl.NumberFormat("es-MX").format(sales)}`;
        });
        setDataLessSales([
          ["Producto", "Piezas", { role: "annotation" }],
          ...lessSales,
        ]);
        setExcelLess(auxLessExcel);
        // setExcelLess(response.data.less_sales_excel);
      }

      setLoaderAll(false);
      setLoaderMore(false);
      setLoaderLess(false);
    }
  };

  const GetDataOrdersByWarehouse = async () => {
    setLoaderBranch(true);
    let data = new FormData();
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }

    if (channel != "Todos") {
      const channelToSend = channels.find((el) => el.canal == channel);
      data.append("channel", channelToSend.id);
    }

    const response = await graphAPI.ordersByWarehouseEP(data);
    if (response.status == 200) {
      const aux = response.data.warehousesOrders;

      if (aux.length != 0) {
        const auxExcel = response.data.excel.map((el) => ({
          warehouse: el.warehouse,
          total: el.total.toString(),
        }));
        setDataOrdersByWarehouse([["Sucursal", "Pedidos"], ...aux]);
        setExcelWhs(auxExcel);
        // setExcelWhs(response.data.excel);
      }
      setTotalOrdersByWarehouse(response.data.total);
      setLoaderBranch(false);
    }
  };

  const GetsalesByCity = async () => {
    setLodaderByCity(true);
    let data = new FormData();
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }
    if (channel != "Todos") {
      const channelToSend = channels.find((el) => el.canal == channel);
      data.append("channel", channelToSend.id);
    }

    const response = await graphAPI.salesByCityEP(data);
    if (response.status == 200) {
      const aux = response.data.sales;

      if (response.data.total != 0) {
        const auxExcel = response.data.excel.map((el) => ({
          city: el.city,
          total: el.total.toString(),
        }));
        setDataByCity([["Ciudad", "Pedidos"], ...aux]);
        setExcelByCity(auxExcel);
      }
      setTotalByCity(response.data.total);
      setLodaderByCity(false);
    }
  };

  const GetSalesDuringDay = async () => {
    setLoaderSalesDuringDay(true);
    let data = new FormData();
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }
    if (channel != "Todos") {
      const channelToSend = channels.find((el) => el.canal == channel);
      data.append("channel", channelToSend.id);
    }

    const response = await salesB2CAPI.salesDuringDayEP(data);
    if (response.status == 200) {
      const aux = response.data.orders_in_day;

      if (aux.length != 0) {
        const auxExcel = response.data.orders_in_day_excel.map((el) => ({
          day: el.day,
          morning: el.morning.toString(),
          afternoon: el.afternoon.toString(),
          night: el.night.toString(),
          total: el.total.toString(),
        }));
        setDataSalesDuringDay([
          ["Pedidos", "Día", "Tarde", "Noche", { role: "annotation" }],
          ...aux,
        ]);
        setExcelDuringDay(auxExcel);
        const map = aux.map((el) => {
          /* Obtener el valor máximo para que al abrir el modal no muestre solo la cnatidad de lineas del valor max */
          const firstEl = el.slice(1);
          return Math.max(...firstEl);
        });
        setMaxValueforDuringDay(Math.max(...map));
      }
      setLoaderSalesDuringDay(false);
    }
  };

  //------------------------------------------------

  //HANDLERS
  //------------------------------------------------

  // const handleOpenModal = () => setOpenFilter(true);
  // const handleCloseFilterModal = () => setOpenFilter(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleCloseFilterModal = () => setAnchorEl(null);

  const handleCloseModal = () => {
    // setOpenFilter(false)
    setAnchorEl(null);
    settingFilters(
      prevData.client,
      channel,
      prevData.startFilterDate,
      prevData.endFilterDate
    );
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  const handleChangeChannel = (e) => {
    setChannel(e.target.value);
  };

  const handleChangeClient = (e) => {
    setClient(e.target.value);
  };

  const handleFilter = () => {
    // const cleanedDateStart = `${startFilterDate.getFullYear()}`;
    // const cleanedDateEnd = `${endFilterDate.getFullYear()}`;

    if (endFilterDate < startFilterDate) {
      setErrors({ error: true });
    } else {
      handleCloseFilterModal();
      setErrors({
        error: false,
        errorNullStart: false,
        errorNullEnd: false,
      });
      RequestData();

      setPrevData({
        client: client,
        channel: channel,
        startFilterDate: startFilterDate,
        endFilterDate: endFilterDate,
      });
    }
  };

  const handleResetFilter = async () => {
    settingFilters("Todos", "Todos", firstDay, today);
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  //------------------------------------------------

  //FILTERS SETTING
  //------------------------------------------------

  const settingFilters = (client, channel, firstDay, endDay) => {
    setClient(client);
    setChannel(channel);
    setStartFilterDate(firstDay);
    setEndFilterDate(endDay);
  };

  const filterValues = {
    "Fecha inicio": getDayjsFormatted(startFilterDate),
    "Fecha fin": getDayjsFormatted(endFilterDate),
    Canal: channel != "Todos" ? channel : undefined,
    Cliente: client != "Todos" ? client : undefined,
  };
  //------------------------------------------------

  return (
    <>
      <div className="w-100 px-3 mb-3">
        <div
          className={`${
            phone ? "mt-1" : "mt-2"
          } d-flex justify-content-between`}
        >
          <DownloadButton
            client={client}
            text={""}
            htmlElement={htmlElement}
            fileName={"Reporte General Ventas"}
            excel={true}
            modal={false}
            sheetName={`Gráficas Ventas`}
            info={info}
          />
          {/* <FilterGraphs setStartFilterDate={setStartFilterDate} setEndFilterDate={setEndFilterDate} startFilterDate={startFilterDate} endFilterDate={endFilterDate}/> */}
          <div>
            <Tooltip title="Filtrar" arrow placement="left">
              {/* <IconButton onClick={handleOpenModal}> */}
              <IconButton onClick={handleClick}>
                <Badge
                  variant="dot"
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "lightgreen",
                      backgroundColor: theme.palette.primary.main,
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                    },
                  }}
                  // badgeContent={countFilter.filter(el => el == true).length}
                  color="primary"
                  invisible={invisible}
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                >
                  <img src={FilterIcon} width={"40px"} alt="Filtro" />
                </Badge>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 8, lg: 8 }}
            ref={htmlElement}
          >
            <ReportHeader
              title="Reporte General de Ventas"
              filters={filterValues}
            />
            <TypeOfOrdersGraph
              client={client}
              data={dataTypeOfOrders}
              isLoading={loaderTypes}
              total={totalTypeOfOrders}
            />
            <SalesChannelGraph
              client={client}
              data={dataSalesChannel}
              isLoading={loaderChannels}
              total={totalSalesChannel}
            />

            <PiecesSoldByDayGraph
              client={client}
              productOk={productOk}
              data={dataPiecesSoldByDay}
              isLoading={lodaderSalesByDay}
              setProduct={setProduct}
              products={products}
              product={product}
              setIdProduct={setIdProduct}
            />
            {/* Arreglando graph  */}
            <PiecesSoldByProductGraph
              client={client}
              data={dataPiecesSoldByProduct}
              isLoading={loaderAll}
            />
            <TopSalesGraph
              client={client}
              data={dataTopSales}
              isLoading={loaderMore}
            />
            <LessSalesGraph
              client={client}
              data={dataLessSales}
              isLoading={loaderLess}
            />
            <OrdersByWarehouseGraph
              client={client}
              data={dataOrdersByWarehouse}
              isLoading={loaderBranch}
              total={totalOrdersByWarehouse}
            />
            <OrdersByCityGraph
              client={client}
              data={dataByCity}
              isLoading={lodaderByCity}
              total={totalByCity}
            />
            <ByTimeGraph
              data={dataSalesDuringDay}
              isLoading={loaderSalesDuringDay}
              maxValue={maxValueforDuringDay}
            />

            <div className="d-none" id="client-data-lol"></div>
          </Grid>
        </div>
      </div>
      <>
        {/* <Modal open={openFilter} onClose={() => console.log("Cerrando")}> */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseFilterModal}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ModalFilters
            errors={errors}
            setErrors={setErrors}
            handleClose={handleCloseModal}
            dates={{
              setEnd: setEndFilterDate,
              end: endFilterDate,
              setStart: setStartFilterDate,
              start: startFilterDate,
            }}
            fields={[
              {
                array: clients,
                prop: "razon_social",
                type: "Cliente",
                value: client,
                handleOnChange: handleChangeClient,
              },
              {
                array: channels,
                prop: "canal",
                type: "Canal",
                value: channel,
                handleOnChange: handleChangeChannel,
              },
            ]}
            onClickFilter={handleFilter}
            onClickResetFilter={handleResetFilter}
          />
          {/* </Modal> */}
        </Popover>
      </>
    </>
  );
};
