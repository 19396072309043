import React, { useEffect, useState } from "react";

import { Divider, Grid, useMediaQuery } from "@mui/material";
import {
  PendingActions,
  AssignmentTurnedInOutlined,
  RuleOutlined,
} from "@mui/icons-material";
import { SkeletonCardEntry } from "./Skeleton/SkeletonCardEntry";
import { ListPagination } from "./Pagination/ListPagination";
import { CardTransfers } from "./CardTransfer";
import { getDateCleaned } from "../helpers/getDateCleaned";
import { roles } from "../helpers/enums";
import { useMemo } from "react";
import { GraphsAPI } from "../api/Graphs";

export const ListViewTransfer = ({
  listIndex,
  setListIndex,
  data,
  search,
  isLoading,
  type,
  setPage,
  page,
  total,
  count,
  setStatus,
  status,
  GetTransferList,
  client,
  clients,
  startFilterDate,
  endFilterDate,
}) => {
  const phone = useMediaQuery("(max-width:480px)");
  const [loadTransfer, setLoadTransfer] = useState(false);
  const [totales, setTotales] = useState({
    pending: "",
    validated: "",
    rejected: "",
  });

  useEffect(() => {
    GetDataTransfer();
  }, [startFilterDate, endFilterDate, client]);

  useEffect(() => {
    GetTransferList();
  }, [status]);

  useEffect(() => {
    GetTransferList();
  }, [search]);

  useEffect(() => {
    setListIndex(0);
    setStatus("En proceso");
  }, []);

  //apis

  const graphAPI = useMemo(() => new GraphsAPI(), []);

  const GetDataTransfer = async () => {
    setLoadTransfer(true);
    let data = new FormData();
    data.append("startDate", getDateCleaned(startFilterDate));
    data.append("endDate", getDateCleaned(endFilterDate));

    if (type == roles.ADMIN) {
      if (client != "Todos") {
        const auxClient = clients.find((el) => el.razon_social == client);
        data.append("client", auxClient.id);
      }
    }

    const response = await graphAPI.transferGraphEP(data);
    if (response.status == 200) {
      let aux = response.data.entries; /* Transfer */

      if (aux.length != 0) {
        setTotales({
          pending: aux[0][1],
          validated: aux[1][1],
          rejected: aux[2][1],
        });
      } else {
        setTotales({ pending: 0, validated: 0, rejected: 0 });
      }
      setLoadTransfer(false);
    } else {
      console.log(response);
    }
  };

  const pending = data.filter((transfer) => {
    return transfer.status == "En proceso";
  });

  const rejected = data.filter((transfer) => {
    return transfer.status == "Rechazada";
  });

  const validated = data.filter((transfer) => {
    return transfer.status == "Finalizada";
  });

  return (
    <>
      <div className="mt-3 bg-white w-100 p-2 r-15">
        <div
          className={`d-flex w-100 align-items-center ${
            phone ? "flex-column" : "justify-content-around"
          }`}
        >
          <div
            className={`w-100 p-2 cursor-pointer inactive-font ${
              listIndex == 0 && "active-chip bg-light-grey"
            }`}
            onClick={() => {
              setListIndex(0);
              setStatus("En proceso");
            }}
          >
            <div className="d-flex justify-content-center">
              <PendingActions />
            </div>
            <div className="d-flex justify-content-center">
              <p className="m-0 h6 text-center">
                En proceso (
                {!isLoading && !loadTransfer ? totales.pending : "..."})
              </p>
              {/* <p className='m-0 h6 text-center'>({pending.length})</p> */}
            </div>
          </div>
          <Divider orientation="vertical" flexItem className="mx-1" />
          <div
            className={`w-100 p-2 cursor-pointer inactive-font ${
              listIndex == 1 && "active-chip bg-green"
            }`}
            onClick={() => {
              setListIndex(1);
              setStatus("Completada");
            }}
          >
            <div className="d-flex justify-content-center">
              <AssignmentTurnedInOutlined />
            </div>
            <div className="d-flex justify-content-center">
              <p className="m-0 h6 text-center">
                Completada (
                {!isLoading && !loadTransfer ? totales.validated : "..."})
              </p>
              {/* <p className='m-0 h6 text-center'>({validated.length})</p> */}
            </div>
          </div>
          {/* <PriorityHighTwoTone /> */}
          <Divider orientation="vertical" flexItem className="mx-1" />
          <div
            className={`w-100 p-2 cursor-pointer inactive-font ${
              listIndex == 2 && "active-chip bg-light-blue"
            }`}
            onClick={() => {
              setListIndex(2);
              setStatus("Rechazada");
            }}
          >
            <div className="d-flex justify-content-center">
              <RuleOutlined />
            </div>
            <div className="d-flex justify-content-center">
              <p className="m-0 h6 text-center">
                Rechazada (
                {!isLoading && !loadTransfer ? totales.rejected : "..."})
              </p>
              {/* <p className='m-0 h6 text-center'>({rejected.length})</p> */}
            </div>
          </div>
          <div></div>
        </div>
      </div>

      <div className="mt-3 w-100 r-15">
        {isLoading ? (
          <Grid
            container
            alignItems="center"
            direction="row"
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
            columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
          >
            <SkeletonCardEntry num={10} rows={6} />
          </Grid>
        ) : (
          <>
            <Grid
              container
              alignItems="center"
              direction="row"
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
              columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
            >
              {listIndex == 0 &&
                status === "En proceso" &&
                pending.map((transfer) => (
                  <CardTransfers
                    info={transfer}
                    color={"#58BDDB"}
                    key={transfer.folio}
                    type={type}
                  />
                ))}
              {listIndex == 1 &&
                status === "Completada" &&
                validated.map((transfer) => (
                  <CardTransfers
                    info={transfer}
                    color={"#00e600"}
                    key={transfer.folio}
                    type={type}
                  />
                ))}
              {listIndex == 2 &&
                status === "Rechazada" &&
                rejected.map((transfer) => (
                  <CardTransfers
                    info={transfer}
                    color={"#7ec2e8"}
                    key={transfer.folio}
                    type={type}
                  />
                ))}
            </Grid>

            {data.length == 0 && search != "" ? (
              <div className="d-flex justify-content-center mt-2">
                No se encontraron resultados para "{search}"
              </div>
            ) : (
              data.length == 0 &&
              search == "" && (
                <div className="d-flex justify-content-center mt-2">
                  No tienes ventas aún.
                </div>
              )
            )}

            {/* {data.length == 0 && (
                            <div className="d-flex justify-content-center mt-2">
                                No tienes transferencias aún.
                            </div>
                        )} */}
            <ListPagination
              setPage={setPage}
              page={page}
              total={total}
              count={count}
              requestData={GetTransferList}
            />
          </>
        )}
      </div>
    </>
  );
};
