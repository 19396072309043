import React, { useRef } from "react";
import { AddPhotoAlternateOutlined, Cached, Close } from "@mui/icons-material";
import { CircularProgress, useMediaQuery } from "@mui/material";

export const InputImagePreview = ({
  handleChangeImage,
  imageFile,
  setImageFile,
  text,
  setFieldValue,
  errorImage,
  touchedImage,
  location,
  maxHeight,
  maxWidth,
  // disabled = false,
  // required = true
}) => {
  const phone = useMediaQuery("(max-width:480px)");

  const inputFile = useRef(null);

  const handleClickOpen = () => {
    inputFile.current.click();
  };

  const handleRemoveImage = () => {
    // inputFile.current.value = null
    setImageFile(null);
    setFieldValue(
      "imageFile",
      false
    ); /*Cambiar el valor de imageFile en Formik para condicional en image*/
    setFieldValue("photoLoaded", false);
  };

  return (
    <div className={`d-flex align-items-center w-100 flex-column`}>
      <div className={`w-100`}>
        <label className="no-wrap h6" htmlFor="image">
          {text}:
        </label>
      </div>
      <div className="w-100 d-flex flex-column align-items-center justify-content-center">
        <div
          className={`r-5 p-3 cursor-pointer' d-flex justify-content-center ${
            touchedImage && errorImage
              ? "input-red-border"
              : "input-grey-border"
          }`}
          style={{
            maxHeight: maxHeight,
            width: "100%",
          }}
        >
          {imageFile ? (
            <>
              <div className="d-flex flex-column justify-content-between">
                <div></div>
                <div className="d-flex justify-content-center">
                  {typeof imageFile == "object" ? (
                    <img
                      src={URL.createObjectURL(imageFile)}
                      alt="preview"
                      style={{
                        maxHeight: `calc(${maxHeight} - 50px)`,
                        maxWidth: maxWidth,
                      }}
                    />
                  ) : (
                    <img
                      src={imageFile}
                      alt="preview"
                      style={{
                        maxHeight: `calc(${maxHeight} - 50px)`,
                        maxWidth: maxWidth,
                      }}
                    />
                  )}
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <div
                    onClick={handleRemoveImage}
                    className="d-flex align-content-center cursor-pointer mr-2"
                  >
                    <Close />
                    <p className="m-0 ml-1">Quitar imagen</p>
                  </div>
                  <div
                    onClick={handleClickOpen}
                    className="d-flex align-content-center cursor-pointer"
                  >
                    <Cached /> <p className="m-0 ml-1">Cambiar imagen</p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                onClick={handleClickOpen}
                className="d-flex flex-column align-items-center justify-content-center"
              >
                <div
                  className={` ${
                    touchedImage && errorImage
                      ? "text-danger"
                      : "text-secondary"
                  }`}
                >
                  Da click aquí para subir una imagen
                </div>
                <div className="d-flex justify-content-center text-secondary">
                  <AddPhotoAlternateOutlined
                    sx={{ fontSize: 140 }}
                    color={`${
                      touchedImage && errorImage ? "error" : "inherit"
                    }`}
                  />
                </div>
              </div>
              {/* } */}
            </>
          )}
        </div>
        {touchedImage && errorImage ? (
          <div className="pl-3 mt-1 text-danger xs-font">{errorImage}</div>
        ) : null}
      </div>

      <input
        ref={inputFile}
        onChange={(e) => {
          handleChangeImage(e);
        }}
        id={"image"}
        type="file"
        name="image"
        accept="image/png, image/jpeg"
        style={{ display: "none" }}
      />
    </div>
  );
};
