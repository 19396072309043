import React, { useMemo, useState } from "react";
import {
  Grid,
  Paper,
  IconButton,
  Tooltip,
  Modal,
  useMediaQuery,
  Popover,
  Backdrop,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { DeleteForever, Visibility } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";

import { ModalAction } from "../Modal/ModalAction";
import { ModalSuccess } from "../Modal/ModalSuccess";
import { ModalError } from "../Modal/ModalError";

import LoaderDS from "../../assets/LoaderDS.svg";
import { SalesB2BAPI } from "../../api/SalesB2B";

import { roles } from "../../helpers/enums";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 10,
  minHeight: 220,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  // alignItems: "center"
}));

export const CardSalesB2B = ({ info, requestData }) => {
  /*************** STYLE STATES *****************/
  const phone = useMediaQuery("(max-width:480px)");

  const navigate = useNavigate();

  const rol = localStorage.getItem("rol");
  /*************** MODAL STATES *****************/

  const [openCancel, setOpenCancel] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const idPopover = openCancel ? "simple-popover" : undefined;

  /*************** MODAL HANDLERS *****************/

  const handleClick = (event, action) => {
    switch (action) {
      case "details":
        navigate("/cliente/ventas/B2B/detalle/", {
          state: info.id,
        });
        break;
      case "cancel":
        setAnchorEl(event.currentTarget);
        setOpenCancel(true);
        break;
      case "products":
        navigate("/cliente/ventas/B2B/productos/", {
          state: info,
        });
    }
  };

  const handleCloseModal = (modal) => {
    switch (modal) {
      case "success":
        setOpenSuccess(false);
        requestData();
        break;
      case "cancel":
        setOpenCancel(false);
        break;
      case "error":
        setOpenError(false);
        break;
    }
  };

  /*************** CANCEL SALE *****************/
  const api = useMemo(() => new SalesB2BAPI(), []);

  const cancelSale = async (orderCode) => {
    setOpenCancel(false);
    setIsLoading(true);
    const response = await api.cancelSaleEP({ order_code: orderCode });
    if (response.status == 200) {
      setOpenSuccess(true);
      setIsLoading(false);
    } else {
      // console.log(response);
      setOpenError(true);
      setErrorMsg(response.response.data.details);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
        <Item className={`mb-3 hover-card`}>
          <div className={`mx-2`}>
            <div className="d-flex justify-content-end">
              <Tooltip title={"Ver detalles"} arrow placement="top">
                <IconButton
                  sx={{
                    "&:hover": {
                      color: "blue",
                    },
                    padding: "0 1px 0 0",
                  }}
                  onClick={(e) => handleClick(e, "details")}
                  disabled={false}
                  className={`hover-icon`}
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
              {rol != roles.ADMIN && (
                <Tooltip title={"Cancelar"} arrow placement="top">
                  <IconButton
                    sx={{
                      "&:hover": {
                        color: "red",
                      },
                      padding: "0 1px 0 0",
                    }}
                    onClick={(e) => handleClick(e, "cancel")}
                    disabled={info.statusId != 3 ? false : true}
                    className={`ml-2 hover-icon`}
                  >
                    <DeleteForever />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <div className="font-weight-bold h6 ds-blue-font">
              {info.orderCode}
            </div>
            {rol == roles.ADMIN && (
              <div>
                {" "}
                Cliente: <span className="font-weight-bold">{info.client}</span>
              </div>
            )}
            <div>
              {" "}
              Referencia interna:{" "}
              <span className="font-weight-bold">{info.reference}</span>
            </div>
            <div>
              {" "}
              Fecha registro:{" "}
              <span className="font-weight-bold">{info.creationDate}</span>
            </div>
            <div>
              Estatus: <span className="font-weight-bold">{info.status}</span>
            </div>
            <div>
              Negocio: <span className="font-weight-bold">{info.business}</span>
            </div>
            {/* <div>
              Origen: <span className="font-weight-bold">{info.branch}</span>
            </div> */}
            <div>
              Destino:{" "}
              <span className="font-weight-bold">{info.businessLocation}</span>
            </div>
            <div>
              Monto: <span className="font-weight-bold">{info.amount}</span>
            </div>
          </div>
        </Item>
      </Grid>
      <Popover
        id={idPopover}
        open={openCancel}
        anchorEl={anchorEl}
        onClose={() => handleCloseModal("cancel")}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: !phone && 300 },
        }}
      >
        <ModalAction
          text={"Estás a punto de cancelar la venta"}
          object={info.orderCode}
          handleClose={() => handleCloseModal("cancel")}
          ejectAction={() => {
            cancelSale(info.orderCode);
          }}
        />
      </Popover>
      <Modal open={openSuccess}>
        <div>
          <ModalSuccess
            text={"La venta fue cancelada con éxito"}
            handleClose={() => handleCloseModal("success")}
          />
        </div>
      </Modal>
      <Modal open={openError}>
        <div>
          <ModalError
            text={"No se pudo cancelar la venta"}
            handleClose={() => handleCloseModal("error")}
            error={errorMsg}
          />
        </div>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
