import React, { useMemo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Typography,
  Box,
  useMediaQuery,
  IconButton,
  Grid,
  TextField,
  Modal,
  Backdrop,
} from "@mui/material";
import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";
import { Close } from "@mui/icons-material";
import { ProductsAPI } from "../../api/Products";
import { getCSRF } from "../../helpers/getCSRF";
import { useNavigate } from "react-router-dom";
import { ModalError } from "./ModalError";
import LoaderDS from "../../assets/LoaderDS.svg";

export const ModalSizesEntries = ({
  product,
  entryObj,
  CreateEntry,
  setErrorSizes,
}) => {
  const phone = useMediaQuery("(max-width:480px)");
  // const navigate = useNavigate();
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [openBD, setOpenBD] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "90%" : "45%",
    bgcolor: "background.paper",
    boxShadow: 24,
    // p: 1,
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 2,
    paddingRight: 2,
    borderRadius: 2,
  };

  // APIS
  const productAPI = useMemo(() => new ProductsAPI(), []);

  const EditProduct = async (obj) => {
    setOpenBD(true);
    let data = new FormData();

    data.append("id", product.id);
    data.append("sku", product.sku);
    data.append("descripcion", product.descripcion);
    data.append("codigo_barras", product.codigo_barras);
    data.append("precio_por_unidad", parseFloat(product.precio_por_unidad));
    data.append("categoria", product.categoria_id);
    data.append("image", product.imagen);
    data.append("peso", obj.weight);
    data.append("altura", obj.height);
    data.append("profundidad", obj.depth);
    data.append("ancho", obj.width);

    const response = await productAPI.editProductEP(data);
    if (response.status == 200) {
      CreateEntry(entryObj);
      setErrorSizes(false);
      setOpenBD(false);
    } else {
      setOpenBD(false);
      setOpenError(true);
      if (response.response.data.non_field_errors) {
        setErrorText(response.response.data.non_field_errors[0]);
      }
    }
  };

  const dimesions = [
    { keyName: "Peso (kg)", value: "weight", type: false },
    { keyName: "Alto (cm)", value: "height", type: false },
    { keyName: "Profundidad (cm)", value: "depth", type: false },
    { keyName: "Ancho (cm)", value: "width", type: false },
  ];
  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      weight: "",
      height: "",
      width: "",
      depth: "",
    },
    validationSchema: Yup.object({
      weight: Yup.number()
        .typeError("Debe ser un número")
        .required("Campo Obligatorio")
        .min(0, "Debe ser mayor a 0")
        .max(10000, "Debe ser un número menor a 10000")
        .transform((value, originalValue) =>
          /\s/.test(originalValue) ? NaN : value
        ) /*No acepta espacios vacíos */
        .test("len", "Máximo dos decimales", (val) => {
          if (val != undefined) {
            return /^\s*-?[1-9]\d*(\.\d{1,2})?\s*$/.test(val);
          }
          return true;
        }),
      height: Yup.number()
        .typeError("Debe ser un número")
        .required("Campo Obligatorio")
        .min(0, "Debe ser mayor a 0")
        .max(10000, "Debe ser un número menor a 10000")
        .transform((value, originalValue) =>
          /\s/.test(originalValue) ? NaN : value
        ) /*No acepta espacios vacíos */
        .test("len", "Máximo dos decimales", (val) => {
          if (val != undefined) {
            return /^\s*-?[1-9]\d*(\.\d{1,2})?\s*$/.test(val);
          }
          return true;
        }),
      depth: Yup.number()
        .typeError("Debe ser un número")
        .required("Campo Obligatorio")
        .min(0, "Debe ser mayor a 0")
        .max(10000, "Debe ser un número menor a 10000")
        .transform((value, originalValue) =>
          /\s/.test(originalValue) ? NaN : value
        ) /*No acepta espacios vacíos */
        .test("len", "Máximo dos decimales", (val) => {
          if (val != undefined) {
            return /^\s*-?[1-9]\d*(\.\d{1,2})?\s*$/.test(val);
          }
          return true;
        }),
      width: Yup.number()
        .typeError("Debe ser un número")
        .required("Campo Obligatorio")
        .min(0, "Debe ser mayor a 0")
        .max(1000000, "Debe ser un número menor a 1000000")
        .transform((value, originalValue) =>
          /\s/.test(originalValue) ? NaN : value
        ) /*No acepta espacios vacíos */
        .test("len", "Máximo dos decimales", (val) => {
          if (val != undefined) {
            return /^\s*-?[1-9]\d*(\.\d{1,2})?\s*$/.test(val);
          }
          return true;
        }),
    }),

    onSubmit: (values) => {
      const obj = {
        ...values,
      };
      EditProduct(obj);
    },
  });

  return (
    <>
      <Box sx={style}>
        <div className="d-flex justify-content-between align-items-center">
          <Typography
            variant="h5"
            className=" ds-blue-font"
            id="modal-modal-description"
          >
            Agregar medidas.
          </Typography>
          <IconButton
            onClick={() => {
              setErrorSizes(false);
            }}
          >
            <Close sx={{ fontSize: 30 }} />
          </IconButton>
        </div>

        <div className="text-center mt-2 mb-2">
          <Typography className="text-danger">
            Las medidas de <strong>{product.descripcion}</strong> son
            obligatorias para realizar el envío a almacén.
          </Typography>
        </div>

        {/* -------------------------------MEDIDAS------------------------------- */}
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}
            className="border p-3 r-15 bg-white"
          >
            {dimesions.map((dimension, i) => (
              <Grid key={i} item xs={6}>
                <div className={`d-flex flex-column px-2`}>
                  <div
                    className={`d-flex align-items-center mr-2 ${
                      phone && "mb-2"
                    }`}
                    style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                  >
                    <p className="no-wrap h6" htmlFor={dimension.value}>{`${
                      dimension.keyName
                    }: ${dimension.type ? "*" : ""}`}</p>
                  </div>
                  <div className="d-flex align-items-center w-100">
                    <div className="d-flex flex-column w-100">
                      <TextField
                        error={
                          formik.touched[dimension.value] &&
                          formik.errors[dimension.value]
                        }
                        size="small"
                        label={dimension.keyName}
                        variant="outlined"
                        fullWidth
                        onChange={(e) => formik.handleChange(e)}
                        value={formik.values[dimension.value]}
                        name={dimension.value}
                        id={dimension.value}
                        helperText={
                          formik.touched[dimension.value] &&
                          formik.errors[dimension.value] ? (
                            <div>{formik.errors[dimension.value]}</div>
                          ) : null
                        }
                      />
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>

          <div
            className={`d-flex mt-3 ${
              phone ? "flex-column" : "justify-content-around"
            }`}
          >
            <div className={` ${phone ? "mb-2 w-100" : "w-50"}`}>
              <CancelButton
                text={"Cancelar"}
                onClick={() => setErrorSizes(false)}
                width={phone ? "100%" : "90%"}
              />
            </div>
            <AcceptButton
              type={"submit"}
              text={"Envíar"}
              width={phone ? "100%" : "40%"}
            />
          </div>
        </form>
      </Box>

      <Modal pen={openError} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalError
            text={`Error al realizar el envío.`}
            handleClose={() => {
              setOpenError(false);
            }}
            error={errorText}
          />
        </div>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
