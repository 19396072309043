import React, { useState } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  tableCellClasses,
} from "@mui/material";

import { roles } from "../../helpers/enums";

import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";
import { ListPagination } from "../Pagination/ListPagination";
import { getDateCleaned } from "../../helpers/getDateCleaned";
import { MoreInfoButton } from "../Buttons/MoreInfoButton";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const TableInventory = ({
  search,
  data,
  type,
  setPage,
  page,
  total,
  requestData,
  count,
}) => {
  const { height, width } = useViewPortDimensions();

  const tRow = (row, index) => {
    const labelId = `enhanced-table-checkbox-${index}`;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        {type == roles.ADMIN && (
          <TableCell align="center">{row.client}</TableCell>
        )}
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          align="center"
        >
          {row.name}
        </TableCell>
        <TableCell align="center">{row.sku}</TableCell>
        <TableCell align="center">
          {row.barCode == "undefined" ? "S/N" : row.barCode}
        </TableCell>
        <TableCell align="center">
          {row.stock != null ? row.stock : ""}
        </TableCell>
        <TableCell align="center">
          {row.committed != null ? row.committed : ""}
        </TableCell>
        <TableCell align="center">
          {row.required != null ? row.required : ""}
        </TableCell>
        <TableCell align="center">
          {row.available != null ? row.available : ""}
        </TableCell>
        <TableCell align="center">
          {row.decrease != null ? row.decrease : 0}
        </TableCell>

        <TableCell align="center">{row.category}</TableCell>
        <TableCell align="center">{row.warehouse}</TableCell>
        <TableCell align="center">{row.update_date.slice(0, 10)}</TableCell>

        {/* <TableCell align="center">{row.update_date.slice(0, 10).split("-").reverse("").join("-")}</TableCell> */}
      </TableRow>
    );
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: height - (74 + 90 + 60) }}>
          <Table sx={{ minWidth: 750 }} aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow>
                {type == roles.ADMIN && (
                  <StyledTableCell align="center">Cliente</StyledTableCell>
                )}
                <StyledTableCell align="center">Nombre</StyledTableCell>
                <StyledTableCell align="center">SKU</StyledTableCell>
                <StyledTableCell align="center">
                  Código de barras
                </StyledTableCell>
                <StyledTableCell align="center" className="no-wrap">
                  Stock{" "}
                  <MoreInfoButton title="Suma de comprometido y disponible" />
                </StyledTableCell>
                <StyledTableCell align="center" className="no-wrap">
                  Comprometido{" "}
                  <MoreInfoButton title="Producto en preparación para envío al cliente" />
                </StyledTableCell>
                <StyledTableCell align="center" className="no-wrap">
                  Solicitado{" "}
                  <MoreInfoButton title="Producto en espera para abastecer a inventario" />
                </StyledTableCell>
                <StyledTableCell align="center" className="no-wrap">
                  Disponible{" "}
                  <MoreInfoButton title="Producto en existencia para venta" />
                </StyledTableCell>
                <StyledTableCell align="center" className="no-wrap">
                  Merma{" "}
                  <MoreInfoButton title="Producto caducado, dañado o perdido" />
                </StyledTableCell>
                <StyledTableCell align="center">Categoría</StyledTableCell>
                <StyledTableCell align="center">Sucursal</StyledTableCell>
                <StyledTableCell align="center">
                  Fecha de actualización
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
              {data.map((row, index) => tRow(row, index))}
            </TableBody>
          </Table>
          {data.length == 0 && search != "" ? (
            <div className="d-flex justify-content-center mt-2">
              No se encontraron resultados para "{search}"
            </div>
          ) : (
            data.length == 0 &&
            search == "" && (
              <div className="d-flex justify-content-center mt-2">
                No tienes ventas aún.
              </div>
            )
          )}
          {/* {
          data.length == 0 && ( 
          <div className="d-flex justify-content-center mt-2">
            No tienes inventario aún.
          </div>
          )
        } */}
        </TableContainer>
        <ListPagination
          setPage={setPage}
          page={page}
          total={total}
          requestData={requestData}
          count={count}
        />
      </Paper>
    </Box>
  );
};
