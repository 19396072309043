import React, { useState } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  styled,
  tableCellClasses,
} from "@mui/material";


import { EditTwoTone, Visibility } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";

import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";

import { roles } from "../../helpers/enums";

import { ListPagination } from "../Pagination/ListPagination";
import { ProductModal } from "../Modal/ProductModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));



export const TableProducts = ({
  search,
  data,
  type,
  setPage,
  page,
  total,
  requestData,
  count,
}) => {
  const { height, width } = useViewPortDimensions();
  const navigate = useNavigate();

  const [modalVisibility, setModalVisibility] = useState(false);
  const [dataProduct, setDataProduct] = useState({})

  const handleVisibility = (row) => {
    setDataProduct(row)
    setModalVisibility(!modalVisibility);
  }

  const handleClick = (row) => {
    console.log("esta es la row",row)
    navigate("/cliente/productos/editar/", {
      state: {
        id: row.id == null ? "" : row.id,
        name: row.name == null ? "" : row.name,
        description: row.descripcion == null ? "" : row.descripcion,
        sku: row.sku == null ? "" : row.sku,
        sku_seller: row.sku_seller == null ? "" : row.sku_seller,
        barCode: row.codigo_barras == null ? "" : row.codigo_barras,
        price: row.precio_por_unidad == null ? "" : row.precio_por_unidad,
        standard_price: row.standard_price == null ? "" : row.standard_price,
        category_id: row.categoria == null ? "" : row.categoria,
        subcategory: row.subcategory == null ? "" : row.subcategory,
        clase_articlo: row.clase_articlo == null ? "" : row.clase_articlo,
        weight: row.peso == null ? "" : row.peso,
        height: row.altura == null ? "" : row.altura,
        width: row.ancho == null ? "" : row.ancho,
        depth: row.profundidad == null ? "" : row.profundidad,
        image: row.imagen == null ? "" : row.imagen,
        imageFile: row.imagen == null ? false : true,
        perishable: row.perishable,
        can_serve_alone: row.can_serve_alone,
        is_alcoholic: row.is_alcoholic,
        is_vegetarian: row.is_vegetarian,
        use_time: row.use_time,
        removal_time: row.removal_time,
        expiration_time: row.expiration_time,
        alert_time: row.alert_time
      },
    });
  };

  const tRow = (row, index) => {
    const labelId = `enhanced-table-checkbox-${index}`;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        {type != roles.ADMIN && (
          <TableCell padding="checkbox">
            <Tooltip
              title={
                row.editable
                  ? "Editar"
                  : "No se puede editar porque tiene stock"
              }
              arrow
              placement="top"
            >
              <div className={`${row.editable && "hover-icon"}`}>
                <IconButton
                  onClick={() => handleClick(row)}
                  disabled={!row.editable}
                >
                  <EditTwoTone />
                </IconButton>
              </div>
            </Tooltip>
          </TableCell>
        )}
        {type == roles.ADMIN && (
          <TableCell align="center">{row.cliente}</TableCell>
        )}
        <TableCell padding="checkbox">
            <Tooltip
              title={
                row.editable
                  ? "Editar"
                  : "No se puede editar porque tiene stock"
              }
              arrow
              placement="top"
            >
              <div className={"hover-icon"}>
                <IconButton
                  onClick={() => handleVisibility(row)}

                >
                  <Visibility />
                </IconButton>
              </div>
            </Tooltip>
          </TableCell>
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          align="center"
        >
          {row.name}
        </TableCell>
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          align="center"
        >
          {row.descripcion.split(' ').slice(0, 6).join(' ')}{row.name.split(' ').length > 6 ? '...' : ''}

        </TableCell>
        <TableCell align="center">
          <img
            src={row.imagen}
            className={row.image && "img-thumbnail"}
            alt=""
            width={80}
            length={80}
          />
        </TableCell>
        <TableCell align="center">{row.sku}</TableCell>
        <TableCell align="center">{row.sku_seller}</TableCell>
        <TableCell align="center">{row.codigo_barras}</TableCell>
        <TableCell align="center">{row.precio_por_unidad}</TableCell>
        <TableCell align="center">
          {row.activo ? "Activo" : "Inactivo"}
        </TableCell>
        <TableCell align="center">
          {row.fecha_creacion.substring(0, 16)}
        </TableCell>
        <TableCell align="center">{row.categoria}</TableCell>
        <TableCell align="center">{row.peso}</TableCell>
        <TableCell align="center">
          {row.altura != null && row.altura != ""
            ? `${row.altura}x${row.ancho}x${row.profundidad}`
            : ""}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
    <ProductModal open={modalVisibility} handleClose={handleVisibility} product={dataProduct}/>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: height - (74 + 90 + 60) }}>
          {" "}
          {/*Para mantener la altura de las tablas */}
          <Table sx={{ minWidth: 750 }} aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow>
                {type == roles.CLIENT && <StyledTableCell></StyledTableCell>}
                {type == roles.ADMIN && (
                  <StyledTableCell align="center">Cliente</StyledTableCell>
                )}
                <StyledTableCell></StyledTableCell>
                <StyledTableCell align="center">Nombre</StyledTableCell>
                <StyledTableCell align="center">Descripción</StyledTableCell>
                <StyledTableCell align="center">Imagen</StyledTableCell>
                <StyledTableCell align="center">SKU</StyledTableCell>
                <StyledTableCell align="center">SKU Seller</StyledTableCell>
                <StyledTableCell align="center">
                  Código de barras
                </StyledTableCell>
                <StyledTableCell align="center">
                  Precio&nbsp;($)
                </StyledTableCell>
                <StyledTableCell align="center">Estatus</StyledTableCell>
                <StyledTableCell align="center" style={{ minWidth: 120 }}>
                  Fecha de registro
                </StyledTableCell>
                <StyledTableCell align="center">Categoría</StyledTableCell>
                <StyledTableCell align="center">Peso&nbsp;(kg)</StyledTableCell>
                <StyledTableCell align="center">Volúmen</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{data.map((row, index) => tRow(row, index))}</TableBody>
          </Table>
          {data.length == 0 && search != "" ? (
            <div className="d-flex justify-content-center mt-2">
              No se encontraron resultados para "{search}"
            </div>
          ) : (
            data.length == 0 &&
            search == "" && (
              <div className="d-flex justify-content-center mt-2">
                No tienes productos aún.
              </div>
            )
          )}
        </TableContainer>
        <ListPagination
          setPage={setPage}
          page={page}
          total={total}
          requestData={requestData}
          count={count}
        />
      </Paper>
    </Box>
  );
};
