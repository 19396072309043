import React, { useEffect, useRef, useState } from "react";

import {
  Badge,
  Grid,
  IconButton,
  Popover,
  Tooltip,
  useMediaQuery,
} from "@mui/material";

import { DownloadButton } from "../../components/Buttons/DownloadButton";
import { WarehousesInventoryGraph } from "../../components/Graphs/WarehousesInventoryGraph";
import { LessExistancesGraph } from "../../components/Graphs/LessExistancesGraph";
import { MoreExistancesGraph } from "../../components/Graphs/MoreExistancesGraph";
import { EntriesGraph } from "../../components/Graphs/EntriesGraph";

import { getDayjsFormatted } from "../../helpers/getDateCleaned";
import {
  graphBranchHeaders,
  graphEntriesHeaders,
  graphLessHeaders,
  graphMoreHeaders,
} from "../../components/Excel/headersGraphs";
import { FilterAlt } from "@mui/icons-material";
import { ModalFilters } from "../../components/Modal/ModalFilters";
import FilterIcon from "../../assets/SVGIcons/Filter.svg";
import { WarehouseAPI } from "../../api/Warehouse";
import { useMemo } from "react";
import { GraphsAPI } from "../../api/Graphs";
import { ReportHeader } from "./ReportHeader";

const styles = [
  [
    ["Entradas", "Cantidad", { role: "style" }],
    ["Por validar", 5, "#bdbebd"],
    ["Validadas", 10, "#89b737"],
    ["Incompletas", 2, "#7ec2e8"],
    ["Canceladas", 3, "#d9534f"],
  ],
];

const dummyDataLess = [
  ["x", "y"],
  ["1", 1],
  ["2", 2],
  ["3", 3],
  ["4", 4],
  ["5", 5],
];

const dummyDataWarehouses = [
  ["Almacen", "Cantidad"],
  ["Sin Stock", 1],
];

const dummyDataEntries = [
  [
    ["Entradas", "Cantidad"],
    ["Sin Entradas", 1],
  ],
  [
    ["Entradas", "Cantidad", { role: "style" }],
    ["Sin Entradas", 1, "#bdbebd"],
  ],
];

const dummyDataMore = [
  ["x", "y"],
  ["1", 5],
  ["2", 4],
  ["3", 3],
  ["4", 2],
  ["5", 1],
];

const dummyExcelWhs = [{ branch: "Sin datos", items: "0" }];

const dummyExcelMore = [
  { product: "Sin datos", description: "Sin productos", stock: "0" },
];

const dummyExcelLess = [
  { product: "Sin datos", description: "Sin productos", stock: "0" },
];

const dummyExcelEntries = [
  { type: "Por validar", total: "0" },
  { type: "Validado", total: "0" },
  { type: "Incompleto", total: "0" },
];

export const ReportsInventory = () => {
  const phone = useMediaQuery("(max-width:480px)");
  const htmlElement = useRef(null);

  //DATA STATES
  //------------------------------------------------

  const [dataMoreInventory, setDataMoreInventory] = useState([]);
  const [dataLessInventory, setDataLessInventory] = useState([]);
  const [dataMoreFullInventory, setDataMoreFullInventory] = useState([]);
  const [dataLessFullInventory, setDataLessFullInventory] = useState([]);
  const [dataEntries, setDataEntries] = useState([]);
  const [dataWarehouses, setDataWarehouses] = useState([]);
  const [totalWarehouses, setTotalWarehouses] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);

  //------------------------------------------------

  //ERRORES
  //------------------------------------------------

  const [errors, setErrors] = useState({
    error: false,
    errorNullStart: false,
    errorNullEnd: false,
  });

  //------------------------------------------------

  //MODAL STATES
  //------------------------------------------------
  const [openFilter, setOpenFilter] = useState(false);
  const [invisible, setInvisible] = useState(false);

  //------------------------------------------------

  //LOADERS STATES
  //------------------------------------------------

  const [loaderWarehouses, setLoaderWarehouses] = useState(true);
  const [loaderMore, setLoaderMore] = useState(true);
  const [loaderLess, setLoaderLess] = useState(true);
  const [loaderEntries, setLoaderEntries] = useState(true);

  //------------------------------------------------

  //FILTERS STATES
  //------------------------------------------------

  const [branch, setBranch] = useState("Todas");
  const [branches, setBranches] = useState([]);

  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

  const [startFilterDate, setStartFilterDate] = useState(firstDay);
  const [endFilterDate, setEndFilterDate] = useState(today);

  const [prevData, setPrevData] = useState({
    startFilterDate: firstDay,
    endFilterDate: today,
    branch: "",
  });

  //------------------------------------------------

  //EFFECTS
  //------------------------------------------------

  useEffect(() => {
    GetDataBranches();
    RequestData();
  }, []);
  //------------------------------------------------

  //EXCEL STATES AND DATA
  //------------------------------------------------

  const [excelWhs, setExcelWhs] = useState([]);
  const [excelMore, setExcelMore] = useState([]);
  const [excelLess, setExcelLess] = useState([]);
  const [excelEntries, setExcelEntries] = useState([]);

  const info = [
    {
      name: "Producto por sucursal",
      data: excelWhs,
      headers: graphBranchHeaders,
    },
    { name: "Maś stock", data: excelMore, headers: graphMoreHeaders },
    { name: "Menos stock", data: excelLess, headers: graphLessHeaders },
    { name: "Envíos", data: excelEntries, headers: graphEntriesHeaders },
  ];

  //------------------------------------------------

  //APIS
  //------------------------------------------------

  const graphAPI = useMemo(() => new GraphsAPI(), []);
  const warehouseAPI = useMemo(() => new WarehouseAPI(), []);

  //------------------------------------------------

  //DATA REQUEST
  //------------------------------------------------

  const RequestData = () => {
    GetWarehousesInventory();
    GetMoreInventory();
    GetLessInventory();
    GetDataEntries();
  };

  const GetDataBranches = async () => {
    let data = new FormData();

    const response = await graphAPI.branchesListEP(data);
    if (response.status == 200) {
      setBranches([{ id: 0, sucursal: "Todas" }, ...response.data.branches]);
      setPrevData({
        ...prevData,
        branch: [{ id: 0, sucursal: "Todas" }, ...response.data.branches],
      });
    } else {
      console.log(response);
    }
  };

  const GetWarehousesInventory = async () => {
    setLoaderWarehouses(true);

    let data = new FormData();

    if (branch != "Todas") {
      const branchToSend = branches.find((el) => el.sucursal == branch);
      data.append("branch", branchToSend.id);
    }

    const response = await graphAPI.inventoryWarehousesEP(data);
    if (response.status == 200) {
      const aux = response.data.warehouses;
      if (aux.length != 0) {
        const auxExcel = response.data.excel.map((el) => ({
          branch: el.branch,
          items: el.items.toString(),
        }));
        let aux4 = aux.map((el) => el[1]).reduce((a, b) => a + b);
        setDataWarehouses([["Almacen", "Cantidad"], ...aux]);
        setTotalWarehouses(aux4);
        setExcelWhs(auxExcel);
      } else {
        setTotalWarehouses(0);
      }

      setLoaderWarehouses(false);
    } else {
      console.log(response);
    }
  };

  const GetMoreInventory = async () => {
    setLoaderMore(true);

    const data = new FormData();

    if (branch != "Todas") {
      const branchToSend = branches.find((el) => el.sucursal == branch);
      data.append("branch", branchToSend.id);
    }

    const response = await graphAPI.moreStockEP(data);
    if (response.status == 200) {
      const aux = response.data.inventory.map((subarr) => [
        subarr[0],
        subarr[1],
        subarr[2].substring(0, 20),
      ]);
      let auxFull = response.data.inventory;

      if (aux.length != 0) {
        const auxExcel = response.data.excel.map((el) => ({
          product: el.product,
          description: el.description,
          stock: el.stock.toString(),
        }));
        setDataMoreInventory([
          ["Producto", "Stock", { role: "annotation" }],
          ...aux,
        ]);
        setDataMoreFullInventory([
          ["Producto", "Stock", { role: "annotation" }],
          ...auxFull,
        ]);
        setExcelMore(auxExcel);
        // setExcelMore(response.data.excel);
      }
      setLoaderMore(false);
    } else {
      console.log(response);
    }
  };

  const GetLessInventory = async () => {
    setLoaderLess(true);

    let data = new FormData();

    if (branch != "Todas") {
      const branchToSend = branches.find((el) => el.sucursal == branch);
      data.append("branch", branchToSend.id);
    }

    const response = await graphAPI.lessStockEP(data);
    if (response.status == 200) {
      const aux = response.data.inventory.map((subarr) => [
        subarr[0],
        subarr[1],
        subarr[2].substring(0, 20),
      ]);
      let auxFull = response.data.inventory;

      if (aux.length != 0) {
        const auxExcel = response.data.excel.map((el) => ({
          product: el.product,
          description: el.description,
          stock: el.stock.toString(),
        }));
        setDataLessInventory([
          ["Producto", "Stock", { role: "annotation" }],
          ...aux,
        ]);
        setDataLessFullInventory([
          ["Producto", "Stock", { role: "annotation" }],
          ...auxFull,
        ]);
        setExcelLess(auxExcel);
      }

      setLoaderLess(false);
    } else {
      console.log(response);
    }
  };

  const GetDataEntries = async () => {
    setLoaderEntries(true);
    let data = new FormData();
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    if (branch != "Todas") {
      const branchToSend = branches.find((el) => el.sucursal == branch);
      data.append("branch", branchToSend.id);
    }

    const response = await graphAPI.entriesGraphEP(data);
    if (response.status == 200) {
      let aux = response.data.entries;

      let aux2 = [...response.data.entries];
      let aux3 = aux2.map((element, i) => {
        return [element[0], element[1], styles[0][i + 1][2]];
      });
      if (aux.length != 0) {
        const auxExcel = response.data.excel.map((el) => ({
          type: el.type,
          total: el.total.toString(),
        }));

        let aux4 = aux.map((el) => el[1]).reduce((a, b) => a + b);
        setTotalEntries(aux4);
        setExcelEntries(auxExcel);
        // setExcelEntries(response.data.excel);
      } else {
        setTotalEntries(0);
      }
      setDataEntries([
        [["Entradas", "Cantidad"], ...aux],
        [["Entradas", "Cantidad", { role: "style" }], ...aux3],
      ]);
      setLoaderEntries(false);
    } else {
      console.log(response);
    }
  };

  //------------------------------------------------

  //HANDLERS
  //------------------------------------------------

  //------------------------------------------------

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleCloseFilterModal = () => setAnchorEl(null);

  const handleCloseModal = () => {
    // setOpenFilter(false)
    setAnchorEl(null);
    settingFilters(branch, prevData.startFilterDate, prevData.endFilterDate);
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  const handleChangeBranch = (e) => {
    setBranch(e.target.value);
  };

  const handleFilter = () => {
    if (endFilterDate < startFilterDate) {
      setErrors({ error: true });
    } else {
      handleCloseFilterModal();
      setErrors({
        error: false,
        errorNullStart: false,
        errorNullEnd: false,
      });
      RequestData();

      setPrevData({
        branch: branch,
        startFilterDate: startFilterDate,
        endFilterDate: endFilterDate,
      });
    }
  };

  const handleResetFilter = async () => {
    settingFilters("Todas", firstDay, today);
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  //FILTERS SETTING
  //------------------------------------------------

  const settingFilters = (branch, firstDay, endDay) => {
    setBranch(branch);
    setStartFilterDate(firstDay);
    setEndFilterDate(endDay);
  };

  //------------------------------------------------

  const filterValues = {
    "Fecha inicio": getDayjsFormatted(startFilterDate),
    "Fecha fin": getDayjsFormatted(endFilterDate),
    Almacén: branch != "Todas" ? branch : undefined,
  };

  return (
    <>
      <div className="w-100 px-3 mb-3">
        <div
          className={`${
            phone ? "mt-1" : "mt-2"
          } d-flex justify-content-between`}
        >
          <DownloadButton
            htmlElement={htmlElement}
            fileName={"Reporte General Inventario"}
            excel={true}
            modal={false}
            info={info}
            sheetName="Gráficas Inventario"
          />
          <div>
            <Tooltip title="Filtrar" arrow placement="left">
              <IconButton onClick={handleClick}>
                <Badge
                  variant="dot"
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "lightgreen",
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                    },
                  }}
                  invisible={invisible}
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                >
                  <img src={FilterIcon} width={"40px"} alt="Filtro" />
                </Badge>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 8, lg: 8 }}
            ref={htmlElement}
          >
            <ReportHeader
              title="Reporte General de Inventario"
              filters={filterValues}
            />
            <WarehousesInventoryGraph
              data={dataWarehouses}
              isLoading={loaderWarehouses}
              total={totalWarehouses}
              filters={filterValues}
            />
            <MoreExistancesGraph
              data={dataMoreInventory}
              dataFull={dataMoreFullInventory}
              isLoading={loaderMore}
              filters={filterValues}
            />
            <LessExistancesGraph
              data={dataLessInventory}
              dataFull={dataLessFullInventory}
              isLoading={loaderLess}
              filters={filterValues}
            />
            <EntriesGraph
              type={1}
              data={dataEntries}
              isLoading={loaderEntries}
              total={totalEntries}
              filters={filterValues}
            />
            <div className="d-none" id="client-data-lol"></div>
          </Grid>
        </div>
      </div>
      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseFilterModal}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ModalFilters
            errors={errors}
            setErrors={setErrors}
            handleClose={handleCloseModal}
            dates={{
              setEnd: setEndFilterDate,
              end: endFilterDate,
              setStart: setStartFilterDate,
              start: startFilterDate,
            }}
            fields={[
              {
                array: branches,
                prop: "sucursal",
                type: "Sucursal",
                value: branch,
                handleOnChange: handleChangeBranch,
              },
            ]}
            onClickFilter={handleFilter}
            onClickResetFilter={handleResetFilter}
          />
        </Popover>
      </>
    </>
  );
};
