import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  useMediaQuery,
  Grid,
  Modal,
  MenuItem,
  Typography,
  CircularProgress,
  Backdrop,
  Autocomplete,
} from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";

import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";

import { ModalError } from "../Modal/ModalError";

import { WarehouseAPI } from "../../api/Warehouse";
import { ProductsAPI } from "../../api/Products";
import { ModalWarningTransfer } from "../Modal/ModalWarningTransfers";
import LoaderDS from "../../assets/LoaderDS.svg";
import { TransferAPI } from "../../api/Transfer";
import { useMemo } from "react";
import { theme } from "../../utils/theme";

export const TransferForm = () => {
  const navigate = useNavigate();
  const phone = useMediaQuery("(max-width:480px)");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorField, setErrorField] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openBD, setOpenBD] = useState(false);
  const [products, setProducts] = useState([]);
  const [prodString, setProdString] = useState("");

  const [idProduct, setIdProduct] = useState(0);
  const [recoveryProduct, setRecoveryProduct] = useState(products[0]);
  const [pieces, setPieces] = useState(0);

  const [branches, setBranches] = useState([]);
  const [recoveryBranchId, setRecoveryBranchId] = useState(branches[0]);
  // const [branchesTotal, setBranchesTotal] = useState([]);
  const [cedis, setCedis] = useState([]);
  // const [cedisTotal, setCedisTotal] = useState([]);

  const [totalProducts, setTotalProducts] = useState(0);

  const [cedisList, setCedisList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [errorQuantity, setErrorQuantity] = useState(false);

  const [cediId, setCediId] = useState(0);
  const [branchId, setBranchId] = useState(0);

  const [checkDeparture, setCheckDeparture] = useState({
    cedi: false,
    branch: false,
  });
  const [checkArrive, setCheckArrive] = useState({
    cedi: false,
    branch: false,
  });

  //--------------------USEEFFECT--------------------
  //-------------------------------------------------

  useEffect(() => {
    GetProductsListCleaned();
  }, []);

  useEffect(() => {
    if (idProduct != 0) {
      GetProductWarehouses();
      GetBranchesList();
      GetCedisList();
    }
  }, [idProduct]);

  useEffect(() => {
    if (idProduct != 0) {
      getProductStock();
    }
  }, [cediId, branchId]);

  //-------------------------------------------------
  //-------------------------------------------------

  //--------------------HANDLES----------------------
  //-------------------------------------------------

  const handleCedi = (e) => setCediId(e.target.value);

  const handleBranch = (e) => {
    setBranchId(e.target.value);
  };

  const handleBranchId = (value) => {
    setRecoveryBranchId(value);
  };

  const handlePieces = (e) => {
    setPieces(e.target.value);
  };

  const handleClose = (path) => {
    if (path == "nuevo") {
      window.location.reload();
    } else {
      navigate(`/cliente/inventario/${path}/`);
    }
  };

  const handleProduct = (value) => {
    if (value != null) {
      setIdProduct(value.id);
      formik.setFieldValue("products", value.id);
      setRecoveryProduct(value);
      const filterProd = products.filter((item) => item.id == value.id);
      const mapProd = filterProd.map((el) => el.descripcion).toString();
      setProdString(mapProd);
    }
  };

  const handleDeparture = (e) => {
    if (e.target.value == 1) {
      setCheckDeparture({ cedi: true });
    } else {
      setCheckDeparture({ branch: true });
    }
  };

  const handleArrive = (e) => {
    if (e.target.value == 1) {
      setCheckArrive({ cedi: true });
    } else {
      setCheckArrive({ branch: true });
    }
  };

  //-------------------------------------------------
  //--------------------REQUESTS---------------------
  //-------------------------------------------------

  // APIS

  const productAPI = useMemo(() => new ProductsAPI(), []);
  const warehouseAPI = useMemo(() => new WarehouseAPI(), []);
  const transferAPI = useMemo(() => new TransferAPI(), []);

  const GetProductsListCleaned = async () => {
    setIsLoading(true);
    let data = new FormData();
    const response = await productAPI.productsListCleanedEP(data);
    if (response.status == 200) {
      setProducts(response.data.products);
    } else {
      console.log(response);
    }
  };

  const getProductStock = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("product", idProduct);

    if (cediId != 0) {
      data.append("branch", cediId);
    }
    if (branchId != 0) {
      data.append("branch", branchId);
    }

    const response = await productAPI.productStockEP(data);
    if (response.status == 200) {
      setTotalProducts(response.data.stock);
      setIsLoading(false);
    } else {
      console.log(response);
    }
  };

  const GetProductWarehouses = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("product", idProduct);

    if (cediId != 0) {
      data.append("branch", cediId);
    }
    if (branchId != 0) {
      data.append("branch", branchId);
    }

    const response = await productAPI.warehousesByProductEP(data);
    if (response.status == 200) {
      if (response.data.branches.length != 0) {
        setBranches(response.data.branches);
      } else {
        setBranches([{ id: 0, sucursal: "No hay Existencias" }]);
      }
      if (response.data.cedis.length != 0) {
        setCedis(response.data.cedis);
      } else {
        setCedis([{ id: 0, cedi: "No hay Existencias" }]);
      }
      setTotalProducts(response.data.total_stock);

      setIsLoading(false);
    } else {
      // console.log(response);
      setIsLoading(false);
    }
  };

  const GetCedisList = async () => {
    let data = new FormData();

    const response = await warehouseAPI.cedisList(data);
    if (response.status == 200) {
      setCedisList(response.data.cedis);
    } else {
      console.log(response);
    }
  };

  const GetBranchesList = async () => {
    let data = new FormData();

    const response = await warehouseAPI.getBranchesList(data);
    if (response.status == 200) {
      setBranchList(response.data.branches);
    } else {
      console.log(response);
    }
  };

  const CreateInventoryTransfer = async (obj) => {
    setOpenBD(true);
    let data = new FormData();

    data.append("product", idProduct);
    data.append(
      "departure_branch",
      obj.departure == 1 ? "" : obj.branchDeparture
    );
    data.append("departure_cedi", obj.departure == 2 ? "" : obj.cediDeparture);
    data.append("arrive_branch", obj.arrive == 1 ? "" : obj.branchArrive);
    data.append("arrive_cedi", obj.arrive == 2 ? "" : obj.cediArrive);
    data.append("amount", parseInt(obj.pieces));
    data.append("departure_type", obj.departure);
    data.append("arrive_type", obj.arrive);

    const response = await transferAPI.inventoryTransferEP(data);
    if (response.status == 200) {
      setOpenBD(false);
      setOpenSuccess(true);
    } else {
      // console.log(response);
      setOpenBD(false);
      setOpenError(true);
      if (response.response.data.non_field_errors) {
        setErrorField(response.response.data.non_field_errors[0]);
      }
    }
  };

  //-------------------------------------------------

  //FIELDS
  //-------------------------------------------------

  const departure = [
    { keyName: "Cedi", type: 1, value: true },
    { keyName: "Sucursal", type: 2, value: true },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      products: "",
      pieces: "",
      // totalProducts: "",

      branchDeparture: "",
      cediDeparture: "",

      branchArrive: "",
      cediArrive: "",

      departure: "",
      arrive: "",
    },

    validationSchema: Yup.object({
      products: Yup.string().required("Campo obligatorio"),
      pieces: Yup.number()
        .required("Campo obligatorio")
        .typeError("Debe ser un número")
        .min(1, "Debe ser un número mayor a 1")
        .integer("Debe ser número entero"),

      departure: Yup.string().required("Campo obligatorio"),
      arrive: Yup.string().required("Campo obligatorio"),
    }),
    onSubmit: (values) => {
      const obj = {
        ...values,
      };
      if (obj.pieces > totalProducts) {
        setErrorQuantity(true);
      } else {
        setErrorQuantity(false);
        CreateInventoryTransfer(obj);
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          rowSpacing={2}
          columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}
          className=""
        >
          {" "}
          {/*---Contenedor padre---*/}
          <Grid item xs={6}>
            {" "}
            {/*---Ocupara mitad de pantalla---*/}
            <Grid
              item
              xs={12}
              style={{ height: "100%" }}
              className={`border bg-white p-3 r-15`}
            >
              {" "}
              {/*---Altura de 100 para que ocupe verticalmente---*/}
              <div className="mb-4">
                <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
                  Detalles producto
                </p>
              </div>
              {idProduct != 0 ? (
                <div
                  className={`d-flex border-left border-warning pl-2 ml-3 bw-5 align-items-center ds-blue-font mb-4`}
                >
                  <Typography>Piezas disponibles:</Typography>

                  {isLoading ? (
                    <div className="w-25 ml-3">
                      <CircularProgress sx={{ color: theme.palette.primary.main }} size={20} />
                    </div>
                  ) : (
                    <div className="ml-1 font-weight-bold">
                      {totalProducts == 0 ? "Sin existencias" : totalProducts}
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className={`d-flex border-left border-warning pl-2 ml-3 bw-5 align-items-center mb-4 ds-blue-font`}
                >
                  <Typography>Selecciona un producto:</Typography>
                </div>
              )}
              <div
                key={"products"}
                className={` d-flex flex-column mb-3 ${
                  phone ? "px-2 " : "px-4"
                }`}
              >
                <div
                  className={`d-flex align-items-center mr-2 ${
                    phone && "mb-2"
                  }`}
                >
                  <p
                    className=" no-wrap h6"
                    htmlFor={"products"}
                  >{`${"Productos"}: ${"*"}`}</p>
                </div>

                <div className="d-flex align-items-center w-100">
                  <Autocomplete
                    noOptionsText={"Sin resultados"}
                    disablePortal
                    options={products}
                    value={recoveryProduct}
                    getOptionLabel={(option) => option.descripcion}
                    onChange={(event, value) => {
                      handleProduct(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Productos"
                        size="small"
                        fullWidth
                        name={"products"}
                        error={
                          formik.touched.products && formik.errors.products
                        }
                        helperText={
                          formik.touched.products && formik.errors.products ? (
                            <div>{formik.errors.products}</div>
                          ) : null
                        }
                      />
                    )}
                    fullWidth
                  />

                  {/* <TextField
                    select
                    size="small"
                    error={formik.touched.products && formik.errors.products}
                    label="Productos"
                    value={formik.values.products}
                    name={"products"}
                    id={"products"}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleProduct(e);
                    }}
                    fullWidth
                    helperText={
                      formik.touched.products && formik.errors.products ? (
                        <div>{formik.errors.products}</div>
                      ) : null
                    }
                  >

                    {products.map((product) => (
                      <MenuItem key={product.id} value={product.id}>
                        {product.descripcion}
                      </MenuItem>
                    ))}
                  </TextField> */}
                </div>
              </div>
              <div
                key={"Piezas"}
                className={` d-flex mb-3 flex-column ${
                  phone ? "px-2 " : "px-4"
                }`}
              >
                <div className={`d-flex align-items-center ${phone && "mb-2"}`}>
                  <label
                    className="no-wrap"
                    htmlFor={"pieces"}
                  >{`${"Cantidad"}:* `}</label>
                </div>
                <div className="d-flex flex-column align-items-center w-100">
                  <div className="d-flex flex-column w-100">
                    <TextField
                      error={formik.touched.pieces && formik.errors.pieces}
                      size="small"
                      label={"Piezas"}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        formik.handleChange(e);
                        handlePieces(e);
                      }}
                      value={formik.values.pieces}
                      name={"pieces"}
                      id={"pieces"}
                      helperText={
                        formik.touched.pieces && formik.errors.pieces ? (
                          <div>{formik.errors.pieces}</div>
                        ) : null
                      }
                    />
                  </div>
                  <div className="text-danger xs-font">
                    {errorQuantity
                      ? "Estás tratando de transferir más piezas de las disponibles"
                      : null}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12} className={`border bg-white p-3 r-15 `}>
              {/* <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}> */}

              <div className="mb-3">
                <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>Origen</p>
              </div>
              <div
                key={"salida"}
                className={`d-flex mb-3 flex-column ${
                  phone ? "px-2 " : "px-4"
                }`}
              >
                <div className={`d-flex ${phone && "mb-2"}`}>
                  <p
                    className="no-wrap h6"
                    htmlFor={"departure"}
                  >{`${"Salida"}: ${"*"}`}</p>
                </div>

                <div className="d-flex align-items-center w-100">
                  <TextField
                    select
                    size="small"
                    error={formik.touched.departure && formik.errors.departure}
                    label="Salida"
                    value={formik.values.departure}
                    name={"departure"}
                    id={"departure"}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleDeparture(e);
                    }}
                    fullWidth
                    helperText={
                      formik.touched.departure && formik.errors.departure ? (
                        <div>{formik.errors.departure}</div>
                      ) : null
                    }
                  >
                    {departure.map((dep) => (
                      <MenuItem key={dep.keyName} value={dep.type}>
                        {dep.keyName}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>

              {/* LISTA DE SUCURSALES */}

              <div
                key={"branchDeparture"}
                className={`d-flex flex-column align-items-end mb-3 ${
                  phone ? "px-2" : "px-4"
                }`}
              >
                {checkDeparture.cedi ? (
                  <div className={`d-flex w-100 `}>
                    <TextField
                      select
                      size="small"
                      error={
                        formik.touched.cediDeparture &&
                        formik.errors.cediDeparture
                      }
                      label="Cedi"
                      value={formik.values.cediDeparture}
                      name={"cediDeparture"}
                      id={"cediDeparture"}
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleCedi(e);
                      }}
                      fullWidth
                      helperText={
                        formik.touched.cediDeparture &&
                        formik.errors.cediDeparture ? (
                          <div>{formik.errors.cediDeparture}</div>
                        ) : null
                      }
                    >
                      {idProduct == 0 ? (
                        <MenuItem>Selecciona un producto</MenuItem>
                      ) : (
                        cedis.map((cedi) => (
                          <MenuItem key={cedi.id} value={cedi.id}>
                            {cedi.cedi}
                          </MenuItem>
                        ))
                      )}
                    </TextField>
                  </div>
                ) : null}

                {checkDeparture.branch ? (
                  <div className={`d-flex w-100 `}>
                    <TextField
                      select
                      size="small"
                      error={
                        formik.touched.branchDeparture &&
                        formik.errors.branchDeparture
                      }
                      label="Sucursal"
                      value={formik.values.branchDeparture}
                      name={"branchDeparture"}
                      id={"branchDeparture"}
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleBranch(e);
                      }}
                      fullWidth
                      helperText={
                        formik.touched.branchDeparture &&
                        formik.errors.branchDeparture ? (
                          <div>{formik.errors.branchDeparture}</div>
                        ) : null
                      }
                    >
                      {idProduct == 0 ? (
                        <MenuItem>Selecciona un producto</MenuItem>
                      ) : (
                        branches.map((branch) => (
                          <MenuItem key={branch.id} value={branch.id}>
                            {branch.sucursal}
                          </MenuItem>
                        ))
                      )}
                    </TextField>
                  </div>
                ) : null}
              </div>
              {/* </Grid> */}
            </Grid>

            <Grid item xs={12} className={`border bg-white p-3 r-15 mt-3 `}>
              {/* <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}> */}

              <div className="mb-3">
                <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>Destino</p>
              </div>

              <div
                // key={"arrive"}
                className={`d-flex mb-3 flex-column ${
                  phone ? "px-2 " : "px-4"
                }`}
              >
                <div className={`d-flex align-items-center ${phone && "mb-2"}`}>
                  <p
                    className="no-wrap h6"
                    htmlFor={"arrive"}
                  >{`${"Recepción"}: ${"*"}`}</p>
                </div>
                <div className=" d-flex align-items-center w-100">
                  <TextField
                    select
                    size="small"
                    error={formik.touched.arrive && formik.errors.arrive}
                    label="Recepción"
                    value={formik.values.arrive}
                    name={"arrive"}
                    id={"arrive"}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleArrive(e);
                    }}
                    fullWidth
                    helperText={
                      formik.touched.arrive && formik.errors.arrive ? (
                        <div>{formik.errors.arrive}</div>
                      ) : null
                    }
                  >
                    {/***  <Search /> ***/}

                    {departure.map((dep) => (
                      <MenuItem key={dep.keyName} value={dep.type}>
                        {dep.keyName}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
              {/* LISTA DE SUCURSALES */}
              <div
                // key={"branchArrive"}
                className={`d-flex flex-column align-items-end mb-3 ${
                  phone ? "px-2" : "px-4"
                }`}
              >
                {
                  // check.branchRec ? null : check.cediRec ? (
                  checkArrive.cedi ? (
                    <div className={`d-flex w-100`}>
                      <TextField
                        select
                        size="small"
                        error={
                          formik.touched.cediArrive && formik.errors.cediArrive
                        }
                        label="CEDI"
                        value={formik.values.cediArrive}
                        name={"cediArrive"}
                        id={"cediArrive"}
                        onChange={(e) => {
                          // handleCedisRec(e);
                          formik.handleChange(e);
                        }}
                        fullWidth
                        helperText={
                          formik.touched.cediArrive &&
                          formik.errors.cediArrive ? (
                            <div>{formik.errors.cediArrive}</div>
                          ) : null
                        }
                      >
                        {idProduct == 0 ? (
                          <MenuItem>Selecciona un producto</MenuItem>
                        ) : (
                          cedisList
                            .filter((cedi) => cedi.id != cediId)
                            .map((cedi) => (
                              <MenuItem key={cedi.id} value={cedi.id}>
                                {cedi.cedi}
                              </MenuItem>
                            ))
                        )}
                      </TextField>
                    </div>
                  ) : null
                }

                {
                  // check.cediRec ? null : check.branchRec ? (
                  checkArrive.branch ? (
                    <div className={`d-flex w-100`}>
                      {/* <Autocomplete
                        noOptionsText={'Sin resultados'}
                        disablePortal
                        options={branchList.filter(branch => branch.id != branchId)}
                        value={recoveryBranchId}
                        getOptionLabel={(option) => option.sucursal}
                        onChange={(event, value) => { handleBranchId(value); formik.setFieldValue("branchArrive", value.id) }}
                        renderInput={(params) =>
                          <TextField {...params}
                            label="Sucursal"
                            size="small"
                            fullWidth
                            name={"branchArrive"}
                            error={formik.touched.branchArrive && formik.errors.branchArrive}
                            helperText={
                              formik.touched.branchArrive && formik.errors.branchArrive ? (
                                <div>{formik.errors.branchArrive}</div>
                              ) : null
                            }
                          />
                        }
                        fullWidth
                      /> */}

                      <TextField
                        select
                        size="small"
                        error={
                          formik.touched.branchArrive &&
                          formik.errors.branchArrive
                        }
                        label="Sucursal"
                        value={formik.values.branchArrive}
                        name={"branchArrive"}
                        id={"branchArrive"}
                        onChange={(e) => {
                          // handleWhsRec(e);
                          formik.handleChange(e);
                        }}
                        fullWidth
                        helperText={
                          formik.touched.branchArrive &&
                          formik.errors.branchArrive ? (
                            <div>{formik.errors.branchArrive}</div>
                          ) : null
                        }
                      >
                        {idProduct == 0 ? (
                          <MenuItem>Selecciona un producto</MenuItem>
                        ) : (
                          branchList
                            .filter((branch) => branch.id != branchId)
                            .map((branch) => (
                              <MenuItem key={branch.id} value={branch.id}>
                                {branch.sucursal}
                              </MenuItem>
                            ))
                        )}
                      </TextField>
                    </div>
                  ) : null
                }
              </div>
            </Grid>
          </Grid>
        </Grid>

        <div
          className={`${
            !phone && "d-flex justify-content-end margin-right mt-2"
          }`}
        >
          <div className={`${phone && "mb-2"}`}>
            <CancelButton
              text={"Cancelar"}
              onClick={() => {
                navigate("/cliente/inventario/");
              }}
              width={phone ? "100%" : ""}
            />
          </div>
          <AcceptButton
            text={"Transferir"}
            type={"submit"}
            width={phone ? "100%" : ""}
          />
        </div>
      </form>

      <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalWarningTransfer
            handleClose={handleClose}
            product={prodString}
            pieces={pieces}
          />
        </div>
      </Modal>

      <Modal open={openError} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalError
            text={`No se pudo actualizar la información inténtalo después.`}
            handleClose={() => setOpenError(false)}
            error={errorField}
          />
        </div>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
