import React, { useState, useContext } from "react";
import { BusinessContext } from "../../context/BusinessContext";
import {
  Grid,
  Paper,
  IconButton,
  Tooltip,
  Modal,
  useMediaQuery,
  Popover,
  Backdrop,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ToggleOffOutlined, ToggleOn } from "@mui/icons-material";

import { EditTwoTone } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";

import { ModalAction } from "../Modal/ModalAction";
import { ModalSuccess } from "../Modal/ModalSuccess";
import { ModalError } from "../Modal/ModalError";

import LoaderDS from "../../assets/LoaderDS.svg";

import { roles } from "../../helpers/enums";
import { useMemo } from "react";
import { SalesB2BAPI } from "../../api/SalesB2B";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 10,
}));

export const CardListView = ({ info, requestData }) => {
  /*************** STYLE STATES *****************/
  const phone = useMediaQuery("(max-width:480px)");

  const navigate = useNavigate();

  const rol = localStorage.getItem("rol");
  /*************** MODAL STATES *****************/

  const [openDesactivate, setOpenDesactivate] = useState(false);
  const [openActivate, setOpenActivate] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [business, setBusiness] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const idPopover = openDesactivate ? "simple-popover" : undefined;

  /*************** REQUEST ACTIONS *****************/

  const api = useMemo(() => new SalesB2BAPI(), []);

  const desactivateBusiness = async () => {
    setIsLoading(true);
    let data = new FormData();

    data.append("business", business.id);
    data.append("status", false);

    const response = await api.deleteBusinessEP(data);
    if (response.status == 200) {
      setOpenSuccess(true);
      setIsLoading(false);
    } else {
      setOpenError(true);
      setErrorMsg(response.response.data.detail);
      setIsLoading(false);
    }
  };

  const activateBusiness = async () => {
    setIsLoading(true);
    let data = new FormData();

    data.append("business", business.id);
    data.append("status", true);

    const response = await api.deleteBusinessEP(data);
    if (response.status == 200) {
      setOpenSuccess(true);
      setIsLoading(false);
    } else {
      setOpenError(true);
      setErrorMsg(response.response.data.detail);
      setIsLoading(false);
    }
  };

  /*************** MODAL HANDLERS *****************/

  const handleCloseModal = (modal) => {
    switch (modal) {
      case "success":
        setOpenSuccess(false);
        requestData();
        break;
      case "desactivate":
        setOpenDesactivate(false);
        break;
      case "activate":
        setOpenActivate(false);
        break;
      case "error":
        setOpenError(false);
        break;
    }
  };

  const handleClick = (event, action, info) => {
    setBusiness(info);
    switch (action) {
      case "edit":
        navigate("/cliente/ventas/B2B/negocios/nuevo/", {
          state: { business: info },
        });
        break;
      case "desactivate":
        setAnchorEl(event.currentTarget);
        setOpenDesactivate(true);
        break;

      case "activate":
        setAnchorEl(event.currentTarget);
        setOpenActivate(true);
        break;

      case "detail":
        if (rol == roles.ADMIN) {
          navigate("/administrador/ventas/B2B/negocios/detalle/", {
            state: { ...info },
          });
        } else {
          navigate("/cliente/ventas/B2B/negocios/detalle/", {
            state: { ...info },
          });
        }

        break;
    }
  };

  return (
    <>
      <Grid item xs={6} sm={3} md={3} lg={3} xl={2.4}>
        <Item className={`mb-3 text-break ${info.status == true && "hover-card"}`}>
          <div className={`mx-2 ${info.status == false && "inactive-font"}`}>
            {rol != roles.ADMIN && (
              <div className="d-flex justify-content-end">
                {info.status == true ? (
                  <Tooltip title="Desactivar" arrow placement="top">
                    <IconButton
                      sx={{ padding: 0, "&:hover": { color: "red" } }}
                      onClick={(e) => handleClick(e, "desactivate", info)}
                      disabled={false}
                      className={"hover-icon"}
                    >
                      <ToggleOn sx={{ fontSize: 25 }} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Activar" arrow placement="top">
                    <IconButton
                      sx={{ padding: 0, "&:hover": { color: "green" } }}
                      onClick={(e) => handleClick(e, "activate", info)}
                      disabled={false}
                      className={"hover-icon"}
                    >
                      <ToggleOffOutlined sx={{ fontSize: 25 }} />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Editar" arrow placement="top">
                  <IconButton
                    sx={{ padding: 0, "&:hover": { color: "green" } }}
                    onClick={(e) => handleClick(e, "edit", info)}
                    disabled={info.status == true ? false : true}
                    className={`ml-2 ${info.status == true && "hover-icon"}`}
                  >
                    <EditTwoTone sx={{ fontSize: 25 }} />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            <div className="mb-1">
              <span
                className={`font-weight-bold h6 ${
                  info.status == true
                    ? "cursor-pointer hover-link-yellow transition-2 ds-blue-font "
                    : "inactive-font"
                }`}
                onClick={(e) => handleClick(e, "detail", info)}
              >
                {info.business}
              </span>
            </div>
            {rol == roles.ADMIN && (
              <div>
                {" "}
                Cliente: <span className="font-weight-bold">{info.client}</span>
              </div>
            )}{" "}
            <div>
              Régimen fiscal:{" "}
              <span className="font-weight-bold"> {info.regime_type} </span>
            </div>
            <div>
              Estatus:{" "}
              <span className="font-weight-bold">
                {" "}
                {info.status == true ? "Activo" : "Inactivo"}
              </span>
            </div>
            {/* <div>
              Última milla:{" "}
              <span className="font-weight-bold">
                {" "}
                {info.last_mile == true ? "Day Store" : "Otra"}
              </span>
            </div> */}
          </div>
        </Item>
      </Grid>

      <Popover
        id={idPopover}
        open={openDesactivate}
        anchorEl={anchorEl}
        onClose={() => handleCloseModal("desactivate")}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: !phone && 350 },
        }}
      >
        <ModalAction
          text={"Estás a punto de desactivar el negocio"}
          object={business.business}
          handleClose={() => handleCloseModal("desactivate")}
          ejectAction={() => {
            desactivateBusiness(business.id);
          }}
        />
      </Popover>
      <Popover
        id={idPopover}
        open={openActivate}
        anchorEl={anchorEl}
        onClose={() => handleCloseModal("activate")}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: !phone && 350 },
        }}
      >
        <ModalAction
          text={"Estás a punto de reactivar el negocio"}
          object={business.business}
          handleClose={() => handleCloseModal("activate")}
          ejectAction={() => {
            activateBusiness(business.id);
          }}
        />
      </Popover>
      <Modal open={openSuccess}>
        <div>
          <ModalSuccess
            text={
              business.status == true
                ? "El negocio fue desactivado con éxito"
                : "El negocio fue reactivado con éxito"
            }
            handleClose={() => handleCloseModal("success")}
          />
        </div>
      </Modal>
      <Modal open={openError}>
        <div>
          <ModalError
            text={
              business.status == true
                ? "No pudimos desactivar el negocio"
                : "No pudimos reactivar el negocio"
            }
            handleClose={() => handleCloseModal("error")}
            error={errorMsg}
          />
        </div>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
