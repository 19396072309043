import { HttpRequest } from "./HttpRequest";
import { getHost } from "../helpers/getHost";

export class NotificationsAPI {
  constructor() {
    this.http = new HttpRequest();
    this.host = getHost();
    this.token = localStorage.getItem("token");
    this.config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${this.token}`,
      },
    };
  }

  getNotificationsEP(data) {
    return this.http.post(
      `${this.host}/cliente/ws/getNotifications/`,
      data,
      this.config
    );
  }

  updateNotificationEP(data) {
    return this.http.post(
      `${this.host}/cliente/ws/updateNotification/`,
      data,
      this.config
    );
  }
}
