import {
  Backdrop,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Popover,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import {
  DeleteForever,
  EditTwoTone,
  Email,
  KeyboardArrowDown,
  LocalPhone,
  LocationOn,
  Person,
  QueryBuilder,
  ToggleOffOutlined,
  ToggleOn,
  WatchLater,
} from "@mui/icons-material";
import { ModalAction } from "../Modal/ModalAction";
import { ModalSuccess } from "../Modal/ModalSuccess";
import { ModalError } from "../Modal/ModalError";
import LoaderDS from "../../assets/LoaderDS.svg";
import { useContext } from "react";
import { BusinessContext } from "../../context/BusinessContext";
import { useNavigate } from "react-router";
import { useMemo } from "react";
import { SalesB2BAPI } from "../../api/SalesB2B";
import { roles } from "../../helpers/enums";

export function LocationCard({
  data,
  locationOpened,
  openLocation,
  date,
  open,
}) {
  const phone = useMediaQuery("(max-width:600px)");

  const time = `${date.getHours() < 10 ? 0 : ""}${date.getHours()}:${
    date.getMinutes() < 10 ? 0 : ""
  }${date.getMinutes()}`;

  const rol = localStorage.getItem("rol");

  /************** MODAL STATES *******************/
  const [isLoading, setIsLoading] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [desactivate, setDesactivate] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openDelete = Boolean(anchorEl);
  const idPopover = openDelete ? "simple-popover" : undefined;
  const navigate = useNavigate();

  /************** ACTIONS BUTTONS *******************/
  const handleClick = (event, action) => {
    switch (action) {
      case "edit":
        navigate("/cliente/ventas/B2B/negocios/agregar_ubicacion/", {
          state: { business: { id: data.business }, location: data },
        });
        break;
      case "desactivate":
        setAnchorEl(event.currentTarget);
        setDesactivate(true);
        break;
      case "activate":
        setAnchorEl(event.currentTarget);
        setDesactivate(false);
        break;
    }
  };

  const handleCloseModal = (modal) => {
    switch (modal) {
      case "success":
        getLocations(data.business);
        setOpenSuccess(false);
        break;
      case "desactivate":
        setAnchorEl(null);
        break;
    }
  };

  /************** DATA STATES AND FUNCTIONS *******************/
  const { getLocations } = useContext(BusinessContext);

  const api = useMemo(() => new SalesB2BAPI(), []);

  const desactivateLocation = async (id, status) => {
    setIsLoading(true);
    const data = new FormData();
    data.append("location", id);
    data.append("status", status);
    const response = await api.desactivateLocationEP(data);
    if (response.status == 200) {
      setOpenSuccess(true);
      setIsLoading(false);
      if (open) {
        openLocation();
      }
    } else {
      // console.log(response);
      setOpenError(true);
      setErrorText(response.response.data.detail);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <ListItemButton
          disabled={data.status ? false : true}
          id={data.id}
          className="p-1"
        >
          <ListItemIcon className="mr-2" sx={{ minWidth: 0 }}>
            <KeyboardArrowDown
              sx={{
                transform:
                  open && locationOpened.id == data.id
                    ? "rotate(-180deg)"
                    : "rotate(0)",
                transition: "0.5s",
              }}
              className={
                open && locationOpened.id == data.id
                  ? "ds-ref-font"
                  : "ds-yellow-font"
              }
              onClick={() => openLocation()}
            />
          </ListItemIcon>
          <ListItemText
            primary={data.alias}
            className="ds-blue-font font-weight-bold"
            onClick={() => openLocation()}
          />
        </ListItemButton>
        {rol == roles.CLIENT ? (
          data.status == true ? (
            <Tooltip title="Desactivar" arrow placement="top">
              <IconButton
                sx={{
                  "&:hover": { color: "red" },
                  padding: "0 1px 0 0",
                }}
                onClick={(e) => handleClick(e, "desactivate")}
                disabled={false}
                className={"hover-icon mx-1"}
              >
                <ToggleOn fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Activar" arrow placement="top">
              <IconButton
                sx={{
                  "&:hover": { color: "green" },
                  padding: "0 1px 0 0",
                }}
                onClick={(e) => handleClick(e, "activate")}
                disabled={false}
                className={"hover-icon mx-1"}
              >
                <ToggleOffOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          )
        ) : null}
      </div>

      <Collapse
        in={open && locationOpened.id == data.id}
        unmountOnExit
        className="mx-2 mb-1"
      >
        <div className="d-flex gray-font mb-1">
          <LocationOn sx={{ fontSize: 13 }} className="mt-1" />
          <p className="m-0 ml-3 xs-font">
            {`${data.street} ${data.number}, col. ${data.neighborhood}, CP ${data.postal_code}, ${data.municipality}`}
          </p>
        </div>
        <div className="d-flex gray-font align-items-center mb-1">
          <WatchLater sx={{ fontSize: 13 }} />
          <p className="m-0 ml-3 xs-font">
            {time >= data.open.substring(0, 5) &&
            time <= data.close.substring(0, 5) ? (
              <span className="ds-green-font">Abierto</span>
            ) : (
              <span className="ds-ref-font ">Cerrado</span>
            )}
            {` ${data.open.substring(0, 5)} - ${data.close.substring(0, 5)}`}
          </p>
        </div>
        <div className="d-flex gray-font align-items-center mb-1">
          <Person sx={{ fontSize: 13 }} />
          <p className="m-0 ml-3 xs-font">
            {`${data.first_name} ${data.last_name}`}
          </p>
        </div>
        <div className="d-flex gray-font align-items-center mb-1">
          <Email sx={{ fontSize: 13 }} />
          <p className="m-0 ml-3 xs-font">{`${data.email}`}</p>
        </div>
        <div className="d-flex gray-font align-items-center">
          <LocalPhone sx={{ fontSize: 13 }} />
          <p className="m-0 ml-3 xs-font">{`${data.cell_phone}`}</p>
        </div>
        {rol == roles.CLIENT && (
          <div className="d-flex justify-content-end">
            <Tooltip title="Editar" arrow placement="top">
              <IconButton
                sx={{
                  "&:hover": { color: "green" },
                  padding: "0 1px 0 0",
                }}
                onClick={(e) => handleClick(e, "edit")}
                disabled={false}
                className={"hover-icon"}
              >
                <EditTwoTone fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </Collapse>
      <Divider />
      <Popover
        id={idPopover}
        open={openDelete}
        anchorEl={anchorEl}
        onClose={() => handleCloseModal("desactivate")}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: !phone && 350 },
        }}
      >
        <ModalAction
          text={`¿Estás seguro que deseas ${
            desactivate ? "desactivar" : "activar"
          } la ubicación`}
          object={data.alias}
          handleClose={() => handleCloseModal("desactivate")}
          ejectAction={() =>
            desactivateLocation(data.id, desactivate ? false : true)
          }
        />
      </Popover>
      <Modal open={openSuccess}>
        <div>
          <ModalSuccess
            handleClose={() => handleCloseModal("success")}
            text={`La ubicación fue ${
              desactivate ? "desactivada" : "activada"
            } con éxito`}
          />
        </div>
      </Modal>

      <Modal open={openError}>
        <div>
          <ModalError
            text={`No se pudo eliminar la ubicación.`}
            handleClose={() => setOpenError(false)}
            error={errorText}
          />
        </div>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
}
