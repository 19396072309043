import React from 'react'

import { Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { theme } from '../../utils/theme';

export const ChangeDocButton = ({ text, onClick, target, border, font, component, href }) => {

    const ChangeDocStyled = styled(Button)({
        
        boxShadow: '0',
        textTransform: 'none',
        fontSize: 14,
        
        backgroundColor: 'transparent',
        color: theme.palette.secondary.main,
        // '#575757',
        textAlign: "center",
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&:active': {
            
        },
        '&:focus': {
        },
    });


    return (
        <ChangeDocStyled target={target} component={component} href={href} variant="text" onClick={onClick} disableElevation >
            <ChangeCircleIcon />
        </ChangeDocStyled>
    )
}
