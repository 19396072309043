import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Grid,
  IconButton,
  Popover,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { SnackbarProvider, useSnackbar } from "notistack";
import { Sidebar } from "../components/Sidebar/Sidebar";
import MenuIcon from "@mui/icons-material/Menu";
import { BreadcrumbsMenu } from "../components/Breadcrumb/BreadcrumbsMenu";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ProfileIcon from "../assets/SVGIcons/Profile.svg";
import { ModalMenuAccount } from "../components/Modal/ModalMenuAccount";
import { ModalNotification } from "../components/Modal/ModalNotification";
import { HistoricalSalesSucursal } from "./pages/HistoricalSalesSucursal";
import { AccountCardsSucursal } from "./pages/AccountCardsSucursal";
import { ActiveSales } from "./pages/ActiveSales";
import { EditPasswordSucursal } from "./pages/EditPasswordSucursal";
import {
  CheckCircle,
  Close,
  Error,
  KeyboardArrowDownRounded,
  ReportProblem,
} from "@mui/icons-material";
import SnackbarAudio from "../sounds/SnackbarAudioSucursal.mp3";
import { getSocketHost } from "../helpers/getHost";
import { AppBar, DrawerHeader, DrawerStyled } from "../utils/menuStyles";
import { sectionsSucursal } from "../utils/menuSections";
import { theme } from "../utils/theme";

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-contentRoot {
    height: 20vh;
    display: flex;
    min-width: 65vw;
    flex-wrap: nowrap;
  }
`;

const LayoutSucursal = ({ variant, setVariant }) => {
  const { pathname } = useLocation();
  const tablet = useMediaQuery("(max-width:600px)");
  const phone = useMediaQuery("(max-width:600px)");
  const breakpointBC = useMediaQuery("(max-width:630px)");
  const admin = localStorage.getItem("nombre");
  const [isLoading, setIsLoading] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [notification, setNotification] = useState(false);

  const handleClick = (event, notification) => {
    setAnchorEl(event.currentTarget);
    setNotification(notification);
  };
  const handleClose = () => setAnchorEl(null);

  const openPO = Boolean(anchorEl);
  const id = openPO ? "simple-popover" : undefined;

  const [total, setTotal] = useState(0);
  const [notifications, setNotifications] = useState({
    e_sales: [],
    inventory_receipt: [],
    inventory_status: [],
    inventory_transfer: [],
  });
  const [loaderNotifications, setLoaderNotifications] = useState(false);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleDrawerToggle = () => {
    tablet && setMobileOpen(!mobileOpen);
    setOpen(true);
  };

  const [socketMsg, setSocketMsg] = useState({
    msg: "",
    orderId: 0,
  });
  const [socketReload, setSocketReload] = useState(0);
  const [icon, setIcon] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const variants = [
    {
      id: 1,
      variant: "success",
      text: "Se generó una venta en ",
      icon: <CheckCircle sx={{ fontSize: 50 }} />,
    },
    {
      id: 2,
      variant: "warning",
      text: "Orden no generada en ",
      icon: <ReportProblem sx={{ fontSize: 50 }} />,
    },
    {
      id: 3,
      variant: "error",
      text: "Orden cancelada en ",
      icon: <Error sx={{ fontSize: 50 }} />,
    },
  ];

  const socketUrl = getSocketHost();

  useEffect(() => {
    const token = `${localStorage.getItem("token")}`;
    const socket = new WebSocket(`${socketUrl}/wss/sucursal/${token}`);

    socket.onopen = () => {
      // console.log("connection")
    };
    socket.onclose = () => {
      // console.log('connection closed')
    };

    socket.onmessage = ({ data }) => {
      const response = JSON.parse(data);
      switch (response.type) {
        case 3:
          switch (response.event) {
            case 1:
              setVariant(
                variants.find((el) => el.id == response.status).variant
              );
              setIcon(variants.find((el) => el.id == response.status).icon);
              setSocketMsg({
                msg: `${variants.find((el) => el.id == response.status).text} ${
                  response.origin
                }. Código de pedido: ${response.code}`,
                orderId: response.details.orderId,
              });
              setSocketReload(new Date(response.timestamp));
              break;

            case 2:
              setVariant("success");
              setIcon(<CheckCircle sx={{ fontSize: 50 }} />);
              setSocketMsg({
                msg: `El pedido ${response.code} fue asignado al servicio de entrega de ${response.lastMile}.`,
                orderId: response.details.orderId,
              });
              setSocketReload(new Date(response.timestamp));
              break;

            case 3:
              setVariant("success");
              setIcon(<CheckCircle sx={{ fontSize: 50 }} />);
              setSocketMsg({
                msg: `El pedido ${response.code} esta en camino a entrega.`,
                orderId: response.details.orderId,
              });
              setSocketReload(new Date(response.timestamp));
              break;

            case 4:
              setVariant("success");
              setIcon(<CheckCircle sx={{ fontSize: 50 }} />);
              setSocketMsg({
                msg: `La orden ${response.code} finalizó.`,
                orderId: response.details.orderId,
              });
              setSocketReload(new Date(response.timestamp));
              break;

            case 5:
              if (response.details.statusDs == 200) {
                setVariant("success");
                setIcon(<CheckCircle sx={{ fontSize: 50 }} />);
                setSocketMsg({
                  msg: `${response.lastMile} en camino a recolección orden ${response.code}.`,
                  orderId: response.details.orderId,
                });
                setSocketReload(new Date(response.timestamp));
              }
              break;
          }
          break;
      }
    };

    return () => socket.close();
  }, []);

  const snackbarAudio = new Audio(SnackbarAudio);

  const action = (id) => (
    <div>
      <IconButton
        onClick={() => {
          closeSnackbar(id);
          snackbarAudio.pause();
        }}
      >
        <Close sx={{ color: "#FFF" }} />
      </IconButton>
    </div>
  );

  useEffect(() => {
    if (socketMsg.msg != "") {
      snackbarAudio.play();

      enqueueSnackbar(
        <div style={{ display: "flex", alignItems: "center" }}>
          {icon}
          <Typography sx={{ fontSize: 25, marginLeft: 1 }}>
            {socketMsg.msg}
          </Typography>
        </div>,
        { autoHideDuration: 5999, variant: `${variant}`, action }
      );
    }
  }, [socketMsg]);

  const nameSection = sectionsSucursal.find((el) => `/${el.link}/` == pathname);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <AppBar
          position="fixed"
          open={tablet ? false : open}
          style={{ width: !open && !tablet && "calc(100% - 65px)" }}
          elevation={0}
        >
          <Toolbar
            sx={{
              bgcolor: "white",
              color: theme.palette.primary.main,
              height: phone ? "65px" : "68px",
            }}
            className={`w-100 d-flex align-items-start align-items-center`}
          >
            {tablet && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            )}

            <div
              className={`w-100 ${phone && "d-flex justify-content-center"}`}
            >
              <div
                className={`w-100 d-flex mt-1 ${
                  phone
                    ? "flex-column align-items-center"
                    : "align-items-center justify-content-between"
                }`}
              >
                <div className="d-flex flex-column">
                  <Typography
                    variant="h5"
                    noWrap
                    component="div"
                    className="ds-blue-font font-weight-bold capitalize"
                  >
                    {nameSection != undefined && nameSection.section}
                  </Typography>
                  {breakpointBC ? null : (
                    <BreadcrumbsMenu
                      sections={sectionsSucursal}
                      principal={"activas"}
                      rol={"warehouse"}
                    />
                  )}
                </div>

                {/*----------------------- NOTIFICACIONES NAME Y PROFILEICON -----------------------*/}
                <div
                  className={`d-flex ${
                    phone
                      ? "w-100 justify-content-center"
                      : "align-items-center justify-content-between"
                  } `}
                >
                  <div></div>

                  {phone ? (
                    <div className="pr-4 mr-4">
                      <Typography
                        sx={{ fontSize: 20 }}
                        className="ds-blue-font capitalize"
                      ></Typography>
                    </div>
                  ) : (
                    <Typography
                      sx={{ fontSize: 20, fontWeight: 600 }}
                      className="ds-blue-font capitalize"
                    ></Typography>
                  )}
                  <IconButton onClick={(e) => handleClick(e)}>
                    <KeyboardArrowDownRounded color={"primary"} />
                  </IconButton>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>

        {/* -------------------------------------------SIDEBAR------------------------------------------ */}
        <Sidebar
          DrawerStyled={DrawerStyled}
          open={open}
          handleDrawer={handleDrawer}
          isLoading={isLoading}
          DrawerHeader={DrawerHeader}
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
          rol={6}
        />

        {/* ------------------------------------------------------------------------------------------- */}

        <div className="d-flex justify-content-center w-100">
          <div
            className=""
            style={{
              width: phone
                ? "100%"
                : open && !phone
                ? "calc(100vw - 235px)"
                : "calc(100vw - 80px)",
            }}
          >
            <Grid container sx={{ marginTop: !tablet ? 8.3 : 7.6 }}>
              <DrawerHeader />

              <Routes>
                {/* Todas las rutas inexistentes te redirigen a ventas */}
                <Route path="*" element={<Navigate to="activas/" replace />} />

                <Route
                  path="activas/"
                  element={<ActiveSales socketReload={socketReload} />}
                />
                <Route path="ventas/" element={<HistoricalSalesSucursal />} />

                <Route path="cuenta/" element={<AccountCardsSucursal />} />
                <Route
                  path="cuenta/password/"
                  element={<EditPasswordSucursal />}
                />
              </Routes>
            </Grid>
          </div>
        </div>
      </Box>
      <Popover
        id={id}
        open={openPO}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: { maxWidth: notification && 350 },
        }}
      >
        {notification ? (
          <ModalNotification
            handleClose={handleClose}
            notifications={notifications}
            loaderNotifications={loaderNotifications}
            total={total}
          />
        ) : (
          <ModalMenuAccount user={"warehouse"} handleClose={handleClose} />
        )}
      </Popover>
    </>
  );
};

export default function IntegrationNotistack({ variant, setVariant }) {
  return (
    <StyledSnackbarProvider
      hideIconVariant
      maxSnack={3}
      variant={`${variant}`}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <LayoutSucursal setVariant={setVariant} variant={variant} />
    </StyledSnackbarProvider>
  );
}
