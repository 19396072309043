import { useState, useEffect, useRef } from "react";
import { DownloadFileButton } from "../Buttons/DownloadFileButton";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";

import { UploadButton } from "../Buttons/UploadButton";
import { DeleteDocButton } from "../Buttons/DeleteDocButton";

import { useNavigate } from "react-router-dom";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";

import { ModalWarning } from "../Modal/ModalWarning";
import { ModalEntrySuccess } from "../Modal/ModalEntrySuccess";

import { InventoryAPI } from "../../api/Inventory";
import { WarehouseAPI } from "../../api/Warehouse";
import { ModalError } from "../Modal/ModalError";
import LoaderDS from "../../assets/LoaderDS.svg";

import readXlsxFile from "read-excel-file";

import {
  TextField,
  Modal,
  useMediaQuery,
  MenuItem,
  Grid,
  Backdrop,
} from "@mui/material";
import { useMemo } from "react";
import { theme } from "../../utils/theme";
import dayjs from "dayjs";
import { getDayjsFormatted } from "../../helpers/getDateCleaned";
import { ExcelGenerator } from "../Excel/ExcelTemplate";

export const MassiveEntriesForm = () => {
  const today = new Date();
  const phone = useMediaQuery("(max-width:480px)");
  const formRef = useRef(null);

  const [errorMessages, setErrorMessages] = useState("");
  const [ticketNumber, setTicketNumber] = useState(0);

  const [openBD, setOpenBD] = useState(false);

  const navigate = useNavigate();

  const inventoryAPI = useMemo(() => new InventoryAPI(), []);
  const warehouseAPI = useMemo(() => new WarehouseAPI(), []);

  /*********** DEPARTURE HANDLING ***********/
  const [cedis, setCedis] = useState([]);
  const [branches, setBranches] = useState([]);

  const [checkDeparture, setCheckDeparture] = useState({
    cedi: false,
    branch: false,
  });

  const departure = [
    { keyName: "Cedi", type: 1, value: true },
    { keyName: "Sucursal", type: 2, value: true },
  ];

  useEffect(() => {
    GetCedis();
    GetBranchesList();
  }, []);

  const GetCedis = async () => {
    let data = new FormData();
    const response = await warehouseAPI.cedisList(data);
    if (response.status == 200) {
      setCedis(response.data.cedis);
    }
  };

  const GetBranchesList = async () => {
    let data = new FormData();

    const response = await warehouseAPI.getBranchesList(data);
    if (response.status == 200) {
      setBranches(response.data.branches);
    } else {
      console.log(response);
    }
  };

  const handleDeparture = (e) => {
    if (e.target.value == 1) {
      setCheckDeparture({ cedi: true });
    } else {
      setCheckDeparture({ branch: true });
    }
  };

  /*********** FILE HANDLING ***********/
  const [excelData, setExcelData] = useState([]);
  const [excelFile, setExcelFile] = useState(null);

  const inputFile = useRef(null);

  const handleUploadClick = (e) => {
    inputFile.current.value = "";
    inputFile.current.click();
    inputFile.current.blur();
  };

  const handleRemoveFile = () => {
    inputFile.current.value = "";
    setExcelFile(null);
    formik.setFieldValue("excelFile", "");
  };

  const handleFile = ({ files }) => {
    if (!files) {
      return;
    }
    const file = files[0];
    setExcelFile(file);
    formik.setFieldValue("excelLoaded", true);
  };

  useEffect(() => {
    if (!excelFile) {
      formik.setFieldValue("excelLoaded", false);
      return;
    }
    formik.setFieldValue("excelLoaded", true);
    getExcelData(excelFile);
  }, [excelFile]);

  const getExcelData = (excelFile) => {
    const schema = {
      sku: {
        prop: "sku",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ sku: value });
        },
      },
      piezas: {
        prop: "piezas",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ piezas: value });
        },
      },
      lote: {
        prop: "lote",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ lote: value });
        },
        type: String,
      },
      fecha_caducidad: {
        prop: "fecha_caducidad",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ fecha_caducidad: value });
        },
      },
    };

    readXlsxFile(excelFile, { schema }).then(({ rows, errors }) => {
      if (rows.length > 1000) {
        formik.setFieldValue("validData", false);
        setErrorMessages("Estas tratando de ingresar más de 1,000 productos");
        setOpenError(true);
        return;
      }
      if (errors.length > 0) {
        formik.setFieldValue("validData", false);
        // console.log(errors);
        setErrorMessages(getErrorMessages(errors));
        setOpenError(true);
        return;
      }
      if (validateDuplicity(rows)) {
        formik.setFieldValue("validData", false);
        setErrorMessages("Tienes productos duplicados");
        setOpenError(true);
        return;
      }
      setExcelData(rows);
      formik.setFieldValue("validData", true);
    });
  };

  const validateDuplicity = (data) => {
    const skuArr = data.map((products) => {
      return products.sku;
    });
    const duplicateData = skuArr.filter(
      (item, index) => skuArr.indexOf(item) !== index
    );

    if (duplicateData.length > 0) {
      return true;
    }
    return false;
  };

  /*********** EXCEL VALIDATION HANDLING ***********/

  const excelValidationSchema = Yup.object({
    sku: Yup.string().matches(
      /^[a-zA-Z]{3}\-+.*/,
      "tener formato AAA-(alfanumérico)"
    ),
    piezas: Yup.number()
      .typeError("ser un número entero")
      .integer("ser un número entero")
      .min(1, "ser mayor a 1")
      .max(100000, "ser mayor a 100,000"),
    lote: Yup.string(),
    fecha_caducidad: Yup.string()
  });

  const getErrorMessages = (errors) => {
    const errorMessages = errors.map((error) => {
      if (error.error == "required") {
        return `Fila ${error.row}: tienes un campo vacío`;
      } 
      else {
        return `Fila ${error.row}: la columna ${error.column} debe ${error.error}`;
      }
    });
    return errorMessages;
  };

  /*********** MODAL HANDLING ***********/

  const [openWarning, setOpenWarning] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleCloseSuccess = (path) => {
    setOpenSuccess(false);
    navigate(`/cliente/inventario/${path}/`);
  };

  /*********** SUBMIT HANDLING ***********/

  const [submitData, setSubmitData] = useState({});

  const handleSendForm = () => {
    setOpenWarning(false);
    createMassiveEntry();
  };

  const createMassiveEntry = async () => {
    setOpenBD(true);
    const data = new FormData();

    data.append("cedi", submitData.departure == 2 ? "" : submitData.cedi.id);
    data.append(
      "branch",
      submitData.departure == 1 ? "" : submitData.branch.id
    );
    data.append("arrive", submitData.date);
    data.append("file", excelFile);

    const response = await inventoryAPI.createMassiveInventoryEntryEP(data);
    if (response.status == 200) {
      setTicketNumber(response.data.id);
      setOpenSuccess(true);
      setOpenBD(false);
    } else {
      // console.log(response);
      setOpenBD(false);
      setErrorMessages(response.response.data.msg);
      setOpenError(true);
    }
  };

  /*********** FORMIK ***********/

  const formik = useFormik({
    initialValues: {
      excelLoaded: false,
      excelFile: "",
      validData: true,
      departure: "",
      cedi: "",
      branch: "",
      date: null,
    },
    validationSchema: Yup.object({
      excelFile: Yup.string().when("excelLoaded", {
        is: false,
        then: Yup.string().required("Campo obligatorio"),
      }),
      validData: Yup.mixed().oneOf([true], "El archivo contiene errores"),
      departure: Yup.string().required("Campo Obligatorio"),
      date: Yup.date().nullable().required("Campo Obligatorio"),
    }),
    onSubmit: (values) => {
      const data = {
        ...values,
        date: getDayjsFormatted(values.date),
        excelFile: excelFile,
      };
      setSubmitData(data);
      setOpenWarning(true);
    },
  });

  const onKeyDown = (e) => {
    //Disable manual date
    e.preventDefault();
  };

  return (
    <div className="mb-3">
      <form>
        <Grid
          container
          spacing={2}
          columnSpacing={2}
          direction="row"
          justify="center"
          rowSpacing={2}
          columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}
        >
          {" "}
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Grid
              item
              xs={12}
              className=" mb-3 border ds-blue-font bg-white r-15 p-3"
              sx={{ height: "100%" }}
            >
              <div className="d-flex flex-column">
                <div className="">
                  <h3 className={`ds-blue-font mb-1 ${phone ? "h6" : "h5"}`}>
                    Instrucciones
                  </h3>
                </div>

                <div
                  className="w-100 mt-3"
                  style={phone ? { fontSize: 13 } : { fontSize: 15 }}
                >
                  <div className="d-flex align-items-center w-100 mb-3">
                    <span className="font-weight-bold"> 1. </span>
                    <p className="m-0">Descarga el template:</p>
                    {/* <a href="https://qaplusback.boxai.mx/static/inventory_entries.xlsx">
                      <DownloadFileButton
                        text={phone ? "" : "Template"}
                        setOpenBD={setOpenBD}
                      />
                    </a> */}
                  <ExcelGenerator UserName={localStorage.getItem("razon_social") ? localStorage.getItem("razon_social") : "inventario"} />
                  </div>
                  <p className=" ">
                    <span className="font-weight-bold"> 2. </span>
                    Instrucciones de llenado del template:
                  </p>
                  <ul>
                    <li>SKU: alfanumérico (Ej. AXO-AM06)</li>
                    <li>Piezas: numérico (Ej. 25)</li>
                    <li>Lote: numérico o alfanumérico (Ej. lote02934) </li>
                    <li>Fecha de caducidad: aaaa/mm/dd (Ej. 2022/10/01)</li>
                    <li>
                      No cambie los encabezados de la tabla y no deje campos
                      vacíos.
                    </li>
                    <li>Puede ingresar hasta 1000 productos es un archivo</li>
                  </ul>

                  <p className="">
                    <span className=" font-weight-bold">3. </span>
                    Carga el template.
                  </p>
                  <p className="m-0">
                    <span className=" font-weight-bold">4. </span>
                    Selecciona la sucursal y la fecha estimada de entrega.
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
          {/*---Altura de 100 para que ocupe verticalmente---*/}
          <Grid item xs={6} sm={6} md={6} lg={6}>
            {" "}
            {/*---Ocupara mitad de pantalla---*/}
            <Grid
              item
              xs={12}
              className={`border bg-white p-3 r-15 h-100 d-flex flex-column justify-content-center`}
            >
              {" "}
              <div className="border bg-white r-15 p-3 d-flex flex-column justify-content-between">
                <div key={"ArchivoExcel"} className={`d-flex  flex-column`}>
                  <div className="d-flex align-items-center w-100">
                    <div className="d-flex flex-column w-100">
                      <div
                        className={` d-flex  flex-column ${
                          phone ? "px-2 " : "px-2"
                        }`}
                      >
                        <div className="d-flex align-items-center w-100 mt-2 ">
                          <div className="d-flex flex-column w-100 ">
                            <input
                              name={excelFile}
                              ref={inputFile}
                              type="file"
                              accept=".xlsx"
                              multiple
                              style={{ display: "none" }}
                              onChange={(e) => {
                                handleFile(e.target);
                              }}
                            />
                            <label htmlFor={excelFile}>
                              <div className="no-wrap d-flex justify-content-between">
                                Archivo:*
                              </div>
                              <UploadButton
                                text={
                                  excelFile ? excelFile.name : "Cargar Archivo"
                                }
                                fileLoaded={excelFile ? "excel" : ""}
                                onClick={handleUploadClick}
                                border={
                                  excelFile
                                    ? theme.palette.secondary.main
                                    : theme.palette.neutrals.inactive
                                }
                                font={
                                  excelFile
                                    ? theme.palette.secondary.main
                                    : theme.palette.neutrals.darkGray
                                }
                                error={
                                  formik.errors.excelFile
                                    ? formik.errors.excelFile
                                    : formik.errors.validData
                                }
                                touched={formik.touched.excelFile}
                              />
                              <div className=" d-flex justify-content-between">
                                {(formik.touched.excelFile &&
                                  formik.errors.excelFile) ||
                                (formik.touched.excelFile &&
                                  formik.errors.validData) ? (
                                  <div className="pl-3 mt-1 text-danger xs-font">
                                    {formik.errors.excelFile ||
                                      formik.errors.validData}
                                  </div>
                                ) : (
                                  <div className="pl-3 mt-1 text-danger xs-font"></div>
                                )}
                                {excelFile ? (
                                  <div className="">
                                    <DeleteDocButton
                                      onClick={handleRemoveFile}
                                      text={"Eliminar"}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div key={"Almacén"} className={`d-flex px-2 flex-column mt-1`}>
                  <div
                    className={`d-flex align-items-center mr-2 ${
                      phone && "mb-2"
                    }`}
                    style={{ minWidth: phone ? "100px" : "200px" }}
                  >
                    <label
                      className=" no-wrap"
                      htmlFor={"departure"}
                    >{`${"Almacén"}:* `}</label>
                  </div>

                  <div className="d-flex align-items-center w-100">
                    <div className="d-flex flex-column w-100">
                      <TextField
                        select
                        size="small"
                        error={
                          formik.touched.departure && formik.errors.departure
                        }
                        label="Almacén"
                        value={formik.values.departure}
                        name={"departure"}
                        id={"departure"}
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleDeparture(e);
                        }}
                        fullWidth
                        helperText={
                          formik.touched.departure &&
                          formik.errors.departure ? (
                            <>{formik.errors.departure}</>
                          ) : null
                        }
                      >
                        {departure.map((dep) => (
                          <MenuItem key={dep.keyName} value={dep.type}>
                            {dep.keyName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </div>
                <div
                  key={"Destino"}
                  className={`d-flex px-2 mb-3 mt-3 justify-content-end`}
                >
                  {checkDeparture.cedi ? (
                    <div className={`d-flex align-items-end w-100`}>
                      <TextField
                        select
                        size="small"
                        error={formik.touched.cedi && formik.errors.cedi}
                        label="CEDI"
                        value={formik.values.cedi}
                        name={"cedi"}
                        id={"cedi"}
                        onChange={formik.handleChange}
                        fullWidth
                        helperText={
                          formik.touched.cedi && formik.errors.cedi ? (
                            <>{formik.errors.cedi}</>
                          ) : null
                        }
                      >
                        {cedis.map((cedi) => (
                          <MenuItem key={cedi.id} value={cedi}>
                            {cedi.cedi}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  ) : null}
                  {checkDeparture.branch ? (
                    <div className={`d-flex align-items-center w-100`}>
                      <TextField
                        select
                        size="small"
                        error={formik.touched.branch && formik.errors.branch}
                        label="Sucursal"
                        value={formik.values.branch}
                        name={"branch"}
                        id={"branch"}
                        onChange={formik.handleChange}
                        fullWidth
                        helperText={
                          formik.touched.branch && formik.errors.branch ? (
                            <>{formik.errors.branch}</>
                          ) : null
                        }
                      >
                        {branches.map((branch) => (
                          <MenuItem key={branch.id} value={branch}>
                            {branch.sucursal}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  ) : null}
                </div>
                <div className={`d-flex px-2 mb-3 flex-column `}>
                  {/* ************************************************************ */}

                  <div className={`d-flex flex-column`}>
                    <div className={`d-flex align-items-center `}>
                      <label className="">Fecha esperada de entrega:* </label>
                    </div>
                    <DesktopDatePicker
                      allowSameDateSelection
                      minDate={dayjs(today)}
                      label={"Fecha de entrega"}
                      format="DD/MM/YYYY"
                      value={formik.values.date}
                      onChange={(value) => {
                        formik.setFieldValue("date", value);
                      }}
                      slotProps={{
                        textField: {
                          error: formik.touched.date && formik.errors.date,
                          onKeyDown: onKeyDown,
                          helperText:
                            formik.touched.date && formik.errors.date ? (
                              <>{formik.errors.date}</>
                            ) : null,
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`${!phone && "d-flex justify-content-center"} mt-2`}
              >
                <div className={phone ? "mb-2" : "mx-3"}>
                  <CancelButton
                    text="Cancelar"
                    type={"text"}
                    onClick={() => {
                      navigate("/cliente/inventario/busqueda");
                    }}
                    width={phone ? "100%" : ""}
                  />
                </div>
                <AcceptButton
                  text="Enviar"
                  type={"text"}
                  onClick={formik.handleSubmit}
                  width={phone ? "100%" : ""}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Modal open={openWarning}>
        <div>
          <ModalWarning
            actionCancel={() => {
              setOpenWarning(false);
            }}
            actionProceed={handleSendForm}
            fileData={excelData}
            type={"entries"}
            submitData={submitData}
          />
        </div>
      </Modal>

      <Modal open={openSuccess}>
        <div>
          <ModalEntrySuccess
            ticketNumber={ticketNumber}
            handleClose={handleCloseSuccess}
          />
        </div>
      </Modal>

      <Modal open={openError}>
        <div>
          <ModalError
            text={
              formik.values.validData
                ? "Error al realizar el envío"
                : "Encontramos errores en tu archivo."
            }
            handleClose={() => {
              setOpenError(false);
            }}
            error={errorMessages}
          />
        </div>
      </Modal>
      {/* 
      <Modal open={errorSizes} >
        <div>
          <ModalSizesEntries
            product={product}
            entryObj={obj}
            CreateEntry={CreateEntry}
            setErrorSizes={setErrorSizes}
          />
        </div>
      </Modal>
          */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </div>
  );
};
