import React from "react";

import { useMediaQuery, IconButton } from "@mui/material";

import { Close } from "@mui/icons-material";
import { CancelEntryButton } from "../Buttons/CancelEntryButton";
import { useMemo } from "react";
import { ClientAPI } from "../../api/Client";

export const ModalDeletUser = ({
  info,
  handleClose,
  setOpenBD,
  setOpenSuccess,
  setOpenError,
  setErrorField,
  RequestData,
}) => {
  const phone = useMediaQuery("(max-width:480px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "85%" : 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
  };

  const handleDelete = () => {
    DeleteUser();
    handleClose();
  };

  // api
  const clientAPI = useMemo(() => new ClientAPI(), []);

  const DeleteUser = async () => {
    setOpenBD(true);
    let data = new FormData();

    data.append("id", info.id);
    data.append("rol", info.rol);

    const response = await clientAPI.deleteUserEP(data);
    if (response.status == 200) {
      setOpenBD(false);
      setOpenSuccess(true);

      setTimeout(() => {
        setOpenSuccess(false);
        RequestData();
      }, 3000);
    } else {
      // console.log(response);
      setOpenBD(false);
      setOpenError(true);
      setErrorField(response.data.detail);
    }
  };

  return (
    <div className="p-3">
      <div className="w-100">
        <div className="d-flex justify-content-between align-items-center">
          <p id="modal-modal-description" className="ds-blue-font h5 m-0">
            Eliminar usuario:
          </p>
          <IconButton onClick={handleClose}>
            <Close fontSize="large" />
          </IconButton>
        </div>

        <div className="w-100 text-center">
          <p id="modal-modal-description" className="ds-blue-font h5 m-0">
            Estas a punto de eliminar al usuario:
          </p>
          <p className="font-weight-bold">
            {info.nombre} {info.apellidos}
          </p>
        </div>
        <div className="w-100 text-center mt-2">
          <p id="modal-modal-description" className="ds-blue-font h6 m-0">
            ¿Deseas continuar?
          </p>
        </div>

        <div
          className={`d-flex mt-3 ${
            phone ? "flex-column" : "justify-content-end"
          }`}
        >
          <CancelEntryButton
            text={"Eliminar"}
            onClick={handleDelete}
            width={"100%"}
          />
        </div>
      </div>
    </div>
  );
};
