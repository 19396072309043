import React, { useState } from "react";
import { Button, Backdrop, ButtonBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Download } from "@mui/icons-material";
import { theme } from "../../utils/theme";

export const DownloadFileButton = ({ text, disabled, onClick }) => {
  return (
    <ButtonBase className="toolbar-button" disabled={disabled} onClick={onClick}>
      <Download className="mr-1" fontSize="small"/>
      <p className="m-0 no-wrap">{text}</p>
    </ButtonBase>
  );
};
