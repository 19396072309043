import React from 'react'

import { Typography, Box, useMediaQuery } from "@mui/material";

import { CheckCircle} from '@mui/icons-material';
import { AcceptButton } from '../Buttons/AcceptButton';

export const ModalDeleteSuccess = ( { handleCloseSuccess }) => {
  const phone = useMediaQuery("(max-width:480px)");

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: phone ? "95%" : 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: 2
  };

  return (
    <Box sx={style}>
      <div className='d-flex justify-content-center'>
        <Typography id="modal-modal-description">
          El usuario se elminió correctamente
        </Typography>
      </div>
      <div className='d-flex justify-content-center mt-2'>
          <CheckCircle sx={{ color: "#6bb120", fontSize: 50,}}
        />
      </div>

      <div>
        {/* <AcceptButton onClick={handleCloseSuccess}/> */}
      </div>
    </Box>
  )
}
