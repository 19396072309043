import React, { useState } from "react";

import {
  Paper,
  IconButton,
  CircularProgress,
  Tooltip,
  useMediaQuery,
  Modal,
  Zoom,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { Chart } from "react-google-charts";

import { ModalGraphs } from "../Modal/ModalGraphs";
import { QueryStatsRounded, ZoomIn } from "@mui/icons-material";

import LoaderGraphs from "../../assets/LoaderGraphs.svg";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderRadius: 10,
  // borderBottom: "2px solid red"
  // cursor: "pointer",
}));

export const CardGraph = ({
  title,
  type,
  data,
  options,
  isLoading,
  modalOption,
  dataFull,
  client,
  filterInput,
  filters,
}) => {
  const allZero = data?.slice(1).every(item => item[1] === 0);
  const phone = useMediaQuery("(max-width:480px)");
  const mediaScreen = useMediaQuery("(max-width:900px)");
  const tablet = useMediaQuery("(min-width:600px)");
  const [openGraph, setOpenGraph] = useState(false);
  const handleClickModal = () => {
    tablet && setOpenGraph(!openGraph);
  };

  const chartOptions = allZero ? 
    {
      ...options,
      hAxis: {
        viewWindow: {
          min: 100
        }
      },
      colors: options.colors
        ? options.colors
        : [
            "#19B1FF",
            "#ffc500",
            "#8CC044",
            "#FF940D",
            "#76559F",
            "#FF9DD0",
            "#FF5C51",
          ],
    } 
    :
    {
      ...options,
      colors: options.colors
        ? options.colors
        : [
            "#19B1FF",
            "#ffc500",
            "#8CC044",
            "#FF940D",
            "#76559F",
            "#FF9DD0",
            "#FF5C51",
          ],
    };
    // console.log('para el tipo: ' + type.toString());
    //console.log(chartOptions);
  return (
    <>
      <Item elevation={0}>
        {isLoading ? (
          <img src={LoaderGraphs} width={"60px"} alt="Loader gráficas" />
        ) : (
          <>
            <div
              className={`d-flex justify-content-between align-items-center ${
                phone && filterInput && "flex-column"
              } ${mediaScreen && filterInput && "flex-column"}`}
            >
              {filterInput /* Status to indicate that "Ventas Por Día" graph, includes an input. */ ? (
                <div
                  className={`mt-2 mb-2 ${
                    phone || mediaScreen ? "w-100" : "w-25"
                  }`}
                >
                  {filterInput}
                </div>
              ) : (
                <div></div>
              )}
              <div
                className={`d-flex align-items-center justify-content-between ${
                  filterInput ? "w-75" : "w-100"
                }`}
              >
                <div></div>
                <p className="m-0 ds-blue-font">
                  <strong>{title}</strong>
                </p>
                <div>
                  {tablet && (
                    <Tooltip title="Maximizar" arrow placement="top">
                      <IconButton
                        size="medium"
                        data-html2canvas-ignore="true"
                        onClick={handleClickModal}
                        disabled={data.length < 1}
                      >
                        <ZoomIn sx={{ fontSize: 30 }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            <Divider />
            {allZero ? (
              <div className="inactive-font mt-2" style={{ height: 192 }}>
                <QueryStatsRounded sx={{ fontSize: 60 }} />
                <p>Sin datos </p>
              </div>
            ) : (data.length > 1 ? (
              <Chart
                chartType={type}
                data={data}
                options={chartOptions}
                width="100%"
                height="100%"
                chartLanguage="es"
              />
            ) : (
              <div className="inactive-font mt-2" style={{ height: 192 }}>
                <QueryStatsRounded sx={{ fontSize: 60 }} />
                <p>Sin datos </p>
              </div>
            ))}
          </>
        )}
      </Item>
      <Modal open={openGraph}>
        <ModalGraphs
          filterInput={filterInput}
          client={client}
          onClose={handleClickModal}
          type={type}
          data={
            dataFull ? dataFull : data
          } /* Más Stock & Menos Stock graphs, character truncate */
          options={{ ...options, ...modalOption }}
          fileName={title}
          filters={filters}
        />
      </Modal>
    </>
  );
};
