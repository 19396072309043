import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useState } from 'react';
import { IconButton, useMediaQuery } from '@mui/material';
import { Close, VisibilityOutlined } from '@mui/icons-material';

export default function ModalImgEvidences({ images, handleClose }) {
    const phone = useMediaQuery("(max-width:600px)");

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: phone ? "95%" : "50%",
        maxHeight: 700,
        height: "80vh",
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    };

    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box sx={style}>

            <div className='w-100 d-flex justify-content-end'>
                <IconButton onClick={handleClose}>
                    <Close sx={{ fontSize: 35 }}/>
                </IconButton>
            </div>
            <div className='w-100 d-flex justify-content-center'>
                {
                    images[activeStep].includes(".pdf") ? 
                    <iframe src={`${images[activeStep]}#toolbar=0`} title="Evidencias" style={{ height: "55vh", width: "100%" }} /> : 
                    <img src={images[activeStep]} alt="imagen evidencia" style={{ maxHeight: "55vh", maxWidth: "100%" }} />
                }
            </div>
            <div className='w-100 '>
                <div className='d-flex flex-column align-items-center justify-content-center w-100'>
                    <a
                        href={images[activeStep]}
                        target="_blank"
                        rel="noopener noreferrer"
                        className='d-flex align-items-center'
                    >
                        <VisibilityOutlined sx={{ color: theme.palette.secondary.main  }} />
                        <span className="ds-blue-font cursor-pointer">
                            Tamaño completo
                        </span>
                    </a>
                </div>

                <MobileStepper
                    sx={{ backgroundColor: "transparent", color: theme.palette.secondary.main  }}
                    variant="text"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            sx={{ color: theme.palette.secondary.main  }}
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                        >
                            Siguiente
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button sx={{ color: theme.palette.secondary.main  }} size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            Anterior
                        </Button>
                    }
                />
            </div>
        </Box>
    );
}
