import { Divider, List, Popover, useMediaQuery } from "@mui/material";
import React from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { Map } from "./Map";
import { Marker } from "./Marker";
import { googleMapsKey } from "../../utils/keys.js";
import { useEffect } from "react";
import { useState } from "react";
import { Search } from "../Search";
import { LocationCard } from "./LocationCard";
import { SkeletonMapList } from "../Skeleton/SkeletonMapList";
import { ModalFilter2 } from "../Modal/ModalFilter2";
import { useContext } from "react";
import { FilterContext } from "../../context/FilterContext";
import { useMemo } from "react";

function LocationMap({ title, data, requestData, isLoading, business }) {
  const phone = useMediaQuery("(max-width:600px)");
  const tablet = useMediaQuery("(max-width:900px)");

  /* DATE IS USED TO CHECK IF LOCATION IS OPEN OR CLOSED */
  const today = new Date();
  /* DATA STATES */
  const { getStatesByBusiness, resetFilter, state } = useContext(FilterContext);
  const [openCard, setOpenCard] = useState(false);
  const [locationOpened, setLocationOpened] = useState({});
  const activeLocations = useMemo(() => data.filter((el) => el.status), [data]);

  /* MAP STATES */
  const [map, setMap] = useState();

  /* SEARCH AND FILTER STATE */
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenFilter(true);
  };

  const closeFilter = () => {
    setOpenFilter(false);
  };

  const searchLocationOpened = () => {
    const result = activeLocations.filter(
      (loc) =>
        loc.alias.substring(0, search.length).toLowerCase() ==
        search.toLocaleLowerCase()
    );
    setSearchResult(result);
  };

  useEffect(() => {
    if (search != "") {
      searchLocationOpened();
    }
  }, [search]);

  /* FIRST RENDER */
  useEffect(() => {
    resetFilter();
    getStatesByBusiness(business.id);
  }, []);

  return (
    <>
      <div className="w-100 pb-1" style={{ height: "90%" }}>
        <div className="p-1">{title}</div>
        <div className={`d-flex ${phone && "flex-column"} w-100 h-100`}>
          <div style={{ width: phone ? "100%" : "35%" }}>
            {/* SEARCH AND FILTER */}
            <div className="mr-3">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                disabled={false}
                onClick={handleOpenFilter}
                invisible={state == 0}
              />
            </div>
            {/* LIST */}
            <List style={{ maxHeight: "57vh", overflowY: "auto" }}>
              <Divider />
              {isLoading ? (
                <SkeletonMapList num={4} key="skeleton" />
              ) : search == "" ? (
                data.length > 0 ? (
                  data.map((loc, i) => (
                    <LocationCard
                      key={loc.id}
                      locationOpened={locationOpened}
                      open={openCard}
                      openLocation={() => {
                        setLocationOpened(loc);
                        setOpenCard(
                          loc.id == locationOpened.id ? !openCard : true
                        );
                      }}
                      date={today}
                      data={loc}
                    />
                  ))
                ) : (
                  <p className="text-center mt-2 gray-font">
                    No tienes ubicaciones aún.
                  </p>
                )
              ) : searchResult.length > 0 ? (
                searchResult.map((loc, i) => (
                  <LocationCard
                    key={loc.id}
                    locationOpened={locationOpened}
                    open={openCard}
                    openLocation={() => {
                      setLocationOpened(loc);
                      setOpenCard(
                        loc.id == locationOpened.id ? !openCard : true
                      );
                    }}
                    date={today}
                    data={loc}
                  />
                ))
              ) : (
                <p className="text-center mt-2 gray-font">
                  No se encontraron resultados para {search}
                </p>
              )}
            </List>
          </div>
          {/* MAP */}
          <div style={{ width: phone ? "100%" : "65%" }}>
            <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={["places"]}>
              <Map
                map={map}
                setMap={setMap}
                center={business.location}
                zoom={20}
                style={{
                  height: phone ? "50vh" : "65vh",
                }}
                multipleMarkers={
                  activeLocations.length == 0 ? false : !openCard
                }
              >
                {openCard ? (
                  <Marker
                    position={locationOpened.location}
                    label={locationOpened.alias}
                    draggable={false}
                    openLocation={() => {
                      setOpenCard(!openCard);
                    }}
                  />
                ) : (
                  activeLocations.length > 0 &&
                  activeLocations.map((info, i) => (
                    <Marker
                      label={info.alias}
                      position={info.location}
                      key={i}
                      openLocation={() => {
                        setLocationOpened(info);
                        setOpenCard(
                          info.id == locationOpened.id ? !openCard : true
                        );
                      }}
                      draggable={false}
                    />
                  ))
                )}
              </Map>
            </Wrapper>
          </div>{" "}
        </div>
      </div>
      <Popover
        open={openFilter}
        anchorEl={anchorEl}
        onClose={closeFilter}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <ModalFilter2
          handleCloseModal={closeFilter}
          fields={["state"]}
          requestData={requestData}
        />
      </Popover>
    </>
  );
}

export default LocationMap;
