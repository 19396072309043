import { HttpRequest } from "./HttpRequest";
import { getHost } from "../helpers/getHost";

export class WarehouseAPI {
  constructor() {
    this.http = new HttpRequest();
    this.host = getHost();
    this.token = localStorage.getItem("token");
    this.config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${this.token}`,
      },
    };
  }

  getBranchesList(data) {
    return this.http.post(
      `${this.host}/warehouse/ws/branchesList/`,
      data,
      this.config
    );
  }

  cedisList(data) {
    return this.http.post(
      `${this.host}/warehouse/ws/cedisList/`,
      data,
      this.config
    );
  }

  whsSalesEP(data) {
    return this.http.post(
      `${this.host}/warehouse/ws/sales/`,
      data,
      this.config
    );
  }

  currentSalesEP(data) {
    return this.http.post(
      `${this.host}/warehouse/ws/currentSales/`,
      data,
      this.config
    );
  }
}
