import React from "react";

import { Button, ButtonBase } from "@mui/material";

import { Cached } from "@mui/icons-material";

export const RefreshButton = ({ text, onClick }) => {
  return (
    <ButtonBase className="toolbar-button" onClick={onClick}>
      <Cached className="mr-1" />
      <p className="m-0 no-wrap">{text}</p>
    </ButtonBase>
  );
};
