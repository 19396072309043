import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  styled,
  tableCellClasses,
} from "@mui/material";

import { EditTwoTone } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";

import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";

import { ListPagination } from "../Pagination/ListPagination";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const TableClients = ({
  search,
  data,
  setPage,
  page,
  total,
  requestData,
  count
}) => {
  const { height, width } = useViewPortDimensions();
  const navigate = useNavigate();

  const handleClick = (row) => {
    navigate("/administrador/clientes/editar/", {
      state: {
        idClient: row.id,
        business: row.razon_social ? row.razon_social : "",
        rfc: row.rfc ? row.rfc : "",
        regime: row.regimen_fiscal ? row.regimen_fiscal : "",
        website: row.sitio_web ? row.sitio_web : "",
        name: row.nombre ? row.nombre : "",
        lastName: row.apellidos ? row.apellidos : "",
        email: row.email ? row.email : "",
        personalNumber: row.telefono ? row.telefono : "",
        officeNumber: row.telefono_oficina ? row.telefono_oficina : "",
        street: row.calle ? row.calle : "",
        suburb: row.colonia ? row.colonia : "",
        postalCode: row.codigo_postal ? row.codigo_postal : "",
        limit: row.plazo_pago ? row.plazo_pago : "",

        //-----------------------------FILES--------------------------------//
        address:
          row.files.length != 0
            ? row.files[0].address_proof != null
              ? row.files[0].address_proof
              : ""
            : "",
        contract:
          row.files.length != 0
            ? row.files[0].ds_agreement != null
              ? row.files[0].ds_agreement
              : ""
            : "",
        act:
          row.files.length != 0
            ? row.files[0].founding_act != null
              ? row.files[0].founding_act
              : ""
            : "",
        id:
          row.files.length != 0
            ? row.files[0].id_legal_repr != null
              ? row.files[0].id_legal_repr
              : ""
            : "",
        fiscal:
          row.files.length != 0
            ? row.files[0].rfc_evidence != null
              ? row.files[0].rfc_evidence
              : ""
            : "",
        other1:
          row.files.length != 0
            ? row.files[0].extra_field_1 != null
              ? row.files[0].extra_field_1
              : ""
            : "",
        other2:
          row.files.length != 0
            ? row.files[0].extra_field_2 != null
              ? row.files[0].extra_field_2
              : ""
            : "",
      },
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: height - (74 + 90 + 60) }}>
          <Table sx={{ minWidth: 750 }} aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell align="center">Razón social</StyledTableCell>
                <StyledTableCell align="center">RFC</StyledTableCell>
                <StyledTableCell align="center">Régimen fiscal</StyledTableCell>
                <StyledTableCell align="center">
                  Nombre contacto
                </StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">
                  Teléfono celular
                </StyledTableCell>
                <StyledTableCell align="center">
                  Teléfono oficina
                </StyledTableCell>
                <StyledTableCell align="center">Sitio web</StyledTableCell>
                <StyledTableCell align="center">Calle y No.</StyledTableCell>
                <StyledTableCell align="center">Colonia</StyledTableCell>
                <StyledTableCell align="center">Código Postal</StyledTableCell>
                <StyledTableCell align="center">Ciudad</StyledTableCell>
                <StyledTableCell align="center">Fecha</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell padding="checkbox">
                      <Tooltip title={"Editar"} arrow placement="top">
                        <div className={`hover-icon`}>
                          <IconButton onClick={() => handleClick(row)}>
                            <EditTwoTone />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      align="center"
                    >
                      {row.razon_social}
                    </TableCell>
                    <TableCell align="center">{row.rfc}</TableCell>
                    <TableCell align="center">{row.regimen_fiscal}</TableCell>
                    <TableCell align="center">{row.nombre}</TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">{row.telefono}</TableCell>
                    <TableCell align="center">
                      {row.telefono_oficina == null
                        ? "N/A"
                        : row.telefono_oficina}
                    </TableCell>
                    <TableCell align="center">
                      {row.sitio_web == null ? "N/A" : row.sitio_web}
                    </TableCell>
                    <TableCell align="center">{`${
                      row.calle == null ? "" : row.calle
                    } ${row.numero == null ? "" : row.numero}`}</TableCell>
                    <TableCell align="center">{row.colonia}</TableCell>
                    <TableCell align="center">{row.codigo_postal}</TableCell>
                    <TableCell align="center">
                      {row.ciudad == null ? "N/A" : row.ciudad}
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: 120 }}>
                      {row.fecha_creacion.split("-").reverse("").join("-")}
                    </TableCell>
                    {/* 
                      <TableCell align="center">{row.files.length != 0 ? row.files[0].founding_act : ""}</TableCell>
                      <TableCell align="center">{row.files.length != 0 ? row.files[0].ds_agreement : ""}</TableCell>
                      <TableCell align="center">{row.files.length != 0 ? row.files[0].address_proof : ""}</TableCell>
                      <TableCell align="center">{row.files.length != 0 ? row.files[0].id : ""} </TableCell>
                      <TableCell align="center">{row.files.length != 0 ? row.files[0].rfc_evidence : ""}</TableCell>
                      <TableCell align="center">{row.files.length != 0 ? row.files[0].extra_field_1 : ""}</TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {data.length == 0 && search != "" ? (
            <div className="d-flex justify-content-center mt-2">
              No se encontraron resultados para "{search}"
            </div>
          ) : (
            data.length == 0 &&
            search == "" && (
              <div className="d-flex justify-content-center mt-2">
                No tienes ventas aún.
              </div>
            )
          )}
          {/* {
            data.length == 0 && (
              <div className="d-flex justify-content-center mt-2">
                No hay clientes registrados aún.
              </div>
            )
          } */}
        </TableContainer>
        <ListPagination
          setPage={setPage}
          page={page}
          total={total}
          requestData={requestData}
          count={count}
        />
      </Paper>
    </Box>
  );
};
