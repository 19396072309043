import React, { useEffect, useState, useContext } from "react";
import { FilterContext } from "../../context/FilterContext";

import {
  Typography,
  Box,
  TextField,
  MenuItem,
  IconButton,
  Divider,
  useMediaQuery,
  Popover,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { Close, ErrorSharp } from "@mui/icons-material";

import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";
import { useFormik } from "formik";
import dayjs from "dayjs";

export const ModalFilter2 = ({
  handleCloseModal,
  fields = [],
  requestData,
  view,
  setPage,
}) => {
  const phone = useMediaQuery("(max-width:480px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "85%" : "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: 5,
    // maxHeight: phone ? "95%" : "",
    // overflowY: phone ? "auto" : "",
  };

  const today = new Date();
  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const { fieldList, resetFilter, closeFilter, canFilter } =
    useContext(FilterContext);

  const handleClose = () => {
    closeFilter();
    handleCloseModal();
  };

  const handleFilter = async () => {
    if (setPage) {
      setPage(0);
    }
    if (canFilter()) {
      requestData();
      handleCloseModal();
    }
  };

  return (
    <div className="p-2">
      {/* Mensaje y botón de cerrar */}

      <div className="d-flex justify-content-between align-items-center">
        <p id="modal-modal-description" className="ds-blue-font h4 m-0">
          Filtrar:
        </p>
        <IconButton onClick={handleClose}>
          <Close fontSize="large" />
        </IconButton>
      </div>

      {/* Inputs de filtros */}

      <div className={`mt-1 ${phone ? "px-1" : "px-4"}}`}>
        {fieldList.map((el, i) =>
          fields.includes(el.key) ? (
            el.key == "nullableStartDate" ||
            el.key == "nullableEndDate" ||
            el.key == "startDate" ||
            el.key == "endDate" ? (
              <div className={`d-flex flex-column mb-2 `} key={i}>
                <div
                  className={`d-flex align-items-center ds-blue-font`}
                  style={{ minWidth: "100px" }}
                >
                  <p
                    className="no-wrap h6"
                    htmlFor={el.label}
                  >{`${el.label}:`}</p>
                </div>

                <div className={`d-flex flex-column`}>
                  <DesktopDatePicker
                    allowSameDateSelection
                    maxDate={dayjs(today)}
                    label={el.label}
                    format="DD/MM/YYYY"
                    value={dayjs(el.value)}
                    onChange={el.handleOnChange}
                    slotProps={{
                      textField: {
                        size: "small",
                        //onKeyDown: onKeyDown,
                      },
                    }}
                  />
                  <div className="text-danger xs-font">{el.error}</div>
                </div>
              </div>
            ) : (
              <div key={i} className={` d-flex mb-2 flex-column`}>
                <div
                  className={`d-flex align-items-center mb-1 ds-blue-font`}
                  style={{ minWidth: "100px" }}
                >
                  <p
                    className="no-wrap h6"
                    htmlFor={el.label}
                  >{`${el.label}:`}</p>
                </div>
                <div className="d-flex align-items-center w-100">
                  <div className="d-flex flex-column w-100">
                    <TextField
                      select
                      size="small"
                      label={el.label}
                      value={el.value}
                      name={el.label}
                      id={el.label}
                      onChange={(e) => el.handleOnChange(e)}
                      fullWidth
                    >
                      {el.array.map((option, i) => (
                        <MenuItem key={i} value={option.id}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              </div>
            )
          ) : null
        )}
      </div>

      <div
        className={`d-flex mt-1 ${
          phone ? "flex-column " : "justify-content-center"
        }`}
      >
        {/* <div className={`${phone && "mb-2"}`}> */}
        <CancelButton
          text={"Limpiar"}
          onClick={resetFilter}
          width={phone ? "100%" : "100%"}
        />
        {/* </div> */}
        <AcceptButton
          text={"Filtrar"}
          type={"text"}
          onClick={handleFilter}
          width={phone ? "100%" : "100%"}
          phone={phone}
        />
      </div>
    </div>
  );
};
