import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import {
  Typography,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  useMediaQuery,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { AcceptButton } from "../Buttons/AcceptButton";

import { useFormik } from "formik";

import { useNavigate } from "react-router-dom";

import { useCountdown } from "../../hooks/useCountdown";
import { CancelButton } from "../Buttons/CancelButton";
import LoaderDS from "../../assets/LoaderDS.svg";
import { useMemo } from "react";
import { LoginAPI } from "../../api/Login";
// import { Countdown } from "../Countdown";

const validate = (values) => {
  const errors = {};

  if (values.code.length < 5) {
    errors.code = "Código inválido";
  }

  return errors;
};

export const ModalRecovery = ({ email, handleSuccess, handleClose }) => {
  const phone = useMediaQuery("(max-width:480px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "95%" : 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
  };

  const [canChangePassword, setCanChangePassword] = useState(false);
  const [openBD, setOpenBD] = useState(false);
  const [errorCode, setErrorCode] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const [resend, setResend] = useState(false);

  const [timeLeft, setTimeLeft] = useCountdown(30);

  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);

  const handleClickResend = () => {
    getRecovery({}, 2);
  };

  // apis
  const loginAPI = useMemo(() => new LoginAPI(), []);

  const getRecovery = async (values, step) => {
    setOpenBD(true);
    let data = new FormData();
    let response;
    switch (step) {
      case 2:
        data.append("email", email);
        data.append("step", step);

        response = await loginAPI.RecoveryEP(data);
        setOpenBD(false);
        if (response.status != 200) {
          // console.log(response);
          setOpenBD(false);
        }

        break;
      case 3:
        data.append("email", email);
        data.append("step", step);
        data.append("code", values.code);

        response = await loginAPI.RecoveryEP(data);
        setOpenBD(false);
        if (response.status == 200) {
          setCanChangePassword(true);
        } else if (response.response.status == 400) {
          setErrorCode(true);
          setOpenBD(false);
        }

        break;
      case 4:
        data.append("email", email);
        data.append("step", step);
        data.append("password", values.newPassword);

        response = await loginAPI.RecoveryEP(data);
        setOpenBD(false);
        if (response.status == 200) {
          handleSuccess();
        } else {
          // console.log(response);
          setOpenBD(false);
        }

        break;
    }
  };

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validate,
    onSubmit: (values) => {
      getRecovery(values, 3);
    },
  });

  const formik2 = useFormik({
    initialValues: {
      newPassword: "",
      confirmation: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("Campo Obligatorio")
        .max(30, "Demasiado largo")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Mínimo 8 caracteres, una mayúscula, una minúscula, un número y un carácter especial"
        ),
      confirmation: Yup.string()
        .required("Campo Obligatorio")
        .when("newPassword", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("newPassword")],
            "Contraseñas deben coincidir"
          ),
        }),
    }),

    onSubmit: (values) => {
      getRecovery(values, 4);
    },
  });

  return (
    <>
      <Box sx={style}>
        {!canChangePassword ? (
          <>
            <div className="d-flex justify-content-center">
              <Typography id="modal-modal-description">
                Te hemos enviado un código de recuperación vía SMS, favor de
                ingresarlo.
              </Typography>
            </div>
            <div className="d-flex align-items-center w-100 mt-3">
              <TextField
                key={"code"}
                size="small"
                label={"Código"}
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.code}
                name={"code"}
                id={"code"}
              />
            </div>

            {errorCode ? (
              <div className="mb-3">
                <p className="m-0 text-danger">Código inválido</p>
              </div>
            ) : (
              ""
            )}
            <div className="d-flex justify-content-between mt-3">
              <CancelButton text={"Cancelar"} onClick={handleClose} />
              <AcceptButton
                text={"Validar código"}
                onClick={formik.handleSubmit}
              />
            </div>
            <div className="d-flex align-items-center justify-content-center w-100 mt-3">
              <p className="text-secondary m-0">¿No recibiste nada?&nbsp;</p>
              {timeLeft == 0 ? (
                <p
                  className="m-0 ds-blue-font cursor-pointer link-decorated"
                  onClick={handleClickResend}
                >
                  Reenviar código
                </p>
              ) : (
                <p className="m-0">{`${timeLeft} s`}</p>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-center">
              <Typography id="modal-modal-description">
                Reestablece tu contraseña.
              </Typography>
            </div>
            <div className="d-flex align-items-center w-100 mt-3">
              <TextField
                error={
                  formik2.touched.newPassword && formik2.errors.newPassword
                }
                helperText={
                  formik2.touched.newPassword && formik2.errors.newPassword ? (
                    <div>{formik2.errors.newPassword}</div>
                  ) : null
                }
                type={showPassword ? "text" : "password"}
                key={"np"}
                size="small"
                label={"Contraseña nueva: "}
                variant="outlined"
                fullWidth
                onChange={formik2.handleChange}
                value={formik2.values.newPassword}
                name={"newPassword"}
                id={"newPassword"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="d-flex align-items-center w-100 mt-2">
              <TextField
                error={
                  formik2.touched.confirmation && formik2.errors.confirmation
                }
                helperText={
                  formik2.touched.confirmation &&
                  formik2.errors.confirmation ? (
                    <div>{formik2.errors.confirmation}</div>
                  ) : null
                }
                type={showPassword2 ? "text" : "password"}
                key={"con"}
                size="small"
                label={"Confirmar contraseña: "}
                variant="outlined"
                fullWidth
                onChange={formik2.handleChange}
                value={formik2.values.confirmation}
                name={"confirmation"}
                id={"confirmation"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        edge="end"
                      >
                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            {errorPassword ? (
              <div className="mb-3">
                <p className="m-0 text-danger">
                  No se pudo cambiar tu contraseña correctamente
                </p>
              </div>
            ) : (
              ""
            )}
            <div className="d-flex justify-content-between mt-3">
              <CancelButton text={"Cancelar"} onClick={handleClose} />
              <AcceptButton text={"Aceptar"} onClick={formik2.handleSubmit} />
            </div>
          </>
        )}
      </Box>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
