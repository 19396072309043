import React, { useState, useEffect } from "react";

import { Divider, Grid, useMediaQuery } from "@mui/material";
import {
  PendingActions,
  AssignmentTurnedInOutlined,
  RuleOutlined,
  HighlightOff,
} from "@mui/icons-material";

import { CardEntry } from "./CardEntry";
import { SkeletonCardEntry } from "./Skeleton/SkeletonCardEntry";
import { ListPagination } from "./Pagination/ListPagination";
import { getDateCleaned } from "../helpers/getDateCleaned";
import { roles } from "../helpers/enums";
import { useMemo } from "react";
import { GraphsAPI } from "../api/Graphs";

export const ListViewInventoryEntries = ({
  listIndex,
  setListIndex,
  data,
  search,
  isLoading,
  type,
  setPage,
  page,
  total,
  count,
  RequestData,
  setStatus,
  status,
  getInventoryEntries,
  startFilterDate,
  endFilterDate,
  clients,
  client,
  icon,
  // setEntriesCardsView
}) => {
  const phone = useMediaQuery("(max-width:480px)");
  const [loadEntry, setLoadEntry] = useState(false);
  const [totales, setTotales] = useState({
    pending: "",
    validate: "",
    incomplete: "",
    cancelled: "",
  });

  useEffect(() => {
    GetDataEntries();
  }, [startFilterDate, endFilterDate, client]);

  useEffect(() => {
    getInventoryEntries();
  }, [status]);

  useEffect(() => {
    getInventoryEntries();
  }, [search]);

  useEffect(() => {
    setListIndex(0);
    setStatus("Por validar");
  }, []);

  // apis
  const graphAPI = useMemo(() => new GraphsAPI(), []);

  const GetDataEntries = async () => {
    setLoadEntry(true);
    let data = new FormData();
    const token = localStorage.getItem("token");
    data.append("startDate", getDateCleaned(startFilterDate));
    data.append("endDate", getDateCleaned(endFilterDate));

    if (type == roles.ADMIN) {
      if (client != "Todos") {
        const auxClient = clients.find((el) => el.razon_social == client);
        data.append("client", auxClient.id);
      }
    }

    const response = await graphAPI.entriesGraphEP(data);
    if (response.status == 200) {
      let aux = response.data.entries;
      if (aux.length != 0) {
        setTotales({
          pending: aux[0][1],
          validate: aux[1][1],
          incomplete: aux[2][1],
          cancelled: aux[3][1],
        });
      } else {
        setTotales({ pending: 0, validate: 0, incomplete: 0, cancelled: 0 });
      }
      setLoadEntry(false);
    } else {
      console.log(response);
    }
  };

  const pending = data.filter((entry) => {
    return entry.status == "Por validar";
  });

  const incomplete = data.filter((entry) => {
    return entry.status == "Incompleto";
  });

  const validated = data.filter((entry) => {
    return entry.status == "Validado";
  });

  const cancelled = data.filter((entry) => {
    return entry.status == "Cancelada";
  });

  return (
    <>
      <div className="mt-3 bg-white p-2 w-100 r-15">
        <div
          className={`d-flex w-100 align-items-center ${
            phone ? "flex-column" : "justify-content-around"
          }`}
        >
          <div
            className={`w-100 p-2 cursor-pointer inactive-font ${
              listIndex == 0 && "active-chip bg-light-grey"
            }`}
            onClick={() => {
              setListIndex(0);
              setStatus("Por validar");
            }}
          >
            <div className="d-flex justify-content-center">
              <PendingActions />
            </div>
            <div className="d-flex justify-content-center">
              <p className="m-0 h6 text-center">
                Por validar (
                {!isLoading && !loadEntry ? totales.pending : "..."})
              </p>
              {/* <p className='m-0 h6 text-center'>({pending.length})</p> */}
            </div>
          </div>

          <Divider orientation="vertical" flexItem className="mx-1" />
          <div
            className={`w-100 p-2 cursor-pointer inactive-font ${
              listIndex == 1 && "active-chip bg-green"
            }`}
            onClick={() => {
              setListIndex(1);
              setStatus("Validado");
            }}
          >
            <div className="d-flex justify-content-center">
              <AssignmentTurnedInOutlined />
            </div>
            <div className="d-flex justify-content-center">
              <p className="m-0 h6 text-center">
                Validadas ({!isLoading && !loadEntry ? totales.validate : "..."}
                )
              </p>
              {/* <p className='m-0 h6 text-center'>({validated.length})</p> */}
            </div>
          </div>
          {/* <PriorityHighTwoTone /> */}

          <Divider orientation="vertical" flexItem className="mx-1" />

          <div
            className={`w-100 p-2 cursor-pointer inactive-font ${
              listIndex == 2 && "active-chip bg-light-blue"
            }`}
            onClick={() => {
              setListIndex(2);
              setStatus("Incompleto");
            }}
          >
            <div className="d-flex justify-content-center">
              <RuleOutlined />
            </div>
            <div className="d-flex justify-content-center">
              <p className="m-0 h6 text-center">
                Incompletas (
                {!isLoading && !loadEntry ? totales.incomplete : "..."})
              </p>
              {/* <p className='m-0 h6 text-center'>({incomplete.length})</p> */}
            </div>
          </div>

          <Divider orientation="vertical" flexItem className="mx-1" />

          <div
            className={`w-100 p-2 cursor-pointer inactive-font ${
              listIndex == 3 && "active-chip bg-red"
            }`}
            onClick={() => {
              setListIndex(3);
              setStatus("Cancelado");
            }}
          >
            <div className="d-flex justify-content-center">
              <HighlightOff />
            </div>
            <div className="d-flex justify-content-center">
              <p className="m-0 h6 text-center">
                Canceladas (
                {!isLoading && !loadEntry ? totales.cancelled : "..."})
              </p>
              {/* <p className='m-0 h6 text-center'>({incomplete.length})</p> */}
            </div>
          </div>
          <div></div>
        </div>
      </div>

      <div className="mt-3 w-100 r-15">
        {isLoading ? (
          <Grid
            container
            alignItems="center"
            direction="row"
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
            columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
          >
            <SkeletonCardEntry num={10} icon={icon} rows={6} />
          </Grid>
        ) : (
          <>
            <Grid
              container
              alignItems="center"
              direction="row"
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
              columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
            >
              {listIndex == 0 &&
                status === "Por validar" &&
                pending.map((entry) => (
                  <CardEntry
                    info={entry}
                    color={"#58BDDB"}
                    key={entry.folio}
                    type={type}
                    RequestData={RequestData}
                    GetDataEntries={GetDataEntries}
                  />
                ))}
              {listIndex == 1 &&
                status === "Validado" &&
                validated.map((entry) => (
                  <CardEntry
                    info={entry}
                    color={"#7CB963"}
                    key={entry.folio}
                    type={type}
                    RequestData={RequestData}
                    GetDataEntries={GetDataEntries}
                  />
                ))}
              {listIndex == 2 &&
                status === "Incompleto" &&
                incomplete.map((entry) => (
                  <CardEntry
                    info={entry}
                    color={"#EE8C26"}
                    key={entry.folio}
                    type={type}
                    RequestData={RequestData}
                    GetDataEntries={GetDataEntries}
                  />
                ))}
              {listIndex == 3 &&
                status === "Cancelado" &&
                cancelled.map((entry) => (
                  <CardEntry
                    isLoading={isLoading}
                    info={entry}
                    color={"#9B68A6"}
                    key={entry.folio}
                    type={type}
                    RequestData={RequestData}
                    GetDataEntries={GetDataEntries}
                  />
                ))}
            </Grid>

            {data.length == 0 && search != "" ? (
              <div className="d-flex justify-content-center mt-2">
                No se encontraron resultados para "{search}"
              </div>
            ) : (
              data.length == 0 &&
              search == "" && (
                <div className="d-flex justify-content-center mt-2">
                  No tienes entradas aún.
                </div>
              )
            )}

            {/* {data.length == 0 && (
                            <div className="d-flex justify-content-center mt-2">
                                No tienes entradas aún.
                            </div>
                        )} */}
            <ListPagination
              setPage={setPage}
              page={page}
              total={total}
              count={count}
              requestData={getInventoryEntries}
            />
          </>
        )}
        {/* <ListPagination setPage={setPage} page={page} total={total} count={count} /> */}
      </div>
    </>
  );
};
