import React, { useState } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  tableCellClasses,
} from "@mui/material";

import { roles } from "../../helpers/enums";

import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";
import { ListPagination } from "../Pagination/ListPagination";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const TableTransfer = ({
  search,
  data,
  type,
  setPage,
  page,
  total,
  requestData,
  count,
}) => {
  const { height, width } = useViewPortDimensions();

  const tRow = (row, index) => {
    const labelId = `enhanced-table-checkbox-${index}`;
    return (
      <TableRow
        hover
        // onClick={(e) => handleClick(e, row)}
        role="checkbox"
        tabIndex={-1}
        key={row.id}
      >
        {type == roles.ADMIN && (
          <TableCell align="center">{row.client}</TableCell>
        )}
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          align="center"
        >
          {row.id}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" align="center">
          {row.sku}
        </TableCell>
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          align="center"
        >
          {row.product}
        </TableCell>
        <TableCell align="center">
          {row.departure_type == 1 ? row.departure_cedi : row.departure_branch}
        </TableCell>
        <TableCell align="center">
          {row.arrive_type == 1 ? row.arrive_cedi : row.arrive_branch}
        </TableCell>
        <TableCell align="center">{row.amount}</TableCell>
        <TableCell align="center">{row.status}</TableCell>
        <TableCell align="center">{row.creation_date}</TableCell>
        <TableCell align="center">
          {row.departure_date != null ? row.departure_date : "N/A"}
        </TableCell>
        <TableCell align="center">
          {row.arrive_date != null ? row.arrive_date : "N/A"}
        </TableCell>
        {/* <TableCell align="center">{row.departure_date != null ? row.departure_date.split("-").reverse("").join("-") : "N/A"}</TableCell>
                <TableCell align="center">{row.arrive_date != null ? row.arrive_date.split("-").reverse("").join("-") : "N/A"}</TableCell> */}
      </TableRow>
    );
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: height - (74 + 90 + 60) }}>
          <Table sx={{ minWidth: 750 }} aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow>
                {type == roles.ADMIN && (
                  <StyledTableCell align="center">Cliente</StyledTableCell>
                )}
                <StyledTableCell align="center">Folio</StyledTableCell>
                <StyledTableCell align="center">SKU</StyledTableCell>
                <StyledTableCell align="center">Producto</StyledTableCell>
                <StyledTableCell align="center">Origen</StyledTableCell>
                <StyledTableCell align="center">Destino</StyledTableCell>
                <StyledTableCell align="center">Cantidad</StyledTableCell>
                <StyledTableCell align="center">Estatus</StyledTableCell>
                <StyledTableCell align="center">Fecha Creación</StyledTableCell>
                <StyledTableCell align="center">Fecha Salida</StyledTableCell>
                <StyledTableCell align="center">
                  Fecha Recepción
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{data.map((row, index) => tRow(row, index))}</TableBody>
          </Table>
          {data.length == 0 && search != "" ? (
            <div className="d-flex justify-content-center mt-2">
              No se encontraron resultados para "{search}"
            </div>
          ) : (
            data.length == 0 &&
            search == "" && (
              <div className="d-flex justify-content-center mt-2">
                No tienes transferencias aún.
              </div>
            )
          )}
        </TableContainer>
        <ListPagination
          setPage={setPage}
          page={page}
          total={total}
          requestData={requestData}
          count={count}
        />
      </Paper>
    </Box>
  );
};
