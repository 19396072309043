
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthContext } from '../context/AuthProvider'

function RequireAuth({ children }){
  const persistence = localStorage.getItem("token") != "null" ? localStorage.getItem("token") : null;
  const roles = {2:"cliente", 3:"administrador",4:"cliente",5:"cliente",6:"warehouse",8:"headFieldManager"}
  const rol = localStorage.getItem("rol") != "null" ? localStorage.getItem("rol") : null;

  const exeptionsPaths = [
    /^\/administrador\/clientes\/editar\//,
    /^\/administrador\/ventas\/B2C\/detalle\//,
    /^\/administrador\/ventas\/Odoo\/detalle\//,
    /^\/cliente\/productos\/editar\//,
    /^\/cliente\/inventario\/busqueda\/agregar\//,
    /^\/cliente\/ventas\/B2C\/detalle\//,
    /^\/cliente\/ventas\/Odoo\/detalle\//
  ];
  const { pathname } = useLocation();

  let allowSave = true;
  for (const path of exeptionsPaths) {
    if (path.test(pathname)) {
      allowSave = false;
      break;
    }
  }

  
  if (allowSave) {
    localStorage.setItem("pathName", pathname);
  }
  // if (pathname != "/administrador/clientes/editar/"){
  //   localStorage.setItem("pathName", pathname)
  // }
   /* Set principal path from user logged and recovers it in the router */
    
    if (!persistence) { /* If token is null navigate user to login */
      return <Navigate to='/' replace />
    } 
    if (!pathname.match(roles[rol])){ // Path security: if pathname doesn't match with rol redirect to rol dashboard
      return <Navigate to={`/${roles[rol]}`} replace />
    }
    return children
}

export default RequireAuth