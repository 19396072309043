import React, { useState, useEffect } from "react";

import { Grid } from "@mui/material";

import { CardGraph } from "../Card/CardGraph";

const dateTicks = [
  new Date("2015-01-01 10:07:26"),
  new Date("2015-01-01 11:07:26"),
  new Date("2015-01-01 12:07:26"),
];

const options = {
  legend: { position: "none" },
  vAxis: {
    format: "currency",
  },

};

export const SalesGraph = ({ data, isLoading, client }) => {

  return (
    <>
      <Grid item xs={4} sm={4} md={8}>
        <CardGraph
          title={"Ventas"}
          type={"Line"}
          data={data}
          options={options}
          isLoading={isLoading}
          client={client}
        />
      </Grid>
    </>
  );
};
