import React from "react";
import {
  Badge,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
} from "@mui/material";
import { FilterList, Search as SearchIcon } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

export const Search = ({
  label,
  setSearch,
  onClick,
  invisible,
  requestData,
  setPage,
  disabled,
}) => {
  const { pathname } = useLocation();

  const handleOnChange = (e) => {
    setSearch(e.target.value);
    if (setPage) {
      setPage(0);
    }
  };

  return (
    <>
      <Paper
        component="form"
        sx={{
          height: 40,
          display: "flex",
          alignItems: "center",
          borderRadius: 50,
        }}
      >
        <InputBase
          sx={{ ml: 2, width: "100%" }}
          placeholder={label}
          inputProps={{ "aria-label": "search " }}
          onChange={(e) => {
            const value = e.target.value;
            handleOnChange(e);
            if (value === "") {
              requestData();
            }
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              requestData();
            }
          }}
          disabled={disabled}
        />
        <SearchIcon className="text-secondary" />
        {pathname !== "/cliente/inventario/busqueda/" && (
          <>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <Tooltip title="Filtrar" arrow placement="top">
              <IconButton
                sx={{ p: "10px" }}
                aria-label="directions"
                onClick={onClick}
                disabled={disabled}
              >
                <Badge
                  variant="dot"
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "lightgreen",
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                    },
                  }}
                  color={"primary"}
                  invisible={invisible}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <FilterList />
                </Badge>
              </IconButton>
            </Tooltip>
          </>
        )}
      </Paper>
    </>
  );
};
