import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  CheckBoxOutlined,
  InventoryOutlined,
  MopedOutlined,
  ThumbUpAltOutlined,
} from "@mui/icons-material";
import { theme } from "../../utils/theme";

// const steps = ['Listo', 'Asignado', 'En ruta', 'Entregado'];

export default function HorizontalLinearThreeSteper({ activeStep, status }) {
  const steps = [
    { step: "Listo", icon: <InventoryOutlined /> },
    { step: "Finalizado", icon: <ThumbUpAltOutlined /> },
    
  ];
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        sx={{
          "& .MuiStepConnector-line": {
            borderTopWidth: "2px",
            borderColor: status === 6 ? theme.palette.error.main : status === 22 ? "green" : '',
          },

          "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
            borderColor: "#ffe5ad",
          },
        }}
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map((label, index) => {

          return (
            <Step
              key={index}
              sx={{
                ".MuiStepLabel-labelContainer span": {
                  // fontSize: 15
                },
                "& .MuiStepLabel-label, .MuiStepIcon-root":{
                  color: status === 6 ? theme.palette.error.main : status === 22 ? "green" : '',
                },
                "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                  {
                    color: "grey.500", // Just text label (COMPLETED)
                  },
                "& .MuiStepLabel-root .Mui-active": {
                  color: theme.palette.secondary.main, // circle color (ACTIVE)
                },
                "& .MuiStepLabel-root .MuiStepIcon-text": {
                  fill: "#FFFFFF", // circle's number (ACTIVE)
                },
              }}
            >
              <StepLabel>{label.step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
