import React from 'react'
import { Grid, IconButton } from '@mui/material'
import { FlippyCard } from "../../components/FlippyCard";
import { useNavigate } from "react-router-dom";
import ReportsSalesIcon from "../../assets/ReportsSales.svg";
import ReportsInventoryIcon from "../../assets/ReportsInventory.svg";

export const Reports = () => {
  const navigate = useNavigate();
  const rol = localStorage.getItem("rol");
  // const rol = 4

  const renderByRol = () => { /*A function with a "switch case" to render the cards depending by the role */
    switch (rol) {
      case "4":
        return <FlippyCard
          xs={12} sm={12} md={6} lg={6}
          description={"Gráficas de reportes de inventario por: ubicación de productos, más stock, menos stock y entradas."}
          onClick={() => {
            navigate("/cliente/reportes/inventario/")
          }}
          title={"Inventario"}
          icon={
            <IconButton>
              <img src={ReportsInventoryIcon} alt="Existance" width={100} height={100} />
            </IconButton>
          }
        />
      case "5":
        return <FlippyCard
          xs={12} sm={12} md={6} lg={6}
          description={"Gráficas de reportes de venta por: tipo de pedidos, canal, fecha, entre otros."}
          onClick={() => {
            navigate("/cliente/reportes/ventas/")
          }}
          title={"Ventas"}
          icon={
            <IconButton>
              <img
                src={ReportsSalesIcon}
                alt="Existance"
                width={100}
                height={100}
              />
            </IconButton>
          }
        />
      default:
        return <>
          <FlippyCard
            xs={12} sm={12} md={6} lg={6}
            description={"Gráficas de reportes de venta por: tipo de pedidos, canal, fecha, entre otros."}
            onClick={() => {
              navigate("/cliente/reportes/ventas/")
            }}
            title={"Ventas"}
            icon={
              <IconButton>
                <img
                  src={ReportsSalesIcon}
                  alt="Existance"
                  width={100}
                  height={100}
                />
              </IconButton>
            }
          />
          <FlippyCard
            xs={12} sm={12} md={6} lg={6}
            description={"Gráficas de reportes de inventario por: ubicación de productos, más stock, menos stock y entradas."}
            onClick={() => {
              navigate("/cliente/reportes/inventario/")
            }}
            title={"Inventario"}
            icon={
              <IconButton>
                <img src={ReportsInventoryIcon} alt="Existance" width={100} height={100} />
              </IconButton>
            }
          />
        </>
    }
  }

  return (
    <>

      <div className="w-100 px-3">
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
          className="mt-2"
        >
          {
            renderByRol() /*Execute function */
          }
        </Grid>
      </div>
    </>
  );
};