import React from "react";

import { Button, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { theme } from "../../utils/theme";

export const SeeButton = ({
  text,
  onClick,
  target,
  border,
  font,
  component,
  href,
}) => {
  const SeeStyled = styled(Button)({
    boxShadow: "0",
    textTransform: "none",
    fontSize: 14,
    backgroundColor: "transparent",
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:active": {},
    "&:focus": {},
  });

  return (
    <SeeStyled
      target={target}
      component={component}
      href={href}
      variant="text"
      onClick={onClick}
      disableElevation
    >
      <VisibilityIcon />
    </SeeStyled>
  );
};
