import React from 'react'

import { Typography, Box, useMediaQuery } from "@mui/material";

import { AcceptButton } from '../Buttons/AcceptButton';
import { CancelButton } from '../Buttons/CancelButton';
import { CheckCircle } from '@mui/icons-material';

export const ModalWarningTransfer = ({actionCancel, actionProceed, pieces, product, handleClose }) => {
  const phone = useMediaQuery("(max-width:480px)");
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: phone ? "90%" : 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: 2
  };

  return (
    <Box sx={style}>
      <div className='d-flex justify-content-center text-center h5 ds-blue-font'>
            Confirmación transferencia
      </div>
      <div className='d-flex justify-content-center text-center'>
           <p>La solicitud de transferencia de <strong>{pieces}</strong> piezas de <strong>{product}</strong> se realizó correctamente.</p> 
      </div>
      <div className='d-flex justify-content-center mt-2'>
        <CheckCircle sx={{ color: "#6bb120", fontSize: 50, }} />
      </div>
      <>
      <div className='d-flex justify-content-center text-center mt-2'>
          ¿Deseas hacer otra transferencia?
      </div>
      <div className={`d-flex mt-4 ${phone ? "flex-column" : "justify-content-between"}`}>
      <div className={`${phone && "mb-2"}`}>
          <CancelButton text={"Salir"} onClick={() => handleClose("transferencias")} width={phone ? "100%" : ""} />
        </div>
          <AcceptButton text={"Sí"} onClick={() => handleClose("nuevo")} width={phone ? "100%" : ""} />
        </div>
        </>
    </Box>
  )
}
