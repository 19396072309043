import { ProductionQuantityLimitsSharp } from "@mui/icons-material";

export const graphEntriesHeaders = [
    { label: "Estatus", key: "type" },
    { label: "Cantidad", key: "total" },
];

export const graphMoreHeaders = [
    { label: "Producto", key: "product" },
    { label: "Descripción", key: "description" },
    { label: "Cantidad", key: "stock" }
];

export const graphLessHeaders = [
    { label: "Producto", key: "product" },
    { label: "Descripción", key: "description" },
    { label: "Cantidad", key: "stock" }
];

export const graphSalesHeaders = [
    { label: "Fecha", key: "date" },
    { label: "Monto", key: "amount" },
];

export const graphTypeHeaders = [
    { label: "Tipo", key: "type" },
    { label: "Cantidad", key: "total" },
];

export const graphChannelHeaders = [
    { label: "Canal", key: "channel" },
    { label: "Cantidad", key: "total" },
];

export const graphWhsHeaders = [
    { label: "Sucursal", key: "warehouse" },
    { label: "Cantidad", key: "total" }
];

export const graphByProdHeaders = [
    { label: "Productos", key: "decripcion" },
    { label: "Ventas", key: "sales" },
    { label: "Total", key: "total" }
];

export const graphSalesByDayHeaders = [
    { label: "Fecha", key: "date" },
    { label: "Piezas", key: "pieces" },
    { label: "Total", key: "total" }
];

export const graphTopSalesHeaders = [
    { label: "Productos", key: "decripcion" },
    { label: "Ventas", key: "sales" },
    { label: "Total", key: "total" }
];
// export const graphTopSalesHeadersAdmin = [
//     { label: "Productos", key: "decripcion" },
//     { label: "Ventas", key: "sales" },
//     { label: "Total", key: "total" }
// ];

export const graphLessSalesHeaders = [
    { label: "Productos", key: "decripcion" },
    { label: "Ventas", key: "sales" },
    { label: "Total", key: "total" }
];

export const graphByCityHeaders = [
    { label: "Ciudad", key: "city" },
    { label: "Total", key: "total" },
];

export const graphDuringByDayHeaders = [
    { label: "Día", key: "day" },
    { label: "Mañana", key: "morning" },
    { label: "Tarde", key: "afternoon" },
    { label: "Noche", key: "night" },
    { label: "Total", key: "total" }
];

export const graphBranchHeaders = [
    { label: "Sucursal", key: "branch" },
    { label: "Total", key: "items" },
];