import React, { useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  TextField,
  Modal,
  useMediaQuery,
  MenuItem,
  Grid,
  Backdrop,
} from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";

import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";

import { ModalProductSuccess } from "../Modal/ModalProductSuccess";
import { ModalError } from "../Modal/ModalError";

import LoaderDS from "../../assets/LoaderDS.svg";
import { useMemo } from "react";
import { ClientAPI } from "../../api/Client";

export const UserForm = () => {
  const navigate = useNavigate();
  const phone = useMediaQuery("(max-width:480px)");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openBD, setOpenBD] = useState(false);

  const [errorEmailStatus, setErrorEmailStatus] = useState(false);
  const [errorPhoneStatus, setErrorPhoneStatus] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();

  const [errorField, setErrorField] = useState("");

  const open = Boolean(anchorEl);

  const fields = {
    0: [
      { keyName: "Nombre(s)", value: "name", type: true },
      { keyName: "Apellidos", value: "lastName", type: true },
      { keyName: "Rol", value: "rol", type: false },
    ],
    1: [
      { keyName: "Puesto", value: "position", type: true },
      { keyName: "Teléfono", value: "personalNumber", type: true },
      { keyName: "Email", value: "email", type: true },
    ],
  };

  const area = [
    { keyName: "Inventario", value: 4 },
    { keyName: "Ventas", value: 5 },
  ];

  const handleClose = (path) => {
    if (path == "nuevo") {
      window.location.reload();
    } else {
      navigate(`/cliente/usuarios/`);
    }
  };

  //apis
  const clientAPI = useMemo(() => new ClientAPI(), []);

  // const EditUser = async (obj) => {
  //     /***Edit user***/
  //     setOpenBD(true);
  //     let data = new FormData();
  //     const token = localStorage.getItem("token");
  //     // data.append("token", `token ${token}`);

  //     data.append("id_client", location.state.id);
  //     data.append("nombre", obj.name);
  //     data.append("apellidos", obj.lastName);
  //     data.append("email", obj.email);
  //     data.append("rol", obj.rol);
  //     data.append("employee_position", obj.position);
  //     data.append("telefono", obj.personalNumber);

  //     const csrf = getCSRF();

  //     try {
  //         const response = await editUserEP(data, csrf, token);
  //         if (response.status == 200) {
  //             setOpenSuccess(true);
  //             setOpenBD(false);
  //         }
  //     } catch (error) {
  //         console.log(error);
  //         if (error.response.status == 401) {
  //             localStorage.removeItem("token"); /* Remove the token so that the "front" can log out when the token changes, you can't call the log out function because it asks for the token (which doesn't exist anymore). */
  //             window.location.reload();
  //         }

  //         switch (error.response.data) {
  //             case "Ocurrió un error, el usuario no existe o no tiene el rol asignado":
  //                 setErrorField("No se encontró al usuario en la base de datos");
  //                 break;
  //             case "El telefono ya está registrado":
  //                 setErrorPhoneStatus(true);
  //                 setErrorField("El teléfono está asociado a otro usuario");
  //                 break;
  //             case "El email ya está registrado":
  //                 setErrorEmailStatus(true);
  //                 setErrorField("El email está asociado a otro usuario");
  //                 break;
  //         }
  //         setOpenError(true);
  //         setOpenBD(false);
  //     }
  // };

  const AddUser = async (obj) => {
    setOpenBD(true);
    let data = new FormData();

    data.append("email", obj.email);
    data.append("nombre", obj.name);
    data.append("apellidos", obj.lastName);
    data.append("rol", obj.rol);
    data.append("telefono", obj.personalNumber);
    data.append("employee_position", obj.position);

    let response;

    if (location.state != undefined) {
      data.append("id_client", location.state.id);
      response = await clientAPI.editUserEP(data);
    } else {
      response = await clientAPI.addUserEP(data);
    }

    if (response.status == 200 || response.status == 201) {
      setOpenSuccess(true);
      setOpenBD(false);
    } else {
      // console.log(response);
      switch (response.response.data) {
        case "El telefono ya está registrado":
          setErrorPhoneStatus(true);
          setErrorField("El teléfono está asociado a otro usuario");
          break;

        case "El email ya está registrado":
          setErrorEmailStatus(true);
          setErrorField("El email está asociado a otro usuario");
          break;
      }
      setOpenBD(false);
      setOpenError(true);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      location.state != undefined
        ? {
            id: location.state.id,
            name: location.state.name,
            lastName: location.state.lastName,
            email: location.state.email,
            personalNumber: location.state.personalNumber,
            position: location.state.position,
            rol: location.state.rol,
          }
        : {
            name: "",
            lastName: "",
            email: "",
            personalNumber: "",
            position: "",
            rol: "",
          },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Demasiado largo"),
      lastName: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Demasiado largo"),
      email: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Formato incorrecto"
        )
        .max(100, "Demasiado largo"),
      personalNumber: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\d+$/, "Deben ser números sin espacios")
        .min(10, "Demasiado corto")
        .max(50, "Demasiado largo"),
      rol: Yup.string().required("Campo Obligatorio"),
    }),

    onSubmit: (values) => {
      const obj = {
        ...values,
      };

      AddUser(obj);

      // if (location.state != undefined || location.state != null) {
      //     EditUser(obj);
      // } else {
      //     AddUser(obj);
      // }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          rowSpacing={2}
          columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}
        >
          {" "}
          {/*---Contenedor padre---*/}
          <Grid item xs={6}>
            {" "}
            {/*---Ocupara mitad de pantalla---*/}
            <Grid
              item
              xs={12}
              style={{ height: "100%" }}
              className={`border bg-white p-3 r-15`}
            >
              {" "}
              {/*---Altura de 100 para que ocupe verticalmente---*/}
              <div>
                <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
                  Datos Usuario
                </p>
              </div>
              <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
                {fields[0].map((field, i) => (
                  <Grid key={i} item xs={12}>
                    <div
                      key={field.keyName}
                      className={` d-flex mb-3 flex-column ${
                        phone ? "px-2 " : "px-4"
                      }`}
                    >
                      <div
                        className={`d-flex align-items-center mr-2 ${
                          phone && "mb-2"
                        }`}
                        style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                      >
                        <p className="h6" htmlFor={field.value}>{`${
                          field.keyName
                        }: ${field.type ? "*" : ""}`}</p>
                      </div>
                      <div className="d-flex align-items-center w-100">
                        <div className="d-flex flex-column w-100">
                          {field.value == "rol" ? (
                            <TextField
                              disabled={
                                location.state != undefined ||
                                location.state != null
                                  ? true
                                  : false
                              }
                              select
                              size="small"
                              error={
                                formik.touched[field.value] &&
                                formik.errors[field.value]
                              }
                              label={field.keyName}
                              value={formik.values[field.value]}
                              name={field.value}
                              id={field.value}
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                              fullWidth
                              helperText={
                                formik.touched[field.value] &&
                                formik.errors[field.value] ? (
                                  <div>{formik.errors[field.value]}</div>
                                ) : null
                              }
                            >
                              {area.map((el, i) => (
                                <MenuItem key={i} value={el.value}>
                                  {el.keyName}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : (
                            <TextField
                              error={
                                formik.touched[field.value] &&
                                formik.errors[field.value]
                              }
                              size="small"
                              label={field.keyName}
                              variant="outlined"
                              fullWidth
                              onChange={formik.handleChange}
                              value={formik.values[field.value]}
                              name={field.value}
                              id={field.value}
                              helperText={
                                formik.touched[field.value] &&
                                formik.errors[field.value] ? (
                                  <div>{formik.errors[field.value]}</div>
                                ) : null ||
                                  (errorEmailStatus &&
                                    field.value == "email") ? (
                                  <div class="text-danger">
                                    El email esta asociado a otro usuario
                                  </div>
                                ) : null ||
                                  (errorPhoneStatus &&
                                    field.value == "personalNumber") ? (
                                  <div class="text-danger">
                                    El teléfono esta asociado a otro usuario
                                  </div>
                                ) : null
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid
              item
              xs={12}
              className={`border bg-white p-3 r-15`}
              style={{ height: "100%" }}
            >
              <div>
                <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
                  Contacto
                </p>
              </div>
              {fields[1].map((field, i) => (
                <Grid key={i} item xs={12}>
                  <div
                    key={field.keyName}
                    className={` d-flex mb-3 flex-column ${
                      phone ? "px-2 " : "px-4"
                    }`}
                  >
                    <div
                      className={`d-flex align-items-center mr-2 ${
                        phone && "mb-2"
                      }`}
                      style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                    >
                      <p className="h6" htmlFor={field.value}>{`${
                        field.keyName
                      }: ${field.type ? "*" : ""}`}</p>
                    </div>
                    <div className="d-flex align-items-center w-100">
                      <div className="d-flex flex-column w-100">
                        <TextField
                          error={
                            formik.touched[field.value] &&
                            formik.errors[field.value]
                          }
                          size="small"
                          label={field.keyName}
                          variant="outlined"
                          fullWidth
                          onChange={formik.handleChange}
                          value={formik.values[field.value]}
                          name={field.value}
                          id={field.value}
                          helperText={
                            formik.touched[field.value] &&
                            formik.errors[field.value] ? (
                              <div>{formik.errors[field.value]}</div>
                            ) : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <div
          className={`${
            !phone && "d-flex justify-content-end margin-right"
          } mt-2 mb-2`}
        >
          <div className={`${phone && "mb-2"}`}>
            <CancelButton
              text={"Cancelar"}
              onClick={() => {
                navigate("/cliente/usuarios/");
              }}
              width={phone ? "100%" : ""}
            />
          </div>
          <AcceptButton
            text={location.state != undefined ? "Editar" : "Agregar"}
            type={"submit"}
            width={phone ? "100%" : ""}
          />
        </div>
      </form>

      <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalProductSuccess
            handleClose={handleClose}
            text={"Usuario"}
            edit={location.state != undefined ? "editData" : "none"}
          />
        </div>
      </Modal>
      <Modal open={openError} onClose={() => console.log("Cerrando")}>
        <div>
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalError
            text={`No se pudo agregar el usuario.`}
            handleClose={() => setOpenError(false)}
            error={errorField}
          />
        </div>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
