import { useState, useEffect } from 'react';

function getViewPortDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useViewPortDimensions() {
  const [viewPortDimensions, setViewPortDimensions] = useState(getViewPortDimensions());

  useEffect(() => {

    const handleChangeSize = () => {
      setViewPortDimensions(getViewPortDimensions());
    }

    window.addEventListener('resize', handleChangeSize);

    return () => window.removeEventListener('resize', handleChangeSize);

  }, []);

  return viewPortDimensions;
}
