import React, { useState } from "react";

import {
  Button,
  IconButton,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { Upload } from "@mui/icons-material";

import { styled } from "@mui/material/styles";
import ExcelLogo from "../../assets/SVGIcons/ExcelLogo.svg";
import pdfLogo from "../../assets/SVGIcons/pdfLogo.svg";
import { theme } from "../../utils/theme";
import { DeleteDocButton } from "./DeleteDocButton";

export const UploadButton = ({
  text,
  onClick,
  disabled,
  border,
  font,
  className,
  fileLoaded,
  error,
  touched,
  height,
  removeFile,
  width,
  data,
  handleRemove,
}) => {
  const phone = useMediaQuery("(max-width:480px)");

  const UploadButtonStyled = styled(Button)({
    width: width ? width : "100%",
    minHeight: height ? height : "50px",
    boxShadow: "0",
    textTransform: "none",
    fontSize: phone ? "13px" : 16,
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    borderRadius: 5,
    backgroundColor: "transparent",
    borderColor: touched && error ? "#d9534f" : border,
    color: font,
    textAlign: "center",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: theme.palette.secondary.main,
      boxShadow: "0",
      color: theme.palette.secondary.main,
    },
    "&:active": {},
    "&:focus": {},
  });

  const [deleteOption, setDeleteOption] = useState(false);
  return (
    <>
      {data ?(
        <div style={{ width: "100%" }} className="d-flex flex-column align-items-center">
          {Array.from(data).map((file, index) => (
            <div style={{ width: "100%" }} className=" pb-3 d-flex flex-row">
              <UploadButtonStyled
                component={"span"}
                variant="contained"
                disableRipple
                onClick={onClick}
                disabled={disabled}
                disableElevation
              >
                <div className="d-flex align-items-center mr-1">
                  {
                    {
                      "": <Upload />,
                      excel: <img src={ExcelLogo} width="15" className="mr-1" />,
                      pdf: <img src={pdfLogo} width="15" className="mr-1" />,
                    }[fileLoaded]
                  }
                  <Tooltip placement="top" arrow title={file.name.length > 20 ? file.name : ""}>
                    <p className="m-0">
                      {file.name.length > 20 ? `${file.name.substring(0, 15)}...` : file.name}
                    </p>
                  </Tooltip>
                </div>
              </UploadButtonStyled>
              <div className="d-flex justify-content-end">
                <div className="d-flex justify-content-end">
                  <DeleteDocButton
                    onClick={handleRemove}
                    text={"Eliminar"}
                  />    
                </div>
              </div>
            </div>
          ))}
          
        </div>
      ):(<UploadButtonStyled
        component={"span"}
        variant="contained"
        disableRipple
        onClick={onClick}
        disabled={disabled}
        disableElevation
      >
        <div className="d-flex align-items-center">
          {
            {
              "": <Upload />,
              excel: <img src={ExcelLogo} width="15" className="mr-1" />,
              pdf: <img src={pdfLogo} width="15" className="mr-1" />,
            }[fileLoaded]
          }
          <Tooltip placement="top" arrow title={text.length > 20 ? text : ""}>
            <p className="m-0">
              {text.length > 20 ? `${text.substring(0, 15)}...` : text}
            </p>
          </Tooltip>
        </div>
      </UploadButtonStyled>)}
    </>
  );
};
