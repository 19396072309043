import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { MassiveEntriesForm } from "../../components/Forms/MassiveEntriesForm"

export const AddMassiveInventory = () => {
  const phone = useMediaQuery("(max-width:480px)");
    // ver si usaremos este loading ya que noe stamos peticionando ninguna data

  return (
    <>
      <div className="w-100 px-3">
        {/* -----------Header------------ */}
        <div
          className={`mt-3  pl-1 pr-1 w-100 r-15 bg-white ${!phone && "mb-3"}`}
        >
          <p className={`ds-blue-font   ${phone ? "h6" : "h5"}`}>
            Añadir inventario masivo
          </p>
          <p className="text-secondary">* Campos obligatorios</p>
        </div>

        <div className="mt-2 w-100 d-flex justify-content-between r-15 d-flex flex-column">
          <MassiveEntriesForm
          />
        </div>
      </div>
    </>
  );
};
