import { HttpRequest } from "./HttpRequest";
import { getHost } from "../helpers/getHost";

export class SalesOdooAPI {
  constructor() {
    this.http = new HttpRequest();
    this.host = getHost();
    this.token = localStorage.getItem("token");
    this.config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${this.token}`,
      },
    };
  }

  salesListEP(data) {
    for (let entry of data.entries()) {
  }
    return this.http.post(`${this.host}/sale/ws/wmsSales/`, data, this.config);
  }

}
