import { HttpRequest } from "./HttpRequest";
import { getHost } from "../helpers/getHost";

export class SalesB2BAPI {
  constructor() {
    this.http = new HttpRequest();
    this.host = getHost();
    this.token = localStorage.getItem("token");
    this.config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${this.token}`,
      },
    };
  }

  createBusinessEP(data) {
    return this.http.post(
      `${this.host}/b2b/ws/createBusiness/`,
      data,
      this.config
    );
  }

  getCPDataEP(data) {
    return this.http.post(
      `${this.host}/b2b/ws/listPostalCode/`,
      data,
      this.config
    );
  }

  getLastMilesEP(data) {
    return this.http.post(
      `${this.host}/b2b/ws/listLastMile/`,
      data,
      this.config
    );
  }

  getRegimeEP(data) {
    return this.http.post(
      `${this.host}/b2b/ws/listTaxRegime/`,
      data,
      this.config
    );
  }

  updateBusinessEP(data) {
    return this.http.put(
      `${this.host}/b2b/ws/createBusiness/`,
      data,
      this.config
    );
  }

  getBusinessListEP(data) {
    return this.http.post(
      `${this.host}/b2b/ws/listBusinessByClient/`,
      data,
      this.config
    );
  }

  getBusinessListAdminEP(data) {
    return this.http.post(
      `${this.host}/b2b/ws/listBusinessForAdmin/`,
      data,
      this.config
    );
  }

  getCleanBusiness() {
    return this.http.get(
      `${this.host}/b2b/cleanedBusiness/`,
      this.config
    );
  }

  deleteBusinessEP(data) {
    return this.http.post(
      `${this.host}/b2b/ws/desactivateBusiness/`,
      data,
      this.config
    );
  }

  getLocationsEP(data) {
    return this.http.post(
      `${this.host}/b2b/ws/listBusinessLocation/`,
      data,
      this.config
    );
  }

  getCleanLocationsEP(data) {
    return this.http.get(
      `${this.host}/b2b/cleanedBusinessLocation/?business=${data}`,
      this.config
    );
  }

  getStatesByBusiness(data) {
    return this.http.post(
      `${this.host}/b2b/ws/listStatebyLocation/`,
      data,
      this.config
    );
  }

  createLocationEP(data) {
    return this.http.post(
      `${this.host}/b2b/ws/createBusinessLocation/`,
      data,
      this.config
    );
  }

  editLocationEP(data) {
    return this.http.put(
      `${this.host}/b2b/ws/createBusinessLocation/`,
      data,
      this.config
    );
  }

  desactivateLocationEP(data) {
    return this.http.post(
      `${this.host}/b2b/ws/desactivateBusinessLocation/`,
      data,
      this.config
    );
  }

  getSalesEP(data) {
    return this.http.post(
      `${this.host}/b2b/ws/listSalesB2bByClient/`,
      data,
      this.config
    );
  }

  cancelSaleEP(data) {
    return this.http.post(
      `${this.host}/b2b/ws/cancelSaleB2b/`,
      data,
      this.config
    );
  }

  getSaleDetail(data) {
    return this.http.post(`${this.host}/b2b/ws/moreB2b/`, data, this.config);
  }

  createSale(data) {
    return this.http.post(
      `${this.host}/b2b/sale/`,
      data,
      this.config
    );
  }

  getSalesAdminEP(data) {
    return this.http.post(
      `${this.host}/b2b/ws/listSalesB2bForAdmin/`,
      data,
      this.config
    );
  }

  validateStockEP(data) {
    return this.http.post(
      `${this.host}/b2b/ws/validateProducts/`,
      data,
      this.config
    );
  }
}
