import React, { useEffect, useMemo, useState } from "react";

import { useMediaQuery } from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";

import { EntryForm } from "../../components/Forms/EntryForm";
import { InventoryAPI } from "../../api/Inventory";

export const AddInventory = () => {
  const location = useLocation();
  const phone = useMediaQuery("(max-width:480px)");
  const navigate = useNavigate();

  const inventoryAPI = useMemo(() => new InventoryAPI(), []);
  const [isLoading, setIsLoading] = useState(false);
  const [lastEntry, setLastEntry] = useState({
    description: "",
    status: "",
    creation_date: "",
    pieces: "",
    expected_date: "",
  });

  useEffect(() => {
    getInventoryEntries();
  }, []);

  const getInventoryEntries = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("page", 0 + 1);

    const response = await inventoryAPI.inventoryEntriesEP(data);
    if (response.status == 200) {
      setIsLoading(false);
      if (response.data.entries.length != 0) {
        setLastEntry({
          description: response.data.entries[0].description,
          status: response.data.entries[0].status,
          creation_date: response.data.entries[0].creation_date,
          pieces: response.data.entries[0].pieces,
          expected_date: response.data.entries[0].expected_date,
        });
      } else {
        setLastEntry({
          description: "",
          status: "",
          creation_date: "",
          pieces: "",
          expected_date: "",
        });
      }
    } else {
      console.log(response);
    }
  };

  return (
    <>
      <div className="w-100 px-3">
        {/* -----------Header------------ */}
        <div
          className={`mt-3  pl-1 pr-1 w-100 r-15 bg-white ${!phone && "mb-3"}`}
        >
          <p className={`ds-blue-font   ${phone ? "h6" : "h5"}`}>
            Añadir inventario
          </p>
          <p className="text-secondary">* Campos obligatorios</p>
        </div>

        <div className="mt-2 w-100 d-flex justify-content-between r-15 d-flex flex-column">
          <EntryForm
            product={location.state}
            lastEntry={lastEntry}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};
