import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Skeleton,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";

import { roles } from "../../helpers/enums";
import { Pagination } from "../Pagination/Pagination";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const SkeletonTable = ({ num, headers }) => {
  const a = Array(num).fill(true);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 400 }}>
          <Table sx={{ minWidth: 750 }} aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <StyledTableCell align="center" key={index}>
                    {header.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {a.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {headers.map((header, index) => (
                      <TableCell
                        sx={{ margin: "auto", minWidth: 110 }}
                        key={index}
                      >
                        <Skeleton
                          variant={header.variant}
                          width={header.width}
                          height={header.width}
                          sx={{ margin: "auto" }}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="d-flex justify-content-start align-items-center p-3">
          <Typography variant="caption" className="ml-2">
            <Skeleton width={50} />
          </Typography>
          <Skeleton variant="circular" className="ml-4" width={20} />
          <Skeleton variant="circular" className="ml-2" width={20} />
        </div>
      </Paper>
    </Box>
  );
};
