import { createContext, useContext, useMemo, useState } from "react";

import { FilterContext } from "./FilterContext";
import { HeadFieldManagerAPI } from "../api/HeadFieldManager";
export const PickersContext = createContext();

export function PickersContextProvider(props) {
  //------------------------------------------------
  //DATA STATES
  //------------------------------------------------
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0); /*Estado p/paginacion*/
  const [total, setTotal] = useState(); /*Total de paginas enviado desde api*/
  const [count, setCount] = useState(); /*Paginacion listas */
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [success, setSuccess] = useState(false);

  const { branches, branch, pickerAvailability, search } =
    useContext(FilterContext);

  //------------------------------------------------
  //LOADERS STATES
  //------------------------------------------------

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);

  //------------------------------------------------
  //APIS
  //------------------------------------------------

  const hfmAPI = useMemo(() => new HeadFieldManagerAPI(), []);

  //------------------------------------------------
  //REQUEST DATA
  //------------------------------------------------
  const getPickerList = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("page", page + 1);

    if (branch != "Todas") {
      const auxBranch = branches.find((el) => el.sucursal == branch);
      data.append("branch", auxBranch.id);
    }
    if (pickerAvailability != "Todos") {
      const auxAvailable = pickerAvailability == "Conectado" ? true : false;
      data.append("available", auxAvailable);
    }
    if (search != "") {
      data.append("search", search);
    }

    const response = await hfmAPI.pickersListByHfmEP(data);
    if (response.status == 200) {
      setData(response.data.resutl.entries);
      setTotal(response.data.resutl.total);
      setCount(Math.ceil(response.data.resutl.total / 10));
      setIsLoading(false);
    } else {
      console.log(response);
    }
  };

  const deletePicker = async (picker) => {
    setIsLoadingDelete(true);
    let data = new FormData();

    data.append("picker", picker.id);
    data.append("status", false);

    const response = await hfmAPI.desactivatePickerEP(data);
    if (response.status == 200) {
      setSuccess(true);
      setIsLoadingDelete(false);
    } else {
      console.log(response);
      setError(true);
      setErrorText(error.response.data.msg || "");
      setIsLoadingDelete(false);
    }
  };

  const addPicker = async (obj, location) => {
    console.log("se ejecuta la función");
    setIsLoadingAdd(true);
    let data = new FormData();

    data.append("name", obj.name);
    data.append("lastName", obj.lastName);
    data.append("email", obj.email);
    data.append("personalNumber", obj.personalNumber);
    data.append("branch", obj.branchId);
    data.append("photo", obj.photoFile);
    data.append("picker_code", obj.employeeNumber);

    if (obj.changePassword) {
      data.append("password", obj.password);
    }
    let response;

    if (location.state != undefined) {
      data.append("id", location.state.id);
      response = await hfmAPI.editPickerEP(data);
    } else {
      response = await hfmAPI.createPickerEP(data);
    }

    if (response.status == 200) {
      setSuccess(true);
      setIsLoadingAdd(false);
    } else {
      console.log(response);
      setIsLoadingAdd(false);
      setError(true);
      setErrorText(
        error.response.data.detail ? error.response.data.detail : ""
      );
    }
  };

  return (
    <PickersContext.Provider
      value={{
        getPickerList,
        data,
        total,
        count,
        isLoading,
        isLoadingDelete,
        isLoadingAdd,
        page,
        setPage,
        setTotal,
        deletePicker,
        error,
        setError,
        errorText,
        setErrorText,
        success,
        setSuccess,
        addPicker,
      }}
    >
      {props.children}
    </PickersContext.Provider>
  );
}
