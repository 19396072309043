import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { styled } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
  Grid,
  useMediaQuery,
  tableCellClasses,
  Modal,
} from "@mui/material";
import {
  LocalShippingRounded,
  WarehouseRounded,
  WhereToVoteRounded,
} from "@mui/icons-material";
import HorizontalLinearThreeSteper from "../../components/Stepper/HorizontalLinearThreeSteps";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 5,
  height: "100%",
  // minHeight: 270,
  // display: "flex",
}));

export const DetailSalesOdoo = () => {
  const [stepperState, setStepperState] = useState(null)
  const [statusStepperState, setStatusStepperState] = useState(null)
  const phone = useMediaQuery("(max-width:480px)");
  const location = useLocation();
  const { name } = location.state;
  const { statusId } = location.state;
  const { status } = location.state;
  const { client } = location.state;
  const { sucursal } = location.state;
  const { creation_date } = location.state;
  const { total } = location.state;
  const { products } = location.state;

  useEffect(() => {
    if (statusId === 4) {
      setStepperState(null);
      setStatusStepperState(6);
    }else{
      if (statusId === 2) {
        setStepperState(null);
        setStatusStepperState(22)
      }else{
        setStepperState(statusId);
        setStatusStepperState(statusId)
      }
    }
  }, [statusId]);
  

  const trakingCardsMarketPlace = [
    
    {
      title: "Generado",
      icon: (
        <LocalShippingRounded
  
          sx={{ fontSize: 60 }}
          // className={activeStep === 4 ? "ds-yellow-font" : "inactive-font" || activeStep === 2 ? "ds-blue-font": "inactive-font"}
          className={statusId === 1|| statusId === 3 ? "ds-yellow-font" : statusId === 2 ? "ds-green-font": statusId === 4 ? "ds-ref-font" : "inactive-font"}
        />
      ),
    },
    {
      title: "Finalizado",
      icon: (
        <WhereToVoteRounded
  
          sx={{ fontSize: 60 }}
          // className={activeStep === 4 ? "ds-yellow-font" : "inactive-font" || activeStep === 2 ? "ds-blue-font": "inactive-font"}
          className={statusId === 1 ? "ds-blue-font" : statusId === 3 ? "ds-yellow-font": statusId === 4 ? "ds-ref-font" : statusId===2 ? "ds-green-font" : "inactive-font"}
        />
      ),
    },
  ];

  return (
  //  <div>HOla jajaja {name}</div>
  <>
  <div className="w-100 px-3 mt-3 ">
    <Grid
      container
      spacing={2}
      direction="row"
      columns={{ xs: 6, sm: 6, md: 12, lg: 12 }}
    >
      <Grid item xs={6} sm={6} md={4}>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ height: "100%" }}>
          <Item>
            <p
              className={`font-weight-bold text-center ${
              phone ? "h6" : "h5"
              }`}
            >
              Detalles de la venta
            </p>
            <div className={`mt-2`}>
              <div className={statusId === 6 && "inactive-font"}>
                <p className="m-1">
                  Nombre:{" "}
                  <span className="font-weight-bold">
                    {name}{" "}
                  </span>{" "}
                </p>
                <p className="m-1">
                  Cliente:{" "}
                  <span className="font-weight-bold">
                    {client}
                  </span>{" "}
                </p>
                <p className="m-1">
                  Sucursal:{" "}
                  <span className="font-weight-bold">
                    {sucursal}
                  </span>{" "}
                </p>
                <p className="m-1">
                  Fecha de creación:{" "}
                  <span className="font-weight-bold">
                    {creation_date}
                  </span>{" "}
                </p>
                <p className="m-1">
                  Total:{" "}
                  <span className="font-weight-bold">
                    {total || "No disponible"}
                  </span>{" "}
                </p>
                <p className="m-1">
                  Estatus:{" "}
                  <span className="font-weight-bold">
                    {status}
                  </span>
                </p>
              </div>

            </div>
          </Item>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={6} md={8}>
        <Grid item xs={12} sx={{ height: "100%" }}>
          <Item className="w-100" sx={{ minHeight: 220 }}>
              <div className="w-100 d-flex flex-column align-items-center">
                <p className={`font-weight-bold ${phone ? "h6" : "h5"}`}>
                  Tracking de envío
                </p>
              </div>
              <div className="mt-2">
                <div>
                  <div className={`d-flex justify-content-between ${statusId === 4 && "ds-ref-font"}`}>
                    {trakingCardsMarketPlace.map((el, i) => (
                      <div
                        className="d-flex flex-column align-items-center justify-content-between w-30 text-center"
                        key={i}
                      >
                        <div>
                          <div className={`${ statusId === 2 ? "ds-green-font" : statusId !== 4 ? "ds-blue-font" :"ds-ref-font"} font-weight-bold`}>
                            {el.title}
                          </div>
                          {el.icon}
                        </div>

                      </div>
                    ))}
                  </div>
                  {
                    statusId === 4 && <p className="ds-ref-font text-center">Pedido Cancelado</p>
                  }
                  <HorizontalLinearThreeSteper activeStep={stepperState} status={statusStepperState}/>
                </div>

              </div>
          </Item>
        </Grid>
        
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ width: "100%" }}>
          <Paper className="px-2" sx={{ width: "100%" }}>
            <div className="pt-2 w-100 d-flex justify-content-center">
              <p className={`font-weight-bold ml-2 ${phone ? "h6" : "h5"}`}>
                Artículos
              </p>
            </div>
            <TableContainer sx={{ maxHeight: "41vh" }}>
              <Table aria-label="sticky table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">SKU</StyledTableCell>
                    <StyledTableCell align="center">
                      Producto
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Cantidad
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <span style={{ whiteSpace: "nowrap" }}>
                        Precio por unidad
                      </span>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((item, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                      key={index}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="center"
                        >
                          {item.sku}
                        </TableCell>
                        <TableCell align="center">
                          {item.description}
                        </TableCell>
                        <TableCell align="center">
                          {item.pieces}
                        </TableCell>
                        <TableCell align="center">
                          {item.price_unit}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>

      </Grid>
    </Grid>
  </div>
  </>
  );
};
