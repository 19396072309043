import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  useMediaQuery,
  Grid,
  Divider,
  MenuItem,
  CircularProgress,
  Backdrop,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";

import { getCSRF } from "../../helpers/getCSRF";

import { WarehouseAPI } from "../../api/Warehouse";
import { TransferAPI } from "../../api/Transfer";
import { ProductsAPI } from "../../api/Products";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useMemo } from "react";
import { theme } from "../../utils/theme";
import dayjs from "dayjs";

export const B2BForm = () => {
  const navigate = useNavigate();
  const phone = useMediaQuery("(max-width:480px)");
  const today = new Date();

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorField, setErrorField] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openBD, setOpenBD] = useState(false);
  const [products, setProducts] = useState([]);
  const [prodString, setProdString] = useState("");
  const [idProduct, setIdProduct] = useState(0);
  const [pieces, setPieces] = useState(0);

  const [branches, setBranches] = useState([]);
  const [branchesTotal, setBranchesTotal] = useState([]);
  const [cedis, setCedis] = useState([]);
  const [cedisTotal, setCedisTotal] = useState([]);

  const [cedisList, setCedisList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [departureType, setDepartureType] = useState();
  const [arriveType, setArriveType] = useState();
  const [errorQuantity, setErrorQuantity] = useState(false);

  const fields = [
    { keyName: "Nombre", value: "name", type: true },
    { keyName: "Calle y número", value: "street", type: true },
    { keyName: "Colonia", value: "suburb", type: true },
    { keyName: "C.P.", value: "postalCode", type: true },
  ];

  const dates = [
    { keyName: "Fecha salida", value: "dateDeparture", type: true },
    { keyName: "Fecha llegada", value: "dateArrive", type: true },
  ];

  const available =
    cedisTotal != 0
      ? cedisTotal
      : null || branchesTotal != 0
      ? branchesTotal
      : null;

  useEffect(() => {
    GetProductsListCleaned();
  }, []);

  useEffect(() => {
    if (idProduct != 0) {
      GetProductWarehouses();
      GetBranchesList();
      GetCedisList();
    }
  }, [idProduct]);

  // APIS
  const productAPI = useMemo(() => new ProductsAPI(), []);
  const warehouseAPI = useMemo(() => new WarehouseAPI(), []);
  const transferAPI = useMemo(() => new TransferAPI(), []);

  const GetProductsListCleaned = async () => {
    setIsLoading(true);
    let data = new FormData();

    const response = await productAPI.productsListCleanedEP(data);
    if (response.status == 200) {
      setProducts(response.data.products);
    } else {
      console.log(response);
    }
  };

  const GetProductWarehouses = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("product", idProduct);

    const response = await productAPI.warehousesByProduct(data);
    if (response.status == 200) {
      if (response.data.branches.length != 0) {
        setBranches(response.data.branches);
        setBranchesTotal(response.data.branches_total[0].total);
      } else {
        setBranches([{ id: 0, sucursal: "No hay Existencias" }]);
      }

      if (response.data.cedis.length != 0) {
        setCedis(response.data.cedis);
        setCedisTotal(response.data.cedis_total[0].total);
      } else {
        setCedis([{ id: 0, cedi: "No hay Existencias" }]);
      }
      setIsLoading(false);
    } else {
      console.log(response);
    }
  };

  const GetCedisList = async () => {
    let data = new FormData();

    const response = await warehouseAPI.cedisList(data);
    if (response.status == 200) {
      setCedisList(response.data.cedis);
    } else {
      console.log(response);
    }
  };

  const GetBranchesList = async () => {
    let data = new FormData();

    const response = await warehouseAPI.getBranchesList(data);
    if (response.status == 200) {
      setBranchList(response.data.branches);
    } else {
      console.log(response);
    }
  };

  const CreateInventoryTransfer = async (obj) => {
    setOpenBD(true);
    let data = new FormData();

    data.append("product", idProduct);
    data.append(
      "departure_branch",
      obj.departure == 1 ? "" : obj.branchDeparture
    );
    data.append("departure_cedi", obj.departure == 2 ? "" : obj.cediDeparture);
    data.append("arrive_branch", obj.arrive == 1 ? "" : obj.branchArrive);
    data.append("arrive_cedi", obj.arrive == 2 ? "" : obj.cediArrive);
    data.append("amount", parseInt(obj.pieces));
    data.append("departure_type", obj.departure);
    data.append("arrive_type", obj.arrive);

    const response = await transferAPI.inventoryTransferEP(data);
    if (response.status == 200) {
      setOpenBD(false);
      setOpenSuccess(true);
    } else {
      // console.log(response);
      setOpenBD(false);
      setOpenError(true);
      if (response.response.data.non_field_errors) {
        setErrorField(response.response.data.non_field_errors[0]);
      }
    }
  };

  const handlePieces = (e) => {
    setPieces(e.target.value);
  };

  const handleClose = (path) => {
    if (path == "nuevo") {
      window.location.reload();
    } else {
      navigate("/cliente/inventario/");
    }
  };

  const handleProduct = (e) => {
    setIdProduct(e.target.value);
    const aux = products.find((el) => el.id == e.target.value);
    setProdString(aux.name);
  };

  const onKeyDown = (e) => {
    //Deshabilitar entrada de fecha por teclado
    e.preventDefault();
  };

  const departure = [
    { keyName: "Cedi", type: 1, value: true },
    { keyName: "Sucursal", type: 2, value: true },
  ];

  const [checkDeparture, setCheckDeparture] = useState({
    cedi: false,
    branch: false,
  });

  const [checkArrive, setCheckArrive] = useState({
    cedi: false,
    branch: false,
  });

  const handleDeparture = (e) => {
    if (e.target.value == 1) {
      setCheckDeparture({ cedi: true });
    } else {
      setCheckDeparture({ branch: true });
    }
  };

  const handleArrive = (e) => {
    setArriveType(e.target.value);
    if (e.target.value == 1) {
      setCheckArrive({ cedi: true });
    } else {
      setCheckArrive({ branch: true });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      products: "",
      pieces: "",

      branchDeparture: "",
      cediDeparture: "",

      branchArrive: "",
      cediArrive: "",

      departure: "",
      arrive: "",

      dateDeparture: null,
      dateArrive: null,

      name: "",
      suburb: "",
      street: "",
      postalCode: "",
    },

    validationSchema: Yup.object({
      products: Yup.string().required("Campo obligatorio"),
      pieces: Yup.number()
        .required("Campo obligatorio")
        .typeError("Debe ser un número")
        .min(1, "Debe ser un número mayor a 1")
        .integer("Debe ser número entero"),
      departure: Yup.string().required("Campo obligatorio"),
      arrive: Yup.string().required("Campo obligatorio"),
      dateDeparture: Yup.string().required("Campo Obligatorio"),
      dateArrive: Yup.string().required("Campo Obligatorio"),
      name: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(150, "Demasiado largo"),
      street: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(50, "Demasiado largo"),
      suburb: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(50, "Demasiado largo"),
      postalCode: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\d+$/, "Deben ser números sin espacios")
        .min(5, "Ingresa un Código Postal válido")
        .max(5, "Demasiado largo"),
    }),
    onSubmit: (values) => {
      const obj = {
        ...values,
      };
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Divider className="mb-4">Productos</Divider>
        <Grid container columns={{ xs: 3, sm: 3, md: 6 }}>
          <Grid item xs={3}>
            <div
              key={"products"}
              className={` d-flex mb-3 ${phone ? "px-2 flex-column" : "px-4"}`}
            >
              <div
                className={`d-flex align-items-center mr-2 ${phone && "mb-2"}`}
                style={{ minWidth: `${phone ? "125px" : "160px"}` }}
              >
                <p
                  className="m-0 no-wrap h6"
                  htmlFor={"products"}
                >{`${"Productos"}: ${"*"}`}</p>
              </div>
              <div className="d-flex align-items-center w-100">
                <TextField
                  select
                  size="small"
                  error={formik.touched.products && formik.errors.products}
                  label="Productos"
                  value={formik.values.products}
                  name={"products"}
                  id={"products"}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleProduct(e);
                  }}
                  fullWidth
                  helperText={
                    formik.touched.products && formik.errors.products ? (
                      <div>{formik.errors.products}</div>
                    ) : null
                  }
                >
                  {/***  <Search /> ***/}

                  {products.map((product) => (
                    <MenuItem key={product.id} value={product.id}>
                      {product.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>

            {idProduct != 0 ? (
              <div className="d-flex border-left border-warning pl-2 ml-3 bw-5 align-items-center">
                Disponible:{" "}
                {isLoading ? (
                  <div className="w-25 align-items-center h-100 ml-3">
                    <CircularProgress
                      sx={{ color: theme.palette.primary.main }}
                      size={20}
                    />
                  </div>
                ) : (
                  `${available == null ? "Sin existencias" : available}`
                )}
              </div>
            ) : null}

            {/* <Chip icon={<FaceIcon />} label={`Disponible: ${isLoading ? "..." : `${available == null ? "Sin existencias" : available}`}`} /> */}
          </Grid>

          <Grid item xs={3}>
            <div
              key={"Piezas"}
              className={` d-flex mb-3 ${phone ? "px-2 flex-column" : "px-4"}`}
            >
              <div
                className={`d-flex align-items-center mr-2 ${phone && "mb-2"}`}
                style={{ minWidth: `${phone ? "125px" : "160px"}` }}
              >
                <label
                  className="m-0 no-wrap"
                  htmlFor={"pieces"}
                >{`${"Cantidad"}:* `}</label>
              </div>
              <div className="d-flex flex-column align-items-center w-100">
                <div className="d-flex flex-column w-100">
                  <TextField
                    error={formik.touched.pieces && formik.errors.pieces}
                    size="small"
                    label={"Piezas"}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      formik.handleChange(e);
                      handlePieces(e);
                    }}
                    value={formik.values.pieces}
                    name={"pieces"}
                    id={"pieces"}
                    helperText={
                      formik.touched.pieces && formik.errors.pieces ? (
                        <div>{formik.errors.pieces}</div>
                      ) : null
                    }
                  />
                </div>
                <div className="text-danger xs-font">
                  {errorQuantity
                    ? "Estás tratando de transferir más piezas de las disponibles"
                    : null}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Divider className="mb-4">Origen</Divider>

        <Grid container columns={{ xs: 3, sm: 3, md: 6 }}>
          <Grid item xs={3}>
            <div
              key={"salida"}
              className={` d-flex mb-3 ${phone ? "px-2 flex-column" : "px-4"}`}
            >
              <div
                className={`d-flex align-items-center mr-2 ${phone && "mb-2"}`}
                style={{ minWidth: `${phone ? "125px" : "160px"}` }}
              >
                <p
                  className="m-0 no-wrap h6"
                  htmlFor={"departure"}
                >{`${"Salida"}: ${"*"}`}</p>
              </div>

              <div className="d-flex align-items-center w-100">
                <TextField
                  select
                  size="small"
                  error={formik.touched.departure && formik.errors.departure}
                  label="Salida"
                  value={formik.values.departure}
                  name={"departure"}
                  id={"departure"}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleDeparture(e);
                  }}
                  fullWidth
                  helperText={
                    formik.touched.departure && formik.errors.departure ? (
                      <div>{formik.errors.departure}</div>
                    ) : null
                  }
                >
                  {/***  <Search /> ***/}

                  {departure.map((dep) => (
                    <MenuItem key={dep.keyName} value={dep.type}>
                      {dep.keyName}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          </Grid>

          {/* LISTA DE SUCURSALES */}
          <Grid item xs={3}>
            <div
              className={` d-flex mb-3 ${phone ? "px-2 flex-column" : "px-4"}`}
            >
              <div
                className={`d-flex align-items-center mr-2 ${phone && "mb-2"}`}
                style={{ minWidth: `${phone ? "125px" : "160px"}` }}
              >
                <p></p>
              </div>

              {
                // check.branchDeparture ? null : check.cediDeparture ? (
                checkDeparture.cedi ? (
                  <div className="d-flex align-items-center w-100">
                    <TextField
                      select
                      size="small"
                      error={
                        formik.touched.cediDeparture &&
                        formik.errors.cediDeparture
                      }
                      label="Cedi"
                      value={formik.values.cediDeparture}
                      name={"cediDeparture"}
                      id={"cediDeparture"}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      fullWidth
                      helperText={
                        formik.touched.cediDeparture &&
                        formik.errors.cediDeparture ? (
                          <div>{formik.errors.cediDeparture}</div>
                        ) : null
                      }
                    >
                      {idProduct == 0 ? (
                        <MenuItem>Selecciona un producto</MenuItem>
                      ) : (
                        cedis.map((cedi) => (
                          <MenuItem key={cedi.id} value={cedi.id}>
                            {cedi.cedi}
                          </MenuItem>
                        ))
                      )}
                    </TextField>
                  </div>
                ) : null
                // </div>
              }

              {
                // check.cediDeparture ? null : check.branchDeparture ? (
                checkDeparture.branch ? (
                  <div className="d-flex align-items-center w-100">
                    <TextField
                      select
                      size="small"
                      error={
                        formik.touched.branchDeparture &&
                        formik.errors.branchDeparture
                      }
                      label="Sucursal"
                      value={formik.values.branchDeparture}
                      name={"branchDeparture"}
                      id={"branchDeparture"}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      fullWidth
                      helperText={
                        formik.touched.branchDeparture &&
                        formik.errors.branchDeparture ? (
                          <div>{formik.errors.branchDeparture}</div>
                        ) : null
                      }
                    >
                      {idProduct == 0 ? (
                        <MenuItem>Selecciona un producto</MenuItem>
                      ) : (
                        branches.map((branch) => (
                          <MenuItem key={branch.id} value={branch.id}>
                            {branch.sucursal}
                          </MenuItem>
                        ))
                      )}
                    </TextField>
                  </div>
                ) : null
              }
            </div>
          </Grid>

          {/* <div className="d-flex w-100 "> */}
          <Grid item xs={3}>
            <div
              className={`d-flex mb-3 ${phone ? "px-2 flex-column" : "px-4"}`}
            >
              <div
                className={`d-flex align-items-center mr-2 ${phone && "mb-2"}`}
                style={{ minWidth: `${phone ? "125px" : "160px"}` }}
              >
                <p className="m-0">Fecha salida:</p>
              </div>

              <div className="d-flex align-items-center w-100">
                <DesktopDatePicker
                  //allowSameDateSelection
                  minDate={dayjs(today)}
                  label={"Fecha salida"}
                  format="DD/MM/YYYY"
                  value={formik.values.dateDeparture}
                  onChange={(value) => {
                    formik.setFieldValue("dateDeparture", value);
                  }}
                  defaultValue={dayjs(today)}
                  slotProps={{
                    textField: {
                      size: "small",
                      error:
                        formik.touched.dateDeparture &&
                        formik.errors.dateDeparture,
                      fullWidth: true,
                      onKeyDown: onKeyDown,
                    },
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div
              className={` d-flex mb-3 ${phone ? "px-2 flex-column" : "px-4"}`}
            >
              <div
                className={`d-flex align-items-center mr-2 ${phone && "mb-2"}`}
                style={{ minWidth: `${phone ? "125px" : "160px"}` }}
              >
                <p className="m-0">Fecha llegada: </p>
              </div>

              <div className="d-flex w-100">
                <DesktopDatePicker
                  allowSameDateSelection
                  minDate={dayjs(today)}
                  label={"Fecha de llegada"}
                  format="DD/MM/YYYY"
                  value={formik.values.dateArrive}
                  onChange={(value) => {
                    formik.setFieldValue("dateArrive", value);
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      error:
                        formik.touched.dateArrive && formik.errors.dateArrive,
                      fullWidth: true,
                      onKeyDown: onKeyDown,
                    },
                  }}
                />
              </div>
            </div>
          </Grid>

          {/* </div> */}
        </Grid>

        <Divider className="mb-4">Recepción</Divider>

        <Grid container columns={{ xs: 3, sm: 3, md: 6 }}>
          {fields.map((field) => (
            <Grid item xs={3}>
              <div
                key={field.keyName}
                className={` d-flex mb-3 ${
                  phone ? "px-2 flex-column" : "px-4"
                }`}
              >
                <div
                  className={`d-flex align-items-center mr-2 ${
                    phone && "mb-2"
                  }`}
                  style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                >
                  <p className="m-0 h6" htmlFor={field.value}>{`${
                    field.keyName
                  }: ${field.type ? "*" : ""}`}</p>
                </div>
                <div className="d-flex align-items-center w-100">
                  <div className="d-flex flex-column w-100">
                    <TextField
                      error={
                        formik.touched[field.value] &&
                        formik.errors[field.value]
                      }
                      size="small"
                      label={field.keyName}
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values[field.value]}
                      name={field.value}
                      id={field.value}
                      helperText={
                        formik.touched[field.value] &&
                        formik.errors[field.value] ? (
                          <div>{formik.errors[field.value]}</div>
                        ) : null
                      }
                    />
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>

        <div
          className={`${!phone && "d-flex justify-content-end margin-right"}`}
        >
          <div className={`${phone && "mb-2"}`}>
            <CancelButton
              text={"Cancelar"}
              onClick={() => {
                navigate("/administrador/inventario/");
              }}
              width={phone ? "100%" : ""}
            />
          </div>
          <AcceptButton
            text={"Enviar"}
            type={"submit"}
            width={phone ? "100%" : ""}
          />
        </div>
      </form>

      {/* <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <ModalWarningTransfer
          handleClose={handleClose}
          product={prodString}
          pieces={pieces}
        />
      </Modal>

      <Modal open={openError} onClose={() => console.log("Cerrando")}>
        <ModalError
          text={`No se pudo actualizar la información inténtalo después.`}
          handleClose={() => setOpenError(false)}
          error={errorField}
        />
      </Modal> */}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <CircularProgress
          sx={{ color: theme.palette.primary.main }}
          size={100}
          thickness={4}
        />
      </Backdrop>
    </>
  );
};
