import { Grid } from "@mui/material";
import Logo from "../../assets/branding/boxai_logo.svg";
import { roles } from "../../helpers/enums";

export const ReportHeader = ({ title, filters }) => {
  const company = localStorage.getItem("razon_social");
  const rol = localStorage.getItem("rol");

  return (
    <Grid item xs={12}>
      <div className="report-header p-3">
        <img src={Logo} alt="Logo" width={150} />
        <h4 className="font-weight-bold medium-font my-2">{title}</h4>
        {rol != roles.ADMIN && <p>{company}</p>}
        {filters?.Fecha && <p>Generado: {filters.Fecha}</p>}
        {filters?.["Fecha fin"] && filters["Fecha inicio"] && (
          <p>
            Periodo: {filters["Fecha inicio"]} - {filters["Fecha fin"]}{" "}
          </p>
        )}
        {filters?.Producto && <p>Producto: {filters.Producto}</p>}
        {filters?.Almacén && <p>Sucursal: {filters.Almacén}</p>}
        {filters?.Canal && <p>Canal: {filters.Canal}</p>}
        {filters?.Cliente && <p>Cliente: {filters.Cliente}</p>}
      </div>
    </Grid>
  );
};
