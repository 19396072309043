import React from "react";

import { Typography, Box, useMediaQuery } from "@mui/material";

import { CheckCircle } from "@mui/icons-material";
import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";

export const ModalSuccess = ({ text, auxText, handleClose, action }) => {
  const phone = useMediaQuery("(max-width:480px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "95%" : 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
  };

  return (
    <Box sx={style}>
      <div className="d-flex justify-content-center text-center">
        <Typography id="modal-modal-description">{text}</Typography>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <CheckCircle sx={{ color: "#6bb120", fontSize: 50 }} />
      </div>
      {action === "add" ? (
        <>
          <div className="d-flex justify-content-center mt-2">{auxText}</div>
          <div
            className={`d-flex mt-3 ${
              phone ? "flex-column" : "justify-content-between"
            }`}
          >
            <div className={`${phone && "mb-2"}`}>
              <CancelButton
                text={"Salir"}
                onClick={() => handleClose("")}
                width={phone ? "100%" : ""}
              />
            </div>
            <AcceptButton
              text={"Sí"}
              onClick={() => handleClose("nuevo")}
              width={phone ? "100%" : ""}
            />
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center mt-3">
          <AcceptButton onClick={() => handleClose("")} text={"Aceptar"} />
          {/* <CancelButton text={"Salir"} onClick={() => handleClose("")}  /> */}
        </div>
      )}
    </Box>
  );
};
