import { Typography, Box, useMediaQuery } from "@mui/material";

import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";

import { sumBy, values } from "lodash";

export const ModalWarning = ({
  actionCancel,
  actionProceed,
  product,
  fileData,
  submitData,
  type,
}) => {
  const phone = useMediaQuery("(max-width:480px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "90%" : 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
  };
  return (
    <Box sx={style}>
      <div className="d-flex justify-content-center">
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="ds-blue-font"
        >
          Confirmar entrada
        </Typography>
      </div>
      <div className="d-flex justify-content-center">
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {
            {
              entries: (
                <>
                  {fileData ? (
                    <>
                      <p className="m-0">
                        Productos: <strong>{fileData.length}</strong>
                      </p>
                      <p className="m-0">
                        Piezas: <strong>{sumBy(fileData, "piezas")}</strong>
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="m-0">
                        Producto: <strong>{product}</strong>
                      </p>
                      <p className="m-0">
                        Piezas: <strong>{submitData.pieces}</strong>
                      </p>
                    </>
                  )}

                  {submitData.branch ? (
                    <p className="m-0">
                      Sucursal: <strong>{submitData.branch.sucursal}</strong>
                    </p>
                  ) : submitData.cedi ? (
                    <p className="m-0">
                      Cedi: <strong>{submitData.cedi.cedi}</strong>
                    </p>
                  ) : null}

                  <p className="m-0">
                    Fecha de recepción: <strong>{submitData.date}</strong>
                  </p>
                </>
              ),
              products: (
                <>
                  {fileData ? (
                    <>
                      <p className="m-0">
                        Productos: <strong>{fileData.length}</strong>
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="m-0">
                        Producto: <strong>{submitData.description}</strong>
                      </p>
                      <p className="m-0">
                        SKU: <strong>{submitData.sku}</strong>
                      </p>
                      <p className="m-0">
                        Código de barras: <strong>{submitData.barCode}</strong>
                      </p>
                      <p className="m-0">
                        Precio: <strong>{submitData.price}</strong>
                      </p>
                      <p className="m-0">
                        Peso: <strong>{submitData.weight}</strong>
                      </p>
                      <p className="m-0">
                        Dimensiones:{" "}
                        <strong>{`${submitData.height}x${submitData.width}x${submitData.depth}`}</strong>
                      </p>
                    </>
                  )}
                </>
              ),
            }[type]
          }
        </Typography>
      </div>
      <div
        className={`d-flex mt-4 ${
          phone ? "flex-column" : "justify-content-between"
        }`}
      >
        <div className={`${phone && "mb-2"}`}>
          <CancelButton
            text={"Cancelar"}
            onClick={actionCancel}
            type={"text"}
            width={phone ? "100%" : ""}
          />
        </div>
        <AcceptButton
          text={"Continuar"}
          onClick={actionProceed}
          type={"text"}
          width={phone ? "100%" : ""}
        />
      </div>
    </Box>
  );
};
