import Box from "@mui/material/Box";
import { IconButton, useMediaQuery } from "@mui/material";
import {
  Close,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { DetailSales } from "../../pages/Sales/DetailSales";

export default function ModalDetailSales({ id, handleClose }) {
  const phone = useMediaQuery("(max-width:600px)");
  const tablet = useMediaQuery("(max-width:900px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "95%" : "75%",
    maxHeight: "95vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "between",
    // padding: 1
    overflow: "auto",
  };

  return (
    <Box sx={style}>
      <div className="w-100 d-flex justify-content-end">
        <IconButton onClick={handleClose}>
          <Close sx={{ fontSize: 35 }} />
        </IconButton>
      </div>

      {/* <h1>DETALLE VENTAS</h1>*/}
      <div className="w-100 d-flex justify-content-center">
        <DetailSales idModal={id} source={true} />
      </div>
    </Box>
  );
}
//#view=fitH

// #toolbar=0
