import React, { useState, useEffect } from "react";

import {
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
  Popover,
} from "@mui/material";
import { Search } from "../../components/Search";
import { AddButton } from "../../components/AddButton";
import { ToggleView } from "../../components/ToggleView";
import { CardProductEntry } from "../../components/Card/CardProductEntry";

import { getCSRF } from "../../helpers/getCSRF";

import { SkeletonCardSearchInventory } from "../../components/Skeleton/SkeletonCardSearchInventory";
import { ListPagination } from "../../components/Pagination/ListPagination";
import { ProductsAPI } from "../../api/Products";

export const SearchAddInventory = () => {
  const phone = useMediaQuery("(max-width:480px)");

  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [page, setPage] = useState(0); /*Estado p/paginacion*/
  const [total, setTotal] = useState(); /*Total de paginas enviado desde api*/
  const [count, setCount] = useState(); /*Paginacion listas */
  const [ready, setReady] = useState(false); /*Paginacion listas */

  useEffect(() => {
    setPage(0);
    setSearch("");
    setReady(true);
  }, []);

  useEffect(() => {
    if (ready) {
      getProductsList();
    }
  }, [ready]);

  // API INSTANCE
  const productAPI = new ProductsAPI();

  const getProductsList = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("page", page + 1);

    if (search != "") {
      data.append("search", search);
    }

    const response = await productAPI.productsListEP(data);
    if (response.status == 200) {
      setData(response.data.products);
      setIsLoading(false);
      setTotal(response.data.total);
      setCount(Math.ceil(response.data.total / 10));
    } else {
      console.log(response);
    }
  };

  return (
    <>
      <div className="w-100 mt-2 px-3">
        <div className="w-100 d-flex justify-content-between p-2 r-15 d-flex flex-column">
          <p className={`m-0 ds-blue-font ${phone ? "h6" : "h5"}`}>
            Ingresa un nombre o SKU de producto para actualizarlo.
          </p>
          {phone ? (
            <div className="mt-2 mb-2 w-100 p-1 r-15">
              <div className="mb-2">
                <Search
                  label={"Buscar"}
                  setSearch={setSearch}
                  requestData={() => getProductsList()}
                  setPage={setPage}
                />
              </div>
              <div className="d-flex justify-content-between">
                <AddButton text={""} link={"envio_masivo/"} />
              </div>
            </div>
          ) : (
            <div className="mt-2 w-100 d-flex justify-content-between p-1 r-15">
              <div className="d-flex align-items-center">
                <Search
                  label={"Buscar"}
                  setSearch={setSearch}
                  requestData={() => getProductsList()}
                  setPage={setPage}
                />
                <div className="d-flex align-items-center ml-2">
                  <AddButton text={"Entrada masiva"} link={"envio_masivo/"} />
                </div>
              </div>
            </div>
          )}
          {isLoading ? (
            <>
              <Grid
                container
                alignItems="center"
                direction="row"
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
                columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
                className="mt-2"
              >
                <SkeletonCardSearchInventory num={10} />
              </Grid>
              <div className="d-flex justify-content-start align-items-center p-2">
                <Typography variant="caption" className="ml-2">
                  <Skeleton width={50} />
                </Typography>
                <Skeleton variant="circular" className="ml-4" width={20} />
                <Skeleton variant="circular" className="ml-2" width={20} />
              </div>
            </>
          ) : (
            <>
              <Grid
                container
                alignItems="center"
                direction="row"
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
                columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
                className="mt-2"
              >
                {data.map((product, i) => (
                  <CardProductEntry key={i} info={product} />
                ))}
              </Grid>
              {data.length == 0 && search != "" ? (
                <div className="d-flex justify-content-center mt-2">
                  No se encontraron resultados para "{search}"
                </div>
              ) : (
                data.length == 0 &&
                search == "" && (
                  <div className="d-flex justify-content-center mt-2">
                    No tienes productos.
                  </div>
                )
              )}
              <ListPagination
                setPage={setPage}
                page={page}
                total={total}
                count={count}
                requestData={() => getProductsList()}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
