import React from "react";

import { Toolbar, Typography, Grid, Paper, IconButton } from "@mui/material";

import { FlippyCard } from "../../components/FlippyCard";

import { useNavigate } from "react-router-dom";
import ExistanceIcon from "../../assets/Caja.svg";
import EntryIcon from "../../assets/Entrada.svg";
import TransferIcon from "../../assets/Transfer.svg";
import ProgrammedIcon from "../../assets/ProgramarEnvio.svg";
import ProgrammedTransferIcon from "../../assets/ProgramarTransfer.svg"

export const Inventory = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="w-100 mt-4 px-4">
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          rowSpacing={2}
          columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}
        >

          <Grid item xs={6} sm={6} md={3} lg={3}>

            <FlippyCard
              height={"100%"}
              description={"Productos en almacenes y cedis Day Store"}
              onClick={() => {
                navigate("/cliente/inventario/existencias/")
              }}
              title={"Inventario"}
              gridSize={12}
              icon={
                <IconButton>
                  <img
                    src={ExistanceIcon}
                    alt="Existance List"
                    width={100}
                    height={100}
                  />
                </IconButton>
              }
            />

          </Grid>


          <Grid item xs={6} sm={6} md={9} lg={9}>

            <Grid container spacing={3}>

              <FlippyCard
                xs={12}
                sm={12}
                md={6}
                lg={6}
                description={"Enviar productos a cedis o sucursales Day Store."}
                onClick={() => {
                  navigate("/cliente/inventario/busqueda/")
                }}
                title={"Envío de Mercancía"}
                gridSize={6}
                icon={
                  <IconButton>
                    <img
                      src={ProgrammedIcon}
                      alt="Existance"
                      width={100}
                      height={100}
                    />
                  </IconButton>
                }
              />

              <FlippyCard
                xs={12}
                sm={12}
                md={6}
                lg={6}
                gridSize={6}
                description={"Estatus de entrada de productos"}
                onClick={() => {
                  navigate("/cliente/inventario/envios/")
                }}
                title={"Historial de Recepciones"}
                icon={
                  <IconButton>
                    <img src={EntryIcon} alt="Entries" width={100} height={100} />
                  </IconButton>
                }
              />
              <FlippyCard
                xs={12}
                sm={12}
                md={6}
                lg={6}
                gridSize={6}
                description={"Transferir productos entre cedis o sucursales Day Store."}
                onClick={() => {
                  navigate("/cliente/inventario/transferir/")
                }}
                title={"Programar Transferencias"}
                icon={
                  <IconButton>
                    <img
                      src={ProgrammedTransferIcon}
                      alt="Transfer"
                      width={100}
                      height={100}
                    />
                  </IconButton>
                }
              />
              <FlippyCard
                xs={12}
                sm={12}
                md={6}
                lg={6}
                gridSize={6}
                description={"Transferencia de productos entre cedis o sucursales Day Store."}
                onClick={() => {
                  navigate("/cliente/inventario/transferencias/")
                }}
                title={"Transferencias de Producto"}
                icon={
                  <IconButton>
                    <img
                      src={TransferIcon}
                      alt="Transfer List"
                      width={100}
                      height={100}
                    />
                  </IconButton>
                }
              />
            </Grid>

          </Grid>

        </Grid>
      </div>
    </>
  );
};
