import React, { useEffect, useMemo, useRef, useState } from "react";
import { InputBase, Paper, useMediaQuery } from "@mui/material";
import { FilterList, Search as SearchIcon } from "@mui/icons-material";

export const MapSearch = ({
  requestData,
  disabled,
  handleChange,
  search,
  error,
}) => {
  const phone = useMediaQuery("(max-width:480px)");

  // console.log(error ? true : false);

  return (
    <>
      <Paper
        component="form"
        sx={{
          px: "2px",
          border: "1px solid #8d8d8d8a",
          width: "100%",
        }}
      >
        <InputBase
          placeholder={"Lugar o dirección"}
          onChange={(e) => handleChange(e)}
          onKeyPress={(e) => {
            if (e.key == "Enter") {
              e.preventDefault();
              requestData(e.target.value);
            }
          }}
          value={search}
          name={"search"}
          disabled={disabled}
          className="ml-2 w-100"
          inputProps={phone && { style: { fontSize: 13 } }}
          endAdornment={
            <SearchIcon
              className="ds-yellow-font mr-2"
              onClick={() => requestData(search)}
            />
          }
          error={error ? true : false}
          helperText={error ? error : null}
        />
      </Paper>
    </>
  );
};
