import React from "react";

import { Grid, useMediaQuery } from "@mui/material";

import { SkeletonCardEntry } from "./Skeleton/SkeletonCardEntry";
import { ListPagination } from "./Pagination/ListPagination";
import { CardHistorical } from "./Card/CardHistorical";

export const ListViewHistorical = ({
  // listIndex,
  // setListIndex,
  data,
  search,
  isLoading,
  type,
  setPage,
  page,
  total,
  count,
  requestData,
}) => {
  const phone = useMediaQuery("(max-width:480px)");

  return (
    <>
      <div className="mt-3 w-100 r-15">
        {isLoading ? (
          <Grid
            container
            alignItems="center"
            direction="row"
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
            columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
          >
            <SkeletonCardEntry num={10} rows={6} />
          </Grid>
        ) : (
          <>
            <Grid
              container
              alignItems="center"
              direction="row"
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
              columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
            >
              {data.map((notification, i) => (
                <CardHistorical
                  info={notification}
                  color={"#58BDDB"}
                  key={i}
                  type={type}
                />
              ))}
            </Grid>
            {/* 
                        {data.length == 0 && search.length != 0 ? (
                            <div className="d-flex justify-content-center mt-2">
                                No se encontraron resultados para "{search}"
                            </div>
                        ) : (
                            ""
                        )} */}

            {/* {data.length == 0 && (
                            <div className="d-flex justify-content-center mt-2">
                                No tienes transferencias aún.
                            </div>
                        )} */}
            <ListPagination
              setPage={setPage}
              page={page}
              total={total}
              count={count}
              requestData={requestData}
            />
          </>
        )}
      </div>
    </>
  );
};
