import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import {
  Grid,
  IconButton,
  Popover,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { Sidebar } from "../components/Sidebar/Sidebar";
import { Clients } from "./pages/Clients";
import { Dashboard } from "./pages/Dashboard";
import { Reports } from "./pages/Reports";
import { ReportsSales } from "./pages/ReportsSales";
import { ReportsInventory } from "./pages/ReportsInventory";
import MenuIcon from "@mui/icons-material/Menu";

import { AddClient } from "./pages/Add/AddClient";
import { Products } from "./pages/Products";
import { SalesAdmin } from "./pages/SalesAdmin";
import { Inventory } from "./pages/Inventory";
import { Existances } from "./pages/Inventory/Existances";
import { Entries } from "./pages/Inventory/Entries";
import { BreadcrumbsMenu } from "../components/Breadcrumb/BreadcrumbsMenu";
import { AccountCardsAdmin } from "./pages/Edit/AccountCardsAdmin";
import { EditAdmin } from "./pages/Edit/EditAdmin";
import { EditInfoAdmin } from "../pages/Edit/EditInfoAdmin";
import { Transfers } from "./pages/Inventory/Transfers";
import { B2B } from "./pages/Inventory/B2B";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import ProfileIcon from "../assets/SVGIcons/Profile.svg";
import { ModalMenuAccount } from "../components/Modal/ModalMenuAccount";
import { ModalNotification } from "../components/Modal/ModalNotification";
import { EditPasswordAdmin } from "./pages/Edit/EditPasswordAdmin";
import { AppBar, DrawerHeader, DrawerStyled } from "../utils/menuStyles";
import { sectionsAdmin } from "../utils/menuSections";
import { Sales } from "./pages/Sales/Sales";
import { SalesOdoo } from "../pages/Sales/SalesOdoo";
import { SalesB2B } from "./pages/Sales/SalesB2B";
import { Business } from "./pages/Sales/Business";
import { DetailSaleB2B } from "../pages/Sales/DetailSaleB2B";
import { BusinessDetails } from "../pages/Sales/BusinessDetails";
import { useMemo } from "react";
import { NotificationsAPI } from "../api/Notifications";
import { theme } from "../utils/theme";
import { KeyboardArrowDownRounded } from "@mui/icons-material";
import { DetailSales } from "../pages/Sales/DetailSales";
import { DetailSalesOdoo } from "../pages/Sales/DetailSalesOdoo";

export const LayoutAdmin = () => {
  const { pathname } = useLocation();
  const tablet = useMediaQuery("(max-width:600px)");
  const phone = useMediaQuery("(max-width:600px)");
  const breakpointBC = useMediaQuery("(max-width:630px)");
  const admin = localStorage.getItem("nombre");
  const [isLoading, setIsLoading] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [notification, setNotification] = useState(false);

  const handleClick = (event, notification) => {
    setAnchorEl(event.currentTarget);
    setNotification(notification);
  };
  const handleClose = () => setAnchorEl(null);

  const openPO = Boolean(anchorEl);
  const id = openPO ? "simple-popover" : undefined;

  const [total, setTotal] = useState(0);
  const [notifications, setNotifications] = useState({
    e_sales: [],
    inventory_receipt: [],
    inventory_status: [],
    inventory_transfer: [],
  });
  const [loaderNotifications, setLoaderNotifications] = useState(false);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleDrawerToggle = () => {
    tablet && setMobileOpen(!mobileOpen);
    setOpen(true);
  };

  const rol = 3;

  useEffect(() => {
    GetNotifications();
  }, []);

  //api
  const notificationsAPI = useMemo(() => new NotificationsAPI(), []);

  const GetNotifications = async () => {
    setLoaderNotifications(true);
    let data = new FormData();

    const response = await notificationsAPI.getNotificationsEP(data);
    if (response.status == 200) {
      setNotifications({
        e_sales: response.data.notifications.e_sales,
        inventory_receipt: response.data.notifications.inventory_receipt,
        inventory_status: response.data.notifications.inventory_status,
        inventory_transfer: response.data.notifications.inventory_transfer,
      });
      setTotal(response.data.total);
      setLoaderNotifications(false);
    } else {
      console.log(response);
    }
  };

  const nameSection = sectionsAdmin.find((el) => `/${el.link}/` == pathname);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <AppBar
          position="fixed"
          open={tablet ? false : open}
          style={{ width: !open && !tablet && "calc(100% - 65px)" }}
          elevation={0}
        >
          <Toolbar
            sx={{
              bgcolor: "white",
              color: theme.palette.primary.main,
              height: phone ? "65px" : "68px",
            }}
            className={`w-100 d-flex align-items-start align-items-center`}
          >
            {tablet && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            )}

            <div
              className={`w-100 ${phone && "d-flex justify-content-center"}`}
            >
              <div
                className={`w-100 d-flex mt-1 ${
                  phone
                    ? "flex-column align-items-center"
                    : "align-items-center justify-content-between"
                }`}
              >
                <div className="d-flex flex-column">
                  <Typography
                    variant="h5"
                    noWrap
                    component="div"
                    className="ds-blue-font font-weight-bold capitalize"
                  >
                    {nameSection != undefined && nameSection.section}
                  </Typography>
                  {breakpointBC ? null : (
                    <BreadcrumbsMenu
                      sections={sectionsAdmin}
                      principal={"dashboard"}
                      rol={"administrador"}
                    />
                  )}
                </div>

                {/*----------------------- NOTIFICACIONES NAME Y PROFILEICON -----------------------*/}
                <div
                  className={`d-flex ${
                    phone
                      ? "w-100 justify-content-center"
                      : "align-items-center justify-content-between"
                  } `}
                >
                  <div></div>

                  {phone ? (
                    <div className="pr-4 mr-4">
                      <Typography
                        sx={{ fontSize: 20 }}
                        className="ds-blue-font capitalize"
                      >
                        {admin != null
                          ? admin.length >= 13
                            ? `${admin.substring(0, 13)}...`
                            : admin
                          : "Sin usuario"}
                      </Typography>
                    </div>
                  ) : (
                    <Typography
                      sx={{ fontSize: 20, fontWeight: 600 }}
                      className="ds-blue-font capitalize"
                    >
                      {admin != null ? admin : "Sin usuario"}
                    </Typography>
                  )}
                  <IconButton onClick={(e) => handleClick(e)}>
                    <KeyboardArrowDownRounded color={"primary"} />
                  </IconButton>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>

        {/* -------------------------------------------SIDEBAR------------------------------------------ */}
        <Sidebar
          DrawerStyled={DrawerStyled}
          open={open}
          handleDrawer={handleDrawer}
          isLoading={isLoading}
          DrawerHeader={DrawerHeader}
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
          rol={3}
        />

        {/* ------------------------------------------------------------------------------------------- */}

        <div className="d-flex justify-content-center w-100">
          <div
            className=""
            style={{
              width: phone
                ? "100%"
                : open && !phone
                ? "calc(100vw - 235px)"
                : "calc(100vw - 80px)",
            }}
          >
            <Grid container sx={{ marginTop: !tablet ? 8.3 : 7.6 }}>
              <DrawerHeader />

              <Routes>
                {/* Todas las rutas inexistentes te redirigen a dashboard */}
                <Route
                  path="*"
                  element={<Navigate to="dashboard/" replace />}
                />
                <Route path="dashboard/" element={<Dashboard />} />
                <Route path="clientes/" element={<Clients />} />
                <Route path="clientes/nuevo/" element={<AddClient />} />
                <Route path="clientes/editar/" element={<EditAdmin />} />

                <Route path="productos/" element={<Products />} />

                <Route path="inventario/" element={<Inventory />} />
                <Route
                  path="inventario/existencias/"
                  element={<Existances />}
                />
                <Route path="inventario/envios/" element={<Entries />} />
                <Route
                  path="inventario/transferencias/"
                  element={<Transfers />}
                />
                <Route path="inventario/traspasos/" element={<B2B />} />

                <Route path="ventas/" element={<Sales />} />
                <Route path="ventas/B2C/" element={<SalesAdmin />} />
                <Route path="ventas/Odoo/" element={<SalesOdoo />} />
                <Route path="ventas/B2C/detalle/" element={<DetailSales />} />
                <Route path="ventas/Odoo/detalle/" element={<DetailSalesOdoo />} />
                <Route path="ventas/B2B/" element={<SalesB2B />} />
                <Route path="ventas/B2B/detalle/" element={<DetailSaleB2B />} />
                <Route path="ventas/B2B/negocios/" element={<Business />} />
                <Route
                  path="ventas/B2B/negocios/detalle"
                  element={<BusinessDetails />}
                />

                <Route path="reportes/" element={<Reports />} />
                <Route path="reportes/ventas/" element={<ReportsSales />} />
                <Route
                  path="reportes/inventario/"
                  element={<ReportsInventory />}
                />

                <Route path="cuenta/" element={<AccountCardsAdmin />} />
                <Route path="cuenta/perfil/" element={<EditInfoAdmin />} />
                <Route
                  path="cuenta/password/"
                  element={<EditPasswordAdmin />}
                />
              </Routes>
            </Grid>
          </div>
        </div>
      </Box>

      <Popover
        id={id}
        open={openPO}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: { maxWidth: notification && 300 },
        }}
      >
        {notification ? (
          <ModalNotification
            handleClose={handleClose}
            notifications={notifications}
            GetNotifications={GetNotifications}
            loaderNotifications={loaderNotifications}
            total={total}
          />
        ) : (
          <ModalMenuAccount user={"administrador"} handleClose={handleClose} />
        )}
      </Popover>
    </>
  );
};
