import React, { useState, useEffect, useContext } from "react";

import { Grid } from "@mui/material";
import { CardGraph } from "../Card/CardGraph";

const options = {
  legend: "none",
  chartArea: {
    width: "80%",
    height: "80%",
  },
  colors: ["#8CC044"]
  //height:"400px"
};

const modalOption = {
  vAxis: {
    title: "Producto",
    minValue: 0,
    titleTextStyle: {
      color: "black",
      bold: true,
    },
  },
  hAxis: {
    minValue: 1,
    titleTextStyle: {
      color: "black",
      bold: true,
    },
  },
};

export const TopSalesGraph = ({ data, isLoading, client, filters }) => {
  return (
    <>
      <Grid item xs={4}>
        <CardGraph
          title={"Artículos más vendidos"}
          type={"BarChart"}
          data={data}
          options={options}
          modalOption={modalOption}
          isLoading={isLoading}
          client={client}
          filters={filters}
        />
      </Grid>
    </>
  );
};
