import React, { useState, useEffect, useContext } from "react";
import { FilterContext } from "../../context/FilterContext";
import { Grid, Popover, useMediaQuery } from "@mui/material";
import { Search } from "../../components/Search";
import { ToggleView } from "../../components/ToggleView";

import { AddButton } from "../../components/AddButton";

import { DownloadButtonExcel } from "../../components/Excel/DownloadButtonExcel";
import { SkeletonCardProduct } from "../../components/Skeleton/SkeletonCardProduct";
import { TableClients } from "../../components/Tables/TableClients";
import { SkeletonTableClients } from "../../components/Skeleton/SkeletonTableClients";
import { ListViewClients } from "../../components/ListViewClients";
import { clientsHeaders } from "../../components/headers";

import { ModalFilter } from "../../components/Modal/ModalFilter";
import { ClientsContext } from "../../context/ClientsContext";

export const Clients = () => {
  const [view, setView] = useState(true);
  const phone = useMediaQuery("(max-width:750px)");

  //------------------------------------------------

  //MODAL STATES
  //------------------------------------------------

  const [ready, setReady] = useState(false);

  const { resetFilter, search, setSearch, invisible } =
    useContext(FilterContext);

  const {
    getClientsList,
    getExcelClientsList,
    data,
    total,
    count,
    isLoading,
    isLoadingExcel,
    page,
    setPage,

    excelData,
  } = useContext(ClientsContext);

  //------------------------------------------------
  //EFFECTS
  //------------------------------------------------

  useEffect(() => {
    setPage(0);
    setSearch("");
    resetFilter();
    setReady(true);
  }, []);

  useEffect(() => {
    if (ready) {
      RequestData();
    }
  }, [ready]);

  //------------------------------------------------

  //DATA REQUEST
  //------------------------------------------------

  const RequestData = () => {
    getClientsList();
  };

  //------------------------------------------------

  //HANDLERS
  //------------------------------------------------

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleCloseFilterModal = () => setAnchorEl(null);

  //------------------------------------------------

  return (
    <>
      <div className="w-100 mt-2 px-3">
        {phone ? (
          <div className="mt-3 w-100 p-1 r-15">
            <div className="mb-2">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                requestData={RequestData}
                setPage={setPage}
              />
            </div>

            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <AddButton text={""} link={"nuevo/"} />
                <DownloadButtonExcel
                  text={""}
                  disabled={data.length > 0 ? false : true}
                  sheetName={"Clientes"}
                  headers={clientsHeaders}
                  data={excelData}
                  getData={() => getExcelClientsList()}
                  isLoading={isLoadingExcel}
                />
              </div>

              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        ) : (
          <div className="mt-2 w-100 d-flex justify-content-between p-1 r-15">
            <div className="d-flex align-items-center">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                requestData={RequestData}
                setPage={setPage}
              />
              <div className="d-flex align-items-center ml-1">
                <AddButton text={"Cliente nuevo"} link={"nuevo/"} />
                <DownloadButtonExcel
                  text={"Descargar"}
                  disabled={data.length > 0 ? false : true}
                  sheetName={"Clientes"}
                  headers={clientsHeaders}
                  data={excelData}
                  getData={() => getExcelClientsList()}
                  isLoading={isLoadingExcel}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        )}
        {view ? (
          // Vista de gráfica
          isLoading ? (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <SkeletonTableClients
                num={3}
                total={total}
                page={page}
                setPage={setPage}
              />
            </div>
          ) : (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <TableClients
                data={data}
                search={search}
                setPage={setPage}
                page={page}
                total={total}
                requestData={RequestData}
                count={count}
              />
            </div>
          )
        ) : isLoading ? (
          <>
            <div className="mt-3 w-100  r-15">
              <Grid
                container
                alignItems="center"
                direction="row"
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
                columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
              >
                <SkeletonCardProduct
                  num={10}
                  rows={6}
                  icon={true}
                  height={200}
                  total={total}
                  page={page}
                  setPage={setPage}
                  count={count}
                />
              </Grid>
            </div>
          </>
        ) : (
          <ListViewClients
            data={data}
            search={search}
            total={total}
            page={page}
            setPage={setPage}
            count={count}
            requestData={RequestData}
          />
        )}
      </div>

      {/* <Modal open={openFilter} onClose={() => console.log("Cerrando")}> */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseFilterModal}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <ModalFilter
          handleCloseModal={handleCloseFilterModal}
          dates={true}
          nullable={true}
          requestData={RequestData}
          view={view}
          setPage={setPage}
        />
      </Popover>

      {/* </Modal> */}
    </>
  );
};
