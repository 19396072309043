import LoaderDS from "../../assets/LoaderDS.svg";
import LogoBox from "../../assets/branding/boxai_imagotipo.svg";
import LogoBoxAI from "../../assets/branding/boxai_logo_blanco_amarillo.svg";

import {
  Backdrop,
  IconButton,
  List,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import {
  DashboardRounded,
  LocalMallRounded,
  AssignmentRounded,
  MonetizationOnRounded,
  QueryStatsRounded,
  GroupRounded,
  SettingsRounded,
  PriceCheckRounded,
  StoreMallDirectoryRounded,
  DeliveryDiningRounded,
  KeyboardArrowRightRounded,
  LocalShippingRounded,
  InputRounded,
  MoveDownRounded,
  ManageAccountsRounded,
  KeyRounded,
  PointOfSale,
} from "@mui/icons-material";
import { useAuthContext } from "../../context/AuthProvider";
import { OpenedMenuList } from "./OpenedMenuList";
import { LogoutSection } from "./LogoutSection";

export const Sidebar = ({
  DrawerStyled,
  open,
  handleDrawer,
  DrawerHeader,
  handleDrawerToggle,
  mobileOpen,
  rol,
}) => {
  const { logoutFunction, isLoading, openBD } = useAuthContext();
  const tablet = useMediaQuery("(max-width:600px)");

  const sections = {
    2: [
      /*Client admin */
      {
        link: "cliente/dashboard/",
        name: "Dashboard",
        icon: <DashboardRounded />,
      },
      {
        link: "cliente/productos/",
        name: "Productos",
        icon: <LocalMallRounded />,
      },
      {
        link: "/",
        name: "Inventario",
        icon: <AssignmentRounded />,
        submenu: [
          {
            link: "cliente/inventario/existencias/",
            name: "Existencias",
            icon: <AssignmentRounded fontSize="18px" />,
            //icon: <img src={ExistanceIcon} width={18} height={18} />,
          },
          {
            link: "cliente/inventario/transferencias/",
            name: "Transferencias",
            icon: <AssignmentRounded fontSize="18px" />,
            //icon: <img src={ProgrammedTransferIcon} width={18} height={18} />,
          },
          {
            link: "cliente/inventario/envios/",
            name: "Envíos",
            icon: <InputRounded fontSize="18px" />,
            //icon: <img src={EntryIcon} width={18} height={18} />,
          },
          {
            link: "cliente/inventario/busqueda/",
            name: "Programar envíos",
            icon: <LocalShippingRounded fontSize="18px" />,
            //icon: <img src={TransferIcon} width={18} height={18} />,
          },
          {
            link: "cliente/inventario/transferir/",
            name: "Transferir",
            icon: <MoveDownRounded fontSize="18px" />,
            //icon: <img src={ProgrammedTransferIcon} width={18} height={18} />,
          },
        ],
      },
      {
        link: "/",
        name: "Ventas",
        icon: <MonetizationOnRounded />,
        submenu: [
          {
            link: "cliente/ventas/B2C/",
            name: "B2C",
            icon: <DeliveryDiningRounded fontSize="18px" />,
          },
          {
            link: "cliente/ventas/B2B/",
            name: "B2B",
            icon: <StoreMallDirectoryRounded fontSize="18px" />,
            submenu: [
              {
                link: "cliente/ventas/B2B/",
                name: "Ventas",
              },
              {
                link: "cliente/ventas/B2B/negocios/",
                name: "Negocios",
              },
            ],
          },
          {
            link: "cliente/ventas/Odoo/",
            name: "Odoo",
            icon: <PointOfSale fontSize="18px" />,
          },
        ],
      },
      {
        link: "/",
        name: "Reportes",
        icon: <QueryStatsRounded />,
        submenu: [
          {
            link: "cliente/reportes/ventas/",
            name: "Ventas",
            icon: <MonetizationOnRounded fontSize="18px" />,
          },
          {
            link: "cliente/reportes/inventario/",
            name: "Inventario",
            icon: <AssignmentRounded fontSize="18px" />,
          },
        ],
      },
      {
        link: "cliente/usuarios/",
        name: "Usuarios",
        icon: <GroupRounded />,
      },
      {
        link: "/",
        name: "Cuenta",
        icon: <SettingsRounded />,
        submenu: [
          {
            link: "cliente/cuenta/perfil",
            name: "Perfil",
            icon: <ManageAccountsRounded fontSize="18px" />,
          },
          {
            link: "cliente/cuenta/password",
            name: "Contraseña",
            icon: <KeyRounded fontSize="18px" />,
          },
        ],
      },
    ],
    3: [
      /*Admin DS */
      {
        link: "administrador/dashboard/",
        name: "Dashboard",
        icon: <DashboardRounded />,
      },
      {
        link: "administrador/clientes/",
        name: "Clientes",
        icon: <GroupRounded />,
      },
      {
        link: "administrador/productos/",
        name: "Productos",
        icon: <LocalMallRounded />,
      },
      {
        link: "/",
        name: "Inventario",
        icon: <AssignmentRounded />,
        submenu: [
          {
            link: "administrador/inventario/existencias/",
            name: "Existencias",
            icon: <AssignmentRounded fontSize="18px" />,
            //icon: <img src={ExistanceIcon} width={18} height={18} />,
          },
          {
            link: "administrador/inventario/transferencias/",
            name: "Transferencias",
            icon: <AssignmentRounded fontSize="18px" />,
            //icon: <img src={ProgrammedTransferIcon} width={18} height={18} />,
          },
          {
            link: "administrador/inventario/envios/",
            name: "Envíos",
            icon: <InputRounded fontSize="18px" />,
            //icon: <img src={EntryIcon} width={18} height={18} />,
          },
          // {
          //   link: "administrador/inventario/traspasos/",
          //   name: "Traspasos",
          //   icon: <AssignmentRounded fontSize="18px" />,
          //   //icon: <img src={ProgrammedTransferIcon} width={18} height={18} />,
          // },
        ],
      },
      {
        link: "/",
        name: "Ventas",
        icon: <MonetizationOnRounded />,
        submenu: [
          {
            link: "administrador/ventas/B2C/",
            name: "B2C",
            icon: <DeliveryDiningRounded fontSize="18px" />,
          },
          {
            link: "administrador/ventas/B2B/",
            name: "B2B",
            icon: <StoreMallDirectoryRounded fontSize="18px" />,
            submenu: [
              {
                link: "administrador/ventas/B2B/",
                name: "Ventas",
              },
              {
                link: "administrador/ventas/B2B/negocios/",
                name: "Negocios",
              },
            ],
          },
          {
            link: "administrador/ventas/Odoo/",
            name: "Odoo",
            icon: <PointOfSale fontSize="18px" />,
          },
        ],
      },
      {
        link: "/",
        name: "Reportes",
        icon: <QueryStatsRounded />,
        submenu: [
          {
            link: "administrador/reportes/ventas/",
            name: "Ventas",
            icon: <MonetizationOnRounded fontSize="18px" />,
          },
          {
            link: "administrador/reportes/inventario/",
            name: "Inventario",
            icon: <AssignmentRounded fontSize="18px" />,
          },
        ],
      },
      {
        link: "/",
        name: "Cuenta",
        icon: <SettingsRounded />,
        submenu: [
          {
            link: "administrador/cuenta/perfil",
            name: "Perfil",
            icon: <ManageAccountsRounded fontSize="18px" />,
          },
          {
            link: "administrador/cuenta/password",
            name: "Contraseña",
            icon: <KeyRounded fontSize="18px" />,
          },
        ],
      },
    ],
    4: [
      /*Inventory client */
      {
        link: "cliente/dashboard/",
        name: "Dashboard",
        icon: <DashboardRounded />,
      },
      {
        link: "cliente/productos/",
        name: "Productos",
        icon: <LocalMallRounded />,
      },
      {
        link: "/",
        name: "Inventario",
        icon: <AssignmentRounded />,
        submenu: [
          {
            link: "cliente/inventario/existencias/",
            name: "Existencias",
            icon: <AssignmentRounded fontSize="18px" />,
            //icon: <img src={ExistanceIcon} width={18} height={18} />,
          },
          {
            link: "cliente/inventario/transferencias/",
            name: "Transferencias",
            icon: <AssignmentRounded fontSize="18px" />,
            //icon: <img src={ProgrammedTransferIcon} width={18} height={18} />,
          },
          {
            link: "cliente/inventario/envios/",
            name: "Envíos",
            icon: <InputRounded fontSize="18px" />,
            //icon: <img src={EntryIcon} width={18} height={18} />,
          },
          {
            link: "cliente/inventario/busqueda/",
            name: "Programar envíos",
            icon: <LocalShippingRounded fontSize="18px" />,
            //icon: <img src={TransferIcon} width={18} height={18} />,
          },
          {
            link: "cliente/inventario/transferir/",
            name: "Transferir",
            icon: <MoveDownRounded fontSize="18px" />,
            //icon: <img src={ProgrammedTransferIcon} width={18} height={18} />,
          },
        ],
      },
      {
        link: "cliente/reportes/inventario/",
        name: "Reportes",
        icon: <QueryStatsRounded />,
      },
      {
        link: "/",
        name: "Cuenta",
        icon: <SettingsRounded />,
        submenu: [
          {
            link: "cliente/cuenta/password",
            name: "Contraseña",
            icon: <KeyRounded fontSize="18px" />,
          },
        ],
      },
    ],
    5: [
      /*Sales Client */
      {
        link: "cliente/dashboard/",
        name: "Dashboard",
        icon: <DashboardRounded />,
      },
      {
        link: "/",
        name: "Ventas",
        icon: <MonetizationOnRounded />,
        submenu: [
          {
            link: "cliente/ventas/B2C/",
            name: "B2C",
            icon: <DeliveryDiningRounded fontSize="18px" />,
          },
          {
            link: "cliente/ventas/B2B/",
            name: "B2B",
            icon: <StoreMallDirectoryRounded fontSize="18px" />,
            submenu: [
              {
                link: "cliente/ventas/B2B/",
                name: "Ventas",
              },
              {
                link: "cliente/ventas/B2B/negocios/",
                name: "Negocios",
              },
            ],
          },
          {
            link: "cliente/ventas/Odoo/",
            name: "Odoo",
            icon: <PointOfSale fontSize="18px" />,
          },
        ],
      },
      {
        link: "cliente/reportes/ventas/",
        name: "Reportes",
        icon: <QueryStatsRounded />,
      },
      {
        link: "/",
        name: "Cuenta",
        icon: <SettingsRounded />,
        submenu: [
          {
            link: "cliente/cuenta/password",
            name: "Contraseña",
            icon: <KeyRounded fontSize="18px" />,
          },
        ],
      },
    ],
    6: [
      /*Sucursal */
      {
        link: "/",
        name: "Ventas",
        icon: <MonetizationOnRounded />,
        submenu: [
          {
            link: "warehouse/activas/",
            name: "En curso",
            icon: <PriceCheckRounded />,
          },
          {
            link: "warehouse/ventas/",
            name: "Histórico",
            icon: <QueryStatsRounded />,
          },
        ],
      },

      {
        link: "/",
        name: "Cuenta",
        icon: <SettingsRounded />,
        submenu: [
          {
            link: "warehouse/cuenta/password",
            name: "Contraseña",
            icon: <KeyRounded fontSize="18px" />,
          },
        ],
      },
    ],
    8: [
      /*Head Field Manager */
      {
        link: "/",
        name: "Ventas",
        icon: <MonetizationOnRounded />,
        submenu: [
          {
            link: "headFieldManager/activas/",
            name: "En curso",
            icon: <PriceCheckRounded />,
          },
          {
            link: "headFieldManager/ventas/",
            name: "Histórico",
            icon: <QueryStatsRounded />,
          },
        ],
      },

      {
        link: "headFieldManager/pickers/",
        name: "Pickers",
        icon: <GroupRounded />,
      },

      {
        link: "/",
        name: "Cuenta",
        icon: <SettingsRounded />,
        submenu: [
          {
            link: "headFieldManager/cuenta/password/",
            name: "Contraseña",
            icon: <KeyRounded fontSize="18px" />,
          },
        ],
      },
    ],
  };

  const drawer = (
    <>
      {tablet && <div style={{ height: "70px" }}></div>}
      <DrawerHeader sx={{ height: tablet ? "50px" : "68px" }}>
        <div className={`d-flex flex-column my-2`}>
          <img src={open ? LogoBoxAI : LogoBox} alt="Bolsa" height={46} />
        </div>
      </DrawerHeader>
      <div className="d-flex w-100 justify-content-end position-relative align-items-center">
        <div className="w-100 border-top-gray" key={"divider1"}></div>
        {!tablet && (
          <IconButton
            onClick={handleDrawer}
            className={`ds-yellow-background p-0 `}
          >
            <KeyboardArrowRightRounded
              sx={{
                color: "#fff",
                transform: open ? "rotate(-180deg)" : "rotate(0)",
                transition: "0.5s",
              }}
            />
          </IconButton>
        )}

        <div className="w-100 border-top-gray"></div>
      </div>
      <List>
        {sections[rol].map((section) => (
          <OpenedMenuList
            section={section}
            sidebarOpen={open}
            key={section.name}
          />
        ))}
      </List>
      <div className="w-100 border-top-gray" key={"divider2"}></div>
      <LogoutSection sidebarOpen={open} />
    </>
  );

  return (
    <>
      <DrawerStyled
        variant={tablet ? "temporary" : "permanent"}
        open={tablet ? mobileOpen : open}
        onClose={tablet && handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </DrawerStyled>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
