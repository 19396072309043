import { HttpRequest } from "./HttpRequest";
import { getHost } from "../helpers/getHost";

export class AdminAPI {
  constructor() {
    this.http = new HttpRequest();
    this.host = getHost();
    this.token = localStorage.getItem("token");
    this.config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${this.token}`,
      },
    };
  }

  clientsListEP(data) {
    return this.http.post(`${this.host}/administrador/ws/clientsList/`, data, this.config);
  }

  newClient(data) {
    return this.http.post(`${this.host}/administrador/ws/createClient/`, data, this.config);
  }

  clientsListCleanedEP(data) {
    return this.http.post(`${this.host}/administrador/ws/clientsListCleaned/`, data, this.config);
  }

  editClientAdminEP(data) {
    return this.http.put(`${this.host}/administrador/ws/editClient/`, data, this.config);
  }

  clientServicesEP(data) {
    return this.http.post(`${this.host}/administrador/ws/clientServices/`, data, this.config);
  }

  clientBranchesEP(data) {
    return this.http.post(`${this.host}/administrador/ws/clientBranches/`, data, this.config);
  }

  clientCedisEP(data) {
    return this.http.post(`${this.host}/administrador/ws/clientCedis/`, data, this.config);
  }

  adminInfoEP(data) {
    return this.http.post(`${this.host}/administrador/ws/adminInfo/`, data, this.config);
  }

  editAdminInfoEP(data) {
    return this.http.put(`${this.host}/administrador/ws/editAdminInfo/`, data, this.config);
  }

}
