import { useState } from "react";
import html2canvas from "html2canvas";
import ReactPDF, {
  Page,
  Text,
  Document,
  Image,
  View,
  Svg,
} from "@react-pdf/renderer";
import FileSaver from "file-saver";
import { getDateCleanedHyphen } from "../helpers/getDateCleaned";

export const useExportFile = (fileName, ref) => {
  const [image, setImage] = useState("");
  const company = localStorage.getItem("company");
  const date = getDateCleanedHyphen(new Date());

  const takeScreenshot = async () => {
    const element = ref.current;
    try {
      if (element) {
        const canvas = await html2canvas(element, { useCORS: true });
        const imageUrl = canvas.toDataURL("image/jpg");
        return { canvas, imageUrl };
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadPdf = async (orientation, size) => {
    const { imageUrl } = await takeScreenshot();
  
    const document = (
      <Document>
        <Page size="A4" orientation="portrait">
          <View>
            <Image src={imageUrl} style={{ width: "100%", height: "auto" }} />
          </View>
        </Page>
      </Document>
    );
  
    const pdfBlob = await ReactPDF.pdf(document).toBlob();
    FileSaver.saveAs(pdfBlob, `BoxAI ${fileName} ${date}.pdf`);
  };
  

  const downloadImg = async () => {
    const { canvas, imageUrl } = await takeScreenshot();
    FileSaver.saveAs(imageUrl, `BoxAI ${fileName} ${date}.png`);
  };

  return { downloadPdf, downloadImg };
};
