import { useState } from "react";

import Box from "@mui/material/Box";
import {
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  Tooltip,
  Popover,
  Modal,
  Backdrop,
} from "@mui/material";
import { Close, EditTwoTone, DeleteForever } from "@mui/icons-material";

import { roles } from "../../helpers/enums";

import { ModalEditEntrySuccess } from "../Modal/ModalEditEntrySuccess";
import { ModalEditEntry } from "./ModalEditEntry";
import { ModalError } from "../Modal/ModalError";

import LoaderDS from "../../assets/LoaderDS.svg";
import { indexOf } from "lodash";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const ModalEntryProducts = ({
  info,
  handleClose,
  type,
  RequestData,
}) => {
  const phone = useMediaQuery("(max-width:600px)");
  const tablet = useMediaQuery("(max-width:900px)");

  const [productInfo, setProductInfo] = useState();
  const [action, setAction] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCloseEdit = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [openBD, setOpenBD] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorField, setErrorField] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "95%" : "85%",
    maxHeight: "80vh",
    //height: "80vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  };

  const handleClick = (event, action, row) => {
    setAnchorEl(event.currentTarget);
    setProductInfo(row);
    setAction(action);
  };

  return (
    <>
      <Box sx={style}>
        <div className="w-100 d-flex justify-content-end">
          <IconButton onClick={handleClose}>
            <Close sx={{ fontSize: 35 }} />
          </IconButton>
        </div>

        <Paper className="px-3 pb-4" sx={{ width: "100%" }}>
          <div className="w-100 d-flex justify-content-center">
            <p
              className={`font-weight-bold gray-font ml-2 ${
                phone ? "h5" : "h4"
              }`}
            >
              {`Productos del folio ${info.folio} (${info.status})`}
            </p>
          </div>

          <TableContainer sx={{ maxHeight: "60vh" }}>
            <Table
              sx={{ minWidth: 750, borderRadius: 2 }}
              aria-label="sticky table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {type == roles.CLIENT && (
                    <StyledTableCell align="center">Acciones</StyledTableCell>
                  )}
                  <StyledTableCell align="center">SKU</StyledTableCell>
                  <StyledTableCell align="center">Descripción</StyledTableCell>
                  <StyledTableCell align="center">Categoría</StyledTableCell>
                  <StyledTableCell align="center">
                    Piezas añadidas
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Piezas confirmadas
                  </StyledTableCell>
                  <StyledTableCell align="center">Lote</StyledTableCell>
                  <StyledTableCell align="center" style={{ minWidth: 120 }}>
                    Caducidad
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {info.products.map((product, index) => {
                  return (
                    <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                      {type != 3 && (
                        <TableCell padding="checkbox">
                          <div className="d-flex">
                            <Tooltip title={"Editar"} arrow placement="top">
                              <div
                                className={`${
                                  info.status == "Por validar" ||
                                  (product.pieces == 0 && "hover-icon")
                                }`}
                              >
                                <IconButton
                                  sx={{ "&:hover": { color: "green" } }}
                                  onClick={(e) =>
                                    handleClick(e, "edit", product)
                                  }
                                  disabled={
                                    info.status != "Por validar" ||
                                    product.pieces == 0
                                      ? true
                                      : false
                                  }
                                >
                                  <EditTwoTone />
                                </IconButton>
                              </div>
                            </Tooltip>
                            <Tooltip title={"Cancelar"} arrow placement="top">
                              <div
                                className={`${
                                  info.status == "Por validar" ||
                                  (product.pieces == 0 && "hover-icon")
                                }`}
                              >
                                <IconButton
                                  sx={{ "&:hover": { color: "red" } }}
                                  onClick={(e) =>
                                    handleClick(e, "cancel", product)
                                  }
                                  disabled={
                                    info.status != "Por validar" ||
                                    product.pieces == 0
                                      ? true
                                      : false
                                  }
                                >
                                  <DeleteForever />
                                </IconButton>
                              </div>
                            </Tooltip>
                          </div>
                        </TableCell>
                      )}
                      <TableCell align="center">{product.sku}</TableCell>{" "}
                      <TableCell align="center">
                        {
                          <Tooltip
                            title={
                              product.description.length > 15
                                ? product.description
                                : ""
                            }
                            placement="top"
                            arproduct
                          >
                            <div>
                              {product.description.substring(0, 15)}
                              {product.description.length > 15 && "..."}
                            </div>
                          </Tooltip>
                        }
                      </TableCell>
                      <TableCell align="center">{product.category}</TableCell>
                      <TableCell align="center">{product.pieces}</TableCell>
                      <TableCell align="center">
                        {product.quantityReceived != null
                          ? product.quantityReceived
                          : 0}
                      </TableCell>{" "}
                      <TableCell align="center">{product.batch}</TableCell>
                      <TableCell align="center">
                        {product.expiration_date
                          ? product.expiration_date
                          : "N/A"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseEdit}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: !phone && 300 },
        }}
      >
        <ModalEditEntry
          info={info}
          product={productInfo}
          RequestData={RequestData}
          action={action}
          handleClose={handleCloseEdit}
          setOpenBD={setOpenBD}
          setOpenSuccess={setOpenSuccess}
          setOpenError={setOpenError}
          setErrorField={setErrorField}
        />
      </Popover>
      <Modal open={openError} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalError
            text={`La solicitud no pudo ser completada.`}
            handleClose={() => {
              setOpenError(false);
            }}
            error={errorField}
          />
        </div>
      </Modal>
      <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalEditEntrySuccess
            text={
              action == "edit"
                ? "El producto se actualizó correctamente"
                : "El producto se canceló correctamente"
            }
          />
        </div>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
