import Box from "@mui/material/Box";
import {
  Divider,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Circle from "@mui/icons-material/Circle";
import { theme } from "../../utils/theme";

export default function ModalContactInfo({ info, handleClose, infoType }) {
  const phone = useMediaQuery("(max-width:600px)");
  const tablet = useMediaQuery("(max-width:900px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "95%" : "50%",
    maxWidth: !phone && 600,
    // maxHeight: 700,
    // height: "80vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 2,
  };

  return (
    <Box sx={style}>
      <div className="w-100 d-flex justify-content-between align-items-center pl-3">
        <Typography variant="h5" className={`font-weight-bold gray-font `}>
          Datos de contacto
        </Typography>
        <IconButton onClick={handleClose}>
          <Close sx={{ fontSize: 35 }} />
        </IconButton>
      </div>

      <Paper className="px-3 pb-4" sx={{ width: "100%" }}>
        {infoType == "picker" ? (
          <div
            className={`d-flex ${
              phone
                ? "flex-column justify-content-center align-items-center"
                : "justify-content-around"
            } `}
          >
            <div className={`w-100 px-2`}>
              <div>
                <Typography
                  variant="h5"
                  className="ds-blue-font font-weight-bold"
                >
                  Picker
                </Typography>
              </div>{" "}
              {/*HEADER*/}
              <div className="d-flex align-items-center">
                <div className="w-30 m-3">
                  {info.photo ? (
                    <div
                      className="rounded-circle border-img"
                      style={{
                        background: `url("${info.photo}") center / cover no-repeat`,
                        height: 150,
                        width: 150,
                      }}
                    ></div>
                  ) : (
                    <AccountCircleIcon
                      className="rounded-circle border-img"
                      sx={{ color: theme.palette.neutrals.inactive, fontSize: 150 }}
                    />
                  )}
                  <Tooltip
                    title={
                      info.available == true ? "Conectado" : "Desconectado"
                    }
                    placement="top"
                    arrow
                  >
                    <Circle
                      className={`${
                        info.available == true
                          ? "ds-green-font"
                          : "inactive-font"
                      }`}
                      sx={{ position: "absolute", bottom: 55, left: 150 }}
                    />
                  </Tooltip>
                </div>
                <div className="">
                  <Typography>
                    Nombre: {`${info.nombre} ${info.apellidos}`}
                  </Typography>
                  <Typography>
                    Número de empleado: {info.picker_code}
                  </Typography>
                  <Typography>Teléfono: {info.telefono}</Typography>
                  <Typography>E-mail: {info.email}</Typography>
                  <Typography>
                    Fecha de registro: {info.fecha_registro}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`d-flex ${
              phone
                ? "flex-column justify-content-center align-items-center"
                : "justify-content-around"
            } `}
          >
            <div className={`w-100 px-2`}>
              <div>
                <Typography variant="h6" className="ds-blue-font">
                  Cliente Daystore
                </Typography>
              </div>{" "}
              {/*HEADER*/}
              <Typography>Nombre: {info.razon_social_cliente}</Typography>
              <Typography>Teléfono: {info.telefono_cliente}</Typography>
            </div>
            <Divider orientation="vertical" flexItem />

            <div className={`w-100 px-2 ${phone && "mb-2"}`}>
              <div>
                <Typography variant="h6" className="ds-blue-font">
                  Última milla
                </Typography>
              </div>{" "}
              {/*HEADER*/}
              <Typography>Nombre: {info.last_mille}</Typography>
              <Typography>
                Operador:{" "}
                {info.orden_estatus.length != 0
                  ? info.orden_estatus[0].nombre_aliado
                  : ""}
              </Typography>
              <Typography>
                Teléfono:{" "}
                {info.orden_estatus.length != 0
                  ? info.orden_estatus[0].numero_aliado
                  : ""}
              </Typography>
              <Typography>
                Placa:{" "}
                {info.orden_estatus.length != 0
                  ? info.orden_estatus[0].placa_vehiculo
                  : ""}
              </Typography>
              <Typography>
                Servicio:{" "}
                {info.orden_estatus.length != 0
                  ? info.orden_estatus[0].tipo_servicio
                  : ""}
              </Typography>
            </div>
            <Divider orientation="vertical" flexItem />

            <div className={`w-100 px-2 ${phone && "mb-2"}`}>
              <div>
                <Typography variant="h6" className="ds-blue-font">
                  Cliente final
                </Typography>
              </div>{" "}
              {/*HEADER*/}
              <Typography>Nombre: {info.nombre_cliente_final}</Typography>
              <Typography>Teléfono: {info.telefono_cliente_final}</Typography>
              <Typography>Email: {info.email_cliente_final}</Typography>
              <Typography>Direccion: {info.direccion_entrega}</Typography>
            </div>
          </div>
        )}
      </Paper>
    </Box>
  );
}
//115292154715393357_08
