import React, { useState } from "react";

import html2canvas from "html2canvas";
import jsPdf from "jspdf";

import {
  Button,
  IconButton,
  Tooltip,
  Popover,
  Divider,
  useMediaQuery,
  CircularProgress,
  Backdrop,
  LinearProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { DownloadButtonExcel } from "../../components/Excel/DownloadButtonExcel";
import { ExcelDownloadMultipleSheet } from "../../components/Excel/ExcelDownloadMultipleSheet";
import LoaderDS from "../../assets/LoaderDS.svg";
import {
  Download,
  GridOnRounded,
  Image,
  PictureAsPdf,
  TableView,
} from "@mui/icons-material";
import DownloadIcon from "../../assets/SVGIcons/Download.svg";
import { theme } from "../../utils/theme";
import { useExportFile } from "../../hooks/useExportFile";

const ButtonStyled = styled(Button)({
  justifyContent: "start",
  textTransform: "none",
  fontSize: 16,
  width: "96%",
  padding: "6px 6px",
  border: "1.5px solid",
  lineHeight: 1.5,
  borderRadius: 5,
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  color: "white",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  margin: 4,
});

export const DownloadButton = ({
  htmlElement,
  fileName,
  excel,
  sheetName,
  info,
  modal,
  client,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const tablet = useMediaQuery("(max-width:600px)");
  const [openBD, setOpenBD] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const { downloadImg, downloadPdf } = useExportFile(fileName, htmlElement);

  return (
    <>
      <Tooltip title="Descargar" placement="right" arrow>
        <IconButton
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          data-html2canvas-ignore="true"
        >
          <img src={DownloadIcon} width={"40px"} alt="Descargar" />
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        open={open}
        id={open ? "simple-popover" : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        <ButtonStyled
          onClick={() => {
            setAnchorEl(null);
            downloadImg();
          }}
        >
          <Image fontSize="medium" />
          <p className="m-0 h6 ml-2">Descargar Imagen</p>
        </ButtonStyled>
        {/* )} */}

        <Divider />

        <ButtonStyled
          onClick={() => {
            setAnchorEl(null);
            downloadPdf("landscape", "A4");
          }}
        >
          <PictureAsPdf fontSize="medium" />
          <p className="m-0 h6 ml-2">Descargar PDF</p>
        </ButtonStyled>

        <Divider />
        {excel ? (
          <ButtonStyled
            onClick={() => {
              ExcelDownloadMultipleSheet(sheetName, info, client);
              setAnchorEl(null);
            }}
          >
            <GridOnRounded fontSize="medium" />
            <p className="m-0 h6 ml-2">Descargar Excel</p>
          </ButtonStyled>
        ) : (
          ""
        )}
      </Popover>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
