import React from "react";

import {
  useMediaQuery,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import { B2BForm } from "../../../components/Forms/B2BForm";

export const B2B = () => {
  
  const phone = useMediaQuery("(max-width:480px)");

  return (
    <>
      <div className="w-100 px-3">
        <div className="mt-2 bg-white w-100 d-flex justify-content-between p-2 r-15 d-flex flex-column">
          <div className="m-2">
            <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
              {" "}
              Salida de mercancia{" "}
            </p>
            <p className="m-0 text-secondary">* Campos obligatorios</p>
          </div>
          <B2BForm /> 
        </div>
      </div>
    </>
  );
};
