import React, { useState, useEffect, useContext } from "react";
import { SalesContext } from "../../context/SalesContext";
import { FilterContext } from "../../context/FilterContext";

import { Grid, Popover, useMediaQuery } from "@mui/material";

import { Search } from "../../components/Search";
import { ToggleView } from "../../components/ToggleView";

import { DownloadButtonExcel } from "../../components/Excel/DownloadButtonExcel";
import { HFMActiveSales } from "../../components/headers";

import { roles } from "../../helpers/enums";
import { ModalFilter } from "../../components/Modal/ModalFilter";
import { SkeletonCard } from "../../components/Skeleton/SkeletonCard";
import { RefreshButton } from "../../components/RefreshButton";

import { ListViewSalesHeadFieldManager } from "../ListViewSalesHeadFieldManager";
import { TableActiveSalesHFM } from "../../components/Tables/TableActiveSalesHFM";
import { SkeletonTable } from "../../components/Skeleton/SkeletonTable";

export const ActiveSalesHeadFieldManager = ({ socketReload }) => {
  const [view, setView] = useState(true);
  const phone = useMediaQuery("(max-width:480px)");
  const tablet = useMediaQuery("(max-width:750px)");
  const [ready, setReady] = useState(false);

  const {
    getSalesList,
    getActiveSalesCardView,
    data,
    cardViewData,
    cardViewTotal,
    page,
    setPage,
    total,
    setTotal,
    count,
    isLoading,
    isLoadingExcel,
    getExcelData,
    excelData,
  } = useContext(SalesContext);

  const {
    resetFilter,
    search,
    setSearch,
    getBranchesByHFM,
    getPickersByHFM,
    getChannelsByHFM,
    getClientsByHFM,
  } = useContext(FilterContext);
  //------------------------------------------------

  //MODAL STATES
  //------------------------------------------------

  const [invisible, setInvisible] = useState(false);

  //------------------------------------------------

  //EFFECTS
  //------------------------------------------------
  // active true means active sales

  const active = true;

  useEffect(() => {
    setPage(0);
    setSearch("");
    resetFilter();
    requestFilterData();
    setReady(true);
  }, []);

  useEffect(() => {
    if (ready) {
      requestData();
    }
  }, [ready]);

  useEffect(() => {
    setPage(0);
    resetFilter();
    setSearch("");
    setReady(true);
  }, [view]);

  useEffect(() => {
    if (view) {
      getSalesList();
    } else {
      getActiveSalesCardView();
    }
  }, [socketReload]);

  //------------------------------------------------

  //DATA REQUEST
  //------------------------------------------------

  const requestData = () => {
    if (!view) {
      getActiveSalesCardView();
    } else {
      getSalesList(active);
    }
    setReady(false);
  };

  const requestFilterData = () => {
    getBranchesByHFM();
    getPickersByHFM();
    getChannelsByHFM();
    getClientsByHFM();
  };

  //------------------------------------------------
  //HANDLERS
  //------------------------------------------------

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleCloseModal = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="w-100 px-3 mt-2">
        {tablet ? (
          <div className="mt-2 w-100 p-1 r-15">
            <div className="mb-2">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                requestData={requestData}
                setPage={setPage}
              />
            </div>

            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <RefreshButton text={""} onClick={requestData} />
                <DownloadButtonExcel
                  text={""}
                  disabled={data.length > 0 ? false : true}
                  getData={() => getExcelData(active)}
                  headers={HFMActiveSales}
                  data={excelData}
                  sheetName={`Ventas en curso`}
                  isLoading={isLoadingExcel}
                />
              </div>

              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        ) : (
          <div className="mt-2 w-100 d-flex justify-content-between p-1 r-15">
            <div className="d-flex align-items-center">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                requestData={requestData}
                setPage={setPage}
              />
              <div className="d-flex align-items-center ml-1">
                <RefreshButton text={"Actualizar"} onClick={requestData} />
                <DownloadButtonExcel
                  text={"Descargar"}
                  disabled={data.length > 0 ? false : true}
                  getData={() => getExcelData(active)}
                  headers={HFMActiveSales}
                  data={excelData}
                  sheetName={`Ventas en curso`}
                  isLoading={isLoadingExcel}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        )}
        {view ? (
          // Vista de tabla
          isLoading ? (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <SkeletonTable num={3} headers={HFMActiveSales} />
            </div>
          ) : (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <TableActiveSalesHFM
                data={data}
                search={search}
                type={roles.ADMIN}
                setPage={setPage}
                page={page}
                total={total}
                headers={HFMActiveSales}
                requestData={requestData}
                count={count}
              />
            </div>
          )
        ) : (
          <ListViewSalesHeadFieldManager
            data={cardViewData}
            search={search}
            setPage={setPage}
            page={page}
            total={cardViewTotal}
            count={count}
            active={active}
            headers={HFMActiveSales}
            requestData={requestData}
            isLoading={isLoading}
            ready={ready}
            setReady={setReady}
          />
        )}
      </div>

      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseModal}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ModalFilter
            handleCloseModal={handleCloseModal}
            dates={true}
            fields={[
              "sucursal",
              "picker",
              "orderType",
              "canal",
              "client",
              "salesHFMActiveStatus",
            ]}
            requestData={requestData}
            view={view}
            setPage={setPage}
          />
        </Popover>
      </>
    </>
  );
};
