import { useEffect, useRef, useState } from "react";

export const useCountdown = (seconds) => {
    const [timeLeft, setTimeLeft] = useState(seconds);
    const intervalRef = useRef();
  
    useEffect(() => {
      intervalRef.current = setInterval(() => {
        setTimeLeft((t) => t - 1);
      }, 1000);
      return () => clearInterval(intervalRef.current);
    }, []);
  
    useEffect(() => {
      if (timeLeft <= 0) {
        clearInterval(intervalRef.current);
      }
    }, [timeLeft]);

    return [timeLeft, setTimeLeft]
  };
  