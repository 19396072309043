import React from 'react'

import { Grid, Paper, IconButton, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles';
import { Info } from '@mui/icons-material';

import { EditTwoTone } from "@mui/icons-material";

import { Navigate, useNavigate } from 'react-router-dom';

import { roles } from '../../helpers/enums';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    borderRadius: 10,
    minHeight: 200,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between"
}));

export const CardClient = ({ info, type }) => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/administrador/clientes/editar/', {
            state: {
                idClient: info.id,
                business: info.razon_social ? info.razon_social : "",
                rfc: info.rfc ? info.rfc : "",
                regime: info.regimen_fiscal ? info.regimen_fiscal : "",
                website: info.sitio_web ? info.sitio_web : "",
                name: info.nombre ? info.nombre : "",
                lastName: info.apellidos ? info.apellidos : "",
                email: info.email ? info.email : "",
                personalNumber: info.telefono ? info.telefono : "",
                officeNumber: info.telefono_oficina ? info.telefono_oficina : "",
                street: info.calle ? info.calle : "",
                suburb: info.colonia ? info.colonia : "",
                postalCode: info.codigo_postal ? info.codigo_postal : "",
                limit: info.plazo_pago ? info.plazo_pago : "",

                //-----------------------------FILES--------------------------------//
                address: info.files.length != 0 ? info.files[0].address_proof != null ? info.files[0].address_proof : "" : "",
                contract: info.files.length != 0 ? info.files[0].ds_agreement != null ? info.files[0].ds_agreement : "" : "",
                act: info.files.length != 0 ? info.files[0].founding_act != null ? info.files[0].founding_act : "" : "",
                id: info.files.length != 0 ? info.files[0].id_legal_repr != null ? info.files[0].id_legal_repr : "" : "",
                fiscal: info.files.length != 0 ? info.files[0].rfc_evidence != null ? info.files[0].rfc_evidence : "" : "",
                other1: info.files.length != 0 ? info.files[0].extra_field_1 != null ? info.files[0].extra_field_1 : "" : "",
                other2: info.files.length != 0 ? info.files[0].extra_field_2 != null ? info.files[0].extra_field_2 : "" : "",
            }
        });
    };

    return (
        <Grid item xs={6} sm={3} md={2.4} lg={2.4} xl={2.4}>
            <Item className='mb-3 hover-card cursor-pointer w-100' onClick={handleClick}>

                <div className='d-flex justify-content-end w-100'>
                    <Tooltip title={"Editar"} arrow placement="top">
                        <div className={`hover-icon`}>
                            <IconButton onClick={handleClick}>
                                <EditTwoTone />
                            </IconButton>
                        </div>
                    </Tooltip>
                </div>

                <div className="w-100"> 
                {
                            info.razon_social != undefined &&
                    <Tooltip title={info.razon_social.length > 20 ? info.razon_social : ""} placement="top" arrow>
                        <div>
                           
                            <strong> {info.razon_social.substring(0, 20)}{info.razon_social.length > 20 && "..."}</strong>
                            
                        </div>
                    </Tooltip>
}
                    <div className="w-100">
                        Régimen físcal: {info.regimen_fiscal}
                    </div>
                    <div className="w-100">
                        RFC: {info.rfc}
                    </div>
                    <div className="w-100">
                        Nombre de contacto: {info.nombre}
                    </div>
                    <div className="w-100">
                        Teléfono: {info.telefono}
                    </div>
                    <Tooltip title={info.email.length > 12 ? info.email : ""} placement="top" arrow>
                        <div>
                            Email: {info.email.substring(0, 12)}{info.email.length > 12 && "..."}
                        </div>
                    </Tooltip>
        
                </div>

            </Item>
        </Grid>

    )
}
