import React from "react";

import { Typography, Box, useMediaQuery } from "@mui/material";

import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";

import { Error } from "@mui/icons-material";

export const ModalError = ({ text, handleClose, error }) => {
  const phone = useMediaQuery("(max-width:480px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "90%" : 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
  };

  return (
    <Box sx={style}>
      <div className="d-flex justify-content-center align-items-center text-center w-100">
        {text}
      </div>
      <div className="d-flex justify-content-center">
        {/* {errorPassWord ? "Inténtelo de nuevo." : "Inténtelo más tarde."} */}
      </div>

      {typeof error == "string" || error == undefined ? (
        <div className="d-flex justify-content-center align-items-center xs-font text-danger text-center">
          {error}
        </div>
      ) : error.length < 4 ? (
        error.map((err) => {
          return (
            <div
              key={err}
              className="d-flex justify-content-center align-items-center xs-font text-danger text-center"
            >
              {err}.
            </div>
          );
        })
      ) : (
        <div className="d-flex justify-content-center align-items-center xs-font text-danger text-center">
          Tu archivo tiene demasiados errores, revisa nuevamente las
          instrucciones de llenado.
        </div>
      )}

      <div className="d-flex justify-content-center mt-2">
        <Error sx={{ color: "red", fontSize: 50 }} />
      </div>
      {/* <div className="d-flex justify-content-center xs-font text-danger text-center mt-2">
        {errorSizes ? "Te redirigiremos al formulario de edición de producto." : null}
      </div> */}
      <div className="d-flex justify-content-center mt-3">
        {/* <CancelButton text={"Salir"} onClick={handleClose}/> */}
        <AcceptButton
          text={"Ok"}
          onClick={handleClose}
          width={phone ? "100%" : ""}
        />
      </div>
    </Box>
  );
};
