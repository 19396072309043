import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";

import { CardGraph } from "../Card/CardGraph";

const options = {
  width: "100%",
  chartArea: { width: "80%", height: "80%" },
  hAxis: {
    // title: "Cantidad",
    title: "Piezas",
    minValue: 0,
  },
  vAxis: {
    textStyle: {
      width: "100%",
    },
    viewWindowMode: "explicit",
    // textPosition: "none",
    // title: "Productos",
  },
  legend: "none",
  annotations: {
    textStyle: {
      // The color of the text.
      color: "#000",
    },
  },
  colors: ["#8CC044"],
};

const modalOption = {
  vAxis: {
    title: "SKU",
    minValue: 0,
    titleTextStyle: {
      color: "black",
      bold: true,
    },
  },
  hAxis: {
    minValue: 1,
    titleTextStyle: {
      color: "black",
      bold: true,
    },
  },
};

export const MoreExistancesGraph = ({
  data,
  dataFull,
  isLoading,
  client,
  filters,
}) => {
  const rol = localStorage.getItem("rol");

  return (
    <>
      <Grid item xs={4}>
        <CardGraph
          title={"Artículos con más stock"}
          type={"BarChart"}
          data={data}
          options={options}
          isLoading={isLoading}
          modalOption={modalOption}
          dataFull={dataFull}
          client={client}
          filters={filters}
        />
      </Grid>
    </>
  );
};
