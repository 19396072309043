import { useState } from "react";
import Box from "@mui/material/Box";
import { IconButton, useMediaQuery, Backdrop } from "@mui/material";
import { Close } from "@mui/icons-material";

import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import LoaderDS from "../../assets/LoaderDS.svg";

export default function ModalPdfClientForm({
  pdf,
  handleClosePdf,
  disable,
  label,
}) {
  const phone = useMediaQuery("(max-width:600px)");
  const tablet = useMediaQuery("(max-width:900px)");
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "95%" : "80%",
    maxHeight: 700,
    height: "80vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setIsLoading(false);
  };

  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "standard_fonts/",
  };

  return (
    <>
      <Box sx={style}>
        <div className="d-flex justify-content-center align-items-center mt-3 mb-2">
          <p
            className={`font-weight-bold gray-font m-0 ${phone ? "h5" : "h4"}`}
          >
            {label}
          </p>
          <IconButton
            onClick={handleClosePdf}
            style={{ position: "absolute", right: 3 }}
          >
            <Close sx={{ fontSize: 35 }} />
          </IconButton>
        </div>
        {/* <img src="" alt="" /> */}

        <div
          style={{
            height: "90%",
            width: "100%",
            border: "solid 1px #575757",
          }}
        >
          <div className="grey-light-background d-flex justify-content-center p-1">
            <button
              className="zoom-button"
              onClick={() => setScale(scale + 0.1)}
              type="button"
            >
              +
            </button>
            <button
              className="zoom-button"
              onClick={() => setScale(scale - 0.1)}
              type="button"
            >
              -
            </button>
          </div>
          <div
            style={{
              overflowY: "scroll",
              height: "100%",
              width: "100%",
            }}
            className="grey-light-background d-flex justify-content-center"
          >
            <Document
              file={`${pdf}`}
              onContextMenu={(e) => e.preventDefault()}
              onLoadSuccess={onDocumentLoadSuccess}
              className="pdf-container"
              onLoadError={console.error}
              options={options}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page pageNumber={index + 1} width={925} scale={scale} />
              ))}
            </Document>
          </div>
        </div>

        {/* <iframe
        style={{ pointerEvents: "none" }}
        src={`${pdf}#view=fitH&toolbar=0`}
        title="testPdf"
        height="100%"
        width="100%"
      /> */}
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
}
//#view=fitH

// #toolbar=0
