import { Grid } from "@mui/material";
import React from "react";
import { CardPickers } from "../components/Card/CardPickers";
import { ListPagination } from "../components/Pagination/ListPagination";

export const ListViewPickers = ({
  search,
  data,
  type,
  setPage,
  page,
  total,
  count,
  requestData,
}) => {
  return (
    <div className="mt-3 w-100  r-15">
      <Grid
        container
        alignItems="center"
        direction="row"
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
        columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
      >
        {data.map((row, index) => (
          <CardPickers
            key={index}
            info={row}
            type={type}
            requestData={requestData}
          />
        ))}
      </Grid>

      {data.length == 0 && search.length != 0 ? (
        <div className="d-flex justify-content-center mt-2">
          No se encontraron resultados para "{search}"
        </div>
      ) : (
        ""
      )}
      {data.length == 0 && (
        <div className="d-flex justify-content-center mt-2">
          No tienes pickers aún.
        </div>
      )}
      <ListPagination
        setPage={setPage}
        page={page}
        total={total}
        count={count}
        requestData={requestData}
      />
    </div>
  );
};
