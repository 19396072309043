import React, { useState } from "react";

import { Backdrop, Modal } from "@mui/material";

import { TextField, CircularProgress, useMediaQuery } from "@mui/material";

import { AcceptButton } from "../Buttons/AcceptButton";

import { ModalRecovery } from "../Modal/ModalRecovery";

import { useFormik } from "formik";

import { CancelButton } from "../Buttons/CancelButton";

import { useNavigate } from "react-router-dom";
import { ModalSuccessPassword } from "../Modal/ModalSuccessPassword";
import LoaderDS from "../../assets/LoaderDS.svg";
import { useMemo } from "react";
import { LoginAPI } from "../../api/Login";

const validate = (values) => {
  const errors = {};
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Cuenta inválida";
  }
  return errors;
};

export const RecoveryForm = () => {
  const phone = useMediaQuery("(max-width:480px)");

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [openBD, setOpenBD] = useState(false);
  const [error, setError] = useState(false);
  const [openModalRecovery, setOpenModalRecovery] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [email, setEmail] = useState("");

  const handleClose = () => setOpenModalRecovery(false);

  const handleCancelReset = () => {
    navigate("/");
  };

  const handleSuccess = () => {
    handleClose();
    setOpenSuccess(true);
    setTimeout(() => {
      setOpenSuccess(false);
      navigate("/");
    }, 2500);
  };

  // apis
  const loginAPI = useMemo(() => new LoginAPI(), []);

  const getRecovery = async (values) => {
    setOpenBD(true);
    let data = new FormData();

    data.append("email", values.email);
    data.append("step", 1);

    const response = await loginAPI.RecoveryEP(data);
    if (response.status == 200) {
      setOpenModalRecovery(true);
      setOpenBD(false);
    } else if (response.status == 40) {
      setError(true);
      setOpenBD(false);
    } else {
      // console.log(response);
      setOpenBD(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: (values) => {
      setEmail(values.email);
      getRecovery(values);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div key={"email"} className={` d-flex mb-3 ${phone ? "" : "px-4"}`}>
          <div className="d-flex align-items-center mr-2">
            <label
              className={`${phone ? "h6" : "h5"} m-0 no-wrap text-left`}
              htmlFor={"email"}
            >{`Correo: `}</label>
          </div>
          <div className="d-flex align-items-center w-100">
            <TextField
              size="small"
              label={"Correo"}
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.email}
              name={"email"}
              id={"email"}
            />
          </div>
          {/* {formik.errors.email ? <div>{formik.errors.email}</div> : null} */}
        </div>
        {error ? (
          <div className="mb-3">
            <p className="m-0 text-danger">
              Introduce una dirección de correo válida
            </p>
          </div>
        ) : (
          ""
        )}
        <CancelButton
          text={"Cancelar"}
          type={"text"}
          onClick={handleCancelReset}
        />
        <AcceptButton text={"Recuperar"} type={"submit"} />
      </form>

      <Modal open={openModalRecovery} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalRecovery
            handleClose={handleClose}
            email={email}
            handleSuccess={handleSuccess}
          />
        </div>
      </Modal>

      <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalSuccessPassword />
        </div>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
