import React, { useState } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
  styled,
  tableCellClasses,
} from "@mui/material";

import { roles } from "../../helpers/enums";

import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";
import { ListPagination } from "../Pagination/ListPagination";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const TableSales = ({
  search,
  data,
  type,
  setPage,
  page,
  total,
  rol,
  requestData,
  count,
}) => {
  const { height, width } = useViewPortDimensions();
  const navigate = useNavigate();
  const handleClick = (row) => {
    navigate(`/${rol}/ventas/B2C/detalle/`, {
      state: {
        id: row.id ? row.id : "",
      },
    });
  };

  const status = [
    { id: 1, value: "Generado" },
    { id: 2, value: "Asignado" },
    { id: 3, value: "En ruta" },
    { id: 4, value: "Finalizado" },
    { id: 5, value: "Otro" },
    { id: 6, value: "Cancelado" },
    { id: 7, value: "Entrega parcial" },
  ];

  const tRow = (row, index) => {
    const labelId = `enhanced-table-checkbox-${index}`;
    return (
      <TableRow
        hover
        // onClick={(e) => handleClick(e, row)}
        role="checkbox"
        tabIndex={-1}
        key={row.id}
      >
        {/* {type != roles.ADMIN && */}
        <TableCell padding="checkbox">
          <Tooltip title={"Ver Detalle"} arrow placement="top">
            <div className={`hover-icon`}>
              <IconButton
                onClick={() => {
                  handleClick(row);
                }}
              >
                <VisibilityIcon />
              </IconButton>
            </div>
          </Tooltip>
        </TableCell>
        {/* } */}
        {type == roles.ADMIN && (
          <TableCell align="center">{row.cliente}</TableCell>
        )}
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          align="center"
        >
          {row.codigo_pedido}
        </TableCell>
        <TableCell align="center">{row.canal}</TableCell>
        <TableCell align="center">
          {row.pedido_generado == "3"
            ? "Cancelada"
            : row.estatus_envio != null &&
              status.find((el) => el.id == row.estatus_envio).value}
        </TableCell>
        <TableCell align="center">{`$ ${new Intl.NumberFormat("es-MX").format(
          row.monto_pedido.toFixed(2)
        )}`}</TableCell>
        <TableCell align="center">{row.almacen_asignado}</TableCell>
        <TableCell align="center">{row.fecha_creacion}</TableCell>
        {/* <TableCell align="center">{row.fecha_creacion.split("-").reverse("").join("-")}</TableCell> */}
      </TableRow>
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: height - (74 + 90 + 60) }}>
          <Table sx={{ minWidth: 750 }} aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow>
                {/* {type == roles.CLIENT &&  */}
                <StyledTableCell></StyledTableCell>
                {/* } */}
                {type == roles.ADMIN && (
                  <StyledTableCell align="center">Cliente</StyledTableCell>
                )}
                <StyledTableCell align="center">Código pedido</StyledTableCell>
                <StyledTableCell align="center">Canal</StyledTableCell>
                <StyledTableCell align="center">Estatus</StyledTableCell>
                <StyledTableCell align="center" sx={{ minWidth: 125 }}>
                  Monto
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ minWidth: 125 }}>
                  Sucursal
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ minWidth: 125 }}>
                  Fecha de creación
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{data.map((row, index) => tRow(row, index))}</TableBody>
          </Table>
          {data.length == 0 && search != "" ? (
            <div className="d-flex justify-content-center mt-2">
              No se encontraron resultados para "{search}"
            </div>
          ) : (
            data.length == 0 &&
            search == "" && (
              <div className="d-flex justify-content-center mt-2">
                No tienes ventas aún.
              </div>
            )
          )}
          {/* {data.length == 0 && (
                        <div className="d-flex justify-content-center mt-2">
                            No tienes ventas aún.
                        </div>
                    )} */}
        </TableContainer>
        <ListPagination
          setPage={setPage}
          page={page}
          total={total}
          requestData={requestData}
          count={count}
        />
      </Paper>
    </Box>
  );
};
