import React from "react";

import { Grid, Paper, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import Logo from "../../assets/branding/boxai_logo.svg";
import { Link } from "react-router-dom";

import { LoginForm } from "../../components/Forms/LoginForm";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderRadius: 15,
}));

export const Login = () => {
  const phone = useMediaQuery("(max-width:480px)");

  return (
    <div
      className={
        "d-flex flex-column h-100vh align-items-center justify-content-center"
      }
    >
      <img alt="BoxAI Plus Logo" src={Logo} width={phone ? "90%" : 400} />
      <Item className={`py-4 px-3 m-3`}>
        <p className={`${phone ? "h5" : "h3"} font-weight-bold text-dark`}>
          Iniciar sesión
        </p>
        <div className="mt-3">
          <LoginForm />
        </div>
        <div>
          <Link to="/recuperarCuenta">
            <p className="m-0 mt-3 ds-blue-font cursor-pointer link-decorated">
              ¿Olvidaste tu contraseña?
            </p>
          </Link>
        </div>
      </Item>
    </div>
  );
};
