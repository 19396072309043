import React from "react";

import { Typography, Box, useMediaQuery } from "@mui/material";

import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";

import { CheckCircle } from "@mui/icons-material";

export const ModalProductSuccess = ({ handleClose, text, edit, massive }) => {
  const phone = useMediaQuery("(max-width:480px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "90%" : 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
  };

  return (
    <Box sx={style}>
      <div className="d-flex justify-content-center align-items-center text-center w-100">
        {/* <Typography id="modal-modal-description"> */}
        {edit == "editData"
          ? "Información actualizada correctamente"
          : massive
          ? `${text} agregados correctamente`
          : `${text} agregado correctamente`}
        {/* </Typography> */}
      </div>
      <div className="d-flex justify-content-center mt-2">
        <CheckCircle sx={{ color: "#6bb120", fontSize: 50 }} />
      </div>
      {edit == "editData" ? (
        <div className="d-flex justify-content-center mt-3">
          <AcceptButton onClick={() => handleClose("")} text={"Aceptar"} />
          {/* <CancelButton text={"Salir"} onClick={() => handleClose("")}  /> */}
        </div>
      ) : massive ? (<div
        className={`d-flex mt-3 ${
          phone ? "flex-column" : "justify-content-center"
        }`}
      >
        <div className={`${phone && "mb-2"}`}>
          <CancelButton
            text={"Salir"}
            onClick={() => handleClose()}
            width={phone ? "100%" : ""}
          />
        </div>
      </div>) : (
        <>
          <div className="d-flex justify-content-center mt-2">
            {/* <Typography id="modal-modal-description"> */}
            ¿Deseas agregar otro {text.toLowerCase()}?{/* </Typography> */}
          </div>
          <div
            className={`d-flex mt-3 ${
              phone ? "flex-column" : "justify-content-between"
            }`}
          >
            <div className={`${phone && "mb-2"}`}>
              <CancelButton
                text={"Salir"}
                onClick={() => handleClose("")}
                width={phone ? "100%" : ""}
              />
            </div>
            <AcceptButton
              text={"Sí"}
              onClick={() => handleClose("nuevo")}
              width={phone ? "100%" : ""}
            />
          </div>
        </>
      )}
    </Box>
  );
};
