import { Grid, Divider, useMediaQuery, setRef } from "@mui/material";
import React from "react";
import { CardSalesHeadFieldManager } from "../components/Card/CardSalesHeadFieldManager";
import { ListPagination } from "../components/Pagination/ListPagination";
import { AssignmentTurnedInOutlined, HighlightOff } from "@mui/icons-material";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import InputIcon from "@mui/icons-material/Input";
import Caja from "../assets/Caja.svg";
import { SkeletonCard } from "../components/Skeleton/SkeletonCard";
import { useContext } from "react";
import { SalesContext } from "../context/SalesContext";
import { FilterContext } from "../context/FilterContext";
import { useState } from "react";
import { useEffect } from "react";

export const ListViewSalesHeadFieldManager = ({
  search,
  setPage,
  page,
  total,
  active,
  isLoading,
  headers,
  requestData,
  data,
  ready,
  setReady,
}) => {
  const phone = useMediaQuery("(max-width:480px)");
  /*
  Status: 
  finalized = 0;
  canceled = 1;
  creation = 2;
  picking = 3;
  packing: 4;
  */
  const { setSaleHFMHistoricStatus, setSaleHFMActiveStatus } =
    useContext(FilterContext);
  const [status, setStatus] = useState(active ? 2 : 0);
  const [statusTotal, setStatusTotal] = useState(0);
  const [statusData, setStatusData] = useState([]);

  useEffect(() => {
    setStatusTotal(
      {
        0: total.finalized,
        1: total.canceled,
        2: total.creation,
        3: total.picking,
        4: total.packing,
      }[status]
    );
    setStatusData(
      {
        0: data.finalized,
        1: data.canceled,
        2: data.creation,
        3: data.picking,
        4: data.packing,
      }[status]
    );
  }, [data, total]);

  useEffect(() => {
    if (ready) {
      requestData();
    }
  }, [ready]);

  // if data change, render again
  return (
    <div className="mt-3 w-100  r-15">
      {active ? (
        <div className="mt-3 bg-white p-2 w-100 r-15">
          <div
            className={`d-flex w-100 align-items-center ${
              phone ? "flex-column" : "justify-content-around"
            }`}
          >
            <div
              className={`w-100 p-2 cursor-pointer inactive-font ${
                status == 2 && "active-chip bg-light-grey"
              }`}
              onClick={() => {
                if (page > 0) {
                  setPage(0);
                  setReady(true);
                }
                setStatus(2);
                setStatusTotal(total.creation);
                setStatusData(data.creation);
                setSaleHFMActiveStatus("Creación");
              }}
            >
              <div className="d-flex justify-content-center">
                <InputIcon />
              </div>
              <div className="d-flex justify-content-center">
                <p className="m-0 h6 text-center">
                  Creación ({!isLoading ? total.creation : "..."})
                </p>
              </div>
            </div>

            <Divider orientation="vertical" flexItem className="mx-1" />

            <div
              className={`w-100 p-2 cursor-pointer inactive-font ${
                status == 3 && "active-chip bg-light-blue"
              }`}
              onClick={() => {
                if (page > 0) {
                  setPage(0);
                  setReady(true);
                }
                setStatus(3);
                setStatusTotal(total.picking);
                setStatusData(data.picking);
                setSaleHFMActiveStatus("Picking");
              }}
            >
              <div className="d-flex justify-content-center">
                <ShoppingBasketIcon />
              </div>
              <div className="d-flex justify-content-center">
                <p className="m-0 h6 text-center">
                  Picking ({!isLoading ? total.picking : "..."})
                </p>
              </div>
            </div>
            <Divider orientation="vertical" flexItem className="mx-1" />

            <div
              className={`w-100 p-2 cursor-pointer inactive-font ${
                status == 4 && "active-chip bg-green"
              }`}
              onClick={() => {
                if (page > 0) {
                  setPage(0);
                  setReady(true);
                }
                setStatus(4);
                setStatusTotal(total.packing);
                setStatusData(data.packing);
                setSaleHFMActiveStatus("Packing");
              }}
            >
              <div className="d-flex justify-content-center">
                <ShoppingBagIcon />
              </div>
              <div className="d-flex justify-content-center">
                <p className="m-0 h6 text-center">
                  Packing ({!isLoading ? total.packing : "..."})
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-3 bg-white p-2 w-100 r-15">
          <div
            className={`d-flex w-100 align-items-center ${
              phone ? "flex-column" : "justify-content-around"
            }`}
          >
            <div
              className={`w-100 p-2 cursor-pointer inactive-font ${
                status == 0 && "active-chip bg-green"
              }`}
              onClick={() => {
                if (page > 0) {
                  setPage(0);
                  setReady(true);
                }
                setStatus(0);
                setStatusTotal(total.finalized);
                setStatusData(data.finalized);
                setSaleHFMHistoricStatus("Finalizada");
              }}
            >
              <div className="d-flex justify-content-center">
                <AssignmentTurnedInOutlined />
              </div>
              <div className="d-flex justify-content-center">
                <p className="m-0 h6 text-center">
                  Finalizadas ({!isLoading ? total.finalized : "..."})
                </p>
              </div>
            </div>

            <Divider orientation="vertical" flexItem className="mx-1" />

            <div
              className={`w-100 p-2 cursor-pointer inactive-font ${
                status == 1 && "active-chip bg-light-blue"
              }`}
              onClick={() => {
                if (page > 0) {
                  setPage(0);
                  setReady(true);
                }
                setStatus(1);
                setStatusTotal(total.canceled);
                setStatusData(data.canceled);
                setSaleHFMHistoricStatus("Cancelada");
              }}
            >
              <div className="d-flex justify-content-center">
                <HighlightOff />
              </div>
              <div className="d-flex justify-content-center">
                <p className="m-0 h6 text-center">
                  Canceladas ({!isLoading ? total.canceled : "..."})
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mt-3 w-100 r-15">
        {isLoading ? (
          <Grid
            container
            alignItems="center"
            direction="row"
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
            columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
          >
            <SkeletonCard num={10} headers={headers} icons={0} />
          </Grid>
        ) : (
          <>
            {statusData.length > 0 ? (
              <Grid
                container
                alignItems="center"
                direction="row"
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
                columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
              >
                {statusData.map((row, index) => (
                  <CardSalesHeadFieldManager key={index} info={row} />
                ))}
              </Grid>
            ) : search == "" ? (
              <div className="d-flex justify-content-center mt-2">
                No tienes ventas aún.
              </div>
            ) : (
              <div className="d-flex justify-content-center mt-2">
                No se encontraron resultados para "{search}"
              </div>
            )}
            <ListPagination
              setPage={setPage}
              page={page}
              total={statusTotal}
              requestData={requestData}
            />
          </>
        )}
      </div>
    </div>
  );
};
