import React, { useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  TextField,
  CircularProgress,
  useMediaQuery,
  InputAdornment,
  IconButton,
} from "@mui/material";

import { AcceptButton } from "../Buttons/AcceptButton";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAuthContext } from "../../context/AuthProvider";

export const LoginForm = () => {
  const { canAccess, errors, isLoading } = useAuthContext();

  const [showPassword, setShowPassword] = useState(false);
  const [counter, setCounter] = useState(0);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const phone = useMediaQuery("(max-width:480px)");

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    // validate,
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Formato incorrecto"
        )
        .required("Campo Obligatorio"),
      password: Yup.string().required("Campo obligatorio"),
    }),

    onSubmit: (values) => {
      canAccess(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div key={"email"} className={` d-flex mb-3 ${phone ? "" : "px-4"}`}>
        <TextField
          disabled={counter === 5 ? true : false}
          error={formik.touched.email && formik.errors.email}
          size="small"
          label={"Correo"}
          variant="outlined"
          fullWidth
          onChange={formik.handleChange}
          value={formik.values.email ?? " "}
          name={"email"}
          id={"email"}
          helperText={
            formik.touched.email && formik.errors.email ? (
              <div>{formik.errors.email}</div>
            ) : null}
            InputLabelProps={{ shrink: true }}
          />
      </div>

      <div key={"password"} className={` d-flex mb-3 ${phone ? "" : "px-4"}`}>
        <TextField
          disabled={counter === 5 ? true : false}
          error={formik.touched.password && formik.errors.password}
          helperText={
            formik.touched.password && formik.errors.password ? (
              <div>{formik.errors.password}</div>
            ) : null
          }
          size="small"
          type={showPassword ? "text" : "password"}
          label={"Contraseña"}
          variant="outlined"
          fullWidth
          onChange={formik.handleChange}
          value={formik.values.password ?? " "}
          name={"password"}
          id={"password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
        />
      </div>
      {errors.wrongEmail ? (
        <div className="mb-3">
          {/* <p className="m-0 text-danger">{counter == 5 ? "Espera 5 segundos y vuelve a intentarlo" : "Usuario inexistente."}</p> */}
          <p className="m-0 text-danger">Usuario inexistente</p>
        </div>
      ) : (
        ""
      )}
      {errors.nonAllowedUser ? (
        <div className="mb-3">
          <p className="m-0 text-danger">
            Usuario no tiene privilegios para ingresar.
          </p>
        </div>
      ) : (
        ""
      )}
      {errors.wrongPassword ? (
        <div className="mb-3">
          <p className="m-0 text-danger">Contraseña incorrecta.</p>
        </div>
      ) : (
        ""
      )}

      <AcceptButton
        text={
          !isLoading ? "Entrar" : <CircularProgress color="inherit" size={20} />
        }
        type={"submit"}
        disabled={counter === 5 ? true : false}
        isLoading={isLoading}
      />

      {/* <AcceptButton
  text={
    !isLoading ? "Entrar" : <CircularProgress color="inherit" size={20} />
  }
  type={"submit"}
  disabled={counter === 5 ? true : false}
  isLoading={isLoading}
/> */}
    </form>
  );
};
