import React from "react";

import { Grid, TextField, MenuItem } from "@mui/material";
import { CardGraph } from "../Card/CardGraph";

const options = {
  // legend: { position: "bottom" },
  curveType: "function",
  hAxis: {
    title: "Piezas",
    titleTextStyle: {
      color: "black",
      bold: true,
    },
    // minValue: 1,
  },

  vAxis: {
    title: "Monto",
    titleTextStyle: {
      color: "black",
      bold: true,
    },
    format: "short",
    viewWindow: { min: 0 },
    viewWindowMode: "explicit",
  },
};
const modalOption = {
  titleTextStyle: {
    fontSize: 20,
  },
};

export const PiecesSoldByDayGraph = ({
  data,
  isLoading,
  product,
  products,
  setIdProduct,
  setProduct,
  productOk,
  client,
  filters
}) => {
  console.log("estos son los products", products)
  const handleOnchange = (e) => {
    const value = e.target.value;
    setProduct(value);
    const aux = products.find((el) => el.descripcion == value);
    setIdProduct(aux.id);
  };

  const filterInput = (
    <div className="w-100">
      <TextField
        disabled={products.length == 0 ? true : false}
        select
        size="small"
        label={products.length == 0 ? "Sin ventas" : "Producto"}
        value={product}
        name={"product"}
        id={"product"}
        onChange={(e) => {
          handleOnchange(e);
        }}
        fullWidth
      >
        {products.map((product) => (
          <MenuItem key={product.name} value={product.name}>
            {product.name}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );

  return (
    <>
      <Grid item xs={4} sm={4} md={8}>
        <CardGraph
          title={"Ventas Por Día"}
          type={
            "LineChart"
          } /*"linechart" is used because "line" needs two "spans" to draw the line, while "LC" draws a point when there is only one "span"*/
          data={data}
          options={options}
          modalOption={modalOption}
          isLoading={isLoading}
          client={client}
          filterInput={filterInput}
          productOk={productOk}
          product={product}
          filters={filters}
        />
      </Grid>
    </>
  );
};
