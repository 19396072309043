import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Divider } from "@mui/material";
import React, { useState } from "react";
import { MoreVertRounded } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { StyledList, StyledMenuItem, StyledPopover } from "./SidebarStyles";

export const SubMenu = ({ section }) => {
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const openSection = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    section && (
      <StyledList
        style={{
          pointerEvents: "auto",
          padding: 2,
        }}
      >
        <ListItemText
          primary={section.name}
          primaryTypographyProps={{
            fontWeight: "bold",
          }}
          className={"px-3 py-0"}
        />

        {section.submenu && (
          <>
            <Divider />
            {section.submenu.map((el, index) => (
              <>
                <Link to={`/${el.link}`} underline="none" key={index}>
                  <StyledMenuItem
                    selected={
                      !el.submenu
                        ? pathname.includes(el.link)
                        : el.submenu.find(
                            (sm) => pathname.includes(sm.link) && sm.link != "/"
                          )
                        ? true
                        : false
                    }
                    sx={{
                      py: 0,
                      pr: 0,
                      mx: 1,
                      my: 0.5,
                    }}
                    onMouseEnter={(e) =>
                      el.submenu ? handlePopoverOpen(e) : handlePopoverClose()
                    }
                  >
                    <ListItemIcon
                      sx={{
                        color: "inherit",
                        width: "23px",
                      }}
                    >
                      {el.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={el.name}
                      primaryTypographyProps={{
                        fontSize: "15px",
                        //fontWeight: "bold",
                        marginRight: 1,
                      }}
                    />
                    {el.submenu && <MoreVertRounded sx={{ width: 18 }} />}
                  </StyledMenuItem>
                </Link>
                {el.submenu && (
                  <StyledPopover
                    id="mouse-over-popover"
                    open={openSection}
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    onClose={handlePopoverClose}
                    sx={{ pointerEvents: "none", ml: 0.8, mt: -0.5 }}
                    PaperProps={{
                      onMouseLeave: handlePopoverClose,
                    }}
                  >
                    <div
                      style={{
                        pointerEvents: "auto",
                      }}
                    >
                      {el.submenu.map((sub, index) => (
                        <Link to={`/${sub.link}`} underline="none" key={index}>
                          <StyledMenuItem
                            selected={pathname == `/${sub.link}`}
                            sx={{
                              py: 0,
                              mx: 1,
                              my: 0.5,
                            }}
                          >
                            <ListItemText
                              primary={sub.name}
                              primaryTypographyProps={{
                                fontSize: "13px",
                                //fontWeight: "bold",
                              }}
                            />
                          </StyledMenuItem>
                        </Link>
                      ))}
                    </div>
                  </StyledPopover>
                )}
              </>
            ))}
          </>
        )}
      </StyledList>
    )
  );
};
