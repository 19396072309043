import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { useMediaQuery, IconButton, Box } from "@mui/material";

import { Close } from "@mui/icons-material";
import { CancelEntryButton } from "../Buttons/CancelEntryButton";
import { CancelButton } from "../Buttons/CancelButton";
import { AcceptButton } from "../Buttons/AcceptButton";

export const ModalDelete = ({
  type,
  info,
  handleClose,
  deleteObject,
  auxText,
  text,
}) => {
  const phone = useMediaQuery("(max-width:480px)");

  const handleDelete = () => {
    deleteObject(info);
    handleClose();
  };

  return (
    <div className="p-3">
      {type == "picker" ? (
        <div className="w-100">
          <div className="w-100 text-center">
            <p id="modal-modal-description" className="ds-blue-font h6 m-0">
              {`Estas a punto de dar de baja ${auxText}:`}
            </p>
            <p className="font-weight-bold">
              {info.nombre} {info.apellidos}
            </p>
          </div>
          <div className="w-100 text-center mt-2">
            <p id="modal-modal-description" className="ds-blue-font h6 m-0">
              ¿Deseas continuar?
            </p>
          </div>

          <div
            className={`d-flex mt-3 ${
              phone ? "flex-column" : "justify-content-end"
            }`}
          >
            <CancelEntryButton
              text={"Continuar"}
              onClick={handleDelete}
              width={"100%"}
            />
            <CancelButton
              text={"Cancelar"}
              onClick={handleClose}
              width={"100%"}
            />
          </div>
        </div>
      ) : (
        <div className="w-100">
          <p className="ds-blue-font medium-font m-0 text-center">
            {`${text}:`}
          </p>
          <p className="font-weight-bold medium-font text-center">{auxText}</p>
          <p className="ds-blue-font medium-font m-0 text-center">
            ¿Deseas continuar?
          </p>

          <div
            className={`d-flex mt-3 ${
              phone ? "flex-column" : "justify-content-between"
            }`}
          >
            <CancelButton text={"No"} onClick={handleClose} width={"50%"} />
            <AcceptButton text={"Sí"} onClick={handleDelete} width={"50%"} />
          </div>
        </div>
      )}
    </div>
  );
};
