import React, { useState } from "react";

import { Grid, Paper, IconButton, Tooltip, Modal } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Info } from "@mui/icons-material";

import VisibilityIcon from "@mui/icons-material/Visibility";

import { Navigate, useNavigate } from "react-router-dom";

import { roles } from "../../helpers/enums";
import ModalPDFEvidences from "../Modal/ModalPDFEvidence";
import ModalContactInfo from "../Modal/ModalContactInfo";
import ModalProducts from "../Modal/ModalProducts";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: 10,
  minHeight: 220,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  // alignItems: "center"
}));

export const CardSalesSucursal = ({ info, type, rol }) => {
  const [pdf, setPdf] = useState("");
  const [openPdf, setOpenPdf] = useState(false);

  const [contact, setContact] = useState();
  const [openContact, setOpenContact] = useState(false);

  const [order, setOrder] = useState([]);
  const [openOrder, setOpenOrder] = useState(false);

  const handlePdf = (pdf) => {
    setOpenPdf(true);
    setPdf(pdf);
  };
  const handleClosePdf = () => {
    setOpenPdf(false);
  };

  const handleContactInfo = (info) => {
    setOpenContact(true);
    setContact(info);
  };
  const handleOrder = (order) => {
    setOrder(order);
    setOpenOrder(true);
  };
  return (
    <>
      <Grid item xs={6} sm={3} md={2.4} lg={2.4} xl={2.4}>
        <Item className="mb-3 ">
          {/* <div className='ml-3'> */}

          <Tooltip
            title={info.codigo_pedido.length > 18 ? info.codigo_pedido : ""}
            placement="top"
            arrow
          >
            <div>
              <strong>
                Código pedido: {info.codigo_pedido.substring(0, 18)}
                {info.codigo_pedido.length > 18 && "..."}
              </strong>
            </div>
            {/* <div>
                            Producto actualizado: {info.description.substring(0, 15)}
                            {info.description.length > 15 && "..."}
                        </div> */}
          </Tooltip>

          <div>Canal: {info.eccommerce}</div>

          <div>Monto: {info.monto_pedido}</div>

          <div className="d-flex">
            Etiqueta:{" "}
            {info.orden_estatus.length != 0 ? (
              info.orden_estatus[0].url_etiquetado != null ? (
                <span
                  className="cursor-pointer ds-blue-font"
                  onClick={() =>
                    handlePdf(info.orden_estatus[0].url_etiquetado)
                  }
                >
                  Ver guía
                </span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>

          <div>
            Url de rastreo:{" "}
            {info.orden_estatus.length != 0 ? (
              <a
                href={info.orden_estatus[0].url_rastreo}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="ds-blue-font cursor-pointer">Ver URL</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="d-flex">
            Orden:{" "}
            {info.articulo_orden.length != 0 ? (
              <span
                className="cursor-pointer ds-blue-font"
                onClick={() => handleOrder(info.articulo_orden)}
              >
                Ver orden
              </span>
            ) : (
              ""
            )}
          </div>

          <div className="d-flex">
            Contacto:{" "}
            <span
              className="cursor-pointer ds-blue-font"
              onClick={() => handleContactInfo(info)}
            >
              Ver contacto
            </span>
          </div>

          <div>Fecha: {info.fecha_pedido}</div>
        </Item>
      </Grid>

      <Modal open={openPdf} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalPDFEvidences pdf={pdf} handleClosePdf={handleClosePdf} />
        </div>
      </Modal>

      <Modal open={openOrder} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalProducts
            order={order}
            handleClose={() => setOpenOrder(false)}
          />
        </div>
      </Modal>

      <Modal open={openContact} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalContactInfo
            info={contact}
            handleClose={() => setOpenContact(false)}
          />
        </div>
      </Modal>
    </>
  );
};
