import React from "react";

import { Button, ButtonBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';
import { AddCircle } from "@mui/icons-material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { Link } from "react-router-dom";
import { theme } from "../utils/theme";

const AddButtonStyled = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  // border: '1px solid',
  lineHeight: 1.5,
  borderRadius: 15,
  backgroundColor: "white",
  color: theme.palette.secondary.main,
  "&:hover": {
    backgroundColor: "#e9eaef",
    //   borderColor: '#0062cc',
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    //   borderColor: '#005cbf',
  },
  "&:focus": {
    //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

export const EditButton = ({ text, link, onClick }) => {
  return link.length > 0 ? (
    <Link to={link}>
      <ButtonBase className="toolbar-button">
        <AddCircle className="mr-1" fontSize="small" />
        <p className="m-0 no-wrap">{text}</p>
      </ButtonBase>
    </Link>
  ) : (
    <ButtonBase className="toolbar-button" onClick={onClick}>
      <EditIcon className="mr-1" fontSize="small" />
      <p className="m-0 no-wrap">{text}</p>
    </ButtonBase>
  );
};
