import React, { useEffect, useRef, useState } from "react";

import {
  Grid,
  useMediaQuery,
  Tooltip,
  IconButton,
  Badge,
  Popover,
} from "@mui/material";

import { DownloadButton } from "../../components/Buttons/DownloadButton";
import { WarehousesInventoryGraph } from "../../components/Graphs/WarehousesInventoryGraph";
import { LessExistancesGraph } from "../../components/Graphs/LessExistancesGraph";
import { MoreExistancesGraph } from "../../components/Graphs/MoreExistancesGraph";
import { EntriesGraph } from "../../components/Graphs/EntriesGraph";

import {
  getDateCleaned,
  getDayjsFormatted,
} from "../../helpers/getDateCleaned";

import { ModalFilters } from "../../components/Modal/ModalFilters";
import {
  graphBranchHeaders,
  graphEntriesHeaders,
  graphLessHeaders,
  graphMoreHeaders,
} from "../../components/Excel/headersGraphs";
import FilterIcon from "../../assets/SVGIcons/Filter.svg";
import { WarehouseAPI } from "../../api/Warehouse";
import { useMemo } from "react";
import { GraphsAPI } from "../../api/Graphs";
import { AdminAPI } from "../../api/Admin";
import { theme } from "../../utils/theme";
import { ReportHeader } from "../../pages/Reports/ReportHeader";

const styles = [
  [
    ["Entradas", "Cantidad", { role: "style" }],
    ["Por validar", 5, "#bdbebd"],
    ["Validadas", 10, "#89b737"],
    ["Incompletas", 2, "#7ec2e8"],
    ["Canceladas", 3, "#d9534f"],
  ],
];

export const ReportsInventory = () => {
  const phone = useMediaQuery("(max-width:480px)");
  const htmlElement = useRef(null);

  //DATA STATES
  //------------------------------------------------

  const [dataWarehouses, setDataWarehouses] = useState([]);
  const [dataMoreInventory, setDataMoreInventory] = useState([]);
  const [dataLessInventory, setDataLessInventory] = useState([]);
  const [dataMoreFullInventory, setDataMoreFullInventory] = useState([]);
  const [dataLessFullInventory, setDataLessFullInventory] = useState([]);
  const [dataEntries, setDataEntries] = useState([]);
  const [totalWarehouses, setTotalWarehouses] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);

  //------------------------------------------------

  //ERRORES
  //------------------------------------------------

  const [errors, setErrors] = useState({
    error: false,
    errorNullStart: false,
    errorNullEnd: false,
  });

  //------------------------------------------------

  //MODAL STATES
  //------------------------------------------------
  const [openFilter, setOpenFilter] = useState(false);
  const [invisible, setInvisible] = useState(false);

  //------------------------------------------------

  //LOADERS STATES
  //------------------------------------------------

  const [loaderWarehouses, setLoaderWarehouses] = useState(true);
  const [loaderMore, setLoaderMore] = useState(true);
  const [loaderLess, setLoaderLess] = useState(true);
  const [loaderEntries, setLoaderEntries] = useState(true);

  //------------------------------------------------

  //FILTERS STATES
  //------------------------------------------------

  const [client, setClient] = useState("Todos");
  const [clients, setClients] = useState([]);

  const [branch, setBranch] = useState("Todas");
  const [branches, setBranches] = useState([]);

  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

  const [startFilterDate, setStartFilterDate] = useState(firstDay);
  const [endFilterDate, setEndFilterDate] = useState(today);

  const [prevData, setPrevData] = useState({
    startFilterDate: firstDay,
    endFilterDate: today,
    client: "Todos",
    branch: "",
  });

  //------------------------------------------------

  //EFFECTS
  //------------------------------------------------

  useEffect(() => {
    GetClients();
    GetDataBranches(client);
    RequestData();
  }, []);

  //------------------------------------------------

  //EXCEL STATES AND DATA
  //------------------------------------------------

  const [excelWhs, setExcelWhs] = useState([]);
  const [excelMore, setExcelMore] = useState([]);
  const [excelLess, setExcelLess] = useState([]);
  const [excelEntries, setExcelEntries] = useState([]);

  const info = [
    {
      name: "Producto por sucursal",
      data: excelWhs,
      headers: graphBranchHeaders,
    },
    { name: "Maś stock", data: excelMore, headers: graphMoreHeaders },
    { name: "Menos stock", data: excelLess, headers: graphLessHeaders },
    { name: "Envíos", data: excelEntries, headers: graphEntriesHeaders },
  ];

  //------------------------------------------------

  //APIS
  //------------------------------------------------
  const graphAPI = useMemo(() => new GraphsAPI(), []);
  const warehouseAPI = useMemo(() => new WarehouseAPI(), []);
  const adminAPI = useMemo(() => new AdminAPI(), []);

  //------------------------------------------------

  //DATA REQUEST
  //------------------------------------------------

  const RequestData = () => {
    GetWarehousesInventory();
    GetMoreInventory();
    GetLessInventory();
    GetDataEntries();
  };

  const GetClients = async () => {
    let data = new FormData();

    const response = await adminAPI.clientsListCleanedEP(data);
    if (response.status == 200) {
      setClients([{ id: 0, razon_social: "Todos" }, ...response.data.clients]);
    } else {
      console.log(response);
    }
  };

  const GetDataBranches = async (client) => {
    let data = new FormData();

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }
    
    const response = await warehouseAPI.getBranchesList(data);
    if (response.status == 200) {
      setBranches([{ id: 0, sucursal: "Todas" }, ...response.data.branches]);
    } else {
      console.log(response);
    }
  };

  const GetWarehousesInventory = async () => {
    setLoaderWarehouses(true);
    let data = new FormData();

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }
    if (branch != "Todas") {
      const branchToSend = branches.find((el) => el.sucursal == branch);
      data.append("branch", branchToSend.id);
    }

    const response = await graphAPI.inventoryWarehousesEP(data);
    if (response.status == 200) {
      const aux = response.data.warehouses;

      if (aux.length != 0) {
        const auxExcel = response.data.excel.map((el) => ({
          branch: el.branch,
          items: el.items.toString(),
        }));
        setDataWarehouses([["Almacen", "Cantidad"], ...aux]);
        let aux4 = aux.map((el) => el[1]).reduce((a, b) => a + b);
        setTotalWarehouses(aux4);
        setExcelWhs(auxExcel);
      } else {
        setTotalWarehouses(0);
      }

      setLoaderWarehouses(false);
    } else {
      console.log(response);
    }
  };

  const GetMoreInventory = async () => {
    setLoaderMore(true);

    let data = new FormData();

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }

    if (branch != "Todas") {
      const branchToSend = branches.find((el) => el.sucursal == branch);
      data.append("branch", branchToSend.id);
    }

    const response = await graphAPI.moreStockEP(data);
    if (response.status == 200) {
      const aux = response.data.inventory.map((subarr) => [
        subarr[0],
        subarr[1],
        subarr[2].substring(0, 20),
      ]);
      let auxFull = response.data.inventory;

      if (aux.length != 0) {
        const auxExcel = response.data.excel.map((el) => ({
          product: el.product,
          description: el.description,
          stock: el.stock.toString(),
        }));
        setDataMoreInventory([
          ["Producto", "Stock", { role: "annotation" }],
          ...aux,
        ]);
        setDataMoreFullInventory([
          ["Producto", "Stock", { role: "annotation" }],
          ...auxFull,
        ]);
        setExcelMore(auxExcel);
      }
      setLoaderMore(false);
    }
  };

  const GetLessInventory = async () => {
    setLoaderLess(true);
    let data = new FormData();

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }

    if (branch != "Todas") {
      const branchToSend = branches.find((el) => el.sucursal == branch);
      data.append("branch", branchToSend.id);
    }

    const response = await graphAPI.lessStockEP(data);
    if (response.status == 200) {
      const aux = response.data.inventory.map((subarr) => [
        subarr[0],
        subarr[1],
        subarr[2].substring(0, 20),
      ]);
      let auxFull = response.data.inventory;

      if (aux.length != 0) {
        const auxExcel = response.data.excel.map((el) => ({
          product: el.product,
          description: el.description,
          stock: el.stock.toString(),
        }));
        setDataLessInventory([
          ["Producto", "Stock", { role: "annotation" }],
          ...aux,
        ]);
        setDataLessFullInventory([
          ["Producto", "Stock", { role: "annotation" }],
          ...auxFull,
        ]);
        setExcelLess(auxExcel);
      }

      setLoaderLess(false);
    }
  };

  const GetDataEntries = async () => {
    setLoaderEntries(true);
    let data = new FormData();
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }
    if (branch != "Todas") {
      const branchToSend = branches.find((el) => el.sucursal == branch);
      data.append("branch", branchToSend.id);
    }

    const response = await graphAPI.entriesGraphEP(data);
    if (response.status == 200) {
      let aux = response.data.entries;
      let aux2 = [...response.data.entries];
      let aux3 = aux2.map((element, i) => {
        return [element[0], element[1], styles[0][i + 1][2]];
      });
      if (aux.length != 0) {
        const auxExcel = response.data.excel.map((el) => ({
          type: el.type,
          total: el.total.toString(),
        }));
        setDataEntries([
          [["Entradas", "Cantidad"], ...aux],
          [["Entradas", "Cantidad", { role: "style" }], ...aux3],
        ]);
        let aux4 = aux.map((el) => el[1]).reduce((a, b) => a + b);
        setTotalEntries(aux4);
        setExcelEntries(auxExcel);
        // setExcelEntries(response.data.excel);
      }
      setLoaderEntries(false);
    }
  };

  //------------------------------------------------

  //HANDLERS
  //------------------------------------------------

  //------------------------------------------------

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleCloseFilterModal = () => setAnchorEl(null);

  const handleCloseModal = () => {
    // setOpenFilter(false)
    setAnchorEl(null);
    settingFilters(
      prevData.client,
      branch,
      prevData.startFilterDate,
      prevData.endFilterDate
    );
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  const handleChangeClient = (e) => {
    setClient(e.target.value);
    GetDataBranches(e.target.value)
  };

  const handleChangeBranch = (e) => {
    setBranch(e.target.value);
  };

  const handleFilter = () => {
    if (endFilterDate < startFilterDate) {
      setErrors({ error: true });
    } else {
      handleCloseFilterModal();
      setErrors({
        error: false,
        errorNullStart: false,
        errorNullEnd: false,
      });
      RequestData();

      setPrevData({
        client: client,
        branch: branch,
        startFilterDate: startFilterDate,
        endFilterDate: endFilterDate,
      });
    }
  };

  const handleResetFilter = async () => {
    settingFilters("Todos", "Todas", firstDay, today);
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  //FILTERS SETTING
  //------------------------------------------------

  const settingFilters = (client, branch, firstDay, endDay) => {
    setClient(client);
    setBranch(branch);
    setStartFilterDate(firstDay);
    setEndFilterDate(endDay);
  };

  //------------------------------------------------

  const filterValues = {
    "Fecha inicio": getDayjsFormatted(startFilterDate),
    "Fecha fin": getDayjsFormatted(endFilterDate),
    Almacén: branch != "Todas" ? branch : undefined,
    Cliente: client != "Todos" ? client : undefined,
  };

  return (
    <>
      <div className="w-100 px-3 mb-3">
        <div
          className={`${
            phone ? "mt-1" : "mt-2"
          } d-flex justify-content-between`}
        >
          <DownloadButton
            client={client}
            htmlElement={htmlElement}
            fileName={"Reporte General Inventario"}
            excel={true}
            modal={false}
            info={info}
            sheetName={`Gráficas Inventario`}
          />
          <div>
            <Tooltip title="Filtrar" arrow placement="left">
              <IconButton onClick={handleClick}>
                <Badge
                  variant="dot"
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "lightgreen",
                      backgroundColor: theme.palette.primary.main,
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                    },
                  }}
                  invisible={invisible}
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                >
                  <img src={FilterIcon} width={"40px"} alt="Filtro" />
                </Badge>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 8, lg: 8 }}
            ref={htmlElement}
          >
            <ReportHeader
              title="Reporte General de Ventas"
              filters={filterValues}
            />
            <WarehousesInventoryGraph
              client={client}
              data={dataWarehouses}
              isLoading={loaderWarehouses}
              total={totalWarehouses}
            />
            <MoreExistancesGraph
              client={client}
              data={dataMoreInventory}
              dataFull={dataMoreFullInventory}
              isLoading={loaderMore}
            />
            <LessExistancesGraph
              client={client}
              data={dataLessInventory}
              dataFull={dataLessFullInventory}
              isLoading={loaderLess}
            />
            <EntriesGraph
              client={client}
              type={1}
              data={dataEntries}
              isLoading={loaderEntries}
              total={totalEntries}
            />
            <div className="d-none" id="client-data-lol"></div>
          </Grid>
        </div>
      </div>
      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseFilterModal}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ModalFilters
            errors={errors}
            setErrors={setErrors}
            handleClose={handleCloseModal}
            dates={{
              setEnd: setEndFilterDate,
              end: endFilterDate,
              setStart: setStartFilterDate,
              start: startFilterDate,
            }}
            fields={[
              {
                array: clients,
                prop: "razon_social",
                type: "Cliente",
                value: client,
                handleOnChange: handleChangeClient,
              },
              {
                array: branches,
                prop: "sucursal",
                type: "Sucursal",
                value: branch,
                handleOnChange: handleChangeBranch,
              },
            ]}
            onClickFilter={handleFilter}
            onClickResetFilter={handleResetFilter}
          />
        </Popover>
      </>
    </>
  );
};
