import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { useMediaQuery, IconButton, Box } from "@mui/material";

import { Close } from "@mui/icons-material";
import { CancelEntryButton } from "../Buttons/CancelEntryButton";
import { CancelButton } from "../Buttons/CancelButton";
import { AcceptButton } from "../Buttons/AcceptButton";

export const ModalAction = ({ handleClose, ejectAction, object, text }) => {
  const phone = useMediaQuery("(max-width:480px)");

  const handleAction = () => {
    ejectAction();
    handleClose();
  };

  return (
    <div className="p-3">
      <div className="w-100">
        <p className="ds-blue-font small-font m-0 text-center">{`${text}:`}</p>
        <p className="font-weight-bold small-font text-center">{object}</p>
        <p className="ds-blue-font small-font m-0 text-center">
          ¿Deseas continuar?
        </p>

        <div
          className={`d-flex mt-3 small-font ${
            phone ? "flex-column" : "justify-content-center"
          }`}
        >
          <CancelButton
            text={"No"}
            onClick={handleClose}
            width={"20%"}
            fontSize={"inherit"}
          />
          <AcceptButton
            text={"Sí"}
            onClick={handleAction}
            width={"20%"}
            fontSize={"inherit"}
          />
        </div>
      </div>
    </div>
  );
};
