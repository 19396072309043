import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Button, Typography, Grid } from '@mui/material';
import { Close } from '@mui/icons-material';


const useStyles = makeStyles((theme) => ({
  ProductPaper: {
    position: 'absolute',
    width: '80%', // Cambiar el ancho para ocupar la mayoría del espacio en dispositivos móviles
    maxWidth: 600, // Establecer un ancho máximo para mantener la legibilidad
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  ProductImage: {
    maxWidth: '100%',
    height: 'auto',
    margin: 'auto',
  },
}));

export const ProductModal = ({ open, handleClose, product }) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.ProductPaper}>
        <Close onClick={handleClose} style={{ cursor: 'pointer', position: 'absolute', top: 10, right: 10 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" gutterBottom style={{ color: '#004876', fontWeight: 'bold', textAlign: 'center' }}>
              {product.name}
            </Typography>
            <Typography variant="body2" gutterBottom><b>Descripción:</b> {product.descripcion ? product.descripcion : "No disponible"}</Typography>
            <Typography variant="body2" gutterBottom><b>SKU:</b> {product.sku ? product.sku : "No disponible"}</Typography>
            <Typography variant="body2" gutterBottom><b>Cliente:</b> {product.cliente ? product.cliente : "No disponible"}</Typography>
            <Typography variant="body2" gutterBottom><b>Categoría Uber:</b> {product.clase_articlo ? product.clase_articlo : "No disponible"}</Typography>
            <Typography variant="body2" gutterBottom><b>Categoría:</b> {product.categoria ? product.categoria : "No disponible"}</Typography>
            <Typography variant="body2" gutterBottom><b>Subcategoría:</b> {product.subcategory ? product.subcategory : "No disponible"}</Typography>
            <Typography variant="body2" gutterBottom><b>Precio por unidad:</b> {product.precio_por_unidad ? "$"+product.precio_por_unidad : "No disponible"}</Typography>
            <Typography variant="body2" gutterBottom><b>Costo:</b> {product.standard_price ? "$"+product.standard_price : "No disponible"}</Typography>
            <Typography variant="body2" gutterBottom><b>Peso:</b> {product.peso ? product.peso : "No disponible"} kg</Typography>
            <Typography variant="body2" gutterBottom>
              <b>Dimensiones:</b> {product.altura && product.ancho && product.profundidad ? `${product.altura}x${product.ancho}x${product.profundidad} cm` : "No disponible"}
            </Typography>            
            <Typography variant="body2" gutterBottom><b>Código de barras:</b> {product.codigo_barras ? product.codigo_barras : "No disponible"}</Typography>
            <Typography variant="body2" gutterBottom><b>Fecha de creación:</b> {product.fecha_creacion ? product.codigo_barras : "No disponible"}</Typography>
            <Typography variant="body2" gutterBottom><b>Activo:</b> {product.activo ? 'Sí' : 'No'}</Typography>
            <Typography variant="body2" gutterBottom><b>¿Puede servirse solo?:</b> {product.can_serve_alone ? 'Sí' : 'No'}</Typography>
            <Typography variant="body2" gutterBottom><b>¿Es alcohólico?:</b> {product.is_alcoholic ? 'Sí' : 'No'}</Typography>
            <Typography variant="body2" gutterBottom><b>¿Es vegetariano?:</b> {product.is_vegetarian ? 'Sí' : 'No'}</Typography>
            <Typography variant="body2" gutterBottom><b>Perecedero:</b> {product.perishable ? 'Sí' : 'No'}</Typography>
            <Typography variant="body2" gutterBottom><b>Tiempo de uso:</b> {product.use_time ? `${product.use_time} días` : 'No disponible'}</Typography>
            <Typography variant="body2" gutterBottom><b>Tiempo de expiración:</b> {product.expiration_time ? `${product.expiration_time} días` : 'No disponible'}</Typography>
            <Typography variant="body2" gutterBottom><b>Tiempo de remoción:</b> {product.removal_time ? `${product.removal_time} días` : 'No disponible'}</Typography>
            <Typography variant="body2" gutterBottom><b>Días de alerta:</b> {product.alert_time ? `${product.alert_time} días` : 'No disponible'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
            {product.imagen && <img src={product.imagen} alt={product.name} className={classes.ProductImage} />}
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};
