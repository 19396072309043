import React from "react";

import {
  useMediaQuery,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import { TransferForm } from "../../components/Forms/TransferForm";
// import { ClientForm } from "../../../components/Forms/ClientForm";

export const InventoryTransfers = () => {

  const phone = useMediaQuery("(max-width:480px)");

  return (
    <>
      <div className="w-100 px-3">
        
        <div className="mt-3 mb-3 w-100 r-15 p-2 bg-white">
          <p className={`ds-blue-font m-0 ${phone ? "h6" : "h5"}`}>
            Transferencias de Productos
          </p>
          <p className="m-0 text-secondary">* Campos obligatorios</p>
        </div>

        <div className="mt-2  w-100 d-flex justify-content-between  r-15 d-flex flex-column">

          <TransferForm />
        </div>
      </div>
    </>
  );
};