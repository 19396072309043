import { Breadcrumbs, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Link as RouterLink, Route, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { NavigateNext } from "@mui/icons-material";
import { theme } from "../../utils/theme";

const Background = styled("div")({
  boxShadow: "none",
  textTransform: "none",
  // borderRadius: 5,
  // backgroundColor: "white",
  color: "#1f4468cc",
  "&:hover": {
    // fontSize: 18,
    // backgroundColor: "#e9eaef",
    boxShadow: "none",
    // fontWeight: "bold",
  },

  "&:focus": {
    //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

const StyledLink = styled(RouterLink)({
  color: "#1f4468cc",
  "&:hover": {
    color: theme.palette.secondary.main,
    // fontWeight: "bold",
  },
});

export const BreadcrumbsMenu = ({ sections, principal, rol }) => {
  const { pathname } = useLocation();
  const tablet = useMediaQuery("(max-width:800px)");
  const pathnames = pathname
    .split("/")
    .filter((x) => x)
    .slice(1);
  const principalPath = `/${rol}/${principal}/`;

  return (
    <Breadcrumbs
      separator={<NavigateNext fontSize="small" sx={{ color: "#1f446870" }} />}
      maxItems={tablet ? 2 : 5}
      aria-label="breadcrumb"
    >
      {/* {
        pathname != principalPath &&
        <StyledLink color="inherit" to={principalPath}>
          {sections.find((x) => `/${x.link}/` == principalPath).name}
        </StyledLink>
      } */}

      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${rol}/${pathnames.slice(0, index + 1).join("/")}/`;

        return last ? (
          <Background key={index}>
            <Typography key={to}>
              {pathnames.length == 1
                ? ""
                : sections.find((x) => `/${x.link}/` == to).name}
            </Typography>
          </Background>
        ) : (
          <Background key={index}>
            {" "}
            {/*ANTERIOR */}
            <StyledLink color="inherit" to={to} key={to}>
              {pathnames.length == 1
                ? ""
                : sections.find((x) => `/${x.link}/` == to).name}
            </StyledLink>
          </Background>
        );
      })}
    </Breadcrumbs>
  );
};
