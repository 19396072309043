import React, { useRef } from "react";

import { Typography, Box, IconButton, Tooltip } from "@mui/material";
import { Chart } from "react-google-charts";
import { Close, ZoomInMap, ZoomOut } from "@mui/icons-material";
import { DownloadButton } from "../Buttons/DownloadButton";
import { ReportHeader } from "../../pages/Reports/ReportHeader";

const style = {
  // display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  borderRadius: 2,
};

export const ModalGraphs = ({
  onClose,
  type,
  data,
  options,
  fileName,
  client,
  filterInput,
  product,
  filters,
}) => {
  const htmlElement = useRef(null);

  return (
    <Box sx={style}>
      <div ref={htmlElement} className="h-100">
        <div className="d-flex flex-row justify-content-between align-items-start">
          <ReportHeader filters={filters} title={fileName} />
          <DownloadButton
            text={""}
            htmlElement={htmlElement}
            fileName={`${fileName} ${product}`}
            excel={false}
            modal={true}
            client={client}
          />
          {filterInput}

          <Tooltip title="Minimizar" arrow placement="top">
            <IconButton onClick={onClose} data-html2canvas-ignore="true">
              <ZoomOut fontSize="large" />
            </IconButton>
          </Tooltip>
        </div>
        {/* NOTA: Debe estar amarrado a una etiqueta HTML para poder crear el canvas, un div cambia 
        la proporcion de la gráfica, por eso puse esta etiqueta link */}
        {/* <a ref={htmlElement}>  */}
        <Chart
          chartType={type}
          data={data}
          options={{
            ...options,
            colors: options.colors
              ? options.colors
              : [
                  "#19B1FF",
                  "#ffc500",
                  "#8CC044",
                  "#FF940D",
                  "#76559F",
                  "#FF9DD0",
                  "#FF5C51",
                ],
          }}
          width={"100%"}
          height={"85%"}
          chartLanguage="es"
        />
      </div>
    </Box>
  );
};
