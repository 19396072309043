export const roles = {
    CLIENT: 2,
    ADMIN: 3
}

export const channelsEnum = {
    ALL_TEXT: "Todos",
    ALL_INDEX: 0
}

export const graphsSectionEnum = {
    DASHBOARD: 1,
    SALES: 2,
    INVENTORY: 3,
}