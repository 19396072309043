import {
  Close,
  Inventory2,
  LocalShipping,
  NotificationImportantOutlined,
  ProductionQuantityLimits,
} from "@mui/icons-material";
import {
  Badge,
  Divider,
  IconButton,
  Modal,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModalDetailsNotifications } from "./ModalDeatilsNotifications";
import { theme } from "../../utils/theme";

export const ModalNotification = ({
  handleClose,
  notifications,
  GetNotifications,
  loaderNotifications,
  setIsUpdate,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState({});
  const arrRows = Array(3).fill(true);

  const handleClick = (data) => {
    setOpen(true);
    setNotification(data);
  };

  // const notifications = [

  // ]

  return (
    <>
      <div className="px-3 d-flex align-items-center justify-content-between pt-2 pb-2">
        <Typography sx={{ color: theme.palette.secondary.main, fontSize: 20 }}>
          Notificaciones
        </Typography>
        <div
          className="cursor-pointer d-flex align-items-center"
          onClick={handleClose}
        >
          <Close sx={{ color: theme.palette.secondary.main }} />
        </div>
      </div>

      <Divider />

      <div className="p-3">
        {loaderNotifications ? (
          arrRows.map((el, i) => (
            <div className="d-flex mb-3 align-items-center" key={i}>
              <Skeleton variant="rounded" width={40} height={40} />
              <Skeleton height={25} width={150} className="ml-2" />
            </div>
          ))
        ) : (
          <div className=" d-flex flex-column justify-content-center align-items-start cursor-pointer mb-3 ">
            {notifications.e_sales.length != 0 &&
              notifications.e_sales.map((el, i) => (
                <div
                  key={i}
                  className="notification d-flex align-items-center m-2"
                  onClick={() => {
                    handleClick(el);
                  }}
                >
                  <Badge
                    variant="dot"
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: "#fb0808",
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                      },
                    }}
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    invisible={el.readed}
                  >
                    <IconButton
                      className="icon-animation"
                      sx={{
                        marginRight: 1,
                        backgroundColor: "#f0f2f5",
                        borderRadius: 1,
                        "&:hover": { backgroundColor: "#e1ebfa" },
                      }}
                    >
                      <ProductionQuantityLimits
                        sx={{ fontSize: 17, color: "#ffc500" }}
                      />
                    </IconButton>
                  </Badge>

                  <div>
                    <Typography sx={{ fontSize: 10 }} className="ds-blue-font">
                      {el.dateCreation.split(" ").reverse().join(" ")}{" "}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15, "&:hover": { fontWeight: "bold" } }}
                      className="ds-blue-font"
                    >
                      <strong>Venta</strong>
                    </Typography>
                    <Typography
                      sx={{ fontSize: 10, "&:hover": { fontWeight: "bold" } }}
                      className="ds-blue-font"
                    >
                      {el.shipmentCode}
                    </Typography>
                  </div>
                </div>
              ))}
            <Divider />
            {notifications.inventory_receipt.length != 0 &&
              notifications.inventory_receipt.map((el, i) => (
                <div
                  key={i}
                  className="notification d-flex align-items-center m-2"
                  onClick={() => {
                    handleClick(el);
                  }}
                >
                  <Badge
                    variant="dot"
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: "#fb0808",
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                      },
                    }}
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    invisible={el.readed}
                  >
                    <IconButton
                      className="icon-animation"
                      sx={{
                        marginRight: 1,
                        backgroundColor: "#f0f2f5",
                        borderRadius: 1,
                        "&:hover": { backgroundColor: "#e1ebfa" },
                      }}
                    >
                      <Inventory2 sx={{ fontSize: 17, color: "#08b6fb" }} />
                    </IconButton>
                  </Badge>

                  <div>
                    <Typography sx={{ fontSize: 10 }} className="ds-blue-font">
                      {el.dateCreation.split(" ").reverse().join(" ")}{" "}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15, "&:hover": { fontWeight: "bold" } }}
                      className="ds-blue-font"
                    >
                      <strong>Entrada de mercancía</strong>
                    </Typography>
                    <Typography
                      sx={{ fontSize: 10, "&:hover": { fontWeight: "bold" } }}
                      className="ds-blue-font"
                    >
                      {el.sku}
                    </Typography>
                  </div>
                </div>
              ))}
            <Divider />
            {notifications.inventory_status.length != 0 &&
              notifications.inventory_status.map((el, i) => (
                <div
                  key={i}
                  className="notification d-flex align-items-center m-2"
                  onClick={() => {
                    handleClick(el);
                  }}
                >
                  <Badge
                    variant="dot"
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: "#fb0808",
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                      },
                    }}
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    invisible={el.readed}
                  >
                    <IconButton
                      className="icon-animation"
                      sx={{
                        marginRight: 1,
                        backgroundColor: "#f0f2f5",
                        borderRadius: 1,
                        "&:hover": { backgroundColor: "#e1ebfa" },
                      }}
                    >
                      <Inventory2 sx={{ fontSize: 17, color: "#63e243" }} />
                    </IconButton>
                  </Badge>

                  <div>
                    <Typography sx={{ fontSize: 10 }} className="ds-blue-font">
                      {el.dateCreation.split(" ").reverse().join(" ")}{" "}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15, "&:hover": { fontWeight: "bold" } }}
                      className="ds-blue-font"
                    >
                      <strong>Inventario</strong>
                    </Typography>
                    <Typography
                      sx={{ fontSize: 10, "&:hover": { fontWeight: "bold" } }}
                      className="ds-blue-font"
                    >
                      {el.sku}
                    </Typography>
                  </div>
                </div>
              ))}
            <Divider />
            {notifications.inventory_transfer.length != 0 &&
              notifications.inventory_transfer.map((el, i) => (
                <div
                  key={i}
                  className="notification d-flex align-items-center m-2"
                  onClick={() => {
                    handleClick(el);
                  }}
                >
                  <Badge
                    variant="dot"
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: "#fb0808",
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                      },
                    }}
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    invisible={el.readed}
                  >
                    <IconButton
                      className="icon-animation"
                      sx={{
                        marginRight: 1,
                        backgroundColor: "#f0f2f5",
                        borderRadius: 1,
                        "&:hover": { backgroundColor: "#e1ebfa" },
                      }}
                    >
                      <LocalShipping sx={{ fontSize: 17, color: "#e2044e" }} />
                    </IconButton>
                  </Badge>

                  <div>
                    <Typography sx={{ fontSize: 10 }} className="ds-blue-font">
                      {el.dateCreation.split(" ").reverse().join(" ")}{" "}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15, "&:hover": { fontWeight: "bold" } }}
                      className="ds-blue-font"
                    >
                      {" "}
                      <strong>Transferencia</strong>{" "}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 10, "&:hover": { fontWeight: "bold" } }}
                      className="ds-blue-font"
                    >
                      {el.sku}
                    </Typography>
                  </div>
                </div>
              ))}

            <div className="w-100 text-center">
              <Typography
                className="cursor-pointer ds-blue-font"
                onClick={() => {
                  navigate("/cliente/notificaciones/");
                  handleClose();
                }}
              >
                Ver más
              </Typography>
            </div>
          </div>
        )}
      </div>

      <Modal open={open} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalDetailsNotifications
            setOpen={setOpen}
            notification={notification}
            GetNotifications={GetNotifications}
            handleCloseModal={handleClose}
            setIsUpdate={setIsUpdate}
          />
        </div>
      </Modal>
    </>
  );
};
