import React, { useState } from "react";

import {
  Grid,
  Paper,
  IconButton,
  Tooltip,
  Popover,
  Modal,
  Backdrop,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Delete, Info } from "@mui/icons-material";

import { EditTwoTone } from "@mui/icons-material";

import { Navigate, useNavigate } from "react-router-dom";

import { roles } from "../../helpers/enums";
import { ModalDeletUser } from "../Modal/ModalDeleteUser";
import { ModalDeleteSuccess } from "../Modal/ModalDeleteSuccess";
import { ModalError } from "../Modal/ModalError";
import LoaderDS from "../../assets/LoaderDS.svg";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: 10,
  minHeight: 220,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const CardUser = ({ info, type, RequestData }) => {
  const phone = useMediaQuery("(max-width:480px)");

  const [openBD, setOpenBD] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openError, setOpenError] = useState(false);
  const [errorField, setErrorField] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [data, setData] = useState();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => setAnchorEl(null);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/cliente/usuarios/editar/", {
      state: {
        id: info.id ? info.id : "",
        name: info.nombre ? info.nombre : "",
        lastName: info.apellidos ? info.apellidos : "",
        email: info.email ? info.email : "",
        personalNumber: info.telefono ? info.telefono : "",
        rol: info.rol ? info.rol : "",
        position: info.employee_position ? info.employee_position : "",
      },
    });
  };

  const handleDelete = (event) => {
    setData(info);
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Grid item xs={6} sm={3} md={2.4} lg={2.4} xl={2.4}>
        <Item className="mb-3 hover-card  w-100">
          <div className="d-flex justify-content-between w-100">
            <Tooltip title={"Eliminar"} arrow placement="top">
              <div className={`hover-icon`}>
                <IconButton onClick={handleDelete}>
                  <Delete />
                </IconButton>
              </div>
            </Tooltip>

            <Tooltip title={"Editar"} arrow placement="top">
              <div className={`hover-icon`}>
                <IconButton onClick={handleClick}>
                  <EditTwoTone />
                </IconButton>
              </div>
            </Tooltip>
          </div>

          <div className="w-100">
            <div className="w-100">
              Nombre: {info.nombre} {info.apellidos}
            </div>
            <Tooltip
              title={info.email.length > 15 ? info.email : ""}
              placement="top"
              arrow
            >
              <div>
                Email: {info.email.substring(0, 15)}
                {info.email.length > 15 && "..."}
              </div>
            </Tooltip>
            <div className="w-100">Teléfono: {info.telefono}</div>
            <div className="w-100">
              Rol: {info.rol == 4 ? "Inventario" : "Ventas"}
            </div>
            <div className="w-100">Puesto: {info.employee_position}</div>
            <div className="w-100">Fecha: {info.fecha_registro}</div>
          </div>
        </Item>
      </Grid>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        // onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: !phone && 300 },
        }}
      >
        <ModalDeletUser
          info={data}
          handleClose={handleClose}
          setOpenBD={setOpenBD}
          setOpenError={setOpenError}
          setErrorField={setErrorField}
          RequestData={RequestData}
          setOpenSuccess={setOpenSuccess}
        />
      </Popover>

      <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalDeleteSuccess text={"Usuario"} />
        </div>
      </Modal>

      <Modal open={openError} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalError
            text={`La solicitud no pudo ser completada.`}
            handleClose={() => {
              setOpenError(false);
            }}
            error={errorField}
          />
        </div>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
