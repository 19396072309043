import React, { useState } from "react";
import { Button, ButtonBase } from "@mui/material";
import { Download } from "@mui/icons-material";
import { ExcelDownloadOneSheet } from "./ExcelDownloadOneSheet";
import { ExcelDownloadMultipleSheet } from "./ExcelDownloadMultipleSheet";

export const DownloadButtonExcel = ({
  text,
  disabled,
  getData,
  data,
  headers,
  sheetName,
  multiple = false,
  client,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = async () => {
    
    setIsLoading(true);
    try {
      // Aquí obtienes los datos
      await getData();
      console.log("Data del excel",data)
      // Después de obtener los datos, descarga el archivo de Excel
      if (multiple) {
        ExcelDownloadMultipleSheet(sheetName, data, client || "");
      } else {
        ExcelDownloadOneSheet(data.length === 0 ? await getData() : data, headers, sheetName, client || "");
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ButtonBase
      className="toolbar-button"
      disabled={disabled || isLoading}
      onClick={handleClick}
    >
      <Download className="mr-1" fontSize="small" />
      <p className="m-0 no-wrap">{text}</p>
    </ButtonBase>
  );
};
