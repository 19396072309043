import React, { useState, useEffect } from "react";

import { Grid } from "@mui/material";

import { CardGraph } from "../Card/CardGraph";

const options = [
  {
    legend: {
      position: "left",
      alignment: "center",
    },
    pieSliceText: "percent",
    tooltip: { trigger: "selection" },
    is3D: true,
    pieStartAngle: 90,
    chartArea: { width: "80%", height: "80%" },

    hAxis: {
      // title: "Cantidad",
      minValue: 0,
    },
    vAxis: {
      // title: "Productos",
    },
  },
];

const optionsColumn = {
  chartArea: { width: "80%", height: "50%" },
  legend: "none",
  hAxis: {},
};

const modalOption = {
  titleTextStyle: {
    fontSize: 20,
  },
};

const chartType = ["PieChart", "ColumnChart"];

export const EntriesGraph = ({
  type,
  data,
  isLoading,
  total,
  client,
  filters,
}) => {
  return (
    <>
      <Grid item xs={4}>
        <CardGraph
          title={"Envío de mercancía"}
          type={"ColumnChart"}
          data={data[type]}
          options={{
            ...(type == 0 ? options[type] : optionsColumn),
            title: `Total: ${total}`,
          }}
          modalOption={modalOption}
          isLoading={isLoading}
          client={client}
          filters={filters}
        />
      </Grid>
    </>
  );
};
