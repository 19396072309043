import React, { useMemo, useState } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  tableCellClasses,
  Modal,
  Tooltip,
  IconButton,
  Popover,
  useMediaQuery,
  Backdrop,
} from "@mui/material";

import { roles } from "../../helpers/enums";

import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";

import { DeleteForever, Visibility } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";

import { ModalSuccess } from "../Modal/ModalSuccess";
import { ModalError } from "../Modal/ModalError";

import LoaderDS from "../../assets/LoaderDS.svg";
import { SaleB2B } from "../../classes/SaleB2B";
import { ModalAction } from "../Modal/ModalAction";
import { SalesB2BAPI } from "../../api/SalesB2B";
import { ListPagination } from "../Pagination/ListPagination";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    textAlign: "center",
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: "center",
    fontSize: 14,
  },
}));

export function TableSalesB2B({
  search,
  data,
  setPage,
  page,
  total,
  requestData,
  headers,
  count,
}) {
  /*************** STYLE STATES *****************/
  const { height, width } = useViewPortDimensions();
  const phone = useMediaQuery("(max-width:480px)");

  const navigate = useNavigate();
  const rol = localStorage.getItem("rol");

  /*************** MODAL STATES *****************/

  const [openCancel, setOpenCancel] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [sale, setSale] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const idPopover = openCancel ? "simple-popover" : undefined;

  const handleClick = (event, action, info) => {
    setSale(info);
    switch (action) {
      case "details":
        if (rol != roles.ADMIN) {
          navigate("/cliente/ventas/B2B/detalle/", {
            state: info.id,
          });
        } else {
          navigate("/administrador/ventas/B2B/detalle/", {
            state: info.id,
          });
        }
        break;
      case "cancel":
        setAnchorEl(event.currentTarget);
        setOpenCancel(true);
        break;
      case "products":
        navigate("/cliente/ventas/B2B/productos/", {
          state: info,
        });
    }
  };

  const handleCloseModal = (modal) => {
    switch (modal) {
      case "success":
        setOpenSuccess(false);
        requestData();
        break;
      case "cancel":
        setOpenCancel(false);
        break;
      case "error":
        setOpenError(false);
        break;
    }
  };

  /*************** CANCEL SALE *****************/
  const api = useMemo(() => new SalesB2BAPI(), []);

  const cancelSale = async (orderCode) => {
    setOpenCancel(false);
    setIsLoading(true);
    const response = await api.cancelSaleEP({ order_code: orderCode });
    if (response.status == 200) {
      setOpenSuccess(true);
      setIsLoading(false);
    } else {
      console.log(response);
      setOpenError(true);
      setErrorMsg(response.response.data.msg);
      setIsLoading(false);
    }
  };

  const tRow = (row, indexR) => {
    const sale = new SaleB2B(row);
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={indexR}>
        <StyledTableCell>
          <div className="d-flex justify-content-center">
            <Tooltip title={"Ver detalles"} arrow placement="top">
              <IconButton
                sx={{
                  "&:hover": {
                    color: "blue",
                  },
                  padding: "0 1px 0 0",
                }}
                onClick={(e) => handleClick(e, "details", sale)}
                disabled={false}
                className={`hover-icon`}
              >
                <Visibility />
              </IconButton>
            </Tooltip>
            {rol != roles.ADMIN && (
              <Tooltip title={"Cancelar"} arrow placement="top">
                <IconButton
                  sx={{
                    "&:hover": {
                      color: "red",
                    },
                    padding: "0 1px 0 0",
                  }}
                  onClick={(e) => handleClick(e, "cancel", sale)}
                  disabled={sale.statusId != 3 ? false : true}
                  className={`ml-2 hover-icon`}
                >
                  <DeleteForever />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </StyledTableCell>
        {rol == roles.ADMIN && (
          <StyledTableCell sx={{ minWidth: 100 }}>
            {sale.client}
          </StyledTableCell>
        )}
        <StyledTableCell>{sale.orderCode}</StyledTableCell>
        <StyledTableCell>{sale.reference}</StyledTableCell>
        <StyledTableCell sx={{ minWidth: 120 }}>
          {sale.creationDate}
        </StyledTableCell>
        <StyledTableCell>{sale.status}</StyledTableCell>
        <StyledTableCell>{sale.business}</StyledTableCell>
        {/*<StyledTableCell sx={{ minWidth: 135 }}>{sale.branch}</StyledTableCell>*/}
        <StyledTableCell>{sale.businessLocation}</StyledTableCell>
        <StyledTableCell sx={{ minWidth: 120 }}>
          $ {sale.amount}
        </StyledTableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: height - (74 + 90 + 60) }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-label="sticky table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {headers.map((header, index) => (
                    <StyledTableCell key={index}>
                      {header.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => tRow(row, index))}
              </TableBody>
            </Table>
            {data.length == 0 && search != "" ? (
              <div className="d-flex justify-content-center mt-2">
                No se encontraron resultados para "{search}"
              </div>
            ) : (
              data.length == 0 &&
              search == "" && (
                <div className="d-flex justify-content-center mt-2">
                  No tienes ventas.
                </div>
              )
            )}
          </TableContainer>
          <ListPagination
            setPage={setPage}
            page={page}
            total={total}
            count={count}
          />
        </Paper>
      </Box>
      <Popover
        id={idPopover}
        open={openCancel}
        anchorEl={anchorEl}
        onClose={() => handleCloseModal("cancel")}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: !phone && 300 },
        }}
      >
        <ModalAction
          text={"Estás a punto de cancelar la venta"}
          object={sale.orderCode}
          handleClose={() => handleCloseModal("cancel")}
          ejectAction={() => {
            cancelSale(sale.orderCode);
          }}
        />
      </Popover>
      <Modal open={openSuccess}>
        <div>
          <ModalSuccess
            text={"La venta fue cancelada con éxito"}
            handleClose={() => handleCloseModal("success")}
          />
        </div>
      </Modal>
      <Modal open={openError}>
        <div>
          <ModalError
            text={"No se pudo cancelar la venta"}
            handleClose={() => handleCloseModal("error")}
            error={errorMsg}
          />
        </div>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
}
