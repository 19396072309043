import React, { useEffect, useMemo, useState } from "react";

import { Grid, Popover, useMediaQuery } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { Search } from "../../components/Search";
import { ToggleView } from "../../components/ToggleView";
import { TableInventory } from "../../components/Tables/TableInventory";

import { AddButton } from "../../components/AddButton";
import { ListViewInventory } from "../../components/ListViewInventory";

import { InventoryAPI } from "../../api/Inventory";
import { SkeletonTableInventory } from "../../components/Skeleton/SkeletonTableInventory";
import { SkeletonCardProduct } from "../../components/Skeleton/SkeletonCardProduct";

import { DownloadButtonExcel } from "../../components/Excel/DownloadButtonExcel";
import { inventoryHeaders } from "../../components/headers";
import { getDayjsFormatted } from "../../helpers/getDateCleaned";
import { ProductsAPI } from "../../api/Products";

import { roles } from "../../helpers/enums";
import { ModalFilters } from "../../components/Modal/ModalFilters";
import { WarehouseAPI } from "../../api/Warehouse";

export const InventoryExistance = () => {
  const [view, setView] = useState(true);
  const phone = useMediaQuery("(max-width:480px)");
  const navigate = useNavigate();

  //DATA STATES
  //------------------------------------------------

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0); /*Estado p/paginacion*/
  const [total, setTotal] = useState(); /*Total de paginas enviado desde api*/
  const [count, setCount] = useState(); /*Paginacion listas */
  const [search, setSearch] = useState("");
  const [ready, setReady] = useState(false);

  //------------------------------------------------
  //APIS
  //------------------------------------------------
  const inventoryApi = useMemo(() => new InventoryAPI(), []);
  const productAPI = useMemo(() => new ProductsAPI(), []);
  const warehouseAPI = useMemo(() => new WarehouseAPI(), []);

  //------------------------------------------------
  //ERRORES
  //------------------------------------------------

  const [errors, setErrors] = useState({
    error: false,
    errorNullStart: false,
    errorNullEnd: false,
  });

  //------------------------------------------------

  //MODAL STATES
  //------------------------------------------------

  const [openFilter, setOpenFilter] = useState(false);
  const [invisible, setInvisible] = useState(true);

  //------------------------------------------------

  //LOADERS STATES
  //------------------------------------------------

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  //------------------------------------------------

  //FILTERS STATES
  //------------------------------------------------

  const [category, setCategory] = useState("Todas");
  const [categories, setCategories] = useState([]);

  const [branch, setBranch] = useState("Todas");
  const [branches, setBranches] = useState([]);

  const today = new Date();
  const [startFilterDate, setStartFilterDate] = useState(null);
  const [endFilterDate, setEndFilterDate] = useState(null);

  const [prevData, setPrevData] = useState({
    startFilterDate: null,
    endFilterDate: null,
    category: "Todas",
    branch: "Todas",
  });

  //------------------------------------------------

  //EFFECTS
  //------------------------------------------------

  useEffect(() => {
    setPage(0);
    setSearch("");
    setReady(true);
    requestFilterData();
  }, []);

  useEffect(() => {
    if (ready) {
      RequestData();
    }
  }, [ready]);

  //------------------------------------------------

  //EXCEL STATES AND DATA
  //------------------------------------------------

  const [dataExcel, setDataExcel] = useState([]);

  //------------------------------------------------

  //DATA REQUEST
  //------------------------------------------------

  const RequestData = () => {
    getInventoryList();
    setReady(false);
  };

  const requestFilterData = () => {
    GetCategories();
    GetDataBranches();
  };

  const GetCategories = async () => {
    let data = new FormData();

    const response = await productAPI.categoriesListEP(data);
    if (response.status == 200) {
      setCategories([{ id: 0, categoria: "Todas" }, ...response.data]);
    } else {
      console.log(response);
    }
  };

  const GetDataBranches = async () => {
    let data = new FormData();

    const response = await warehouseAPI.getBranchesList(data);
    if (response.status == 200) {
      setBranches([{ id: 0, sucursal: "Todas" }, ...response.data.branches]);
    } else {
      console.log(response);
    }
  };

  const GetInventoryExcelList = async () => {
    setIsLoadingExcel(true);
    let data = new FormData();

    if (endFilterDate != null) {
      data.append("endDate", getDayjsFormatted(endFilterDate));
    }

    if (startFilterDate != null) {
      data.append("startDate", getDayjsFormatted(startFilterDate));
    }

    if (category != "Todas") {
      const categoryToSend = categories.find((el) => el.categoria == category);
      data.append("category", categoryToSend.id);
    }

    if (branch != "Todas") {
      const branchToSend = branches.find((el) => el.sucursal == branch);
      data.append("branch", branchToSend.id);
    }
    if (search != "") {
      data.append("search", search);
    }

    const response = await inventoryApi.inventoryExcelEP(data);
    if (response.status == 200) {
      const auxExcel = response.data.inventory.map((el) => ({
        categoria: el.categoria,
        codigo_barras: el.codigo_barras,
        descripcion: el.descripcion,
        disponible: el.disponible != null ? el.disponible.toString() : "0",
        merma: el.decrease != null ? el.decrease.toString() : "0",
        solicitado: el.solicitado != null ? el.solicitado.toString() : "0",
        stock: el.stock != null ? el.stock.toString() : "0",
        comprometido:
          el.comprometido != null ? el.comprometido.toString() : "0",
        fecha_creacion: el.fecha_creacion,
        sku: el.sku,
        sucursal: el.sucursal,
      }));
      setDataExcel(auxExcel);
      return auxExcel;
      setIsLoadingExcel(false);
    } else {
      console.log(response);
    }
  };

  const getInventoryList = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("page", page + 1); /*Donde se va a posicionar la página*/

    if (endFilterDate != null) {
      data.append("endDate", getDayjsFormatted(endFilterDate));
    }
    if (startFilterDate != null) {
      data.append("startDate", getDayjsFormatted(startFilterDate));
    }
    if (category != "Todas") {
      const categoryToSend = categories.find((el) => el.categoria == category);
      data.append("category", categoryToSend.id);
    }
    if (branch != "Todas") {
      const branchToSend = branches.find((el) => el.sucursal == branch);
      data.append("branch", branchToSend.id);
    }
    if (search != "") {
      data.append("search", search);
    }

    const response = await inventoryApi.inventoryListEP(data);
    if (response.status == 200) {
      setData(response.data.inventory);
      setTotal(response.data.total);
      setCount(Math.ceil(response.data.total / 10));
      setIsLoading(false);
      if (
        category != "Todas" ||
        branch != "Todas" ||
        (startFilterDate != null && endFilterDate != null)
      ) {
        setInvisible(false);
      } else {
        setInvisible(true);
      }
    } else {
      console.log(response);
    }
  };

  //------------------------------------------------

  //HANDLERS
  //------------------------------------------------

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleCloseFilterModal = () => setAnchorEl(null);

  const handleCloseModal = () => {
    // setOpenFilter(false);
    setAnchorEl(null);
    settingFilters(
      prevData.category,
      prevData.branch,
      prevData.startFilterDate,
      prevData.endFilterDate
    );
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  const handleChangeCategory = (e) => {
    setCategory(e.target.value);
  };

  const handleChangeBranch = (e) => {
    setBranch(e.target.value);
  };

  const handleFilter = () => {
    if (startFilterDate != null || endFilterDate != null) {
      if (startFilterDate != null && endFilterDate == null) {
        setErrors({ errorNullEnd: true });
      } else if (endFilterDate != null && startFilterDate == null) {
        setErrors({ errorNullStart: true });
      } else if (endFilterDate < startFilterDate) {
        setErrors({ error: true });
      } else {
        handleCloseFilterModal();
        setErrors({
          error: false,
          errorNullStart: false,
          errorNullEnd: false,
        });
        setPage(0);
        setReady(true);
        setPrevData({
          category: category,
          branch: branch,
          startFilterDate: startFilterDate,
          endFilterDate: endFilterDate,
        });
      }
    } else {
      handleCloseFilterModal();
      setPage(0);
      setReady(true);
      setPrevData({
        category: category,
        branch: branch,
        startFilterDate: startFilterDate,
        endFilterDate: endFilterDate,
      });
    }
  };

  const handleResetFilter = async () => {
    settingFilters("Todas", "Todas", null, null);
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  //------------------------------------------------

  //FILTERS SETTING
  //------------------------------------------------

  const settingFilters = (category, branch, firstDay, endDay) => {
    setCategory(category);
    setBranch(branch);
    setStartFilterDate(firstDay);
    setEndFilterDate(endDay);
  };

  //------------------------------------------------

  return (
    <>
      <div className="w-100 px-3 mt-2">
        {phone ? (
          <div className="mt-2 w-100 p-1 r-15">
            <div className="mb-2">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                requestData={RequestData}
                setPage={setPage}
              />
            </div>

            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <AddButton text={""} link={"/cliente/inventario/busqueda"} />
                <DownloadButtonExcel
                  text={""}
                  disabled={data.length > 0 ? false : true}
                  data={dataExcel}
                  headers={inventoryHeaders}
                  sheetName={"Inventario"}
                  isLoading={isLoadingExcel}
                  getData={() => GetInventoryExcelList()}
                />
              </div>
              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        ) : (
          <div className="mt-2 w-100 d-flex justify-content-between p-1 r-15">
            <div className="d-flex">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                requestData={RequestData}
                setPage={setPage}
              />
              <div className="d-flex align-items-center ml-1">
                <DownloadButtonExcel
                  text={"Descargar"}
                  disabled={data.length > 0 ? false : true}
                  data={dataExcel}
                  headers={inventoryHeaders}
                  sheetName={"Inventario"}
                  isLoading={isLoadingExcel}
                  getData={() => GetInventoryExcelList()}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        )}

        {view ? (
          // Vista de tabla
          isLoading ? (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <SkeletonTableInventory
                num={3}
                type={roles.CLIENT}
                total={total}
                page={page}
                setPage={setPage}
              />
            </div>
          ) : (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <TableInventory
                search={search}
                data={data}
                type={roles.CLIENT}
                total={total}
                page={page}
                setPage={setPage}
                requestData={RequestData}
                count={count}
              />
            </div>
          )
        ) : isLoading ? (
          <div className="w-100 mt-3 r-15">
            <Grid
              container
              alignItems="center"
              direction="row"
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
              columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
            >
              <SkeletonCardProduct
                height={200}
                num={10}
                rows={7}
                icon={false}
                page={page}
                setPage={setPage}
                count={count}
                total={total}
              />
            </Grid>
          </div>
        ) : (
          <ListViewInventory
            search={search}
            data={data}
            type={roles.CLIENT}
            page={page}
            setPage={setPage}
            count={count}
            total={total}
            requestData={RequestData}
          />
        )}
      </div>

      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseFilterModal}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ModalFilters
            errors={errors}
            setErrors={setErrors}
            handleClose={handleCloseModal}
            dates={{
              setEnd: setEndFilterDate,
              end: endFilterDate,
              setStart: setStartFilterDate,
              start: startFilterDate,
            }}
            fields={[
              {
                array: categories,
                prop: "categoria",
                type: "Categoría",
                value: category,
                handleOnChange: handleChangeCategory,
              },
              {
                array: branches,
                prop: "sucursal",
                type: "Sucursal",
                value: branch,
                handleOnChange: handleChangeBranch,
              },
            ]}
            onClickFilter={handleFilter}
            onClickResetFilter={handleResetFilter}
          />
        </Popover>
        {/* </Modal> */}
      </>
    </>
  );
};
