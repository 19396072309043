import React, { useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Modal,
  useMediaQuery,
  MenuItem,
  Grid,
  Backdrop,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";

import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";

import { InputImagePreview } from "../Input/InputImagePreview";

import { ProductsAPI } from "../../api/Products";

import { ModalWarning } from "../Modal/ModalWarning";
import { ModalProductSuccess } from "../Modal/ModalProductSuccess";
import { ModalError } from "../Modal/ModalError";

import LoaderDS from "../../assets/LoaderDS.svg";
import NumberInputAdornments from "../inputNumber";

export const ProductForm = () => {
  useEffect(() => {
    GetCategories();
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  // APIS
  const productAPI = useMemo(() => new ProductsAPI(), []);

  const phone = useMediaQuery("(max-width:480px)");

  const [isPerecederoChecked, setIsPerecederoChecked] = useState(location.state?.perishable !== undefined ? location.state.perishable : false);
  const [canServeOnly, setCanServeOnly] = useState(location.state?.can_serve_alone !== undefined ? location.state.can_serve_alone : false);
  const [isAlcoholic, setIsAlcoholic] = useState(location.state?.is_alcoholic !== undefined ? location.state.is_alcoholic : false);
  const [isVegetarian, setIsVegetarian] = useState(location.state?.is_vegetarian !== undefined ? location.state.is_vegetarian : false);

  const [imageFile, setImageFile] = useState(null);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  const [openBD, setOpenBD] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [submitData, setSubmitData] = useState({});
  const [inpExpirationTime, setInpExpirationTime] = React.useState(location.state?.expiration_time ? location.state.expiration_time : 0);
  const [inpUseTime, setInpUseTime] = React.useState(location.state?.use_time ? location.state.use_time : 0);
  const [inpRemovalTime, setInpRemovalTime] = React.useState(location.state?.removal_time ? location.state.removal_time : 0);
  const [inpAlertTime, setInpAlertTime] = React.useState(location.state?.alert_time ? location.state.alert_time : 0);


  const fields = [
    { keyName: "Nombre", value: "name", type: true },
    { keyName: "Descripción", value: "description", type: true },
    { keyName: "SKU", value: "sku", type: true },
    { keyName: "Código de barras", value: "barCode", type: false },
    { keyName: "Precio", value: "price", type: true },
    { keyName: "Costo", value: "standard_price", type: false},
    { keyName: "Categoría Uber", value: "clase_articlo", type: false },
    { keyName: "Subcategoría", value: "subcategory", type: false },
  ];

  const dimesions = [
    { keyName: "Peso (kg)", value: "weight", type: true },
    { keyName: "Alto (cm)", value: "height", type: true },
    { keyName: "Profundidad (cm)", value: "depth", type: true },
    { keyName: "Ancho (cm)", value: "width", type: true },
  ];

  


  const handlePerecederoChange = (event) => {
    setIsPerecederoChecked(event.target.checked);
    // if (event.target.checked) {
    //   alert('¡El checkbox está seleccionado!');
    // }
  };

  const handleCanServeOnly = (e) => {
    setCanServeOnly(e.target.checked);
  };

  const handleIsAlcoholic = (e) => {
    setIsAlcoholic(e.target.checked);
  };

  const handleIsVegetarian = (e) =>{
    setIsVegetarian(e.target.checked);
  };

  const handleClose = (path) => {
    if (path == "nuevo") {
      window.location.reload();
    } else {
      navigate(`/cliente/productos/${path}`);
    }
  };

  const handleSendForm = () => {
    setOpenWarning(false);
    AddProduct();
  };

  const GetCategories = async () => {
    let data = 'all=true'
    const response = await productAPI.categoriesListEP(data);

    if (response.status == 200) {
      setCategories(response.data);
      if (location.state) {
        const ct = response.data.filter(
          (el) => el.categoria == location.state.category_id
        );
        const categoryId = ct.map((item) => item.id).toString();
        setCategoryId(parseInt(categoryId));
        setImageFile(location.state.image);
      }
    } else {
      console.log(response);
    }
  };

  const AddProduct = async () => {
    setOpenBD(true);
    let data = new FormData();

    console.log("la loca", location)
    data.append("sku", submitData.sku);
    data.append("name", submitData.name)
    data.append("descripcion", submitData.description);
    if(submitData.barCode !== ''){
      data.append("codigo_barras", submitData.barCode);
    }
    data.append("precio_por_unidad", parseFloat(submitData.price));
    if (submitData.standard_price !== '') {
      data.append("standard_price", parseFloat(submitData.standard_price));
    }
    data.append("categoria", submitData.category_id);
    data.append("image", submitData.image);
    data.append("peso", submitData.weight);
    data.append("altura", submitData.height);
    data.append("profundidad", submitData.depth);
    data.append("ancho", submitData.width);
    data.append("subcategory", submitData.subcategory);
    data.append("clase_articlo", submitData.clase_articlo);
    data.append("can_serve_alone", canServeOnly);
    data.append("is_alcoholic", isAlcoholic);
    data.append("is_vegetarian", isVegetarian);
    data.append("perishable", isPerecederoChecked);

    if(isPerecederoChecked){
      data.append("expiration_time", parseInt(inpExpirationTime));
      data.append("use_time", parseInt(inpUseTime));
      data.append("removal_time", parseInt(inpRemovalTime));
      data.append("alert_time", parseInt(inpAlertTime));
    }


    // console.log(submitData);
    // console.log(imageFile);

    for (var pair of data.entries()) {
      console.log(pair[0] + " - " + pair[1]);
    }

    let response;

    if (location.state != undefined) {
      data.append("id", location.state.id);
      response = await productAPI.editProductEP(data);
    } else {
      response = await productAPI.createProductEP(data);
    }

    if (response.status == 200) {
      setOpenSuccess(true);
      setOpenBD(false);
    } else {
      console.log(response.response.data)
      setOpenBD(false);
      setOpenError(true);
      if (response.response.data.non_field_errors) {
        setErrorText(response.response.data.non_field_errors[0]);
      }
      if(response.response.data.sku){
        setErrorText(response.response.data.sku[0])
        if(response.response.data.bar_code){
          setErrorText([response.response.data.sku[0], response.response.data.bar_code[0]])
        }
      }
      if(response.response.data.bar_code){
        setErrorText(response.response.data.bar_code[0])
        if(response.response.data.sku){
          setErrorText([response.response.data.sku[0], response.response.data.bar_code[0]])
        }
      }
      if (response.response.data.wms_error) {
        setErrorText(
          `Hubo un error al intentar conectarnos con nuestro sistema de ERP, comunicate con el equipo de soporte y comparteles el id del error:${response.response.data.wms_error}`
        );
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      location.state != undefined
        ? {
            id: location.state.id,
            name: location.state.name,
            description: location.state.description,
            sku: location.state.sku.includes(
              localStorage.getItem("codigo_cliente")
            )
              ? location.state.sku.slice(4)
              : location.state.sku,
            barCode: location.state.barCode,
            price: location.state.price,
            standard_price: location.state.standard_price,
            category_id: categoryId,
            subcategory: location.state.subcategory,
            clase_articlo: location.state.clase_articlo,
            weight: location.state.weight,
            height: location.state.height,
            width: location.state.width,
            depth: location.state.depth,
            imageFile: location.state.imageFile,
            image: "",
            perishable: location.state.perishable,
          }
        : {
            name: "",
            description: "",
            sku: "",
            barCode: "",
            price: "",
            standard_price: "",
            category_id: "",
            weight: "",
            height: "",
            width: "",
            depth: "",
            imageFile: false,
            image: "",
          },
    validationSchema: Yup.object({
      sku: Yup.string()
        .min(2, "Demasiado corto")
        .max(40, "Demasiado largo")
        .matches(/^\w+$/, "Debe ser alfanumérico, sin espacios")
        .required("Campo Obligatorio"),
      barCode: Yup.string()
        .matches(/^\w+$/, "Debe ser alfanumérico, sin espacios") //Sólo números y letras
        .min(10, "Demasiado corto")
        .max(40, "Demasiado largo"),
      price: Yup.number()
        .required("Campo obligatorio")
        .min(0, "Debe ser mayor a 0")
        .typeError("Debe ser un número sin espacios")
        .max(1000000, "Debe ser un número menor a 1000000")
        .transform((value, originalValue) =>
          /\s/.test(originalValue) ? NaN : value
        ) /*No acepta espacios vacíos */
        .test("len", "Máximo dos decimales", (val) => {
          if (val != undefined) {
            return /^\s*-?[1-9]\d*(\.\d{1,2})?\s*$/.test(val);
          }
          return true;
        }),
      standard_price: Yup.number()
        .min(0, "Debe ser mayor a 0")
        .typeError("Debe ser un número sin espacios")
        .max(1000000, "Debe ser un número menor a 1000000")
        .transform((value, originalValue) =>
          /\s/.test(originalValue) ? NaN : value
        ) /*No acepta espacios vacíos */
        .test("len", "Máximo dos decimales", (val) => {
          if (val != undefined) {
            return /^\s*-?[1-9]\d*(\.\d{1,2})?\s*$/.test(val);
          }
          return true;
        }),
      name: Yup.string()
      .required("Campo obligatorio")
      .matches(
        /^[^\W\s][\w\u00C0-\u024F\u1E00\s\'\.\/\&\-\,]*$/,
        "Debe comenzar por letra o número. Sólo se aceptan -, /, &, ', . y , "
      )
      .min(5, "Demasiado corto")
      .max(150, "Demasiado largo"),
      description: Yup.string()
        .required("Campo obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s\'\.\/\&\-\,]*$/,
          "Debe comenzar por letra o número. Sólo se aceptan -, /, &, ', . y , "
        )
        .min(5, "Demasiado corto")
        .max(150, "Demasiado largo"),
      category_id: Yup.string().required("Campo obligatorio"),
      subcategory: Yup.string()
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s\'\.\/\&\-\,]*$/,
          "Debe comenzar por letra o número. Sólo se aceptan -, /, &, ', . y , "
        )
        .max(150, "Demasiado largo"),
      clase_articlo: Yup.string()
      .matches(
        /^[^\W\s][\w\u00C0-\u024F\u1E00\s\'\.\/\&\-\,]*$/,
        "Debe comenzar por letra o número. Sólo se aceptan -, /, &, ', . y , "
      )
      .max(150, "Demasiado largo"),
      weight: Yup.number()
        .required("Campo obligatorio")
        .typeError("Debe ser un número")
        .min(0, "Debe ser mayor a 0")
        .max(1000, "Debe ser un número menor a 1000")
        .transform((value, originalValue) =>
          /\s/.test(originalValue) ? NaN : value
        )
        .test("len", "Debe ser mayor a 0 y máximo dos decimales.", (val) => {
          if (val != undefined) {
            return /^(?!0+(?:\.0+)?$)\s*-?[0-9]\d*(\.\d{1,2})?\s*$/.test(val);
          }
          return true;
        }) /*No acepta espacios vacíos */,
      width: Yup.number()
        .required("Campo obligatorio")
        .typeError("Debe ser un número")
        .min(0, "Debe ser mayor a 0")
        .max(100, "Debe ser un número menor a 100")
        .transform((value, originalValue) =>
          /\s/.test(originalValue) ? NaN : value
        )
        .test("len", "Debe ser mayor a 0 y máximo dos decimales", (val) => {
          if (val != undefined) {
            return /^(?!0+(?:\.0+)?$)\s*-?[0-9]\d*(\.\d{1,2})?\s*$/.test(val);
          }
          return true;
        }),
      height: Yup.number()
        .required("Campo obligatorio")
        .typeError("Debe ser un número")
        .min(0, "Debe ser mayor a 0")
        .max(100, "Debe ser un número menor a 100")
        .transform((value, originalValue) =>
          /\s/.test(originalValue) ? NaN : value
        )
        .test("len", "Debe ser mayor a 0 y máximo dos decimales", (val) => {
          if (val != undefined) {
            return /^(?!0+(?:\.0+)?$)\s*-?[0-9]\d*(\.\d{1,2})?\s*$/.test(val);
          }
          return true;
        }),
      depth: Yup.number()
        .required("Campo obligatorio")
        .typeError("Debe ser un número")
        .min(0, "Debe ser mayor a 0")
        .max(100, "Debe ser un número menor a 100")
        .transform((value, originalValue) =>
          /\s/.test(originalValue) ? NaN : value
        )
        .test("len", "Debe ser mayor a 0 y máximo dos decimales", (val) => {
          if (val != undefined) {
            return /^(?!0+(?:\.0+)?$)\s*-?[0-9]\d*(\.\d{1,2})?\s*$/.test(val);
          }
          return true;
        }),
      image: Yup.string().when("imageFile", {
        is: false,
        then: Yup.string(),
      }),
    }),

    onSubmit: (values) => {
      const data = {
        ...values,
        image: imageFile,
      };
      setSubmitData(data);
      setOpenWarning(true);
    },
  });

  const handleChangeImage = ({ target }) => {
    if (!target.files) {
      return;
    }

    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

    if (!allowedExtensions.exec(target.files[0].name)) {
      alert("Formato inválido, porfavor escoge una imagen");
      target.value = "";
      target.files = null;
      return false;
    }

    const file = target.files[0];
    setImageFile(file);
    formik.setFieldValue("imageFile", true);
    // setErrorImage(true);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          rowSpacing={2}
          columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}
          className=""
        >
          {" "}
          {/*---Contenedor padre---*/}
          <Grid item xs={6}>
            {" "}
            {/*---Ocupara mitad de pantalla---*/}
            <Grid
              item
              xs={12}
              style={{ height: "100%" }}
              className={`border p-3 r-15 bg-white`}
            >
              {" "}
              {/*---Altura de 100 para que ocupe verticalmente---*/}
              {fields.map((field, i) => (
                <div key={i}>
                  <div
                    className={`d-flex flex-column mb-3 ${
                      phone ? "px-2 " : "px-4"
                    }`}
                  >
                    <div
                      className={`d-flex align-items-center mr-2 ${
                        phone && "mb-2"
                      }`}
                      style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                    >
                      <p className=" no-wrap h6" htmlFor={field.value}>{`${
                        field.keyName
                      }: ${field.type ? "*" : ""}`}</p>
                    </div>

                    <div className="d-flex align-items-center w-100">
                      <div className="d-flex align-items-center w-100">
                        {field.value == "sku" ? (
                          <div
                            style={{
                              height: "40px",
                              borderRadius: "5px 0 0 5px",
                              minWidth: "50px",
                            }}
                            className={`d-flex align-items-center justify-content-center border grey-light-background m-0 p-1 gray-font ${
                              formik.touched.sku && formik.errors.sku && "mb-4"
                            }`}
                          >{`${
                            localStorage.getItem("codigo_cliente") != null
                              ? localStorage.getItem("codigo_cliente")
                              : ""
                          }-`}</div>
                        ) : null}
                        <TextField
                          error={
                            formik.touched[field.value] &&
                            formik.errors[field.value]
                          }
                          size="small"
                          label={field.keyName}
                          variant="outlined"
                          fullWidth
                          onChange={formik.handleChange}
                          value={formik.values[field.value]}
                          name={field.value}
                          id={field.value}
                          helperText={
                            formik.touched[field.value] &&
                            formik.errors[field.value] ? (
                              <div>{formik.errors[field.value]}</div>
                            ) : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div
                key={"category_id"}
                className={` d-flex mb-3 flex-column ${
                  phone ? "px-2 " : "px-4"
                }`}
              >
                {/* <div className="d-flex justify-content-end">
                  <button type="button" className="btn btn-link" style={{ color: '#004876' }}>Añadir Categoría</button>
                </div> */}
                <div
                  className={`d-flex align-items-center mr-2 ${
                    phone && "mb-2"
                  }`}
                  style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                >
                  <p className="no-wrap h6" htmlFor={"category_id"}>
                    {`${"Categoría"}: ${"*"}`}
                  </p>
                </div>
                <div className="d-flex align-items-center w-100">
                  <div className="d-flex flex-column w-100">
                    <TextField
                      select
                      size="small"
                      error={
                        formik.touched.category_id && formik.errors.category_id
                      }
                      label="Categoría"
                      value={formik.values.category_id}
                      name={"category_id"}
                      id={"category_id"}
                      onChange={formik.handleChange}
                      fullWidth
                      helperText={
                        formik.touched.category_id &&
                        formik.errors.category_id ? (
                          <div>{formik.errors.category_id}</div>
                        ) : null
                      }
                    >
                      {/* <Search /> */}
                      {categories.map((option, i) => (
                        <MenuItem key={i} value={option.id}>
                          {option.categoria}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                
              </div>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* -------------------------------IMAGEN------------------------------- */}
                <div
                  className={` d-flex mb-3 ${
                    phone ? "px-2" : "px-4"
                  } border p-3 r-15 bg-white`}
                >
                  <InputImagePreview
                    location={location.state}
                    setFieldValue={formik.setFieldValue}
                    errorImage={formik.errors.image}
                    touchedImage={formik.touched.image}
                    text={"Imagen del producto"}
                    handleChangeImage={handleChangeImage}
                    imageFile={imageFile}
                    setImageFile={setImageFile}
                    maxWidth={"400px"}
                    maxHeight={"190px"}
                    disabled={true}
                    required={false}
                  />
                </div>

                {/* -------------------------------MEDIDAS------------------------------- */}
                <Grid
                  container
                  columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}
                  className="border p-3 r-15 bg-white"
                >
                  {dimesions.map((dimension, i) => (
                    <Grid key={i} item xs={6}>
                      <div
                        className={` d-flex mb-3 flex-column ${
                          phone ? "px-2 " : "px-4"
                        }`}
                      >
                        <div
                          className={`d-flex align-items-center mr-2 ${
                            phone && "mb-2"
                          }`}
                          style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                        >
                          <p
                            className="no-wrap h6"
                            htmlFor={dimension.value}
                          >{`${dimension.keyName}: ${
                            dimension.type ? "*" : ""
                          }`}</p>
                        </div>
                        <div className="d-flex align-items-center w-100">
                          <div className="d-flex flex-column w-100">
                            <TextField
                              error={
                                formik.touched[dimension.value] &&
                                formik.errors[dimension.value]
                              }
                              size="small"
                              label={dimension.keyName}
                              variant="outlined"
                              fullWidth
                              onChange={formik.handleChange}
                              value={formik.values[dimension.value]}
                              name={dimension.value}
                              id={dimension.value}
                              helperText={
                                formik.touched[dimension.value] &&
                                formik.errors[dimension.value] ? (
                                  <div>{formik.errors[dimension.value]}</div>
                                ) : null
                              }
                            />
                          </div>
                        </div>
                      </div>
                      
                    </Grid>
                  ))}
                  <div>
                  <div className="d-flex flex-row justify-content-center align-items-center w-100">
                    <FormControlLabel
                      control={<Checkbox checked={canServeOnly} onChange={handleCanServeOnly} color="default" />}
                      label="Puede servir solo"
                      labelPlacement="start" 
                    />
                    <FormControlLabel
                      control={<Checkbox checked={isAlcoholic} onChange={handleIsAlcoholic} color="default" />}
                      label="Es alcoholica"
                      labelPlacement="start" 
                    />
                    <FormControlLabel
                      control={<Checkbox checked={isVegetarian} onChange={handleIsVegetarian} color="default" />}
                      label="Es vegetariana"
                      labelPlacement="start" 
                    />
                    <FormControlLabel
                      control={<Checkbox checked={isPerecederoChecked} onChange={handlePerecederoChange} color="default" />}
                      label="Perecedero"
                      labelPlacement="start"
                    />
                  </div>
                  {isPerecederoChecked && (
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={3}>
                        <label htmlFor="expiration-time">Dias de expiración:</label>
                        <NumberInputAdornments
                          value={inpExpirationTime}
                          handleChange={setInpExpirationTime}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <label htmlFor="use-time">Consumir antes de:</label>
                        <NumberInputAdornments
                          value={inpUseTime}
                          handleChange={setInpUseTime}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <label htmlFor="removal-time">Tiempo de remoción:</label>
                        <NumberInputAdornments
                          value={inpRemovalTime}
                          handleChange={setInpRemovalTime}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <label htmlFor="alert-time">No. de días de alerta:</label>
                        <NumberInputAdornments 
                          value={inpAlertTime}
                          handleChange={setInpAlertTime}
                        />
                      </Grid>
                  </Grid>
                  
                  )}

                    
                </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div
          className={`${
            !phone ? "d-flex justify-content-end margin-right mt-2" : "mt-2"
          }`}
        >
          <div className={`${phone && "mb-2"}`}>
            <CancelButton
              text={"Cancelar"}
              onClick={() => {
                navigate("/cliente/productos/");
              }}
              width={phone ? "100%" : ""}
            />
          </div>

          <AcceptButton
            text={location.state != undefined ? "Editar" : "Agregar"}
            type={"submit"}
            width={phone ? "100%" : ""}
          />
        </div>
      </form>
      <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalProductSuccess
            handleClose={handleClose}
            text={"Producto"}
            edit={location.state != undefined ? "editData" : "none"}
          />
        </div>
      </Modal>
      <Modal open={openError} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalError
            text={`No se pudo ${
              location.state != undefined ? "editar" : "agregar"
            } el producto.`}
            handleClose={() => setOpenError(false)}
            error={errorText}
          />
        </div>
      </Modal>
      <Modal open={openWarning}>
        <div>
          <ModalWarning
            actionCancel={() => {
              setOpenWarning(false);
            }}
            actionProceed={handleSendForm}
            submitData={submitData}
            type={"products"}
          />
        </div>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
