import React, { useEffect, useRef, useState } from "react";
import { Field, useFormik, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Modal,
  useMediaQuery,
  MenuItem,
  Grid,
  IconButton,
  Tooltip,
  Chip,
  FormControlLabel,
  Checkbox,
  Typography,
  Popover,
  Backdrop,
  LinearProgress,
} from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";

import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";

import { getCSRF } from "../../helpers/getCSRF";

import { Add } from "@mui/icons-material";

import { ModalProductSuccess } from "../Modal/ModalProductSuccess";
import { ModalError } from "../Modal/ModalError";

import { WarehouseAPI } from "../../api/Warehouse";
import { AdminAPI } from "../../api/Admin";

import LoaderDS from "../../assets/LoaderDS.svg";
import { UploadButton } from "../Buttons/UploadButton";
import { DeleteDocButton, DocsButton } from "../Buttons/DeleteDocButton";
import { ChangeDocButton } from "../Buttons/ChangeDocButton";
import { SeeButton } from "../Buttons/SeeButton";

import ModalImgClientForm from "../Modal/ModalImgClientForm";
import ModalPdfClientForm from "../Modal/ModalPdfClientForm";
import { useMemo } from "react";
import { theme } from "../../utils/theme";

export const ClientForm = () => {
  const navigate = useNavigate();
  const phone = useMediaQuery("(max-width:480px)");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openBD, setOpenBD] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorRfcRepStatus, setErrorRfcRepStatus] = useState(false);
  const [errorRfcInvalidStatus, setErrorRfcInvalidStatus] = useState(false);
  const [errorEmailStatus, setErrorEmailStatus] = useState(false);
  const [errorPhoneStatus, setErrorPhoneStatus] = useState(false);
  const [errorCP, setErrorCP] = useState(false);

  const [whs, setWhs] = useState([]); /*ARRAY DE SUCURSALES POR ID*/
  const [whsStr, setWhsStr] = useState([]); /*ARRAY DE SUCURSALES POR STRINGS*/

  const [cediID, setCediID] = useState([]);
  const [cediStr, setCediStr] = useState([]); // ARRAY DE CEDIS EN STR

  const [cedis, setCedis] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [lastMileCost, setlastMileCost] = useState(0);
  const [transferenceCost, setTransferenceCost] = useState(0);
  const [storageCost, setStorageCost] = useState(0);
  const [callbackCost, setCallbackCost] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const [isPdf, setIsPdf] = useState(false);
  const [urlFile, setUrlFile] = useState("");
  const [openPdf, setOpenPdf] = useState(false);
  const [openImg, setOpenImg] = useState(false);
  const [label, setLabel] = useState("");

  const location = useLocation();
  const actRef = useRef(null);
  const idRef = useRef(null);
  const addressRef = useRef(null);
  const contractRef = useRef(null);
  const fiscalRef = useRef(null);
  const other1Ref = useRef(null);

  const [nameDoc, setNameDoc] = useState({
    act: "",
    address: "",
    id: "",
    contract: "",
    fiscal: "",
    other1: "",
  });

  const [docFile, setDocFile] = useState({
    act: null,
    address: null,
    id: null,
    contract: null,
    fiscal: null,
    other1: null,
  });

  const [errorField, setErrorField] = useState("");

  const [checkState, setCheckState] = useState({
    lastMileCheck: false,
    transferenceCheck: false,
    callbackCheck: false,
  });

  const handlePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleRemoveImage = (e) => {
    setNameDoc({ ...nameDoc, [e.target.value]: "" });
    setDocFile({ ...docFile, [e.target.value]: "" });
  };

  const handleUpload = (uploadRef) => {
    uploadRef.current && uploadRef.current.click();
  };

  const handleChangeImages = ({ target }) => {
    if (!target.files) {
      return;
    }
    const allowedExtensions = /(\.jpg|\.pdf|\.png)$/i;
    if (!allowedExtensions.exec(target.files[0].name)) {
      alert("Formato inválido, por favor escoge una imagen");
      target.value = "";
      target.files = null;
      return false;
    }
    const file = target.files[0];
    setNameDoc({ ...nameDoc, [target.name]: file.name });
    setDocFile({ ...docFile, [target.name]: file });
  };

  const handleVisor = (doc) => {
    const regExImg = /(\.jpg|\.png)$/i;
    const regExPdf = /(\.pdf)$/i;

    if (!regExImg.exec(doc.url)) {
      setIsPdf(true);
      setOpenPdf(true);
    } else if (!regExPdf.exec(doc.url)) {
      setIsPdf(false);
      setOpenImg(true);
    }
    // console.log(doc.url);
    setUrlFile(doc.url);
    setLabel(doc.keyName);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    getBranchesList();
    getCedisList();

    if (location.state != undefined) {
      getClientServices();
      getClientBranches();
      getClientCedis();

      setNameDoc({
        act: location.state.act.split("/").at(-1),
        address: location.state.address.split("/").at(-1),
        id: location.state.id.split("/").at(-1),
        contract: location.state.contract.split("/").at(-1),
        fiscal: location.state.fiscal.split("/").at(-1),
        other1: location.state.other1.split("/").at(-1),
      });

      setDocFile({
        act: location.state.act,
        address: location.state.address,
        id: location.state.id,
        contract: location.state.contract,
        fiscal: location.state.fiscal,
        other1: location.state.other1,
      });
    }
  }, []);

  const fields = [
    { keyName: "Razón social", value: "business", type: true },
    { keyName: "RFC", value: "rfc", type: true },
    { keyName: "Régimen fiscal", value: "regime", type: true },
    { keyName: "Nombre(s)", value: "name", type: true },
    { keyName: "Apellidos", value: "lastName", type: true },
    { keyName: "Calle y número", value: "street", type: true },
    { keyName: "Colonia", value: "suburb", type: true },
    { keyName: "C.P.", value: "postalCode", type: true },
    { keyName: "Teléfono celular", value: "personalNumber", type: true },
    { keyName: "Teléfono oficina", value: "officeNumber", type: false },
    { keyName: "Email", value: "email", type: true },
    { keyName: "Sitio web", value: "website", type: true },
  ];

  const regime = [
    { keyName: "Persona Física", value: "Fisica" },
    { keyName: "Persona Moral", value: "Moral" },
  ];

  const billing = [{ keyName: "Límite de pago", value: "limit", type: true }];

  const services = [
    { keyName: "Resguardo", value: "storage", status: true },
    {
      keyName: "Última milla",
      value: "lastMile",
      status: checkState.lastMileCheck,
      check: "lastMileCheck",
    },
    {
      keyName: "Transferencia",
      value: "transference",
      status: checkState.transferenceCheck,
      check: "transferenceCheck",
    },
    {
      keyName: "Recolección",
      value: "callback",
      status: checkState.callbackCheck,
      check: "callbackCheck",
    },
  ];

  const documents = [
    {
      keyName: "Acta constitutiva",
      value: "act",
      type: true,
      ref: actRef,
      name: nameDoc.act,
      url: docFile.act == "" ? "" : docFile.act,
    },
    {
      keyName: "Comprobante de domicilio",
      value: "address",
      type: true,
      ref: addressRef,
      name: nameDoc.address,
      url: docFile.address == "" ? "" : docFile.address,
    },
    {
      keyName: "Identificación del representante legal",
      value: "id",
      type: true,
      ref: idRef,
      name: nameDoc.id,
      url: docFile.id == "" ? "" : docFile.id,
    },
    {
      keyName: "Comprobante de situación fiscal",
      value: "fiscal",
      type: true,
      ref: fiscalRef,
      name: nameDoc.fiscal,
      url: docFile.fiscal == "" ? "" : docFile.fiscal,
    },
    {
      keyName: "Contrato Day Store",
      value: "contract",
      type: true,
      ref: contractRef,
      name: nameDoc.contract,
      url: docFile.contract == "" ? "" : docFile.contract,
    },
    {
      keyName: "Otros",
      value: "other1",
      type: true,
      ref: other1Ref,
      name: nameDoc.other1,
      url: docFile.other1 == "" ? "" : docFile.other1,
    },
  ];

  //apis
  const adminAPI = useMemo(() => new AdminAPI(), []);
  const warehouseAPI = useMemo(() => new WarehouseAPI(), []);

  const getClientServices = async () => {
    let data = new FormData();

    data.append("client", location.state.idClient);

    const response = await adminAPI.clientServicesEP(data);
    if (response.status == 200) {
      let aux = {
        lastMileCheck: false,
        transferenceCheck: false,
        callbackCheck: false,
      };
      response.data.services.forEach((item) => {
        switch (item.service) {
          case "Resguardo":
            setStorageCost(item.amount);
            break;

          case "Última Milla":
            item.amount != 0
              ? (aux.lastMileCheck = true)
              : (aux.transferenceCheck = false);
            setlastMileCost(item.amount);
            break;

          case "Transferencia":
            item.amount != 0
              ? (aux.transferenceCheck = true)
              : (aux.transferenceCheck = false);
            setTransferenceCost(item.amount);
            break;

          case "Recolección":
            item.amount != 0
              ? (aux.callbackCheck = true)
              : (aux.callbackCheck = false);
            setCallbackCost(item.amount);
            break;
        }
      });
      setCheckState(aux);

      setTimeout(() => {
        formik.setFieldValue("lastMileCheck", aux.lastMileCheck);
        formik.setFieldValue("transferenceCheck", aux.transferenceCheck);
        formik.setFieldValue("callbackCheck", aux.callbackCheck);
      }, 1);
    } else {
      console.log(response);
    }
  };

  const getClientBranches = async () => {
    /* List of branches associated with a client */
    setIsLoading(true);
    let data = new FormData();

    data.append("client", location.state.idClient);

    const response = await adminAPI.clientBranchesEP(data);
    if (response.status == 200) {
      const br = response.data.branches.map((item) => item.branch);
      const brId = response.data.branches.map((item) => item.branch_id);
      setIsLoading(false);
      setWhsStr(br);
      setWhs(brId);
      setTimeout(() => {
        if (br.length != 0) {
          formik.setFieldValue("warehouses", br[br.length - 1]);
        }
      }, 1);
    } else {
      console.log(response);
    }
  };

  const getClientCedis = async () => {
    /* List of cedis associated with a client */
    setIsLoading(true);
    let data = new FormData();

    data.append("client", location.state.idClient);

    const response = await adminAPI.clientCedisEP(data);
    if (response.status == 200) {
      const cedis = response.data.cedis.map((item) => item.cedi);
      const cedisId = response.data.cedis.map((item) => item.cedi_id);
      setIsLoading(false);
      setCediStr(cedis);
      setCediID(cedisId);

      setTimeout(() => {
        if (cedis.length != 0) {
          formik.setFieldValue("cedi", cedis[cedis.length - 1]);
        }
      }, 1);
    } else {
      console.log(response);
    }
  };

  const getCedisList = async () => {
    let data = new FormData();

    const response = await warehouseAPI.cedisList(data);
    if (response.status == 200) {
      setCedis(response.data.cedis);
    } else {
      console.log(response);
    }
  };

  const getBranchesList = async () => {
    let data = new FormData();

    const api = new WarehouseAPI();
    const response = await warehouseAPI.getBranchesList(data);
    if (response.status == 200) {
      setWarehouses(response.data.branches);
    } else {
      console.log(response);
    }
  };

  const handleClose = (path) => {
    if (path == "nuevo") {
      window.location.reload();
    } else {
      navigate(`/administrador/clientes/`);
    }
  };

  const [whsValue, setWhsValue] = useState("");
  const [cediValue, setCediValue] = useState("");

  const handleWarehouseArray = (value, type) => {
    /* Create chip branches */
    const filterBySucursal = warehouses.filter(
      (ware) => ware.sucursal == value
    );
    const sucursalId = filterBySucursal
      .map((sucursal) => sucursal.id)
      .toString();

    switch (type) {
      case "add":
        const lol = [...whs];

        if (sucursalId == "" || lol.includes(sucursalId)) {
          return;
        } else {
          const aux = [...whs, sucursalId];
          const str = [...whsStr, value];
          setWhs(aux);
          setWhsStr(str);
          formik.setFieldValue("warehouses", str.join(""));
        }
        break;
      case "delete":
        const aux = [...whs].filter((el) => el != sucursalId);
        const str = [...whsStr].filter((el) => el != value);

        setWhs(aux);
        setWhsStr(str);
        formik.setFieldValue("warehouses", str.join(""));
        break;
    }
  };

  const handleCediArray = (value, type) => {
    /* Create chip cedis */
    const filterByCedis = cedis.filter((cedi) => cedi.cedi == value);
    const cediId = filterByCedis.map((cedi) => cedi.id).toString();

    switch (type) {
      case "add":
        const lol = [...cediID];
        const suc = [...cediStr];

        if (cediId == "" || lol.includes(cediId)) {
          return;
        } else {
          const aux = [...cediID, cediId];
          const str = [...cediStr, value];
          setCediStr(str);
          setCediID(aux);
          formik.setFieldValue("cedi", str.join(""));
        }
        break;
      case "delete":
        const aux = [...cediID].filter((el) => el != cediId);
        const str = [...cediStr].filter((el) => el != value);
        setCediID(aux);
        setCediStr(str);
        formik.setFieldValue("cedi", str.join(""));
        break;
    }
  };

  const handleCheck = (event) => {
    /*Habilitar y deshabilitar inputs con check*/
    setCheckState({
      ...checkState,
      [event.target.name]: event.target.checked,
    });
  };

  const EditClient = async (obj) => {
    /***EDITAR CLIENTE***/
    setOpenBD(true);
    let data = new FormData();

    data.append("id", location.state.idClient);
    data.append("business", obj.business == "" ? "" : obj.business);
    data.append("rfc", obj.rfc == "" ? "" : obj.rfc);
    data.append("regime", obj.regime);
    data.append("website", obj.website);
    data.append("name", obj.name);
    data.append("lastName", obj.lastName);
    data.append("email", obj.email);
    data.append("personalNumber", parseInt(obj.personalNumber));
    data.append(
      "officeNumber",
      obj.officeNumber == "" ? "" : parseInt(obj.officeNumber)
    );
    data.append("street", obj.street);
    data.append("suburb", obj.suburb);
    data.append("postalCode", parseInt(obj.postalCode));
    data.append("cedis", cediID.join(","));
    data.append("branches", whs.join(","));
    data.append("storage", parseFloat(obj.storage));
    data.append(
      "callback",
      obj.callback == "" || !obj.callbackCheck ? "" : parseFloat(obj.callback)
    );
    data.append(
      "transference",
      obj.transference == "" || !obj.transferenceCheck
        ? ""
        : parseFloat(obj.transference)
    );
    data.append(
      "lastMile",
      obj.lastMile == "" || !obj.lastMileCheck ? "" : parseFloat(obj.lastMile)
    );
    data.append("limit", parseInt(obj.limit));
    data.append("rfc_evidence", obj.fiscal);
    data.append("ds_agreement", obj.contract);
    data.append("founding_act", obj.act);
    data.append("id_legal_repr", obj.id);
    data.append("address_proof", obj.address);
    data.append("extra_field_1", obj.other1);

    const response = await adminAPI.editClientAdminEP(data);
    if (response.status == 200) {
      setOpenSuccess(true);
      setOpenBD(false);
    } else {
      // console.log(response);
      setOpenError(true);
      setOpenBD(false);
      switch (response.response.data.detail) {
        case "RFC ya está asociado a otro cliente":
          setErrorRfcRepStatus(true);
          break;
        case "Email ya está asociado a otro cliente":
          setErrorEmailStatus(true);
          break;
        case "Teléfono ya está asociado a otro cliente":
          setErrorPhoneStatus(true);
          break;
        case "El código postal ingresado no existe":
          setErrorCP(true);
          break;
        default:
          setErrorField(response.response.data.detail);
          break;
      }
      setErrorField(response.response.data.detail);
      if (response.response.data.rfc[0]) {
        setErrorRfcInvalidStatus(true);
        setErrorField(response.response.data.rfc[0]);
      }
      if (response.response.data.wms_error) {
        setErrorField(
          `Hubo un error al intentar conectarnos con nuestro sistema de ERP, comunicate con el equipo de soporte y comparteles el id del error:${response.response.data.wms_error}`
        );
      }
    }
  };

  const AddClient = async (obj) => {
    setOpenBD(true);
    let data = new FormData();

    data.append("business", obj.business);
    data.append("rfc", obj.rfc);
    data.append("regime", obj.regime);
    data.append("website", obj.website);
    data.append("name", obj.name);
    data.append("lastName", obj.lastName);
    data.append("email", obj.email);
    data.append("personalNumber", parseInt(obj.personalNumber));
    data.append(
      "officeNumber",
      obj.officeNumber == "" ? "" : parseInt(obj.officeNumber)
    );
    data.append("street", obj.street);
    data.append("suburb", obj.suburb);
    data.append("postalCode", obj.postalCode);
    data.append("cedis", cediID.join(","));
    data.append("branches", whs.join(","));
    data.append("storage", parseFloat(obj.storage));
    data.append(
      "callback",
      obj.callback == "" || !obj.callbackCheck ? "" : parseFloat(obj.callback)
    );
    data.append(
      "transference",
      obj.transference == "" || !obj.transferenceCheck
        ? ""
        : parseFloat(obj.transference)
    );
    data.append(
      "lastMile",
      obj.lastMile == "" || !obj.lastMileCheck ? "" : parseFloat(obj.lastMile)
    );
    data.append("limit", parseInt(obj.limit));
    data.append("rfc_evidence", obj.fiscal);
    data.append("ds_agreement", obj.contract);
    data.append("founding_act", obj.act);
    data.append("id_legal_repr", obj.id);
    data.append("address_proof", obj.address);
    data.append("extra_field_1", obj.other1);

    const response = await adminAPI.newClient(data);
    if (response.status == 200) {
      setOpenSuccess(true);
      setOpenBD(false);
    } else {
      // console.log(response);
      setOpenBD(false);
      setOpenError(true);
      switch (response.response.data.detail) {
        case "RFC ya está asociado a otro cliente":
          setErrorRfcRepStatus(true);
          break;
        case "Email ya está asociado a otro cliente":
          setErrorEmailStatus(true);
          break;
        case "Teléfono ya está asociado a otro cliente":
          setErrorPhoneStatus(true);
          break;
        case "El código postal ingresado no existe":
          setErrorCP(true);
          break;
      }
      setErrorField(response.response.data.detail);
      if (response.response.data.rfc[0]) {
        setErrorRfcInvalidStatus(true);
        setErrorField(response.response.data.rfc[0]);
      }
      if (response.response.data.wms_error) {
        setErrorField(
          `Hubo un error al intentar conectarnos con nuestro sistema de ERP, comunicate con el equipo de soporte y comparteles el id del error:${response.response.data.wms_error}`
        );
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      location.state != undefined
        ? {
            business: location.state.business,
            rfc: location.state.rfc,
            regime: location.state.regime,
            website: location.state.website,
            name: location.state.name,
            lastName: location.state.lastName,
            email: location.state.email,
            personalNumber: location.state.personalNumber,
            officeNumber: location.state.officeNumber,
            street: location.state.street,
            suburb: location.state.suburb,
            postalCode:
              location.state.postalCode.length == 4
                ? "0" + location.state.postalCode
                : location.state.postalCode,
            limit: location.state.limit,

            warehouses: "",
            cedi: "",
            storage: storageCost,
            lastMile: lastMileCost,
            transference: transferenceCost,
            callback: callbackCost,

            lastMileCheck: false,
            callbackCheck: false,
            transferenceCheck: false,
          }
        : {
            business: "",
            rfc: "",
            regime: "",
            website: "",
            name: "",
            lastName: "",
            email: "",
            personalNumber: "",
            officeNumber: "",
            street: "",
            suburb: "",
            postalCode: "",
            limit: "",
            warehouses: "",
            cedi: "",
            storage: "",
            lastMile: "",
            transference: "",
            callback: "",
            lastMileCheck: false,
            callbackCheck: false,
            transferenceCheck: false,
          },
    validationSchema: Yup.object({
      business: Yup.string()
        .required("Campo Obligatorio")
        .max(100, "Demasiado largo")
        .matches(/^(?!\s)\w/, "Debe ser alfanumérico, sin espacios al inicio"),
      rfc: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\w+$/, "Debe ser alfanumérico, sin espacios")
        /* .matches(
          /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
          "Ingresa un formato válido"
        ) */
        .min(10, "Demasiado corto")
        .max(15, "Demasiado largo"),
      regime: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\w+$/, "Debe ser alfanumérico, sin espacios")
        .max(10, "Demasiado largo"),
      website: Yup.string()
        .required("Campo obligatorio")
        .matches(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,})/,
          "Ingresa un formato válido, ej: https://www.sitioweb.com"
        )
        .max(100, "Demasiado largo"),
      name: Yup.string()
        .required("Campo obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Demasiado largo"),
      lastName: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Demasiado largo"),
      email: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Formato incorrecto"
        )
        .max(100, "Demasiado largo"),
      personalNumber: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\d+$/, "Deben ser números sin espacios")
        .min(10, "Demasiado corto")
        .max(12, "Demasiado largo"),
      officeNumber: Yup.string()
        .matches(/^\d+$/, "Deben ser números sin espacios")
        .min(10, "Demasiado corto")
        .max(15, "Demasiado largo"),
      street: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(50, "Demasiado largo"),
      suburb: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(50, "Demasiado largo"),
      postalCode: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\d+$/, "Deben ser números sin espacios")
        .min(5, "Ingresa un Código Postal válido")
        .max(5, "Demasiado largo"),
      limit: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\d+$/, "Deben ser números enteros sin espacio"),
      storage: Yup.number()
        .required("Campo obligatorio")
        .typeError("Debe ser un número")
        .min(0, "Debe ser mayor a 0")
        .transform((value, originalValue) =>
          /\s/.test(originalValue) ? NaN : value
        )
        .test("len", "Debe ser mayor a 0 y máximo dos decimales.", (val) => {
          if (val != undefined) {
            return /^(?!0+(?:\.0+)?$)\s*-?[0-9]\d*(\.\d{1,2})?\s*$/.test(val);
          }
          return true;
        }),
      lastMileCheck: Yup.boolean(),
      callbackCheck: Yup.boolean(),
      transferenceCheck: Yup.boolean(),
      lastMile: Yup.number().when("lastMileCheck", {
        is: true,
        then: Yup.number()
          .required("Campo obligatorio")
          .typeError("Debe ser un número")
          .min(0, "Debe ser mayor a 0")
          .transform((value, originalValue) =>
            /\s/.test(originalValue) ? NaN : value
          )
          .test("len", "Debe ser mayor a 0 y máximo dos decimales.", (val) => {
            if (val != undefined) {
              return /^(?!0+(?:\.0+)?$)\s*-?[0-9]\d*(\.\d{1,2})?\s*$/.test(val);
            }
            return true;
          }),
      }),
      transference: Yup.number().when("transferenceCheck", {
        is: true,
        then: Yup.number()
          .required("Campo obligatorio")
          .typeError("Debe ser un número")
          .min(0, "Debe ser mayor a 0")
          .transform((value, originalValue) =>
            /\s/.test(originalValue) ? NaN : value
          )
          .test("len", "Debe ser mayor a 0 y máximo dos decimales.", (val) => {
            if (val != undefined) {
              return /^(?!0+(?:\.0+)?$)\s*-?[0-9]\d*(\.\d{1,2})?\s*$/.test(val);
            }
            return true;
          }),
      }),
      callback: Yup.number().when("callbackCheck", {
        is: true,
        then: Yup.number()
          .required("Campo obligatorio")
          .typeError("Debe ser un número")
          .min(0, "Debe ser mayor a 0")
          .transform((value, originalValue) =>
            /\s/.test(originalValue) ? NaN : value
          )
          .test("len", "Debe ser mayor a 0 y máximo dos decimales.", (val) => {
            if (val != undefined) {
              return /^(?!0+(?:\.0+)?$)\s*-?[0-9]\d*(\.\d{1,2})?\s*$/.test(val);
            }
            return true;
          }),
      }),
      warehouses: Yup.string().test(
        "oneOfRequired",
        "Agrega un cedi o una sucursal",
        (item, testContext) => {
          return testContext.parent.warehouses || testContext.parent.cedi;
        }
      ),
      cedi: Yup.string().test(
        "oneOfRequired",
        "Agrega un cedi o una sucursal",
        (item, testContext) => {
          return testContext.parent.warehouses || testContext.parent.cedi;
        }
      ),
    }),
    onSubmit: (values) => {
      const obj = {
        ...values,
        act: docFile.act,
        address: docFile.address,
        id: docFile.id,
        contract: docFile.contract,
        fiscal: docFile.fiscal,
        other1: docFile.other1,
      };

      if (location.state != undefined || location.state != null) {
        EditClient(obj);
      } else {
        AddClient(obj);
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          rowSpacing={2}
        >
          {" "}
          {/*---Contenedor padre---*/}
          <Grid item xs={12} sm={12} md={6}>
            {" "}
            {/*---Ocupara mitad de pantalla---*/}
            <Grid item xs={12} className={`border bg-white p-3 r-15`}>
              {" "}
              {/*---Altura de 100 para que ocupe verticalmente---*/}
              <div className="">
                <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
                  Datos Cliente
                </p>
              </div>
              <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
                {fields.map((field) => (
                  <Grid
                    key={field.keyName}
                    item
                    xs={
                      field.value != "business" && field.value != "street"
                        ? 6
                        : 12
                    }
                  >
                    <div
                      className={` d-flex mb-3 flex-column ${
                        phone ? "px-2 " : "px-2"
                      }`}
                    >
                      <div
                        className={`d-flex align-items-center mr-2 ${
                          phone && "mb-2"
                        }`}
                      >
                        <p className="h6" htmlFor={field.value}>{`${
                          field.keyName
                        }: ${field.type ? "*" : ""}`}</p>
                      </div>
                      <div className="d-flex align-items-center w-100">
                        <div className="d-flex flex-column w-100">
                          {field.value == "regime" ? (
                            <TextField
                              select
                              size="small"
                              error={
                                formik.touched[field.value] &&
                                formik.errors[field.value]
                              }
                              label={field.keyName}
                              value={formik.values[field.value]}
                              name={field.value}
                              id={field.value}
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                              fullWidth
                              helperText={
                                formik.touched[field.value] &&
                                formik.errors[field.value] ? (
                                  <div>{formik.errors[field.value]}</div>
                                ) : null
                              }
                            >
                              {regime.map((reg) => (
                                <MenuItem key={reg.value} value={reg.value}>
                                  {reg.keyName}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : (
                            <TextField
                              error={
                                formik.touched[field.value] &&
                                formik.errors[field.value]
                              }
                              size="small"
                              label={field.keyName}
                              variant="outlined"
                              fullWidth
                              onChange={formik.handleChange}
                              onBlur={() => {
                                setErrorRfcRepStatus(false);
                                setErrorEmailStatus(false);
                                setErrorPhoneStatus(false);
                                setErrorCP(false);
                                setErrorRfcInvalidStatus(false);
                              }}
                              value={formik.values[field.value]}
                              name={field.value}
                              id={field.value}
                              helperText={
                                formik.touched[field.value] &&
                                formik.errors[field.value] ? (
                                  <div>{formik.errors[field.value]}</div>
                                ) : null ||
                                  (errorRfcRepStatus &&
                                    field.value == "rfc") ? (
                                  <div class="text-danger">
                                    El RFC esta asociado a otro cliente
                                  </div>
                                ) : null ||
                                  (errorRfcInvalidStatus &&
                                    field.value == "rfc") ? (
                                  <div class="text-danger">
                                    El rfc no tiene un formato válido. Debe
                                    tener el siguiente formato ORP830422DI6
                                  </div>
                                ) : null ||
                                  (errorEmailStatus &&
                                    field.value == "email") ? (
                                  <div class="text-danger">
                                    El email esta asociado a otro cliente
                                  </div>
                                ) : null ||
                                  (errorPhoneStatus &&
                                    field.value == "personalNumber") ? (
                                  <div class="text-danger">
                                    El teléfono esta asociado a otro cliente
                                  </div>
                                ) : null ||
                                  (errorCP && field.value == "postalCode") ? (
                                  <div class="text-danger">
                                    Ingresa un C.P. válido
                                  </div>
                                ) : null
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} className={`border bg-white p-3 r-15 mt-3`}>
              <div>
                <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
                  Datos almacén
                </p>
              </div>
              <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
                <Grid item xs md={12} lg={6}>
                  <div
                    key={"cedi"}
                    className={` d-flex mb-3 flex-column ${
                      phone ? "px-2 " : "px-2"
                    }`}
                  >
                    <div
                      className={`d-flex align-items-center mr-2 ${
                        phone && "mb-2"
                      }`}
                    >
                      <p
                        className="no-wrap h6"
                        htmlFor={"cedi"}
                      >{`${"CEDI"}: ${"*"}`}</p>
                    </div>
                    <div className="d-flex align-items-center w-100">
                      <TextField
                        select
                        size="small"
                        error={formik.touched.cedi && formik.errors.cedi}
                        label="CEDI"
                        value={cediValue}
                        name={"cedi"}
                        id={"cedi"}
                        onChange={(e) => {
                          setCediValue(e.target.value);
                        }}
                        fullWidth
                      >
                        {/***  <Search /> ***/}
                        {cedis.map((cedi) => (
                          <MenuItem key={cedi.id} value={cedi.cedi}>
                            {cedi.cedi}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Tooltip arrow placement="right" title="Agregar">
                        <IconButton
                          onClick={() => handleCediArray(cediValue, "add")}
                        >
                          <Add />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </Grid>

                <Grid item md={12} lg={6}>
                  <div
                    key={"warehouses"}
                    className={` d-flex mb-3 flex-column ${
                      phone ? "px-2 " : "px-2"
                    }`}
                  >
                    <div
                      className={`d-flex align-items-center mr-2 ${
                        phone && "mb-2"
                      }`}
                      style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                    >
                      <p
                        className="no-wrap h6"
                        htmlFor={"warehouses"}
                      >{`${"Sucursales"}: ${"*"}`}</p>
                    </div>
                    <div className="d-flex align-items-center w-100">
                      <TextField
                        select
                        size="small"
                        error={
                          formik.touched.warehouses && formik.errors.warehouses
                        }
                        label="Sucursal"
                        value={whsValue}
                        name={"warehouses"}
                        id={"warehouses"}
                        onChange={(e) => {
                          setWhsValue(e.target.value);
                        }}
                        fullWidth
                      >
                        {warehouses.map((warehouse) => (
                          <MenuItem
                            key={warehouse.id}
                            value={warehouse.sucursal}
                          >
                            {warehouse.sucursal}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Tooltip arrow placement="right" title="Agregar">
                        <IconButton
                          onClick={() => handleWarehouseArray(whsValue, "add")}
                        >
                          <Add />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </Grid>

                {(formik.touched.warehouses && formik.errors.warehouses) ||
                (formik.touched.cedi && formik.errors.cedi) ? (
                  <div className="xs-font text-danger w-100 text-center">
                    {formik.errors.warehouses}
                  </div>
                ) : null}
              </Grid>

              {isLoading ? (
                <div>
                  <LinearProgress sx={{ color: theme.palette.primary.main }} />
                </div>
              ) : (
                <div className="d-flex w-100 px-4 flex-wrap">
                  {whsStr.map((el, i) => (
                    <div
                      className="d-flex align-items-center mr-2 mb-1"
                      key={i}
                    >
                      <Chip
                        label={el}
                        sx={{
                          color: "white",
                          backgroundColor: theme.palette.primary.main,
                          "& .MuiChip-deleteIcon": {
                            color: "white",
                          },
                        }}
                        onDelete={() => {
                          handleWarehouseArray(el, "delete");
                        }}
                      />
                    </div>
                  ))}
                  {cediStr.map((el, i) => (
                    <div
                      className="d-flex align-items-center mr-2 mb-1"
                      key={i}
                    >
                      <Chip
                        label={el}
                        sx={{
                          color: "white",
                          backgroundColor: theme.palette.secondary.main,
                          "& .MuiChip-deleteIcon": {
                            color: "white",
                          },
                        }}
                        onDelete={() => {
                          handleCediArray(el, "delete");
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid item xs={12} className={`border bg-white p-3 r-15`}>
              <div>
                <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
                  Datos facturación
                </p>
              </div>

              <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
                {services.map((check) => (
                  <Grid key={check.keyName} item xs={6}>
                    <div
                      className={` d-flex mb-3 flex-column  ${
                        phone ? "px-2" : "px-2"
                      }`}
                    >
                      <div
                        className={`d-flex align-items-center mr-2 ${
                          phone && "mb-2"
                        }`}
                      >
                        <FormControlLabel
                          onChange={(e) => {
                            handleCheck(e);
                            formik.handleChange(e);
                          }}
                          name={check.check}
                          control={
                            check.value == "storage" ? (
                              <Checkbox name={check.value} checked={true} />
                            ) : (
                              <Checkbox checked={formik.values[check.check]} />
                            )
                          }
                          label={
                            check.value == "storage"
                              ? `${check.keyName}:*`
                              : `${check.keyName}:`
                          }
                        />
                      </div>

                      <div className="d-flex align-items-center w-100">
                        <div className="d-flex flex-column w-100">
                          {check.status ? (
                            <TextField
                              error={
                                formik.touched[check.value] &&
                                formik.errors[check.value]
                              }
                              helperText={
                                formik.touched[check.value] &&
                                formik.errors[check.value] ? (
                                  <div>{formik.errors[check.value]}</div>
                                ) : null
                              }
                              value={formik.values[check.value]}
                              name={check.value}
                              id={check.value}
                              onChange={formik.handleChange}
                              label="Precio"
                              variant="outlined"
                              size="small"
                              fullWidth
                            />
                          ) : (
                            <TextField
                              disabled
                              error={
                                formik.touched[check.value] &&
                                formik.errors[check.value]
                              }
                              helperText={
                                formik.touched[check.value] &&
                                formik.errors[check.value] ? (
                                  <div>{formik.errors[check.value]}</div>
                                ) : null
                              }
                              value=""
                              name={check.value}
                              id={check.value}
                              onChange={formik.handleChange}
                              label="Precio"
                              variant="outlined"
                              size="small"
                              fullWidth
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}

                {billing.map((field) => (
                  <Grid key={field.keyName} item xs={12}>
                    <div
                      key={field.keyName}
                      className={` d-flex mb-3 flex-column ${
                        phone ? "px-2" : "px-2"
                      }`}
                    >
                      <div className="d-flex align-items-center mr-2 mb-1">
                        <Typography
                          aria-owns={open ? "mouse-over-popover" : undefined}
                          aria-haspopup="true"
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                        >
                          {`${field.keyName}: ${field.type ? "*" : ""}`}
                        </Typography>
                        <Popover
                          id="mouse-over-popover"
                          sx={{
                            pointerEvents: "none",
                          }}
                          open={open}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                        >
                          <Typography sx={{ p: 1 }}>
                            Cantidad de días para pagar
                          </Typography>
                        </Popover>
                      </div>
                      <div className="d-flex align-items-center w-100">
                        <div className="d-flex flex-column w-100">
                          <TextField
                            error={
                              formik.touched[field.value] &&
                              formik.errors[field.value]
                            }
                            size="small"
                            label={field.keyName}
                            variant="outlined"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values[field.value]}
                            name={field.value}
                            id={field.value}
                            helperText={
                              formik.touched[field.value] &&
                              formik.errors[field.value] ? (
                                <div>{formik.errors[field.value]}</div>
                              ) : null
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item xs={12} className={`mt-3  border bg-white p-3 r-15`}>
              <div className="">
                <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
                  Documentos
                </p>

                <p className={`ds-blue-font h6`}>Subir documentos</p>
              </div>

              <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
                {documents.map((doc) => (
                  <Grid key={doc.keyName} item xs={6} md={12} lg={6}>
                    <div
                      className={` d-flex  flex-column ${
                        phone ? "px-2 " : "px-2"
                      }`}
                    >
                      <div className="d-flex align-items-center w-100 mt-2 ">
                        <div className="d-flex flex-column w-100 ">
                          <input
                            name={doc.value}
                            ref={doc.ref}
                            type="file"
                            accept="image/jpg,image/png,application/pdf"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleChangeImages(e);
                            }}
                          />
                          <label htmlFor={doc.value}>
                            <div className="small-font d-flex justify-content-between">
                              {doc.keyName}:
                            </div>
                            <UploadButton
                              text={
                                doc.name != ""
                                  ? doc.name.length >= 15
                                    ? `${doc.name.substring(0, 15)}...`
                                    : doc.name
                                  : doc.keyName
                              }
                              onClick={() => {
                                handleUpload(doc.ref);
                              }}
                              border={
                                doc.name != ""
                                  ? theme.palette.secondary.main
                                  : theme.palette.neutrals.inactive
                              }
                              font={
                                doc.name != ""
                                  ? theme.palette.secondary.main
                                  : theme.palette.neutrals.darkGray
                              }
                            />
                          </label>
                          {doc.name == "" ? null : (
                            <div
                              className="d-flex justify-content-between"
                              style={{ marginTop: -10 }}
                            >
                              <DeleteDocButton
                                value={doc.value}
                                onClick={handleRemoveImage}
                                text={"Eliminar"}
                              />
                              {location.state != undefined &&
                              typeof doc.url != "object" ? (
                                <SeeButton
                                  text={"Ver"}
                                  onClick={() => handleVisor(doc)}
                                />
                              ) : null}
                              <ChangeDocButton
                                onClick={() => {
                                  handleUpload(doc.ref);
                                }}
                                text={"Cambiar"}
                              />
                            </div>
                          )}
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div
          className={`${
            !phone ? "d-flex justify-content-end margin-right" : "mt-2"
          }`}
        >
          <div className={`${phone && "mb-2"}`}>
            <CancelButton
              text={"Cancelar"}
              onClick={() => {
                navigate("/administrador/clientes/");
              }}
              width={phone ? "100%" : ""}
            />
          </div>
          <AcceptButton
            text={location.state != undefined ? "Editar" : "Agregar"}
            type={"submit"}
            width={phone ? "100%" : ""}
            onClick={() => {
              const err = Object.keys(formik.errors);
              if (err.length) {
                const input = document.querySelector(`input[name=${err[0]}]`);
                input.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                  inline: "start",
                });
              }
            }}
          />
        </div>
      </form>

      <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalProductSuccess
            handleClose={handleClose}
            text={"Cliente"}
            edit={location.state != undefined ? "editData" : "none"}
          />
        </div>
      </Modal>

      <Modal open={openError} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalError
            text={`No se pudo agregar el cliente.`}
            handleClose={() => setOpenError(false)}
            error={errorField}
          />
        </div>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>

      {isPdf ? (
        <Modal open={openPdf} onClose={() => console.log("Cerrando")}>
          <div>
            <ModalPdfClientForm
              pdf={urlFile}
              handleClosePdf={() => setOpenPdf(false)}
              label={label}
            />
          </div>
        </Modal>
      ) : (
        <Modal open={openImg} onClose={() => console.log("Cerrando")}>
          <div>
            <ModalImgClientForm
              image={urlFile}
              handleClose={() => setOpenImg(false)}
            />
          </div>
        </Modal>
      )}
    </>
  );
};
