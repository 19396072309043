import dayjs from "dayjs";

export const getDateCleaned = (date) => {
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
};

export const getDateCleanedHyphen = (date) => {
  const m = (date.getMonth() + 1).toString();
  const d = date.getDate().toString();

  return `${date.getFullYear()}-${m.length > 1 ? m : 0 + m}-${
    d.length > 1 ? d : 0 + d
  }`;
};

export const getDayjsFormatted = (date) => {
  return dayjs(date).format("YYYY/MM/DD");
};

export const getDayjsHyphenFormatted = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};
