import React from "react";

import { Grid } from "@mui/material";
import { CardGraph } from "../Card/CardGraph";

const options = {
  chartArea: { width: "80%", height: "50%" },
  legend: "none",
  colors: ["#FF940D"],
};

const modalOption = {
  vAxis: {
    title: "Producto",
    titleTextStyle: {
      color: "black",
      bold: true,
    },
  },
  hAxis: {
    title: "Piezas",
    titleTextStyle: {
      color: "black",
      bold: true,
    },
  },
};

export const PiecesSoldByProductGraph = ({
  data,
  isLoading,
  client,
  filters,
}) => {
  return (
    <>
      <Grid item xs={4} sm={4} md={8}>
        <CardGraph
          title={"Piezas Vendidas Por Producto Y Monto"}
          type={"ColumnChart"}
          data={data}
          options={options}
          modalOption={modalOption}
          isLoading={isLoading}
          client={client}
          filters={filters}
        />
      </Grid>
    </>
  );
};
