import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { Collapse, Divider, List } from "@mui/material";
import React, { useState } from "react";
import { MoreVertRounded, KeyboardArrowDown } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { StyledList, StyledMenuItem, StyledPopover } from "./SidebarStyles";
import { SubMenu } from "./SubMenu";

export const OpenedMenuList = ({ section, sidebarOpen }) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [openSecondMenu, setOpenSecondMenu] = useState(false);
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverOpen = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <ListItem
      key={"item"}
      sx={{
        display: "block",
        py: 0.5,
        px: sidebarOpen ? 1 : 0.5,
      }}
      onMouseOver={(e) => {
        handlePopoverOpen(e);
      }}
      onMouseLeave={() => handlePopoverClose()}
    >
      {section.submenu ? (
        <>
          <StyledMenuItem
            onClick={() => {
              if (sidebarOpen) {
                setSubMenuOpen(!subMenuOpen);
              }
            }}
            open={sidebarOpen}
            selected={
              !sidebarOpen &&
              section.submenu.find(
                (el) => pathname.includes(el.link) && el.link != "/"
              )
                ? true
                : false
            }
          >
            <ListItemIcon
              sx={{
                color: "inherit",
              }}
            >
              {section.icon}
              {!sidebarOpen && <MoreVertRounded sx={{ width: 18 }} />}
            </ListItemIcon>
            <ListItemText
              primary={section.name}
              primaryTypographyProps={{
                fontSize: "16px",
                fontWeight: "bold",
              }}
              sx={{ opacity: sidebarOpen ? 1 : 0 }}
            />

            {sidebarOpen && (
              <KeyboardArrowDown
                sx={{
                  transform: subMenuOpen ? "rotate(-180deg)" : "rotate(0)",
                  transition: "0.5s",
                }}
              />
            )}
          </StyledMenuItem>
          <Collapse in={sidebarOpen && subMenuOpen}>
            <Divider />
            <StyledList className="p-0">
              {section.submenu.map((el, index) => (
                <>
                  <Link
                    to={!el.submenu && `/${el.link}`}
                    underline="none"
                    key={index}
                    onClick={() => setOpenSecondMenu((prev) => !prev)}
                  >
                    <StyledMenuItem
                      open={sidebarOpen}
                      selected={pathname.includes(el.link)}
                      sx={{
                        ml: 2,
                        my: 0.5,
                        py: 0,
                      }}
                    >
                      <ListItemIcon>{el.icon}</ListItemIcon>
                      <ListItemText primary={el.name} />
                      {el.submenu && (
                        <KeyboardArrowDown
                          sx={{
                            transform: openSecondMenu
                              ? "rotate(-180deg)"
                              : "rotate(0)",
                            transition: "0.5s",
                          }}
                        />
                      )}
                    </StyledMenuItem>
                  </Link>
                  {el.submenu && (
                    <Collapse in={openSecondMenu}>
                      <Divider />
                      {el.submenu.map((sm, i) => (
                        <Link to={`/${sm.link}`} underline="none" key={i}>
                          <StyledMenuItem
                            open={sidebarOpen}
                            //selected={pathname == `/${sm.link}`}
                            sx={{
                              ml: 6,
                              py: 0,
                              my: 0.5,
                            }}
                          >
                            <ListItemText
                              primary={sm.name}
                              primaryTypographyProps={{
                                fontSize: "15px",
                              }}
                            />
                          </StyledMenuItem>
                        </Link>
                      ))}
                    </Collapse>
                  )}
                </>
              ))}
            </StyledList>
          </Collapse>
          {!sidebarOpen && (
            <StyledPopover
              id="mouse-over-popover"
              open={popoverOpen}
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              onClose={handlePopoverClose}
              sx={{ pointerEvents: "none" }}
              PaperProps={{
                onMouseLeave: handlePopoverClose,
              }}
            >
              <SubMenu section={section} />
            </StyledPopover>
          )}
        </>
      ) : (
        <>
          <Link to={`/${section.link}`} underline="none">
            <StyledMenuItem
              open={sidebarOpen}
              selected={pathname.includes(section.link) ? true : false}
            >
              <ListItemIcon
                sx={{
                  color: "inherit",
                  minWidth: 0,
                }}
              >
                {section.icon}
              </ListItemIcon>
              <ListItemText
                primary={section.name}
                primaryTypographyProps={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
                sx={{ opacity: sidebarOpen ? 1 : 0 }}
              />
            </StyledMenuItem>
          </Link>
          {!sidebarOpen && (
            <StyledPopover
              id="mouse-over-popover"
              open={popoverOpen}
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              onClose={handlePopoverClose}
              sx={{ pointerEvents: "none" }}
              PaperProps={{
                onMouseLeave: handlePopoverClose,
              }}
            >
              <SubMenu section={section} />
            </StyledPopover>
          )}
        </>
      )}
    </ListItem>
  );
};
