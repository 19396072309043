import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
//import ReactExport from "react-export-excel";

export const ExcelDownloadMultipleSheet = (sheetName, info, client) => {
  const today = format(new Date(), "dd-MM-yyyy");
  const company = localStorage.getItem("razon_social");

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const wb = XLSX.utils.book_new();

  if (info.length > 0) {
    info.map((sheetData) => {
      const excelData = sheetData.data.map((data) => {
        let row = {};
        sheetData.headers.forEach((header) => {
          row = { ...row, [header.label]: data[header.key] };
        });
        return row;
      });
      const sheet = XLSX.utils.json_to_sheet(excelData);
      XLSX.utils.book_append_sheet(wb, sheet, sheetData.name);
    });

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blobData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      blobData,
      `BAP ${sheetName} ${client || company} ${today}` + fileExtension
    );
  }
};
