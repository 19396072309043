import React, { useState } from "react";

import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import { BorderColor, ViewList, ViewModule } from "@mui/icons-material";

export const ToggleView = ({
  view,
  setView,
  setStatus,
  entries,
  transfers,
  setIsTable,
  requestData,
}) => {
  const handleClick = () => {
    setView(!view);
    // console.log(view)
    if (view && entries) {
      setStatus("Por validar");
      setIsTable(false);
    } else if (!view && entries) {
      setStatus("Todos");
      setIsTable(true);
    }

    if (view && transfers) {
      setStatus("En proceso");
      setIsTable(false);
    } else if (!view && entries) {
      setStatus("Todos");
      setIsTable(true);
    }
  };

  return (
    <>
      <ToggleButtonGroup size="small">
        <ToggleButton
          sx={{
            borderRadius: "10px",
            height: 45,
            marginRight: "3px",
            border: "none",
          }}
          value="module"
          aria-label="module"
          onClick={handleClick}
          className={
            view ? "ds-yellow-background" : "lt-blue-background-transparent"
          }
        >
          <ViewList />
        </ToggleButton>

        <ToggleButton
          sx={{ borderRadius: "10px", height: 45, border: "none" }}
          value="list"
          aria-label="list"
          onClick={handleClick}
          className={
            !view ? "ds-yellow-background" : "lt-blue-background-transparent"
          }
        >
          <ViewModule />
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

// sx={[{
//   '&:hover': {
//     color: 'red',
//     backgroundColor: 'white',
//   },
// }, {
//   '&:active': {
//     color: 'red',
//     backgroundColor: 'white',
//     borderColor: 'white'
//   },
// },
// {
//   '&:focus': {
//     color: 'red',
//     backgroundColor: 'white',
//     borderColor: 'white'
//   },
// }
// ]}
