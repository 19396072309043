import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Modal,
  useMediaQuery,
  MenuItem,
  Grid,
  Backdrop,
  styled,
  TableCell,
  tableCellClasses,
  CircularProgress,
} from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";

import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";

import { ModalSuccess } from "../Modal/ModalSuccess";
import { ModalError } from "../Modal/ModalError";

import LoaderDS from "../../assets/LoaderDS.svg";
import { SalesB2BAPI } from "../../api/SalesB2B";
import { Wrapper } from "@googlemaps/react-wrapper";
import { googleMapsKey } from "../../utils/keys";
import { Map } from "../Map/Map";
import { Marker } from "../Map/Marker";
import { MapSearch } from "../Map/MapSearch";
import { Search } from "@mui/icons-material";

export const LocationBusinessForm = ({}) => {
  /************** STYLE VARIABLES *******************/

  const navigate = useNavigate();
  const phone = useMediaQuery("(max-width:480px)");

  /************** MODAL STATES *******************/
  const [isLoading, setIsLoading] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openSucces, setOpenSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [auxErrorText, setAuxErrorText] = useState("");

  /************** MAP STATES *******************/
  const routeLocation = useLocation().state;
  const business =
    routeLocation == undefined ? undefined : routeLocation.business;
  let location =
    routeLocation == undefined ? undefined : routeLocation.location;

  const [map, setMap] = useState();
  const [addressIsLoading, setAddressIsLoading] = useState(false);
  const [center, setCenter] = useState(business ? business.location : { lat: 19.42847, lng: -99.12766 });

  // locationRoute is used to get id client and id business from the previous page

  /************** ARRAYS(OPTIONS) FOR SELECT INPUTS *******************/
  //this arrays are mapped to render select input options}
  const [businessArr, setBusinessArr] = useState([]);

  const hours = [
    { value: "00:00" },
    { value: "00:30" },
    { value: "01:00" },
    { value: "01:30" },
    { value: "02:00" },
    { value: "02:30" },
    { value: "03:00" },
    { value: "03:30" },
    { value: "04:00" },
    { value: "04:30" },
    { value: "05:00" },
    { value: "05:30" },
    { value: "06:00" },
    { value: "06:30" },
    { value: "07:00" },
    { value: "07:30" },
    { value: "08:00" },
    { value: "08:30" },
    { value: "09:00" },
    { value: "09:30" },
    { value: "10:00" },
    { value: "10:30" },
    { value: "11:00" },
    { value: "11:30" },
    { value: "12:00" },
    { value: "12:30" },
    { value: "13:00" },
    { value: "13:30" },
    { value: "14:00" },
    { value: "14:30" },
    { value: "15:00" },
    { value: "15:30" },
    { value: "16:00" },
    { value: "16:30" },
    { value: "17:00" },
    { value: "17:30" },
    { value: "18:00" },
    { value: "18:30" },
    { value: "19:00" },
    { value: "19:30" },
    { value: "20:00" },
    { value: "20:30" },
    { value: "21:00" },
    { value: "21:30" },
    { value: "22:00" },
    { value: "22:30" },
    { value: "23:00" },
    { value: "23:30" },
    { value: "24:00" },
  ];

  /************** INPUTS FIELDS *******************/
  //This fielfs arrays are mapped to render inputs
  const businessFields = [
    {
      keyName: "Negocio",
      value: "business",
      required: true,
      type: "select",
      array: businessArr,
    },
  ];

  const locationFields = [
    {
      keyName: "Alias",
      value: "alias",
      required: true,
      type: "text",
      readOnly: false,
    },
    {
      keyName: "Código Postal",
      value: "postal_code",
      required: true,
      type: "text",
      type: "autocomplete",
      readOnly: false,
    },
    /* {
      keyName: "País",
      value: "country",
      required: false,
      type: "text",
      readOnly: true,
    }, */
    {
      keyName: "Estado",
      value: "state",
      required: false,
      type: "text",
      readOnly: true,
    },
    {
      keyName: "Municipio",
      value: "municipality",
      required: false,
      type: "text",
      readOnly: true,
    },
    {
      keyName: "Colonia",
      value: "neighborhood",
      required: true,
      type: "text",
      readOnly: false,
    },
    {
      keyName: "Calle",
      value: "street",
      required: true,
      type: "text",
      readOnly: false,
    },
    {
      keyName: "Número",
      value: "number",
      required: true,
      type: "text",
      readOnly: false,
    },
    {
      keyName: "Entre calle 1",
      value: "between_street_1",
      required: true,
      type: "text",
      readOnly: false,
    },
    {
      keyName: "Entre calle 2",
      value: "between_street_2",
      required: true,
      type: "text",
      readOnly: false,
    },
    {
      keyName: "Referencia",
      value: "address_reference",
      required: true,
      type: "text",
      readOnly: false,
    },
    {
      keyName: "Abre",
      value: "open",
      required: false,
      type: "select",
      array: hours,
    },
    {
      keyName: "Cierra",
      value: "close",
      required: false,
      type: "select",
      array: hours,
    },
  ];

  const contactFields = [
    { keyName: "Nombre(s)", value: "first_name", required: true, type: "text" },
    { keyName: "Apellidos", value: "last_name", required: true, type: "text" },
    { keyName: "Email", value: "email", required: true, type: "text" },
    {
      keyName: "Teléfono",
      value: "cell_phone",
      required: true,
      type: "text",
    },
  ];
  /************** API INSTANCE *******************/
  const api = new SalesB2BAPI();

  /************** REQUEST DATA *******************/
  const getBusinessList = async () => {
    const data = new FormData();
    data.append("status_business", true);
    const response = await api.getCleanBusiness(data);
    if (response.status == 200) {
      setBusinessArr(response.data);
    } else {
      console.log(response);
    }
  };

  const [cpIsLoading, setCpIsLoading] = useState(false);
  const [invalidCP, setInvalidCP] = useState(false);

  const getCPData = async (cp) => {
    setCpIsLoading(true);
    let data = new FormData();
    data.append("postal_code", cp);
    try {
      const response = await api.getCPDataEP(data);
      if (response.status == 200) {
        setInvalidCP(false);
        //formik.setFieldValue("country", response.data[0].country);
        formik.setFieldValue("state", response.data[0].state);
        formik.setFieldValue("municipality", response.data[0].city);
      } else {
        //formik.setFieldValue("country", "");
        formik.setFieldValue("state", "");
        formik.setFieldValue("municipality", "");
        setInvalidCP(true);
      }
      setCpIsLoading(false);
    } catch (error) {
      setCpIsLoading(false);
      // console.log(error);
    }
  };

  const getGeolocation = (address) => {
    setAddressIsLoading(true);
    var geocoder = new window.google.maps.Geocoder();

    geocoder
      .geocode({ address: address })
      .then((response) => {
        if (response.results[0]) {
          // console.log(response);
          //INPUT FIELDS ARE FILLED WITH THE LOCATION RESULT
          const neighborhood = response.results[0].address_components.find(
            (ad) =>
              ad.types.includes("neighborhood") ||
              ad.types.includes("sublocality")
          );
          const street = response.results[0].address_components.find((ad) =>
            ad.types.includes("route")
          );
          const number = response.results[0].address_components.find((ad) =>
            ad.types.includes("street_number")
          );
          const postalCode = response.results[0].address_components.find((ad) =>
            ad.types.includes("postal_code")
          );

          const country = response.results[0].address_components.find((ad) =>
            ad.types.includes("country")
          ).short_name;
          if (country == "MX") {
            formik.setFieldValue(
              "location",
              response.results[0].geometry.location
            );
            formik.setFieldValue(
              "neighborhood",
              neighborhood ? neighborhood.long_name : ""
            );
            formik.setFieldValue("street", street ? street.long_name : "");
            formik.setFieldValue("number", number ? number.long_name : "");
            formik.setFieldValue(
              "postal_code",
              postalCode ? postalCode.long_name : ""
            );
            if (postalCode) {
              getCPData(postalCode.long_name);
            }
          } else {
            setOpenError(true);
            setErrorText("Ups, no tenemos cobertura en esa región");
          }
        }
        setAddressIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setOpenError(true);
        setErrorText(
          "No se encontró ningún resultado, incluye más datos a tu búsqueda"
        );
        formik.setFieldValue("postal_code", "");
        formik.setFieldValue("neighborhood", "");
        formik.setFieldValue("street", "");
        formik.setFieldValue("number", "");
        formik.setFieldValue("municipality", "");
        formik.setFieldValue("state", "");
        //formik.setFieldValue("country", "");
        formik.setFieldValue("location", "");
        setAddressIsLoading(false);
      });
  };

  const getAddress = (location) => {
    setAddressIsLoading(true);
    var geocoder = new window.google.maps.Geocoder();

    geocoder
      .geocode({ location: location })
      .then((response) => {
        // console.log(response);
        if (response.results[0]) {
          //INPUT FIELDS ARE FILLED WITH THE LOCATION RESULT

          const neighborhood = response.results[0].address_components.find(
            (ad) =>
              ad.types.includes("neighborhood") ||
              ad.types.includes("sublocality")
          );
          const street = response.results[0].address_components.find((ad) =>
            ad.types.includes("route")
          );
          const number = response.results[0].address_components.find((ad) =>
            ad.types.includes("street_number")
          );
          const postalCode = response.results[0].address_components.find((ad) =>
            ad.types.includes("postal_code")
          );

          formik.setFieldValue(
            "location",
            response.results[0].geometry.location
          );
          formik.setFieldValue(
            "neighborhood",
            neighborhood ? neighborhood.long_name : ""
          );
          formik.setFieldValue("street", street ? street.long_name : "");
          formik.setFieldValue("number", number ? street.long_name : "");
          formik.setFieldValue(
            "postal_code",
            postalCode ? postalCode.long_name : ""
          );
          if (postalCode) {
            getCPData(postalCode.long_name);
          }
        }
        setAddressIsLoading(false);
      })
      .catch((e) => {
        setOpenError(true);
        setErrorText(
          "No se encontró ningún resultado, incluye más datos a tu búsqueda"
        );
        formik.setFieldValue("postal_code", "");
        formik.setFieldValue("neighborhood", "");
        formik.setFieldValue("street", "");
        formik.setFieldValue("number", "");
        formik.setFieldValue("municipality", "");
        formik.setFieldValue("state", "");
        //formik.setFieldValue("country", "");
        formik.setFieldValue("location", "");
        setAddressIsLoading(false);
        // console.log(e);
      });
  };

  /*  const getGeolocation = (location) => {
    var geocoder = new window.google.maps.Geocoder();

    geocoder
      .geocode({ location: location })
      .then((response) => {
        if (response.results[0]) {
          formik.setFieldValue(
            "location",
            response.results[0].geometry.location
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }; */

  /************** USE EFFECT *******************/
  // IF STATE LOCATION HAS DATA, THEN DE ACTION MUST BE EDIT
  useEffect(() => {
    getBusinessList();
  }, []);

  /************** HANDLE CLOSE SUCESS *******************/

  const handleCloseSuccess = (path) => {
    if (path == "nuevo") {
      setOpenSuccess(false);
      formik.resetForm();
      navigate(`/cliente/ventas/B2B/negocios/agregar_ubicacion`, {
        state: { business: business, location: undefined },
      });
    } else {
      navigate(`/cliente/ventas/B2B/negocios/`);
      setOpenSuccess(false);
    }
  };

  /************** SUBMIT FUNCTIONS*******************/
  const editLocation = async (location) => {
    setIsLoading(true);
    const response = await api.editLocationEP(location);
    if (response.status == 200) {
      setOpenSuccess(true);
      setIsLoading(false);
    } else {
      // console.log(response);
      setErrorText("No se pudo editar la ubicación");
      setAuxErrorText(response.response.data.detail);
      setOpenError(true);
      setIsLoading(false);
    }
  };

  const createLocation = async (data) => {
    setIsLoading(true);
    const response = await api.createLocationEP(data);
    if (response.status == 200) {
      setOpenSuccess(true);
      setIsLoading(false);
    } else {
      // console.log(response);
      setErrorText("No se pudo crear la ubicación");
      setAuxErrorText(response.response.data.detail);
      setOpenError(true);
      setIsLoading(false);
    }
  };

  /************** SUBMIT FUNCTIONS*******************/

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: location
      ? {
          //business
          business: business.id,

          //location
          search: "",
          location: location.location,
          alias: location.alias,
          postal_code: location.postal_code,
          //country: location.country,
          state: location.state,
          municipality: location.municipality,
          neighborhood: location.neighborhood,
          street: location.street,
          number: location.number,
          between_street_1: location.between_street_1,
          between_street_2: location.between_street_2,
          address_reference: location.address_reference,

          //contact
          first_name: location.first_name,
          last_name: location.last_name,
          email: location.email,
          cell_phone: location.cell_phone,
          open: location.open.substring(0, 5),
          close: location.close.substring(0, 5),
        }
      : {
          //business
          business: business ? business.id : "",

          //location
          search: "",
          location: "",
          alias: "",
          postal_code: "",
          //country: "",
          state: "",
          municipality: "",
          neighborhood: "",
          street: "",
          number: "",
          between_street_1: "",
          between_street_2: "",
          address_reference: "",

          //contact
          first_name: "",
          last_name: "",
          email: "",
          cell_phone: "",
          open: "",
          close: "",
        },
    validationSchema: Yup.object({
      //business
      business: Yup.string().required("Campo Obligatorio"),

      //location
      location: Yup.object().required("Seleccione una ubicación"),
      /* search: Yup.string().when("location", {
        is: "",
        then: (schema) => schema.required("Seleccione una ubicación"),
      }), */
      alias: Yup.string()
        .required("Campo Obligatorio")
        .max(100, "Demasiado largo")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Solo alfanuméricos y espacios"
        ),
      postal_code: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\d+$/, "Deben ser números sin espacios")
        .min(3, "Demasiado corto")
        .max(10, "Demasiado largo"),
      neighborhood: Yup.string()
        .required("Campo Obligatorio")
        .max(100, "Demasiado largo"),
      street: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s-\.]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Demasiado largo"),
      number: Yup.string()
        .required("Campo Obligatorio")
        .max(15, "Solo diez carácteres")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        ),
      between_street_1: Yup.string()
        .required("Campo Obligatorio")
        .max(100, "Demasiado largo"),
      between_street_2: Yup.string()
        .required("Campo Obligatorio")
        .max(100, "Demasiado largo"),
      address_reference: Yup.string()
        .required("Campo Obligatorio")
        .max(100, "Demasiado largo"),
      //contact
      first_name: Yup.string()
        .required("Campo obligatorio")
        .matches(/^[a-zA-ZÑÀ-ÿ\s]*$/, "Solo letras y acentos")
        .max(100, "Demasiado largo"),
      last_name: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^[a-zA-ZÑÀ-ÿ\s]*$/, "Solo letras y acentos")
        .max(100, "Demasiado largo"),
      email: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Formato incorrecto"
        )
        .max(100, "Demasiado largo"),
      cell_phone: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\d+$/, "Deben ser números sin espacios")
        .min(10, "Demasiado corto")
        .max(12, "Demasiado largo"),
      open: Yup.string().required("Campo obligatorio"),
      close: Yup.string().required("Campo obligatorio"),
    }),
    onSubmit: (values) => {
      let data;
      if (location) {
        data = { id: location.id, ...values };
        editLocation(data);
      } else {
        data = { ...values };
        createLocation(data);
      }
    },
  });
  //if business is changed, change the map center
  useEffect(() => {
    if (location == undefined) {
      if (businessArr.length > 0 && formik.values.business != "") {
        setCenter(
          businessArr.find((el) => el.id == formik.values.business).location
        );
      }
    }
  }, [formik.values.business]);

  return (
    <>
      <form>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          rowSpacing={2}
        >
          {" "}
          {/*---Contenedor padre---*/}
          <Grid item xs={12} sm={12} md={6}>
            {" "}
            {/*------------BUSINESS---------------*/}
            <Grid item xs={12} className={`border bg-white r-15 p-3`}>
              <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>Negocio</p>
              <div className="d-flex flex-wrap">
                {businessFields.map((field, index) => (
                  <div
                    key={index}
                    className={` d-flex flex-column w-100 ${
                      phone ? "mb-1" : "mb-3  px-2"
                    }`}
                  >
                    <p
                      className={`mr-2 mb-1 ${phone ? "xs-font" : ""}`}
                      htmlFor={field.value}
                    >{`${field.keyName}: ${field.required ? "*" : ""}`}</p>
                    <TextField
                      select
                      size="small"
                      error={
                        formik.touched[field.value] &&
                        formik.errors[field.value]
                      }
                      label={field.keyName}
                      value={formik.values[field.value]}
                      name={field.value}
                      id={field.value}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      fullWidth
                      helperText={
                        formik.touched[field.value] &&
                        formik.errors[field.value]
                          ? formik.errors[field.value]
                          : null
                      }
                      disabled={location ? true : false}
                      InputProps={phone && { style: { fontSize: 13 } }}
                      InputLabelProps={phone && { style: { fontSize: 13 } }}
                    >
                      {field.array.map((option) => (
                        <MenuItem key={option.value} value={option.id}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                ))}
              </div>
            </Grid>
            {/*------------GEOLOCATION---------------*/}
            <Grid item xs={12} className={`border bg-white p-3 mt-3 r-15`}>
              <p className={`ds-blue-font mr-2 ${phone ? "h6" : "h5"}`}>
                Geolocalización BRO
              </p>
              <div className={`w-100 ${phone ? "mb-1" : "mb-3 px-2"}`}>
                <p
                  className={`mr-2 mb-1 ${phone ? "xs-font" : ""}`}
                  htmlFor={"search"}
                >{`Lugar o dirección:*`}</p>
                <TextField
                  size="small"
                  fullWidth
                  label={"Lugar o dirección"}
                  onChange={formik.handleChange}
                  onKeyPress={(e) => {
                    if (e.key == "Enter") {
                      e.preventDefault();
                      getGeolocation(e.target.value);
                    }
                  }}
                  value={formik.values.search}
                  name={"search"}
                  inputProps={phone && { style: { fontSize: 13 } }}
                  InputProps={{
                    endAdornment: (
                      <Search
                        className="ds-yellow-font mr-2 cursor-pointer"
                        onClick={() => getGeolocation(formik.values.search)}
                      />
                    ),
                  }}
                  error={formik.errors.location && formik.touched.search}
                  helperText={
                    formik.errors.location &&
                    formik.touched.search &&
                    formik.errors.location
                  }
                />
                <p className="m-0 xs-font font-weight-bold text-end">
                  Nota: arrastre el marcador si es necesario.{" "}
                </p>
                <Wrapper
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                  libraries={["places"]}
                >
                  <Map
                    setMap={setMap}
                    map={map}
                    zoom={15}
                    center={center}
                    style={{
                      height: "25vh",
                      width: "100%",
                    }}
                    multipleMarkers={false}
                  >
                    {(location || formik.values.location) && (
                      <Marker
                        position={formik.values.location}
                        draggable={true}
                        getAddress={getAddress}
                      />
                    )}
                  </Map>
                </Wrapper>
              </div>
              {/* )} */}
            </Grid>
            {/*------------CONTACT INPUTS---------------*/}
            <Grid item xs={12} className={`border bg-white p-3 mt-3 r-15`}>
              {" "}
              {/*---Altura de 100 para que ocupe verticalmente---*/}
              <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
                Datos de contacto
              </p>
              <div className="d-flex flex-wrap">
                {contactFields.map((field, index) => (
                  <div
                    key={index}
                    className={` d-flex flex-column ${
                      phone ? "w-100 mb-1" : "w-50 mb-3 px-2"
                    }`}
                  >
                    <p
                      className={`mr-2 mb-1 ${phone ? "xs-font" : ""}`}
                      htmlFor={field.value}
                    >{`${field.keyName}: ${field.required ? "*" : ""}`}</p>
                    {field.type == "select" ? (
                      <TextField
                        select
                        size="small"
                        error={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                        }
                        label={field.keyName}
                        value={formik.values[field.value]}
                        name={field.value}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        fullWidth
                        InputLabelProps={phone && { style: { fontSize: 13 } }}
                        InputProps={phone && { style: { fontSize: 13 } }}
                        helperText={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                            ? formik.errors[field.value]
                            : null
                        }
                      >
                        {field.array.map((el) => (
                          <MenuItem key={el.value} value={el.value}>
                            {el.keyName}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        error={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                        }
                        size="small"
                        label={field.keyName}
                        variant="outlined"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values[field.value]}
                        name={field.value}
                        InputLabelProps={phone && { style: { fontSize: 13 } }}
                        InputProps={phone && { style: { fontSize: 13 } }}
                        helperText={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                            ? formik.errors[field.value]
                            : null
                        }
                      />
                    )}
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {/*------------LOCATION INPUTS---------------*/}
            <Grid item xs={12} className={`border bg-white p-3 r-15`}>
              {" "}
              {/*---Altura de 100 para que ocupe verticalmente---*/}
              <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
                Datos de ubicación
              </p>
              <div className="d-flex flex-wrap">
                {locationFields.map((field, index) => (
                  <div
                    key={index}
                    className={` d-flex ${
                      phone ? "mb-1" : "mb-3 px-2"
                    } flex-column ${phone ? "w-100" : "w-50"} `}
                  >
                    <div className="d-flex">
                      <p
                        className={`mr-2 mb-1 ${phone ? "xs-font" : ""}`}
                        htmlFor={field.value}
                      >{`${field.keyName}: ${field.required ? "*" : ""}`}</p>
                      {(cpIsLoading && //field.value == "country" ||
                        (field.value == "state" ||
                          field.value == "municipality")) ||
                      (addressIsLoading &&
                        (field.value == "postal_code" ||
                          //field.value == "country" ||
                          field.value == "state" ||
                          field.value == "municipality" ||
                          field.value == "neighborhood" ||
                          field.value == "street" ||
                          field.value == "number")) ? (
                        <CircularProgress
                          size={15}
                          className="ml-1 ds-yellow-font"
                        />
                      ) : null}{" "}
                    </div>
                    {field.type == "select" ? (
                      <TextField
                        select
                        size="small"
                        error={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                        }
                        label={field.keyName}
                        value={formik.values[field.value]}
                        name={field.value}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        fullWidth
                        helperText={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                            ? formik.errors[field.value]
                            : null
                        }
                        InputLabelProps={phone && { style: { fontSize: 13 } }}
                        InputProps={phone && { style: { fontSize: 13 } }}
                      >
                        {field.array.map((el) => (
                          <MenuItem key={el.value} value={el.value}>
                            {el.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : field.value == "postal_code" ? (
                      <TextField
                        error={
                          invalidCP ||
                          (formik.touched[field.value] &&
                            formik.errors[field.value])
                        }
                        size="small"
                        label={field.keyName}
                        variant="outlined"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values[field.value]}
                        name={field.value}
                        id={field.value}
                        InputProps={phone && { style: { fontSize: 13 } }}
                        InputLabelProps={phone && { style: { fontSize: 13 } }}
                        helperText={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                            ? formik.errors[field.value]
                            : invalidCP
                            ? "El CP no existe"
                            : null
                        }
                        onBlur={(e) => {
                          if (e.target.value != "") {
                            getCPData(e.target.value);
                          }
                        }}
                      />
                    ) : (
                      <TextField
                        error={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                        }
                        size="small"
                        label={field.keyName}
                        variant="outlined"
                        fullWidth
                        onChange={formik.handleChange}
                        value={
                          (cpIsLoading && //field.value == "country" ||
                            (field.value == "state" ||
                              field.value == "municipality")) ||
                          (addressIsLoading &&
                            (field.value == "postal_code" ||
                              //field.value == "country" ||
                              field.value == "state" ||
                              field.value == "municipality" ||
                              field.value == "neighborhood" ||
                              field.value == "street" ||
                              field.value == "number"))
                            ? "Cargando... "
                            : formik.values[field.value]
                        }
                        name={field.value}
                        helperText={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                            ? formik.errors[field.value]
                            : null
                        }
                        InputProps={{ readOnly: field.readOnly }}
                        InputLabelProps={phone && { style: { fontSize: 13 } }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </Grid>
            <div
              className={`d-flex flex-wrap justify-content-center mt-3 w-100`}
            >
              <div className={`${phone && "mb-2 w-100"}`}>
                <CancelButton
                  text={"Cancelar"}
                  onClick={() => navigate(`/cliente/ventas/B2B/negocios/`)}
                  width={phone ? "100%" : ""}
                />
              </div>
              <AcceptButton
                text={location ? "Editar" : "Agregar"}
                width={phone ? "100%" : ""}
                onClick={formik.handleSubmit}
              />
            </div>
          </Grid>
        </Grid>
      </form>
      <Modal open={openSucces} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalSuccess
            handleClose={handleCloseSuccess}
            text={`La ubicación fue ${
              location ? "editada" : "agregada"
            } con éxito`}
            auxText={"¿Deseas agregar otra ubicación?"}
            action={"add"}
          />
        </div>
      </Modal>

      <Modal open={openError} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalError
            text={errorText}
            handleClose={() => setOpenError(false)}
            error={auxErrorText}
          />
        </div>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
