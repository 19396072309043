import { Close, LogoutOutlined, SettingsOutlined } from '@mui/icons-material';
import { IconButton, List } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthProvider';
import { theme } from '../../utils/theme';

export const ModalMenuAccount = ({ handleClose, user }) => {

    const { logoutFunction } = useAuthContext();

    return (
        <div className=''>
            <div className="d-flex justify-content-between align-items-center">
                {/* <Typography sx={{ fontSize: 17 }} className='ds-blue-font capitalize'>{razonSocial != "undefined" ? razonSocial : name}</Typography> */}
                <div></div>
                <IconButton onClick={handleClose}>
                    <Close fontSize="small" />
                </IconButton>
            </div>

            <div className='pr-4'>
                <List className=''>

                    <div className="d-flex align-items-center mini-menu cursor-pointer w-100">
                      
                      <Link to={`/${user}/cuenta/`} underline="none"
                            onClick={handleClose}
                            style={{ color: theme.palette.secondary.main  }}
                        >
                            <IconButton>
                                <SettingsOutlined sx={{ color: theme.palette.secondary.main  }} />
                            </IconButton>
                            Configurar cuenta
                        </Link>
                    </div>

                    <div className={`d-flex`} onClick={() => {
                        logoutFunction();
                        handleClose()
                    }}>
                        <div className="d-flex align-items-center ds-blue-font mini-menu cursor-pointer w-100">

                            <IconButton sx={{ color: theme.palette.secondary.main  }}>
                                <LogoutOutlined />
                            </IconButton>
                            <span>Cerrar sesión</span>
                        </div>
                    </div>

                </List>
            </div>


        </div>
    )
}
