import React from 'react';
import * as XLSX from 'xlsx';
import { DownloadFileButton } from '../Buttons/DownloadFileButton';

export const ExcelGenerator = ({UserName}) => {
  const data = [
    ["sku", "piezas", "lote", "fecha_caducidad"],
    ["AXO-AM05", 12, "lote02934", "2022/10/01"],
    ["AXO-AM06", 12, "lote234", "2022/10/01"]
  ];
  const fecha = new Date();
  const fechaFormateada = fecha.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
  const finalName ="carga_masiva_"+ UserName+"_"+fechaFormateada.toString()+".xlsx"
  const generateExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, finalName);
  };

  return (
    <div>
      {/* <button onClick={generateExcel}>Generar Excel</button> */}
      <DownloadFileButton 
      onClick={generateExcel}
      text={"Template"}
      />
    </div>
  );
};

