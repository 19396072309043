import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import { BreadcrumbsMenu } from "../components/Breadcrumb/BreadcrumbsMenu";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ProfileIcon from "../assets/SVGIcons/Profile.svg";

import { AddPicker } from "./pages/AddPicker";
import { HistoricalSalesHeadFieldManager } from "./pages/HistoricalSalesHeadFieldManager";
import { AccountCardsHeadFieldManager } from "./pages/AccountCardsHeadFieldManager";
import { ActiveSalesHeadFieldManager } from "./pages/ActiveSalesHeadFieldManager";
import { EditPasswordHeadFieldManager } from "./pages/EditPasswordHeadFieldManager";
import { Pickers } from "./pages/Pickers";

import {
  Grid,
  IconButton,
  styled,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { SnackbarProvider, useSnackbar } from "notistack";
import { Sidebar } from "../components/Sidebar/Sidebar";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, DrawerHeader, DrawerStyled } from "../utils/menuStyles";
import { sectionsHeadFieldManager } from "../utils/menuSections";
import SnackbarAudio from "../sounds/SnackbarAudioClient.mp3";
import { getSocketHost } from "../helpers/getHost";
import { Close, KeyboardArrowDownRounded } from "@mui/icons-material";
import { theme } from "../utils/theme";

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-contentRoot {
    display: flex;
    min-width: 50vw;
    flex-wrap: nowrap;
  }
`;

const LayoutHeadFieldManager = ({ variant, setVariant }) => {
  const { pathname } = useLocation();
  const tablet = useMediaQuery("(max-width:600px)");
  const phone = useMediaQuery("(max-width:600px)");
  const breakpointBC = useMediaQuery("(max-width:630px)");
  const headFieldManager = localStorage.getItem("nombre");
  const [isLoading, setIsLoading] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openPO = Boolean(anchorEl);
  const id = openPO ? "simple-popover" : undefined;

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleDrawerToggle = () => {
    tablet && setMobileOpen(!mobileOpen);
    setOpen(true);
  };

  const nameSection = sectionsHeadFieldManager.find(
    (el) => `/${el.link}/` == pathname
  );

  const [socketMsg, setSocketMsg] = useState("");
  const [socketData, setSocketData] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar(); /* Snackbar */

  const socketUrl = getSocketHost();

  useEffect(() => {
    const token = `${localStorage.getItem("token")}`;
    const socket = new WebSocket(`${socketUrl}/wss/hfm/${token}`);

    socket.onopen = () => {
      // console.log("connection");
    };

    socket.onclose = () => {
      // console.log('connection closed')
    };

    socket.onmessage = ({ data }) => {
      console.log("websocket data:" + data);
      const response = JSON.parse(data);
      switch (response.event) {
        case 1:
          {
            /* NUEVA VENTA */
          }
          setVariant("success");
          setSocketMsg(
            `Se generó una venta en ${response.origin}. Código de pedido: ${response.code}`
          );
          break;
        case 4:
          {
            /* ACEPTACIÓN DE LA VENTA, ASIGNACIÓN DE PICKER Y CAMBIO DE STATUS A PICKING*/
          }
          setSocketData(response);
          break;

        case 5:
          {
            /* ACTUALIZACIÓN DE EMBALAJE Y CAMBIO DE STATUS A PACKING */
          }
          setSocketData(response);
          break;
      }
    };

    return () => socket.close();
  }, []);

  const snackbarAudio = new Audio(SnackbarAudio);

  const action = (id) => (
    <div>
      <IconButton
        onClick={() => {
          closeSnackbar(id);
          snackbarAudio.pause();
        }}
      >
        <Close sx={{ color: "#FFF", fontSize: 15 }} />
      </IconButton>
    </div>
  );

  useEffect(() => {
    if (socketMsg != "") {
      snackbarAudio.play();
      enqueueSnackbar(
        <div className="d-flex w-100 justify-content-between align-items-center">
          <Typography>{socketMsg}</Typography>
        </div>,
        { autoHideDuration: 6000, variant: `${variant}`, action }
      );
    }
  }, [socketMsg]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <AppBar
          position="fixed"
          open={tablet ? false : open}
          style={{ width: !open && !tablet && "calc(100% - 65px)" }}
          elevation={0}
        >
          <Toolbar
            sx={{
              bgcolor: "white",
              color: theme.palette.primary.main,
              height: phone ? "65px" : "68px",
            }}
            className={`w-100 d-flex align-items-start align-items-center`}
          >
            {tablet && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            )}

            <div
              className={`w-100 ${phone && "d-flex justify-content-center"}`}
            >
              <div
                className={`w-100 d-flex mt-1 ${
                  phone
                    ? "flex-column align-items-center"
                    : "align-items-center justify-content-between"
                }`}
              >
                <div className="d-flex flex-column">
                  <Typography
                    variant="h5"
                    noWrap
                    component="div"
                    className="ds-blue-font font-weight-bold capitalize"
                  >
                    {nameSection != undefined && nameSection.section}
                  </Typography>
                  {breakpointBC ? null : (
                    <BreadcrumbsMenu
                      sections={sectionsHeadFieldManager}
                      principal={"activas"}
                      rol={"headFieldManager"}
                    />
                  )}
                </div>

                {/*----------------------- NOTIFICACIONES NAME Y PROFILEICON -----------------------*/}
                <div
                  className={`d-flex ${
                    phone
                      ? "w-100 justify-content-center"
                      : "align-items-center justify-content-between"
                  } `}
                >
                  {phone ? (
                    <div className="pr-4 mr-4">
                      <Tooltip title={headFieldManager} placement="top" arrow>
                        <Typography
                          sx={{ fontSize: 20 }}
                          className="ds-blue-font capitalize"
                        >
                          {headFieldManager != null
                            ? headFieldManager.length >= 13
                              ? `${headFieldManager.substring(0, 13)}...`
                              : headFieldManager
                            : "Sin usuario"}
                        </Typography>
                      </Tooltip>
                    </div>
                  ) : (
                    <Typography
                      sx={{ fontSize: 20, fontWeight: 600 }}
                      className="ds-blue-font capitalize"
                    >
                      {headFieldManager != null
                        ? headFieldManager
                        : "Sin usuario"}
                    </Typography>
                  )}
                  <IconButton onClick={(e) => handleClick(e)}>
                    <KeyboardArrowDownRounded color={"primary"} />
                  </IconButton>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>

        {/* -------------------------------------------SIDEBAR------------------------------------------ */}
        <Sidebar
          DrawerStyled={DrawerStyled}
          open={open}
          handleDrawer={handleDrawer}
          isLoading={isLoading}
          DrawerHeader={DrawerHeader}
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
          rol={8}
        />

        {/* ------------------------------------------------------------------------------------------- */}

        <div className="d-flex justify-content-center w-100">
          <div
            className=""
            style={{
              width: phone
                ? "100%"
                : open && !phone
                ? "calc(100vw - 235px)"
                : "calc(100vw - 80px)",
            }}
          >
            <Grid container sx={{ marginTop: !tablet ? 8.3 : 7.6 }}>
              <DrawerHeader />

              <Routes>
                {/* Todas las rutas inexistentes te redirigen a ventas */}
                <Route path="*" element={<Navigate to="activas/" replace />} />

                <Route
                  path="activas/"
                  element={
                    <ActiveSalesHeadFieldManager socketReload={socketMsg} />
                  }
                />
                <Route
                  path="ventas/"
                  element={<HistoricalSalesHeadFieldManager />}
                />
                <Route
                  path="pickers/"
                  element={<Pickers socketReload={socketData} />}
                />
                <Route path="pickers/nuevo" element={<AddPicker />} />
                <Route
                  path="cuenta/"
                  element={<AccountCardsHeadFieldManager />}
                />
                <Route
                  path="cuenta/password/"
                  element={<EditPasswordHeadFieldManager />}
                />
              </Routes>
            </Grid>
          </div>
        </div>
      </Box>
    </>
  );
};

export default function IntegrationNotistack({ variant, setVariant }) {
  return (
    <StyledSnackbarProvider maxSnack={5} variant={`${variant}`}>
      <LayoutHeadFieldManager setVariant={setVariant} variant={variant} />
    </StyledSnackbarProvider>
  );
}
