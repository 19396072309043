import React, { useState, useEffect } from "react";

import { Popover, useMediaQuery } from "@mui/material";

import { ToggleView } from "../../components/ToggleView";
import { Search } from "../../components/Search";
import { RefreshButton } from "../../components/RefreshButton";

import { roles } from "../../helpers/enums";

import { DownloadButtonExcel } from "../../components/Excel/DownloadButtonExcel";
import { transferHeaders } from "../../components/headers";
import { WarehouseAPI } from "../../api/Warehouse";

import { TableTransfer } from "../../components/Tables/TableTransfer";
import { SkeletonTableTransfer } from "../../components/Skeleton/SkeletonTableTransfer";
import { ListViewTransfer } from "../../components/ListViewTransfer";
import { ModalFilters } from "../../components/Modal/ModalFilters";
import { TransferAPI } from "../../api/Transfer";
import { useMemo } from "react";
import { getDayjsFormatted } from "../../helpers/getDateCleaned";

export const InventoryTransfersList = () => {
  const [view, setView] = useState(true);
  const [listIndex, setListIndex] = useState(0);
  const phone = useMediaQuery("(max-width:700px)");

  //DATA STATES
  //------------------------------------------------

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0); /*Estado p/paginacion*/
  const [total, setTotal] = useState(); /*Total de paginas enviado desde api*/
  const [count, setCount] = useState(); /*Paginacion listas */
  const [search, setSearch] = useState("");
  const [ready, setReady] = useState(false);

  //------------------------------------------------

  //ERRORES
  //------------------------------------------------

  const [errors, setErrors] = useState({
    error: false,
    errorNullStart: false,
    errorNullEnd: false,
  });

  //------------------------------------------------

  //MODAL STATES
  //------------------------------------------------

  const [openFilter, setOpenFilter] = useState(false);
  const [invisible, setInvisible] = useState(false);

  //------------------------------------------------

  //LOADERS STATES
  //------------------------------------------------

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  //------------------------------------------------

  //FILTERS STATES
  //------------------------------------------------

  const [isTable, setIsTable] = useState(null);

  const [status, setStatus] = useState("Todos");

  const [cedi, setCedi] = useState("Todos");
  const [cedis, setCedis] = useState([]);

  const [branch, setBranch] = useState("Todas");
  const [branches, setBranches] = useState([]);

  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

  const [startFilterDate, setStartFilterDate] = useState(firstDay);
  const [endFilterDate, setEndFilterDate] = useState(today);

  const [prevData, setPrevData] = useState({
    startFilterDate: firstDay,
    endFilterDate: today,
    cedi: "Todos",
    branch: "Todas",
    status: "Todos",
  });

  //------------------------------------------------

  //EFFECTS
  //------------------------------------------------
  //------------------------------------------------

  useEffect(() => {
    setPage(0);
    setSearch("");
    requestFilterData();
    setReady(true);
  }, []);

  useEffect(() => {
    RequestData();
  }, [isTable]);

  useEffect(() => {
    if (ready) {
      RequestData();
    }
  }, [ready]);

  useEffect(() => {
    if (view) {
      setStatus("Todos");
      setReady(true);
    }
  }, [view]);

  //EXCEL STATES AND DATA
  //------------------------------------------------

  const [dataExcel, setDataExcel] = useState([]);

  const statusObj = [
    { id: 0, status: "Todos" },
    { id: 1, status: "En proceso" },
    { id: 2, status: "Completada" },
    { id: 3, status: "Rechazada" },
  ];

  //------------------------------------------------

  //DATA REQUEST
  //------------------------------------------------

  const RequestData = () => {
    GetTransferList();
    setReady(false);
  };

  const requestFilterData = () => {
    getBranchesList();
    getCedisList();
  };

  const handleRefresh = () => {
    setIsLoading(true);
    GetTransferList();
  };

  //APIS

  const warehouseAPI = useMemo(() => new WarehouseAPI(), []);
  const transferAPI = useMemo(() => new TransferAPI(), []);

  const getCedisList = async () => {
    let data = new FormData();

    const response = await warehouseAPI.cedisList(data);
    if (response.status === 200) {
      setCedis([{ id: 0, cedi: "Todos" }, ...response.data.cedis]);
    } else {
      console.log(response);
    }
  };

  const getBranchesList = async () => {
    let data = new FormData();

    const response = await warehouseAPI.getBranchesList(data);
    if (response.status === 200) {
      setBranches([{ id: 0, sucursal: "Todas" }, ...response.data.branches]);
    } else {
      console.log(response);
    }
  };

  const GetTransfersExcel = async () => {
    setIsLoadingExcel(true);
    let data = new FormData();
    data.append("page", page + 1);

    if (search !== "") {
      data.append("search", search);
    }
    if (startFilterDate != null) {
      data.append("startDate", getDayjsFormatted(startFilterDate));
    }
    if (endFilterDate != null) {
      data.append("endDate", getDayjsFormatted(endFilterDate));
    }
    if (status !== "Todos") {
      const auxStatus = statusObj.find((el) => el.status == status);
      data.append("status", auxStatus.id);
    }
    if (cedi !== "Todos") {
      const auxCedi = cedis.find((el) => el.cedi == cedi);
      data.append("cedi", auxCedi.id);
    }
    if (branch !== "Todas") {
      const auxBranch = branches.find((el) => el.sucursal == branch);
      data.append("branch", auxBranch.id);
    }

    const response = await transferAPI.transfersExcelEP(data);
    if (response.status === 200) {
      const aux = response.data.transfers.map((el) => ({
        id: el.id,
        arrive:
          el.arrive_branch != null
            ? el.arrive_branch
            : el.arrive_cedi != null
            ? el.arrive_cedi
            : "N/A",
        arrive_date: el.arrive_date != null ? el.arrive_date : "N/A",
        amount: el.amount,
        comments: el.comments != null ? el.comments : "N/A",
        departure:
          el.departure_branch != null
            ? el.departure_branch
            : el.departure_cedi != null
            ? el.departure_cedi
            : "N/A",
        departure_date: el.departure_date != null ? el.departure_date : "N/A",
        inventory_transfer: el.inventory_transfer,
        product: el.product,
        sku: el.sku,
        status: el.status,
      }));
      setDataExcel(aux);
      setIsLoadingExcel(false);
      return aux;
    } else {
      console.log(response);
    }
  };

  const GetTransferList = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("page", page + 1);
    if (search !== "") {
      data.append("search", search);
    }
    if (startFilterDate != null) {
      data.append("startDate", getDayjsFormatted(startFilterDate));
    }
    if (endFilterDate != null) {
      data.append("endDate", getDayjsFormatted(endFilterDate));
    }
    if (status !== "Todos") {
      const auxStatus = statusObj.find((el) => el.status === status);
      data.append("status", auxStatus.id);
    }
    if (cedi !== "Todos") {
      const auxCedi = cedis.find((el) => el.cedi === cedi);
      data.append("cedi", auxCedi.id);
    }
    if (branch !== "Todas") {
      const auxBranch = branches.find((el) => el.sucursal === branch);
      data.append("branch", auxBranch.id);
    }

    const response = await transferAPI.transferListEP(data);
    if (response.status === 200) {
      setData(response.data.transfers);
      setIsLoading(false);
      setTotal(response.data.total);
      setCount(Math.ceil(response.data.total / 10));
    } else {
      setIsLoading(false);
      console.log(response);
    }
  };

  //------------------------------------------------

  //HANDLERS
  //------------------------------------------------

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleCloseFilterModal = () => setAnchorEl(null);

  const handleCloseModal = () => {
    // setOpenFilter(false);
    setAnchorEl(null);
    if (!view) {
      settingFilters(
        prevData.cedi,
        prevData.branch,
        status,
        prevData.startFilterDate,
        prevData.endFilterDate
      );
    } else {
      settingFilters(
        prevData.cedi,
        prevData.branch,
        prevData.status,
        prevData.startFilterDate,
        prevData.endFilterDate
      );
    }
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleChangeCedi = (e) => {
    setCedi(e.target.value);
  };

  const handleChangeBranch = (e) => {
    setBranch(e.target.value);
  };

  const handleFilter = () => {
    if (startFilterDate != null || endFilterDate != null) {
      if (startFilterDate != null && endFilterDate == null) {
        setErrors({ errorNullEnd: true });
      } else if (endFilterDate != null && startFilterDate == null) {
        setErrors({ errorNullStart: true });
      } else if (endFilterDate < startFilterDate) {
        setErrors({ error: true });
      } else {
        handleCloseFilterModal();
        setErrors({
          error: false,
          errorNullStart: false,
          errorNullEnd: false,
        });
        setPage(0);
        setReady(true);
        setPrevData({
          cedi: cedi,
          branch: branch,
          status: status,
          startFilterDate: startFilterDate,
          endFilterDate: endFilterDate,
        });
      }
    } else {
      handleCloseFilterModal();
      setPage(0);
      setReady(true);
      setPrevData({
        cedi: cedi,
        branch: branch,
        status: status,
        startFilterDate: startFilterDate,
        endFilterDate: endFilterDate,
      });
    }
  };

  const handleResetFilter = async () => {
    if (!view) {
      settingFilters("Todos", "Todas", status, firstDay, today);
    } else {
      settingFilters("Todos", "Todas", "Todos", firstDay, today);
    }
    // settingFilters("Todos", "Todas", "Todos", null, null);
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  //------------------------------------------------

  //FILTERS SETTING
  //------------------------------------------------

  const settingFilters = (cedi, branch, status, firstDay, endDay) => {
    setCedi(cedi);
    setBranch(branch);
    setStatus(status);
    setStartFilterDate(firstDay);
    setEndFilterDate(endDay);
  };

  //------------------------------------------------

  return (
    <>
      <div className="w-100 px-3 mt-2">
        {phone ? (
          <div className="mt-2 w-100 p-1 r-15">
            <div className="mb-2">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                requestData={RequestData}
                setPage={setPage}
              />
            </div>

            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <RefreshButton text={""} onClick={handleRefresh} />
                <DownloadButtonExcel
                  text={""}
                  disabled={data.length > 0 ? false : true}
                  data={dataExcel}
                  headers={transferHeaders}
                  sheetName={"Transferencias"}
                  isLoading={isLoadingExcel}
                  getData={() => GetTransfersExcel()}
                />
              </div>

              <ToggleView
                setView={setView}
                view={view}
                setStatus={setStatus}
                transfers={true}
                setIsTable={setIsTable}
              />
            </div>
          </div>
        ) : (
          <div className="mt-2 w-100 d-flex justify-content-between p-1 r-15">
            <div className="d-flex align-items-center">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                requestData={RequestData}
                setPage={setPage}
              />
              <div className="d-flex align-items-center ml-1">
                <RefreshButton text={"Actualizar"} onClick={handleRefresh} />
                <DownloadButtonExcel
                  text={"Descargar"}
                  disabled={data.length > 0 ? false : true}
                  data={dataExcel}
                  headers={transferHeaders}
                  sheetName={"Transferencias"}
                  isLoading={isLoadingExcel}
                  getData={() => GetTransfersExcel()}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <ToggleView
                setView={setView}
                view={view}
                setStatus={setStatus}
                transfers={true}
                setIsTable={setIsTable}
              />
            </div>
          </div>
        )}

        {view ? (
          isLoading ? (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <SkeletonTableTransfer
                num={3}
                total={total}
                page={page}
                setPage={setPage}
              />
            </div>
          ) : (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <TableTransfer
                search={search}
                data={data}
                setPage={setPage}
                page={page}
                total={total}
                requestData={RequestData}
                count={count}
              />
            </div>
          )
        ) : (
          <ListViewTransfer
            setStatus={setStatus}
            status={status}
            GetTransferList={GetTransferList}
            startFilterDate={startFilterDate}
            endFilterDate={endFilterDate}
            search={search}
            data={data}
            listIndex={listIndex}
            setListIndex={setListIndex}
            isLoading={isLoading}
            page={page}
            setPage={setPage}
            count={count}
            total={total}
            type={roles.CLIENT}
          />
        )}
      </div>

      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseFilterModal}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ModalFilters
            view={view}
            errors={errors}
            setErrors={setErrors}
            handleClose={handleCloseModal}
            dates={{
              setEnd: setEndFilterDate,
              end: endFilterDate,
              setStart: setStartFilterDate,
              start: startFilterDate,
            }}
            fields={[
              {
                array: statusObj,
                prop: "status",
                type: "Estatus",
                value: status,
                handleOnChange: handleChangeStatus,
              },
              {
                array: branches,
                prop: "sucursal",
                type: "Sucursal",
                value: branch,
                handleOnChange: handleChangeBranch,
              },
              {
                array: cedis,
                prop: "cedi",
                type: "Cedis",
                value: cedi,
                handleOnChange: handleChangeCedi,
              },
            ]}
            onClickFilter={handleFilter}
            onClickResetFilter={handleResetFilter}
          />
        </Popover>
        {/* </Modal> */}
      </>
    </>
  );
};
