import React from 'react'

import { Grid, Paper, IconButton, useMediaQuery, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles';
import { Info } from '@mui/icons-material';

import { EditTwoTone } from "@mui/icons-material";

import { Navigate, useNavigate } from 'react-router-dom';

import { roles } from '../helpers/enums';



export const CardTransfers = ({ info, color, type }) => {
    const phone = useMediaQuery("(max-width:480px)");

    // const navigate = useNavigate();

    // const handleClick = () => {
    // };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        borderRadius: 10,
        border: "solid 1px",
        borderColor: color,
        minHeight: 160,
        display: "flex",
        alignItems: "center"
    }));

    return (
        <Grid item xs={6} sm={3} md={2.4} lg= {2.4} xl={2.4}>
            <Item
                className='mb-2'
            >
                <div className='d-flex justify-content-between w-100'>
                    <div className='w-100'>
                        {/* <div className={`w-100 d-flex ${phone ? "flex-column" : "justify-content-between"}`}>
                            <div>
                                Folio: {info.id}
                            </div>
                            <div>
                                Status:  {info.status}
                            </div>
                        </div> */}
                        <div>
                            Folio: {info.id}
                        </div>
                        <div>
                            Status:  {info.status}
                        </div>
                        {
                            type == roles.ADMIN &&
                            <div>
                                Cliente: {info.client}
                            </div>
                        }
                        <Tooltip title={info.product.length > 20 ? info.product : ""} placement="top" arrow>
                            <div>Producto actualizado: {info.product.substring(0, 20)}{info.product.length > 20 && "..."}</div>
                        </Tooltip>
                        {/* <div>
                            Producto: {info.product}
                        </div> */}
                        {/* <div>
                            Origen: {info.departure_type == 1 ? info.departure_cedi : info.departure_branch}
                        </div>
                        <div>
                            Destino: {info.arrive_type == 1 ? info.arrive_cedi : info.arrive_branch}
                        </div> */}
                        <div>
                            Fecha: {info.creation_date}
                        </div>
                    </div>
                </div>
            </Item>
        </Grid>

    )
}


