import React, { useState } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
  styled,
  tableCellClasses,
} from "@mui/material";

import { roles } from "../../helpers/enums";

import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";
import { ListPagination } from "../Pagination/ListPagination";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const TableOdoo = ({
  search,
  data,
  type,
  setPage,
  page,
  total,
  rol,
  requestData,
  count,
}) => {
  const { height, width } = useViewPortDimensions();
  const navigate = useNavigate();
  //id, name, branch, status, statusId, client, products, creation_date
  const handleClick = (row) => {
    navigate(`/${rol}/ventas/Odoo/detalle/`, {
      state: {
        name: row.name,
        sucursal: row.branch,
        status: row.status,
        statusId : row.statusId,
        client: row.client,
        products: row.products,
        creation_date: row.creation_date,
        total: row.amount_total
      }
    });
  };

  const status = [
    { id: 1, value: "Generado" },
    { id: 2, value: "Asignado" },
    { id: 3, value: "En ruta" },
    { id: 4, value: "Finalizado" },
    { id: 5, value: "Otro" },
    { id: 6, value: "Cancelado" },
    { id: 7, value: "Entrega parcial" },
  ];

  const tRow = (row, index) => {
    const labelId = `enhanced-table-checkbox-${index}`;
    return (
      <TableRow
        hover
        // onClick={(e) => handleClick(e, row)}
        role="checkbox"
        tabIndex={-1}
        key={row.id}
      >
        {/* {type != roles.ADMIN && */}
        <TableCell padding="checkbox">
          <Tooltip title={"Ver Detalle"} arrow placement="top">
            <div className={`hover-icon`}>
              <IconButton
                onClick={() => {
                  handleClick(row);
                }}
              >
                <VisibilityIcon />
              </IconButton>
            </div>
          </Tooltip>
        </TableCell>
        {/* } */}
        {type == roles.ADMIN && (
          <TableCell align="center">{row.client || "--"}</TableCell>
        )}
        
        <TableCell align="center">{row.branch || "--"}</TableCell>
        <TableCell align="center">
          {row.name || "--"}
        </TableCell>
        <TableCell align="center">{row.status || "--"}</TableCell>
        <TableCell align="center">{row.creation_date || "--"}</TableCell>
        <TableCell align="center">{row.amount_total || "--"}</TableCell>
        {/* <TableCell align="center">{row.fecha_creacion.split("-").reverse("").join("-")}</TableCell> */}
      </TableRow>
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: height - (74 + 90 + 60) }}>
          <Table sx={{ minWidth: 750 }} aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow>
                {/* {type == roles.CLIENT &&  */}
                <StyledTableCell></StyledTableCell>
                {/* } */}
                {type == roles.ADMIN && (
                  <StyledTableCell align="center">Cliente</StyledTableCell>
                )}
                <StyledTableCell align="center">Sucursal</StyledTableCell>
                <StyledTableCell align="center">Nombre</StyledTableCell>
                <StyledTableCell align="center">Estatus</StyledTableCell>
                {/* <StyledTableCell align="center" sx={{ minWidth: 125 }}>
                  Monto
                </StyledTableCell> */}
                <StyledTableCell align="center" sx={{ minWidth: 125 }}>
                  Fecha de creación
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ minWidth: 125 }}>
                  Total
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{data.map((row, index) => tRow(row, index))}</TableBody>
          </Table>
          {data.length == 0 && search != "" ? (
            <div className="d-flex justify-content-center mt-2">
              No se encontraron resultados para "{search}"
            </div>
          ) : (
            data.length == 0 &&
            search == "" && (
              <div className="d-flex justify-content-center mt-2">
                No tienes ventas aún.
              </div>
            )
          )}
          {/* {data.length == 0 && (
                        <div className="d-flex justify-content-center mt-2">
                            No tienes ventas aún.
                        </div>
                    )} */}
        </TableContainer>
        <ListPagination
          setPage={setPage}
          page={page}
          total={total}
          requestData={requestData}
          count={count}
        />
      </Paper>
    </Box>
  );
};
