import { useState } from "react";

import Box from "@mui/material/Box";
import { useMediaQuery, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { MassiveProductEditForm } from "../Forms/MassiveProductEditForm";

export function ModalEditMassiveProducts({ handleClose, requestData }) {
  const phone = useMediaQuery("(max-width:600px)");
  const tablet = useMediaQuery("(max-width:900px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "95%" : "75%",
    //maxHeight: 500,
    //height: "80vh",
    bgcolor: "#f1f1f1",
    boxShadow: 24,
    borderRadius: 2,
    display: "block",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <>
      <Box sx={style}>
        <div
          className={`m-3 pl-1 pr-1 w-auto r-15 bg-white d-flex flex-row justify-content-between align-items-center ${
            !phone && "mb-3"
          }`}
        >
          <div className="p-1">
            <p className={`ds-blue-font m-0 ${phone ? "h6" : "h5"}`}>
              Editar Productos
            </p>
            <p className="text-secondary m-0">* Campos obligatorios</p>
          </div>

          <IconButton onClick={handleClose}>
            <Close sx={{ fontSize: 30 }} />
          </IconButton>
        </div>
        <MassiveProductEditForm
          handleClose={handleClose}
          requestData={requestData}
        />
      </Box>
    </>
  );
}
