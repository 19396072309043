import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { CardGraph } from "../Card/CardGraph";

const options = {
  // title: "Productos con menos stock",
  chartArea: { width: "80%", height: "80%" },
  hAxis: {
    // title: "Cantidad",
    title: "Piezas",
    minValue: 1,
  },
  vAxis: {
    // title: "Productos",
  },
  legend: "none",

  annotations: {
    textStyle: {
      // The color of the text.
      color: "#000",
    },
  },
  colors: ["#FF5C51"],
};

const modalOption = {
  annotations: {
    textStyle: {
      // The color of the text.
      color: "#000",
    },
  },
  vAxis: {
    title: "SKU",
    minValue: 0,
    titleTextStyle: {
      color: "black",
      bold: true,
    },
  },
  hAxis: {
    minValue: 1,
    titleTextStyle: {
      color: "black",
      bold: true,
    },
  },
};

export const LessExistancesGraph = ({
  data,
  dataFull,
  isLoading,
  client,
  filters,
}) => {
  const rol = localStorage.getItem("rol");

  return (
    <>
      <Grid item xs={4}>
        <CardGraph
          title={"Artículos con menos stock"}
          type={"BarChart"}
          data={data}
          options={options}
          isLoading={isLoading}
          modalOption={modalOption}
          dataFull={dataFull}
          client={client}
          filters={filters}
        />
      </Grid>
    </>
  );
};
