import React, { useState, useEffect, useContext } from "react";
import { PickersContext } from "../../context/PickersContext";
import { FilterContext } from "../../context/FilterContext";

import { Grid, Popover, useMediaQuery } from "@mui/material";

import { Search } from "../../components/Search";
import { ToggleView } from "../../components/ToggleView";

import { roles } from "../../helpers/enums";
import { ModalFilter } from "../../components/Modal/ModalFilter";
import { SkeletonCard } from "../../components/Skeleton/SkeletonCard";
import { ListViewPickers } from "../../components/ListViewPickers";
import { AddButton } from "../../components/AddButton";
import { RefreshButton } from "../../components/RefreshButton";

import { TablePickersList } from "../../components/Tables/TablePickersList";
import { SkeletonTable } from "../../components/Skeleton/SkeletonTable";
import { pickersCardHeaders, pickersHeaders } from "../../components/headers";

export const Pickers = ({ socketReload }) => {
  const [view, setView] = useState(true);
  const phone = useMediaQuery("(max-width:480px)");
  const tablet = useMediaQuery("(max-width:600px)");

  const {
    getPickerList,
    data,
    setData,
    page,
    setPage,
    total,
    count,
    isLoading,
  } = useContext(PickersContext);

  const { resetFilter, search, setSearch, getBranchesByHFM } =
    useContext(FilterContext);

  //------------------------------------------------

  //MODAL STATES
  //------------------------------------------------

  const [invisible, setInvisible] = useState(false);
  const [ready, setReady] = useState(false);

  //------------------------------------------------

  //EFFECTS
  //------------------------------------------------

  useEffect(() => {
    setPage(0);
    setSearch("");
    resetFilter();
    requestFilterData();
    setReady(true);
  }, []);

  useEffect(() => {
    if (ready) {
      requestData();
    }
  }, [ready]);

  useEffect(() => {
    console.log(socketReload);
    switch (socketReload.event) {
      case 1:
        const availabilityChangedData = data.map((picker) =>
          picker.id == socketReload.details.pickerId
            ? {
                ...picker,
                available: socketReload.details.pickerAvailable,
                log: {
                  login:
                    socketReload.details.pickerAvailable == true &&
                    picker.log == null // solo se cambiará el dato si no hay un dato, de lo contrario se sobreescribirá el login
                      ? socketReload.details.pickerLogDate
                      : picker.log.login,
                  logout:
                    socketReload.details.pickerAvailable == false
                      ? socketReload.details.pickerLogDate
                      : null,
                },
              }
            : picker
        );
        setData(availabilityChangedData);
        break;

      case 2:
        const pickingChangedData = data.map((picker) =>
          picker.id == socketReload.details.pickerId
            ? {
                ...picker,
                is_active: socketReload.details.pickerIsActive,
              }
            : picker
        );
        setData(pickingChangedData);
        break;
    }
  }, [socketReload]);

  //------------------------------------------------

  //DATA REQUEST
  //------------------------------------------------

  const requestData = () => {
    getPickerList();
  };
  const requestFilterData = () => {
    getBranchesByHFM();
  };
  /****************SALES REQUEST****************/

  //------------------------------------------------

  //HANDLERS
  //------------------------------------------------

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleCloseModal = () => setAnchorEl(null);

  //------------------------------------------------

  return (
    <>
      <div className="w-100 px-3 mt-2">
        {tablet ? (
          <div className="mt-2 w-100 p-1 r-15">
            <div className="mb-2">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                setPage={setPage}
                requestData={requestData}
              />
            </div>

            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <RefreshButton text={""} onClick={requestData} />
                <AddButton text={""} link={"nuevo/"} />
              </div>
              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        ) : (
          <div className="mt-2 w-100 d-flex justify-content-between p-1 r-15">
            <div className="d-flex align-items-center">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                requestData={requestData}
                setPage={setPage}
              />
              <div className="d-flex align-items-center ml-1">
                <AddButton text={"Picker nuevo"} link={"nuevo/"} />
                <RefreshButton text={"Actualizar"} onClick={requestData} />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        )}
        {view ? (
          // Vista de tabla
          isLoading ? (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <SkeletonTable num={3} headers={pickersHeaders} />
            </div>
          ) : (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <TablePickersList
                data={data}
                search={search}
                setPage={setPage}
                page={page}
                total={total}
                requestData={requestData}
                headers={pickersHeaders}
                count={count}
              />
            </div>
          )
        ) : isLoading ? (
          <>
            <div className="mt-3 w-100 r-15">
              <Grid
                container
                alignItems="center"
                direction="row"
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
                columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
              >
                <SkeletonCard num={10} headers={pickersCardHeaders} icons={2} />
              </Grid>
            </div>
          </>
        ) : (
          <ListViewPickers
            data={data}
            search={search}
            type={roles.ADMIN}
            setPage={setPage}
            page={page}
            total={total}
            count={count}
            requestData={requestData}
          />
        )}
      </div>

      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseModal}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ModalFilter
            handleCloseModal={handleCloseModal}
            dates={false}
            fields={["sucursal", "pickerAvailability"]}
            requestData={requestData}
            setPage={setPage}
          />
        </Popover>
      </>
    </>
  );
};
