import { Grid, Divider, useMediaQuery, setRef } from "@mui/material";
import React from "react";
import { ListPagination } from "../components/Pagination/ListPagination";
import { SkeletonCard } from "../components/Skeleton/SkeletonCard";
import { SaleB2B } from "../classes/SaleB2B";
import { CardSalesB2B } from "./Card/CardSalesB2B";
import { roles } from "../helpers/enums";

export function ListViewSalesB2B({
  search,
  setPage,
  page,
  isLoading,
  headers,
  requestData,
  data,
  total,
}) {
  const phone = useMediaQuery("(max-width:480px)");

  const rol = localStorage.getItem("rol");

  return (
    <div className="mt-3 w-100  r-15">
      <div className="mt-3 w-100 r-15">
        {isLoading ? (
          <Grid
            container
            alignItems="center"
            direction="row"
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
            columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
          >
            <SkeletonCard
              num={10}
              headers={headers}
              icons={rol == roles.ADMIN ? 1 : 2}
            />
          </Grid>
        ) : (
          <>
            {data.length > 0 ? (
              <Grid
                container
                alignItems="center"
                direction="row"
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
                columns={{ xs: 4, sm: 6, md: 9, lg: 12, xl: 12 }}
              >
                {data.map((row, index) => (
                  <CardSalesB2B
                    key={index}
                    info={new SaleB2B(row)}
                    requestData={requestData}
                  />
                ))}
              </Grid>
            ) : search == "" ? (
              <div className="d-flex justify-content-center mt-2">
                No tienes ventas.
              </div>
            ) : (
              <div className="d-flex justify-content-center mt-2">
                No se encontraron resultados para "{search}"
              </div>
            )}
            <ListPagination setPage={setPage} page={page} total={total} />
          </>
        )}
      </div>
    </div>
  );
}
