import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const ExcelDownloadOneSheet = (data, headers, sheetName, client) => {
  const excelData = data.map((info) => {
    let row = {};
    headers.forEach((header) => {
      if (header.excel) {
        row = { ...row, [header.label]: info[header.key] };
      }
    });
    return row;
  });
  const today = format(new Date(), "dd-MM-yyyy");

  const company = localStorage.getItem("razon_social");

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(excelData);
  const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blobData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(
    blobData,
    `BAP ${sheetName} ${client || company} ${today}` + fileExtension
  );
};
