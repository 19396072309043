import React, { useState, useEffect } from "react";

import { Grid, useMediaQuery, Popover, Modal } from "@mui/material";

import { Search } from "../../components/Search";
import { ToggleView } from "../../components/ToggleView";
import { TableProducts } from "../../components/Tables/TableProducts";
import { ListViewProducts } from "../../components/ListViewProducts";

import { AddButton } from "../../components/AddButton";

import { SkeletonTableProducts } from "../../components/Skeleton/SkeletonTableProducts";
import { DownloadButtonExcel } from "../../components/Excel/DownloadButtonExcel";
import { productsHeaders } from "../../components/headers";
import { SkeletonCardProduct } from "../../components/Skeleton/SkeletonCardProduct";

import { roles } from "../../helpers/enums";

import { ModalFilter } from "../../components/Modal/ModalFilter";
import { ModalAddMassiveProducts } from "../../components/Modal/ModalAddMassiveProducts";
import { useContext } from "react";
import { FilterContext } from "../../context/FilterContext";
import { ProductContext } from "../../context/ProductContext";
import { ConstructionOutlined } from "@mui/icons-material";
import { ModalEditMassiveProducts } from "../../components/Modal/ModalEditMassiveProducts";
import { EditButton } from "../../components/EditButton";

export const Products = () => {
  const phone = useMediaQuery("(max-width:480px)");
  const tablet = useMediaQuery("(max-width:915px)");

  const [view, setView] = useState(true);

  const [ready, setReady] = useState(false);

  //------------------------------------------------

  //FILTERS STATES
  //------------------------------------------------

  const { getCategories, search, setSearch, invisible, closeFilter } =
    useContext(FilterContext);

  //DATA STATES
  //------------------------------------------------

  const {
    data,
    page,
    setPage,
    total,
    setTotal,
    count,
    getProductsList,
    getProductExcelList,
    excelData,
    isLoading,
    isLoadingExcel,
  } = useContext(ProductContext);

  //------------------------------------------------

  //MODAL MASSIVE ADD PRODUCT
  //------------------------------------------------

  const [openMassiveModal, setOpenMassiveModal] = useState(false);
  const [openEditMassiveModal, setOpenEditMassiveModal] = useState(false)
  const handleOpenModal = () => {
    setOpenMassiveModal(true);
  };
  const handleCloseMassiveModal = () => {
    setOpenMassiveModal(false);
  };

  const handleOpenEditMassiveModal = () => {
    setOpenEditMassiveModal(!openEditMassiveModal);
  }
  //------------------------------------------------

  //EFFECTS
  //------------------------------------------------

  useEffect(() => {
    setPage(0);
    setSearch("");
    setReady(true);
    requestFilterData();
  }, []);

  useEffect(() => {
    if (ready) {
      requestData();
    }
  }, [ready]);

  //------------------------------------------------

  //DATA REQUEST
  //------------------------------------------------

  const requestData = () => {
    getProductsList();
  };

  const requestFilterData = () => {
    getCategories();
  };

  //------------------------------------------------

  //HANDLERS
  //------------------------------------------------

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleCloseFilterModal = () => {
    closeFilter();
    setAnchorEl(null);
  };

  //------------------------------------------------

  return (
    <>
      <div className="w-100 px-3 mt-2">
        {tablet ? (
          <div className="mt-2 w-100 p-1 mb-2 r-15">
            <div className=" mb-2">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                requestData={requestData}
                setPage={setPage}
              />
            </div>

            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <AddButton text={""} link={"nuevo/"} />
                <AddButton text={""} link={""} onClick={handleOpenModal} />
                <AddButton text={""} link={""} onClick={handleOpenEditMassiveModal} />
                <DownloadButtonExcel
                  text={""}
                  disabled={data.length > 0 ? false : true}
                  data={excelData}
                  headers={productsHeaders}
                  sheetName={"Productos"}
                  isLoading={isLoadingExcel}
                  getData={() => getProductExcelList()}
                />
              </div>

              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        ) : (
          <div className="mt-2 w-100 d-flex justify-content-between mb-3 p-1 r-15">
            <div className="d-flex align-items-center">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                requestData={requestData}
                setPage={setPage}
              />
              <div className="d-flex align-items-centers ml-1">
                <AddButton text={"Producto nuevo"} link={"nuevo/"} />
                <AddButton
                  text={"Carga masiva"}
                  link={""}
                  onClick={handleOpenModal}
                />
                <EditButton
                  text={"Edicion masiva"}
                  link={""}
                  onClick={handleOpenEditMassiveModal}
                />
                <DownloadButtonExcel
                  text={"Descargar"}
                  disabled={data.length > 0 ? false : true}
                  data={excelData}
                  headers={productsHeaders}
                  sheetName={"Productos"}
                  isLoading={isLoadingExcel}
                  getData={() => getProductExcelList()}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        )}
        {view ? (
          // Vista de tabla
          isLoading ? (
            <div className="mt-1 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <SkeletonTableProducts
                num={3}
                type={roles.CLIENT}
                total={total}
                page={page}
                setPage={setPage}
              />
            </div>
          ) : (
            <div className="mt-1 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <TableProducts
                data={data}
                search={search}
                type={roles.CLIENT}
                setPage={setPage}
                page={page}
                total={total}
                isLoading={isLoading}
                requestData={requestData}
                count={count}
              />
            </div>
          )
        ) : isLoading ? (
          <div className=" w-100 r-15">
            <Grid
              container
              alignItems="center"
              direction="row"
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
              columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
            >
              <SkeletonCardProduct
                num={10}
                rows={7}
                icon={true}
                img={true}
                total={total}
                page={page}
                setPage={setPage}
                count={count}
              />
            </Grid>
          </div>
        ) : (
          <ListViewProducts
            height={330}
            data={data}
            search={search}
            type={roles.CLIENT}
            total={total}
            page={page}
            setPage={setPage}
            count={count}
            requestData={requestData}
          />
        )}
      </div>

      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseFilterModal}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ModalFilter
            handleCloseModal={handleCloseFilterModal}
            dates={true}
            nullable={true}
            fields={["categoria"]}
            requestData={requestData}
            setPage={setPage}
            view={view}
          />
        </Popover>
        <Modal open={openMassiveModal} onClose={handleCloseMassiveModal}>
          <div>
            {" "}
            {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
            <ModalAddMassiveProducts
              handleClose={handleCloseMassiveModal}
              requestData={requestData}
            />
          </div>
        </Modal>
        <Modal open={openEditMassiveModal} onClose={handleOpenEditMassiveModal}>
          <div>
            {" "}
            {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
            <ModalEditMassiveProducts
              handleClose={handleOpenEditMassiveModal}
              requestData={requestData}
            />
          </div>
        </Modal>
      </>
    </>
  );
};
