import React from "react";
import { Button, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";

export const AcceptButton = ({
  text,
  type,
  onClick,
  width,
  fontSize,
  disabled,
  isLoading,
}) => {
  const phone = useMediaQuery("(max-width:480px)");

  const AcceptButtonStyled = styled(Button)({
    marginTop: phone ? "2px" : "",
    width: width,
    boxShadow: "1",
    textTransform: "none",
    fontSize: fontSize ? fontSize : phone ? 13 : 16,
    padding: "6px 12px",
    border: "1.5px solid",
    lineHeight: 1.5,
    borderRadius: 5,
    backgroundColor: "#ffc500",
    borderColor: disabled ? "none" : "#ffc500",
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "3",
      color: "#ffc500",
    },
  });

  return (
    <AcceptButtonStyled
      variant="contained"
      disableRipple
      onClick={onClick}
      type={type}
      disabled={disabled || isLoading}
    >
      {text}
    </AcceptButtonStyled>
  );
};
