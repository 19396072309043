import React from "react";

import { Button, ButtonBase } from "@mui/material";

import { AddCircle } from "@mui/icons-material";

export const AddFormButton = ({ text, onClick }) => {
  return (
    <ButtonBase disableRipple onClick={onClick} className="toolbar-button">
      <AddCircle className="mr-1" fontSize="small" />
      <p className="m-0 no-wrap">{text}</p>
    </ButtonBase>
  );
};
