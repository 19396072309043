import { HttpRequest } from "./HttpRequest";
import { getHost } from "../helpers/getHost";

export class ProductsAPI {
  constructor() {
    this.http = new HttpRequest();
    this.host = getHost();
    this.token = localStorage.getItem("token");
    this.config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${this.token}`,
      },
    };
  }

  newProductEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/createProduct/`,
      data,
      this.config
    );
  }

  createProductEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/productForms/`,
      data,
      this.config
    );
  }

  editProductEP(data) {
    return this.http.put(
      `${this.host}/inventory/ws/productForms/`,
      data,
      this.config
    );
  }

  massiveProductFormsEp(data) {
    return this.http.post(
      `${this.host}/inventory/ws/productsTemplate/`,
      data,
      this.config
    );
  }

  editMassiveProductFormsEp(data) {
    return this.http.put(
      `${this.host}/inventory/ws/productsTemplate/`,
      data,
      this.config
    );
  }

  productsListEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/productList/`,
      data,
      this.config
    );
  }

  categoriesListEP(data) {
    return this.http.get(
      `${this.host}/inventory/ws/categories/?${data}`,
      this.config
    );
  }

  productsListCleanedEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/productsListCleaned/`,
      data,
      this.config
    );
  }

  productExcelListEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/productList/`,
      data,
      this.config
    );
  }

  warehousesByProductEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/productWarehouses/`,
      data,
      this.config
    );
  }

  productStockEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/productByWarehouse/`,
      data,
      this.config
    );
  }
}
