import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";

import { CardGraph } from "../Card/CardGraph";

const options = {
  legend: { position: "bottom", alignment: "center" },
  pieSliceText: "percent",
  // title: `Total: ${36}`,
  tooltip: { trigger: "selection" },
  is3D: true,
  pieStartAngle: 90,
  chartArea: { width: "80%", height: "80%" },
};

const modalOption = {
  titleTextStyle: {
    fontSize: 20,
  },
};

export const WarehousesInventoryGraph = ({
  data,
  isLoading,
  total,
  client,
  filters,
}) => {
  return (
    <>
      <Grid item xs={4}>
        <CardGraph
          title={"Ubicación De Productos"}
          type={"PieChart"}
          data={data}
          options={{ ...options, title: `Total: ${total}` }}
          modalOption={modalOption}
          isLoading={isLoading}
          client={client}
          filters={filters}
        />
      </Grid>
    </>
  );
};
