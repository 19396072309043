
export const getCSRF = () => {
    // const csrf = document.cookie.split(';').find((element) => element.includes('csrf'))
    // if (csrf == "") {
    //     window.location.reload()
    // } else {
    //     csrf.trim()
    //     const indexOfEqual = csrf.indexOf("=")
    //     const csrf_real = csrf?.slice(indexOfEqual + 1, csrf.length)
    //     return csrf_real;
    // }

}