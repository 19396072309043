export const sectionsAdmin = [
  {
    link: "administrador/dashboard",
    name: "Dashboard",
    section: "Dashboard",
  },
  {
    link: "administrador/clientes",
    name: "Clientes",
    section: "Clientes",
  },
  {
    link: "administrador/clientes/nuevo",
    name: "Nuevo",
    section: "Clientes",
  },
  {
    link: "administrador/clientes/editar",
    name: "Editar",
    section: "Clientes",
  },
  {
    link: "administrador/productos",
    name: "Productos",
    section: "Productos",
  },
  {
    link: "administrador/inventario",
    name: "Inventario",
    section: "Inventario",
  },
  {
    link: "administrador/inventario/existencias",
    name: "Existencias",
    section: "Inventario",
  },
  {
    link: "administrador/inventario/envios",
    name: "Envíos",
    section: "Inventario",
  },
  {
    link: "administrador/inventario/transferencias",
    name: "Transferencias",
    section: "Inventario",
  },
  {
    link: "administrador/inventario/traspasos",
    name: "Traspasos",
    section: "Inventario",
  },
  {
    link: "administrador/ventas",
    name: "Ventas",
    section: "Ventas",
  },
  {
    link: "administrador/ventas/B2C",
    name: "B2C",
    section: "Ventas",
  },
  {
    link: "administrador/ventas/Odoo",
    name: "Odoo",
    section: "Ventas",
  },
  {
    link: "administrador/ventas/B2C/detalle",
    name: "Detalle",
    section: "Ventas",
  },
  {
    link: "administrador/ventas/Odoo/detalle",
    name: "Detalle",
    section: "Ventas",
  },
  {
    link: "administrador/ventas/B2B",
    name: "B2B",
    section: "Ventas",
  },
  {
    link: "administrador/ventas/B2B/detalle",
    name: "Detalle",
    section: "Ventas",
  },
  {
    link: "administrador/ventas/B2B/negocios",
    name: "Negocios",
    section: "Ventas",
  },
  {
    link: "administrador/ventas/B2B/negocios/detalle",
    name: "Detalle",
    section: "Ventas",
  },
  {
    link: "administrador/reportes",
    name: "Reportes",
    section: "Reportes",
  },
  {
    link: "administrador/reportes/ventas",
    name: "Ventas",
    section: "Reportes",
  },
  {
    link: "administrador/reportes/inventario",
    name: "Inventario",
    section: "Reportes",
  },
  {
    link: "administrador/cuenta",
    name: "Cuenta",
    section: "Cuenta",
  },
  {
    link: "administrador/cuenta/perfil",
    name: "Perfil",
    section: "Cuenta",
  },
  {
    link: "administrador/cuenta/password",
    name: "Contraseña",
    section: "Cuenta",
  },
];

export const sectionsClient = [
  {
    link: "cliente/dashboard",
    name: "Dashboard",
    section: "Dashboard",
  },
  {
    link: "cliente/notificaciones",
    name: "Histórico",
    section: "Notificaciones",
  },
  {
    link: "cliente/productos",
    name: "Productos",
    section: "Productos",
  },
  {
    link: "cliente/productos/nuevo",
    name: "Nuevo",
    section: "Productos",
  },
  {
    link: "cliente/productos/editar",
    name: "Editar",
    section: "Productos",
  },
  {
    link: "cliente/inventario",
    name: "Inventario",
    section: "Inventario",
  },
  {
    link: "cliente/inventario/existencias",
    name: "Existencias",
    section: "Inventario",
  },
  {
    link: "cliente/inventario/envios",
    name: "Envíos",
    section: "Inventario",
  },
  {
    link: "cliente/inventario/busqueda",
    name: "Programar envíos",
    section: "Inventario",
  },
  {
    link: "cliente/inventario/busqueda/agregar",
    name: "Agregar",
    section: "Inventario",
  },
  {
    link: "cliente/inventario/busqueda/envio_masivo",
    name: "Envio Masivo",
    section: "Inventario",
  },
  {
    link: "cliente/inventario/transferir",
    name: "Transferir",
    section: "Inventario",
  },
  {
    link: "cliente/inventario/transferencias",
    name: "Transferencias",
    section: "Inventario",
  },
  {
    link: "cliente/ventas",
    name: "Ventas",
    section: "Ventas",
  },
  {
    link: "cliente/ventas/B2C",
    name: "B2C",
    section: "Ventas",
  },
  {
    link: "cliente/ventas/Odoo",
    name: "Odoo",
    section: "Ventas",
  },
  {
    link: "cliente/ventas/B2C/detalle",
    name: "Detalle",
    section: "Ventas",
  },
  {
    link: "cliente/ventas/Odoo/detalle",
    name: "Detalle",
    section: "Ventas",
  },
  {
    link: "cliente/ventas/B2B",
    name: "B2B",
    section: "Ventas",
  },
  {
    link: "cliente/ventas/B2B/detalle",
    name: "Detalle",
    section: "Ventas",
  },
  {
    link: "cliente/ventas/B2B/productos",
    name: "Productos",
    section: "Ventas",
  },
  {
    link: "cliente/ventas/B2B/negocios",
    name: "Negocios",
    section: "Ventas",
  },
  {
    link: "cliente/ventas/B2B/negocios/detalle",
    name: "Detalle negocio",
    section: "Ventas",
  },
  {
    link: "cliente/ventas/B2B/negocios/nuevo",
    name: "Agregar negocio",
    section: "Ventas",
  },
  {
    link: "cliente/ventas/B2B/nueva_venta",
    name: "Registrar venta",
    section: "Ventas",
  },
  {
    link: "cliente/ventas/B2B/negocios/agregar_ubicacion",
    name: "Agregar ubicación",
    section: "Ventas",
  },
  {
    link: "cliente/reportes",
    name: "Reportes",
    section: "Reportes",
  },
  {
    link: "cliente/reportes/ventas",
    name: "Ventas",
    section: "Reportes",
  },
  {
    link: "cliente/reportes/inventario",
    name: "Inventario",
    section: "Reportes",
  },
  {
    link: "cliente/usuarios",
    name: "Usuarios",
    section: "Usuarios",
  },
  {
    link: "cliente/usuarios/nuevo",
    name: "Nuevo",
    section: "Usuarios",
  },
  {
    link: "cliente/usuarios/editar",
    name: "Editar",
    section: "Usuarios",
  },
  {
    link: "cliente/cuenta",
    name: "Cuenta",
    section: "Cuenta",
  },
  {
    link: "cliente/cuenta/perfil",
    name: "Perfil",
    section: "Cuenta",
  },
  {
    link: "cliente/cuenta/password",
    name: "Contraseña",
    section: "Cuenta",
  },
];

export const sectionsSucursal = [
  {
    link: "warehouse/activas",
    name: "En curso",
    section: "En curso",
  },
  {
    link: "warehouse/ventas",
    name: "Histórico",
    section: "Histórico",
  },
  {
    link: "warehouse/cuenta",
    name: "Cuenta",
    section: "Cuenta",
  },
  {
    link: "warehouse/cuenta/password",
    name: "Contraseña",
    section: "Cuenta",
  },
];

export const sectionsHeadFieldManager = [
  {
    link: "headFieldManager/activas",
    name: "En curso",
    section: "En curso",
  },
  {
    link: "headFieldManager/ventas",
    name: "Histórico",
    section: "Histórico",
  },
  {
    link: "headFieldManager/pickers",
    name: "Pickers",
    section: "Pickers",
  },
  {
    link: "headFieldManager/pickers/nuevo",
    name: "Nuevo",
    section: "Nuevo",
  },
  {
    link: "headFieldManager/cuenta",
    name: "Cuenta",
    section: "Cuenta",
  },
  {
    link: "headFieldManager/cuenta/password",
    name: "Contraseña",
    section: "Cuenta",
  },
];
