import React from "react";

import { Toolbar, Typography, Grid, Paper, IconButton } from "@mui/material";
import AccountIcon from '../../../assets/Account.svg';
import PasswordIcon from '../../../assets/Password.svg';

import { useNavigate } from "react-router-dom";
import { FlippyCard } from "../../../components/FlippyCard";

export const AccountCardsAdmin = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="w-100 px-3">
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}
          className="mt-2"
        >

          <FlippyCard
            xs={6} sm={6} md={6} lg={6}
            description={"Edita tu información de administrador cómo: dirección, email, número telefónico"}
            onClick={() => {
              navigate("/administrador/cuenta/perfil/")
            }}
            title={"Perfil"}
            icon={
              <IconButton>
                <img
                  src={AccountIcon}
                  alt="Account"
                  width={100}
                  height={100}
                />
              </IconButton>
            }
          />

          <FlippyCard
            xs={6} sm={6} md={6} lg={6}
            description={"Actualiza tu contraseña"}
            onClick={() => {
              navigate("/administrador/cuenta/password/")
            }}
            title={"Contraseña"}
            icon={
              <IconButton>
                <img
                  src={PasswordIcon}
                  alt="Password"
                  width={100}
                  height={100} />
              </IconButton>
            }
          />

        </Grid>
      </div>
    </>
  );
};
