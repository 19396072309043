import React, { useState, useEffect, useContext } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  tableCellClasses,
  Modal,
  Tooltip,
  IconButton,
  Popover,
  useMediaQuery,
  Backdrop,
} from "@mui/material";

import { roles } from "../../helpers/enums";

import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";
import { ListPagination } from "../Pagination/ListPagination";

import {
  DeleteForever,
  EditTwoTone,
  AccountBox,
  Visibility,
  AccountCircle,
  ShoppingBasket,
  Circle,
} from "@mui/icons-material";

import { useNavigate } from "react-router-dom";

import ModalContactInfo from "../Modal/ModalContactInfo";
import { ModalDelete } from "../Modal/ModalDelete";
import { ModalSuccess } from "../Modal/ModalSuccess";
import { ModalError } from "../Modal/ModalError";
import { ModalMap } from "../Modal/ModalMap";

import { PickersContext } from "../../context/PickersContext";

import LoaderDS from "../../assets/LoaderDS.svg";
import { theme } from "../../utils/theme";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const TablePickersList = ({
  search,
  data,
  setPage,
  page,
  total,
  requestData,
  headers,
  count,
}) => {
  const { height, width } = useViewPortDimensions();
  const phone = useMediaQuery("(max-width:480px)");

  const navigate = useNavigate();

  const [openDelete, setOpenDelete] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  /*************** MAP STATES *****************/
  const [openMap, setOpenMap] = useState(false);

  const [picker, setPicker] = useState();
  const [openContact, setOpenContact] = useState(false);

  const {
    isLoadingDelete,
    deletePicker,
    error,
    success,
    setSuccess,
    setError,
    errorText,
  } = useContext(PickersContext);

  const handleClick = (event, action, info) => {
    setPicker(info);
    switch (action) {
      case "edit":
        navigate("/headFieldManager/pickers/nuevo", {
          state: {
            id: info.id == null ? "" : info.id,
            name: info.nombre == null ? "" : info.nombre,
            lastName: info.apellidos == null ? "" : info.apellidos,
            email: info.email == null ? "" : info.email,
            employeeNumber: info.picker_code == null ? "" : info.picker_code,
            personalNumber: info.telefono == null ? "" : info.telefono,
            branchId: info.warehouse_id == null ? "" : info.warehouse_id,
            photoFile: info.photo == null ? "" : info.photo,
            photoLoaded: info.photo == null ? false : true,
          },
        });
        break;
      case "cancel":
        setAnchorEl(event.currentTarget);
        setOpenDelete(true);
        break;
      case "contact":
        setOpenContact(true);
        break;
      case "map":
        setOpenMap(true);
    }
  };

  const idPopover = openDelete ? "simple-popover" : undefined;

  const handleCloseSuccess = () => {
    setSuccess(false);
    requestData();
  };

  const tRow = (row, index) => {
    const labelId = `enhanced-table-checkbox-${index}`;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        <TableCell padding="checkbox">
          <div className="d-flex justify-content-center mx-4">
            <Tooltip title={"Dar de baja"} arrow placement="top">
              <div className={"hover-icon"}>
                <IconButton
                  sx={{ "&:hover": { color: "red" }, padding: "0 1px 0 0" }}
                  onClick={(e) => handleClick(e, "cancel", row)}
                  disabled={false}
                >
                  <DeleteForever />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title={"Editar"} arrow placement="top">
              <div className={"hover-icon"}>
                <IconButton
                  sx={{ "&:hover": { color: "green" }, padding: "0 1px 0 0" }}
                  onClick={(e) => handleClick(e, "edit", row)}
                  disabled={false}
                >
                  <EditTwoTone />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        </TableCell>
        <TableCell align="center" sx={{ padding: "0 16px" }}>
          {row.photo ? (
            <div
              onClick={(e) => handleClick(e, "contact", row)}
              className="rounded-circle cursor-pointer"
              style={{
                background: `url("${row.photo}") center / cover no-repeat`,
                height: 50,
                width: 50,
                margin: "auto",
              }}
            ></div>
          ) : (
            <AccountCircle sx={{ color: theme.palette.neutrals.inactive, fontSize: 50 }} />
          )}
        </TableCell>
        <TableCell
          align="center"
          style={{ minWidth: 150 }}
        >{`${row.nombre} ${row.apellidos}`}</TableCell>
        <TableCell align="center" style={{ minWidth: 130 }}>
          {row.warehouse}
        </TableCell>

        <TableCell align="center">
          <Tooltip
            title={row.available == true ? "Conectado" : "Desconectado"}
            placement="top"
            arrow
          >
            <Circle
              className={`${
                row.available == true ? "ds-green-font" : "inactive-font"
              }`}
            />
          </Tooltip>
        </TableCell>
        <TableCell align="center">
          <Tooltip
            title={row.is_active == true ? "Picking" : "No picking"}
            placement="top"
            arrow
          >
            <ShoppingBasket
              className={`${
                row.is_active == true ? " ds-yellow-font" : "inactive-font"
              }`}
            />
          </Tooltip>
        </TableCell>
        <TableCell align="center" style={{ minWidth: 120 }}>
          {row.log != null
            ? row.log.login != null
              ? row.log.login.substring(0, 16)
              : ""
            : ""}
        </TableCell>
        <TableCell align="center" style={{ minWidth: 120 }}>
          {row.available == false && row.log != null && row.log.logout != null
            ? row.log.logout.substring(0, 16)
            : ""}
        </TableCell>
        <TableCell align="center">
          {row.location ? (
            <span
              className="cursor-pointer ds-blue-font"
              onClick={(e) => handleClick(e, "map", row)}
            >
              Ver
            </span>
          ) : (
            <p className="inactive-font m-0">Ver</p>
          )}
        </TableCell>
        <TableCell align="center">
          <Tooltip title={"Ver Contacto"} arrow placement="top">
            <div className={"hover-icon"}>
              <IconButton
                sx={{ "&:hover": { color: "blue" }, padding: "0 1px 0 0" }}
                onClick={(e) => handleClick(e, "contact", row)}
                disabled={false}
              >
                <AccountBox />
              </IconButton>
            </div>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: height - (74 + 90 + 60) }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-label="sticky table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {headers.map((header, index) => (
                    <StyledTableCell align="center" key={index}>
                      {header.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => tRow(row, index))}
              </TableBody>
            </Table>
            {data.length == 0 && search != "" ? (
              <div className="d-flex justify-content-center mt-2">
                No se encontraron resultados para "{search}"
              </div>
            ) : (
              data.length == 0 &&
              search == "" && (
                <div className="d-flex justify-content-center mt-2">
                  No tienes pickers aún.
                </div>
              )
            )}
          </TableContainer>
          <ListPagination
            setPage={setPage}
            page={page}
            total={total}
            requestData={requestData}
            count={count}
          />
        </Paper>
      </Box>

      <Modal open={openContact}>
        <div>
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalContactInfo
            info={picker}
            handleClose={() => setOpenContact(false)}
            infoType={"picker"}
          />
        </div>
      </Modal>
      <Popover
        id={idPopover}
        open={openDelete}
        anchorEl={anchorEl}
        onClose={() => setOpenDelete(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: !phone && 300 },
        }}
      >
        <ModalDelete
          type={"picker"}
          info={picker}
          auxText={"a"}
          handleClose={() => setOpenDelete(false)}
          deleteObject={deletePicker}
        />
      </Popover>
      <Modal open={success}>
        <div>
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalSuccess
            text={"El picker se dió de baja correctamente"}
            handleClose={handleCloseSuccess}
          />
        </div>
      </Modal>
      <Modal open={error}>
        <div>
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalError
            text={`No se pudo dar de baja el picker.`}
            handleClose={() => setError(false)}
            error={errorText}
          />
        </div>
      </Modal>
      <Modal open={openMap}>
        <div>
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalMap
            info={picker && `${picker.nombre} ${picker.apellidos}`}
            handleClose={() => setOpenMap(false)}
            location={picker && picker.location}
          />
        </div>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingDelete}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
