import React, { createContext, useState, useContext, useMemo } from "react";
import { FilterContext } from "./FilterContext";
import { SalesB2BAPI } from "../api/SalesB2B";
import {
  BusinessExcelAdmin,
  BusinessExcelHeaders,
  LocationsExcelAdmin,
  LocationsExcelHeaders,
} from "../components/headers";

export const BusinessContext = createContext();

export function BusinessContextProvider(props) {
  //------------------------------------------------
  //DATA STATES
  //------------------------------------------------
  const [data, setData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(); /*Paginacion listas */
  const [excelData, setExcelData] = useState([]);

  //------------------------------------------------
  //REQUEST STATES
  //------------------------------------------------
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  //------------------------------------------------
  //LOADERS STATES
  //------------------------------------------------

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  //------------------------------------------------
  //FILTERS STATES
  //------------------------------------------------

  const { regimeType, businessStatus, search, state, adminClient } =
    useContext(FilterContext);

  /************** API INSTANCE *******************/
  const api = useMemo(() => new SalesB2BAPI(), []);

  //------------------------------------------------
  //DATA REQUEST
  //------------------------------------------------

  const getBusinessData = async (admin) => {
    setIsLoading(true);
    const data = new FormData();
    data.append("page", page + 1);
    data.append("excel", false);
    if (regimeType != 0) {
      data.append("regime_type", parseInt(regimeType, 10));
    }

    if (businessStatus == 1) {
      data.append("status", "True");
    } else if (businessStatus == 2) {
      data.append("status", "False");
    }

    if (adminClient != 0) {
      data.append("client", adminClient);
    }

    if (search != "") {
      data.append("search", search);
    }

    let response;
    if (admin) {
      response = await api.getBusinessListAdminEP(data);
    } else {
      response = await api.getBusinessListEP(data);
    }
    if (response.status == 200) {
      setData(response.data.entries);
      setTotal(response.data.total);
      setCount(Math.ceil(response.data.total / 10));
      setIsLoading(false);
    } else {
      console.log(response);
      setIsLoading(false);
    }
  };

  const getBusinessExcelData = async (admin) => {
    setIsLoadingExcel(true);
    let data = new FormData();
    data.append("page", page + 1);
    data.append("excel", true);

    if (regimeType != 0) {
      data.append("regime_type", parseInt(regimeType, 10));
    }

    if (businessStatus == 1) {
      data.append("status", "True");
    } else if (businessStatus == 2) {
      data.append("status", "False");
    }

    if (adminClient != 0) {
      data.append("client", adminClient);
    }

    if (search != "") {
      data.append("search", search);
    }

    let response;
    if (admin) {
      response = await api.getBusinessListAdminEP(data);
    } else {
      response = await api.getBusinessListEP(data);
    }

    if (response.status == 200) {
      setExcelData([
        {
          name: "Negocios",
          data: response.data.entries.map((data) => {
            return {
              client: data.client,
              business: data.business,
              status: data.status == true ? "Activo" : "Inactivo",
              business_name: data.business_name,
              date_created: data.date_created,
              rfc: data.rfc,
              legal_representative: data.legal_representative,
              tax_regime: data.tax_regime.value,
              regime_type: data.regime_type,
              website: data.website,
              office_phone: data.office_phone,
              address: `${data.street} ${data.number}, ${data.neighborhood} CP ${data.postal_code}, ${data.municipality},`,
              contact_name: `${data.first_name_contact} ${data.last_name_contact}`,
              cell_phone_contact: data.cell_phone_contact,
              email_contact: data.email_contact,
            };
          }),
          headers: admin ? BusinessExcelAdmin : BusinessExcelHeaders,
        },
        {
          name: "Ubicaciones",
          data: response.data.entries.flatMap((data) => {
            return data.business_locations.map((loc) => {
              return {
                client: data.client,
                business_name: loc.business_name,
                alias: loc.alias,
                date_created: loc.date_created,
                country: loc.country,
                state: loc.state,
                municipality: loc.municipality,
                neighborhood: loc.neighborhood,
                street: loc.street,
                number: loc.number,
                open: loc.open.substring(0, 5),
                close: loc.close.substring(0, 5),
                contact_name: `${loc.first_name} ${loc.last_name}`,
                cell_phone: loc.cell_phone,
                email: loc.email,
              };
            });
          }),
          headers: admin ? LocationsExcelAdmin : LocationsExcelHeaders,
        },
      ]);
      setIsLoadingExcel(false);
    } else {
      console.log(response);
      setIsLoadingExcel(false);
    }
  };

  const getLocations = async (id) => {
    setIsLoading(true);
    let data = new FormData();

    data.append("business", id);

    if (state != 0) {
      data.append("state", state);
    }

    const response = await api.getLocationsEP(data);
    if (response.status == 200) {
      setLocations(response.data.entries);
      setIsLoading(false);
    } else {
      console.log(response);
      setIsLoading(false);
    }
  };

  return (
    <BusinessContext.Provider
      value={{
        data,
        locations,
        setLocations,
        page,
        setTotal,
        setPage,
        total,
        count,
        excelData,
        isLoading,
        isLoadingExcel,
        isLoadingDelete,
        getBusinessData,
        getBusinessExcelData,
        getLocations,
        error,
        errorMsg,
        setError,
        success,
        setSuccess,
      }}
    >
      {props.children}
    </BusinessContext.Provider>
  );
}
