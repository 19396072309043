import { Product } from "./Product";

export class SaleB2B {
  statusList = [
    { id: 1, value: "Generado" },
    { id: 3, value: "Cancelado" },
    /* { id: 1, value: "Listo" },
    { id: 2, value: "Asignado" },
    { id: 3, value: "En ruta" },
    { id: 4, value: "Finalizado" },
    { id: 5, value: "Otro" },
    { id: 6, value: "Cancelado" }, */
  ];

  constructor(data, detail) {
    this.id = data.id;
    this.orderCode = data.code;
    this.creationDate = data.date_created
      ? data.date_created.substring(0, 10)
      : "Sin información";
    this.departureDate = data.departure_date_branch;
    this.deliveryDate = data.delivery_date
      ? data.delivery_date
      : `Estimada ${data.estimated_date}`;
    this.products = data.products && data.products.map((p) => new Product(p));
    this.statusId = data.generated_order;
    this.status =
      this.statusList.find((el) => el.id == data.generated_order).value || "";
    this.business = data.business;
    //this.branch = data.origin;
    this.businessLocation = data.business_location;
    this.amount = `${data.amount_requested} ${data.type_coin}`;
    this.reference = data.reference;
    this.paymentMethod = data.payment_method;
    this.client = data.client;

    if (detail) {
      this.guideNumber = data.guide_number;
      this.trakingTag = "";
      this.trakingUrl = "";
      this.lastMile = data.last_mile;
      this.purchaseOrder = data.purchase_order;
      this.evidence = "";
    }
  }
}
