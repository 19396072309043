import React from "react";

import {
  useMediaQuery,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import { ClientForm } from "../../../components/Forms/ClientForm";

export const AddClient = () => {

  const phone = useMediaQuery("(max-width:480px)");

  return (
    <>
      <div className="w-100 px-3">

        <div className="bg-white pb-1 pt-1 mt-3 mb-3 r-15 pl-2">
          <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
            Agregar cliente
          </p>
          <p className="m-0 text-secondary">* Campos obligatorios</p>
        </div>
        <div className="mt-2 w-100 d-flex justify-content-between r-15 d-flex flex-column">
          <ClientForm />
        </div>
      </div>
    </>
  );
};
