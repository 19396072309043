import React, { useState, useEffect, useContext } from "react";

import { Grid } from "@mui/material";
import { CardGraph } from "../Card/CardGraph";

const modalOption = {
  vAxis: {
    title: "Producto",
    minValue: 0,
    titleTextStyle: {
      color: "black",
      bold: true,
    },
  },
  hAxis: {
    title: "Piezas",
    minValue: 0,
    titleTextStyle: {
      color: "black",
      bold: true,
    },
  },
  annotations: {
    textStyle: {
      // The color of the text.
      color: "#000",
    },
  },
};

export const options = {
  chartArea: { width: "65%", height: "80%" },
  legend: "none",
  colors: ["#FF5C51"],
};

export const LessSalesGraph = ({ data, isLoading, client, filters }) => {
  return (
    <>
      <Grid item xs={4}>
        <CardGraph
          title={"Menos Vendidos"}
          type={"BarChart"}
          data={data}
          options={options}
          modalOption={modalOption}
          isLoading={isLoading}
          client={client}
          filters={filters}
        />
      </Grid>
    </>
  );
};
