import React from 'react'

import { useMediaQuery } from '@mui/material'
import { Close } from '@mui/icons-material'


import { useNavigate } from 'react-router-dom'
import { AdminProfileForm } from '../../components/Forms/AdminProfileForm'

export const EditInfoAdmin = () => {

  const navigate = useNavigate();

  const phone = useMediaQuery('(max-width:480px)');

  return (
    <>
      <div className='w-100 px-3'>

        <div className='bg-white r-15 mt-3 mb-3 px-3 pt-2 pb-1'>
          <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}> Editar información </p>
        </div>

        <div className='mt-2 w-100 d-flex justify-content-between r-15 d-flex flex-column' >
          <AdminProfileForm />
        </div>
      </div>
    </>
  )
}

