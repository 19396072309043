import React, { useState } from "react";
import * as Yup from "yup";

import {
  Typography,
  TextField,
  useMediaQuery,
  IconButton,
} from "@mui/material";

import { AcceptButton } from "../Buttons/AcceptButton";

import { Close } from "@mui/icons-material";
import { useFormik } from "formik";
import { InventoryAPI } from "../../api/Inventory";
import { CancelEntryButton } from "../Buttons/CancelEntryButton";
import { useMemo } from "react";

export const ModalEditEntry = ({
  info,
  product,
  handleClose,
  setOpenBD,
  setOpenSuccess,
  setOpenError,
  setErrorField,
  RequestData,
  action,
  GetDataEntries,
}) => {
  const phone = useMediaQuery("(max-width:480px)");
  const [errorPieces, setErrorPieces] = useState(false);

  const inventoryAPI = useMemo(() => new InventoryAPI(), []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "85%" : 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
  };

  const handleCancel = () => {
    if (product) {
      EditInventoryEntry(4);
    } else {
      cancelFolio();
    }
    handleClose();
  };

  const cancelFolio = async () => {
    setOpenBD(true);
    let data = new FormData();
    data.append("entryId", info.folio);

    const response = await inventoryAPI.deleteFolioInventoryEP(data);

    if (response.status == 200) {
      setOpenBD(false);
      setOpenSuccess(true);
      //GetDataEntries();

      setTimeout(() => {
        setOpenSuccess(false);
        RequestData();
      }, 1500);
    } else {
      // console.log(response);
      setOpenBD(false);
      setErrorField(response.response.data.msg);
      setOpenError(true);
    }
  };

  const EditInventoryEntry = async (obj) => {
    setOpenBD(true);
    let data = new FormData();
    data.append("entryId", info.folio);
    data.append("productLineId", product.productLineId);

    if (obj != 4) {
      data.append("pieces", obj.pieces);
      data.append("status", 1);
    }

    let response;
    if (obj == 4) {
      response = await inventoryAPI.deleteProductEntryEP(data);
    } else if (obj != 4) {
      response = await inventoryAPI.editInventoryEntryEP(data);
    }

    if (response.status == 200) {
      setOpenBD(false);
      setOpenSuccess(true);
      //GetDataEntries();

      setTimeout(() => {
        setOpenSuccess(false);
        RequestData();
      }, 1500);
    } else {
      // console.log(response);
      setOpenBD(false);
      setErrorField(response.response.data.msg);
      setOpenError(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      pieces: product ? product.pieces : null,
    },
    validationSchema: Yup.object({
      pieces: Yup.number()
        .required("Campo obligatorio")
        .integer("Debe ser número entero")
        .typeError("Debe ser un número")
        .min(1, "Debe ser mayor a 0")
        .nullable(),
    }),

    onSubmit: (values) => {
      const obj = {
        ...values,
      };
      if (product.pieces <= values.pieces) {
        setErrorPieces(true);
      } else {
        EditInventoryEntry(obj);
        handleClose();
      }
    },
  });

  return (
    <div className="p-3">
      {action != "cancel" ? (
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <p id="modal-modal-description" className="ds-blue-font h5 m-0">
              Editar cantidad:
            </p>
            <IconButton onClick={handleClose}>
              <Close fontSize="large" />
            </IconButton>
          </div>
          <div className="d-flex justify-content-start mt-2 mb-2">
            <Typography id="modal-modal-description">
              Producto:{" "}
              <span className="font-weight-bold">{product.description}</span>
            </Typography>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div
              className={`d-flex flex-column ${
                phone ? "" : "justify-content-center"
              }`}
            >
              <div
                className={`d-flex align-items-center mr-2 w-50 ${
                  phone && "mb-2"
                }`}
                // style={{ minWidth: `${phone ? "115px" : "120px"}` }}
              >
                <p className="h6 w-100" htmlFor="pieces">
                  Cantidad:*
                </p>
              </div>
              <div className="d-flex align-items-center w-100 ">
                <div className="d-flex flex-column w-100 ">
                  <TextField
                    error={formik.touched.pieces && formik.errors.pieces}
                    size="small"
                    label={"Piezas"}
                    variant="outlined"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.pieces}
                    name={"pieces"}
                    id={"pieces"}
                    helperText={
                      formik.touched.pieces && formik.errors.pieces ? (
                        <div>{formik.errors.pieces}</div>
                      ) : null || errorPieces ? (
                        <div class="text-danger">
                          La cantidad no puede ser igual o mayor, cancela la
                          venta y crea una nueva.
                        </div>
                      ) : null
                    }
                  />
                </div>
              </div>
            </div>

            <div
              className={`d-flex mt-3 ${
                phone ? "flex-column" : "justify-content-end"
              }`}
            >
              <AcceptButton text={"Editar"} type="submit" width={"100%"} />
            </div>
          </form>
        </div>
      ) : (
        <div className="w-100">
          <div className="d-flex justify-content-between align-items-center">
            <p id="modal-modal-description" className="ds-blue-font h5 m-0">
              Cancelar entrada:
            </p>
            <IconButton onClick={handleClose}>
              <Close fontSize="large" />
            </IconButton>
          </div>

          <div className="w-100 text-center">
            <p id="modal-modal-description" className="ds-blue-font h5 m-0">
              Estas a punto de cancelar:
            </p>
            <p className="font-weight-bold">
              {product
                ? product.description
                : `folio ${info.folio} con ${info.products.length} productos`}
            </p>
          </div>
          <div className="w-100 text-center mt-2">
            <p id="modal-modal-description" className="ds-blue-font h6 m-0">
              ¿Deseas continuar?
            </p>
          </div>

          <div
            className={`d-flex mt-3 ${
              phone ? "flex-column" : "justify-content-end"
            }`}
          >
            {/* <CancelButton text={"Salir"} onClick={handleClose} width={phone ? "100%" : ""} /> */}
            <CancelEntryButton
              text={"Cancelar envío"}
              onClick={handleCancel}
              width={"100%"}
            />
            {/* <AcceptButton text={"Editar"} type="submit" width={"100%"} /> */}
          </div>
        </div>
      )}
    </div>
  );
};
