import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { List } from "@mui/material";
import React, { useState } from "react";
import { LogoutRounded } from "@mui/icons-material";
import { useAuthContext } from "../../context/AuthProvider";
import { StyledMenuItem, StyledPopover } from "./SidebarStyles";

export const LogoutSection = ({ sidebarOpen }) => {
  const { logoutFunction, isLoading, openBD } = useAuthContext();

  const [anchorEl, setAnchorEl] = useState(null);
  const popoverOpen = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <List>
      <ListItem
        sx={{
          display: "block",
          py: 0.5,
          px: sidebarOpen ? 1 : 0.5,
        }}
        key={"Cerrar sesión"}
        onMouseOver={(e) => {
          handlePopoverOpen(e);
        }}
        onMouseLeave={() => handlePopoverClose()}
      >
        <StyledMenuItem open={sidebarOpen} onClick={logoutFunction}>
          <ListItemIcon
            sx={{
              color: "inherit",
              minWidth: 0,
            }}
          >
            <LogoutRounded />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              fontSize: "16px",
              fontWeight: "bold",
            }}
            primary={"Cerrar sesión"}
            sx={{ opacity: sidebarOpen ? 1 : 0 }}
          />
        </StyledMenuItem>
      </ListItem>
      {!sidebarOpen && (
        <StyledPopover
          id="mouse-over-popover"
          open={popoverOpen}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handlePopoverClose}
          sx={{ pointerEvents: "none" }}
          PaperProps={{
            onMouseLeave: handlePopoverClose,
          }}
        >
          <div
            style={{
              pointerEvents: "auto",
            }}
          >
            <ListItemText
              primary={"Cerrar sesión"}
              primaryTypographyProps={{
                fontSize: "15px",
                fontWeight: "bold",
              }}
              className={"px-3 py-1"}
            />
          </div>
        </StyledPopover>
      )}
    </List>
  );
};
