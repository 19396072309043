import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  useMediaQuery,
  Grid,
  Modal,
  MenuItem,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Backdrop,
  Autocomplete,
  Tooltip,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  styled,
  tableCellClasses,
  Paper,
  InputAdornment,
} from "@mui/material";
import { Add, DeleteForever } from "@mui/icons-material";

import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useNavigate, useLocation } from "react-router-dom";

import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";
import { UploadButton } from "../Buttons/UploadButton";
import { DeleteDocButton, DocsButton } from "../Buttons/DeleteDocButton";

import { ModalError } from "../Modal/ModalError";

import readXlsxFile from "read-excel-file";

import { ProductsAPI } from "../../api/Products";
import LoaderDS from "../../assets/LoaderDS.svg";
import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";
import { SalesB2BAPI } from "../../api/SalesB2B";
import { ModalConfirmation } from "../Modal/ModalConfirmation";
import { getDayjsHyphenFormatted } from "../../helpers/getDateCleaned";
import { WarehouseAPI } from "../../api/Warehouse";
import { DownloadButtonExcel } from "../Excel/DownloadButtonExcel";
import { ProductSaleB2B } from "../headers";
import { useMemo } from "react";
import { sumBy } from "lodash";
import { ModalSuccess } from "../Modal/ModalSuccess";
import { theme } from "../../utils/theme";
import dayjs from "dayjs";
import { GraphsAPI } from "../../api/Graphs";

const StyledTableCell = styled(TableCell)(({ theme, valid }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
    padding: "4px",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    color: valid ? "inherit" : "red",
    fontSize: 14,
    align: "center",
    padding: "checkbox",
    textAlign: "center",
  },
}));

export const B2BSaleForm = () => {
  const navigate = useNavigate();
  const { height, width } = useViewPortDimensions();
  const phone = useMediaQuery("(max-width:480px)");

  //--------------------MODAL STATES----------------------
  const [openWarning, setOpenWarning] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emptyProductsError, setEmptyProductsError] = useState(false);
  const [orderCode, setOrderCode] = useState("");
  //--------------------PRODUCT STATES----------------------

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [product, setProduct] = useState({ id: 0, descripcion: "" });
  const [pieces, setPieces] = useState(null);
  //const [stock, setStock] = useState(0);
  const [isLoadingValidation, setIsLoadingValidation] = useState(false);

  //--------------------ACTIONS STATES----------------------
  //const [stockError, setStockError] = useState(false);
  const [duplicityError, setDuplicityError] = useState(false);
  const [submitData, setSubmitData] = useState({});
  //--------------------BUSINESS STATES----------------------

  const [businessList, setBusinessList] = useState([]);
  const [locations, setLocations] = useState([]);

  //--------------------BRANCH STATES----------------------

  const [branches, setBranches] = useState([]);
  //const [cedis, setCedis] = useState([]);

  //const [cedi, setCedi] = useState(0);
  const [branch, setBranch] = useState(0);

  //--------------------DATES STATES----------------------
  const today = new Date();
  const [newDate, setNewDate] = useState("");

  //--------------------SALE STATES----------------------
  const currencies = [
    { id: 1, value: "MXN" },
    { id: 2, value: "USD" },
    { id: 3, value: "EUR" },
  ];

  const paymentMethods = [
    { id: 0, value: "Otro"},
    { id: 1, value: "Transferencia" },
    { id: 2, value: "Efectivo" },
    { id: 3, value: "Crédito" },
    { id: 4, value: "Cheque" },
  ];
  const [purchaseOrderFile, setPurchaseOrderFile] = useState(null);
  const [purchaseOrderFile2, setPurchaseOrderFile2] = useState(null);
  const inputPdfFile = useRef(null);
  const inputPdfFile2 = useRef(null);

  //--------------------EXCEL FILE STATES----------------------
  const [excelFile, setExcelFile] = useState(false);
  const inputExcelFile = useRef(null);

  //--------------------USEEFFECT---------------------

  useEffect(() => {
    //getBranches();
    //getCedis();
    getBusiness();
    GetBranchesLIstEP();
    //getProductsListCleaned();
  }, []);

  /* useEffect(() => {
    if (product) {
      if (product.id != 0) {
        getProductStock();
      }
    } else {
      setStock(0);
    }
  }, [product, cedi, branch]); */

  //--------------------FORM HANDLER----------------------

  const handleCloseSuccess = (path) => {
    if (path == "nuevo") {
      window.location.reload();
    } else {
      navigate(`/cliente/ventas/B2B/`);
    }
  };

  //--------------------FILE HANDLERS----------------------
  const handleClickInput = (input) => {
    input.current.value = "";
    input.current.click();
    input.current.blur();
  };

  const removePurchaseOrder = () => {
    inputPdfFile.current.value = "";
    setPurchaseOrderFile(null);
    formik.setFieldValue("purchaseOrderFile", false);
  };

  const removePurchaseOrder2 = (indexToRemove) => {
    inputPdfFile.current.value = "";
    // setPurchaseOrderFile2(null);

    const newData = [...purchaseOrderFile2]; 

    if(newData.length !== 1){
      newData.splice(indexToRemove, 1); 
      setPurchaseOrderFile2(newData);
    }else{
      setPurchaseOrderFile2(null);
      formik.setFieldValue("purchaseOrderFile", false);
    }
    
    
    // formik.setFieldValue("purchaseOrderFile", false);
  };

  const handlePurchaseOrder = ({ files }) => {
    if (!files) {
      return;
    }
    const file = files[0];
    setPurchaseOrderFile(file);
    formik.setFieldValue("excelFile", true);
  };

  const handlePurchaseOrder2 = ({ files }) => {
    if (!files) {
      return;
    }
    // const file = files[0];
    setPurchaseOrderFile2(files);
    console.log("numero de arvhibos", files.length);
    formik.setFieldValue("excelFile", true);
  };

  const removeExcelFile = () => {
    inputExcelFile.current.value = "";
    setExcelFile(null);
    setSelectedProducts([]);
    formik.setFieldValue("excelFile", false);
    formik.setFieldValue("validExcelData", true);
  };

  const handleExcelFile = ({ files }) => {
    if (!files) {
      return;
    }
    const file = files[0];
    setExcelFile(file);
    setSelectedProducts([]);
    formik.setFieldValue("excelFile", true);
    validateExcelData(file);
  };

  const validateExcelData = (excelFile) => {
    setIsLoadingValidation(true);
    // Excel validation Schema
    const excelValidationSchema = Yup.object({
      SKU: Yup.string()
        .matches(/^[a-zA-Z0-9\-_]+$/, "ser alfanumérico")
        .max(128, "tener máximo 128 carácteres"),
      Piezas: Yup.number()
        .typeError("ser un número sin espacios")
        .integer("ser un número entero")
        .min(1, "ser mayor a 1")
        .max(1000000, "ser menor a 1,000,000"),
      
    });

    // Excel Schema, this is for extract data from the file and convert it to JSON
    const schema = {
      SKU: {
        prop: "article",
        validate: (value) => {
          excelValidationSchema.validateSync({ SKU: value });
        },
        type: String,
        required: true,
      },
      Piezas: {
        prop: "quantity",
        validate: (value) => {
          excelValidationSchema.validateSync({ Piezas: value });
        },
        required: true,
      },
    };

    // This function read the file and transform to json, excecute validation schema and return the rows and the errors
    readXlsxFile(excelFile, { schema }).then(({ rows, errors }) => {
      // console.log(rows);
      // console.log(errors);

      if (rows.length > 1000) {
        setErrorMessage("Estas intentando cargar más de 1000 productos.");
        setOpenError(true);
        setIsLoadingValidation(false);
        return;
      }

      if (errors.length > 0) {
        formik.setFieldValue("validExcelData", false);
        const errorMessages = getErrorMessages(errors);
        setErrorMessage(errorMessages);
        setSelectedProducts(() =>
          rows.map((p, index) => {
            const invalidProduct = errors.find((err) => index == err.row - 2);
            if (invalidProduct) {
              return {
                ...p,
                msg: `${invalidProduct.column} debe ${
                  invalidProduct.error == "required"
                    ? "estar lleno"
                    : invalidProduct.error
                }`,
              };
            } else {
              return { ...p, msg: "" };
            }
          })
        );
        setOpenError(true);
        setIsLoadingValidation(false);
        return;
      }

      // Extract all sku in a single array
      const skuArr = rows.map((products) => {
        return products.sku;
      });
      //Validate sku duplicity in the data
      const duplicatedData = skuArr.filter(
        (item, index) => item != undefined && skuArr.indexOf(item) !== index
      );
      if (duplicatedData.length > 0) {
        formik.setFieldValue("validExcelData", false);
        setErrorMessage("Tienes productos con ID duplicado.");
        setOpenError(true);
        setIsLoadingValidation(false);
        setSelectedProducts(() =>
          rows.map((p) => {
            if (duplicatedData.includes(p.sku)) {
              return { ...p, msg: "producto duplicado" };
            } else {
              return { ...p, msg: "" };
            }
          })
        );
        return;
      }
      setSelectedProducts(rows);
      formik.setFieldValue("validExcelData", true);
      setIsLoadingValidation(false);
      //validateStock(rows);
    });
  };

  const getErrorMessages = (errors) => {
    const errorMessages = errors.map((error) => {
      if (error.error == "required") {
        return `Fila ${error.row}: tienes un campo vacío CHIN`;
      } else {
        return `Fila ${error.row}: la columna ${error.column} debe ${error.error}`;
      }
    });
    return errorMessages;
  };

  //--------------------REQUESTS---------------------

  // APIS
  const productAPI = useMemo(() => new ProductsAPI(), []);
  const warehouseAPI = useMemo(() => new WarehouseAPI(), []);
  const salesB2BAPI = useMemo(() => new SalesB2BAPI(), []);

  /* const getProductsListCleaned = async () => {
    let data = new FormData();

    const response = await productAPI.productsListCleanedEP(data);
    if (response.status == 200) {
      setProducts(response.data.products);
    } else {
      console.log(response);
    }
  }; */

  //const [stockIsLoading, setStockIsLoading] = useState(false);
  /* const getProductStock = async () => {
    setStockIsLoading(true);
    let data = new FormData();
    data.append("product", product.id);

    if (cedi != 0) {
      data.append("branch", cedi);
    }
    if (branch != 0) {
      data.append("branch", branch);
    }

    const response = await productAPI.productStockEP(data);
    if (response.status == 200) {
      setStock(response.data.stock);
      setStockIsLoading(false);
    } else {
      console.log(response);
    }
  }; */

  /* const getCedis = async () => {
    let data = new FormData();

    const response = await warehouseAPI.cedisList(data);
    if (response.status == 200) {
      setCedis(response.data.cedis);
    } else {
      console.log(response);
    }
  }; */

  /* const getBranches = async () => {
    let data = new FormData();

    const response = await warehouseAPI.getBranchesList(data);
    if (response.status == 200) {
      setBranches(response.data.branches);
    } else {
      console.log(response);
    }
  }; */

  const getBusiness = async () => {
    try {
      console.log("intentando conectar")
      const response = await salesB2BAPI.getCleanBusiness();
      if (response.status === 200) {
        console.log("La solicitud fue exitosa", response.data);
        setBusinessList(response.data)
      } else {
        console.error("Error en la solicitud:", response);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };

  const getLocations = async (business) => {
    // let data = new FormData();
    // data.append("business", business);
    // data.append("status_business", true);

    let data = business;
    const response = await salesB2BAPI.getCleanLocationsEP(data);
    if (response.status == 200) {
      console.log(response.data);
      setLocations(response.data);
    } else {
      console.log(response);
    }
  };

  /* const validateStock = async (products) => {
    setIsLoadingValidation(true);
    const data = new FormData();
    data.append("products", JSON.stringify(products));
    data.append(
      "origin",
      formik.values.departure == 1 ? formik.values.cedi : formik.values.branch
    );

    const response = await salesB2BAPI.validateStockEP(data);
    if (response.status == 200) {
      setIsLoadingValidation(false);
      if (response.data.length > 0) {
        setOpenError(true);
        setErrorMessage(response.data.map((p) => `Sku ${p.sku}: ${p.msg}`));
        setSelectedProducts(() =>
          products.map((p) => {
            const badProduct = response.data.find((el) => el.sku == p.sku);
            if (badProduct != undefined) {
              return { ...p, msg: badProduct.msg };
            } else {
              return { ...p, msg: "" };
            }
          })
        );
        formik.setFieldValue("validExcelData", false);
      } else {
        setSelectedProducts(products);
        formik.setFieldValue("validExcelData", true);
      }
    } else {
      setIsLoadingValidation(false);
      setOpenError(true);
      setErrorMessage(
        "No pudimos validar tus productos. Vuelve a cargar el archivo."
      );
      setExcelFile(null);
      setSelectedProducts([]);
      formik.setFieldValue("excelFile", false);
      formik.setFieldValue("validExcelData", false);
      console.log(response);
    }
  }; */

  //--------------------PRODUCT HANDLERS----------------------

  const resetInputs = () => {
    setProduct({ id: 0, descripcion: "" });
    setPieces(0);
  };

  const validateDuplicity = () => {
    const duplicatedProduct = selectedProducts.filter(
      (el) => el.id == product.id
    );
    if (duplicatedProduct.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  /* const addProduct = () => {
    if (!validateDuplicity()) {
      setDuplicityError(false);
      if (product.description != "" && pieces != 0) {
        /* if (parseInt(pieces, 10) < stock) {
          setStock(0);
          resetInputs();
          setStockError(false);
        } else {
          setStockError(true);
        } 
        resetInputs();
        setSelectedProducts([
          ...selectedProducts,
          { ...product, quantity: parseInt(pieces, 10) },
        ]);
      }
    } else {
      setDuplicityError(true);
    }
  }; */

  const deleteProduct = (description) => {
    setSelectedProducts(
      selectedProducts.filter((product) => product.description !== description)
    );
  };

  const handleClick = (event, action, info) => {
    switch (action) {
      case "delete":
        deleteProduct(info.description);
        break;
    }
  };

  const graphAPI = useMemo(() => new GraphsAPI(), []);

  const GetBranchesLIstEP = async () => {
    let data = new FormData();
    
    const response = await graphAPI.branchesListAllEP(data);
    if (response.status == 200) {
      const aux = response.data.branches;
      setBranches([ ...aux]);
    } else {
      console.log(response);
    }
  }

  //-------------------FORM SUBMIT----------------------

  const createSale = async () => {
    setIsLoading(true);
    let data = new FormData();

    data.append("sucursal", branch);
    /* data.append(
      "origin",
      submitData.departure == 1 ? submitData.cedi : submitData.branch
    ); */
    data.append("business_location", submitData.arriveLocation);
    data.append(
      "estimated_date",
      getDayjsHyphenFormatted(submitData.arriveDate)
    );
    data.append("total_amount", submitData.amount);
    data.append("type_coin", submitData.currency);
    data.append("payment_method", submitData.paymentMethod);
    if (submitData.reference) {
      data.append("reference", submitData.reference);
    }
    if (purchaseOrderFile) {
      data.append("purchase_order", purchaseOrderFile);
    }
    console.log(JSON.stringify(selectedProducts))
    data.append("items", JSON.stringify(selectedProducts));
    data.append("number_pieces", sumBy(selectedProducts, "quantity"));
    data.append("number_articles", selectedProducts.length);
    const response = await salesB2BAPI.createSale(data);
    if (response.status == 201) {
      setIsLoading(false);
      setOrderCode(response.data.code);
      setOpenSuccess(true);
    } else {
      setIsLoading(false);
      setOpenError(true);
      // console.log("respuesta mala", response.response.data)
      if(response.response.data.reference[0]){
        setErrorMessage(response.response.data.reference[0]);
      }
      if(response.response.data.invalid_sku[0]){
        setErrorMessage(response.response.data.invalid_sku[0]);
      }
      
    }
  };

  //-------------------------------------------------

  //FIELDS
  //-------------------------------------------------

  /* const departure = [
    { keyName: "Cedi", type: 1, value: true },
    { keyName: "Sucursal", type: 2, value: true },
  ]; */

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      business: "",
      //departure: "1",
      //cedi: "",
      //branch: "",
      arriveLocation: "",
      arriveDate: null,
      amount: "",
      currency: "MXN",
      paymentMethod: "Otro",
      reference: "",
      purchaseOrderFile: false,
      purchaseOrderFile2: false,
      //massive: false,
      excelFile: false,
      validExcelData: true,
    },

    validationSchema: Yup.object({
      business: Yup.string().required("Campo obligatorio"),
      /* branch: Yup.string().when("departure", {
        is: (value) => value == 2,
        then: Yup.string().required("Campo obligatorio"),
      }),
      cedi: Yup.string().when("departure", {
        is: (value) => value == 1,
        then: Yup.string().required("Campo obligatorio"),
      }), */
      arriveLocation: Yup.string().required("Campo obligatorio"),
      arriveDate: Yup.date().required("Campo obligatorio"),
      amount: Yup.number()
        .required("Campo obligatorio")
        .typeError("Solo números"),
      /* excelFile: Yup.bool().when("massive", {
        is: true,
        then: Yup.bool().oneOf([true], "Campo obligatorio"),
      }), */
      /* validExcelData: Yup.bool().when("massive", {
        is: true,
        then: Yup.bool().oneOf([true], "El archivo contiene errores"),
      }), */
      excelFile: Yup.bool().oneOf([true], "Campo obligatorio"),
      validExcelData: Yup.bool().oneOf([true], "El archivo contiene errores"),
    }),
    onSubmit: (values) => {
      if (selectedProducts.length > 0) {
        setSubmitData(values);
        setOpenWarning(true);
      } else {
        setEmptyProductsError(true);
      }
    },
  });

  const onKeyDown = (e) => {
    //Disable manual date
    e.preventDefault();
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          rowSpacing={2}
          columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}
          className=""
        >
          {" "}
          {/*---Contenedor padre---*/}
          <Grid item xs={6}>
            {" "}
            {/*---Ocupara mitad de pantalla---*/}
            <Grid
              item
              xs={12}
              className={`border bg-white r-15 ${phone ? "p-2" : "p-3"}`}
            >
              {" "}
              {/*---Altura de 100 para que ocupe verticalmente---*/}
              <p className={`ds-blue-font ${phone ? "h6 mb-1" : "h5 mb-2"}`}>
                Detalles de envío
              </p>
              {/*-------------SUCURSAL INPUT-----------*/}
              <div
                key={"business"}
                className={` d-flex flex-column px-2 ${
                  phone ? "mb-1" : "mb-3"
                }`}
              >
                <p
                  className={`mb-1 ${phone ? "xs-font" : ""}`}
                  htmlFor={"business"}
                >{`${"Sucursal"}: ${"*"}`}</p>

                <TextField
                  select
                  label="Sucursal"
                  size="small"
                  fullWidth
                  value={formik.values.branch}
                  name={"branch"}
                  id={"branch"}
                  onChange={(event, value) => {
                    alert(event.target.value);
                    formik.handleChange(event);
                    setBranch(event.target.value);
                  }}
                  error={formik.touched.business && formik.errors.business}
                  helperText={
                    formik.touched.business && formik.errors.business
                      ? formik.errors.business
                      : null
                  }
                  InputLabelProps={phone && { style: { fontSize: 13 } }}
                  InputProps={phone && { style: { fontSize: 13 } }}
                >
                  {branches.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.sucursal}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {/*-------------BUSINESS INPUT-----------*/}
              <div
                key={"business"}
                className={` d-flex flex-column px-2 ${
                  phone ? "mb-1" : "mb-3"
                }`}
              >
                <p
                  className={`mb-1 ${phone ? "xs-font" : ""}`}
                  htmlFor={"business"}
                >{`${"Negocio"}: ${"*"}`}</p>

                <TextField
                  select
                  label="Negocios"
                  size="small"
                  fullWidth
                  value={formik.values.business}
                  name={"business"}
                  onChange={(event, value) => {
                    formik.handleChange(event);
                    alert(event.target.value)
                    getLocations(event.target.value);
                  }}
                  error={formik.touched.business && formik.errors.business}
                  helperText={
                    formik.touched.business && formik.errors.business
                      ? formik.errors.business
                      : null
                  }
                  InputLabelProps={phone && { style: { fontSize: 13 } }}
                  InputProps={phone && { style: { fontSize: 13 } }}
                >
                  {businessList.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {/*-------------DEPARTURE INPUT-----------*/}
              {/* <div
                // key={"arrive"}
                className={`d-flex flex-column w-100 px-2 ${
                  phone ? "mb-1" : "mb-3"
                }`}
              >
                <p
                  className={`mb-1 ${phone ? "xs-font" : ""}`}
                  htmlFor={"departure"}
                >{`${"Origen"}: ${"*"}`}</p>
                <div
                  className={`d-flex align-items-center ${
                    phone && "flex-column"
                  }`}
                >
                  <div className={`${phone ? "w-100" : " mr-3 w-50"}`}>
                    <TextField
                      select
                      size="small"
                      error={
                        formik.touched.departure && formik.errors.departure
                      }
                      label="Surcursal/ cedi"
                      value={formik.values.departure}
                      name={"departure"}
                      id={"departure"}
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.setFieldValue("branch", "");
                        formik.setFieldValue("cedi", "");
                        setBranch(0);
                        setCedi(0);
                      }}
                      fullWidth
                      helperText={
                        formik.touched.departure && formik.errors.departure
                          ? formik.errors.departure
                          : null
                      }
                      InputLabelProps={phone && { style: { fontSize: 13 } }}
                      InputProps={phone && { style: { fontSize: 13 } }}
                    >

                      {departure.map((dep) => (
                        <MenuItem key={dep.keyName} value={dep.type}>
                          {dep.keyName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  {formik.values.departure == 1 && (
                    <div className={phone ? `w-100` : "w-50"}>
                      <TextField
                        select
                        size="small"
                        error={formik.touched.cedi && formik.errors.cedi}
                        label="CEDI"
                        value={formik.values.cedi}
                        name={"cedi"}
                        id={"cedi"}
                        onChange={(e) => {
                          formik.handleChange(e);
                          setCedi(e.target.value);
                        }}
                        fullWidth
                        helperText={
                          formik.touched.cedi && formik.errors.cedi
                            ? formik.errors.cedi
                            : null
                        }
                        InputLabelProps={phone && { style: { fontSize: 13 } }}
                        InputProps={phone && { style: { fontSize: 13 } }}
                      >
                        {cedis.map((cedi) => (
                          <MenuItem key={cedi.id} value={cedi.id}>
                            {cedi.cedi}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  )}
                  {formik.values.departure == 2 && (
                    <div className={phone ? `w-100` : "w-50"}>
                      <TextField
                        select
                        size="small"
                        error={formik.touched.branch && formik.errors.branch}
                        label="Sucursal"
                        value={formik.values.branch}
                        name={"branch"}
                        id={"branch"}
                        onChange={(e) => {
                          formik.handleChange(e);
                          setBranch(e.target.value);
                        }}
                        fullWidth
                        helperText={
                          formik.touched.branch && formik.errors.branch
                            ? formik.errors.branch
                            : null
                        }
                        InputLabelProps={phone && { style: { fontSize: 13 } }}
                        InputProps={phone && { style: { fontSize: 13 } }}
                      >
                        {branches.map((branch) => (
                          <MenuItem key={branch.id} value={branch.id}>
                            {branch.sucursal}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  )}
                </div>
              </div> */}
              {/*------------- CONTAINER FOR ALIGN INPUTS-----------*/}
              <div className={`d-flex ${phone && "flex-column"}`}>
                {/*-------------ARRIVE INPUT-----------*/}
                <div
                  key={"salida"}
                  className={`d-flex flex-column pl-2 ${
                    phone ? "mb-1 w-100 pr-2" : "mb-3 w-50 mr-2"
                  }`}
                >
                  <p
                    className={`mb-1 ${phone ? "xs-font" : ""}`}
                    htmlFor={"arriveLocation"}
                  >{`${"Destino"}: ${"*"}`}</p>

                  <TextField
                    select
                    size="small"
                    error={
                      formik.touched.arriveLocation &&
                      formik.errors.arriveLocation
                    }
                    label="Ubicaciones"
                    value={formik.values.arriveLocation}
                    name={"arriveLocation"}
                    id={"arriveLocation"}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    fullWidth
                    helperText={
                      formik.touched.arriveLocation &&
                      formik.errors.arriveLocation
                        ? formik.errors.arriveLocation
                        : null
                    }
                    InputLabelProps={phone && { style: { fontSize: 13 } }}
                    InputProps={phone && { style: { fontSize: 13 } }}
                  >
                    {locations.length == 0 ? (
                      <MenuItem>El negocio no tiene ubicaciones</MenuItem>
                    ) : (
                      locations.map((location, index) => (
                        <MenuItem key={index} value={location.id}>
                          {location.name}
                        </MenuItem>
                      ))
                    )}
                  </TextField>
                </div>
                {/*-------------ARRIVE DATE INPUT-----------*/}
                <div
                  className={`d-flex pl-2 flex-column pr-2 ${
                    phone ? "mb-1 w-100" : "mb-3 w-50"
                  }`}
                >
                  <div className={`d-flex flex-column ${phone && "mb-2"}`}>
                    <p
                      className={`mb-1 ${phone ? "xs-font" : ""}`}
                      htmlFor={"arriveDate"}
                    >
                      Fecha de entrega:*{" "}
                    </p>
                    <DesktopDatePicker
                      minDate={dayjs(today)}
                      label={"Fecha de entrega"}
                      name={"arriveDate"}
                      format="DD/MM/YYYY"
                      value={formik.values.arriveDate}
                      onChange={(value) => {
                        formik.setFieldValue("arriveDate", value);
                      }}
                      allowSameDateSelection
                      slotProps={{
                        textField: {
                          size: "small",
                          error:
                            formik.touched.arriveDate &&
                            formik.errors.arriveDate,
                          fullWidth: true,
                          onKeyDown: onKeyDown,
                          helperText:
                            formik.touched.arriveDate &&
                            formik.errors.arriveDate
                              ? formik.errors.arriveDate
                              : null,
                        },
                        InputLabelProps: {
                          style: { fontSize: phone ? 13 : "inherit" },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              className={`border bg-white r-15 mt-3 ${phone ? "p-2" : "p-3"}`}
            >
              {" "}
              {/*---Altura de 100 para que ocupe verticalmente---*/}
              <p className={`ds-blue-font ${phone ? "h6 mb-1" : "h5 mb-2"}`}>
                Detalles de venta
              </p>
              <div className={`d-flex w-100 ${phone && "flex-column"}`}>
                <div
                  className={`d-flex mr-2 ${
                    phone ? "mb-1 w-100 px-2" : "mb-3 w-50 pl-2"
                  }`}
                >
                  {/*-------------AMOUNT-----------*/}

                  <div className="w-100">
                    <p
                      className={`mb-1 ${phone ? "xs-font" : ""}`}
                      htmlFor={"amount"}
                    >{`${"Monto"}: ${"*"}`}</p>
                    <TextField
                      label="Monto"
                      size="small"
                      fullWidth
                      value={formik.values.amount}
                      name={"amount"}
                      onChange={(event, value) => {
                        formik.handleChange(event);
                      }}
                      error={formik.touched.amount && formik.errors.amount}
                      helperText={
                        formik.touched.amount && formik.errors.amount
                          ? formik.errors.amount
                          : null
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        style: { fontSize: phone ? 13 : "inherit" },
                      }}
                      InputLabelProps={phone && { style: { fontSize: 13 } }}
                    />
                  </div>
                  {/*-------------CURRENCY-----------*/}
                  <div>
                    <p
                      className={`invisible mb-1 ${phone ? "xs-font" : ""}`}
                      htmlFor={"currency"}
                    >
                      {`${"Moneda"}: ${"*"}`}
                    </p>

                    <TextField
                      select
                      size="small"
                      error={formik.touched.currency && formik.errors.currency}
                      label="Moneda"
                      value={formik.values.currency}
                      name={"currency"}
                      id={"currency"}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      fullWidth
                      helperText={
                        formik.touched.currency && formik.errors.currency
                          ? formik.errors.currency
                          : null
                      }
                      InputLabelProps={phone && { style: { fontSize: 13 } }}
                      InputProps={phone && { style: { fontSize: 13 } }}
                    >
                      {currencies.map((currency) => (
                        <MenuItem key={currency.id} value={currency.value}>
                          {currency.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                {/*-------------PAYMENT METHOD-----------*/}
                <div
                  className={`ml-2 ${
                    phone ? "mb-1 w-100 pr-3" : "mb-3 w-50 ml-2 pr-2 "
                  }`}
                >
                  <p
                    className={`mb-1 ${phone ? "xs-font" : ""}`}
                    htmlFor={"paymentMethod"}
                  >{`${"Método de pago"}:`}</p>

                  <TextField
                    select
                    size="small"
                    error={
                      formik.touched.paymentMethod &&
                      formik.errors.paymentMethod
                    }
                    label="Método de pago"
                    value={formik.values.paymentMethod}
                    name={"paymentMethod"}
                    id={"paymentMethod"}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    fullWidth
                    helperText={
                      formik.touched.paymentMethod &&
                      formik.errors.paymentMethod
                        ? formik.errors.paymentMethod
                        : null
                    }
                    InputLabelProps={phone && { style: { fontSize: 13 } }}
                    InputProps={phone && { style: { fontSize: 13 } }}
                  >
                    {paymentMethods.map((method, index) => (
                      <MenuItem key={method.id} value={method.value}>
                        {method.value}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
              <div className={`d-flex w-100 ${phone && "flex-column"}`}>
                {/*-------------Reference-----------*/}
                <div
                  className={`mr-2 ${
                    phone ? "mb-1 w-100 px-2" : "mb-3 w-50 pl-2 "
                  }`}
                >
                  <p
                    className={`mb-1 ${phone ? "xs-font" : ""}`}
                    htmlFor={"reference"}
                  >{`${"Referencia interna"}:`}</p>

                  <TextField
                    size="small"
                    error={formik.touched.reference && formik.errors.reference}
                    label="Referencia interna"
                    value={formik.values.reference}
                    name={"reference"}
                    id={"reference"}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    fullWidth
                    helperText={
                      formik.touched.reference && formik.errors.reference
                        ? formik.errors.reference
                        : null
                    }
                    InputLabelProps={phone && { style: { fontSize: 13 } }}
                    InputProps={phone && { style: { fontSize: 13 } }}
                  />
                </div>
                {/*-------------BUY ORDER-----------*/}
                <div
                  className={`ml-2  ${
                    phone ? "mb-1 w-100 pr-3 " : "mb-3 w-50 pr-2"
                  }`}
                >
                  <input
                    name={"purchaseOrderFile"}
                    ref={inputPdfFile}
                    type="file"
                    accept=".pdf"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      handlePurchaseOrder(e.target);
                    }}
                  />
                  <label
                    htmlFor={"purchaseOrderFile"}
                    style={{ width: "100%" }}
                  >
                    <p
                      className={`mb-1 ${phone ? "xs-font" : ""}`}
                    >{`${"Orden de compra"}:`}</p>
                    <div className="d-flex ">
                      <UploadButton
                        text={
                          purchaseOrderFile
                            ? purchaseOrderFile.name
                            : "Cargar Archivo"
                        }
                        fileLoaded={purchaseOrderFile ? "pdf" : ""}
                        onClick={() => handleClickInput(inputPdfFile)}
                        border={"rgb(175, 175, 175, 1)"}
                        font={
                          purchaseOrderFile
                            ? theme.palette.secondary.main
                            : theme.palette.neutrals.darkGray
                        }
                        error={formik.errors.purchaseOrderFile}
                        touched={formik.touched.purchaseOrderFile}
                        height={"40px"}
                      />
                    </div>
                    <div className=" d-flex justify-content-between">
                      {formik.touched.purchaseOrderFile &&
                      formik.errors.purchaseOrderFile ? (
                        <p className="pl-3 mt-1 text-danger xs-font">
                          {formik.errors.purchaseOrderFile}
                        </p>
                      ) : null}
                    </div>
                    {purchaseOrderFile ? (
                      <div className="d-flex justify-content-end">
                        <DeleteDocButton
                          onClick={removePurchaseOrder}
                          text={"Eliminar"}
                        />
                      </div>
                    ) : null}
                  </label>
                </div>
                <div
                  className={`ml-2  ${
                    phone ? "mb-1 w-100 pr-3 " : "mb-3 w-50 pr-2"
                  }`}
                >
                  <input
                    name={"purchaseOrderFile2"}
                    ref={inputPdfFile2}
                    type="file"
                    accept=".pdf"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      handlePurchaseOrder2(e.target);
                    }}
                    multiple
                  />
                  <label
                    htmlFor={"purchaseOrderFile2"}
                    style={{ width: "100%" }}
                  >
                    <p
                      className={`mb-1 ${phone ? "xs-font" : ""}`}
                    >{`${"Otros archivos"}:`}</p>
                    <div className="d-flex ">
                      
                      <UploadButton
                        text={
                          purchaseOrderFile2
                            ? ""
                            : "Cargar Archivo"
                        }
                        fileLoaded={purchaseOrderFile2 ? "pdf" : ""}
                        onClick={() => handleClickInput(inputPdfFile2)}
                        border={"rgb(175, 175, 175, 1)"}
                        font={
                          purchaseOrderFile2
                            ? theme.palette.secondary.main
                            : theme.palette.neutrals.darkGray
                        }
                        error={formik.errors.purchaseOrderFile2}
                        touched={formik.touched.purchaseOrderFile2}
                        height={"40px"}
                        data={purchaseOrderFile2}
                        handleRemove={removePurchaseOrder2}
                      />
                      
                    </div>
                    <div className=" d-flex justify-content-between">
                      {formik.touched.purchaseOrderFile2 &&
                      formik.errors.purchaseOrderFile2 ? (
                        <p className="pl-3 mt-1 text-danger xs-font">
                          {formik.errors.purchaseOrderFile}
                        </p>
                      ) : null}
                    </div>
                    {/* {purchaseOrderFile2 ? (
                      <div className="d-flex justify-content-end">
                        <div className="d-flex justify-content-end">
                          <DeleteDocButton
                            p={"Eliminar todo"}
                            onClick={removePurchaseOrder2}
                            text={"Eliminar"}
                          />    
                        </div>
                      </div>
                    ) : null} */}
                  </label>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {" "}
            {/*---Ocupara mitad de pantalla---*/}
            <Grid
              item
              xs={12}
              style={{ height: "100%" }}
              className={`border bg-white p-3 r-15`}
            >
              <div className="mb-2 d-flex justify-content-between">
                <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
                  Productos
                </p>
                {/* <div className={`d-flex ${phone && "flex-column"}`}>
                  <FormControlLabel
                    sx={{
                      fontSize: "8px",
                      padding: 0,
                      margin: "2px 0 2px 16px",
                    }}
                    label={
                      <span className={`${phone ? "xs-font" : "small-font"}`}>
                        Individual
                      </span>
                    }
                    control={
                      <Checkbox
                        checked={!formik.values.massive}
                        sx={{
                          color: theme.palette.primary.main,
                          "&.Mui-checked": { color: theme.palette.primary.main },
                          padding: 0,
                          marginRight: 1,
                        }}
                        size="small"
                        onChange={(e) => {
                          formik.setFieldValue("massive", !e.target.checked);
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    sx={{
                      fontSize: "8px",
                      padding: 0,
                      margin: "2px 0 2px 16px",
                    }}
                    label={
                      <span className={`${phone ? "xs-font" : "small-font"}`}>
                        Masivo
                      </span>
                    }
                    control={
                      <Checkbox
                        checked={formik.values.massive}
                        sx={{
                          color: theme.palette.primary.main,
                          "&.Mui-checked": { color: theme.palette.primary.main },
                          padding: 0,
                          marginRight: 1,
                        }}
                        size="small"
                        onChange={(e) => {
                          formik.setFieldValue("massive", e.target.checked);
                        }}
                      />
                    }
                  />
                </div> */}
              </div>
              {/*formik.values.massive ? (
                <> */}
              {/*-------------INSTRUCTIONS-----------*/}

              <div className={` px-2 ${phone ? "mb-1" : "mb-2"}`}>
                <p className={`mb-1 ${phone ? "xs-font" : ""}`}>
                  Instrucciones:
                </p>
                <ol className="xs-font pl-4">
                  <li>Descargue el template y llene el archivo:</li>
                  <ul className="pl-4">
                    <li>
                      El ID solo puede contener números, letras, guión medio y
                      bajo.
                    </li>
                    <li>Máximo 1000 productos.</li>
                    <li>Máximo 1,000,000 piezas por productos.</li>
                    <li>Las piezas y el precio deben ser numéricos.</li>
                  </ul>
                </ol>
              </div>
              <div
                className={` d-flex align-items-center px-2 ${
                  phone ? "mb-1" : "mb-2"
                }`}
              >
                <p className={`mb-1 ${phone ? "xs-font" : ""}`}> Template:</p>
                <DownloadButtonExcel
                  text={phone ? "" : "Descargar"}
                  disabled={false}
                  headers={ProductSaleB2B}
                  data={[
                    {
                      sku: "AAA-12345",
                      quantity: 10,
                    },
                  ]}
                  sheetName={`Products template`}
                  getData={() => console.log("downloading template")}
                />
              </div>
              {/*-------------EXCEL FILE-----------*/}
              <div className={`w-100 px-2 ${phone ? "mb-1" : "mb-2"}`}>
                <input
                  name={"excelFile"}
                  ref={inputExcelFile}
                  type="file"
                  accept=".xlsx"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleExcelFile(e.target);
                  }}
                />
                <label htmlFor={"excelFile"} style={{ width: "100%" }}>
                  <p
                    className={`mb-1 ${phone ? "xs-font" : ""}`}
                  >{`${"Archivo"}: *`}</p>
                  {/* <Tooltip
                        title={
                          cedi || branch
                            ? ""
                            : "Seleccione una sucursal o un cedis"
                        }
                        placement="top"
                      > */}
                  <div className="d-flex ">
                    <UploadButton
                      text={excelFile ? excelFile.name : "Cargar Archivo"}
                      fileLoaded={excelFile ? "excel" : ""}
                      onClick={() => handleClickInput(inputExcelFile)}
                      border={"rgb(175, 175, 175, 1)"}
                      font={
                        excelFile
                          ? theme.palette.secondary.main
                          : theme.palette.neutrals.darkGray
                      }
                      error={
                        formik.errors.excelFile || formik.errors.validExcelData
                      }
                      touched={
                        formik.touched.excelFile || formik.touched.excelFile
                      }
                      height={"40px"}
                      disabled={false}
                      width={excelFile ? "95%" : "100%"}
                      //disabled={cedi || branch ? false : true}
                    />
                    {excelFile ? (
                      <div className="d-flex justify-content-end">
                        <DeleteDocButton
                          onClick={removeExcelFile}
                          text={"Eliminar"}
                        />
                      </div>
                    ) : null}
                  </div>
                  {/*</Tooltip>*/}

                  <div className=" d-flex justify-content-between">
                    {formik.touched.excelFile &&
                    (formik.errors.excelFile ||
                      formik.errors.validExcelData) ? (
                      <p className="pl-3 mt-1 text-danger xs-font">
                        {formik.errors.excelFile ||
                          formik.errors.validExcelData}
                      </p>
                    ) : null}
                  </div>
                </label>
              </div>
              {/*}</>
              ) : (
                <>
                  <div
                    key={"products"}
                    className={` d-flex flex-column px-2 ${
                      phone ? "mb-1" : "mb-3"
                    }`}
                  >
                    <p
                      className={`mb-1 ${phone ? "xs-font" : ""}`}
                      htmlFor={"products"}
                    >{`${"Productos"}: ${"*"}`}</p>

                    <div className="d-flex align-items-center w-100">
                      <Autocomplete
                        noOptionsText={"Sin resultados"}
                        disablePortal
                        options={products}
                        value={product}
                        getOptionLabel={(option) => option.description}
                        onChange={(e, value) => {
                          formik.handleChange(e);
                          setProduct(value);
                          setDuplicityError(false);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Productos"
                            size="small"
                            fullWidth
                            name={"products"}
                            error={duplicityError}
                            helperText={
                              duplicityError && "Ya añadiste este producto"
                            }
                            InputLabelProps={
                              phone && { style: { fontSize: 13 } }
                            }
                          />
                        )}
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-start">
                    {/*
                    <div
                      key={"stock"}
                      className={` d-flex flex-column w-75 mr-2 pl-2 ${
                        phone ? "mb-1" : "mb-3"
                      }`}
                    >
                      <label
                        className={`mb-1 ${phone ? "xs-font" : ""}`}
                        htmlFor={"stock"}
                      >
                        {`${"Stock"}: `}{" "}
                        <span>
                          {stockIsLoading && (
                            <CircularProgress
                              size={15}
                              className="ml-1 ds-yellow-font"
                            />
                          )}
                        </span>
                      </label>

                      <TextField
                        error={stockError}
                        size="small"
                        label={"Stock"}
                        variant="outlined"
                        fullWidth
                        value={stockIsLoading ? "Cargando..." : stock}
                        name={"stock"}
                        id={"stock"}
                        InputLabelProps={phone && { style: { fontSize: 13 } }}
                        InputProps={{
                          readOnly: true,
                          style: { fontSize: phone ? 13 : "inherit" },
                        }}
                      />
                    </div> 

                    <div
                      key={"Piezas"}
                      className={` d-flex mb-3 flex-column w-100 ml-2 pr-2`}
                    >
                      <label
                        className={`mb-1 ${phone ? "xs-font" : ""}`}
                        htmlFor={"pieces"}
                      >{`${"Cantidad"}:* `}</label>
                      <div className="d-flex align-items-start w-100">
                        <TextField
                          //error={stockError}
                          size="small"
                          label={"Piezas"}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            setPieces(e.target.value);
                          }}
                          value={pieces}
                          name={"pieces"}
                          id={"pieces"}
                          helperText={
                            stockError && "Debe ser menor a la existencia"
                          } 
                          InputLabelProps={phone && { style: { fontSize: 13 } }}
                          InputProps={phone && { style: { fontSize: 13 } }}
                        />
                        <Tooltip arrow placement="top" title="Agregar">
                          <IconButton
                            onClick={() => {
                              addProduct();
                            }}
                          >
                            <Add />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </> 
                
                          )} */}

              <div key={"summary"} className={` d-flex flex-column mb-3 px-2`}>
                <div
                  className={`d-flex align-items-center mr-2 ${
                    phone && "mb-2"
                  }`}
                >
                  <p
                    className=" no-wrap h6"
                    htmlFor={"products"}
                  >{`${"Resumen"}: ${"*"}`}</p>
                </div>

                <div className="d-flex align-items-center w-100">
                  <Paper sx={{ width: "100%" }}>
                    <TableContainer sx={{ maxHeight: 140 }}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow padding="checkbox">
                            <StyledTableCell
                              sx={{ width: "20px" }}
                            ></StyledTableCell>
                            <StyledTableCell>ID</StyledTableCell>
                            {/* <StyledTableCell>Descripción</StyledTableCell> */}
                            <StyledTableCell>Piezas</StyledTableCell>
                            {/* <StyledTableCell>Precio</StyledTableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedProducts.map((product, index) => (
                            <Tooltip
                              title={product.msg || ""}
                              placement="top"
                              arrow
                            >
                              <TableRow hover role="checkbox" key={index}>
                                <StyledTableCell padding="checkbox">
                                  <IconButton
                                    size="medium"
                                    sx={{
                                      "&:hover": { color: "red" },
                                      padding: "0 1px 0 0",
                                    }}
                                    onClick={(e) =>
                                      handleClick(e, "delete", product)
                                    }
                                    disabled={false}
                                  >
                                    <DeleteForever fontSize="small" />
                                  </IconButton>
                                </StyledTableCell>
                                <StyledTableCell
                                  padding="checkbox"
                                  valid={product.msg ? false : true}
                                >
                                  {product.article}
                                </StyledTableCell>
                                {/* <StyledTableCell
                                  padding="checkbox"
                                  valid={product.msg ? false : true}
                                >
                                  {product.description}
                                </StyledTableCell> */}
                                <StyledTableCell
                                  padding="checkbox"
                                  valid={product.msg ? false : true}
                                >
                                  {product.quantity}
                                </StyledTableCell>
                                {/* <StyledTableCell
                                  padding="checkbox"
                                  valid={product.msg ? false : true}
                                >
                                  {product.unitPrice}
                                </StyledTableCell> */}
                              </TableRow>
                            </Tooltip>
                          ))}
                        </TableBody>
                      </Table>
                      {isLoadingValidation ? (
                        <div
                          className={`d-flex justify-content-center align-items-center my-1`}
                        >
                          <CircularProgress
                            size={15}
                            sx={{ color: theme.palette.primary.main }}
                          />
                          <p className="m-0 ml-2 small-font">
                            Validando datos...
                          </p>
                        </div>
                      ) : (
                        selectedProducts == 0 && (
                          <div
                            className={`xs-font text-center ${
                              emptyProductsError && "ds-ref-font"
                            }`}
                          >
                            No has agregado productos
                          </div>
                        )
                      )}
                    </TableContainer>
                  </Paper>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <div
          className={`mt-2 ${
            !phone && "d-flex justify-content-end margin-right"
          }`}
        >
          <div className={`${phone && "mb-2"}`}>
            <CancelButton
              text={"Cancelar"}
              onClick={() => {
                navigate("/cliente/ventas/B2B");
              }}
              width={phone ? "100%" : ""}
            />
          </div>
          <AcceptButton
            text={"Enviar"}
            type={"submit"}
            width={phone ? "100%" : ""}
            disabled={
              formik.values.massive && !formik.values.validExcelData
                ? true
                : false
            }
          />
        </div>
      </form>

      <Modal open={openWarning}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalConfirmation
            actionCancel={() => setOpenWarning(false)}
            actionProceed={() => {
              setOpenWarning(false);
              createSale();
            }}
            info={selectedProducts.map((p) => {
              return {
                label: p.description,
                value: `${p.quantity} pzas`,
              };
            })}
          />
        </div>
      </Modal>

      <Modal open={openError}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalError
            text={
              formik.values.validExcelData
                ? `No se pudo registrar la venta.`
                : `Encontramos errores en tu archivo`
            }
            handleClose={() => setOpenError(false)}
            error={errorMessage}
          />
        </div>
      </Modal>
      <Modal open={openSuccess}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalSuccess
            text={`La venta fue registrada con éxito. El código de pedido de su venta es ${orderCode}`}
            auxText={"¿Deseas registrar otra venta?"}
            handleClose={handleCloseSuccess}
            action={"add"}
          />
        </div>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
