import React, { useState } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  tableCellClasses,
  Modal,
} from "@mui/material";

import { roles } from "../../helpers/enums";

import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";
import { ListPagination } from "../Pagination/ListPagination";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import ModalPDFEvidences from "../Modal/ModalPDFEvidence";
import ModalProducts from "../Modal/ModalProducts";
import ModalContactInfo from "../Modal/ModalContactInfo";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const TableActiveSales = ({
  search,
  data,
  type,
  setPage,
  page,
  total,
  rol,
  requestData,
  count,
}) => {
  const { height, width } = useViewPortDimensions();
  const navigate = useNavigate();
  const [pdf, setPdf] = useState("");
  const [openPdf, setOpenPdf] = useState(false);

  const [contact, setContact] = useState();
  const [openContact, setOpenContact] = useState(false);

  const [order, setOrder] = useState([]);
  const [openOrder, setOpenOrder] = useState(false);

  const handlePdf = (pdf) => {
    setOpenPdf(true);
    setPdf(pdf);
  };
  const handleClosePdf = () => {
    setOpenPdf(false);
  };

  const handleOrder = (order) => {
    setOrder(order);
    setOpenOrder(true);
  };

  const handleContactInfo = (info) => {
    setOpenContact(true);
    setContact(info);
  };

  // 1. es listo entrega, 2. es asignado, 3. en ruta a entrega, 4. finalizado, 5. movimiento de última milla
  const status = [
    { id: 1, value: "En proceso" },
    { id: 2, value: "Asignado" },
    { id: 3, value: "En ruta" },
    { id: 4, value: "Finalizado" },
    { id: 5, value: "Última milla" },
    { id: 6, value: "Cancelado" },
    { id: 7, value: "Entrega parcial" },

  ];

  const formatTime = (time) => {};

  const tRow = (row, index) => {
    const labelId = `enhanced-table-checkbox-${index}`;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          align="center"
          sx={{minWidth: 100}}
        >
          {row.fecha_creacion.substring(0,10)}
        </TableCell>
        <TableCell align="center">{row.codigo_pedido}</TableCell>
        <TableCell align="center">{row.eccommerce}</TableCell>
        <TableCell align="center">
          {row.articulo_orden.length != 0 ? (
            <span
              className="cursor-pointer ds-blue-font"
              onClick={() => handleOrder(row.articulo_orden)}
            >
              Ver artículos
            </span>
          ) : (
            ""
          )}
        </TableCell>
        <TableCell align="center">
          {row.estatus_envio != null &&
            status.find((el) => el.id == row.estatus_envio).value}
        </TableCell>

        <TableCell align="center">{row.numero_guia}</TableCell>
        <TableCell align="center">
          {row.orden_estatus.length != 0 ? (
            row.orden_estatus[0].url_etiquetado != null ? (
              <span
                className="cursor-pointer ds-blue-font"
                onClick={() => handlePdf(row.orden_estatus[0].url_etiquetado)}
              >
                Ver etiqueta
              </span>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </TableCell>
        <TableCell align="center">
          <span
            className="cursor-pointer ds-blue-font"
            onClick={() => handleContactInfo(row)}
          >
            Ver datos
          </span>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: height - (74 + 90 + 60) }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-label="sticky table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    align="center"
                    style={{ flexWrap: "nowrap" }}
                  >
                    Fecha
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Código pedido
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ flexWrap: "nowrap" }}
                  >
                    Canal
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ flexWrap: "nowrap" }}
                  >
                    Artículos
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ flexWrap: "nowrap" }}
                  >
                    Estatus
                  </StyledTableCell>
                  <StyledTableCell align="center">Guía</StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ flexWrap: "nowrap" }}
                  >
                    Etiqueta
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ flexWrap: "nowrap" }}
                  >
                    {" "}
                    Contacto
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => tRow(row, index))}
              </TableBody>
            </Table>
            {data.length == 0 && search != "" ? (
              <div className="d-flex justify-content-center mt-2">
                No se encontraron resultados para "{search}"
              </div>
            ) : (
              data.length == 0 &&
              search == "" && (
                <div className="d-flex justify-content-center mt-2">
                  No tienes ventas aún.
                </div>
              )
            )}
          </TableContainer>
          <ListPagination
            setPage={setPage}
            page={page}
            total={total}
            requestData={requestData}
            count={count}
          />
        </Paper>
      </Box>

      <Modal open={openPdf} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalPDFEvidences pdf={pdf} handleClosePdf={handleClosePdf} />
        </div>
      </Modal>

      <Modal open={openOrder} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalProducts
            order={order}
            handleClose={() => setOpenOrder(false)}
          />
        </div>
      </Modal>

      <Modal open={openContact} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalContactInfo
            info={contact}
            handleClose={() => setOpenContact(false)}
          />
        </div>
      </Modal>
    </>
  );
};
