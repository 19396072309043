import React from "react";

import {
  Grid,
  Paper,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1.5),
  color: theme.palette.text.secondary,
  borderRadius: 5,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const SkeletonCard = ({ num, icons, headers }) => {
  const arrNum = Array(num).fill(true);
  const arrIcons = Array(icons).fill(true);
  const phone = useMediaQuery("(max-width:480px)");

  return (
    <>
      {arrNum.map((element, index) => (
        <Grid item xs={6} sm={3} md={2.4} lg={2.4} xl={2.4}>
          <Item className="mb-2" key={index}>
            <div className="d-flex w-100 justify-content-end mr-1">
              {arrIcons.map((icon, index) => (
                <Skeleton
                  variant="circular"
                  width={30}
                  height={30}
                  key={index}
                  className="mr-1"
                />
              ))}
            </div>
            <div className="mt-2">
              {headers.map(
                (header, index) =>
                  header.label != "Acciones" && (
                    <Skeleton
                      variant={header.variant}
                      key={index}
                      width={header.width}
                      height={header.width}
                      sx={{ margin: "auto" }}
                    />
                  )
              )}
            </div>
          </Item>
        </Grid>
      ))}

      {/* Skeleton pagination */}
      <div className="d-flex justify-content-start align-items-center p-2 ml-3">
        <Typography variant="caption" className="ml-2">
          <Skeleton width={50} />
        </Typography>
        <Skeleton variant="circular" className="ml-4" width={20} />
        <Skeleton variant="circular" className="ml-2" width={20} />
      </div>
    </>
  );
};
