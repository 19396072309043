import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import App from "../App";
import { AppAdmin } from "../admin/AppAdmin";
import { Login } from "../pages/Login/Login";
import { Recovery } from "../pages/Login/Recovery";
import { AppSucursal } from "../sucursal/AppSucursal";
import { AppHeadFieldManager } from "../headFieldManager/AppHeadFieldManager";
import { useAuthContext } from "../context/AuthProvider";
import RequireAuth from "./RequireAuth";
import { SalesContextProvider } from "../context/SalesContext";
import { FilterContexProvider } from "../context/FilterContext";
import { PickersContextProvider } from "../context/PickersContext";
import { ClientsContextProvider } from "../context/ClientsContext";
import { ProductContextProvider } from "../context/ProductContext";
import { BusinessContextProvider } from "../context/BusinessContext";

export const Router = () => {
  const { token } = useAuthContext(); /* Recovery token from logged user */
  const path =
    localStorage.getItem(
      "pathName"
    ); /* Recovery main path from logged user, see RequireAuth */
  const rol = localStorage.getItem("rol");

  return (
    <Routes>
      {/* When the token exists and the route does not, it redirects to the main route, otherwise it redirects to "/"(login) */}
      {token ? (
        <Route path="*" element={<Navigate to={path} />} />
      ) : (
        <Route path="*" element={<Navigate to="/" replace />} />
      )}
      {/* If the token exists and the route is "/" it should return to the main route, otherwise navigate to login */}
      {token ? (
        <Route path="/" element={<Navigate to={path} />} />
      ) : (
        <Route path="/" element={<Login />} />
      )}
      <Route path="recuperarCuenta/" element={<Recovery />} />{" "}
      {/* PUBLIC ROUTE */}
      <Route
        path="administrador/*"
        element={
          <RequireAuth>
            <FilterContexProvider>
              <ClientsContextProvider>
                <BusinessContextProvider>
                  <AppAdmin />
                </BusinessContextProvider>
              </ClientsContextProvider>
            </FilterContexProvider>
          </RequireAuth>
        }
      />{" "}
      {/*PRIVATE ROUTE */}
      <Route
        path="cliente/*"
        element={
          <RequireAuth>
            <FilterContexProvider>
              <ProductContextProvider>
                <BusinessContextProvider>
                  <App />
                </BusinessContextProvider>
              </ProductContextProvider>
            </FilterContexProvider>
          </RequireAuth>
        }
      />{" "}
      {/* PRIVATE ROUTE */}
      <Route
        path="warehouse/*"
        element={
          <RequireAuth>
            <AppSucursal />
          </RequireAuth>
        }
      />{" "}
      {/* PRIVATE ROUTE */}
      <Route
        path="headFieldManager/*"
        element={
          <RequireAuth>
            <FilterContexProvider>
              <SalesContextProvider>
                <PickersContextProvider>
                  <AppHeadFieldManager />
                </PickersContextProvider>
              </SalesContextProvider>
            </FilterContexProvider>
          </RequireAuth>
        }
      />{" "}
      {/* PRIVATE ROUTE */}
    </Routes>
  );
};
