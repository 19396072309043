import React, { useRef, useState, useEffect } from "react";

import {
  Grid,
  useMediaQuery,
  Tooltip,
  IconButton,
  Badge,
  Popover,
} from "@mui/material";

import { EntriesGraph } from "../../components/Graphs/EntriesGraph";
import { LessExistancesGraph } from "../../components/Graphs/LessExistancesGraph";
import { MoreExistancesGraph } from "../../components/Graphs/MoreExistancesGraph";
import { SalesGraph } from "../../components/Graphs/SalesGraph";
import { TopSalesGraph } from "../../components/Graphs/TopSalesGraph";
import { DownloadButton } from "../../components/Buttons/DownloadButton";
import FilterIcon from "../../assets/SVGIcons/Filter.svg";

import {
  getDateCleaned,
  getDayjsFormatted,
} from "../../helpers/getDateCleaned";

import {
  graphEntriesHeaders,
  graphLessHeaders,
  graphMoreHeaders,
  graphSalesHeaders,
  graphTopSalesHeaders,
} from "../../components/Excel/headersGraphs";
import { FilterAlt } from "@mui/icons-material";
import { ModalFilters } from "../../components/Modal/ModalFilters";
import { useMemo } from "react";
import { GraphsAPI } from "../../api/Graphs";
import { ReportHeader } from "../Reports/ReportHeader";

const styles = [
  [
    ["Entradas", "Cantidad", { role: "style" }],
    ["Por validar", 5, "#58BDDB"] /*Azul */,
    ["Validadas", 10, "#7CB963"] /*Verde claro*/,
    ["Incompletas", 2, "#EE8C26"] /*Naranja */,
    ["Canceladas", 3, "#9B68A6"] /*Morado */,
  ],
];

export const Dashboard = ({ socketReload }) => {
  const phone = useMediaQuery("(max-width:480px)");
  const htmlElement = useRef(null);
  const rol = localStorage.getItem("rol");

  //DATA STATES
  //------------------------------------------------

  const [dataEntries, setDataEntries] = useState([]);
  const [dataMoreInventory, setDataMoreInventory] = useState([]);
  const [dataLessInventory, setDataLessInventory] = useState([]);
  const [dataMoreFullInventory, setDataMoreFullInventory] = useState([]);
  const [dataLessFullInventory, setDataLessFullInventory] = useState([]);
  const [dataSales, setDataSales] = useState([]);
  const [dataTopSales, setDataTopSales] = useState([]);
  const [totalEntries, setTotalEntries] = useState(0);

  //------------------------------------------------

  //ERRORES
  //------------------------------------------------

  const [errors, setErrors] = useState({
    error: false,
    errorNullStart: false,
    errorNullEnd: false,
  });

  //------------------------------------------------

  //MODAL STATES
  //------------------------------------------------

  // const [openFilter, setOpenFilter] = useState(false);
  const [invisible, setInvisible] = useState(false);

  //------------------------------------------------

  //LOADERS STATES
  //------------------------------------------------

  const [loaderEntries, setLoaderEntries] = useState(true);
  const [loaderMore, setLoaderMore] = useState(true);
  const [loaderLess, setLoaderLess] = useState(true);
  const [loaderTop, setLoaderTop] = useState(true);
  const [loaderSales, setLoaderSales] = useState(true);

  //------------------------------------------------

  //FILTERS STATES
  //------------------------------------------------

  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

  const [startFilterDate, setStartFilterDate] = useState(firstDay);
  const [endFilterDate, setEndFilterDate] = useState(today);

  const [prevData, setPrevData] = useState({
    startFilterDate: firstDay,
    endFilterDate: today,
  });

  //------------------------------------------------

  //EFFECTS
  //------------------------------------------------
  useEffect(() => {
    RequestData();
  }, []);

  useEffect(() => {
    if (socketReload.sale != 0) {
      switch (rol) {
        case "5":
          GetDataSales();
          break;
        default:
          GetDataSales();
          break;
      }
    }
  }, [socketReload.sale]);
  //------------------------------------------------

  //EXCEL STATES AND DATA
  //------------------------------------------------

  const [excelEntries, setExcelEntries] = useState([]);
  const [excelSales, setExcelSales] = useState([]);
  const [excelMore, setExcelMore] = useState([]);
  const [excelLess, setExcelLess] = useState([]);
  const [excelTop, setExcelTop] = useState([]);

  const info = [
    {
      name: "Recepción de mercancía",
      data: excelEntries,
      headers: graphEntriesHeaders,
    },
    {
      name: "Artículos con más Stock",
      data: excelMore,
      headers: graphMoreHeaders,
    },
    {
      name: "Artículos con menos Stock",
      data: excelLess,
      headers: graphLessHeaders,
    },
    { name: "Ventas", data: excelSales, headers: graphSalesHeaders },
    { name: "Top Ventas", data: excelTop, headers: graphTopSalesHeaders },
  ];

  //------------------------------------------------

  //APIS
  //------------------------------------------------

  const graphAPI = useMemo(() => new GraphsAPI(), []);

  //------------------------------------------------

  //DATA REQUEST
  //------------------------------------------------

  const RequestData = () => {
    switch (rol) {
      case "4":
        GetDataEntries();
        GetMoreInventory();
        GetLessInventory();
        break;

      case "5":
        GetDataTopSales();
        GetDataSales();
        break;

      default:
        GetDataEntries();
        GetMoreInventory();
        GetLessInventory();
        GetDataTopSales();
        GetDataSales();
        break;
    }
  };

  const GetDataEntries = async () => {
    setLoaderEntries(true);
    let data = new FormData();
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    const response = await graphAPI.entriesGraphEP(data);
    if (response.status == 200) {
      let aux = response.data.entries;
      let aux2 = [...response.data.entries];
      let aux3 = aux2.map((element, i) => {
        return [element[0], element[1], styles[0][i + 1][2]];
      });
      const auxExcel = response.data.excel.map((el) => ({
        type: el.type,
        total: el.total.toString(),
      }));
      setDataEntries([[["Entradas", "Cantidad", { role: "style" }], ...aux3]]);
      if (aux.length != 0) {
        let aux4 = aux.map((el) => el[1]).reduce((a, b) => a + b);
        setTotalEntries(aux4);
        setExcelEntries(auxExcel);
      } else {
        setTotalEntries(0);
      }
      setLoaderEntries(false);
    } else {
      console.log(response);
    }
  };

  const GetMoreInventory = async () => {
    setLoaderMore(true);

    let data = new FormData();

    const response = await graphAPI.moreStockEP(data);
    if (response.status == 200) {
      const aux = response.data.inventory.map((subarr) => [
        subarr[0],
        subarr[1],
        subarr[2].substring(0, 20),
      ]);
      let auxFull = response.data.inventory;
      const auxExcel = response.data.excel.map((el) => ({
        product: el.product,
        description: el.description,
        stock: el.stock.toString(),
      }));

      if (aux.length != 0) {
        setDataMoreInventory([
          ["Producto", "Stock", { role: "annotation" }],
          ...aux,
        ]);
        setDataMoreFullInventory([
          ["Producto", "Stock", { role: "annotation" }],
          ...auxFull,
        ]);
        setExcelMore(auxExcel);
      }
      setLoaderMore(false);
    } else {
      console.log(response);
    }
  };

  const GetLessInventory = async () => {
    setLoaderLess(true);
    let data = new FormData();

    const response = await graphAPI.lessStockEP(data);
    if (response.status == 200) {
      const aux = response.data.inventory.map((subarr) => [
        subarr[0],
        subarr[1],
        subarr[2].substring(0, 20),
      ]);
      let auxFull = response.data.inventory;
      const auxExcel = response.data.excel.map((el) => ({
        product: el.product,
        description: el.description,
        stock: el.stock.toString(),
      }));

      if (aux.length != 0) {
        setDataLessInventory([
          ["Stock", "Producto", { role: "annotation" }],
          ...aux,
        ]);
        setDataLessFullInventory([
          ["Producto", "Stock", { role: "annotation" }],
          ...auxFull,
        ]);
        setExcelLess(auxExcel);
        // setExcelLess(response.data.excel)
      }
      setLoaderLess(false);
    } else {
      console.log(response);
    }
  };

  //FORMATEAR FECHA A DD/MM/YYYY PARA GRÁFICA
  //------------------------------------------------

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, 0);
  };

  const formatDate = (date) => {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  };
  //------------------------------------------------

  const GetDataSales = async () => {
    setLoaderSales(true);

    let data = new FormData();
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    const response = await graphAPI.salesAmountPerDayEP(data);
    if (response.status == 200) {
      const sales = response.data.sales;
      const auxExcel = response.data.excel.map((el) => ({
        date: el.date,
        amount: el.amount.toString(),
      }));

      if (sales.length != 0) {
        const arrWithDate = [...sales].map((el) => [
          formatDate(new Date(...el[0])),
          el[1],
        ]);
        setDataSales([["Día", "Monto $"], ...arrWithDate]);
        setExcelSales(auxExcel);
      }
      setLoaderSales(false);
    } else {
      console.log(response);
    }
  };

  const GetDataTopSales = async () => {
    setLoaderTop(true);
    let data = new FormData();
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    const response = await graphAPI.topSalesEP(data);
    if (response.status == 200) {
      const aux = response.data.more_sales;
      const auxExcel = response.data.more_sales_excel.map((el) => ({
        decripcion: el.decripcion,
        sales: el.sales,
        total: el.total.toString(),
      }));

      if (aux.length != 0) {
        //  sales formatting:
        aux.forEach((el) => {
          const sales = el[2].replace("$", "");
          el[2] = `$ ${new Intl.NumberFormat("es-MX").format(sales)}`;
        });

        setDataTopSales([
          ["Producto", "Piezas", { role: "annotation" }],
          ...aux,
        ]);
        setExcelTop(auxExcel);
        // setExcelTop(response.data.more_sales_excel);
      }
      setLoaderTop(false);
    } else {
      console.log(response);
    }
  };

  //HANDLERS
  //------------------------------------------------

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleCloseFilterModal = () => setAnchorEl(null);

  const handleCloseModal = () => {
    // setOpenFilter(false)
    setAnchorEl(null);
    settingFilters(prevData.startFilterDate, prevData.endFilterDate);
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  const handleFilter = () => {
    if (endFilterDate < startFilterDate) {
      setErrors({ error: true });
    } else {
      handleCloseFilterModal();
      setErrors({
        error: false,
        errorNullStart: false,
        errorNullEnd: false,
      });
      RequestData();
      setPrevData({
        startFilterDate: startFilterDate,
        endFilterDate: endFilterDate,
      });
    }
  };

  const handleResetFilter = async () => {
    settingFilters(firstDay, today);
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  //------------------------------------------------

  //FILTERS SETTING
  //------------------------------------------------

  const settingFilters = (firstDay, endDay) => {
    setStartFilterDate(firstDay);
    setEndFilterDate(endDay);
  };

  //------------------------------------------------
  const filterValues = {
    "Fecha inicio": getDayjsFormatted(startFilterDate),
    "Fecha fin": getDayjsFormatted(endFilterDate),
  };

  return (
    <>
      <div className="w-100 px-3 mb-2">
        <div
          className={`${
            phone ? "mt-1" : "mt-2"
          } d-flex justify-content-between`}
        >
          <DownloadButton
            text={""}
            htmlElement={htmlElement}
            excel={true}
            modal={false}
            info={info}
            fileName="Dashboard de Ventas e Inventario"
            sheetName="Gráficas Reporte General"
          />
          <div>
            <Tooltip title="Filtrar" arrow placement="left">
              {/* <IconButton onClick={handleOpenModal}> */}
              <IconButton onClick={handleClick}>
                <Badge
                  color="primary"
                  variant="dot"
                  invisible={invisible}
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "lightgreen",
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                    },
                  }}
                >
                  <img src={FilterIcon} width={"40px"} alt="Filtro" />
                </Badge>
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
          ref={htmlElement}
        >
          <ReportHeader
            title="Dashboard de Ventas e Inventario"
            filters={filterValues}
          />
          {rol != 5 && (
            <>
              <EntriesGraph
                type={0}
                data={dataEntries}
                total={totalEntries}
                isLoading={loaderEntries}
                filters={filterValues}
              />
              <MoreExistancesGraph
                data={dataMoreInventory}
                dataFull={dataMoreFullInventory}
                isLoading={loaderMore}
                filters={filterValues}
              />
              <LessExistancesGraph
                data={dataLessInventory}
                dataFull={dataLessFullInventory}
                isLoading={loaderLess}
                filters={filterValues}
              />
            </>
          )}

          {rol != 4 && (
            <>
              <SalesGraph
                data={dataSales}
                isLoading={loaderSales}
                filters={filterValues}
              />
              <TopSalesGraph
                data={dataTopSales}
                isLoading={loaderTop}
                filters={filterValues}
              />
            </>
          )}
        </Grid>
      </div>
      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseFilterModal}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ModalFilters
            errors={errors}
            setErrors={setErrors}
            handleClose={handleCloseModal}
            dates={{
              setEnd: setEndFilterDate,
              end: endFilterDate,
              setStart: setStartFilterDate,
              start: startFilterDate,
            }}
            fields={[]}
            onClickFilter={handleFilter}
            onClickResetFilter={handleResetFilter}
          />
        </Popover>
      </>
    </>
  );
};
