import { Grid } from "@mui/material";
import React from "react";
import { CardInventory } from "./Card/CardInventory";
import { ListPagination } from "./Pagination/ListPagination";

export const ListViewInventory = ({
  search,
  data,
  type,
  setPage,
  page,
  total,
  count,
  requestData,
}) => {
  return (
    <div className="mt-3 w-100 r-15">
      <Grid
        container
        alignItems="center"
        direction="row"
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
        columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
      >
        {data.map((row, index) => (
          <CardInventory key={index} info={row} type={type} />
        ))}
      </Grid>

      {data.length == 0 && search != "" ? (
        <div className="d-flex justify-content-center mt-2">
          No se encontraron resultados para "{search}"
        </div>
      ) : (
        data.length == 0 &&
        search == "" && (
          <div className="d-flex justify-content-center mt-2">
            No tienes ventas aún.
          </div>
        )
      )}
      {/* {
        data.length == 0 && (
          <div className="d-flex justify-content-center mt-2">
            No tienes inventario aún.
          </div>
        )
      } */}
      <ListPagination
        setPage={setPage}
        page={page}
        total={total}
        count={count}
        requestData={requestData}
      />
    </div>
  );
};
