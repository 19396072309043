import React from 'react'

import { Grid, IconButton } from '@mui/material'

import { FlippyCard } from "../../components/FlippyCard";

import { useNavigate } from "react-router-dom";

import ReportsSalesIcon from "../../assets/ReportsSales.svg";
import ReportsInventoryIcon from "../../assets/ReportsInventory.svg";

export const Reports = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="w-100 px-3">
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
          className="mt-2"
        >
          <FlippyCard
            xs={12} sm={12} md={6} lg={6}
            description={"Gráficas de reportes de ventas por: Tipo de ventas, Ventas por Canal, Piezas Vendidas Por Producto y Monto, Más Vendidos, Menos Vendidos, Pedidos Por Sucursal."}
            onClick={() => {
              navigate("/administrador/reportes/ventas/")
            }}
            title={"Ventas"}
            icon={
              <IconButton>
                <img
                  src={ReportsSalesIcon}
                  alt="Existance"
                  width={100}
                  height={100}
                />
              </IconButton>
            }
          />

          <FlippyCard
            xs={12} sm={12} md={6} lg={6}
            description={"Gráficas de reportes de inventario por: Ubicación, Más Stock, Menos Stock y Entradas."}
            onClick={() => {
              navigate("/administrador/reportes/inventario/")
            }}
            title={"Inventario"}
            icon={
              <IconButton>
                <img src={ReportsInventoryIcon} alt="Existance" width={100} height={100} />
              </IconButton>
            }
          />
        </Grid>
      </div>
    </>
  );
};