import { LocationOn } from "@mui/icons-material";
import { useEffect } from "react";
import { useState } from "react";

export const Marker = ({
  map,
  position,
  openLocation,
  label,
  draggable,
  getAddress,
}) => {
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    } else {
      if (openLocation) {
        marker.addListener("click", () => {
          openLocation();
        });
      }
      if (draggable) {
        marker.setOptions({ draggable: draggable });

        marker.addListener("dragend", (e) => {
          // console.log(e);
          getAddress(e.latLng);
        });
      }
    }
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions({
        position: position,
        map: map,
      });

      if (label) {
        marker.setLabel({
          text: label,
          className: "mb-5 r-15 px-1 fw-bolder bg-white ds-blue-font",
        });
      }
    }
  }, [marker, position]);

  return null;
};
