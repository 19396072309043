import React from 'react'

import { Typography, Box, useMediaQuery } from "@mui/material";

import { AcceptButton } from '../Buttons/AcceptButton';
import { CancelButton } from '../Buttons/CancelButton';

import { EventAvailable } from '@mui/icons-material';
import { theme } from '../../utils/theme';

export const ModalEntrySuccess = ({ticketNumber, handleClose}) => {
  const phone = useMediaQuery("(max-width:480px)");

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: phone ? "90%" : 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: 2
  };

  return (
    <Box sx={style}>
      <div className='d-flex justify-content-center text-center align-items-center'>
        <Typography id="modal-modal-description">
            Se ha registrado correctamente tu entrada de producto
        </Typography>
      </div>
      <div className='d-flex justify-content-center'>
        <Typography id="modal-modal-description">
            Folio: <strong className='ds-blue-font'>{ticketNumber} </strong> 
        </Typography>
      </div>
      <div className='d-flex justify-content-center'>
        <Typography id="modal-modal-description">
            Estatus: Por Validar
        </Typography>
      </div>
      <div className='d-flex justify-content-center mt-2'>
          <EventAvailable sx={{ color: theme.palette.secondary.main , fontSize: 50,}}
    />
      </div>
      <div className='d-flex justify-content-center mt-2'>
        <Typography id="modal-modal-description">
            ¿Deseas agregar otra entrada?
        </Typography>
      </div>
      <div className={`d-flex mt-4 ${phone ? "flex-column" : "justify-content-between"}`}>
      <div className={`${phone && "mb-2"}`}>
        <CancelButton text={"Salir"} onClick={() => handleClose("envios")} width={phone ? "100%" : ""} />
        </div>
        <AcceptButton text={"Sí"} onClick={() => handleClose("busqueda")} width={phone ? "100%" : ""} />
      </div>
    </Box>
  )
}
