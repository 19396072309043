import React, { useEffect } from "react";

export const Map = ({
  children,
  style,
  center,
  zoom,
  multipleMarkers,
  map,
  setMap,
  ...options
}) => {
  const ref = React.useRef(null);
  const markers = children;
  var bounds = new window.google.maps.LatLngBounds();
  useEffect(() => {
    if (ref.current && !map) {
      if (markers) {
        setMap(new window.google.maps.Map(ref.current, {}));
      } else {
        setMap(new window.google.maps.Map(ref.current, { zoom, center }));
      }
    }
  }, [ref, map]);

  useEffect(() => {
    if (map && markers) {
      AutoCenter();
    }
  }, [markers]);

  useEffect(() => {
    if (map) {
      map.setCenter(center);
    }
  }, [center]);

  function AutoCenter() {
    if (multipleMarkers) {
      markers.map((marker) =>
        marker.props.position ? bounds.extend(marker.props.position) : null
      );
      map.fitBounds(bounds);
      if (markers.length == 1) {
        map.setZoom(17);
      }
    } else {
      if (markers.props.position) {
        bounds.extend(markers.props.position);
        map.fitBounds(bounds);
        map.setZoom(17);
      }
    }
  }

  return (
    <>
      <div ref={ref} style={style} id="map" />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};
