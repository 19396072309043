import React, { useState, useEffect } from "react";

import { Grid, Popover, useMediaQuery } from "@mui/material";

import { Search } from "../../components/Search";
import { ToggleView } from "../../components/ToggleView";

import {
  getDateCleaned,
  getDayjsFormatted,
} from "../../helpers/getDateCleaned";

import { roles } from "../../helpers/enums";
import { ModalFilters } from "../../components/Modal/ModalFilters";
import { SkeletonCardSales } from "../../components/Skeleton/SkeletonCardSales";
import { TableSalesSucursal } from "../../components/Tables/TableSalesSucursal";
import { ListViewSalesSucursal } from "../ListViewSalesSucursal";
import { SkeletonTableSalesSucursal } from "../../components/Skeleton/SkeletonTableSalesSucursal";
import { WarehouseAPI } from "../../api/Warehouse";

export const HistoricalSalesSucursal = () => {
  const [view, setView] = useState(true);
  const phone = useMediaQuery("(max-width:480px)");

  //DATA STATES
  //------------------------------------------------
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0); /*Estado p/paginacion*/
  const [total, setTotal] = useState(); /*Total de paginas enviado desde api*/
  const [count, setCount] = useState(); /*Paginacion listas */
  const [search, setSearch] = useState("");
  const [ready, setReady] = useState(false);

  //------------------------------------------------

  //ERRORES
  //------------------------------------------------

  const [errors, setErrors] = useState({
    error: false,
    errorNullStart: false,
    errorNullEnd: false,
  });

  //------------------------------------------------

  //MODAL STATES
  //------------------------------------------------

  const [openFilter, setOpenFilter] = useState(false);
  const [invisible, setInvisible] = useState(false);

  //------------------------------------------------

  //LOADERS STATES
  //------------------------------------------------

  const [isLoading, setIsLoading] = useState(true);

  //------------------------------------------------

  //FILTERS STATES
  //------------------------------------------------

  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

  const [startFilterDate, setStartFilterDate] = useState(firstDay);
  const [endFilterDate, setEndFilterDate] = useState(today);

  const [channel, setChannel] = useState("Todos");
  const [channels, setChannels] = useState([]);

  const [prevData, setPrevData] = useState({
    startFilterDate: firstDay,
    endFilterDate: today,
    channel: "Todos",
  });

  //------------------------------------------------

  //EFFECTS
  //------------------------------------------------
  useEffect(() => {
    setPage(0);
    setSearch("");
    setReady(true);
  }, []);

  useEffect(() => {
    if (ready) {
      RequestData();
    }
  }, [ready]);

  //------------------------------------------------

  //EXCEL STATES AND DATA
  //------------------------------------------------

  //------------------------------------------------

  //DATA REQUEST
  //------------------------------------------------

  const RequestData = () => {
    GetSalesList();
    setReady(false);
  };

  const GetSalesList = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("page", page + 1);
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    if (channel != "Todos") {
      const channelToSend = channels.find((el) => el.canal == channel);
      data.append("channel", channelToSend.id);
    }
    if (search != "") {
      data.append("search", search);
    }

    const api = new WarehouseAPI();
    const response = await api.whsSalesEP(data);
    if (response.status == 200) {
      setData(response.data.sales);
      setTotal(response.data.total);
      setCount(Math.ceil(response.data.total / 10));
      setIsLoading(false);
    } else {
      console.log(response);
    }
  };

  //------------------------------------------------

  //HANDLERS
  //------------------------------------------------

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleCloseFilterModal = () => setAnchorEl(null);

  const handleCloseModal = () => {
    setAnchorEl(null);
    settingFilters(prevData.startFilterDate, prevData.endFilterDate);
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  const handleFilter = () => {
    if (endFilterDate < startFilterDate) {
      setErrors({ error: true });
    } else {
      handleCloseFilterModal();
      setErrors({
        error: false,
        errorNullStart: false,
        errorNullEnd: false,
      });
      setPage(0);
      setReady(true);
      setPrevData({
        startFilterDate: startFilterDate,
        endFilterDate: endFilterDate,
      });
    }
  };

  const handleResetFilter = async () => {
    settingFilters(firstDay, today);
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  //------------------------------------------------

  //FILTERS SETTING
  //------------------------------------------------

  const settingFilters = (firstDay, endDay) => {
    setStartFilterDate(firstDay);
    setEndFilterDate(endDay);
  };

  //------------------------------------------------

  return (
    <>
      <div className="w-100 px-3 mt-2">
        {phone ? (
          <div className="mt-2 w-100 p-1 r-15">
            <div className="mb-2">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                requestData={RequestData}
                setPage={setPage}
              />
            </div>

            <div className="d-flex justify-content-between">
              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        ) : (
          <div className="mt-2 w-100 d-flex justify-content-between p-1 r-15">
            <div className="d-flex align-items-center">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                requestData={RequestData}
                setPage={setPage}
              />
            </div>
            <div className="d-flex align-items-center">
              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        )}
        {view ? (
          // Vista de tabla
          isLoading ? (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <SkeletonTableSalesSucursal
                num={3}
                type={roles.ADMIN}
                total={total}
                page={page}
                setPage={setPage}
              />
            </div>
          ) : (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <TableSalesSucursal
                data={data}
                search={search}
                type={roles.ADMIN}
                setPage={setPage}
                page={page}
                total={total}
                rol={"warehouse"}
                requestData={RequestData}
                count={count}
              />
            </div>
          )
        ) : isLoading ? (
          <>
            <div className="mt-3 w-100 r-15">
              <Grid
                container
                alignItems="center"
                direction="row"
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
                columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
              >
                <SkeletonCardSales
                  num={10}
                  rows={6}
                  icon={false}
                  type={roles.ADMIN}
                  total={total}
                  page={page}
                  setPage={setPage}
                  count={count}
                />
              </Grid>
            </div>
          </>
        ) : (
          <ListViewSalesSucursal
            rol={"warehouse"}
            data={data}
            search={search}
            type={roles.ADMIN}
            setPage={setPage}
            page={page}
            total={total}
            count={count}
            requestData={RequestData}
          />
        )}
      </div>

      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseFilterModal}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ModalFilters
            errors={errors}
            setErrors={setErrors}
            handleClose={handleCloseModal}
            dates={{
              setEnd: setEndFilterDate,
              end: endFilterDate,
              setStart: setStartFilterDate,
              start: startFilterDate,
            }}
            fields={[]}
            onClickFilter={handleFilter}
            onClickResetFilter={handleResetFilter}
          />
        </Popover>
      </>
    </>
  );
};
