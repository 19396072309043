import React from "react";

import { Grid, Paper, Skeleton, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ListPagination } from "../Pagination/ListPagination";



export const SkeletonCardProduct = ({ num, rows, icon, img, total, page, setPage, count, height }) => {

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    borderRadius: 5,
    minHeight: height
  }));

  const a = Array(num).fill(true);
  const arrRows = Array(rows).fill(true);

  const phone = useMediaQuery("(max-width:480px)");

  return (
    <>
      {a.map((element, index) => (
        <Grid item xs={6} sm={3} md={2.4} lg= {2.4} xl={2.4} key={index}>
          <Item className="mb-3">
            <div className="d-flex flex-column justify-content-between align-items-center">
              {
                icon && (
                  <div className="d-flex justify-content-end w-100 mr-1 mb-2">
                    <Skeleton variant="circular" width={40} height={40} />
                  </div>
                )
              }
              {
                img && <Skeleton variant="rectangular" />
              }
              {/* <div className="d-flex align-items-center"> */}

                <div className="mt-2 w-100">
                  {
                    arrRows.map((element, index) => <Skeleton variant="text" />)
                  }
                </div>
              {/* </div> */}

            </div>
          </Item>
        </Grid>
      ))}

      <div className="d-flex justify-content-start align-items-center p-2 ml-3">
        <Typography variant="caption" className="ml-2"><Skeleton width={50} /></Typography>
        <Skeleton variant="circular" className="ml-4" width={20} />
        <Skeleton variant="circular" className="ml-2" width={20} />
      </div>
    </>
  );
};
