import React, { useEffect, useRef, useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  TextField,
  Modal,
  useMediaQuery,
  MenuItem,
  Grid,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";

import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";

import { ModalWarning } from "../Modal/ModalWarning";
import { ModalEntrySuccess } from "../Modal/ModalEntrySuccess";

import { InventoryAPI } from "../../api/Inventory";
import { ModalError } from "../Modal/ModalError";
import LoaderDS from "../../assets/LoaderDS.svg";
import { ModalSizesEntries } from "../Modal/ModalSizesEntries";
import { ProductsAPI } from "../../api/Products";
import { WarehouseAPI } from "../../api/Warehouse";
import { useMemo } from "react";
import { theme } from "../../utils/theme";
import dayjs from "dayjs";
import { getDayjsFormatted } from "../../helpers/getDateCleaned";

export const EntryForm = ({ product, lastEntry, isLoading }) => {
  const today = new Date();

  const phone = useMediaQuery("(max-width:480px)");

  const formRef = useRef(null);

  const inventoryAPI = useMemo(() => new InventoryAPI(), []);
  const productAPI = useMemo(() => new ProductsAPI(), []);
  const warehouseAPI = useMemo(() => new WarehouseAPI(), []);

  const [openWarning, setOpenWarning] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const [submitData, setSubmitData] = useState({});
  const [ticketNumber, setTicketNumber] = useState(0);

  const [cedis, setCedis] = useState([]);
  const [branches, setBranches] = useState([]);

  const [errorText, setErrorText] = useState("");

  const [errorMinorDate, setErrorMinorDate] = useState(false);
  const [errorSizes, setErrorSizes] = useState(false);
  const [openBD, setOpenBD] = useState(false);
  const [isLoadingStock, setIsLoadingStock] = useState(false);

  const [stock, setStock] = useState(0);

  const [checkDeparture, setCheckDeparture] = useState({
    cedi: false,
    branch: false,
  });

  const departure = [
    { keyName: "Cedi", type: 1, value: true },
    { keyName: "Sucursal", type: 2, value: true },
  ];

  const dimensions = [
    { keyName: "Peso (kg)", value: "weight", type: false },
    { keyName: "Alto (cm)", value: "height", type: false },
    { keyName: "Profundidad (cm)", value: "depth", type: false },
    { keyName: "Ancho (cm)", value: "width", type: false },
  ];

  useEffect(() => {
    getBranchesList();
    getCedisList();
    getProductStock(0);
  }, []);

  const handleDeparture = (e) => {
    if (e.target.value == 1) {
      setCheckDeparture({ cedi: true });
    } else {
      setCheckDeparture({ branch: true });
    }
  };

  const handleSendForm = () => {
    CreateEntry();
    setOpenWarning(false);
  };

  const handleOpenWarning = () => setOpenWarning(true);
  const handleCloseWarning = () => setOpenWarning(false);

  const handleCloseSuccess = (path) => {
    setOpenSuccess(false);
    navigate(`/cliente/inventario/${path}/`);
  };

  const navigate = useNavigate();

  const getBranchesList = async () => {
    const data = new FormData();

    const response = await warehouseAPI.getBranchesList(data);
    if (response.status == 200) {
      setBranches(response.data.branches);
    } else {
      console.log(response);
    }
  };

  const getCedisList = async () => {
    const data = new FormData();

    const response = await warehouseAPI.cedisList(data);
    if (response.status == 200) {
      setCedis(response.data.cedis);
    } else {
      console.log(response);
    }
  };

  const getProductStock = async (branch) => {
    setIsLoadingStock(true);
    const data = new FormData();
    data.append("product", product.id);

    if (branch != 0) {
      data.append("branch", branch);
    }

    const response = await productAPI.productStockEP(data);
    if (response.status == 200) {
      setStock(response.data.stock);
      setIsLoadingStock(false);
    } else {
      // console.log(response);
      setIsLoadingStock(false);
    }
  };

  const CreateEntry = async () => {
    setOpenBD(true);
    let data = new FormData();

    data.append("product", product.id);
    data.append("pieces", parseInt(submitData.pieces));
    data.append("cedi", submitData.departure == 2 ? "" : submitData.cedi.id);
    data.append(
      "branch",
      submitData.departure == 1 ? "" : submitData.branch.id
    );
    data.append("batch", submitData.batch);
    data.append("arrive", submitData.date);
    data.append("expiration_date", submitData.expiration);

    const response = await inventoryAPI.createInventoryEntryEP(data);
    if (response.status == 200) {
      setTicketNumber(response.data.id);
      setOpenSuccess(true);
      setOpenBD(false);
    } else {
      // console.log(response);
      handleCloseWarning();
      setOpenBD(false);
      // console.log(response.response)
      
      

      if (
        response.response.data.non_field_errors ==
        "Producto debe contener dimensiones para poder crear un envío a almacénes"
      ) {
        setErrorSizes(true);
        setErrorText(response.response.data.non_field_errors[0]);
      } else {
        if (response.response.data.wms_error) {
          setOpenError(true);
          setErrorText(
            `Hubo un error al intentar conectarnos con nuestro sistema de ERP, comunicate con el equipo de soporte y comparteles el id del error:${response.response.data.wms_error}`
          );
        }else{
          setOpenError(true);
          setErrorText(response.response.data.non_field_errors[0]);
        }
        
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      pieces: "",
      cedi: "",
      date: null,
      expiration: null,
      batch: "",

      cedi: "",
      branch: "",

      departure: "",
    },
    validationSchema: Yup.object({
      pieces: Yup.number()
        .typeError("Debe ser un número")
        .required("Campo Obligatorio")
        .min(1, "Debe ser mayor a 1")
        .integer("Solo números enteros"),
      date: Yup.date().nullable().required("Campo Obligatorio"),
      expiration: Yup.date()
        .nullable()
        .required("Campo obligatorio")
        .min(
          Yup.ref("date"),
          "No puede ser menor o igual que la fecha de entrega"
        ),
      batch: Yup.string().required("Campo Obligatorio"),
      departure: Yup.string().required("Campo Obligatorio"),
    }),

    onSubmit: (values) => {
      const data = {
        ...values,
        date: getDayjsFormatted(values.date),
        expiration: getDayjsFormatted(values.expiration),
      };

      setErrorMinorDate(false);
      setSubmitData(data);
      handleOpenWarning();
    },
  });

  const onKeyDown = (e) => {
    //Disable manual date
    e.preventDefault();
  };

  return (
    <div className="mb-3">
      <form ref={formRef}>
        <Grid
          container
          spacing={2}
          columnSpacing={2}
          direction="row"
          justify="center"
          rowSpacing={2}
          columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}
        >
          {" "}
          {/*---Contenedor padre---*/}
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Grid
              item
              xs={12}
              className=" mb-3 border ds-blue-font d-flex flex-column justify-content-between bg-white r-15 p-3"
            >
              <div className="d-flex flex-column justify-content-between">
                <div className="">
                  <p className={`ds-blue-font mb-1 ${phone ? "h6" : "h5"}`}>
                    Detalle de producto
                  </p>
                </div>

                <div className="w-100 mt-3" style={{ fontSize: 18 }}>
                  <p className=" ">
                    <span className="font-weight-bold"> Producto: </span>
                    {product.descripcion}
                  </p>
                  <p className=" ">
                    <span className="font-weight-bold">SKU: </span>
                    {product.sku}
                  </p>
                  <p className="">
                    <span className=" font-weight-bold">
                      Existencias en almacén:{" "}
                    </span>
                    {isLoadingStock ? (
                      <CircularProgress
                        sx={{ color: theme.palette.primary.main }}
                        size={15}
                      />
                    ) : (
                      stock
                    )}
                  </p>
                </div>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="border bg-white r-15 p-3 d-flex flex-column justify-content-between">
                <div className="mb-3">
                  <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
                    Última entrada
                  </p>
                </div>

                <div
                  className={`d-flex w-100 flex-column ds-blue-font`}
                  style={{ fontSize: 18 }}
                >
                  <div>
                    <p className="">
                      <span className="font-weight-bold">Producto: </span>
                      {isLoading ? (
                        <CircularProgress
                          sx={{ color: theme.palette.primary.main }}
                          size={15}
                        />
                      ) : (
                        lastEntry.description
                      )}
                    </p>
                  </div>
                  <p className="">
                    <span className="font-weight-bold">Status: </span>
                    {isLoading ? (
                      <CircularProgress
                        sx={{ color: theme.palette.primary.main }}
                        size={15}
                      />
                    ) : (
                      lastEntry.status
                    )}
                  </p>
                  <p className="">
                    <span className="font-weight-bold">Fecha creación: </span>
                    {isLoading ? (
                      <CircularProgress
                        sx={{ color: theme.palette.primary.main }}
                        size={15}
                      />
                    ) : (
                      lastEntry.creation_date
                    )}
                  </p>
                  <p className="">
                    <span className="font-weight-bold">Piezas añadidas: </span>
                    {isLoading ? (
                      <CircularProgress
                        sx={{ color: theme.palette.primary.main }}
                        size={15}
                      />
                    ) : (
                      lastEntry.pieces
                    )}
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            {" "}
            {/*---Ocupara mitad de pantalla---*/}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ height: "100%" }}
              className={`border bg-white p-3 r-15 `}
            >
              {" "}
              {/*---Altura de 100 para que ocupe verticalmente---*/}
              <div key={"Piezas"} className={`d-flex px-2  flex-column`}>
                <div
                  className={`d-flex align-items-center mr-2 ${
                    phone && "mb-2"
                  }`}
                >
                  <label
                    className="no-wrap"
                    htmlFor={"pieces"}
                  >{`${"Piezas"}:* `}</label>
                </div>
                <div className="d-flex align-items-center w-100">
                  <div className="d-flex flex-column w-100">
                    <TextField
                      error={formik.touched.pieces && formik.errors.pieces}
                      size="small"
                      label={"Piezas"}
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values.pieces}
                      name={"pieces"}
                      id={"pieces"}
                      helperText={
                        formik.touched.pieces && formik.errors.pieces ? (
                          <div>{formik.errors.pieces}</div>
                        ) : null
                      }
                    />
                  </div>
                </div>
              </div>
              <div key={"Almacén"} className={`d-flex px-2 flex-column mt-1`}>
                <div
                  className={`d-flex align-items-center mr-2 ${
                    phone && "mb-2"
                  }`}
                  style={{ minWidth: phone ? "100px" : "200px" }}
                >
                  <label
                    className=" no-wrap"
                    htmlFor={"departure"}
                  >{`${"Almacén"}:* `}</label>
                </div>

                <div className="d-flex align-items-center w-100">
                  <div className="d-flex flex-column w-100">
                    <TextField
                      select
                      size="small"
                      error={
                        formik.touched.departure && formik.errors.departure
                      }
                      label="Almacén"
                      value={formik.values.departure}
                      name={"departure"}
                      id={"departure"}
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleDeparture(e);
                      }}
                      fullWidth
                      helperText={
                        formik.touched.departure && formik.errors.departure ? (
                          <div>{formik.errors.departure}</div>
                        ) : null
                      }
                    >
                      {departure.map((dep) => (
                        <MenuItem key={dep.keyName} value={dep.type}>
                          {dep.keyName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              </div>
              <div
                key={"Destino"}
                className={`d-flex px-2 mb-3 mt-3 justify-content-end`}
              >
                {checkDeparture.cedi ? (
                  <div className={`d-flex align-items-end w-100`}>
                    <TextField
                      select
                      size="small"
                      error={formik.touched.cedi && formik.errors.cedi}
                      label="CEDI"
                      value={formik.values.cedi}
                      name={"cedi"}
                      id={"cedi"}
                      onChange={(e) => {
                        getProductStock(e.target.value.id);
                        formik.handleChange(e);
                      }}
                      fullWidth
                      helperText={
                        formik.touched.cedi && formik.errors.cedi ? (
                          <div>{formik.errors.cedi}</div>
                        ) : null
                      }
                    >
                      {cedis.map((cedi) => (
                        <MenuItem key={cedi.id} value={cedi}>
                          {cedi.cedi}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                ) : null}
                {checkDeparture.branch ? (
                  <div className={`d-flex align-items-center w-100`}>
                    <TextField
                      select
                      size="small"
                      error={formik.touched.branch && formik.errors.branch}
                      label="Sucursal"
                      value={formik.values.branch}
                      name={"branch"}
                      id={"branch"}
                      onChange={(e) => {
                        getProductStock(e.target.value.id);
                        formik.handleChange(e);
                      }}
                      fullWidth
                      helperText={
                        formik.touched.branch && formik.errors.branch ? (
                          <div>{formik.errors.branch}</div>
                        ) : null
                      }
                    >
                      {branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch}>
                          {branch.sucursal}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                ) : null}
              </div>
              <div className={`d-flex px-2 mb-3 flex-column `}>
                {/* ************************************************************ */}

                <div className={`d-flex flex-column`}>
                  <div className={`d-flex align-items-center `}>
                    <label className="">Fecha esperada de entrega:* </label>
                  </div>
                  <DesktopDatePicker
                    allowSameDateSelection
                    minDate={dayjs(today)}
                    label={"Fecha de entrega"}
                    format="DD/MM/YYYY"
                    value={formik.values.date}
                    onChange={(value) => {
                      formik.setFieldValue("date", value);
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        error: formik.touched.date && formik.errors.date,
                        fullWidth: true,
                        onKeyDown: onKeyDown,
                        helperText:
                          formik.touched.date && formik.errors.date ? (
                            <div>{formik.errors.date}</div>
                          ) : null,
                      },
                    }}
                  />
                </div>

                {/* ************************************************************ */}

                <div className={`d-flex flex-column mt-2`}>
                  <div className={`d-flex align-items-center mb-2`}>
                    <label className="m-0">Fecha de caducidad:* </label>
                  </div>

                  <DesktopDatePicker
                    allowSameDateSelection
                    minDate={dayjs(today)}
                    label={"Fecha de caducidad"}
                    format="DD/MM/YYYY"
                    value={formik.values.expiration}
                    onChange={(value) => {
                      formik.setFieldValue("expiration", value);
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        error:
                          formik.touched.expiration && formik.errors.expiration,
                        onKeyDown: onKeyDown,
                        helperText:
                          formik.touched.expiration &&
                          formik.errors.expiration ? (
                            <div>{formik.errors.expiration}</div>
                          ) : null,
                      },
                    }}
                  />
                </div>
              </div>
              <div key={"Lote"} className={`d-flex px-2 mb-3 flex-column`}>
                <div
                  className={`d-flex align-items-center mb-2`}
                  // style={{ minWidth: phone ? "100px" : "200px" }}
                >
                  <label
                    className="no-wrap"
                    htmlFor={"batch"}
                  >{`${"Lote"}:* `}</label>
                </div>
                <div className="d-flex align-items-center w-100">
                  <div className="d-flex flex-column w-100">
                    <TextField
                      error={formik.touched.batch && formik.errors.batch}
                      size="small"
                      label={"Lote"}
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values.batch}
                      name={"batch"}
                      id={"batch"}
                      helperText={
                        formik.touched.batch && formik.errors.batch ? (
                          <div>{formik.errors.batch}</div>
                        ) : null
                      }
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </form>

      <div
        className={`${
          !phone && "d-flex justify-content-end margin-right"
        } mt-2`}
      >
        <div className={`${phone && "mb-2"}`}>
          <CancelButton
            text={"Cancelar"}
            onClick={() => {
              navigate("/cliente/inventario/busqueda/");
            }}
            width={phone ? "100%" : ""}
          />
        </div>
        <AcceptButton
          text={"Enviar"}
          type={"text"}
          onClick={formik.handleSubmit}
          width={phone ? "100%" : ""}
        />
      </div>

      <Modal open={openWarning} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalWarning
            actionCancel={handleCloseWarning}
            actionProceed={handleSendForm}
            submitData={submitData}
            product={product.descripcion}
            type={"entries"}
          />
        </div>
      </Modal>

      <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalEntrySuccess
            ticketNumber={ticketNumber}
            handleClose={handleCloseSuccess}
          />
        </div>
      </Modal>

      <Modal open={openError} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalError
            text={`Error al realizar el envío.`}
            handleClose={() => {
              setOpenError(false);
            }}
            error={errorText}
          />
        </div>
      </Modal>

      <Modal open={errorSizes} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalSizesEntries
            product={product}
            entrysubmitData={submitData}
            CreateEntry={CreateEntry}
            setErrorSizes={setErrorSizes}
          />
        </div>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </div>
  );
};
