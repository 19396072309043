import React from "react";

import { useMediaQuery } from "@mui/material";
import { Close } from "@mui/icons-material";

import { UserForm } from "../../components/Forms/UserForm";

export const EditUser = () => {
  
  const phone = useMediaQuery("(max-width:480px)");

  return (
    <>
      <div className="w-100 px-3">
      <div className="bg-white w-100 mt-3 mb-3 pt-1 pb-1 px-3 r-15">
          <p className={`ds-blue-font m-0 ${phone ? "h6" : "h5"}`}>
          Editar usuario
          </p>
          <p className="m-0 text-secondary">* Campos obligatorios</p>
        </div>

        <div className="mt-2  w-100 d-flex justify-content-between r-15 d-flex flex-column">
          <UserForm /> 
        </div>
      </div>
    </>
  );
};