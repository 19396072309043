import { Typography, Box, useMediaQuery } from "@mui/material";

import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";

export const ModalConfirmation = ({ actionCancel, actionProceed, info }) => {
  const phone = useMediaQuery("(max-width:480px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "90%" : 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
  };
  return (
    <Box sx={style}>
      <div className="d-flex justify-content-center">
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="ds-blue-font"
        >
          Confirmar datos
        </Typography>
      </div>
      <div
        className="d-flex justify-content-center"
        style={{ maxHeight: 300, overflowY: "auto" }}
      >
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {info.map((el) => (
            <p className="m-0">
              {el.label} <strong>{el.value}</strong>
            </p>
          ))}
        </Typography>
      </div>
      <div
        className={`d-flex mt-3 ${
          phone ? "flex-column" : "justify-content-between"
        }`}
      >
        <div className={`${phone && "mb-2"}`}>
          <CancelButton
            text={"Cancelar"}
            onClick={actionCancel}
            type={"text"}
            width={phone ? "100%" : ""}
          />
        </div>
        <AcceptButton
          text={"Continuar"}
          onClick={actionProceed}
          type={"text"}
          width={phone ? "100%" : ""}
        />
      </div>
    </Box>
  );
};
