import React, { useState } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  tableCellClasses,
  Modal,
} from "@mui/material";

import { roles } from "../../helpers/enums";

import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";
import { ListPagination } from "../Pagination/ListPagination";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import ModalProducts from "../Modal/ModalProducts";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const TableInactiveSalesHFM = ({
  search,
  data,
  setPage,
  page,
  total,
  headers,
  requestData,
  count,
}) => {
  const { height, width } = useViewPortDimensions();
  const navigate = useNavigate();

  const [openProducts, setOpenProducts] = useState(false);
  const [products, setProducts] = useState([]);

  const handleOpenProducts = (products) => {
    setOpenProducts(true);
    setProducts(products);
  };

  const orderType = [
    { id: 1, value: "Fast Delivery" },
    { id: 2, value: "On Demand" },
    { id: 3, value: "Same Day - Next Day" },
    { id: 4, value: "Paquetería" },
  ];

  const tRow = (row, index) => {
    const labelId = `enhanced-table-checkbox-${index}`;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        <TableCell align="center" style={{ minWidth: 120 }}>
          {row.sale_order}
        </TableCell>
        <TableCell align="center" style={{ minWidth: 120 }}>
          {row.order_type
            ? orderType.find((el) => el.id == row.order_type).value
            : null}
        </TableCell>
        <TableCell align="center" style={{ minWidth: 130 }}>
          {row.name || row.last_name ? `${row.name} ${row.last_name}` : "N/A"}
        </TableCell>
        <TableCell align="center" style={{ minWidth: 130 }}>
          {row.warehouse}
        </TableCell>
        <TableCell align="center">
          {
            <span
              className="cursor-pointer ds-blue-font"
              onClick={() => handleOpenProducts(row.products)}
            >
              Ver artículos
            </span>
          }
        </TableCell>
        <TableCell align="center">{row.channel}</TableCell>
        <TableCell align="center" style={{ minWidth: 130 }}>
          {row.client}
        </TableCell>
        <TableCell align="center">
          {row.completed == true
            ? "Finalizada"
            : row.completed == false
            ? "Cancelada"
            : "N/A"}
        </TableCell>
        <TableCell align="center" tyle={{ minWidth: 130 }}>
          {row.cancellation ? row.cancellation : "N/A"}
        </TableCell>
        <TableCell align="center">
          {row.last_mille ? row.last_mille : "N/A"}
        </TableCell>
        <TableCell align="center" style={{ minWidth: 130 }}>
          {row.completed == false || row.order_type == 1
            ? "N/A"
            : row.shipping_status}
        </TableCell>

        <TableCell align="center" style={{ minWidth: 120 }}>
          {row.date_created ? row.date_created.substring(0, 16) : "N/A"}
        </TableCell>
        <TableCell align="center" style={{ minWidth: 120 }}>
          {row.date_accepted ? row.date_accepted.substring(0, 16) : "N/A"}
        </TableCell>
        <TableCell align="center" style={{ minWidth: 125 }}>
          {row.date_finished ? row.date_finished.substring(0, 16) : "N/A"}
        </TableCell>
        <TableCell align="center" style={{ minWidth: 125 }}>
          {row.time_completed ? row.time_completed.substring(0, 8) : "N/A"}
        </TableCell>
      </TableRow>
    );
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: height - (74 + 90 + 60) }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-label="sticky table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {headers.map((header, index) => (
                    <StyledTableCell align="center" key={index}>
                      {header.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => tRow(row, index))}
              </TableBody>
            </Table>
            {data.length == 0 && search != "" ? (
              <div className="d-flex justify-content-center mt-2">
                No se encontraron resultados para "{search}"
              </div>
            ) : (
              data.length == 0 &&
              search == "" && (
                <div className="d-flex justify-content-center mt-2">
                  No tienes ventas aún.
                </div>
              )
            )}
          </TableContainer>
          <ListPagination
            setPage={setPage}
            page={page}
            total={total}
            requestData={requestData}
            count={count}
          />
        </Paper>
      </Box>

      <Modal open={openProducts} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalProducts
            order={products}
            handleClose={() => setOpenProducts(false)}
          />
        </div>
      </Modal>
    </>
  );
};
