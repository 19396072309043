import { useState, useEffect, useRef, useMemo } from "react";
import { DownloadFileButton } from "../Buttons/DownloadFileButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";

import { UploadButton } from "../Buttons/UploadButton";
import { DeleteDocButton } from "../Buttons/DeleteDocButton";

import { useNavigate } from "react-router-dom";

import { ModalWarning } from "../Modal/ModalWarning";
import { ModalProductSuccess } from "../Modal/ModalProductSuccess";

import { ProductsAPI } from "../../api/Products";
import { ModalError } from "../Modal/ModalError";
import LoaderDS from "../../assets/LoaderDS.svg";

import readXlsxFile from "read-excel-file";

import { Modal, useMediaQuery, Grid, Backdrop } from "@mui/material";
import { CategoriesHeaders, MassiveEditProductHeaders, MassiveProductHeaders } from "../headers";
import { DownloadButtonExcel } from "../Excel/DownloadButtonExcel";
import { theme } from "../../utils/theme";

export const MassiveProductEditForm = ({ handleClose, requestData }) => {
  const phone = useMediaQuery("(max-width:480px)");

  const [errorMessages, setErrorMessages] = useState("");

  const [openBD, setOpenBD] = useState(false);

  const navigate = useNavigate();

  /*********** FILE HANDLING ***********/
  const [submitData, setSubmitData] = useState({});

  const [excelFile, setExcelFile] = useState(null);
  const [excelData, setExcelData] = useState([]);

  const inputFile = useRef(null);

  const handleUploadClick = (e) => {
    inputFile.current.value = "";
    inputFile.current.click();
    inputFile.current.blur();
  };

  const handleRemoveFile = () => {
    inputFile.current.value = "";
    setExcelFile(null);
    formik.setFieldValue("excelFile", "");
  };

  const handleFile = ({ files }) => {
    if (!files) {
      return;
    }
    const file = files[0];
    setExcelFile(file);
    formik.setFieldValue("excelLoaded", true);
  };

  useEffect(() => {
    if (!excelFile) {
      formik.setFieldValue("excelLoaded", false);
      return;
    }
    formik.setFieldValue("excelLoaded", true);
    getExcelData(excelFile);
  }, [excelFile]);

  const getExcelData = (excelFile) => {
    const schema = {
      sku: {
        prop: "sku",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ sku: value });
        },
        type: String,
      },
      nombre: {
        prop: "nombre",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ nombre: value });
        },
      },
      precio: {
        prop: "precio",
        required: true,
        validate: (value) => {
          excelValidationSchema.validateSync({ precio: value });
        },
      },
      categoria: {
        prop: "categoria",
        required: false,
        validate: (value) => {
          excelValidationSchema.validateSync({ categoria: value });
        },
      },
      subcategoria: {
        prop: "subcategoria",
        required: false,
        validate: (value) => {
            excelValidationSchema.validateSync({ subcategoria: value });
          },
          type: String,
      }
    };

    readXlsxFile(excelFile, { schema }).then(({ rows, errors }) => {
      if (errors.length > 0) {
        console.log("estos son los errores bro", errors);
        formik.setFieldValue("validData", false);
        setErrorMessages(getErrorMessages(errors));
        setOpenError(true);
        // console.log(errors);
        return;
      }

      if (rows.length > 1000) {
        formik.setFieldValue("validData", false);
        setErrorMessages("Estas tratando de ingresar más de 1,000 productos");
        setOpenError(true);
        return;
      }

      const skuArr = rows.map((products) => {
        return products.sku;
      });

      if (validateDuplicity(skuArr)) {
        formik.setFieldValue("validData", false);
        setErrorMessages("Tienes productos con SKU duplicados");
        setOpenError(true);
        return;
      }

      setExcelData(rows);
      // console.log(rows);
      formik.setFieldValue("validData", true);
    });
  };

  const validateDuplicity = (data) => {
    const duplicateData = data.filter(
      (item, index) => data.indexOf(item) !== index
    );

    if (duplicateData.length > 0) {
      return true;
    }
    return false;
  };

  /*********** EXCEL VALIDATION HANDLING ***********/

  const excelValidationSchema = Yup.object({
    name: Yup.string()
      .matches(
        /^[^\W\s][\w\u00C0-\u024F\u1E00\s\'\.\/\&\-\,]*$/,
        "comenzar por letra o número. Sólo se aceptan estos carácteres especiales: -/&',._"
      )
      .min(5, "tener al menos 5 carácteres")
      .max(150, "tener máximo 150 carácteres"),
    sku: Yup.string()
      .matches(/^[a-zA-Z0-9\-]+$/, "ser alfanumérico")
      .min(2, "tener al menos 2 carácteres")
      .max(40, "tener máximo 40 carácteres"),
    clase_articlo: Yup.string()
      .matches(
        /^[^\W\s][\w\u00C0-\u024F\u1E00\s\'\.\/\&\-\,]*$/,
        "comenzar por letra o número. Sólo se aceptan estos carácteres especiales: -/&',._"
      )
    .min(5, "tener al menos 5 carácteres")
    .max(150, "tener máximo 150 carácteres"),
    precio: Yup.number()
      .min(0.1, "ser mayor a 0")
      .typeError("ser un número sin espacios")
      .max(1000000, "ser un número menor a 1,000,000")
      .transform((value, originalValue) =>
        /\s/.test(originalValue) ? NaN : value
      ) /*No acepta espacios vacíos */
      .test("len", "tener máximo dos decimales", (val) => {
        if (val != undefined) {
          return /^(?!0+(?:\.0+)?$)\s*-?[0-9]\d*(\.\d{1,2})?\s*$/.test(val);
        }
        return true;
      }),
      subcategoria: Yup.string()
      .matches(
        /^[^\W\s][\w\u00C0-\u024F\u1E00\s\'\.\/\&\-\,]*$/,
        "comenzar por letra o número. Sólo se aceptan estos carácteres especiales: -/&',._"
      )
      .min(5, "tener al menos 5 carácteres")
      .max(150, "tener máximo 150 carácteres"),
  });

  const getErrorMessages = (errors) => {
    // console.log("los errores jaja", errors)
    const errorMessages = errors.map((error) => {
      if (error.error == "required") {
        return `Fila ${error.row}: tienes un campo vacío`;
      } else {
        return `Fila ${error.row}: la columna ${error.column} debe ${error.error}`;
      }
    });
    return errorMessages;
  };

  /*********** MODAL HANDLING ***********/
  const [openWarning, setOpenWarning] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    handleClose();
    requestData();
  };

  /*********** SUBMIT HANDLING ***********/

  const handleSendForm = () => {
    setOpenWarning(false);
    addMassiveProducts();
  };

  // API INSTANCE
  const productAPI = useMemo(() => new ProductsAPI(), []);

  const addMassiveProducts = async () => {
    setOpenBD(true);
    const data = new FormData();

    data.append("file", submitData.excelFile);
    const response = await productAPI.editMassiveProductFormsEp(data);
    console.log(response);
    if (response.status == 200) {
      setOpenSuccess(true);
      setOpenBD(false);
    } else {
      // console.log(response);
      setOpenBD(false);
      const articles = response.response.data.articles?.map(el=> Object.values(el)[0])
      const errorMessage = `${response.response.data.message} ${articles}`
      setErrorMessages(errorMessage);
      setOpenError(true);
    }
  };

  /********* CATEGORY LIST **********/

  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    let data = 'all=true'
    const response = await productAPI.categoriesListEP(data);
    if (response.status == 200) {
      setCategories(response.data);
    } else {
      console.log(response);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  /********* PRODUCT TEMPLATE DUMMY DATA **********/
  const dummyData = [
    {
      sku: 12912239,
      name: "nombre_ejemplo 1",
      precio: 1300.23,
      clase_articlo: "prueba catgoria de uber",
      subcategoria: "ejemplo 1",
    },
    {
        sku: 12912249,
        name: "nombre_ejemplo 2",
        precio: 1999,
        clase_articlo: "prueba catgoria de uber 2",
        subcategoria: "ejemplo 2",
    },
  ];

  /*********** FORMIK ***********/

  const formik = useFormik({
    initialValues: {
      excelLoaded: false,
      excelFile: "",
      validData: true,
    },
    validationSchema: Yup.object({
      excelFile: Yup.string().when("excelLoaded", {
        is: false,
        then: Yup.string().required("Campo obligatorio"),
      }),
      validData: Yup.mixed().oneOf([true], "El archivo contiene errores"),
    }),
    onSubmit: (values) => {
      const data = {
        ...values,
        excelFile: excelFile,
      };
      console.log("datos a enviar", data);
      setSubmitData(data);
      setOpenWarning(true);
    },
  });

  const onKeyDown = (e) => {
    //Disable manual date
    e.preventDefault();
  };

  return (
    <div className="">
      <form>
        <Grid
          container
          spacing={2}
          columnSpacing={2}
          direction="row"
          justify="center"
          rowSpacing={2}
          columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}
        >
          {" "}
          <Grid item xs={12} sm={12} md={12} lg={12} className="pt-0 pr-0">
            <Grid
              item
              xs={12}
              className="m-3 border ds-blue-font d-flex flex-column justify-content-between bg-white r-15 p-3"
            >
              <div
                className={`d-flex flex-column justify-content-between ${
                  phone ? "xs-font" : null
                }`}
              >
                <div className="">
                  <h3 className={`ds-blue-font mb-1 ${phone ? "h6" : "h5"}`}>
                    Instrucciones
                  </h3>
                </div>

                <div
                  className={`w-100 ${phone ? null : "mt-3"}`}
                  style={phone ? { fontSize: 15 } : { fontSize: 18 }}
                >
                  <div
                    className={`d-flex align-items-center w-100 ${
                      phone ? null : "mb-2"
                    }`}
                  >
                    <p className={`m-0`}>
                      {" "}
                      <span className="font-weight-bold"> 1. </span> Descargue
                      el template:
                    </p>
                    <DownloadButtonExcel
                      text={phone ? "" : "Template"}
                      disabled={false}
                      multiple={true}
                      isLoading={false}
                      sheetName={`Template Productos`}
                      getData={() => console.log("excel downloading")}
                      data={[
                        {
                          name: "Productos",
                          data: dummyData,
                          headers: MassiveEditProductHeaders,
                        },
                        {
                          name: "Categorías",
                          data: categories,
                          headers: CategoriesHeaders,
                        },
                      ]}
                    />
                  </div>
                  <div
                    className={`d-flex align-items-center w-100 ${
                      phone ? null : "mb-2"
                    }`}
                  >
                    <p className="m-0">
                      <span className="font-weight-bold"> 2. </span>
                      Lea las instrucciones:
                    </p>
                    <a
                      href="https://qaplusback.boxai.mx/static/Instrucciones_edicion_masiva.pdf"
                      target="_blank" rel="noreferrer"
                    >
                      <DownloadFileButton
                        text={phone ? "" : "Instrucciones"}
                        setOpenBD={setOpenBD}
                      />
                    </a>
                  </div>

                  <p className="py-1 m-0">
                    <span className=" font-weight-bold">3. </span>
                    Carga el template.
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
          {/*---Altura de 100 para que ocupe verticalmente---*/}
          <Grid item xs={12} sm={12} md={12} lg={12} className={"pt-0"}>
            {" "}
            {/*---Ocupara full de pantalla---*/}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {" "}
              <div className="border bg-white r-15 mb-3 mx-3 p-3 d-flex flex-column justify-content-between">
                <div key={"ArchivoExcel"} className={`d-flex  flex-column`}>
                  <div className="d-flex align-items-center w-100">
                    <div className="d-flex flex-column w-100">
                      <div className={` d-flex  flex-column `}>
                        <div className="d-flex align-items-center w-100 mt-2 ">
                          <div className="d-flex flex-column w-100 ">
                            <input
                              name={excelFile}
                              ref={inputFile}
                              type="file"
                              accept=".xlsx"
                              multiple
                              style={{ display: "none" }}
                              onChange={(e) => {
                                handleFile(e.target);
                              }}
                            />
                            <label htmlFor={excelFile}>
                              <div className="no-wrap d-flex justify-content-between">
                                Archivo:*
                              </div>
                              <UploadButton
                                text={
                                  excelFile ? excelFile.name : "Cargar Archivo"
                                }
                                fileLoaded={excelFile ? "excel" : ""}
                                onClick={handleUploadClick}
                                border={
                                  excelFile
                                    ? theme.palette.secondary.main
                                    : theme.palette.neutrals.inactive
                                }
                                font={
                                  excelFile
                                    ? theme.palette.secondary.main
                                    : theme.palette.neutrals.darkGray
                                }
                                error={
                                  formik.errors.excelFile
                                    ? formik.errors.excelFile
                                    : formik.errors.validData
                                }
                                touched={formik.touched.excelFile}
                              />
                              <div className=" d-flex justify-content-between">
                                {(formik.touched.excelFile &&
                                  formik.errors.excelFile) ||
                                (formik.touched.excelFile &&
                                  formik.errors.validData) ? (
                                  <div className="pl-3 mt-1 text-danger xs-font">
                                    {formik.errors.excelFile ||
                                      formik.errors.validData}
                                  </div>
                                ) : (
                                  <div className="pl-3 mt-1 text-danger xs-font"></div>
                                )}
                                {excelFile ? (
                                  <div className="">
                                    <DeleteDocButton
                                      onClick={handleRemoveFile}
                                      text={"Eliminar"}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={12}></Grid>
            <div
              className={`${
                !phone && "d-flex justify-content-center"
              } m-3 mt-0`}
            >
              <div className={phone ? "mb-2" : "mx-3"}>
                <CancelButton
                  text="Cancelar"
                  type={"text"}
                  onClick={handleClose}
                  width={phone ? "100%" : ""}
                />
              </div>
              <AcceptButton
                text="Cargar"
                type={"text"}
                onClick={formik.handleSubmit}
                width={phone ? "100%" : ""}
              />
            </div>
          </Grid>
        </Grid>
      </form>
      <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalProductSuccess
            handleClose={handleCloseSuccess}
            text={"Productos"}
            edit={"none"}
            massive={true}
          />
        </div>
      </Modal>
      <Modal open={openWarning}>
        <div>
          <ModalWarning
            actionCancel={() => {
              setOpenWarning(false);
            }}
            actionProceed={handleSendForm}
            fileData={excelData}
            submitData={submitData}
            type={"products"}
          />
        </div>
      </Modal>
      <Modal open={openError}>
        <div>
          <ModalError
            text={
              formik.values.validData
                ? "No se pudo agregar los productos"
                : "Encontramos errores en tu archivo."
            }
            handleClose={() => {
              setOpenError(false);
            }}
            error={errorMessages}
          />
        </div>
      </Modal>
      {/* 
      <Modal open={errorSizes} >
        <div>
          <ModalSizesEntries
            product={product}
            entryObj={obj}
            CreateEntry={CreateEntry}
            setErrorSizes={setErrorSizes}
          />
        </div>
      </Modal>
          */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </div>
  );
};
