import React from "react";

import { Grid } from "@mui/material";
import { CardGraph } from "../Card/CardGraph";
import { theme } from "../../utils/theme";

let maxValueData;

const options = {
  chartArea: { width: "80%", height: "50%" },
  isStacked: true,
  annotations: {
    alwaysOutside: true,
    textStyle: {
      color: "black",
    },
  },

  series: {
    0: {
      axis: "día",
      color: theme.palette.primary.main,
    },
    1: {
      axis: "tarde",
      color: "#8CC044",
    },
    2: {
      axis: "noche",
      color: "#19B1FF",
    },
  },
  vAxis: {
    title: "Pedidos",
    titleTextStyle: {
      color: "black",
      bold: true,
    },
    viewWindow: {
      max: maxValueData + 25, //maximum value of annotations + 1
    },
  },

  hAxis: {
    title: "Día",
    titleTextStyle: {
      color: "black",
      bold: true,
    },
  },
};

const modalOption = {};

export const ByTimeGraph = ({ data, isLoading, client, maxValue, filters }) => {
  maxValueData = maxValue;

  return (
    <>
      <Grid item xs={4} sm={8} md={8}>
        <CardGraph
          title={"Ventas por semana"}
          type={"ColumnChart"}
          data={data}
          options={options}
          modalOption={modalOption}
          isLoading={isLoading}
          client={client}
          filters={filters}
        />
      </Grid>
    </>
  );
};
