import React from "react";

import { Grid, Paper, Skeleton, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  // display: "flex",
  // alignItems: "center",
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: 5,
  minHeight: 250,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center"

}));

export const SkeletonCardSearchInventory = ({ num }) => {
  const a = Array(num).fill(true);
  const phone = useMediaQuery("(max-width:480px)");

  return (
    <>
      {a.map((element, index) => (
    <Grid item xs={6} lg={2.4} xl={2.4} key={index}>
        <Item className="mb-3">

        <div className="d-flex align-items-center w-100 justify-content-end">
              <Skeleton variant="circular" width={40} height={40} />
            </div>

          <Skeleton variant="rectangular" width={100} height={100} />
           
            <div className="w-100">
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </div>
            

      
      
          
        </Item>
    </Grid>

      ))}

     
    </>
  );
};
