import React from "react";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import { Grid, Paper, styled, useMediaQuery } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: 10,
  // minHeight: 330,
  // display: "flex",
  // alignItems: "center",
  // flexDirection: "column",
  // justifyContent: "space-between"
}));

export const FlippyCard = ({
  title,
  icon,
  onClick,
  description,
  height,
  xs,
  sm,
  md,
  lg,
  bS,
}) => {
  const phone = useMediaQuery("(max-width:480px)");

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} style={{ height: height }}>
      {/* <Grid style={{ height: "100%" }}> */}

      <Flippy
        flipOnHover={true} // default false
        flipDirection="horizontal" // horizontal or vertical
        onClick={onClick}
        style={{ cursor: "pointer", height: height }}
      >
        <FrontSide
          // className="bg-primary"
          animationDuration={1100}
          style={{
            backgroundColor: "#fff",
            textAlign: "center",
            borderRadius: 10,
            height: height,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // backgroundImage: `url(${icon})`,
            // backgroundSize: `${bS}`,
            // backgroundRepeat: "no-repeat",
            // backgroundPosition: "bottom right",
          }}
        >
          {/* <Item style={{height: height}}> */}
          {/* <div className="bg-primary"> */}

          <div>
            <p className={`ds-blue-font ${phone ? "h5" : "h4"}`}>{title}</p>
          </div>
          <div className="icon-animation">{icon}</div>

          {/* </div> */}

          {/* </Item> */}
        </FrontSide>
        <BackSide
          animationDuration={1100}
          style={{
            backgroundColor: "#fff",
            textAlign: "center",
            borderRadius: 10,
            padding: "30px",
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            height: height,
          }}
        >
          <div className="w-100  ">
            <div>
              <p className={`ds-blue-font ${phone ? "h5" : "h4"}`}>{title}</p>
            </div>
            <div className="">{description}</div>
          </div>
        </BackSide>
      </Flippy>
      {/* </Grid> */}
    </Grid>
  );
};
