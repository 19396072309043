import { HttpRequest } from "./HttpRequest";
import { getHost } from "../helpers/getHost";

export class ClientAPI {
  constructor() {
    this.http = new HttpRequest();
    this.host = getHost();
    this.token = localStorage.getItem("token");
    this.config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${this.token}`,
      },
    };
  }

  getClientInfoEP(data) {
    return this.http.post(
      `${this.host}/cliente/ws/getClientInfo/`,
      data,
      this.config
    );
  }

  editClientEP(data) {
    return this.http.put(
      `${this.host}/cliente/ws/editClient/`,
      data,
      this.config
    );
  }

  usersListEP(data) {
    return this.http.post(
      `${this.host}/cliente/ws/getRolClients/`,
      data,
      this.config
    );
  }

  addUserEP(data) {
    return this.http.post(
      `${this.host}/cliente/ws/rolClient/`,
      data,
      this.config
    );
  }

  editUserEP(data) {
    return this.http.put(
      `${this.host}/cliente/ws/rolClient/`,
      data,
      this.config
    );
  }

  deleteUserEP(data) {
    return this.http.post(
      `${this.host}/cliente/ws/deleteRolClients/`,
      data,
      this.config
    );
  }

  usersExcelListEP(data) {
    return this.http.post(
      `${this.host}/cliente/ws/getRolClientsExcel/`,
      data,
      this.config
    );
  }
}
