import React from "react";
import { Grid, IconButton } from "@mui/material";
import { FlippyCard } from "../../components/FlippyCard";
import AccountIcon from '../../assets/Account.svg';
import PasswordIcon from '../../assets/Password.svg';
import { useNavigate } from "react-router-dom";

export const AccountCards = () => {
  const navigate = useNavigate();
  const rol = localStorage.getItem("rol");

  const renderByRol = () => {
    switch (rol) {
      case "4":
      case "5":
      case "6":
        return <FlippyCard
          xs={6} sm={6} md={6} lg={6}
          // gridSize={6}
          description={"Actualiza tu contraseña"}
          onClick={() => {
            navigate("/cliente/cuenta/password/");
          }}
          title={"Contraseña"}
          icon={<IconButton>
            <img
              src={PasswordIcon}
              alt="Password"
              width={100}
              height={100} />
          </IconButton>} />
          ;

      default:
        return <> <FlippyCard
          xs={6} sm={6} md={6} lg={6}
          // gridSize={6}
          description={"Edita tu información de usuario cómo: dirección, email, número telefónico"}
          onClick={() => {
            navigate("/cliente/cuenta/perfil/");
          }}
          title={"Perfil"}
          icon={<IconButton>
            <img
              src={AccountIcon}
              alt="Account"
              width={100}
              height={100} />
          </IconButton>} />

          <FlippyCard
            xs={6} sm={6} md={6} lg={6}
            description={"Actualiza tu contraseña"}
            onClick={() => {
              navigate("/cliente/cuenta/password/");
            }}
            title={"Contraseña"}
            icon={<IconButton>
              <img
                src={PasswordIcon}
                alt="Password"
                width={100}
                height={100} />
            </IconButton>} />
        </>;
    }
  }

  return (
    <>
      <div className="w-100 px-3">
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}
          className="mt-2"
        >
          {
            renderByRol()
          }

        </Grid>
      </div>
    </>
  );
};
