import { HttpRequest } from "./HttpRequest";
import { getHost } from "../helpers/getHost";

export class SalesB2CAPI {
  constructor() {
    this.http = new HttpRequest();
    this.host = getHost();
    this.token = localStorage.getItem("token");
    this.config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${this.token}`,
      },
    };
  }

  salesListEP(data) {
    return this.http.post(`${this.host}/sale/ws/salesList/`, data, this.config);
  }

  getSaleEP(data) {
    return this.http.post(`${this.host}/sale/ws/getSale/`, data, this.config);
  }

  salesExcelListEP(data) {
    return this.http.post(
      `${this.host}/sale/ws/salesExcelList/`,
      data,
      this.config
    );
  }

  salesDuringDayEP(data) {
    return this.http.post(
      `${this.host}/sale/ws/salesDuringDay/`,
      data,
      this.config
    );
  }
}
