import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  styled,
  tableCellClasses,
  Popover,
  Modal,
  useMediaQuery,
  Backdrop,
} from "@mui/material";

import { Delete, DeleteForever, EditTwoTone } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";

import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";

import { ListPagination } from "../Pagination/ListPagination";
import { ModalDeletUser } from "../Modal/ModalDeleteUser";
import { ModalError } from "../Modal/ModalError";

import LoaderDS from "../../assets/LoaderDS.svg";
import { ModalProductSuccess } from "../Modal/ModalProductSuccess";
import { ModalDeleteSuccess } from "../Modal/ModalDeleteSuccess";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const TableUsers = ({
  search,
  data,
  setPage,
  page,
  total,
  RequestData,
  count
}) => {
  const { height, width } = useViewPortDimensions();
  const navigate = useNavigate();
  const phone = useMediaQuery("(max-width:480px)");
  const [openBD, setOpenBD] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorField, setErrorField] = useState("");
  const [info, setInfo] = useState();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => setAnchorEl(null);

  const handleClick = (row) => {
    navigate("/cliente/usuarios/editar/", {
      state: {
        id: row.id ? row.id : "",
        position: row.employee_position ? row.employee_position : "",
        name: row.nombre ? row.nombre : "",
        lastName: row.apellidos ? row.apellidos : "",
        email: row.email ? row.email : "",
        personalNumber: row.telefono ? row.telefono : "",
        rol: row.rol ? row.rol : "",
      },
    });
  };

  const handleDelete = (event, row) => {
    setInfo(row);
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: height - (74 + 90 + 60) }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-label="sticky table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell align="center">Nombre</StyledTableCell>
                  <StyledTableCell align="center">Teléfono</StyledTableCell>
                  <StyledTableCell align="center">Email</StyledTableCell>
                  <StyledTableCell align="center">Rol</StyledTableCell>
                  <StyledTableCell align="center">Puesto</StyledTableCell>
                  <StyledTableCell align="center">Fecha</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell padding="checkbox">
                        <div className="d-flex">
                          <Tooltip title={"Editar"} arrow placement="top">
                            <div className={`hover-icon`}>
                              <IconButton onClick={() => handleClick(row)}>
                                <EditTwoTone />
                              </IconButton>
                            </div>
                          </Tooltip>

                          <Tooltip title={"Eliminar"} arrow placement="top">
                            <div className={`hover-icon`}>
                              <IconButton
                                onClick={(e) => {
                                  handleDelete(e, row);
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </div>
                          </Tooltip>
                        </div>
                      </TableCell>

                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="center"
                      >
                        {" "}
                        {row.nombre} {row.apellidos}{" "}
                      </TableCell>
                      <TableCell align="center"> {row.telefono} </TableCell>
                      <TableCell align="center"> {row.email} </TableCell>
                      <TableCell align="center">
                        {" "}
                        {row.rol == 4 ? "Inventario" : "Ventas"}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {row.employee_position}{" "}
                      </TableCell>
                      <TableCell align="center" sx={{ minWidth: 110 }}>
                        {" "}
                        {row.fecha_registro}{" "}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {data.length == 0 && search != "" ? (
              <div className="d-flex justify-content-center mt-2">
                No se encontraron resultados para "{search}"
              </div>
            ) : (
              data.length == 0 &&
              search == "" && (
                <div className="d-flex justify-content-center mt-2">
                  No tienes ventas aún.
                </div>
              )
            )}
            {/* {data.length == 0 && (
                            <div className="d-flex justify-content-center mt-2">
                                No hay clientes registrados aún.
                            </div>
                        )} */}
          </TableContainer>
          <ListPagination
            setPage={setPage}
            page={page}
            total={total}
            requestData={RequestData}
            count={count}
          />
        </Paper>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        // onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: !phone && 300 },
        }}
      >
        <ModalDeletUser
          info={info}
          handleClose={handleClose}
          setOpenBD={setOpenBD}
          setOpenError={setOpenError}
          setErrorField={setErrorField}
          RequestData={RequestData}
          setOpenSuccess={setOpenSuccess}
        />
      </Popover>

      <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalDeleteSuccess text={"Usuario"} />
        </div>
      </Modal>

      <Modal open={openError} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalError
            text={`La solicitud no pudo ser completada.`}
            handleClose={() => {
              setOpenError(false);
            }}
            error={errorField}
          />
        </div>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
