import { createTheme } from "@mui/material";

export const theme = createTheme({
    typography: {
      fontFamily: `"Open Sans", sans-serif`,
    },
    palette: {
      primary: {
        main: "#ffc500",
        light: "#ffc500",
        dark: "#ffc500",
      },
      secondary: {
        main: "#004876",
      },
      neutrals: {
        darkGray: "rgba(0, 0, 0, 0.6)",
        inactive: "#838e91"
      },
      error:{
        main: "#d32f2f"
      }
    },
  });
  