import { Divider, Skeleton } from "@mui/material";
import React from "react";

export function SkeletonMapList({ num }) {
  const arrNum = Array(num).fill(true);

  return (
    <>
      {arrNum.map((e, i) => (
        <>
          <div
            key={i}
            className="d-flex w-100 my-3 justify-content-center align-items-center"
          >
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              className="mr-3"
            />
            <Skeleton
              variant="text"
              width={"60%"}
              height={30}
              className="mr-3"
            />
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              className="mr-3"
            />
          </div>
          <Divider key={"a" + 1} />
        </>
      ))}
    </>
  );
}
