import React from "react";

import { Grid, Paper, IconButton, useMediaQuery } from "@mui/material";

import { FlippyCard } from "../../../components/FlippyCard";

import { useNavigate } from "react-router-dom";
import ReportsSales from "../../../assets/ReportsSales.svg";
import store from "../../../assets/store.svg";
import homeDelivery from "../../../assets/homeDelivery.svg";

import { styled } from "@mui/material/styles";
import { useState } from "react";

export const Sales = () => {
  const navigate = useNavigate();

  const [navCardsView, setNavCardsView] = useState(false);

  const phone = useMediaQuery("(max-width:480px)"); // FOR RESPONSIVE DESIGN

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(4),
    margin: theme.spacing(phone ? 1 : 4),
    borderRadius: 10,
    width: 210,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  }));

  return (
    <>
      <div className="w-100 mt-5 px-4">
        <Grid
          container
          spacing={5}
          direction="row"
          justify="center"
          rowSpacing={1}
          columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}
          height={"50vh"}
        >
          {navCardsView == false ? (
            <>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <FlippyCard
                  onClick={() => {
                    navigate("/administrador/ventas/B2C/");
                  }}
                  height={"100%"}
                  description={"Ventas a clientes"}
                  title={"B2C"}
                  gridSize={12}
                  icon={
                    <IconButton>
                      <img
                        src={homeDelivery}
                        alt="Existance List"
                        width={100}
                        height={100}
                      />
                    </IconButton>
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6}>
                <FlippyCard
                  onClick={() => setNavCardsView(true)}
                  height={"100%"}
                  description={"Ventas a negocios."}
                  title={"B2B"}
                  gridSize={12}
                  icon={
                    <IconButton>
                      <img
                        src={store}
                        alt="Existance"
                        width={100}
                        height={100}
                      />
                    </IconButton>
                  }
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12} className={phone && "pl-4"}>
              <div className="d-flex justify-content-center w-100 align-items-center flex-wrap">
                <Item
                  className="hover-card2 ds-blue-font cursor-pointer"
                  onClick={() => {
                    navigate("/administrador/ventas/B2B");
                  }}
                >
                  <p className="h5 text-center mb-3">Ventas</p>
                  <img src={ReportsSales} alt="Transfer" height={80} />
                </Item>
                <Item
                  className="hover-card2 ds-blue-font cursor-pointer"
                  onClick={() => {
                    navigate("/administrador/ventas/B2B/negocios");
                  }}
                >
                  <p className="h5 text-center mb-3">Negocios</p>
                  <img src={ReportsSales} alt="Transfer" height={80} />
                </Item>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
};
