import { useContext, useState } from "react";
import { createContext } from "react";
import { FilterContext } from "./FilterContext";
import { getCSRF } from "../helpers/getCSRF";
import { ProductsAPI } from "../api/Products";
import { getDayjsFormatted } from "../helpers/getDateCleaned";

export const ProductContext = createContext();

export function ProductContextProvider(props) {
  //DATA STATES
  //------------------------------------------------

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  //------------------------------------------------

  //FILTER STATES
  //------------------------------------------------
  const { category, categories, nullableEndDate, nullableStartDate, search } =
    useContext(FilterContext);

  //------------------------------------------------

  //LOADERS STATES
  //------------------------------------------------

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  //------------------------------------------------

  //EXCEL STATES AND DATA
  //------------------------------------------------

  const [excelData, setExcelData] = useState([]);

  //------------------------------------------------

  //DATA REQUEST
  //------------------------------------------------

  const productAPI = new ProductsAPI();

  const getProductExcelList = async () => {
    setIsLoadingExcel(true);
    let data = new FormData();
    data.append("excel", true)
    

    if (nullableEndDate != null) {
      data.append("endDate", getDayjsFormatted(nullableEndDate));
    }
    if (nullableStartDate != null) {
      data.append("startDate", getDayjsFormatted(nullableStartDate));
    }
    if (category != "Todas") {
      const categoryToSend = categories.find((el) => el.categoria == category);
      data.append("category", categoryToSend.id);
    }
    if (search != "") {
      data.append("search", search);
    }

    const response = await productAPI.productExcelListEP(data);
    if (response.status == 200) {
      setExcelData(response.data.products);
      console.log('datos del excel jaja', response.data.products)
      setIsLoadingExcel(false);
    } else {
      console.log(response);
    }

    return(response.data.products);
  };

  const getProductsList = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("page", page + 1);

    if (nullableEndDate != null) {
      data.append("endDate", getDayjsFormatted(nullableEndDate));
    }
    if (nullableStartDate != null) {
      data.append("startDate", getDayjsFormatted(nullableStartDate));
    }
    if (category != "Todas") {
      const categoryToSend = categories.find(
        (el) => el.categoria == category
      ).id;
      data.append("category", categoryToSend);
    }
    if (search != "") {
      data.append("search", search);
    }

    const response = await productAPI.productsListEP(data);
    if (response.status == 200) {
      setData(response.data.products);
      console.log("esta es la data de product list", response.data.products)
      setIsLoading(false);
      setTotal(response.data.total);
      setCount(Math.ceil(response.data.total / 10));
    } else {
      console.log(response);
    }
  };

  return (
    <ProductContext.Provider
      value={{
        getProductsList,
        getProductExcelList,
        data,
        excelData,
        page,
        setPage,
        total,
        setTotal,
        count,
        isLoading,
        isLoadingExcel,
      }}
    >
      {props.children}
    </ProductContext.Provider>
  );
}
