import React, { useState } from "react";

import {
  Grid,
  Paper,
  IconButton,
  useMediaQuery,
  Tooltip,
  Modal,
  Backdrop,
  Popover,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { roles } from "../helpers/enums";
import { EditTwoTone, DeleteForever } from "@mui/icons-material";
import { ModalEditEntry } from "./Modal/ModalEditEntry";
import { ModalError } from "./Modal/ModalError";
import LoaderDS from "../assets/LoaderDS.svg";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { ModalEntryProducts } from "../components/Modal/ModalEntryProducts";

let colorChange;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "#1A2027"
      : "#fff" /*Item fuera porque choca con posición del popover */,
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: 10,
  border: "solid 1px",
  borderColor: colorChange,
  minHeight: 180,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const CardEntry = ({
  info,
  color,
  type,
  RequestData,
  GetDataEntries,
}) => {
  colorChange = color;
  const phone = useMediaQuery("(max-width:480px)");
  const [data, setData] = useState();
  const [openProducts, setOpenProducts] = useState(false);

  const [openBD, setOpenBD] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorField, setErrorField] = useState("");

  const [action, setAction] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event, action, info) => {
    setAnchorEl(event.currentTarget);
    setData(info);
    setAction(action);
  };
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Grid item xs={6} sm={3} md={2.4} lg={2.4} xl={2.4}>
        <Item
          className={`mb-2 ${info.status == "Por validar" && "hover-card"}`}
        >
          {/* <div className="d-flex justify-content-between align-items-center w-100"> */}
          {type == roles.CLIENT && (
            <div className="w-100 d-flex justify-content-between">
              <Tooltip title={"Cancelar"} arrow placement="top">
                <div
                  className={`${info.status == "Por validar" && "hover-icon"}`}
                >
                  <IconButton
                    sx={{ "&:hover": { color: "red" } }}
                    onClick={(e) => handleClick(e, "cancel", info)}
                    disabled={info.status != "Por validar" ? true : false}
                  >
                    <DeleteForever />
                  </IconButton>
                </div>
              </Tooltip>
              <Tooltip title={"Ver productos"} arrow placement="top">
                <div className={`${info.products.length > 0 && "hover-icon"}`}>
                  <IconButton
                    sx={{ "&:hover": { color: "blue" } }}
                    onClick={() => setOpenProducts(true)}
                    disabled={info.products.length == 0 ? true : false}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </div>
          )}

          <div className="w-100 ml-2">
            <div>Folio: {info.folio}</div>
            <div>Almacén: {info.cedi == null ? info.branch : info.cedi}</div>
            <div>Status: {info.status}</div>
            {type == roles.ADMIN && <div>Cliente: {info.client}</div>}
            {/****Revisar qué tooltip pondremos en las tarjetas
            <Tooltip
              title={info.description.length > 15 ? info.description : ""}
              placement="top"
              arrow
            >
               <div>
                Producto actualizado: {info.description.substring(0, 15)}
                {info.description.length > 15 && "..."}
              </div> 
            </Tooltip> */}
            {/*  <div>Piezas añadidas: {info.pieces}</div>
            <div>
              Piezas confirmadas:{" "}
              {info.quantity_received != null ? info.quantity_received : 0}
            </div> */}
            <div>Fecha: {info.creation_date}</div>
            <div>Llegada: {info.expectedDate}</div>
          </div>
        </Item>
      </Grid>
      <Modal open={openProducts} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalEntryProducts
            info={info}
            handleClose={() => setOpenProducts(false)}
            type={type}
            RequestData={RequestData}
          />
        </div>
      </Modal>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: !phone && 300 },
        }}
      >
        <ModalEditEntry
          info={data}
          RequestData={RequestData}
          GetDataEntries={GetDataEntries}
          action={action}
          handleClose={handleClose}
          setOpenBD={setOpenBD}
          setOpenSuccess={setOpenSuccess}
          setOpenError={setOpenError}
          setErrorField={setErrorField}
        />
      </Popover>

      <Modal open={openError} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalError
            text={`La solicitud no pudo ser completada.`}
            handleClose={() => {
              setOpenError(false);
            }}
            error={errorField}
          />
        </div>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
