import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { getSocketHost } from "../helpers/getHost";
import { FilterContext } from "./FilterContext";
import { getDateCleaned, getDayjsFormatted } from "../helpers/getDateCleaned";
import { HeadFieldManagerAPI } from "../api/HeadFieldManager";

export const SalesContext = createContext();

export function SalesContextProvider(props) {
  //------------------------------------------------
  //DATA STATES
  //------------------------------------------------
  const [data, setData] = useState([]);
  const [cardViewData, setCardViewData] = useState({
    finalized: [],
    canceled: [],
    creation: [],
    picking: [],
    packing: [],
  });
  const [page, setPage] = useState(0); /*Estado p/paginacion*/
  const [total, setTotal] = useState(0);
  const [cardViewTotal, setCardViewTotal] = useState({
    finalized: 0,
    canceled: 0,
    creation: 0,
    picking: 0,
    packing: 0,
  }); /*Total de paginas enviado desde api*/
  const [count, setCount] = useState(); /*Paginacion listas */
  const [excelData, setExcelData] = useState([]);

  const boxAssigned = [
    { id: 1, value: "16x26x12" },
    { id: 2, value: "23x30x21" },
    { id: 3, value: "25x26x15" },
    { id: 4, value: "10x16x8" },
    { id: 5, value: "18x39x33" },
    { id: 6, value: "10x16x16" },
    { id: 7, value: "22x47x38" },
    { id: 8, value: "20x6x41" },
  ];

  const orderTypeList = [
    { id: 1, value: "Fast Delivery" },
    { id: 2, value: "On Demand" },
    { id: 3, value: "Same Day - Next Day" },
    { id: 4, value: "Paquetería" },
  ];

  const {
    branches,
    branch,
    pickers,
    picker,
    orderTypes,
    orderType,
    channels,
    channel,
    search,
    startDate,
    endDate,
    clients,
    client,
    saleHFMHistoricStatus,
    saleHFMActiveStatus,
    salesHFMActiveStatus,
    lastMileStatus,
    lastMilesStatus,
  } = useContext(FilterContext);

  //------------------------------------------------
  //LOADERS STATES
  //------------------------------------------------

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  //------------------------------------------------

  //DATA REQUEST
  //------------------------------------------------

  //api
  const hfmAPI = useMemo(() => new HeadFieldManagerAPI(), []);

  const getSalesList = async (active) => {
    setIsLoading(true);
    let data = new FormData();
    data.append("date_created", getDayjsFormatted(startDate));
    data.append("end_date_created", getDayjsFormatted(endDate));
    data.append("historical", active ? 0 : 1);

    if (branch != "Todas") {
      const auxBranch = branches.find((el) => el.sucursal == branch).id;
      data.append("branch", auxBranch);
    }
    if (picker != "Todos") {
      const auxPicker = pickers.find((el) => el.picker == picker).id;
      data.append("picker", auxPicker);
    }
    if (orderType != "Todos") {
      const auxOrderType = orderTypes.find(
        (el) => el.orderType == orderType
      ).id;
      data.append("order_type", auxOrderType);
    }
    if (channel != "Todos") {
      const auxChannel = channels.find((el) => el.canal == channel).id;
      data.append("channel", auxChannel);
    }
    if (client != "Todos") {
      const auxClient = clients.find((el) => el.client == client).id;
      data.append("client", auxClient);
    }
    if (saleHFMHistoricStatus != "Todos") {
      const auxStatus = saleHFMHistoricStatus == "Finalizada" ? true : false;
      data.append("completed", auxStatus);
    }
    if (saleHFMActiveStatus != "Todos") {
      const auxSaleHFMActiveStatus = salesHFMActiveStatus.find(
        (el) => el.salesHFMActiveStatus == saleHFMActiveStatus
      ).id;
      data.append("status_sale", auxSaleHFMActiveStatus);
    }
    if (lastMileStatus != "Todos") {
      const auxLastMileStatus = lastMilesStatus.find(
        (el) => el.lastMileStatus == lastMileStatus
      ).id;
      data.append("status_lastmille", auxLastMileStatus);
    }
    if (search != "") {
      data.append("search", search);
    }
    data.append("page", page + 1);

    const response = await hfmAPI.activeSalesEP(data);
    if (response.status == 200) {
      setData(response.data.entries);
      setTotal(response.data.total);
      setCount(Math.ceil(response.data.total / 10));
      setIsLoading(false);
    } else {
      console.log(response);
    }
  };

  const getHistoricSalesCardView = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("date_created", getDayjsFormatted(startDate));
    data.append("end_date_created", getDayjsFormatted(endDate));
    data.append("historical", 1);

    if (branch != "Todas") {
      const auxBranch = branches.find((el) => el.sucursal == branch).id;
      data.append("branch", auxBranch);
    }
    if (picker != "Todos") {
      const auxPicker = pickers.find((el) => el.picker == picker).id;
      data.append("picker", auxPicker);
    }
    if (orderType != "Todos") {
      const auxOrderType = orderTypes.find(
        (el) => el.orderType == orderType
      ).id;
      data.append("order_type", auxOrderType);
    }
    if (channel != "Todos") {
      const auxChannel = channels.find((el) => el.canal == channel).id;
      data.append("channel", auxChannel);
    }
    if (client != "Todos") {
      const auxClient = clients.find((el) => el.client == client).id;
      data.append("client", auxClient);
    }
    if (lastMileStatus != "Todos") {
      const auxLastMileStatus = lastMilesStatus.find(
        (el) => el.lastMileStatus == lastMileStatus
      ).id;
      data.append("status_lastmille", auxLastMileStatus);
    }
    if (search != "") {
      data.append("search", search);
    }
    data.append("page", page + 1);

    const response = await hfmAPI.historicSalesCardViewEP(data);
    if (response.status == 200) {
      setCardViewData({
        finalized: response.data.finalized,
        canceled: response.data.canceled,
        creation: [],
        picking: [],
        packing: [],
      });
      setCardViewTotal({
        finalized: response.data.total_finalized,
        canceled: response.data.total_canceled,
        creation: 0,
        picking: 0,
        packing: 0,
      });
      setIsLoading(false);
    } else {
      console.log(response);
    }
  };

  const getActiveSalesCardView = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("date_created", getDayjsFormatted(startDate));
    data.append("end_date_created", getDayjsFormatted(endDate));
    data.append("historical", 0);

    if (branch != "Todas") {
      const auxBranch = branches.find((el) => el.sucursal == branch).id;
      data.append("branch", auxBranch);
    }
    if (picker != "Todos") {
      const auxPicker = pickers.find((el) => el.picker == picker).id;
      data.append("picker", auxPicker);
    }
    if (orderType != "Todos") {
      const auxOrderType = orderTypes.find(
        (el) => el.orderType == orderType
      ).id;
      data.append("order_type", auxOrderType);
    }
    if (channel != "Todos") {
      const auxChannel = channels.find((el) => el.canal == channel).id;
      data.append("channel", auxChannel);
    }
    if (client != "Todos") {
      const auxClient = clients.find((el) => el.client == client).id;
      data.append("client", auxClient);
    }
    if (search != "") {
      data.append("search", search);
    }
    data.append("page", page + 1);

    const response = await hfmAPI.activeSalesCardViewEP(data);
    if (response.status == 200) {
      setCardViewData({
        finalized: [],
        canceled: [],
        creation: response.data.new,
        picking: response.data.picked,
        packing: response.data.packed,
      });
      setCardViewTotal({
        finalized: 0,
        canceled: 0,
        creation: response.data.total_new,
        picking: response.data.total_picked,
        packing: response.data.total_packed,
      });
      setIsLoading(false);
    } else {
      console.log(response);
    }
  };

  const getExcelData = async (active) => {
    setIsLoadingExcel(true);
    let data = new FormData();
    data.append("date_created", getDayjsFormatted(startDate));
    data.append("end_date_created", getDayjsFormatted(endDate));
    data.append("historical", active ? 0 : 1);

    if (branch != "Todas") {
      const auxBranch = branches.find((el) => el.sucursal == branch).id;
      data.append("branch", auxBranch);
    }
    if (picker != "Todos") {
      const auxPicker = pickers.find((el) => el.picker == picker).id;
      data.append("picker", auxPicker);
    }
    if (orderType != "Todos") {
      const auxOrderType = orderTypes.find(
        (el) => el.orderType == orderType
      ).id;
      data.append("order_type", auxOrderType);
    }
    if (channel != "Todos") {
      const auxChannel = channels.find((el) => el.canal == channel).id;
      data.append("channel", auxChannel);
    }
    if (client != "Todos") {
      const auxClient = clients.find((el) => el.client == client).id;
      data.append("client", auxClient);
    }
    if (saleHFMHistoricStatus != "Todos") {
      const auxStatus = saleHFMHistoricStatus == "Finalizada" ? true : false;
      data.append("completed", auxStatus);
    }
    if (saleHFMActiveStatus != "Todos") {
      const auxSaleHFMActiveStatus = salesHFMActiveStatus.find(
        (el) => el.salesHFMActiveStatus == saleHFMActiveStatus
      ).id;
      data.append("status_sale", auxSaleHFMActiveStatus);
    }
    if (search != "") {
      data.append("search", search);
    }

    const response = await hfmAPI.excelDataEP(data);
    if (response.status == 200) {
      const formattedData = response.data.entries.map((el) =>
        active
          ? {
              folio: el.sale_order,
              order_type: el.order_type
                ? orderTypeList.find(
                    (orderType) => orderType.id == el.order_type
                  ).value
                : "",
              box: `${el.box_name} ${
                el.box_id
                  ? boxAssigned.find((box) => box.id == el.box_id).value
                  : ""
              }`,
              picker:
                el.last_name || el.name ? `${el.name} ${el.last_name}` : "",
              warehouse: el.warehouse != null ? el.warehouse : "",
              channel: el.channel,
              client: el.client,
              status:
                !el.picked && !el.packed
                  ? "Creación"
                  : el.picked && !el.packed
                  ? "Picking"
                  : !el.picked && el.packed
                  ? "Packing"
                  : "",
              date_created: el.date_created
                ? el.date_created.substring(0, 16)
                : "",
              date_accepted: el.date_accepted
                ? el.date_accepted.substring(0, 16)
                : "",
            }
          : {
              folio: el.sale_order,
              order_type: el.order_type
                ? orderTypeList.find(
                    (orderType) => orderType.id == el.order_type
                  ).value
                : "",
              picker:
                el.last_name || el.name ? `${el.name} ${el.last_name}` : "",
              warehouse: el.warehouse != null ? el.warehouse : "",
              channel: el.channel,
              client: el.client,
              statusLastMile: el.shipping_status,
              status: el.completed ? "Finalizada" : "Cancelada",
              lastMile: el.last_mille ? el.last_mille : "",
              comments: el.cancellation ? el.cancellation : "",
              date_created: el.date_created
                ? el.date_created.substring(0, 16)
                : "",
              date_accepted: el.date_accepted
                ? el.date_accepted.substring(0, 16)
                : "",
              date_finished: el.date_finished
                ? el.date_finished.substring(0, 16)
                : "",
              time_completed: el.time_completed
                ? el.time_completed.substring(0, 8)
                : "",
            }
      );
      setExcelData(formattedData);
      setIsLoadingExcel(false);
    } else {
      console.log(response);
      setIsLoadingExcel(false);
    }
  };

  const socketUrl = getSocketHost();

  useEffect(() => {
    const token = `${localStorage.getItem("token")}`;
    const socket = new WebSocket(`${socketUrl}/wss/hfm/${token}`);

    socket.onopen = () => {
      // console.log("connection")
    };
    socket.onclose = () => {
      // console.log('connection closed')
    };

    socket.onmessage = (response) => {
      const socketData = JSON.parse(response.data);
      let updatedData = [];
      switch (socketData.event) {
        case 4:
          {
            /* ACEPTACIÓN DE LA VENTA, ASIGNACIÓN DE PICKER Y CAMBIO DE STATUS A PICKING*/
          }
          updatedData = data.map((sale) =>
            sale.sale_order == socketData.details.sale_order
              ? {
                  ...sale,
                  name: socketData.details.name,
                  last_name: socketData.details.last_name,
                  date_accepted: socketData.details.date_accepted,
                  status_sale: socketData.details.status_sale,
                }
              : sale
          );
          setData(updatedData);
          break;

        case 5:
          {
            /* ACTUALIZACIÓN DE EMBALAJE Y CAMBIO DE STATUS A PACKING */
          }
          updatedData = data.map((sale) =>
            sale.sale_order == socketData.details.sale_order
              ? {
                  ...sale,
                  status_sale: socketData.details.status_sale,
                  box_id: socketData.details.box_id,
                  box_name: socketData.details.box_name,
                  box_image: socketData.details.box_image,
                }
              : sale
          );
          setData(updatedData);
          break;
      }
    };

    return () => socket.close();
  }, [data]);

  return (
    <SalesContext.Provider
      value={{
        getSalesList,
        getExcelData,
        getHistoricSalesCardView,
        getActiveSalesCardView,
        data,
        setData,
        cardViewData,
        cardViewTotal,
        total,
        count,
        isLoading,
        isLoadingExcel,
        page,
        setPage,
        setTotal,
        excelData,
      }}
    >
      {props.children}
    </SalesContext.Provider>
  );
}
