import React, { useEffect, useRef, useState } from "react";
import { Field, useFormik, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Modal,
  useMediaQuery,
  MenuItem,
  Grid,
  FormControlLabel,
  Checkbox,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";

import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";

import { ModalSuccess } from "../Modal/ModalSuccess";
import { ModalError } from "../Modal/ModalError";

import LoaderDS from "../../assets/LoaderDS.svg";
import { SalesB2BAPI } from "../../api/SalesB2B";
import { useMemo } from "react";

export const BusinessForm = () => {
  /************** STYLE VARIABLES *******************/

  const navigate = useNavigate();
  const phone = useMediaQuery("(max-width:480px)");

  /************** MODAL STATES *******************/

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState("");

  // LOCATION IS USED TO CHANGE BETWEEN EDIT AND NEW BUSSINES ACTION

  const location = useLocation().state;
  const business = location ? location.business : undefined;

  /************** ERROR STATES *******************/
  const [invalidCP, setInvalidCP] = useState(false);

  /************** ACTION STATES *******************/
  const [edit, setEdit] = useState(false);

  // THIS STATE CHANGE WHEN CP DATA IS LOADING
  const [cpIsLoading, setCpIsLoading] = useState(false);

  /************** ARRAYS(OPTIONS) FOR SELECT INPUTS *******************/
  //this arrays are mapped to render select input options
  const [regimes, setRegimes] = useState([]);
  const regimeTypes = [
    { id: "1", value: "Persona Fisica" },
    { id: "2", value: "Persona Moral" },
  ];

  /************** USE EFFECT *******************/
  const getRegimes = async (regimeType) => {
    let data = new FormData();
    data.append("regime_type", parseInt(regimeType, 10));
    try {
      const response = await api.getRegimeEP(data);
      if (response.status == 200) {
        setRegimes(response.data.entries);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (business) {
      getRegimes(business.regime_type.id);
    }
  }, []);

  /************** INPUTS FIELDS *******************/
  //This fielfs arrays are mapped to render inputs
  const businessFields = [
    {
      keyName: "Razón social",
      value: "business_name",
      required: true,
      type: "text",
    },
    {
      keyName: "Nombre comercial",
      value: "business",
      required: true,
      type: "text",
    },
    {
      keyName: "RFC",
      value: "rfc",
      required: true,
      type: "text",
    },
    {
      keyName: "Representante legal",
      value: "legal_representative",
      required: true,
      type: "text",
    },
    {
      keyName: "Tipo de régimen",
      value: "regime_type",
      required: true,
      type: "select",
      array: regimeTypes,
    },
    {
      keyName: "Régimen fiscal",
      value: "tax_regime",
      required: true,
      type: "select",
      array:
        regimes.length == 0
          ? [{ id: 0, value: "Seleccione un tipo de régimen" }]
          : regimes,
    },
    {
      keyName: "Sitio web",
      value: "website",
      required: true,
      type: "text",
    },
  ];

  const officeFields = [
    {
      keyName: "Teléfono",
      value: "office_phone",
      required: false,
      type: "text",
      readOnly: false,
    },
    {
      keyName: "Código Postal",
      value: "postal_code",
      required: true,
      type: "text",
      type: "autocomplete",
      readOnly: false,
    },
    /* {
      keyName: "País",
      value: "country",
      required: false,
      type: "text",
      readOnly: true,
    }, */
    {
      keyName: "Estado",
      value: "state",
      required: false,
      type: "text",
      readOnly: true,
    },
    {
      keyName: "Municipio",
      value: "municipality",
      required: false,
      type: "text",
      readOnly: true,
    },
    {
      keyName: "Colonia",
      value: "neighborhood",
      required: true,
      type: "text",
      readOnly: false,
    },
    {
      keyName: "Calle",
      value: "street",
      required: true,
      type: "text",
      readOnly: false,
    },
    {
      keyName: "Número",
      value: "number",
      required: true,
      type: "text",
      readOnly: false,
    },
  ];

  const contactFields = [
    {
      keyName: "Nombre(s)",
      value: "first_name_contact",
      required: true,
      type: "text",
    },
    {
      keyName: "Apellidos",
      value: "last_name_contact",
      required: true,
      type: "text",
    },
    {
      keyName: "Email",
      value: "email_contact",
      required: true,
      type: "text",
    },
    {
      keyName: "Teléfono",
      value: "cell_phone_contact",
      required: true,
      type: "text",
    },
  ];

  /************** USE EFFECT *******************/
  // IF STATE LOCATION HAS DATA, THEN DE ACTION MUST BE EDIT
  useEffect(() => {
    if (business != undefined) {
      setEdit(true);
      getCPData(business.postal_code);
    }
  }, []);

  /************** HANDLE CLOSE FORM *******************/

  const handleClose = (path) => {
    if (path == "nuevo") {
      setOpenSuccess(false);
      formik.resetForm();
      navigate(`/cliente/ventas/B2B/negocios/nuevo/`, {
        state: undefined,
      });
    } else {
      navigate(`/cliente/ventas/B2B/negocios/`);
    }
  };

  /************** API INSTANCE *******************/
  const api = useMemo(() => new SalesB2BAPI(), []);

  /************** SUBMIT FUNCTIONS*******************/

  const addBusiness = async (data) => {
    setIsLoading(true);
    let response = {};
    if (edit) {
      response = await api.updateBusinessEP(data);
    } else {
      response = await api.createBusinessEP(data);
    }
    if (response.status == 200) {
      setOpenSuccess(true);
    } else {
      setErrorText(response.response.data.detail);
      setOpenError(true);
      // console.log(response);
    }
    setIsLoading(false);
  };

  /************** REQUEST DATA *******************/

  const getCPData = async (cp) => {
    setCpIsLoading(true);
    let data = new FormData();
    data.append("postal_code", cp);
    try {
      const response = await api.getCPDataEP(data);
      if (response.status == 200) {
        setErrorText("");
        setInvalidCP(false);
        //formik.setFieldValue("country", response.data[0].country);
        formik.setFieldValue("state", response.data[0].state);
        formik.setFieldValue("municipality", response.data[0].city);
      } else {
        //formik.setFieldValue("country", "");
        formik.setFieldValue("state", "");
        formik.setFieldValue("municipality", "");
        setErrorText(response.response.data.detail);
        setInvalidCP(true);
      }
      setCpIsLoading(false);
    } catch (error) {
      setCpIsLoading(false);
      // console.log(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      business != undefined
        ? {
            ...business,
            regime_type: business.regime_type == "Persona Moral" ? 2 : 1,
            tax_regime: business.tax_regime.id,
            /*
          last_mile_name:
            business.last_mile == false
              ? business.other_lm
                ? "other"
                : business.last_mile_name.id
              : "" */
          }
        : {
            //business
            business_name: "",
            business: "",
            rfc: "",
            legal_representative: "",
            regime_type: "",
            tax_regime: "",
            website: "",

            //office
            office_phone: "",
            postal_code: "",
            //country: "",
            state: "",
            city: "",
            municipality: "",
            neighborhood: "",
            street: "",
            number: "",

            //contact
            first_name_contact: "",
            last_name_contact: "",
            email_contact: "",
            cell_phone_contact: "",

            //services
            //last_mile: true,
          },
    validationSchema: Yup.object({
      //business
      business_name: Yup.string()
        .required("Campo Obligatorio")
        .max(100, "Demasiado largo")
        .matches(/^(?!\s)\w/, "Debe ser alfanumérico, sin espacios al inicio"),
      business: Yup.string()
        .required("Campo Obligatorio")
        .max(100, "Demasiado largo")
        .matches(/^(?!\s)\w/, "Debe ser alfanumérico, sin espacios al inicio"),
      rfc: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\w+$/, "Debe ser alfanumérico, sin espacios")
        .matches(
          /^([A-Za-zÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
          "Ingresa un formato válido"
        )
        .min(10, "Demasiado corto ")
        .max(15, "Demasiado largo"),
      regime_type: Yup.string().required("Campo Obligatorio"),
      tax_regime: Yup.string().required("Campo Obligatorio"),
      legal_representative: Yup.string()
        .required("Campo Obligatorio")
        .max(100, "Demasiado largo")
        .matches(/^[a-zA-ZÑÀ-ÿ\s]*$/, "Solo letras y acentos"),
      website: Yup.string()
        .required("Campo obligatorio")
        .matches(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,})/,
          "Ingresa un formato válido, ej: https://www.sitioweb.com"
        )
        .max(60, "Demasiado largo"),

      //office
      office_phone: Yup.string()
        .required("Campo obligatorio")

        .matches(/^\d+$/, "Deben ser números sin espacios")
        .min(10, "Demasiado corto")
        .max(15, "Demasiado largo"),
      postal_code: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\d+$/, "Deben ser números sin espacios")
        .min(3, "Demasiado corto")
        .max(10, "Demasiado largo"),

      neighborhood: Yup.string()
        .required("Campo Obligatorio")
        .max(100, "Demasiado largo"),
      street: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s-\.]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Demasiado largo"),
      number: Yup.string()
        .required("Campo Obligatorio")
        .max(15, "Solo diez carácteres")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        ),
      //contact
      first_name_contact: Yup.string()
        .required("Campo obligatorio")
        .matches(/^[a-zA-ZÑÀ-ÿ\s]*$/, "Solo letras y acentos")
        .max(100, "Demasiado largo"),
      last_name_contact: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^[a-zA-ZÑÀ-ÿ\s]*$/, "Solo letras y acentos")
        .max(100, "Demasiado largo"),
      email_contact: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Formato incorrecto"
        )
        .max(100, "Demasiado largo"),
      cell_phone_contact: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\d+$/, "Deben ser números sin espacios")
        .min(10, "Demasiado corto")
        .max(12, "Demasiado largo"),
    }),
    onSubmit: (values) => {
      if (!invalidCP) {
        let obj;
        if (edit) {
          obj = {
            id: business.id,
            ...values,
          };
        } else {
          obj = {
            ...values,
          };
        }
        addBusiness(obj);
      }
    },
  });

  useEffect(() => {
    if (formik.values.regime_type != "") {
      getRegimes(formik.values.regime_type);
    }
  }, [formik.values.regime_type]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          rowSpacing={2}
        >
          {" "}
          {/*---Contenedor padre---*/}
          <Grid item xs={12} sm={12} md={6}>
            {" "}
            {/*---Ocupara mitad de pantalla---*/}
            {/*------------BUSINESS INPUTS---------------*/}
            <Grid
              item
              xs={12}
              className={`border bg-white r-15 ${phone ? "p-2" : "p-3"}`}
            >
              {" "}
              {/*---Altura de 100 para que ocupe verticalmente---*/}
              <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
                Datos Fiscales
              </p>
              <div className="d-flex flex-wrap">
                {businessFields.map((field, index) => (
                  <div
                    key={index}
                    className={` d-flex flex-column w-100 px-2 ${
                      phone ? "mb-1" : "mb-3" /*
                        : // if the last element of the array is odd width must be 100%
                        businessFields.length - 1 != index
                        ? "w-50 mb-3"
                        : index % 2 == 0
                        ? "w-100 mb-3"
                        : "w-50 mb-3"
                */
                    }`}
                  >
                    <p
                      className={`mr-2 mb-1 ${phone ? "xs-font" : ""}`}
                      htmlFor={field.value}
                    >{`${field.keyName}: ${field.required ? "*" : ""}`}</p>
                    {field.type == "select" ? (
                      <TextField
                        select
                        size="small"
                        error={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                        }
                        label={field.keyName}
                        value={formik.values[field.value]}
                        name={field.value}
                        id={field.value}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        fullWidth
                        helperText={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                            ? formik.errors[field.value]
                            : null
                        }
                        InputProps={phone && { style: { fontSize: 13 } }}
                        InputLabelProps={phone && { style: { fontSize: 13 } }}
                      >
                        {field.array.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        error={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                        }
                        size="small"
                        label={field.keyName}
                        variant="outlined"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values[field.value]}
                        name={field.value}
                        id={field.value}
                        helperText={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                            ? formik.errors[field.value]
                            : null
                        }
                        InputProps={phone && { style: { fontSize: 13 } }}
                        InputLabelProps={phone && { style: { fontSize: 13 } }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {/*------------CONTACT INPUTS---------------*/}
            <Grid
              item
              xs={12}
              className={`border bg-white r-15 mb-3 ${phone ? "p-2" : "p-3"}`}
            >
              {" "}
              {/*---Altura de 100 para que ocupe verticalmente---*/}
              <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
                Datos de contacto
              </p>
              <div className="d-flex flex-wrap">
                {contactFields.map((field, index) => (
                  <div
                    key={index}
                    className={` d-flex flex-column ${
                      phone ? "w-100 mb-1" : "w-50 mb-3"
                    }  px-2`}
                  >
                    <p
                      className={`mr-2 mb-1 ${phone ? "xs-font" : ""}`}
                      htmlFor={field.value}
                    >{`${field.keyName}: ${field.required ? "*" : ""}`}</p>
                    {field.type == "select" ? (
                      <TextField
                        select
                        size="small"
                        error={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                        }
                        label={field.keyName}
                        value={formik.values[field.value]}
                        name={field.value}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        fullWidth
                        helperText={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                            ? formik.errors[field.value]
                            : null
                        }
                        InputProps={phone && { style: { fontSize: 13 } }}
                        InputLabelProps={phone && { style: { fontSize: 13 } }}
                      >
                        {field.array.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        error={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                        }
                        size="small"
                        label={field.keyName}
                        variant="outlined"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values[field.value]}
                        name={field.value}
                        helperText={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                            ? formik.errors[field.value]
                            : null
                        }
                        InputProps={phone && { style: { fontSize: 13 } }}
                        InputLabelProps={phone && { style: { fontSize: 13 } }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </Grid>
            {/*------------OFFICE INPUTS---------------*/}
            <Grid
              item
              xs={12}
              className={`border bg-white r-15 ${phone ? "p-2" : "p-3"}`}
            >
              {" "}
              {/*---Altura de 100 para que ocupe verticalmente---*/}
              <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
                Datos de oficina
              </p>
              <div className="d-flex flex-wrap">
                {officeFields.map((field, index) => (
                  <div
                    key={index}
                    className={` d-flex flex-column ${
                      phone ? "w-100 mb-1" : "w-50 mb-3"
                    }  px-2`}
                  >
                    <div className="d-flex">
                      <p
                        className={`mr-2 mb-1 ${phone ? "xs-font" : ""}`}
                        htmlFor={field.value}
                      >{`${field.keyName}: ${field.required ? "*" : ""}`}</p>
                      {cpIsLoading && //field.value == "country" ||
                      (field.value == "state" ||
                        field.value == "municipality") ? (
                        <CircularProgress
                          size={15}
                          className="ml-1 ds-yellow-font"
                        />
                      ) : null}
                    </div>
                    {field.type == "select" ? (
                      <TextField
                        select
                        size="small"
                        error={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                        }
                        label={field.keyName}
                        value={formik.values[field.value]}
                        name={field.value}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        fullWidth
                        helperText={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                            ? formik.errors[field.value]
                            : null
                        }
                        InputProps={phone && { style: { fontSize: 13 } }}
                        InputLabelProps={phone && { style: { fontSize: 13 } }}
                      >
                        {field.array.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={field.array.value}
                          >
                            {field.array.keyName}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : field.value == "postal_code" ? (
                      <TextField
                        error={
                          invalidCP ||
                          (formik.touched[field.value] &&
                            formik.errors[field.value])
                        }
                        size="small"
                        label={field.keyName}
                        variant="outlined"
                        fullWidth
                        onChange={(e) => formik.handleChange(e)}
                        value={formik.values[field.value]}
                        name={field.value}
                        id={field.value}
                        helperText={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                            ? formik.errors[field.value]
                            : invalidCP
                            ? errorText
                            : null
                        }
                        InputProps={phone && { style: { fontSize: 13 } }}
                        InputLabelProps={phone && { style: { fontSize: 13 } }}
                        onBlur={(e) => getCPData(e.target.value)}
                      />
                    ) : (
                      <TextField
                        error={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                        }
                        size="small"
                        label={field.keyName}
                        IOSSwitch
                        variant="outlined"
                        fullWidth
                        onChange={formik.handleChange}
                        value={
                          cpIsLoading && //field.value == "country" ||
                          (field.value == "state" ||
                            field.value == "municipality")
                            ? "Cargando... "
                            : formik.values[field.value]
                        }
                        name={field.value}
                        helperText={
                          formik.touched[field.value] &&
                          formik.errors[field.value]
                            ? formik.errors[field.value]
                            : null
                        }
                        InputProps={{
                          readOnly: field.readOnly,
                          style: { fontSize: phone ? 15 : "inherit" },
                        }}
                        InputLabelProps={phone && { style: { fontSize: 13 } }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </Grid>
            {/*------------SERVICES INPUTS---------------*/}
            {/* <Grid
              item
              xs={12}
              className={`mt-3 border bg-white r-15 ${
                phone ? "p-2" : "p-3 h-25"
              }`}
            >
              <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>Servicios</p>
              <div className={`px-2`}>
                <FormControlLabel
                  onChange={(e) => formik.handleChange(e)}
                  name={"last_mile"}
                  control={
                    <Checkbox
                      size={phone ? "small" : "medium"}
                      checked={formik.values.last_mile}
                      sx={{
                        color: theme.palette.primary.main,
                        "&.Mui-checked": { color: theme.palette.primary.main },
                      }}
                    />
                  }
                  label={
                    <p className={`m-0 ${phone ? "xs-font" : ""}`}>
                      Quiero el servicio de última milla de DayStore
                    </p>
                  }
                />
              </div>
            </Grid> */}
            {/*------------SUBMIT BUTTONS---------------*/}
            <div
              className={`${
                !phone ? "d-flex justify-content-end margin-right mt-3" : "mt-2"
              }`}
            >
              <div className={`${phone && "mb-2"}`}>
                <CancelButton
                  text={"Cancelar"}
                  onClick={() => {
                    navigate("/cliente/ventas/B2B/negocios/");
                  }}
                  width={phone ? "100%" : ""}
                />
              </div>
              <AcceptButton
                text={business != undefined ? "Editar" : "Agregar"}
                type={"submit"}
                width={phone ? "100%" : ""}
                onClick={() => {
                  const err = Object.keys(formik.errors);
                  if (err.length) {
                    const input = document.querySelector(
                      `input[name=${err[0]}]`
                    );
                    input.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "start",
                    });
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>
      </form>

      <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalSuccess
            handleClose={handleClose}
            text={`El negocio fue ${
              business == undefined ? "agregado" : "editado"
            } con éxito`}
            action={"add"}
            auxText={`¿Deseas agregar otro negocio?`}
          />
        </div>
      </Modal>

      <Modal open={openError} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalError
            text={`No se pudo ${
              business == undefined ? "agregar" : "editar"
            } el negocio.`}
            handleClose={() => setOpenError(false)}
            error={errorText}
          />
        </div>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
