import React from "react";

import { Button, TextField, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CancelButton = ({ text, type, onClick, width, fontSize }) => {
  const phone = useMediaQuery("(max-width:480px)");

  const CancelButtonStyled = styled(Button)({
    width: width,
    boxShadow: "1",
    textTransform: "none",
    fontSize: fontSize ? fontSize : phone ? 13 : 16,
    padding: "6px 12px",
    border: "1.5px solid",
    lineHeight: 1.5,
    borderRadius: 5,
    backgroundColor: "#999797",
    borderColor: "#999797",
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "white",
      //   borderColor: '#0062cc',
      boxShadow: "3",
      color: "#999797",
    },
    "&:active": {
      //   boxShadow: 'none',
      //   backgroundColor: '#0062cc',
      //   borderColor: '#005cbf',
    },
    "&:focus": {
      //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  });
  return (
    <CancelButtonStyled
      variant="contained"
      disableRipple
      className="mr-3"
      onClick={onClick}
      type={type}
    >
      {text}
    </CancelButtonStyled>
  );
};
