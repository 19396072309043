import { createContext, useState, useEffect, useMemo } from "react";
import { getCSRF } from "../helpers/getCSRF";
import { SalesB2BAPI } from "../api/SalesB2B";
import { WarehouseAPI } from "../api/Warehouse";
import { ProductsAPI } from "../api/Products";
import { HeadFieldManagerAPI } from "../api/HeadFieldManager";
import { AdminAPI } from "../api/Admin";

export const FilterContext = createContext();

export function FilterContexProvider(props) {
  //FILTERS STATES
  //------------------------------------------------

  const [invisible, setInvisible] = useState(true);

  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(today);
  const [nullableStartDate, setNullableStartDate] = useState(null);
  const [nullableEndDate, setNullableEndDate] = useState(null);

  const [branch, setBranch] = useState("Todas");
  const [branches, setBranches] = useState([]);

  const [picker, setPicker] = useState("Todos");
  const [pickers, setPickers] = useState([]);

  const [orderType, setOrderType] = useState("Todos");

  const [client, setClient] = useState("Todos");
  const [clients, setClients] = useState([]);

  const [channel, setChannel] = useState("Todos");
  const [channels, setChannels] = useState([]);

  const [pickerAvailability, setpickerAvailability] = useState("Todos");

  const [saleHFMHistoricStatus, setSaleHFMHistoricStatus] = useState("Todos");
  const [saleHFMActiveStatus, setSaleHFMActiveStatus] = useState("Todos");

  const [lastMileStatus, setLastMileStatus] = useState(0);

  const [lastMile, setLastMile] = useState(0);
  const [lastMiles, setLastMiles] = useState([]);
  const [regimeType, setRegimeType] = useState(0);
  const [businessStatus, setBusinessStatus] = useState(0);
  const [states, setStates] = useState([]);
  const [state, setState] = useState(0);
  const [businessLocations, setBusinessLocations] = useState([]);
  const [businessLocation, setBusinessLocation] = useState(0);
  const [businessArr, setBusinessArr] = useState([]);
  const [business, setBusiness] = useState(0);
  const [saleStatus, setSaleStatus] = useState(0);
  const [clientBranches, setClientBranches] = useState([]);
  const [clientBranch, setClientBranch] = useState(0);

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("Todas");

  const [search, setSearch] = useState("");

  const [adminClient, setAdminClient] = useState(0);
  const [adminClients, setAdminClients] = useState([]);

  const [prevData, setPrevData] = useState({
    startDate: firstDay,
    endDate: today,
    nullableStartDate: null,
    nullableEndDate: null,
    branch: "Todas",
    picker: "Todos",
    orderType: "Todos",
    channel: "Todos",
    client: "Todos",
    pickerAvailability: "Todos",
    saleHFMActiveStatus: "Todos",
    saleHFMHistoricStatus: "Todos",
    lastMileStatus: "Todos",
    category: "Todas",
    lastMile: 0,
    regimeType: 0,
    businessStatus: 0,
    state: 0,
    businessLocation: 0,
    business: 0,
    saleStatus: 0,
    clientBranch: 0,
    adminClient: 0,
  });

  const orderTypes = [
    { id: 0, orderType: "Todos" },
    { id: 1, orderType: "Fast Delivery" },
    { id: 2, orderType: "On Demand" },
    { id: 3, orderType: "Same Day - Next Day" },
    { id: 4, orderType: "Paquetería" },
  ];

  const pickersAvailabilities = [
    { id: 0, pickerAvailability: "Todos" },
    { id: 1, pickerAvailability: "Conectado" },
    { id: 2, pickerAvailability: "Desconectado" },
  ];

  const salesHFMHistoricStatus = [
    { id: 0, salesHFMHistoricStatus: "Todos" },
    { id: 1, salesHFMHistoricStatus: "Finalizada" },
    { id: 2, salesHFMHistoricStatus: "Cancelada" },
  ];

  const salesHFMActiveStatus = [
    { id: 0, salesHFMActiveStatus: "Todos" },
    { id: 1, salesHFMActiveStatus: "Creación" },
    { id: 2, salesHFMActiveStatus: "Picking" },
    { id: 3, salesHFMActiveStatus: "Packing" },
  ];

  const lastMilesStatus = [
    { id: 0, lastMileStatus: "Todos" },
    { id: 1, lastMileStatus: "Listo para envío" },
    { id: 2, lastMileStatus: "En camino" },
    { id: 3, lastMileStatus: "Finalizado" },
    { id: 4, lastMileStatus: "Otro" },
  ];

  const regimeTypes = [
    { id: "0", value: "Todos" },
    { id: "1", value: "Persona Fisica" },
    { id: "2", value: "Persona Moral" },
  ];

  const arrBusinessStatus = [
    { id: 0, value: "Todos" },
    { id: 1, value: "Activo" },
    { id: 2, value: "Inactivo" },
  ];

  const saleStatusArr = [
    { id: 0, value: "Todos" },
    { id: 1, value: "Generado" },
    { id: 3, value: "Cancelado" },
    /* { id: 1, value: "Listo" },
    { id: 2, value: "Asignado" },
    { id: 3, value: "En ruta" },
    { id: 4, value: "Finalizado" },
    { id: 5, value: "Otro" }, 
    { id: 6, value: "Cancelado" },*/
  ];

  //ERRORES
  //------------------------------------------------

  const [errors, setErrors] = useState({
    error: false,
    errorNullStart: false,
    errorNullEnd: false,
  });

  /****************FILTERS REQUEST****************/

  // APIS
  const salesB2BAPI = useMemo(() => new SalesB2BAPI(), []);
  const productAPI = useMemo(() => new ProductsAPI(), []);
  const hfmAPI = useMemo(() => new HeadFieldManagerAPI(), []);
  const warehouseAPI = useMemo(() => new WarehouseAPI(), []);
  const adminAPI = useMemo(() => new AdminAPI(), []);

  const getBranchesByHFM = async () => {
    let data = new FormData();

    const response = await hfmAPI.branchesByHfmEP(data);
    if (response.status == 200) {
      setBranches([{ id: 0, sucursal: "Todas" }, ...response.data.entries]);
    } else {
      console.log(response);
    }
  };

  const getPickersByHFM = async () => {
    let data = new FormData();

    const response = await hfmAPI.pickersByHfmEP(data);
    if (response.status == 200) {
      const pickersNames = response.data.entries.map((el) => {
        return { id: el.id, picker: `${el.nombre} ${el.apellidos}` };
      });
      setPickers([{ id: 0, picker: "Todos" }, ...pickersNames]);
    } else {
      console.log(response);
    }
  };

  const getChannelsByHFM = async () => {
    let data = new FormData();

    const response = await hfmAPI.channelsByHfmEP(data);
    if (response.status == 200) {
      setChannels([{ id: 0, canal: "Todos" }, ...response.data.entries]);
    } else {
      console.log(response);
    }
  };

  const getClientsByHFM = async () => {
    let data = new FormData();

    const response = await hfmAPI.clientsByHfmEP(data);
    if (response.status == 200) {
      setClients([{ id: 0, client: "Todos" }, ...response.data.entries]);
    } else {
      console.log(response);
    }
  };

  const getCategories = async () => {
    let data = new FormData();

    const response = await productAPI.categoriesListEP(data);
    if (response.status == 200) {
      setCategories([{ id: 0, categoria: "Todas" }, ...response.data]);
    } else {
      console.log(response);
    }
  };

  const getBusiness = async () => {
    const data = new FormData();

    const response = await salesB2BAPI.getCleanBusiness(data);
    if (response.status == 200) {
      setBusinessArr([{ id: 0, value: "Todos" }, ...response.data]);
    } else {
      console.log(response);
    }
  };

  const getStatesByBusiness = async (id) => {
    let data = new FormData();
    data.append("business", id);
    const response = await salesB2BAPI.getStatesByBusiness(data);
    if (response.status == 200) {
      setStates([{ id: 0, value: "Todos" }, ...response.data]);
    } else {
      console.log(response);
    }
  };

  const getLocationsByBusiness = async () => {
    let data = new FormData();
    if (business != 0) {
      data.append("business", business);
    }
    const response = await salesB2BAPI.getCleanLocationsEP(data);
    if (response.status == 200) {
      setBusinessLocations([{ id: 0, value: "Todas" }, ...response.data]);
    } else {
      console.log(response);
    }
  };

  const getBranchesByClient = async () => {
    let data = new FormData();
    const response = await warehouseAPI.getBranchesList(data);
    if (response.status == 200) {
      const auxBranches = response.data.branches.map((b) => {
        return { id: b.id, value: b.sucursal };
      });
      setClientBranches([{ id: 0, value: "Todas" }, ...auxBranches]);
    } else {
      console.log(response);
    }
  };

  const getClientsByAdmin = async () => {
    const data = new FormData();
    const response = await adminAPI.clientsListCleanedEP(data);
    if (response.status == 200) {
      const auxClients = response.data.clients.map((c) => {
        return { id: c.id, value: c.razon_social };
      });
      setAdminClients([{ id: 0, value: "Todos" }, ...auxClients]);
    } else {
      console.log(response);
    }
  };

  //------------------------------------------------
  //HANDLERS
  //------------------------------------------------

  const handleChangeStart = (newValue) => {
    setStartDate(newValue);
  };

  const handleChangeEnd = (newValue) => {
    setEndDate(newValue);
  };

  const handleChangeNullStart = (newValue) => {
    setNullableStartDate(newValue);
    setErrors({ errorNullStart: false });
  };

  const handleChangeNullEnd = (newValue) => {
    setNullableEndDate(newValue);
    setErrors({ errorNullEnd: false });
  };

  const handleChangeBranch = (e) => {
    setBranch(e.target.value);
  };

  const handleChangePicker = (e) => {
    setPicker(e.target.value);
  };

  const handleChangeOrderType = (e) => {
    setOrderType(e.target.value);
  };

  const handleChangeChannel = (e) => {
    setChannel(e.target.value);
  };

  const handleChangeClient = (e) => {
    setClient(e.target.value);
  };

  const handleChangepickerAvailability = (e) => {
    setpickerAvailability(e.target.value);
  };

  const handleChangeSaleHFMHistoricStatus = (e) => {
    setSaleHFMHistoricStatus(e.target.value);
  };

  const handleChangeSaleHFMActiveStatus = (e) => {
    setSaleHFMActiveStatus(e.target.value);
  };

  const handleChangeLastMileStatus = (e) => {
    setLastMileStatus(e.target.value);
  };

  const handleChangeCategory = (e) => {
    setCategory(e.target.value);
  };
  const handleChangeLastMile = (e) => {
    setLastMile(e.target.value);
  };

  const handleChangeRegimeType = (e) => {
    setRegimeType(e.target.value);
  };

  const handleChangeBusinessStatus = (e) => {
    setBusinessStatus(e.target.value);
  };

  const handleChangeState = (e) => {
    setState(e.target.value);
  };

  const handleChangeLocation = (e) => {
    setBusinessLocation(e.target.value);
  };

  const handleChangeBusiness = (e) => {
    setBusiness(e.target.value);
  };

  const handleChangeSaleStatus = (e) => {
    setSaleStatus(e.target.value);
  };

  const handleChangeClientBranch = (e) => {
    setClientBranch(e.target.value);
  };

  const handleChangeAdminClient = (e) => {
    setAdminClient(e.target.value);
  };

  const canFilter = () => {
    if (nullableStartDate != null || nullableEndDate != null) {
      if (nullableStartDate != null && nullableEndDate == null) {
        setErrors({ errorNullEnd: true });
        return false;
      } else if (nullableEndDate != null && nullableStartDate == null) {
        setErrors({ errorNullStart: true });
        return false;
      } else if (nullableEndDate < nullableStartDate) {
        setErrors({ error: true });
      } else {
        setErrors({
          error: false,
          errorNullStart: false,
          errorNullEnd: false,
        });
        setPrevData({
          startDate: startDate,
          endDate: endDate,
          nullableStartDate: nullableStartDate,
          nullableEndDate: nullableEndDate,
          branch: branch,
          picker: picker,
          orderType: orderType,
          channel: channel,
          client: client,
          pickerAvailability: pickerAvailability,
          saleHFMHistoricStatus: saleHFMHistoricStatus,
          saleHFMActiveStatus: saleHFMActiveStatus,
          lastMileStatus: lastMileStatus,
          category: category,
          lastMile: lastMile,
          regimeType: regimeType,
          businessStatus: businessStatus,
          state: state,
          businessLocation: businessLocation,
          business: business,
          saleStatus: saleStatus,
          clientBranch: clientBranch,
          adminClient: adminClient,
        });
        if (
          /* startDate != firstDay ||
          endDate != today || */
          nullableEndDate != null ||
          nullableStartDate != null ||
          branch != "Todas" ||
          picker != "Todos" ||
          orderType != "Todos" ||
          channel != "Todos" ||
          client != "Todos" ||
          pickerAvailability != "Todos" ||
          saleHFMHistoricStatus != "Todos" ||
          saleHFMActiveStatus != "Todos" ||
          lastMileStatus != "Todos" ||
          category != "Todas" ||
          regimeType != 0 ||
          businessStatus != 0
        ) {
          setInvisible(false);
        }
        return true;
      }
    } else if (endDate < startDate) {
      setErrors({ error: true });
      return false;
    } else {
      setErrors({
        error: false,
        errorNullStart: false,
        errorNullEnd: false,
      });
      setPrevData({
        startDate: startDate,
        endDate: endDate,
        nullableStartDate: nullableStartDate,
        nullableEndDate: nullableEndDate,
        branch: branch,
        picker: picker,
        orderType: orderType,
        channel: channel,
        client: client,
        pickerAvailability: pickerAvailability,
        saleHFMHistoricStatus: saleHFMHistoricStatus,
        saleHFMActiveStatus: saleHFMActiveStatus,
        lastMileStatus: lastMileStatus,
        category: category,
        regimeType: regimeType,
        businessStatus: businessStatus,
        state: state,
        businessLocation: businessLocation,
        business: business,
        saleStatus: saleStatus,
        clientBranch: clientBranch,
        adminClient: adminClient,
      });
      if (
        /* startDate != firstDay ||
        endDate != today || */
        nullableEndDate != null ||
        nullableStartDate != null ||
        branch != "Todas" ||
        picker != "Todos" ||
        orderType != "Todos" ||
        channel != "Todos" ||
        client != "Todos" ||
        pickerAvailability != "Todos" ||
        saleHFMHistoricStatus != "Todos" ||
        saleHFMActiveStatus != "Todos" ||
        lastMileStatus != "Todos" ||
        category != "Todas" ||
        regimeType != 0 ||
        businessStatus != 0
      ) {
        setInvisible(false);
      }
      return true;
    }
  };

  const resetFilter = () => {
    settingFilters(
      firstDay,
      today,
      null,
      null,
      "Todas",
      "Todos",
      "Todos",
      "Todos",
      "Todos",
      "Todos",
      "Todos",
      "Todos",
      "Todos",
      "Todas",
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0
    );
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
    setInvisible(true);
  };

  const closeFilter = () => {
    settingFilters(
      prevData.startDate,
      prevData.endDate,
      prevData.nullableStartDate,
      prevData.nullableEndDate,
      prevData.branch,
      prevData.picker,
      prevData.orderType,
      prevData.channel,
      prevData.client,
      prevData.pickerAvailability,
      prevData.saleHFMHistoricStatus,
      prevData.saleHFMActiveStatus,
      prevData.lastMileStatus,
      prevData.category,
      prevData.lastMile,
      prevData.regimeType,
      prevData.businessStatus,
      prevData.state,
      prevData.businessLocation,
      prevData.business,
      prevData.saleStatus,
      prevData.clientBranch,
      prevData.adminClient
    );
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  //------------------------------------------------

  //FILTERS SETTING
  //------------------------------------------------

  const settingFilters = (
    startDate,
    endDate,
    nullableStartDate,
    nullableEndDate,
    branch,
    picker,
    orderType,
    channel,
    client,
    pickerAvailability,
    saleHFMHistoricStatus,
    saleHFMActiveStatus,
    lastMilesStatus,
    category,
    lastMile,
    regimeType,
    businessStatus,
    state,
    businessLocation,
    business,
    saleStatus,
    clientBranch,
    adminClient
  ) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setNullableStartDate(nullableStartDate);
    setNullableEndDate(nullableEndDate);
    setBranch(branch);
    setPicker(picker);
    setOrderType(orderType);
    setChannel(channel);
    setClient(client);
    setpickerAvailability(pickerAvailability);
    setSaleHFMHistoricStatus(saleHFMHistoricStatus);
    setSaleHFMActiveStatus(saleHFMActiveStatus);
    setLastMileStatus(lastMilesStatus);
    setCategory(category);
    setLastMile(lastMile);
    setRegimeType(regimeType);
    setBusinessStatus(businessStatus);
    setState(state);
    setBusinessLocation(businessLocation);
    setBusiness(business);
    setSaleStatus(saleStatus);
    setClientBranch(clientBranch);
    setAdminClient(adminClient);
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
    setInvisible(true);
  };

  const fieldList = [
    {
      key: "nullableStartDate",
      label: "Fecha inicio",
      value: nullableStartDate,
      handleOnChange: handleChangeNullStart,
      error: errors.errorNullStart ? "Elige una fecha inicio" : "",
    },
    {
      key: "nullableEndDate",
      label: "Fecha fin",
      value: nullableEndDate,
      handleOnChange: handleChangeNullEnd,
      error: errors.errorNullEnd
        ? "Elige una fecha fin"
        : errors.error
        ? "No puede ser menor a la fecha de inicio"
        : "",
    },
    {
      key: "startDate",
      label: "Fecha inicio",
      value: startDate,
      handleOnChange: handleChangeStart,
    },
    {
      key: "endDate",
      label: "Fecha fin",
      value: endDate,
      handleOnChange: handleChangeEnd,
      error: errors.error ? "No puede ser menor a la fecha de inicio" : "",
    },
    {
      array: branches,
      prop: "sucursal",
      type: "Sucursal",
      value: branch,
      handleOnChange: handleChangeBranch,
    },
    {
      array: pickers,
      prop: "picker",
      type: "Picker",
      value: picker,
      handleOnChange: handleChangePicker,
    },
    {
      array: orderTypes,
      prop: "orderType",
      type: "Envío",
      value: orderType,
      handleOnChange: handleChangeOrderType,
    },
    {
      array: channels,
      prop: "canal",
      type: "Canal",
      value: channel,
      handleOnChange: handleChangeChannel,
    },
    {
      array: clients,
      prop: "client",
      type: "Cliente",
      value: client,
      handleOnChange: handleChangeClient,
    },
    {
      array: pickersAvailabilities,
      prop: "pickerAvailability",
      type: "Disponibilidad",
      value: pickerAvailability,
      handleOnChange: handleChangepickerAvailability,
    },
    {
      array: salesHFMHistoricStatus,
      prop: "salesHFMHistoricStatus",
      type: "Estatus venta",
      value: saleHFMHistoricStatus,
      handleOnChange: handleChangeSaleHFMHistoricStatus,
    },
    {
      array: salesHFMActiveStatus,
      prop: "salesHFMActiveStatus",
      type: "Estatus",
      value: saleHFMActiveStatus,
      handleOnChange: handleChangeSaleHFMActiveStatus,
    },
    {
      array: lastMilesStatus,
      prop: "lastMileStatus",
      type: "Última milla",
      value: lastMileStatus,
      handleOnChange: handleChangeLastMileStatus,
    },
    {
      array: categories,
      prop: "categoria",
      type: "Categoría",
      value: category,
      handleOnChange: handleChangeCategory,
    },
    {
      array: lastMiles,
      key: "lastMile",
      label: "Última milla",
      value: lastMile,
      handleOnChange: handleChangeLastMile,
    },
    {
      array: regimeTypes,
      key: "regimeType",
      label: "Tipo de régimen",
      value: regimeType,
      handleOnChange: handleChangeRegimeType,
    },
    {
      array: arrBusinessStatus,
      key: "businessStatus",
      label: "Estatus",
      value: businessStatus,
      handleOnChange: handleChangeBusinessStatus,
    },
    {
      array: states,
      key: "state",
      label: "Estado",
      value: state,
      handleOnChange: handleChangeState,
    },
    {
      array: saleStatusArr,
      key: "saleStatus",
      value: saleStatus,
      label: "Estatus",
      handleOnChange: handleChangeSaleStatus,
    },
    {
      array: businessArr,
      key: "business",
      value: business,
      label: "Negocio",
      handleOnChange: handleChangeBusiness,
    },
    {
      array: businessLocations,
      key: "businessLocation",
      label: "Ubicación",
      value: businessLocation,
      handleOnChange: handleChangeLocation,
    },
    {
      array: clientBranches,
      key: "clientBranch",
      value: clientBranch,
      label: "Sucursal",
      handleOnChange: handleChangeClientBranch,
    },
    {
      array: adminClients,
      key: "adminClient",
      value: adminClient,
      label: "Cliente",
      handleOnChange: handleChangeAdminClient,
    },
  ];

  return (
    <FilterContext.Provider
      value={{
        invisible,
        startDate,
        endDate,
        nullableEndDate,
        nullableStartDate,
        getBranchesByHFM,
        getPickersByHFM,
        getChannelsByHFM,
        getClientsByHFM,
        getCategories,
        getStatesByBusiness,
        getLocationsByBusiness,
        getBusiness,
        getBranchesByClient,
        getClientsByAdmin,
        fieldList,
        search,
        setSearch,
        resetFilter,
        handleChangeStart,
        handleChangeEnd,
        handleChangeNullStart,
        handleChangeNullEnd,
        canFilter,
        errors,
        setErrors,
        closeFilter,
        branches,
        branch,
        pickers,
        picker,
        orderTypes,
        orderType,
        channels,
        channel,
        clients,
        client,
        category,
        categories,
        pickerAvailability,
        saleHFMHistoricStatus,
        setSaleHFMHistoricStatus,
        saleHFMActiveStatus,
        setSaleHFMActiveStatus,
        salesHFMActiveStatus,
        lastMileStatus,
        setLastMileStatus,
        lastMilesStatus,
        lastMiles,
        lastMile,
        regimeType,
        regimeTypes,
        businessStatus,
        setBranch,
        invisible,
        state,
        states,
        businessLocation,
        business,
        saleStatus,
        clientBranch,
        adminClient,
      }}
    >
      {props.children}
    </FilterContext.Provider>
  );
}
