import React from 'react'

import { useMediaQuery } from '@mui/material'
import { Close } from '@mui/icons-material'

import { ProductForm } from '../../components/Forms/ProductForm'

import { useNavigate } from 'react-router-dom'

export const EditProduct = () => {

  const navigate = useNavigate();

  const phone = useMediaQuery('(max-width:480px)');


  return (
    <>
      <div className='w-100 px-3'>

      <div className="bg-white w-100 mt-3 mb-3 pt-1 pb-1 px-3 r-15">
      <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}> Editar producto </p>
        </div>

        <div className='mt-2  w-100 d-flex justify-content-between r-15 d-flex flex-column' >
    
          <ProductForm />
        </div>
      </div>
    </>
  )
}

