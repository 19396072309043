import { Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { NotificationsAPI } from "../../api/Notifications";
import { ListViewHistorical } from "../../components/ListViewHistorical";
import { SkeletonCardProduct } from "../../components/Skeleton/SkeletonCardProduct";

export const Historical = ({ socketReload, isUpdate, NotificationsFn }) => {
  const phone = useMediaQuery("(max-width:480px)");
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(0); /*Position page*/
  const [totalPaginated, setTotalPaginated] =
    useState(); /*Total notification(read & unread) */
  const [count, setCount] = useState(); /*Pages total*/

  useEffect(() => {
    if (socketReload != 0) {
      GetNotifications();
    }
  }, [socketReload]);

  useEffect(() => {
    if (isUpdate != 0) {
      GetNotifications();
    }
  }, [isUpdate]);

  // api
  const notificationsAPI = useMemo(() => new NotificationsAPI(), []);

  const UpdateNotification = async (list) => {
    let data = new FormData();
    data.append("idsNotification", list);

    const response = await notificationsAPI.updateNotificationEP(data);

    if (response.status == 200) {
      NotificationsFn();
    } else {
      console.log(response);
    }
  };

  const GetNotifications = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("page", page + 1);

    const response = await notificationsAPI.getNotificationsEP(data);
    if (response.status == 200) {
      const auxSales = response.data.notifications.e_sales.map((el) => el.id);
      const auxReceipt = response.data.notifications.inventory_receipt.map(
        (el) => el.id
      );
      const auxStatus = response.data.notifications.inventory_status.map(
        (el) => el.id
      );
      const auxTransfer = response.data.notifications.inventory_transfer.map(
        (el) => el.id
      );
      const auxListId = auxSales
        .concat(auxReceipt, auxStatus, auxTransfer)
        .join(",");
      setNotifications(
        response.data.notifications.e_sales.concat(
          response.data.notifications.inventory_receipt,
          response.data.notifications.inventory_status,
          response.data.notifications.inventory_transfer
        )
      );
      setTotalPaginated(response.data.totalPaginated);
      setCount(Math.ceil(response.data.totalPaginated / 10));
      setIsLoading(false);
      UpdateNotification(auxListId);
    } else {
      console.log(response);
    }
  };

  return (
    <>
      <div className="w-100 mt-2 px-3">
        {phone ? (
          <div className="mt-2 bg-white w-100 p-1 r-15">
            <div className="mb-2 p-2">
              <p className={`ds-blue-font m-0 ${phone ? "h6" : "h5"}`}>
                Histórico notificaciones
              </p>
              {/* <Search label={"Buscar"} setSearch={setSearch} onClick={handleClick} invisible={invisible} /> */}
            </div>
          </div>
        ) : (
          <div className="mt-2 bg-white w-100 p-1 r-15">
            <div className="d-flex align-items-center p-2">
              <p className={`ds-blue-font m-0 ${phone ? "h6" : "h5"}`}>
                Histórico notificaciones
              </p>
            </div>
          </div>
        )}
        {isLoading ? (
          <>
            <div className="mt-3 w-100 r-15">
              <Grid
                container
                alignItems="center"
                direction="row"
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
                columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
              >
                <SkeletonCardProduct
                  num={10}
                  rows={6}
                  icon={false}
                  img={true}
                />
              </Grid>
            </div>
          </>
        ) : (
          <ListViewHistorical
            data={notifications}
            setPage={setPage}
            page={page}
            total={totalPaginated}
            count={count}
            requestData={() => GetNotifications()}
          />
        )}
      </div>
    </>
  );
};
