import React, { useMemo, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
  Grid,
  useMediaQuery,
  tableCellClasses,
  Modal,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import HorizontalLinearStepper from "../../components/Stepper/HorizontalLinearStepper";
import ModalImgEvidences from "../../components/Modal/ModalImgEvidences";
import ModalPDFEvidences from "../../components/Modal/ModalPDFEvidence";
import { SalesB2BAPI } from "../../api/SalesB2B";
import { SaleB2B } from "../../classes/SaleB2B";
import boxOpenIcon from "../../assets/boxOpenIcon.svg";

import { LocalShipping, ShoppingBag, Store } from "@mui/icons-material";
import { roles } from "../../helpers/enums";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 5,
  height: "100%",
  // minHeight: 270,
  // display: "flex",
}));

export function DetailSaleB2B() {
  const phone = useMediaQuery("(max-width:480px)");
  const navigate = useNavigate();
  const saleId = useLocation().state;
  const rol = localStorage.getItem("rol");
  if (saleId == undefined) {
    if (rol != roles.ADMIN) {
      navigate("/cliente/ventas/B2B/");
    } else {
      navigate("/administrador/ventas/B2B/");
    }
  }

  const [isLoading, setIsLoading] = useState(true);
  //const [activeStep, setActiveStep] = useState(0);
  const [openImg, setOpenImg] = useState(false);
  const [pdf, setPdf] = useState("");
  const [openPdf, setOpenPdf] = useState(false);
  const [sale, setSale] = useState({});
  /* const trakingCards = [
    {
      title: "Sucursal Origen",
      auxInfo1: sale.branch,
      date: sale.departureDate ? sale.departureDate : "",
      auxInfo2: "",
      icon: (
        <div className="mb-1">
          <img
            src={boxOpenIcon}
            className={activeStep == 0 ? "filter-blue" : "filter-yellow"}
            width={60}
          />
        </div>
      ),
    },
    {
      title: "Última milla",
      auxInfo1: sale.lastMile ? sale.lastMile : "",
      date: "",
      auxInfo2:
        sale.guideNumber && sale.trakingUrl ? (
          <>
            <p className="m-0">
              Guía:{" "}
              <span
                className="cursor-pointer ds-blue-font"
                onClick={() => {
                  handlePdf(sale.trakingTag);
                }}
              >
                {sale.guideNumber}
              </span>
            </p>
            <a href={sale.trakingUrl} className="ds-blue-font">
              Url de rastreo
            </a>
          </>
        ) : (
          ""
        ),
      icon: (
        <LocalShipping
          sx={{ fontSize: 60 }}
          className={
            activeStep == 2
              ? "ds-blue-font"
              : activeStep > 2
              ? "ds-yellow-font"
              : "inactive-font"
          }
        />
      ),
    },
    {
      title: "Ubicación de entrega",
      auxInfo1: sale.businessLocation,
      date: sale.deliveryDate,
      auxInfo2: sale.evidences ? (
        <p
          className="m-0 cursor-pointer ds-blue-font"
          onClick={() => setOpenImg(true)}
        >
          Evidencias
        </p>
      ) : (
        ""
      ),
      icon: (
        <Store
          sx={{ fontSize: 60 }}
          className={activeStep == 4 ? "ds-yellow-font" : "inactive-font"}
        />
      ),
    },
  ]; */

  const handlePdf = (pdf) => {
    setOpenPdf(true);
    setPdf(pdf);
  };
  const handleClosePdf = () => {
    setOpenPdf(false);
  };

  useEffect(() => {
    GetSales();
  }, [saleId]);

  const api = useMemo(() => new SalesB2BAPI(), []);

  const GetSales = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("order", saleId);

    const response = await api.getSaleDetail(data);
    if (response.status == 200) {
      const detail = true;
      setSale(new SaleB2B(response.data, detail));

      /* //Otro
      if (sale.statusId > 4) {
        setActiveStep(0);
        //Finalizado
      } else if (sale.shippingStatusId == 4) {
        setActiveStep(4);
      } else {
        setActiveStep(sale.shippingStatusId - 1); //Listo para entrega, Asignado, En camino
      } */
      setIsLoading(false);
    } else {
      console.log(response);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="w-100 px-3 my-3 ">
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          rowSpacing={2}
          columns={{ xs: 6, sm: 6, md: 12, lg: 12 }}
        >
          {" "}
          {/*---Contenedor padre---*/}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {" "}
            {/*---Ocupara mitad de pantalla---*/}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ height: "100%" }}
            >
              {/*---Altura de 100 para que ocupe verticalmente---*/}
              <Item>
                <p
                  className={`font-weight-bold text-center ${
                    phone ? "h6" : "h5"
                  }`}
                >
                  Detalles de la venta
                </p>
                <div className="mt-2">
                  {isLoading ? (
                    <>
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                    </>
                  ) : (
                    <>
                      <p className="m-1">
                        Código de pedido:{" "}
                        <span className="font-weight-bold">
                          {sale.orderCode}
                        </span>{" "}
                      </p>
                      <p className="m-1">
                        Referencia interna:{" "}
                        <span className="font-weight-bold">
                          {sale.reference}
                        </span>
                      </p>
                      <p className="m-1">
                        Órden de compra:{" "}
                        {sale.purchaseOrder ? (
                          <span
                            className="font-weight-bold ds-blue-font cursor-pointer"
                            onClick={() => handlePdf(sale.purchaseOrder)}
                          >
                            Ver
                          </span>
                        ) : (
                          "El documento no fue proporcionado"
                        )}
                      </p>

                      <p className="m-1">
                        Monto:{" "}
                        <span className="font-weight-bold">
                          $ {sale.amount}{" "}
                        </span>
                      </p>
                      {sale.paymentMethod ? (
                        <p className="m-1">
                          Método de pago:{" "}
                          <span className="font-weight-bold">
                            {sale.paymentMethod}{" "}
                          </span>
                        </p>
                      ) : null}
                      <p className="m-1">
                        Fecha de registro:{" "}
                        <span className="font-weight-bold">
                          {sale.creationDate}
                        </span>
                      </p>
                    </>
                  )}
                </div>
              </Item>
            </Grid>
          </Grid>
          {/* <Grid item xs={6} sm={6} md={7} lg={7}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Item className="w-100" style={{ minHeight: 220 }}>
                  <div className="w-100 d-flex justify-content-center">
                    <p className={`font-weight-bold ${phone ? "h6" : "h5"}`}>
                      Tracking de envío
                    </p>
                  </div>
                  <div className="mt-2">
                    {isLoading ? (
                      <div className="d-flex flex-column align-items-center">
                        <div className="d-flex justify-content-around mb-3 w-100">
                          <Skeleton
                            variant="rectangular"
                            width="25%"
                            height={100}
                          />
                          <Skeleton
                            variant="rectangular"
                            width="25%"
                            height={100}
                          />
                          <Skeleton
                            variant="rectangular"
                            width="25%"
                            height={100}
                          />
                        </div>
                        <Skeleton variant="text" width={"90%"} />
                      </div>
                    ) : (
                      <>
                        <div className="d-flex justify-content-between">
                          {trakingCards.map((el, i) => (
                            <div
                              className="d-flex flex-column align-items-center"
                              key={i}
                            >
                              <div className="ds-blue-font font-weight-bold h-25">
                                {el.title}
                              </div>
                              <div className="h-25">{el.auxInfo1}</div>
                              <div className="h-25">{el.date}</div>
                              <div className="xs-font h-25 text-center">
                                {el.auxInfo2}
                              </div>
                              {el.icon}
                            </div>
                          ))}
                        </div>
                        <HorizontalLinearStepper activeStep={activeStep} />
                      </>
                    )}
                  </div>
                </Item>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item xs={12} className="">
            <Box className="" sx={{ width: "100%" }}>
              <Paper className="px-2 pb-2" sx={{ width: "100%" }}>
                <div className="pt-2 w-100 d-flex justify-content-center">
                  <p className={`font-weight-bold ml-2 ${phone ? "h6" : "h5"}`}>
                    Artículos
                  </p>
                </div>

                <TableContainer sx={{ maxHeight: "41vh" }}>
                  <Table aria-label="sticky table" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">SKU</StyledTableCell>
                        <StyledTableCell align="center">
                          Producto
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Cantidad
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Precio por unidad
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoading ? (
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell>
                            <Skeleton variant="text" />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton variant="text" />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton variant="text" />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton variant="text" />
                          </TableCell>
                        </TableRow>
                      ) : (
                        sale.products &&
                        sale.products.map((p, i) => {
                          const labelId = `enhanced-table-checkbox-${i}`;
                          return (
                            <TableRow
                              key={i}
                              hover
                              role="checkbox"
                              tabIndex={-1}
                            >
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                {p.sku}
                              </TableCell>
                              <TableCell align="center">
                                {p.description}
                              </TableCell>
                              <TableCell align="center">{p.quantity}</TableCell>
                              <TableCell align="center">
                                $ {p.unitPrice}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </div>

      <Modal open={openImg} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalImgEvidences
            images={sale.evidence}
            handleClose={() => setOpenImg(false)}
          />
        </div>
      </Modal>

      <Modal open={openPdf} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalPDFEvidences pdf={pdf} handleClosePdf={handleClosePdf} />
        </div>
      </Modal>
    </>
  );
}
