import React from "react";

import { Grid, Paper, Skeleton, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1.5),
  color: theme.palette.text.secondary,
  borderRadius: 5,
  minHeight: 220,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"

}));

export const SkeletonCardEntry = ({ num, icon, rows }) => {
  const a = Array(num).fill(true);
  const arrRows = Array(rows).fill(true);

  const phone = useMediaQuery("(max-width:480px)");

  return (
    <>
      {a.map((element, index) => (
        <Grid item xs={6} sm={3} md={2.4} lg= {2.4} xl={2.4}>
          <Item className="mb-2" key={index}>
            {
                icon && (
                  <div className="d-flex w-100 justify-content-between mr-1">
                    <Skeleton variant="circular" width={35} height={35} />
                    <Skeleton variant="circular" width={35} height={35} />
                  </div>
                )
              }

              <div className="mt-2">
              {
                    arrRows.map((element, index) => <Skeleton variant="text" />)
                  }
              </div>

              {/* <div className="d-flex align-items-start">
                {!phone && <Skeleton variant="text" width={100} />}
              </div> */}

          </Item>
        </Grid>
      ))}

      {/* Skeleton pagination */}
      <div className="d-flex justify-content-start align-items-center p-2 ml-3">
        <Typography variant="caption" className="ml-2"><Skeleton width={50} /></Typography>
        <Skeleton variant="circular" className="ml-4" width={20} />
        <Skeleton variant="circular" className="ml-2" width={20} />
      </div>
    </>
  );
};
