import React from 'react'

import { Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { theme } from '../../utils/theme';

export const DeleteDocButton = ({ text, onClick, disabled, border, font, value, ref, p }) => {

    const DeleteDocStyled = styled(Button)({
        boxShadow: '0',
        textTransform: 'none',
        fontSize: 14,
        backgroundColor: 'transparent',
        color: theme.palette.secondary.main,
        textAlign: "center",
        fontWeight: 'bold',
        display: "flex",       
        alignItems: "center",    
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&:active': {
      
        },
        '&:focus': {
      
        },
    });


    return (
        <DeleteDocStyled value={value} variant="text" onClick={(e) => onClick(e)} disableElevation  >
            <p>{p}</p>
            <DeleteForeverIcon/>
        </DeleteDocStyled>
    )
}
