import React, { useState } from "react";

import { Grid, Paper, IconButton, Tooltip, Modal } from "@mui/material";
import { styled } from "@mui/material/styles";
import ModalProducts from "../Modal/ModalProducts";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: 10,
  minHeight: 220,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  // alignItems: "center"
}));

export const CardSalesHeadFieldManager = ({ info, type, rol }) => {
  const [openProducts, setOpenProducts] = useState(false);
  const [products, setProducts] = useState([]);

  const orderType = [
    { id: 1, value: "Fast Delivery" },
    { id: 2, value: "On Demand" },
    { id: 3, value: "Same Day - Next Day" },
    { id: 4, value: "Consolidado" },
  ];

  const boxAssigned = [
    { id: 1, value: "16x26x12" },
    { id: 2, value: "23x30x21" },
    { id: 3, value: "25x26x15" },
    { id: 4, value: "10x16x8" },
    { id: 5, value: "18x39x33" },
    { id: 6, value: "10x16x16" },
    { id: 7, value: "22x47x38" },
    { id: 8, value: "20x6x41" },
  ];

  const handleOpenProducts = (products) => {
    setOpenProducts(true);
    setProducts(products);
  };

  return (
    <>
      <Grid item xs={6} sm={3} md={4} lg={3} xl={2.4}>
        <Item className="mb-3 hover-card">
          {/* <div className='ml-3'> */}
          <Tooltip
            title={info.sale_order.length > 20 ? info.sale_order : ""}
            placement="top"
            arrow
          >
            <div>
              <strong>
                Código pedido: {info.sale_order.substring(0, 20)}
                {info.sale_order.length > 20 && "..."}
              </strong>
            </div>
          </Tooltip>
          <div>
            Envío:{" "}
            {info.order_type
              ? orderType.find((el) => el.id == info.order_type).value
              : null}
          </div>
          {info.box_assigned ? (
            <div>
              Embalaje:
              {info.boxAssigned
                ? boxAssigned.find((el) => el.id == info.box_assigned).value
                : null}
            </div>
          ) : null}

          <Tooltip
            title={
              info.last_name || info.name
                ? info.last_name.length > 20
                  ? `${info.name} ${info.last_name}`
                  : ""
                : ""
            }
            placement="top"
            arrow
          >
            <div>
              Picker:
              {info.name || info.last_name
                ? ` ${info.name} ${
                    info.last_name.length > 20
                      ? `${info.last_name.substring(0, 20)}...`
                      : info.last_name
                  }`
                : " -"}
            </div>
          </Tooltip>
          <Tooltip
            title={info.warehouse.length > 20 ? info.warehouse : ""}
            placement="top"
            arrow
          >
            <div>
              {`Sucursal: ${info.warehouse.substring(0, 20)} ${
                info.warehouse.length > 20 ? "..." : ""
              }`}
            </div>
          </Tooltip>
          <div>Canal: {info.channel}</div>
          <Tooltip
            title={info.client.length > 20 ? info.client : ""}
            placement="top"
            arrow
          >
            <div>
              {`Cliente: ${info.client.substring(0, 20)} ${
                info.client.length > 20 ? "..." : ""
              }`}
            </div>
          </Tooltip>
          {info.last_mille ? (
            <Tooltip title={info.shipping_status} placement="left" arrow>
              <div>
                Última milla:{" "}
                <span className="ds-blue-font">{info.last_mille}</span>
              </div>
            </Tooltip>
          ) : null}

          <div>
            Productos:{" "}
            <span
              className="cursor-pointer ds-blue-font"
              onClick={() => handleOpenProducts(info.products)}
            >
              Ver
            </span>
          </div>
          <div>F. Creada: {info.date_created.substring(0, 16)}</div>
          <div>
            F. Aceptada:{" "}
            {info.date_acepted ? info.date_acepted.substring(0, 16) : "-"}
          </div>
          {info.date_finished ? (
            <div>F. Finalizada: {info.date_finished.substring(0, 16)}</div>
          ) : null}
          {info.time_completed ? (
            <div>Picking: {info.time_completed.substring(0, 8)}</div>
          ) : null}
        </Item>
      </Grid>
      <Modal open={openProducts} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalProducts
            order={products}
            handleClose={() => setOpenProducts(false)}
          />
        </div>
      </Modal>
    </>
  );
};
