import React from "react";

import { Grid, Paper, IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Info } from "@mui/icons-material";

import VisibilityIcon from "@mui/icons-material/Visibility";

import { Navigate, useNavigate } from "react-router-dom";

import { roles } from "../../helpers/enums";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: 10,
  minHeight: 160,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  //alignItems: "center"
}));

export const CardSales = ({ info, type, rol }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/${rol}/ventas/detalle/`, {
      state: {
        id: info.id ? info.id : "",
        canal: info.canal ? info.canal : "",
        codigo_pedido: info.codigo_pedido ? info.codigo_pedido : "",
        fecha_creacion: info.fecha_creacion ? info.fecha_creacion : "",
        monto_pedido: info.monto_pedido ? info.monto_pedido : "",
        telefono_cliente:
          info.telefono_cliente || info.telefono_cliente != "None"
            ? info.telefono_cliente
            : "",
        email_cliente: info.email_cliente ? info.email_cliente : "",
        direccion: info.direccion ? info.direccion : "",
        nombre_cliente: info.nombre_cliente ? info.nombre_cliente : "",
        apellido_cliente: info.apellido_cliente ? info.apellido_cliente : "",
        almacen_asignado: info.almacen_asignado ? info.almacen_asignado : "",
        cliente: info.cliente ? info.cliente : "",
      },
    });
  };

  return (
    <Grid item xs={6} sm={3} md={2.4} lg={2.4} xl={2.4}>
      <Item
        className="mb-3 hover-card cursor-pointer"
        onClick={() => {
          handleClick(info);
        }}
      >
        <div className="w-100 d-flex justify-content-end">
          <Tooltip title={"Ver Detalle"} arrow placement="top">
            <div className={`hover-icon`}>
              <IconButton
                onClick={() => {
                  handleClick(info);
                }}
              >
                <VisibilityIcon />
              </IconButton>
            </div>
          </Tooltip>
        </div>

        <div className="ml-1">
          <Tooltip
            title={info.codigo_pedido.length > 14 ? info.codigo_pedido : ""}
            placement="top"
            arrow
          >
            <div>
              <strong>
                Código de pedido: {info.codigo_pedido.substring(0, 14)}
                {info.codigo_pedido.length > 14 && "..."}
              </strong>
            </div>
          </Tooltip>

          <div>Canal: {info.canal}</div>
          {type == roles.ADMIN && <div>Cliente: {info.cliente}</div>}
          <div>Monto: {info.monto_pedido}</div>
          <div>Fecha: {info.fecha_creacion}</div>
        </div>
        {/* </div> */}
        {/* <div className='d-flex align-items-center'>
                        <img src={info.imagen} className={info.imagen && "img-thumbnail"} alt="" width={100} length={100} />
                    </div> */}
        {/* </div> */}
      </Item>
    </Grid>
  );
};
