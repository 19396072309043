import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Modal,
  useMediaQuery,
  MenuItem,
  Grid,
  Backdrop,
  Tooltip,
  InputAdornment,
  IconButton,
} from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material";

import { useNavigate, useLocation } from "react-router-dom";

import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";

import { InputImagePreview } from "../Input/InputImagePreview";

import { ModalError } from "../Modal/ModalError";
import { ModalSuccess } from "../Modal/ModalSuccess";

import LoaderDS from "../../assets/LoaderDS.svg";
import { PickersContext } from "../../context/PickersContext";
import { useMemo } from "react";
import { HeadFieldManagerAPI } from "../../api/HeadFieldManager";

export const PickerForm = () => {
  const phone = useMediaQuery("(max-width:480px)");

  const [branches, setBranches] = useState([]);
  const [photoFile, setPhotoFile] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const fields = [
    { keyName: "Nombre(s)", value: "name", type: true },
    { keyName: "Apellidos", value: "lastName", type: true },
    { keyName: "Email", value: "email", type: true },
    { keyName: "Número de empleado", value: "employeeNumber", type: true },
    { keyName: "Teléfono", value: "personalNumber", type: true },
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = (path) => {
    setSuccess(false);
    if (path == "nuevo") {
      window.location.reload();
    } else {
      navigate(`/headFieldManager/pickers/${path}`);
    }
  };

  useEffect(() => {
    RequestData();
    if (location.state != undefined) {
      setPhotoFile(location.state.photoFile);
    }
  }, []);

  const RequestData = () => {
    getBranchesByHFM();
  };

  // api
  const hfmAPI = useMemo(() => new HeadFieldManagerAPI(), []);

  const getBranchesByHFM = async () => {
    let data = new FormData();

    const response = await hfmAPI.branchesByHfmEP(data);
    if (response.status == 200) {
      setBranches([...response.data.entries]);
    } else {
      console.log(response);
    }
  };

  const {
    addPicker,
    error,
    setError,
    success,
    setSuccess,
    errorText,
    isLoadingAdd,
  } = useContext(PickersContext);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      location.state != undefined
        ? {
            id: location.state.id,
            name: location.state.name,
            lastName: location.state.lastName,
            email: location.state.email,
            employeeNumber: location.state.employeeNumber,
            personalNumber: location.state.personalNumber,
            branchId: location.state.branchId,
            photoFile: location.state.photo,
            photoLoaded: location.state.photoLoaded,
            changePassword: false,
            password: "",
            confirmPassword: "",
          }
        : {
            name: "",
            lastName: "",
            email: "",
            employeeNumber: "",
            personalNumber: "",
            branchId: "",
            photoFile: "",
            photoLoaded: false,
            changePassword: false,
            password: "",
            confirmPassword: "",
          },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Campo obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Demasiado largo"),
      lastName: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Demasiado largo"),
      email: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Formato incorrecto"
        )
        .max(100, "Demasiado largo"),
      employeeNumber: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\d+$/, "Deben ser números sin espacios")
        .min(2, "Demasiado corto")
        .max(7, "Demasiado largo")
        .typeError("Debe ser un número"),
      personalNumber: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\d+$/, "Deben ser números sin espacios")
        .min(10, "Demasiado corto")
        .max(12, "Demasiado largo"),
      branchId: Yup.string().required("Campo obligatorio"),
      photoFile: Yup.string().when("photoLoaded", {
        is: false,
        then: Yup.string().required("Campo obligatorio"),
      }),
      password: Yup.string().when("changePassword", {
        is: true,
        then: Yup.string()
          .required("Campo obligatorio")
          .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s)(?!.*(.)\1\1.*).{8,100}$/,
            "No cumple con los requerimientos."
          ),
      }),
      confirmPassword: Yup.string().when("changePassword", {
        is: true,
        then: Yup.string()
          .required("Campo Obligatorio")
          .oneOf([Yup.ref("password")], "Las contraseñas no coinciden"),
      }),
    }),

    onSubmit: (values) => {
      const obj = {
        ...values,
        photoFile: photoFile,
      };
      addPicker(obj, location);
    },
  });

  const handleChangePhoto = ({ target }) => {
    if (!target.files) {
      formik.setFieldValue("photoLoaded", false);
      return;
    }

    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

    if (!allowedExtensions.exec(target.files[0].name)) {
      alert("Formato inválido, porfavor escoge una imagen");
      target.value = "";
      target.files = null;
      formik.setFieldValue("photoLoaded", false);
      return false;
    }

    const file = target.files[0];
    setPhotoFile(file);
    formik.setFieldValue("photoLoaded", true);
    // setErrorImage(true);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          rowSpacing={2}
          columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}
        >
          {" "}
          {/*---Contenedor padre---*/}
          <Grid item xs={6} sm={6} md={6} lg={6}>
            {" "}
            {/*---Ocupara mitad de pantalla---*/}
            <Grid
              item
              xs={12}
              style={{ height: "100%" }}
              className={`border p-3 r-15 bg-white w-100`}
            >
              {" "}
              {/*---Altura de 100 para que ocupe verticalmente---*/}
              {fields.map((field, i) => (
                <div
                  className={`d-flex flex-column mb-3 ${
                    phone ? "px-2 " : "px-4"
                  }`}
                  key={i}
                >
                  <div
                    className={`d-flex align-items-center`}
                    style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                  >
                    <p className={`h6 no-wrap`} htmlFor={field.value}>{`${
                      field.keyName
                    }: ${field.type ? "*" : ""}`}</p>
                  </div>

                  <TextField
                    error={
                      formik.touched[field.value] && formik.errors[field.value]
                    }
                    size="small"
                    label={field.keyName}
                    variant="outlined"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values[field.value]}
                    name={field.value}
                    id={field.value}
                    helperText={
                      formik.touched[field.value] &&
                      formik.errors[field.value] ? (
                        <div>{formik.errors[field.value]}</div>
                      ) : null
                    }
                  />
                </div>
              ))}
              {/* -------------------------------BRANCH------------------------------- */}
              {/* if is editing put this field in the lefth side */}
              {location.state != undefined ? (
                <div
                  key={"Sucursal"}
                  className={`d-flex  flex-column mb-3 ${
                    phone ? "px-2 " : "px-4"
                  }`}
                >
                  <div
                    className={`d-flex align-items-center`}
                    style={{ minWidth: phone ? "125px" : "160px" }}
                  >
                    <p
                      className={`h6 no-wrap`}
                      htmlFor={"branchId"}
                    >{`${"Sucursal"}:* `}</p>
                  </div>
                  <TextField
                    select
                    size="small"
                    error={formik.touched.branchId && formik.errors.branchId}
                    label="Sucursal"
                    value={formik.values.branchId}
                    name={"branchId"}
                    id={"branchId"}
                    onChange={formik.handleChange}
                    fullWidth
                    helperText={
                      formik.touched.branchId && formik.errors.branchId ? (
                        <>{formik.errors.branchId}</>
                      ) : null
                    }
                  >
                    {branches.map((branch) => (
                      <MenuItem key={branch.id} value={branch.id}>
                        {branch.sucursal}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Grid
              item
              xs={12}
              style={{ height: "100%" }}
              className={"border p-3 r-15 bg-white w-100"}
            >
              {/* -------------------------------BRANCH------------------------------- */}
              {/* if is not editing put this field in the right side */}
              {location.state == undefined ? (
                <div
                  key={"Sucursal"}
                  className={`d-flex  flex-column mb-3 ${
                    phone ? "px-2 " : "px-4"
                  }`}
                >
                  <div
                    className={`d-flex align-items-center`}
                    style={{ minWidth: phone ? "125px" : "160px" }}
                  >
                    <p
                      className={`h6 no-wrap`}
                      htmlFor={"branchId"}
                    >{`${"Sucursal"}:* `}</p>
                  </div>
                  <TextField
                    select
                    size="small"
                    error={formik.touched.branchId && formik.errors.branchId}
                    label="Sucursal"
                    value={formik.values.branchId}
                    name={"branchId"}
                    id={"branchId"}
                    onChange={formik.handleChange}
                    fullWidth
                    helperText={
                      formik.touched.branchId && formik.errors.branchId ? (
                        <>{formik.errors.branchId}</>
                      ) : null
                    }
                  >
                    {branches.map((branch) => (
                      <MenuItem key={branch.id} value={branch.id}>
                        {branch.sucursal}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              ) : null}
              {/* -------------------------------PHOTO------------------------------- */}

              <div className={` d-flex mb-3 ${phone ? "px-2" : "px-4"}`}>
                <InputImagePreview
                  location={location.state}
                  setFieldValue={formik.setFieldValue}
                  errorImage={formik.errors.photoFile}
                  touchedImage={formik.touched.photoFile}
                  text={"Fotografía"}
                  handleChangeImage={handleChangePhoto}
                  imageFile={photoFile}
                  setImageFile={setPhotoFile}
                  maxWidth={"400px"}
                  maxHeight={"190px"}
                />
              </div>
              {/*-------------------------------PASSSWORD-------------------------------*/}

              {location.state != undefined ? (
                <div
                  className={`d-flex flex-column mb-3 ${
                    phone ? "px-2 " : "px-4"
                  }`}
                >
                  <div
                    className={`d-flex align-items-center`}
                    style={{ minWidth: phone ? "100px" : "200px" }}
                  >
                    <p className={`h6 no-wrap`} htmlFor={"changePassword"}>
                      Cambiar contraseña: *{" "}
                    </p>
                  </div>

                  <TextField
                    select
                    size="small"
                    error={
                      formik.touched.changePassword &&
                      formik.errors.changePassword
                    }
                    label="Cambiar contraseña"
                    value={formik.values.changePassword}
                    name={"changePassword"}
                    id={"changePassword"}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    fullWidth
                    helperText={
                      formik.touched.changePassword &&
                      formik.errors.changePassword ? (
                        <div>{formik.errors.changePassword}</div>
                      ) : null
                    }
                  >
                    <MenuItem value={true}>Sí</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </TextField>
                </div>
              ) : null}
              {formik.values.changePassword ? (
                <>
                  <div
                    className={`d-flex flex-column mb-3 ${
                      phone ? "px-2 " : "px-4"
                    }`}
                  >
                    {" "}
                    <div
                      className={`d-flex align-items-center`}
                      style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                    >
                      <p className={`h6 no-wrap`} htmlFor={"password"}>
                        Nueva contraseña: *{" "}
                      </p>
                    </div>
                    <Tooltip
                      title={
                        "Al menos 8 carácteres, un carácter especial, una mayúscula, una minúsula y un número, sin repetir caracteres más de tres veces consecutivas."
                      }
                      arrow
                      placement="top"
                    >
                      <TextField
                        error={
                          formik.touched.password && formik.errors.password
                        }
                        size="small"
                        label={"Contraseña"}
                        variant="outlined"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        name={"password"}
                        id={"password"}
                        helperText={
                          formik.touched.password && formik.errors.password ? (
                            <div>{formik.errors.password}</div>
                          ) : null
                        }
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div
                    className={`d-flex flex-column mb-3 ${
                      phone ? "px-2 " : "px-4"
                    }`}
                  >
                    {" "}
                    <div
                      className={`d-flex align-items-center`}
                      style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                    >
                      <p className={`h6 no-wrap`} htmlFor={"confirmPassword"}>
                        Confirmar contraseña: *{" "}
                      </p>
                    </div>
                    <TextField
                      error={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                      size="small"
                      label={"Contraseña"}
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values.confirmPassword}
                      name={"confirmPassword"}
                      id={"confirmPassword"}
                      helperText={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword ? (
                          <div>{formik.errors.confirmPassword}</div>
                        ) : null
                      }
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </>
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        <div
          className={`${
            !phone ? "d-flex justify-content-end margin-right mt-2" : "mt-2"
          }`}
        >
          <div className={`${phone && "mb-2"}`}>
            <CancelButton
              text={"Cancelar"}
              onClick={() => {
                navigate("/headFieldManager/pickers/");
              }}
              width={phone ? "100%" : ""}
            />
          </div>

          <AcceptButton
            text={location.state != undefined ? "Editar" : "Agregar"}
            type={"submit"}
            width={phone ? "100%" : ""}
          />
        </div>
      </form>
      <Modal open={error} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalError
            text={`No se pudo agregar el picker.`}
            handleClose={() => setError(false)}
            error={errorText}
          />
        </div>
      </Modal>
      <Modal open={success} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalSuccess
            handleClose={handleClose}
            text={
              location.state != undefined
                ? `La información se actualizó correctamente. ${
                    formik.values.changePassword
                      ? "\n Recibirá un correo con la contraseña."
                      : ""
                  }`
                : `Se agregó el picker correctamente. \n Recibirá un correo con la contraseña.`
            }
            auxText={"¿Deseas agregar otro Picker?"}
            action={location.state != undefined ? "edit" : "add"}
          />
        </div>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingAdd}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
