import React from "react";

import { useMediaQuery } from "@mui/material";

import { PickerForm } from "../../components/Forms/PickerForm";
import { useLocation } from "react-router-dom";

export const AddPicker = () => {
  const phone = useMediaQuery("(max-width:480px)");
  const location = useLocation();

  return (
    <>
      <div className="w-100 px-3 pb-2">
        <div className="bg-white w-100 mt-3 mb-3 pt-1 pb-1 px-3 r-15">
          <p className={`ds-blue-font m-0 fw-bold ${phone ? "h6" : "h5"}`}>
            {location.state == undefined ? "Agregar Picker" : "Editar Picker"}
          </p>
          <p className={`m-0 text-secondary ${phone && "xs-font"}`}>
            * Campos obligatorios
          </p>
        </div>

        <div className="mt-2  w-100 d-flex justify-content-between  r-15 d-flex flex-column">
          <PickerForm />
        </div>
      </div>
    </>
  );
};
