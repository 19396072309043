import React, { useContext, useState, useEffect } from "react";
import { FilterContext } from "../../../context/FilterContext";
import { useMediaQuery, Popover } from "@mui/material";
import { Search } from "../../../components/Search";
import { DownloadButtonExcel } from "../../../components/Excel/DownloadButtonExcel";
import { SkeletonTable } from "../../../components/Skeleton/SkeletonTable";
import { ToggleView } from "../../../components/ToggleView";
import { B2BSalesAdmin, ExcelB2BAdmin } from "../../../components/headers";
import { AddButton } from "../../../components/AddButton";
import { SalesB2BAPI } from "../../../api/SalesB2B";
import { TableSalesB2B } from "../../../components/Tables/TableSalesB2B";
import { ModalFilter2 } from "../../../components/Modal/ModalFilter2";
import {
  getDateCleanedHyphen,
  getDayjsHyphenFormatted,
} from "../../../helpers/getDateCleaned";
import { ListViewSalesB2B } from "../../../components/ListViewSalesB2B";
import { useMemo } from "react";
import { SaleB2B } from "../../../classes/SaleB2B";

export function SalesB2B() {
  const [view, setView] =
    useState(
      true
    ); /* IF TRUE TABLE VIEW IS ACTIVE, OTHERWISE CARD VIEW IS ACTIVE */
  const phone = useMediaQuery("(max-width:480px)"); // FOR RESPONSIVE DESIGN
  const tablet = useMediaQuery("(max-width:750px)"); // FOR RESPONSIVE DESIGN
  //------------------------------------------------
  //DATA STATES
  //------------------------------------------------
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(); /*Paginacion listas */
  const [excelData, setExcelData] = useState([]);

  //------------------------------------------------
  //LOADERS STATES
  //------------------------------------------------

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  //------------------------------------------------
  //FILTER STATES AND FUNCTIONS
  //------------------------------------------------
  const {
    search,
    setSearch,
    resetFilter,
    getLocationsByBusiness,
    getBusiness,
    getBranchesByClient,
    getClientsByAdmin,
    clientBranch,
    business,
    businessLocation,
    saleStatus,
    startDate,
    endDate,
    adminClient,
  } = useContext(FilterContext);
  const [invisible, setInvisible] = useState(false);
  //------------------------------------------------
  //EFFECTS
  //------------------------------------------------

  useEffect(() => {
    setPage(0);
    setSearch("");
    resetFilter();
    requestFilterData();
  }, []);

  useEffect(() => {
    requestData();
  }, [page]);

  //------------------------------------------------
  //DATA REQUEST
  //------------------------------------------------

  const requestData = () => {
    getSales();
  };

  const requestFilterData = () => {
    getBusiness();
    getBranchesByClient();
    getClientsByAdmin();
  };

  //When the user select a business to filter, we will show only the locations link to that business
  useEffect(() => {
    getLocationsByBusiness();
  }, [business]);

  /************** REQUEST DATA *******************/
  const api = useMemo(() => new SalesB2BAPI(), []);

  const getSales = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("page", page + 1);
    data.append("excel", false);
    data.append("date_created", getDayjsHyphenFormatted(startDate));
    data.append("end_date_created", getDayjsHyphenFormatted(endDate));

    if (adminClient != 0) {
      data.append("client_sale", adminClient);
    }

    if (clientBranch != 0) {
      data.append("origin", clientBranch);
    }
    if (business != 0) {
      data.append("business_sale", parseInt(business, 10));
    }
    if (businessLocation != 0) {
      data.append("businesslocation", businessLocation);
    }
    if (saleStatus != 0) {
      data.append("generated_order", saleStatus);
    }
    if (search != "") {
      data.append("search_sale", search);
    }

    const response = await api.getSalesAdminEP(data);
    if (response.status == 200) {
      setData(response.data.entries);
      setTotal(response.data.total);
      setCount(Math.ceil(response.data.total / 10));
      setIsLoading(false);
    } else {
      // console.log(response);
      setIsLoading(false);
    }
  };

  const getSalesExcel = async () => {
    setIsLoadingExcel(true);
    let data = new FormData();

    data.append("page", page + 1);
    data.append("excel", true);
    data.append("date_created", getDayjsHyphenFormatted(startDate));
    data.append("end_date_created", getDayjsHyphenFormatted(endDate));

    if (adminClient != 0) {
      data.append("client_sale", adminClient);
    }

    /* if (clientBranch != 0) {
      data.append("origin", clientBranch);
    } */
    if (business != 0) {
      data.append("business", business);
    }
    if (businessLocation != 0) {
      data.append("businesslocation", businessLocation);
    }
    if (saleStatus != 0) {
      data.append("generated_order", saleStatus);
    }
    if (search != "") {
      data.append("search_sale", search);
    }

    const response = await api.getSalesAdminEP(data);
    if (response.status == 200) {
      setExcelData(response.data.entries.map((el) => new SaleB2B(el)));
      setIsLoadingExcel(false);
    } else {
      // console.log(response);
      setIsLoadingExcel(false);
    }
  };

  //------------------------------------------------
  // MODAL HANDLERS
  //------------------------------------------------
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleCloseModal = () => {
    setAnchorEl(null);
  };

  //------------------------------------------------
  // TABLE CONTENT
  //------------------------------------------------

  return (
    <>
      <div className="w-100 px-3 mt-2">
        {tablet ? (
          <div className="mt-2 w-100 p-1 r-15">
            <div className="mb-2">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                requestData={requestData}
                setPage={setPage}
              />
            </div>

            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <DownloadButtonExcel
                  text={""}
                  disabled={data.length > 0 ? false : true}
                  getData={() => {
                    getSalesExcel();
                  }}
                  headers={ExcelB2BAdmin}
                  data={excelData}
                  sheetName={`Ventas B2B`}
                  isLoading={isLoadingExcel}
                />
              </div>

              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        ) : (
          <div className="mt-2 w-100 d-flex justify-content-between p-1 r-15">
            <div className="d-flex align-items-center">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                requestData={requestData}
                setPage={setPage}
              />
              <div className="d-flex align-items-center ml-1">
                <DownloadButtonExcel
                  text={"Descargar"}
                  disabled={data.length > 0 ? false : true}
                  getData={() => {
                    getSalesExcel();
                  }}
                  headers={ExcelB2BAdmin}
                  data={excelData}
                  sheetName={`Ventas B2B`}
                  isLoading={isLoadingExcel}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        )}
        {view ? (
          // Vista de tabla
          isLoading ? (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <SkeletonTable num={3} headers={B2BSalesAdmin} />
            </div>
          ) : (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <TableSalesB2B
                data={data}
                search={search}
                setPage={setPage}
                page={page}
                total={total}
                headers={B2BSalesAdmin}
                requestData={requestData}
              />
            </div>
          )
        ) : (
          <ListViewSalesB2B
            data={data}
            search={search}
            setPage={setPage}
            page={page}
            total={total}
            count={count}
            requestData={requestData}
            isLoading={isLoading}
            headers={B2BSalesAdmin}
          />
        )}
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseModal}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <ModalFilter2
          handleCloseModal={handleCloseModal}
          fields={[
            "adminClient",
            "startDate",
            "endDate",
            //"clientBranch",
            //"business",
            //"businessLocation",
            "saleStatus",
          ]}
          requestData={requestData}
          view={view}
          setPage={setPage}
        />
      </Popover>
    </>
  );
}
