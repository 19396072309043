import React, { useState, useEffect } from "react";
import { TablePagination } from "@mui/material";
import { theme } from "../../utils/theme";

export const Pagination = ({ total, setPage, page, requestData }) => {
  const [ready, setReady] = useState(false);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
    setReady(true);
  };

  useEffect(() => {
    if (ready && requestData) {
      requestData();
    }
  }, [ready]);

  return (
    <div className="d-flex">
      <div className="d-flex align-items-center pl-3">
        <span className="ds-blue-font small-font">Página: {page + 1}</span>
      </div>

      <TablePagination
        sx={{
          ".MuiTablePagination-displayedRows": {
            margin: 0 /*Remueve margin de paginacion */,
            color: theme.palette.secondary.main,
          },
          ".MuiButtonBase-root:hover": {
            backgroundColor: theme.palette.primary.main,
          },
          ".MuiIconButton-colorInherit": {
            color: theme.palette.secondary.main,
          },
          ".MuiIconButton-colorInherit:hover": {
            color: "#fff",
          },
        }}
        labelDisplayedRows={({ from, to, count }) => {
          return "" + from + "-" + to + " de " + count;
        }}
        className="d-flex justify-content-start align-items-center"
        component="div"
        count={total}
        rowsPerPageOptions={[]} /*Para eliminar rows per page*/
        rowsPerPage={10}
        page={page}
        onPageChange={handleChangePage}
      />
    </div>
  );
};
