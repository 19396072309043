import React, { useState, useEffect } from "react";
import { Pagination, useMediaQuery } from "@mui/material";
import { theme } from "../../utils/theme";

export const ListPagination = ({
  total,
  page,
  setPage,
  count,
  requestData,
}) => {
  const phone = useMediaQuery("(max-width:480px)");
  const [ready, setReady] = useState(false);

  const handleChangePage = (e, newPage) => {
    setPage(newPage - 1); /*Para posicionar en page correcta */
    setReady(true);
  };

  const maxPages = Math.ceil(total / 10);
  const pageLocation = page + 1;

  useEffect(() => {
    if (ready) {
      if (requestData) {
        requestData();
      }
    }
    setReady(false);
  }, [ready]);

  return (
    <div
      className={`d-flex py-2 ${
        phone ? "flex-column" : "justify-content-start align-items-center"
      }`}
    >
      <div
        className={`${phone && "mt-2"} mx-3`}
      >
        <span className="ds-blue-font xs-font">
          {total == 0 ? 0 : pageLocation * 10 - 9}-
          {total == 0
            ? 0
            : pageLocation == maxPages
            ? total
            : pageLocation * 10}{" "}
          de {total}
        </span>
      </div>
      <Pagination
        sx={{
          ".MuiButtonBase-root:hover": {
            backgroundColor: theme.palette.primary.main,
          },
          ".MuiPaginationItem-text": {
            color: theme.palette.secondary.main ,
          },
          ".MuiPaginationItem-text:hover": {
            color: "#fff",
          },
          ".MuiPaginationItem-root": {
            color: theme.palette.secondary.main ,
          },
          "&& .Mui-selected": {
            backgroundColor: "#a8c0c9",
            color: theme.palette.secondary.main ,
            ":hover": {
              backgroundColor: theme.palette.primary.main,
            },
          },
        }}
        count={Math.ceil(total / 10)}
        page={page + 1} /*Para posicionar en page correcta */
        onChange={handleChangePage}
        size="small"
      />
    </div>
  );
};
