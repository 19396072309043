import { HttpRequest } from "./HttpRequest";
import { getHost } from "../helpers/getHost";

export class InventoryAPI {
  constructor() {
    this.http = new HttpRequest();
    this.host = getHost();
    this.token = localStorage.getItem("token");
    this.config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${this.token}`,
      },
    };
  }

  inventoryListEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/inventory/`,
      data,
      this.config
    );
  }

  createInventoryEntryEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/inventoryEntry/`,
      data,
      this.config
    );
  }

  editInventoryEntryEP(data) {
    return this.http.put(
      `${this.host}/inventory/ws/inventoryEntry/`,
      data,
      this.config
    );
  }

  createMassiveInventoryEntryEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/createInventoryEntries/`,
      data,
      this.config
    );
  }

  deleteFolioInventoryEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/deleteEntries/`,
      data,
      this.config
    );
  }

  deleteProductEntryEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/deleteEntryLine/`,
      data,
      this.config
    );
  }

  inventoryEntriesEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/inventoryEntries/`,
      data,
      this.config
    );
  }

  inventoryExcelEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/inventoryExcel/`,
      data,
      this.config
    );
  }

  /* editInventoryEntryEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/editInventoryEntry/`,
      data,
      this.config
    );
  } */
  productStockEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/productStock/`,
      data,
      this.config
    );
  }

  entriesExcelEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/entriesExcel/`,
      data,
      this.config
    );
  }
}
