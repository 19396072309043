import React, { useState } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  tableCellClasses,
  Modal,
  Tooltip,
} from "@mui/material";

import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";
import { ListPagination } from "../Pagination/ListPagination";
import { useNavigate } from "react-router-dom";
import ModalProducts from "../Modal/ModalProducts";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const TableActiveSalesHFM = ({
  search,
  data,
  setPage,
  page,
  total,
  headers,
  requestData,
  count,
}) => {
  const { height, width } = useViewPortDimensions();
  const navigate = useNavigate();

  const [openProducts, setOpenProducts] = useState(false);
  const [products, setProducts] = useState([]);

  const handleOpenProducts = (products) => {
    setOpenProducts(true);
    setProducts(products);
  };

  const orderType = [
    { id: 1, value: "Fast Delivery" },
    { id: 2, value: "On Demand" },
    { id: 3, value: "Same Day - Next Day" },
    { id: 4, value: "Paquetería" },
  ];

  const boxAssigned = [
    { id: 1, value: "16x26x12" },
    { id: 2, value: "23x30x21" },
    { id: 3, value: "25x26x15" },
    { id: 4, value: "10x16x8" },
    { id: 5, value: "18x39x33" },
    { id: 6, value: "10x16x16" },
    { id: 7, value: "22x47x38" },
    { id: 8, value: "20x6x41" },
  ];

  const saleStatus = [
    { id: 0, value: "Todos" },
    { id: 1, value: "Creación" },
    { id: 2, value: "Picking" },
    { id: 3, value: "Packing" },
  ];

  const formatTime = (time) => {};

  const tRow = (row, index) => {
    const labelId = `enhanced-table-checkbox-${index}`;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        <TableCell align="center" style={{ minWidth: 120 }}>
          {row.sale_order}
        </TableCell>
        <TableCell align="center" style={{ minWidth: 120 }}>
          {row.order_type
            ? orderType.find((el) => el.id == row.order_type).value
            : "N/A"}
        </TableCell>
        <TableCell align="center" style={{ padding: "4px 16px" }}>
          <Tooltip
            title={
              row.box_id
                ? boxAssigned.find((el) => el.id == row.box_id).value
                : ""
            }
            placement="top"
            arrow
          >
            <div>
              {row.box_id ? (
                <>
                  <img src={row.box_image} sx={{ maxWidth: 60 }} height={40} />
                  <p className="m-0">{row.box_name}</p>
                </>
              ) : (
                "No asignado"
              )}
            </div>
          </Tooltip>
        </TableCell>
        <TableCell align="center">
          {row.last_name || row.name
            ? `${row.name} ${row.last_name}`
            : "No asignado"}
        </TableCell>
        <TableCell align="center" style={{ minWidth: 130 }}>
          {row.warehouse}
        </TableCell>
        <TableCell align="center">
          <span
            className="cursor-pointer ds-blue-font"
            onClick={() => handleOpenProducts(row.products)}
          >
            Ver artículos
          </span>
        </TableCell>
        <TableCell align="center">{row.channel}</TableCell>
        <TableCell align="center" style={{ minWidth: 130 }}>
          {row.client}
        </TableCell>
        <TableCell align="center">
          {row.status_sale != null
            ? saleStatus.find((el) => row.status_sale == el.id).value
            : "N/A"}
        </TableCell>
        <TableCell align="center" style={{ minWidth: 120 }}>
          {row.date_created.substring(0, 16)}
        </TableCell>
        <TableCell align="center" style={{ minWidth: 120 }}>
          {row.date_accepted
            ? row.date_accepted.substring(0, 16)
            : "En espera"}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: height - (74 + 90 + 60) }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-label="sticky table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {headers.map((header, index) => (
                    <StyledTableCell align="center" key={index}>
                      {header.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => tRow(row, index))}
              </TableBody>
            </Table>
            {data.length == 0 && search != "" ? (
              <div className="d-flex justify-content-center mt-2">
                No se encontraron resultados para "{search}"
              </div>
            ) : (
              data.length == 0 &&
              search == "" && (
                <div className="d-flex justify-content-center mt-2">
                  No tienes ventas aún.
                </div>
              )
            )}
          </TableContainer>
          <ListPagination
            setPage={setPage}
            page={page}
            total={total}
            requestData={requestData}
            count={count}
          />
        </Paper>
      </Box>

      <Modal open={openProducts} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalProducts
            order={products}
            handleClose={() => setOpenProducts(false)}
          />
        </div>
      </Modal>
    </>
  );
};
