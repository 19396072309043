import { HttpRequest } from "./HttpRequest";
import { getHost } from "../helpers/getHost";

export class HeadFieldManagerAPI {
  constructor() {
    this.http = new HttpRequest();
    this.host = getHost();
    this.token = localStorage.getItem("token");
    this.config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${this.token}`,
      },
    };
  }

  branchesByHfmEP(data) {
    return this.http.post(`${this.host}/hfm/ws/listBranch/`, data, this.config);
  }

  activeSalesEP(data) {
    return this.http.post(
      `${this.host}/hfm/ws/activeSalesByHfm/`,
      data,
      this.config
    );
  }

  pickersByHfmEP(data) {
    return this.http.post(
      `${this.host}/hfm/ws/listPickers/`,
      data,
      this.config
    );
  }

  pickersListByHfmEP(data) {
    return this.http.post(
      `${this.host}/hfm/ws/listPickersbyHfm/`,
      data,
      this.config
    );
  }

  createPickerEP(data) {
    return this.http.post(
      `${this.host}/hfm/ws/createPicker/`,
      data,
      this.config
    );
  }

  editPickerEP(data) {
    return this.http.put(
      `${this.host}/hfm/ws/createPicker/`,
      data,
      this.config
    );
  }

  desactivatePickerEP(data) {
    return this.http.post(
      `${this.host}/hfm/ws/desactivatePicker/`,
      data,
      this.config
    );
  }

  channelsByHfmEP(data) {
    return this.http.post(
      `${this.host}/hfm/ws/listChannel/`,
      data,
      this.config
    );
  }

  clientsByHfmEP(data) {
    return this.http.post(`${this.host}/hfm/ws/listClient/`, data, this.config);
  }

  excelDataEP(data) {
    return this.http.post(
      `${this.host}/hfm/ws/activeSalesByHfmExcel/`,
      data,
      this.config
    );
  }

  historicSalesCardViewEP(data) {
    return this.http.post(
      `${this.host}/hfm/ws/finishedAndCanceled/`,
      data,
      this.config
    );
  }

  activeSalesCardViewEP(data) {
    return this.http.post(`${this.host}/hfm/ws/statusSale/`, data, this.config);
  }
}
