import React, { useState, useContext, useMemo } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  tableCellClasses,
  Modal,
  Tooltip,
  IconButton,
  Popover,
  useMediaQuery,
  Backdrop,
} from "@mui/material";

import { roles } from "../../helpers/enums";

import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";
import { Pagination } from "../Pagination/Pagination";

import {
  EditTwoTone,
  ToggleOff,
  ToggleOffOutlined,
  ToggleOn,
} from "@mui/icons-material";

import { useNavigate } from "react-router-dom";

import { ModalSuccess } from "../Modal/ModalSuccess";
import { ModalError } from "../Modal/ModalError";
import { ModalAction } from "../Modal/ModalAction";

import LoaderDS from "../../assets/LoaderDS.svg";

import { BusinessContext } from "../../context/BusinessContext";
import { ListPagination } from "../Pagination/ListPagination";
import { SalesB2BAPI } from "../../api/SalesB2B";

const StyledTableCell = styled(TableCell)(({ theme, status }) => ({
  [`&.${tableCellClasses.head}`]: {
    textAlign: "center",
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: "center",
    fontSize: 14,
    color: status ? theme.palette.common.black : theme.palette.neutrals.inactive,
  },
}));

export function BusinessTable({
  search,
  data,
  setPage,
  page,
  total,
  requestData,
  headers,
  count,
}) {
  /*************** STYLE STATES *****************/
  const { height, width } = useViewPortDimensions();
  const phone = useMediaQuery("(max-width:480px)");

  const navigate = useNavigate();
  const rol = localStorage.getItem("rol");
  /*************** MODAL STATES *****************/

  const [openDesactivate, setOpenDesactivate] = useState(false);
  const [openActivate, setOpenActivate] = useState(false);
  const [business, setBusiness] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const idPopover = openDesactivate ? "simple-popover" : undefined;

  /*************** REQUEST ACTIONS *****************/

  const api = useMemo(() => new SalesB2BAPI(), []);

  const desactivateBusiness = async () => {
    setIsLoading(true);
    let data = new FormData();

    data.append("business", business.id);
    data.append("status", false);

    const response = await api.deleteBusinessEP(data);
    if (response.status == 200) {
      setOpenSuccess(true);
      setIsLoading(false);
    } else {
      setOpenError(true);
      setErrorMsg(response.response.data.detail);
      setIsLoading(false);
    }
  };

  const activateBusiness = async () => {
    setIsLoading(true);
    let data = new FormData();

    data.append("business", business.id);
    data.append("status", true);

    const response = await api.deleteBusinessEP(data);
    if (response.status == 200) {
      setOpenSuccess(true);
      setIsLoading(false);
    } else {
      setOpenError(true);
      setErrorMsg(response.response.data.detail);
      setIsLoading(false);
    }
  };

  /*************** MODAL HANDLERS *****************/

  const handleCloseModal = (modal) => {
    switch (modal) {
      case "success":
        setOpenSuccess(false);
        requestData();
        break;
      case "desactivate":
        setOpenDesactivate(false);
        break;
      case "activate":
        setOpenActivate(false);
        break;
      case "error":
        setOpenError(false);
        break;
    }
  };

  const handleClick = (event, action, info) => {
    setBusiness(info);
    switch (action) {
      case "edit":
        navigate("/cliente/ventas/B2B/negocios/nuevo/", {
          state: { business: info },
        });
        break;
      case "desactivate":
        setAnchorEl(event.currentTarget);
        setOpenDesactivate(true);
        break;

      case "activate":
        setAnchorEl(event.currentTarget);
        setOpenActivate(true);
        break;
      case "detail":
        if (rol == roles.ADMIN) {
          navigate("/administrador/ventas/B2B/negocios/detalle/", {
            state: { ...info },
          });
        } else {
          navigate("/cliente/ventas/B2B/negocios/detalle/", {
            state: { ...info },
          });
        }

        break;
    }
  };

  const tRow = (row, indexR) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={indexR}>
        {rol != roles.ADMIN && (
          <StyledTableCell status={row.status}>
            <div className="d-flex justify-content-center">
              {row.status == true ? (
                <Tooltip title="Desactivar" arrow placement="top">
                  <IconButton
                    sx={{ "&:hover": { color: "red" }, padding: "0 1px 0 0" }}
                    onClick={(e) => handleClick(e, "desactivate", row)}
                    disabled={false}
                    className={"hover-icon"}
                  >
                    <ToggleOn />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Activar" arrow placement="top">
                  <IconButton
                    sx={{ "&:hover": { color: "green" }, padding: "0 1px 0 0" }}
                    onClick={(e) => handleClick(e, "activate", row)}
                    disabled={false}
                    className={"hover-icon"}
                  >
                    <ToggleOffOutlined />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title="Editar" arrow placement="top">
                <IconButton
                  sx={{ "&:hover": { color: "green" }, padding: "0 1px 0 0" }}
                  onClick={(e) => handleClick(e, "edit", row)}
                  disabled={row.status == true ? false : true}
                  className={`ml-2 ${row.status == true ? "hover-icon" : ""}`}
                >
                  <EditTwoTone className />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        )}

        {rol == roles.ADMIN && (
          <StyledTableCell status={row.status}>{row.client}</StyledTableCell>
        )}
        <StyledTableCell
          status={row.status}
          onClick={(e) => row.status == true && handleClick(e, "detail", row)}
        >
          <Tooltip title="Ver detalle" placement="top" arrow>
            <span
              className={
                row.status == true ? "cursor-pointer ds-blue-font" : ""
              }
            >
              {row.business}
            </span>
          </Tooltip>
        </StyledTableCell>
        <StyledTableCell status={row.status}>{row.regime_type}</StyledTableCell>
        <StyledTableCell status={true}>
          {row.status == true ? "Activo" : "Inactivo"}
        </StyledTableCell>
        {/* <StyledTableCell status={row.status}>
          {row.last_mile == true ? "Day Store" : "Otra"}
        </StyledTableCell> */}
      </TableRow>
    );
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: height - (74 + 90 + 60) }}>
            <Table
              sx={{ minWidth: 360 }}
              aria-label="sticky table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {headers.map((header, index) => (
                    <StyledTableCell key={index}>
                      {header.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => tRow(row, index))}
              </TableBody>
            </Table>
            {data.length == 0 && search != "" ? (
              <div className="d-flex justify-content-center mt-2">
                No se encontraron resultados para "{search}"
              </div>
            ) : (
              data.length == 0 &&
              search == "" && (
                <div className="d-flex justify-content-center mt-2">
                  No tienes negocios.
                </div>
              )
            )}
          </TableContainer>
          <ListPagination
            setPage={setPage}
            page={page}
            total={total}
            requestData={requestData}
            count={count}
          />
        </Paper>
      </Box>

      <Popover
        id={idPopover}
        open={openDesactivate}
        anchorEl={anchorEl}
        onClose={() => handleCloseModal("desactivate")}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: !phone && 350 },
        }}
      >
        <ModalAction
          text={"Estás a punto de desactivar el negocio"}
          object={business.business}
          handleClose={() => handleCloseModal("desactivate")}
          ejectAction={() => {
            desactivateBusiness(business.id);
          }}
        />
      </Popover>
      <Popover
        id={idPopover}
        open={openActivate}
        anchorEl={anchorEl}
        onClose={() => handleCloseModal("activate")}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: !phone && 350 },
        }}
      >
        <ModalAction
          text={"Estás a punto de reactivar el negocio"}
          object={business.business}
          handleClose={() => handleCloseModal("activate")}
          ejectAction={() => {
            activateBusiness(business.id);
          }}
        />
      </Popover>
      <Modal open={openSuccess}>
        <div>
          <ModalSuccess
            text={
              business.status == true
                ? "El negocio fue desactivado con éxito"
                : "El negocio fue reactivado con éxito"
            }
            handleClose={() => handleCloseModal("success")}
          />
        </div>
      </Modal>
      <Modal open={openError}>
        <div>
          <ModalError
            text={
              business.statis == true
                ? "No pudimos desactivar el negocio"
                : "No pudimos reactivar el negocio"
            }
            handleClose={() => handleCloseModal("error")}
            error={errorMsg}
          />
        </div>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
}
