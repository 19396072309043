import React from 'react'

import { Grid, Paper, IconButton, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles';

import { roles } from '../../helpers/enums';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    borderRadius: 10,
    minHeight: 210,
    display: "flex",
    alignItems: "center",
}));

export const CardInventory = ({ info, type }) => {

    return (
        <Grid item xs={6} sm={3} md={2.4} lg={2.4} xl={2.4}>
            <Item className='mb-3'>

                <div className='d-flex justify-content-between w-100'>
                    <div>
                        {/* <div>
                            {info.name}
                        </div> */}

                        <Tooltip
                            title={info.description.length > 20 ? info.description : ""}
                            placement="top"
                            arrow
                        >
                            <div>
                                <strong>{info.description.substring(0, 20)}</strong>{info.description.length > 20 && "..."}
                            </div>
                        </Tooltip>

                        {/* <div>
                            <strong>{info.description}</strong>
                        </div> */}

                        <div>
                            SKU: {info.sku}
                        </div>
                        {type == roles.ADMIN &&
                            <div>
                                Cliente: {info.client}
                            </div>
                        }
                        <div>
                            Código de barras: {info.sku}
                        </div>
                        <div>
                            Stock: {info.stock}
                        </div>
                    </div>
                </div>

            </Item>
        </Grid>
    )
}
