import React, { useState } from "react";

import {
  Grid,
  Paper,
  IconButton,
  Tooltip,
  Popover,
  Modal,
  Backdrop,
  useMediaQuery,
  Badge,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ModalDetailSales from "../Modal/ModalDetailSales";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: 10,
  minHeight: 430,
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const CardHistorical = ({ info, type, }) => {
  const media = useMediaQuery("(max-width:900px)");
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);

  const types = [
    { id: 0, value: "Recepción de mercancía" },
    { id: 1, value: "Transferencia de mercancía" },
    { id: 2, value: "Cambio en el inventario" },
    { id: 3, value: "Venta" },
  ]

  return (
    <>
      <Grid item xs={6} sm={3} md={2.3} lg={2.3} xl={2.3}>
        <Badge
          variant="dot"
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: "#fb0808",
              width: 10,
              height: 10,
              borderRadius: "50%",
            },
          }}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          invisible={info.readed}
        >
          <Item className="mb-3 w-100">
            <div className="w-100">
              <strong>Fecha de notificación:</strong>{" "}
              {info.dateCreation}
            </div>
            <div className="w-100">
              <strong>SKU:</strong>{" "}
              {info.sku}
            </div>
            {
              info.typeNotification == 3 &&
              <>

                <Tooltip title={info.article.length > 20 ? info.article : ""} placement="top" arrow>
                  <div className="w-100">
                    <strong>Producto:</strong>{" "}{info.article.substring(0, 20)}{info.article.length > 20 && "..."}
                  </div>
                </Tooltip><div className="w-100">
                  <strong>Cantidad: </strong>
                  {info.qty}
                </div>
              </>
            }
            <div className="w-100">
              <strong>Descripción: </strong>{" "}
              {info.message}
            </div>
            <div className="w-100">
              <strong>Origen: </strong>{" "}
              {info.originNotification}
            </div>
            <div className="w-100">
              <strong>Tipo de notificación:</strong> {types.find(el => el.id == info.typeNotification).value}
            </div>
            {
              info.typeNotification == 3 &&
              <>
                <Tooltip title={info.details.trackingNumber.length > 20 ? info.details.trackingNumber : ""} placement="top" arrow>
                  <div className="w-100">
                    <strong>Número de guía:</strong>{" "}
                    {info.details.trackingNumber == "" ? "No asignado" : info.details.trackingNumber.toString().substring(0, 20)}{info.details.trackingNumber.length > 20 && "..."}
                  </div>
                </Tooltip>

                <div className='w-100 text-center'>
                  <span style={{ fontSize: 16 }} className="cursor-pointer ds-blue-font" onClick={() => { setOpen(true); setId(info.details.orderId) }}>Ver más</span>
                </div>
              </>
            }


          </Item>
        </Badge>

      </Grid>

      <Modal open={open} onClose={() => console.log("Cerrando")}>
        <div>
        <ModalDetailSales id={id} handleClose={() => setOpen(false)} />
        </div>
      </Modal>


    </>
  );
};
