import React, { useState, useEffect, useMemo } from "react";

import { Grid, Popover, useMediaQuery } from "@mui/material";

import { Search } from "../../components/Search";
import { ToggleView } from "../../components/ToggleView";

import { DownloadButtonExcel } from "../../components/Excel/DownloadButtonExcel";
import { salesHeadersAdmin } from "../../components/headers";

import {
  getDateCleaned,
  getDayjsFormatted,
} from "../../helpers/getDateCleaned";

import { roles } from "../../helpers/enums";
import { ModalFilters } from "../../components/Modal/ModalFilters";
import { TableSales } from "../../components/Tables/TableSales";
import { SkeletonTableSales } from "../../components/Skeleton/SkeletonTableSales";
import { SkeletonCardSales } from "../../components/Skeleton/SkeletonCardSales";
import { ListViewSales } from "../../components/ListViewSales";
import { SalesB2CAPI } from "../../api/SalesB2C";
import { GraphsAPI } from "../../api/Graphs";
import { AdminAPI } from "../../api/Admin";

export const SalesAdmin = () => {
  const [view, setView] = useState(true);
  const phone = useMediaQuery("(max-width:480px)");

  //DATA STATES
  //------------------------------------------------
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0); /*Estado p/paginacion*/
  const [total, setTotal] = useState(0); /*Total de paginas enviado desde api*/
  const [count, setCount] = useState(0); /*Paginacion listas */
  const [search, setSearch] = useState("");
  const [ready, setReady] = useState(false);

  //------------------------------------------------

  //ERRORES
  //------------------------------------------------

  const [errors, setErrors] = useState({
    error: false,
    errorNullStart: false,
    errorNullEnd: false,
  });

  //------------------------------------------------

  //MODAL STATES
  //------------------------------------------------

  const [openFilter, setOpenFilter] = useState(false);
  const [invisible, setInvisible] = useState(false);

  //------------------------------------------------

  //LOADERS STATES
  //------------------------------------------------

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  //------------------------------------------------

  //FILTERS STATES
  //------------------------------------------------

  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

  const [startFilterDate, setStartFilterDate] = useState(firstDay);
  const [endFilterDate, setEndFilterDate] = useState(today);

  const [channel, setChannel] = useState("Todos");
  const [channels, setChannels] = useState([]);
  const [status, setStatus] = useState("Todos");

  const [client, setClient] = useState("Todos");
  const [clients, setClients] = useState([]);

  const [prevData, setPrevData] = useState({
    startFilterDate: firstDay,
    endFilterDate: today,
    channel: "Todos",
    client: "Todos",
  });

  const statusId = [
    {id: 0, value: "Todos"},
    {id: 1, value: "Listo para entrega"},
    {id: 2, value: "Asignado"},
    {id: 3, value: "En camino"},
    {id: 4, value: "Finalizado"},
    {id: 5, value: "Otro"},
    {id: 6, value: "Cancelado"},
    {id: 7, value: "Entrega parcial"}
  ]

  //------------------------------------------------

  //EFFECTS
  //------------------------------------------------

  useEffect(() => {
    setPage(0);
    setSearch("");
    setReady(true);
    requestFilterData();
  }, []);

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  }

  useEffect(() => {
    if (ready) {
      RequestData();
    }
  }, [ready]);

  //------------------------------------------------

  //EXCEL STATES AND DATA
  //------------------------------------------------

  const [dataExcel, setDataExcel] = useState([]);

  //------------------------------------------------

  //DATA REQUEST
  //------------------------------------------------

  const RequestData = () => {
    GetSalesList();
  };

  const requestFilterData = () => {
    GetClients();
    GetDataChannel();
  };

  //APIS
  const salesB2CAPI = useMemo(() => new SalesB2CAPI(), []);
  const graphAPI = useMemo(() => new GraphsAPI(), []);
  const adminAPI = useMemo(() => new AdminAPI(), []);

  const GetClients = async () => {
    let data = new FormData();

    const response = await adminAPI.clientsListCleanedEP(data);
    if (response.status == 200) {
      setClients([{ id: 0, razon_social: "Todos" }, ...response.data.clients]);
    } else {
      console.log(response);
    }
  };

  const GetDataChannel = async () => {
    let data = new FormData();

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }

    const response = await graphAPI.channelsListEP(data);
    if (response.status == 200) {
      const aux = response.data.channels;
      setChannels([{ id: 0, canal: "Todos" }, ...aux]);
    } else {
      console.log(response);
    }
  };

  const GetSalesExcelList = async () => {
    setIsLoadingExcel(true);
    let data = new FormData();

    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));
    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }

    if (channel != "Todos") {
      const channelToSend = channels.find((el) => el.canal == channel);
      data.append("channel", channelToSend.id);
    }
    if (search != "") {
      data.append("search", search);
    }

    const response = await salesB2CAPI.salesExcelListEP(data);
    if (response.status == 200) {
      const aux = response.data.sales.map((el) => ({
        cardCode: el.cardCode,
        almacen_asignado: el.almacen_asignado,
        apellido_cliente: el.apellido_cliente,
        cardCode: el.cardCode,
        codigo_pedido: el.codigo_pedido,
        cp: el.cp,
        direccion: el.direccion,
        eccommerce: el.eccommerce,
        email_cliente: el.email_cliente,
        estatus_envio:
          el.estatus_envio == 1
            ? "Listo entrega"
            : el.estatus_envio == 2
            ? "Asignado"
            : el.estatus_envio == 3
            ? "En camino"
            : el.estatus_envio == 4
            ? "Finalizado"
            : el.estatus_envio == 6
            ? "Cancelado"
            : el.estatus_envio == 7
            ? "Entrega parcial"
            : "Otro",
        fecha_pedido: el.fecha_pedido,
        metodo_pago: el.metodo_pago,
        monto_pedido: el.monto_pedido,
        nombre_cliente: el.nombre_cliente,
        telefono_cliente: el.telefono_cliente,
        u_NumeroGuia: el.u_NumeroGuia,
      }));
      setDataExcel(aux);
      setIsLoadingExcel(false);
    } else {
      console.log(response);
    }
  };

  const GetSalesList = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("page", page + 1);
    /* data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate)); */

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }
    if (channel != "Todos") {
      const channelToSend = channels.find((el) => el.canal == channel);
      data.append("channel", channelToSend.id);
    }
    if (search != "") {
      data.append("search", search);
    } else {
      //add startDate and endDate only if search is emtpy
      data.append("startDate", getDayjsFormatted(startFilterDate));
      data.append("endDate", getDayjsFormatted(endFilterDate));
    } 

    if (status != "Todos"){
      const statusToSend = statusId.find((el) => el.value == status);
      data.append("estatus_envio", statusToSend.id);
    }

    const response = await salesB2CAPI.salesListEP(data);
    if (response.status == 200) {
      setData(response.data.sales);
      setTotal(response.data.total);
      setCount(Math.ceil(response.data.total / 10));
      setIsLoading(false);
    } else {
      console.log(response);
    }
  };

  //------------------------------------------------

  //HANDLERS
  //------------------------------------------------

  // const handleOpenModal = () => setOpenFilter(true);
  // const handleCloseFilterModal = () => setOpenFilter(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleCloseFilterModal = () => setAnchorEl(null);

  const handleCloseModal = () => {
    // setOpenFilter(false);
    setAnchorEl(null);
    settingFilters(
      prevData.channel,
      prevData.client,
      prevData.startFilterDate,
      prevData.endFilterDate
    );
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  const handleChangeClient = (e) => {
    setClient(e.target.value);
  };

  const handleChangeChannel = (e) => {
    setChannel(e.target.value);
  };

  const handleFilter = () => {
    if (endFilterDate < startFilterDate) {
      setErrors({ error: true });
    } else {
      handleCloseFilterModal();
      setErrors({
        error: false,
        errorNullStart: false,
        errorNullEnd: false,
      });
      RequestData();
      setPrevData({
        channel: channel,
        client: client,
        startFilterDate: startFilterDate,
        endFilterDate: endFilterDate,
      });
    }
  };

  const handleResetFilter = async () => {
    settingFilters("Todos", "Todos", firstDay, today);
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  //------------------------------------------------

  //FILTERS SETTING
  //------------------------------------------------

  const settingFilters = (channel, client, firstDay, endDay) => {
    setChannel(channel);
    setClient(client);
    setStartFilterDate(firstDay);
    setEndFilterDate(endDay);
  };

  //------------------------------------------------

  return (
    <>
      <div className="w-100 px-3 mt-2">
        {phone ? (
          <div className="mt-2 w-100 p-1 r-15">
            <div className="mb-2">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                requestData={RequestData}
                setPage={setPage}
              />
            </div>

            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <DownloadButtonExcel
                  text=""
                  disabled={data.length > 0 ? false : true}
                  data={dataExcel}
                  headers={salesHeadersAdmin}
                  isLoading={isLoadingExcel}
                  sheetName={`Ventas`}
                  getData={() => GetSalesExcelList()}
                  client={client}
                />
              </div>

              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        ) : (
          <div className="mt-2 w-100 d-flex justify-content-between p-1 r-15">
            <div className="d-flex align-items-center">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                invisible={invisible}
                requestData={RequestData}
                setPage={setPage}
              />
              <div className="d-flex align-items-center ml-1">
                <DownloadButtonExcel
                  text={"Descargar"}
                  disabled={data.length > 0 ? false : true}
                  data={dataExcel}
                  headers={salesHeadersAdmin}
                  isLoading={isLoadingExcel}
                  sheetName={`Ventas`}
                  getData={() => GetSalesExcelList()}
                  client={client}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <ToggleView setView={setView} view={view} />
            </div>
          </div>
        )}
        {view ? (
          // Vista de tabla
          isLoading ? (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <SkeletonTableSales
                num={3}
                type={roles.ADMIN}
                total={total}
                page={page}
                setPage={setPage}
              />
            </div>
          ) : (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <TableSales
                data={data}
                search={search}
                type={roles.ADMIN}
                setPage={setPage}
                page={page}
                total={total}
                rol={"administrador"}
                requestData={RequestData}
                count={count}
              />
            </div>
          )
        ) : isLoading ? (
          <>
            <div className="mt-3 w-100 r-15">
              <Grid
                container
                alignItems="center"
                direction="row"
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
                columns={{ xs: 4, sm: 6, md: 12, lg: 12, xl: 12 }}
              >
                <SkeletonCardSales
                  num={10}
                  rows={6}
                  icon={true}
                  type={roles.ADMIN}
                  total={total}
                  page={page}
                  setPage={setPage}
                  count={count}
                />
              </Grid>
            </div>
          </>
        ) : (
          <ListViewSales
            data={data}
            search={search}
            type={roles.ADMIN}
            setPage={setPage}
            page={page}
            total={total}
            count={count}
            rol={"administrador"}
            requestData={RequestData}
          />
        )}
      </div>

      <>
        {/* <Modal open={openFilter} onClose={() => console.log("Cerrando")}> */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseFilterModal}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ModalFilters
            errors={errors}
            setErrors={setErrors}
            handleClose={handleCloseModal}
            dates={{
              setEnd: setEndFilterDate,
              end: endFilterDate,
              setStart: setStartFilterDate,
              start: startFilterDate,
            }}
            fields={[
              {
                array: clients,
                prop: "razon_social",
                type: "Cliente",
                value: client,
                handleOnChange: handleChangeClient,
              },
              {
                array: channels,
                prop: "canal",
                type: "Canal",
                value: channel,
                handleOnChange: handleChangeChannel,
              },
              {
                array: statusId,
                prop: "value",
                type: "Estatus",
                value: status,
                handleOnChange: handleChangeStatus,
            },
            ]}
            onClickFilter={handleFilter}
            onClickResetFilter={handleResetFilter}
          />
        </Popover>
        {/* </Modal> */}
      </>
    </>
  );
};
