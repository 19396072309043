import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  useMediaQuery,
  Grid,
  Modal,
  Skeleton,
  Backdrop,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";

import { ModalProductSuccess } from "../Modal/ModalProductSuccess";
import { ModalError } from "../Modal/ModalError";
import LoaderDS from "../../assets/LoaderDS.svg";
import { useMemo } from "react";
import { ClientAPI } from "../../api/Client";

export const ClientProfileForm = () => {
  const navigate = useNavigate();
  const phone = useMediaQuery("(max-width:480px)");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorCP, setErrorCP] = useState(false);
  const [openBD, setOpenBD] = useState(false);

  const [clientInfo, setClientInfo] = useState({
    razon_social: "",
    rfc: "",
    regimen_fiscal: "",
    sitio_web: "",
    nombre: "",
    apellidos: "",
    email: "",
    telefono: "",
    telefono_oficina: "",
    calle: "",
    colonia: "",
    codigo_postal: "",
  });

  useEffect(() => {
    getClientInfo();
  }, []);

  //apis

  const clientAPI = useMemo(() => new ClientAPI(), []);

  const getClientInfo = async () => {
    setIsLoading(true);
    let data = new FormData();

    const response = await clientAPI.getClientInfoEP(data);
    if (response.status == 200) {
      setClientInfo(response.data.client);
      setIsLoading(false);
    } else {
      console.log(response);
    }
  };

  const EditClient = async (obj) => {
    setOpenBD(true);
    let data = new FormData();
    data.append("rfc", obj.rfc);
    data.append("regimen_fiscal", obj.regimen_fiscal);
    data.append("sitio_web", obj.sitio_web);
    data.append("nombre", obj.nombre);
    data.append("apellidos", obj.apellidos);
    data.append("email", obj.email);
    data.append("telefono", obj.telefono);
    data.append("telefono_oficina", obj.telefono_oficina);
    data.append("calle", obj.calle);
    data.append("colonia", obj.colonia);
    data.append("codigo_postal", obj.codigo_postal);

    const response = await clientAPI.editClientEP(data);
    if (response.status == 200) {
      if (response.status == 200) {
        setOpenSuccess(true);
        setOpenBD(false);
      }
    } else {
      console.log(response);
      if (
        response.response.data.non_field_errors ==
        "Código postal no registrado en nuestra base de datos"
      ) {
        setErrorCP(true);
      } else {
        setErrorCP(false);
      }
      setOpenBD(false);
    }
  };

  const fields = {
    0: [
      {
        keyName: "Razón social",
        value: "razon_social",
        type: true,
        ditable: "disabled",
      },
      { keyName: "RFC", value: "rfc", type: true, editable: "disabled" },
      {
        keyName: "Régimen fiscal",
        value: "regimen_fiscal",
        type: true,
        editable: "disabled",
      },
    ],

    1: [
      {
        keyName: "Nombre(s)",
        value: "nombre",
        type: true,
        editable: "enabled",
      },
      {
        keyName: "Apellidos",
        value: "apellidos",
        type: true,
        editable: "enabled",
      },
      {
        keyName: "Calle y número",
        value: "calle",
        type: true,
        editable: "enabled",
      },
      { keyName: "Colonia", value: "colonia", type: true, editable: "enabled" },
      {
        keyName: "C.P.",
        value: "codigo_postal",
        type: true,
        editable: "enabled",
      },
    ],

    2: [
      {
        keyName: "Teléfono celular",
        value: "telefono",
        type: true,
        editable: "enabled",
      },
      {
        keyName: "Teléfono oficina",
        value: "telefono_oficina",
        type: false,
        editable: "enabled",
      },
      {
        keyName: "Sitio web",
        value: "sitio_web",
        type: false,
        editable: "enabled",
      },
      { keyName: "Email", value: "email", type: true, editable: "enabled" },
    ],
  };
  const handleClose = (path) => {
    if (path == "nuevo") {
      window.location.reload();
    } else {
      navigate(`/cliente/cuenta/`);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      razon_social: clientInfo.razon_social,
      rfc: clientInfo.rfc,
      regimen_fiscal: clientInfo.regimen_fiscal,
      sitio_web: clientInfo.sitio_web == null ? "" : clientInfo.sitio_web,
      nombre: clientInfo.nombre == null ? "" : clientInfo.nombre,
      apellidos: clientInfo.apellidos == null ? "" : clientInfo.apellidos,
      email: clientInfo.email == null ? "" : clientInfo.email,
      telefono: clientInfo.telefono == null ? "" : clientInfo.telefono,
      telefono_oficina:
        clientInfo.telefono_oficina == null ? "" : clientInfo.telefono_oficina,
      calle: clientInfo.calle == null ? "" : clientInfo.calle,
      colonia: clientInfo.colonia == null ? "" : clientInfo.colonia,
      codigo_postal:
        clientInfo.codigo_postal == null
          ? ""
          : clientInfo.codigo_postal.length == 4
          ? "0" + clientInfo.codigo_postal
          : clientInfo.codigo_postal,
    },

    validationSchema: Yup.object({
      sitio_web: Yup.string()
        .matches(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
          "Ingresa un sitio web válido"
        )
        .max(100, "Demasiado largo"),
      nombre: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Demasiado largo"),
      apellidos: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(100, "Demasiado largo"),
      email: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Formato incorrecto"
        )
        .max(100, "Demasiado largo"),
      telefono: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\d+$/, "Deben ser números sin espacios")
        .min(10, "Demasiado corto")
        .max(12, "Demasiado largo"),
      telefono_oficina: Yup.string()
        .matches(/^\d+$/, "Deben ser números sin espacios")
        .min(10, "Demasiado corto")
        .max(12, "Demasiado largo"),
      calle: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(50, "Demasiado largo"),
      colonia: Yup.string()
        .required("Campo Obligatorio")
        .matches(
          /^[^\W\s][\w\u00C0-\u024F\u1E00\s]*$/,
          "Sólo alfanuméricos y espacios"
        )
        .max(50, "Demasiado largo"),
      codigo_postal: Yup.string()
        .required("Campo Obligatorio")
        .matches(/^\d+$/, "Deben ser números sin espacios")
        .min(5, "Ingresa un Código Postal válido")
        .max(5, "Demasiado largo"),
    }),

    onSubmit: (values) => {
      const obj = {
        ...values,
      };
      EditClient(obj);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {/* -------------------------DATOS CLIENTE------------------------ */}

        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          rowSpacing={2}
          columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}
        >
          {" "}
          {/*---Contenedor padre---*/}
          <Grid item xs={6}>
            {" "}
            {/*---Ocupara mitad de pantalla---*/}
            <Grid
              item
              xs={12}
              style={{ height: "100%" }}
              className={`border bg-white p-3 r-15 ${!phone && "mr-1"}`}
            >
              <div className="mb-4">
                <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
                  Datos generales
                </p>
              </div>
              {fields[1].map((field, i) => (
                <div
                  key={i}
                  className={` d-flex mb-3 flex-column ${
                    phone ? "px-2 " : "px-4"
                  }`}
                >
                  <div
                    className={`d-flex align-items-center mr-2 ${
                      phone && "mb-2"
                    } `}
                    style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                  >
                    <p className="h6" htmlFor={field.value}>{`${
                      field.keyName
                    }: ${field.type ? "*" : ""}`}</p>
                  </div>
                  <div className="d-flex align-items-center w-100 ">
                    <div className="d-flex flex-column w-100">
                      {isLoading ? (
                        <Skeleton variant="rectangular" height={40} />
                      ) : field.editable == "disabled" ? (
                        <TextField
                          disabled
                          size="small"
                          label={field.keyName}
                          variant="outlined"
                          fullWidth
                          value={formik.values[field.value]}
                          name={field.value}
                          id={field.value}
                        />
                      ) : (
                        <TextField
                          error={
                            formik.touched[field.value] &&
                            formik.errors[field.value]
                          }
                          size="small"
                          label={field.keyName}
                          variant="outlined"
                          fullWidth
                          onChange={formik.handleChange}
                          value={formik.values[field.value]}
                          name={field.value}
                          id={field.value}
                          helperText={
                            formik.touched[field.value] &&
                            formik.errors[field.value] ? (
                              <div>{formik.errors[field.value]}</div>
                            ) : null ||
                              (errorCP && field.value == "codigo_postal") ? (
                              <div class="text-danger">
                                El C.P. no es válido
                              </div>
                            ) : null
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}{" "}
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} className="">
            <Grid
              container
              columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}
              className="border bg-white mb-3 p-3 r-15"
            >
              <div className="mb-3">
                <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
                  Datos fiscales
                </p>
              </div>
              {fields[0].map((field, i) => (
                <Grid key={i} item xs={field.value != "razon_social" ? 6 : 12}>
                  <div
                    className={` d-flex mb-3 flex-column ${
                      phone ? "px-2 " : "px-4"
                    }`}
                  >
                    <div
                      className={`d-flex align-items-center mr-2 ${
                        phone && "mb-2"
                      } `}
                      // style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                    >
                      <p className="h6" htmlFor={field.value}>{`${
                        field.keyName
                      }: ${field.type ? "*" : ""}`}</p>
                    </div>
                    <div className="d-flex align-items-center w-100 ">
                      <div className="d-flex flex-column w-100">
                        {isLoading ? (
                          <Skeleton variant="rectangular" height={40} />
                        ) : field.editable == "disabled" ? (
                          <TextField
                            disabled
                            size="small"
                            label={field.keyName}
                            variant="outlined"
                            fullWidth
                            value={formik.values[field.value]}
                            name={field.value}
                            id={field.value}
                          />
                        ) : (
                          <TextField
                            error={
                              formik.touched[field.value] &&
                              formik.errors[field.value]
                            }
                            size="small"
                            label={field.keyName}
                            variant="outlined"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values[field.value]}
                            name={field.value}
                            id={field.value}
                            helperText={
                              formik.touched[field.value] &&
                              formik.errors[field.value] ? (
                                <div>{formik.errors[field.value]}</div>
                              ) : null ||
                                (errorCP && field.value == "codigo_postal") ? (
                                <div class="text-danger">
                                  El C.P. no es válido
                                </div>
                              ) : null
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12} className="border bg-white p-3 r-15">
              <div className="mb-4">
                <p className={`ds-blue-font ${phone ? "h6" : "h5"}`}>
                  Contacto
                </p>
              </div>

              <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
                {fields[2].map((field, i) => (
                  <Grid key={i} item xs={6}>
                    <div
                      className={` d-flex mb-3 flex-column ${
                        phone ? "px-2 " : "px-4"
                      }`}
                    >
                      <div
                        className={`d-flex align-items-center mr-2 ${
                          phone && "mb-2"
                        } `}
                        style={{ minWidth: `${phone ? "125px" : "160px"}` }}
                      >
                        <p className="h6" htmlFor={field.value}>{`${
                          field.keyName
                        }: ${field.type ? "*" : ""}`}</p>
                      </div>
                      <div className="d-flex align-items-center w-100 ">
                        <div className="d-flex flex-column w-100">
                          {isLoading ? (
                            <Skeleton variant="rectangular" height={40} />
                          ) : field.editable == "disabled" ? (
                            <TextField
                              disabled
                              size="small"
                              label={field.keyName}
                              variant="outlined"
                              fullWidth
                              value={formik.values[field.value]}
                              name={field.value}
                              id={field.value}
                            />
                          ) : (
                            <TextField
                              error={
                                formik.touched[field.value] &&
                                formik.errors[field.value]
                              }
                              size="small"
                              label={field.keyName}
                              variant="outlined"
                              fullWidth
                              onChange={formik.handleChange}
                              value={formik.values[field.value]}
                              name={field.value}
                              id={field.value}
                              helperText={
                                formik.touched[field.value] &&
                                formik.errors[field.value] ? (
                                  <div>{formik.errors[field.value]}</div>
                                ) : null ||
                                  (errorCP &&
                                    field.value == "codigo_postal") ? (
                                  <div class="text-danger">
                                    El C.P. no es válido
                                  </div>
                                ) : null
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div
          className={`${
            !phone && "d-flex justify-content-end margin-right mt-2 mb-2"
          }`}
        >
          <div className={`${phone && "mb-2"}`}>
            <CancelButton
              text={"Cancelar"}
              onClick={() => {
                navigate("/cliente/cuenta/");
              }}
              width={phone ? "100%" : ""}
            />
          </div>
          <AcceptButton
            text={"Actualizar"}
            type={"submit"}
            width={phone ? "100%" : ""}
          />
        </div>
      </form>

      <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalProductSuccess
            handleClose={handleClose}
            text={"Cliente"}
            edit={"editData"}
          />
        </div>
      </Modal>

      <Modal open={openError} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalError
            text={`No se pudo agregar el cliente.`}
            handleClose={() => setOpenError(false)}
          />
        </div>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
