import React, { useState, useEffect, useMemo } from "react";

import { Popover, useMediaQuery } from "@mui/material";

import { ToggleView } from "../../components/ToggleView";
import { Search } from "../../components/Search";
import { RefreshButton } from "../../components/RefreshButton";
import { TableInventoryEntries } from "../../components/Tables/TableInventoryEntries";

import { SkeletonTableInventoryEntries } from "../../components/Skeleton/SkeletonTableInventoryEntries";
import { ListViewInventoryEntries } from "../../components/ListViewInventoryEntries";

import { roles } from "../../helpers/enums";

import { WarehouseAPI } from "../../api/Warehouse";
import { InventoryAPI } from "../../api/Inventory";
import { getDayjsFormatted } from "../../helpers/getDateCleaned";
import { ModalFilters } from "../../components/Modal/ModalFilters";

export const InventoryEntries = () => {
  const [view, setView] = useState(true);
  const [listIndex, setListIndex] = useState(0);
  //------------------------------------------------

  //APIS
  //------------------------------------------------
  const warehouseAPI = useMemo(() => new WarehouseAPI(), []);
  const inventoryAPI = useMemo(() => new InventoryAPI(), []);
  //------------------------------------------------

  //DATA STATES
  //------------------------------------------------

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0); /*Estado p/paginacion*/
  const [total, setTotal] = useState(); /*Total de paginas enviado desde api*/
  const [count, setCount] = useState(); /*Paginacion listas */
  const [search, setSearch] = useState("");
  const [ready, setReady] = useState(false);

  //------------------------------------------------

  //ERRORES
  //------------------------------------------------

  const [errors, setErrors] = useState({
    error: false,
    errorNullStart: false,
    errorNullEnd: false,
  });

  //------------------------------------------------

  //MODAL STATES
  //------------------------------------------------

  const [openFilter, setOpenFilter] = useState(false);
  const [invisible, setInvisible] = useState(false);

  //------------------------------------------------

  //LOADERS STATES
  //------------------------------------------------

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  //------------------------------------------------

  //FILTERS STATES
  //------------------------------------------------
  const [isTable, setIsTable] = useState(null);

  const [status, setStatus] = useState("Todos");

  const [category, setCategory] = useState("Todas");
  const [categories, setCategories] = useState([]);

  const [cedi, setCedi] = useState("Todos");
  const [cedis, setCedis] = useState([]);

  const [branch, setBranch] = useState("Todas");
  const [branches, setBranches] = useState([]);

  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

  const [startFilterDate, setStartFilterDate] = useState(firstDay);
  const [endFilterDate, setEndFilterDate] = useState(today);

  const [prevData, setPrevData] = useState({
    startFilterDate: firstDay,
    endFilterDate: today,
    //  category: "Todas",
    cedi: "Todos",
    branch: "Todas",
    status: "Todos",
  });

  //------------------------------------------------

  //EFFECTS
  //------------------------------------------------

  useEffect(() => {
    setPage(0);
    setSearch("");
    requestFilterData();
    setReady(true);
  }, []);

  useEffect(() => {
    RequestData();
  }, [isTable]);

  useEffect(() => {
    if (ready) {
      RequestData();
    }
  }, [ready]);

  //------------------------------------------------

  //EXCEL STATES AND DATA
  //------------------------------------------------

  const [dataExcel, setDataExcel] = useState([]);

  const statusObj = [
    { id: 0, status: "Todos" },
    { id: 1, status: "Por validar" },
    { id: 2, status: "Validado" },
    { id: 3, status: "Incompleto" },
    { id: 4, status: "Cancelado" },
  ];

  //------------------------------------------------

  //DATA REQUEST
  //------------------------------------------------

  const RequestData = () => {
    /***********temporary disable************
    GetEntriesExcel(); */
    getInventoryEntries();
    setReady(false);
  };

  const requestFilterData = () => {
    //  GetCategories();
    GetCedis();
    getBranchesList();
  };

  const handleRefresh = () => {
    setIsLoading(true);
    getInventoryEntries();
  };

  const GetCedis = async () => {
    let data = new FormData();
    const response = await warehouseAPI.cedisList(data);
    if (response.status == 200) {
      setCedis([{ id: 0, cedi: "Todos" }, ...response.data.cedis]);
    } else {
      console.log(response);
    }
  };

  const getBranchesList = async () => {
    let data = new FormData();

    const response = await warehouseAPI.getBranchesList(data);
    if (response.status == 200) {
      setBranches([{ id: 0, sucursal: "Todas" }, ...response.data.branches]);
    } else {
      console.log(response);
    }
  };

  const phone = useMediaQuery("(max-width:720px)");

  const GetEntriesExcel = async () => {
    let data = new FormData();

    data.append("page", page + 1);

    if (endFilterDate != null) {
      data.append("endDate", getDayjsFormatted(endFilterDate));
    }
    if (startFilterDate != null) {
      data.append("startDate", getDayjsFormatted(startFilterDate));
    }
    if (cedi != "Todos") {
      const cediToSend = cedis.find((el) => el.cedi == cedi);
      data.append("cedi", cediToSend.id);
    }
    if (branch != "Todas") {
      const auxBranch = branches.find((el) => el.sucursal == branch);
      data.append("branch", auxBranch.id);
    }
    if (status != "Todos") {
      const auxStatus = statusObj.find((el) => el.status == status);
      data.append("status", auxStatus.id);
    }
    if (search != "") {
      data.append("search", search);
    }

    const response = await inventoryAPI.entriesExcelEP(data);
    if (response.status == 200) {
      const aux = response.data.entries;
      /*En el excel falta piezas confirmadas*/
      const auxMap = aux.map((el) => ({
        almacen:
          el.branch != null ? el.branch : el.cedi != null ? el.cedi : "N/A",
        creation_date: el.creation_date,
        expected_date: el.expected_date,
        expiration_date:
          el.expiration_date == null ? "N/A" : el.expiration_date,
        folio: el.folio,
        pieces: el.pieces,
        product: el.product,
        sku: el.sku,
        status:
          el.status == 1
            ? "Por validar"
            : el.status == 2
            ? "Incompleto"
            : el.status == 3
            ? "Validado"
            : el.status == 4
            ? "Cancelado"
            : "Otro",
      }));
      setDataExcel(auxMap);
    } else {
      console.log(response);
    }
  };

  const getInventoryEntries = async () => {
    setIsLoading(true);
    let data = new FormData();

    data.append("page", page + 1);

    if (endFilterDate != null) {
      data.append("endDate", getDayjsFormatted(endFilterDate));
    }
    if (startFilterDate != null) {
      data.append("startDate", getDayjsFormatted(startFilterDate));
    } /* Ya no habrá filtrado por categoría
    if (category != "Todas") {
      const categoryToSend = categories.find((el) => el.categoria == category);
      data.append("category", categoryToSend.id);
    } */
    if (cedi != "Todos") {
      const cediToSend = cedis.find((el) => el.cedi == cedi);
      data.append("cedi", cediToSend.id);
    }
    if (branch != "Todas") {
      const auxBranch = branches.find((el) => el.sucursal == branch);
      data.append("branch", auxBranch.id);
    }
    if (status != "Todos") {
      const auxStatus = statusObj.find((el) => el.status == status);
      data.append("status", auxStatus.id);
    }
    if (search != "") {
      data.append("search", search);
    }

    const response = await inventoryAPI.inventoryEntriesEP(data);
    if (response.status == 200) {
      setData(response.data.entries);
      setIsLoading(false);
      setTotal(response.data.total);
      setCount(Math.ceil(response.data.total / 10));
    } else {
      console.log(response);
    }
  };

  //------------------------------------------------

  //HANDLERS
  //------------------------------------------------

  // const handleOpenModal = () => setOpenFilter(true);
  // const handleCloseFilterModal = () => setOpenFilter(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleCloseFilterModal = () => setAnchorEl(null);

  const handleCloseModal = () => {
    // setOpenFilter(false);
    setAnchorEl(null);
    if (!view) {
      settingFilters(
        //  prevData.category,
        prevData.cedi,
        prevData.branch,
        status,
        prevData.startFilterDate,
        prevData.endFilterDate
      );
    } else {
      settingFilters(
        //  prevData.category,
        prevData.cedi,
        prevData.branch,
        prevData.status,
        prevData.startFilterDate,
        prevData.endFilterDate
      );
    }
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  /* const handleChangeCategory = (e) => {
    setCategory(e.target.value);
  }; */

  const handleChangeCedi = (e) => {
    setCedi(e.target.value);
  };

  const handleChangeBranch = (e) => {
    setBranch(e.target.value);
  };

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleFilter = () => {
    if (startFilterDate != null || endFilterDate != null) {
      if (startFilterDate != null && endFilterDate == null) {
        setErrors({ errorNullEnd: true });
      } else if (endFilterDate != null && startFilterDate == null) {
        setErrors({ errorNullStart: true });
      } else if (endFilterDate < startFilterDate) {
        setErrors({ error: true });
      } else {
        handleCloseFilterModal();
        setErrors({
          error: false,
          errorNullStart: false,
          errorNullEnd: false,
        });
        setPage(0);
        setReady(true);
        setPrevData({
          //  category: category,
          cedi: cedi,
          branch: branch,
          status: status,
          startFilterDate: startFilterDate,
          endFilterDate: endFilterDate,
        });
      }
    } else {
      handleCloseFilterModal();
      setPage(0);
      setReady(true);
      setPrevData({
        //  category: category,
        cedi: cedi,
        branch: branch,
        status: status,
        startFilterDate: startFilterDate,
        endFilterDate: endFilterDate,
      });
    }
  };

  const handleResetFilter = async () => {
    if (!view) {
      settingFilters("Todas", "Todos", "Todas", status, firstDay, today);
    } else {
      settingFilters("Todas", "Todos", "Todas", "Todos", firstDay, today);
    }
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  //------------------------------------------------

  //FILTERS SETTING
  //------------------------------------------------

  const settingFilters = (category, cedi, branch, status, firstDay, endDay) => {
    //  setCategory(category);
    setCedi(cedi);
    setBranch(branch);
    setStatus(status);
    setStartFilterDate(firstDay);
    setEndFilterDate(endDay);
  };

  //------------------------------------------------
  return (
    <>
      <div className="w-100 mt-2 px-3">
        {phone ? (
          <div className="mt-2 w-100 p-1 r-15">
            <div className="mb-2">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                requestData={RequestData}
                setPage={setPage}
              />
            </div>

            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <RefreshButton text={""} onClick={handleRefresh} />
                {/* <DownloadButtonExcel
                  text={""}
                  disabled={true}
                  data={dataExcel}
                  isLoading={isLoadingExcel}
                  headers={entriesHeaders}
                  sheetName={"Entradas"}
                /> */}
              </div>

              <ToggleView
                setView={setView}
                view={view}
                setStatus={setStatus}
                entries={true}
                setIsTable={setIsTable}
              />
            </div>
          </div>
        ) : (
          <div className="mt-2 w-100 d-flex justify-content-between p-1 r-15">
            <div className="d-flex align-items-center">
              <Search
                label={"Buscar"}
                setSearch={setSearch}
                onClick={handleClick}
                requestData={RequestData}
                setPage={setPage}
              />
              <div className="d-flex align-items-center ml-1">
                <RefreshButton text={"Actualizar"} onClick={handleRefresh} />
                {/* <DownloadButtonExcel
                  text={"Descargar"}
                  disabled={true}
                  data={dataExcel}
                  isLoading={isLoadingExcel}
                  headers={entriesHeaders}
                  sheetName={"Entradas"}
                /> */}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <ToggleView
                setView={setView}
                view={view}
                setStatus={setStatus}
                entries={true}
                setIsTable={setIsTable}
              />
            </div>
          </div>
        )}

        {view ? (
          isLoading ? (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <SkeletonTableInventoryEntries
                type={roles.CLIENT}
                num={3}
                total={total}
                page={page}
                setPage={setPage}
              />
            </div>
          ) : (
            <div className="mt-3 bg-white w-100 d-flex justify-content-between p-2 r-15">
              <TableInventoryEntries
                RequestData={RequestData}
                search={search}
                data={data}
                setPage={setPage}
                type={roles.CLIENT}
                page={page}
                total={total}
                count={count}
              />
            </div>
          )
        ) : (
          <ListViewInventoryEntries
            icon={true}
            entries={true}
            setStatus={setStatus}
            status={status}
            getInventoryEntries={getInventoryEntries}
            startFilterDate={startFilterDate}
            endFilterDate={endFilterDate}
            page={page}
            search={search}
            RequestData={RequestData}
            data={data}
            listIndex={listIndex}
            setListIndex={setListIndex}
            type={roles.CLIENT}
            isLoading={isLoading}
            setPage={setPage}
            count={count}
            total={total}
          />
        )}
      </div>

      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseFilterModal}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ModalFilters
            view={view}
            errors={errors}
            setErrors={setErrors}
            handleClose={handleCloseModal}
            dates={{
              setEnd: setEndFilterDate,
              end: endFilterDate,
              setStart: setStartFilterDate,
              start: startFilterDate,
            }}
            fields={[
              {
                array: statusObj,
                prop: "status",
                type: "Estatus",
                value: status,
                handleOnChange: handleChangeStatus,
              },
              {
                array: cedis,
                prop: "cedi",
                type: "Cedi",
                value: cedi,
                handleOnChange: handleChangeCedi,
              },
              {
                array: branches,
                prop: "sucursal",
                type: "Sucursal",
                value: branch,
                handleOnChange: handleChangeBranch,
              },
            ]}
            onClickFilter={handleFilter}
            onClickResetFilter={handleResetFilter}
          />
        </Popover>

        {/* </Modal> */}
      </>
    </>
  );
};
