import React from "react";
import { Grid } from "@mui/material";
import { CardGraph } from "../Card/CardGraph";

const options = {
  legend: { position: "left", alignment: "center" },
  pieSliceText: "percent",
  // title: `Total: ${total}`,
  tooltip: { trigger: "selection" },
  pieStartAngle: 0,
  pieHole: 0.3,
  chartArea: { width: "80%", height: "80%" },
};

const modalOption = {
  titleTextStyle: {
    fontSize: 20,
  },
};

export const OrdersByWarehouseGraph = ({
  data,
  isLoading,
  total,
  client,
  filters,
}) => {
  return (
    <>
      <Grid item xs={4}>
        <CardGraph
          title={"Ventas Por Sucursal"}
          type={"PieChart"}
          data={data}
          options={{ ...options, title: `Total: ${total}` }}
          modalOption={modalOption}
          isLoading={isLoading}
          client={client}
          filters={filters}
        />
      </Grid>
    </>
  );
};
