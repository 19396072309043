import React, { useState, useEffect } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
  Modal,
  Backdrop,
  tableCellClasses,
  styled,
  useMediaQuery,
  Popover,
} from "@mui/material";

import { useViewPortDimensions } from "../../hooks/useViewPortDimensions";

import { ListPagination } from "../Pagination/ListPagination";

import { roles } from "../../helpers/enums";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DeleteForever } from "@mui/icons-material";
import { ModalEntryProducts } from "../Modal/ModalEntryProducts";
import { ModalEditEntrySuccess } from "../Modal/ModalEditEntrySuccess";
import { ModalEditEntry } from "../Modal/ModalEditEntry";
import { ModalError } from "../Modal/ModalError";
import LoaderDS from "../../assets/LoaderDS.svg";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const TableInventoryEntries = ({
  search,
  data,
  type,
  setPage,
  page,
  total,
  RequestData,
  count,
  // setEntriesCardsView,
  // view,
  // status,
  // getInventoryEntries,
  // isTable,
}) => {
  const { height, width } = useViewPortDimensions();
  // setEntriesCardsView(false) /*Habilitar field estatus filtro */
  const phone = useMediaQuery("(max-width:480px)");

  //const [open, setOpen] = useState(false);
  const [info, setInfo] = useState();
  const [openBD, setOpenBD] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openProducts, setOpenProducts] = useState(false);
  const [errorField, setErrorField] = useState("");
  const [action, setAction] = useState("");
  const [openExcelViewer, setOpenExcelViewer] = useState(false);
  const [excelFile, setExcelFile] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event, action, row) => {
    setAnchorEl(event.currentTarget);
    setInfo(row);
    setAction(action);
  };

  const handleOpenProducts = (row) => {
    setOpenProducts(true);
    setInfo(row);
  };

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const tRow = (row, index) => {
    // const handleClick = (edit, cancel) => {
    //   // setOpen(true);
    //   setInfo(row);
    // };
    const labelId = `enhanced-table-checkbox-${index}`;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={row.folio}>
        {type == 2 && (
          <TableCell padding="checkbox">
            <div className="d-flex justify-content-center">
              <Tooltip title={"Cancelar"} arrow placement="top">
                <div
                  className={`${row.status == "Por validar" && "hover-icon"}`}
                >
                  <IconButton
                    sx={{ "&:hover": { color: "red" } }}
                    onClick={(e) => handleClick(e, "cancel", row)}
                    disabled={row.status != "Por validar" ? true : false}
                  >
                    <DeleteForever />
                  </IconButton>
                </div>
              </Tooltip>

              <Tooltip title={"Ver productos"} arrow placement="top">
                <div className={`${row.products.length > 0 && "hover-icon"}`}>
                  <IconButton
                    sx={{ "&:hover": { color: "blue" } }}
                    onClick={() => handleOpenProducts(row)}
                    disabled={row.products.length == 0 ? true : false}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </div>
          </TableCell>
        )}

        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          align="center"
        >
          {row.folio}
        </TableCell>
        {type == roles.ADMIN && (
          <TableCell align="center">{row.client}</TableCell>
        )}
        <TableCell align="center">{row.creation_date}</TableCell>
        <TableCell align="center">{row.status}</TableCell>
        <TableCell align="center">
          {row.cedi ? row.cedi : row.branch ? row.branch : "Sin datos"}
        </TableCell>
        <TableCell align="center">{row.products.length}</TableCell>
        <TableCell align="center">{row.expectedDate}</TableCell>
        <TableCell align="center">
          {row.fileUrl ? (
            <a href={row.fileUrl}>Descargar archivo</a>
          ) : (
            "Sin archivo"
          )}
        </TableCell>
        {/* <TableCell align="center">{row.expected_date.split("-").reverse("").join("-")}</TableCell>
          <TableCell align="center">
            {row.expiration_date ? row.expiration_date.split("-").reverse("").join("-") : "N/A"}
          </TableCell> */}
      </TableRow>
    );
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: height - (74 + 90 + 60) }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-label="sticky table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {type == 2 && (
                    <StyledTableCell align="center">Acciones</StyledTableCell>
                  )}
                  <StyledTableCell align="center">Folio</StyledTableCell>
                  {type == roles.ADMIN && (
                    <StyledTableCell align="center">Cliente</StyledTableCell>
                  )}
                  <StyledTableCell align="center">Fecha</StyledTableCell>
                  <StyledTableCell align="center">Estatus</StyledTableCell>
                  <StyledTableCell align="center">Almacén</StyledTableCell>
                  <StyledTableCell align="center">Productos</StyledTableCell>
                  <StyledTableCell align="center">
                    Fecha llegada
                  </StyledTableCell>
                  <StyledTableCell align="center">Archivo</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
    rows.slice().sort(getComparator(order, orderBy)) */}
                {data.map((row, index) => tRow(row, index))}
              </TableBody>
            </Table>
            {data.length == 0 && search != "" ? (
              <div className="d-flex justify-content-center mt-2">
                No se encontraron resultados para "{search}"
              </div>
            ) : (
              data.length == 0 &&
              search == "" && (
                <div className="d-flex justify-content-center mt-2">
                  No tienes entradas aún.
                </div>
              )
            )}
          </TableContainer>
          <ListPagination
            setPage={setPage}
            page={page}
            total={total}
            requestData={RequestData}
            count={count}
          />
        </Paper>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: !phone && 300 },
        }}
      >
        <ModalEditEntry
          info={info}
          RequestData={RequestData}
          action={action}
          handleClose={handleClose}
          setOpenBD={setOpenBD}
          setOpenSuccess={setOpenSuccess}
          setOpenError={setOpenError}
          setErrorField={setErrorField}
        />
      </Popover>

      <Modal open={openProducts} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalEntryProducts
            info={info}
            handleClose={() => setOpenProducts(false)}
            type={type}
            RequestData={RequestData}
          />
        </div>
      </Modal>

      <Modal open={openError} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI*/}
          <ModalError
            text={`La solicitud no pudo ser completada.`}
            handleClose={() => {
              setOpenError(false);
            }}
            error={errorField}
          />
        </div>
      </Modal>
      <Modal open={openSuccess} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalEditEntrySuccess text={"El folio se canceló correctamente"} />
        </div>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
