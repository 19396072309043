import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Skeleton,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";

import { roles } from "../../helpers/enums";
import { Pagination } from "../Pagination/Pagination";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const SkeletonTableInventory = ({num, type, total, page, setPage}) => {

    const a = Array(num).fill(true);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 400}}>
          <Table sx={{ minWidth: 750 }} aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow>
                {type == roles.ADMIN && <StyledTableCell align="center">Cliente</StyledTableCell>}
                <StyledTableCell align="center">Descripción</StyledTableCell>
                <StyledTableCell align="center">SKU</StyledTableCell>
                <StyledTableCell align="center">Código de barras</StyledTableCell>
                <StyledTableCell align="center">Stock</StyledTableCell>
                <StyledTableCell align="center">Comprometido</StyledTableCell>
                <StyledTableCell align="center">Solicitado</StyledTableCell>
                <StyledTableCell align="center">Disponible</StyledTableCell>
                <StyledTableCell align="center">Merma</StyledTableCell>

                <StyledTableCell align="center">Categoría</StyledTableCell>
                <StyledTableCell align="center">Sucursal</StyledTableCell>
                <StyledTableCell align="center">Fecha de actualización</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {
                    a.map((row, index) => {

                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                      >
                        {type == roles.ADMIN && <TableCell align="center"><Skeleton variant="text" /></TableCell>}
                        <TableCell
                          align="center"
                        >
                          <Skeleton variant="text" />
                        </TableCell>
                        <TableCell align="center"><Skeleton variant="text" /></TableCell>
                        <TableCell align="center"><Skeleton variant="text" /></TableCell>
                        <TableCell align="center"><Skeleton variant="text" /></TableCell>
                        <TableCell align="center"><Skeleton variant="text" /></TableCell>
                        <TableCell align="center"><Skeleton variant="text" /></TableCell>
                        <TableCell align="center"><Skeleton variant="text" /></TableCell>
                        <TableCell align="center"><Skeleton variant="text" /></TableCell>
                        <TableCell align="center"><Skeleton variant="text" /></TableCell>
                        <TableCell align="center"><Skeleton variant="text" /></TableCell>
                        <TableCell align="center"><Skeleton variant="text" /></TableCell>

                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* {total == undefined ? 
          ( */}
          <div className="d-flex justify-content-start align-items-center p-3">
            <Typography variant="caption" className="ml-2"><Skeleton width={50} /></Typography> 
            <Skeleton variant="circular" className="ml-4" width={20} />
           <Skeleton variant="circular" className="ml-2" width={20} />
            </div> 
            {/* : 
          (<Pagination setPage={setPage} page={page} total={total} />
          )} */}
      </Paper>
    </Box>
  );
};
