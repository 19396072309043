import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Dashboard } from "../pages/Dashboard/Dashboard";
import { Inventory } from "../pages/Inventory/Inventory";
import { Products } from "../pages/Products/Products";
import { Reports } from "../pages/Reports/Reports";
import { InventoryEntries } from "../pages/Inventory/InventoryEntries";
import { InventoryTransfers } from "../pages/Inventory/InventoryTransfers";
import { InventoryExistance } from "../pages/Inventory/InventoryExistance";
import { AddProduct } from "../pages/Add/AddProduct";
import { EditProduct } from "../pages/Edit/EditProduct";
import { ReportsSales } from "../pages/Reports/ReportsSales";
import { ReportsInventory } from "../pages/Reports/ReportsInventory";
import { EditClient } from "../pages/Edit/EditClient";
import { AccountCards } from "../pages/Edit/AccountCards";
import { EditPassword } from "../pages/Edit/EditPassword";
import { BreadcrumbsMenu } from "../components/Breadcrumb/BreadcrumbsMenu";
import { InventoryTransfersList } from "../pages/Inventory/InventoryTransfersList";
import { SalesB2C } from "../pages/Sales/SalesB2C";
import { SalesB2B } from "../pages/Sales/SalesB2B";
import { DetailSaleB2B } from "../pages/Sales/DetailSaleB2B";
import { Business } from "../pages/Sales/Business";
import { AddBusiness } from "../pages/Sales/AddBusiness";
import { AddLocation } from "../pages/Sales/AddLocation";
import { RegisterSale } from "../pages/Sales/RegisterSale";

import { Sales } from "../pages/Sales/Sales";
import { DetailSales } from "../pages/Sales/DetailSales";
import { SearchAddInventory } from "../pages/Inventory/SearchAddInventory";
import { AddInventory } from "../pages/Add/AddInventory";
import { AddMassiveInventory } from "../pages/Add/AddMassiveInventory";

import { Users } from "../pages/Users/Users";
import { AddUser } from "../pages/Add/AddUser";
import MenuIcon from "@mui/icons-material/Menu";
import { SnackbarProvider, useSnackbar } from "notistack";

import { Button, Grid, Popover, useMediaQuery } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import { theme } from "../utils/theme";
import {
  Close,
  KeyboardArrowDownRounded,
  Notifications,
} from "@mui/icons-material";

import ProfileIcon from "../assets/SVGIcons/Profile.svg";
import { Toolbar, Typography } from "@mui/material";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { EditUser } from "../pages/Edit/EditUser";
import { ModalNotification } from "./Modal/ModalNotification";
import { ModalMenuAccount } from "./Modal/ModalMenuAccount";
import { Sidebar } from "./Sidebar/Sidebar";
import { Historical } from "../pages/Notifications/Historical";
import SnackbarAudio from "../sounds/SnackbarAudioClient.mp3";
import ReceiptAudio from "../sounds/InventoryReceiptSound.mp3";
import BellAudio from "../sounds/BellAudioClient.mp3";
import { getSocketHost } from "../helpers/getHost";
import {
  AppBar,
  DrawerHeader,
  DrawerStyled,
  openedMixin,
} from "../utils/menuStyles";
import { sectionsClient } from "../utils/menuSections";
import { BusinessDetails } from "../pages/Sales/BusinessDetails";
import { useMemo } from "react";
import { NotificationsAPI } from "../api/Notifications";
import { SalesOdoo } from "../pages/Sales/SalesOdoo";
import { DetailSalesOdoo } from "../pages/Sales/DetailSalesOdoo";

const Layout = ({ variant, setVariant }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const rol = localStorage.getItem("rol");
  const breakpointBC = useMediaQuery("(max-width:630px)");
  const tablet = useMediaQuery("(max-width:600px)");
  const phone = useMediaQuery("(max-width:600px)");

  const razonSocial = localStorage.getItem("razon_social");
  const [notification, setNotification] = useState(false);
  const [isUpdate, setIsUpdate] = useState(0);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openPO = Boolean(anchorEl);
  const id = openPO ? "simple-popover" : undefined;

  const [total, setTotal] = useState(0);
  const [notifications, setNotifications] = useState({
    e_sales: [],
    inventory_receipt: [],
    inventory_status: [],
    inventory_transfer: [],
  });
  const [loaderNotifications, setLoaderNotifications] = useState(false);

  const handleClose = () => setAnchorEl(null);

  const handleDrawerToggle = () => {
    tablet && setMobileOpen(!mobileOpen);
    setOpen(true);
  };

  const handleClick = (event, notification) => {
    setAnchorEl(event.currentTarget);
    setNotification(notification);
  };

  const handleDrawer = () => {
    setOpen(!open);
  };

  const [socketMsg, setSocketMsg] = useState({
    msg: "",
    orderId: 0,
    statusMov: false,
  });

  const [socketReload, setSocketReload] = useState({
    /* From here the reloads that are made when the socket is updated are handled, they are passed to the child components in each useEffect */
    sale: 0,
    receipt: 0,
    status: 0,
    transfer: 0,
    bellAudio: false,
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar(); /* Snackbar */

  const auxType3 = [
    { id: 1, variant: "success", text: "Se generó una venta en " },
    { id: 2, variant: "warning", text: "Orden no generada en " },
    { id: 3, variant: "error", text: "Orden cancelada en " },
  ];
  const [audio, setAudio] = useState();

  const socketUrl = getSocketHost();

  useEffect(() => {
    const token = `${localStorage.getItem("token")}`;
    const socket = new WebSocket(`${socketUrl}/wss/user/${token}`);

    socket.onopen = () => {
      console.log("connection open");
    };

    socket.onclose = () => {
      console.log("connection closed");
    };

    socket.onmessage = ({ data }) => {
      // console.log("websocket data:" + data);
      const response = JSON.parse(data);
      switch (response.type /* Parent switch receipt number type */) {
        case 0 /*RECEIPT INVENTORY TYPE */:
          setSocketReload({ receipt: new Date(response.timestamp) });
          switch (response.event) {
            case 1:
              /*Pendiente */
              break;
            case 2:
              setAudio(ReceiptAudio);
              setVariant("success");
              setSocketMsg({
                msg: `Se recibieron ${response.details.quantity} pzs. en sucursal ${response.details.sucursal} folio: ${response.details.folio}`,
                orderId: 0,
                statusMov: false,
              });
              break;

            case 3:
              setAudio(ReceiptAudio);
              setVariant("warning");
              setSocketMsg({
                msg: `Folio ${response.folio} incompleto, se recibieron ${response.details.quantity} en sucursal ${response.details.sucursal}`,
                orderId: 0,
                statusMov: false,
              });
              break;
            case 4:
              /*Pendiente */
              break;
          }

          break;

        case 1 /*TRANSFER INVENTORY TYPE */:
          setSocketReload({
            transfer: new Date(response.timestamp),
            bellAudio: true,
          });
          break;

        case 2 /*CHANGE STATUS INVENTORY TYPE */:
          setSocketReload({
            status: new Date(response.timestamp),
            bellAudio: false,
          });
          break;

        case 3 /*SALES TYPE */:
          setAudio(SnackbarAudio);
          setSocketReload({ sale: new Date(response.timestamp) });

          switch (response.event) {
            case 1:
              setVariant(
                auxType3.find((el) => el.id == response.status).variant
              );
              setSocketMsg({
                msg: `${auxType3.find((el) => el.id == response.status).text} ${
                  response.origin
                }. Código de pedido: ${response.code}`,
                orderId: response.details.orderId,
                statusMov: true,
              });
              break;

            case 2:
              setVariant("success");
              setSocketMsg({
                msg: `El pedido ${response.code} fue asignado al servicio de entrega de ${response.lastMile}.`,
                orderId: response.details.orderId,
                statusMov: true,
              });

              break;

            case 3:
              setVariant("success");
              setSocketMsg({
                msg: `El pedido ${response.code} esta en camino a entrega.`,
                orderId: response.details.orderId,
                statusMov: true,
              });

              break;

            case 4:
              setVariant("success");
              setSocketMsg({
                msg: `La orden ${response.code} finalizó.`,
                orderId: response.details.orderId,
                statusMov: true,
              });

              break;

            case 5:
              if (response.details.statusDs == 200) {
                setVariant("success");
                setSocketMsg({
                  msg: `${response.lastMile} en camino a recolección orden ${response.code}.`,
                  orderId: response.details.orderId,
                  statusMov: true,
                });
              } else {
                setVariant("success");
                setSocketMsg({
                  msg: `${response.lastMile} notificó un movimiento para el pedido ${response.code}.`,
                  orderId: response.details.orderId,
                  statusMov: true,
                });
              }
              break;
          }
          break;
      }
    };

    return () => socket.close();
  }, []);

  const snackbarAudio = new Audio(audio);

  const action = (id) => (
    <div>
      <IconButton
        onClick={() => {
          closeSnackbar(id);
          snackbarAudio.pause();
        }}
      >
        <Close sx={{ color: "#FFF", fontSize: 15 }} />
      </IconButton>
    </div>
  );
  //setSocketMsg({msg: "test", orderId: "123546", statusMov: true})
  useEffect(() => {
    if (socketMsg.msg != "") {
      snackbarAudio.play();
      enqueueSnackbar(
        <div className="d-flex w-100 justify-content-between align-items-center">
          <Typography>{socketMsg.msg}</Typography>
          {socketMsg.statusMov && (
            <Button
              sx={{ color: "#FFF", fontWeight: "bold" }}
              variant="text"
              onClick={() => {
                navigate("/cliente/ventas/B2C/detalle/", {
                  state: {
                    id: socketMsg.orderId,
                  },
                });
                snackbarAudio.pause();
              }}
            >
              Detalle
            </Button>
          )}
        </div>,
        { autoHideDuration: 6000, variant: `${variant}`, action }
      );
    }
  }, [socketMsg]);

  const bellAudio = new Audio(BellAudio);

  useEffect(() => {
    GetNotifications();
    if (socketReload.bellAudio == true) {
      bellAudio.play();
    }
  }, [
    socketReload.sale,
    socketReload.receipt,
    socketReload.status,
    socketReload.transfer,
  ]);

  //apis
  const notificationsAPI = useMemo(() => new NotificationsAPI(), []);

  const GetNotifications = async () => {
    setLoaderNotifications(true);
    let data = new FormData();

    const response = await notificationsAPI.getNotificationsEP(data);
    if (response.status == 200) {
      setNotifications({
        e_sales: response.data.notifications.e_sales,
        inventory_receipt: response.data.notifications.inventory_receipt,
        inventory_status: response.data.notifications.inventory_status,
        inventory_transfer: response.data.notifications.inventory_transfer,
      });
      setTotal(response.data.total);
      setLoaderNotifications(false);
    } else {
      console.log(response);
    }
  };

  const nameSection = sectionsClient.find(
    (el) => `/${el.link}/` == pathname
  ); /* Names of sections */

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        {/* -------------------------------------------HEADER------------------------------------------ */}
        <AppBar
          position="fixed"
          open={tablet ? false : open}
          style={{ width: !open && !tablet && "calc(100% - 65px)" }}
          elevation={0}
        >
          <Toolbar
            sx={{
              bgcolor: "white",
              color: theme.palette.primary.main,
              height: phone ? "65px" : "68px",
            }}
            className={`w-100 d-flex align-items-start align-items-center`}
          >
            {/* -------------------------------------MOBILE MENU------------------------------------ */}
            {phone && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            )}

            <div
              className={`w-100 ${phone && "d-flex justify-content-center"}`}
            >
              <div
                className={`w-100 d-flex mt-1 ${
                  phone
                    ? "flex-column align-items-center"
                    : "align-items-center justify-content-between"
                }`}
              >
                <div className="d-flex flex-column">
                  <Typography
                    variant="h5"
                    noWrap
                    component="div"
                    className="ds-blue-font font-weight-bold capitalize"
                  >
                    {nameSection != undefined && nameSection.section}
                  </Typography>
                  {breakpointBC ? null : (
                    <BreadcrumbsMenu
                      sections={sectionsClient}
                      principal={"dashboard"}
                      rol={"cliente"}
                    />
                  )}
                </div>

                {/*----------------------- NOTIFICATIONS NAME & PROFILEICON -----------------------*/}
                <div
                  className={`d-flex  ${
                    phone
                      ? "w-100 justify-content-center"
                      : "align-items-center justify-content-between"
                  }`}
                >
                  <div className="d-flex mr-2">
                    <IconButton onClick={(e) => handleClick(e, true)}>
                      <Notifications
                        sx={{
                          fontSize: phone ? 25 : 30,
                          color: theme.palette.primary.main,
                        }}
                      />
                    </IconButton>
                    <Typography sx={{ color: "red", marginLeft: -1.5 }}>
                      {loaderNotifications ? "..." : total}
                    </Typography>
                  </div>

                  {phone ? (
                    <div>
                      <Typography
                        sx={{ fontSize: 20 }}
                        className="ds-blue-font capitalize"
                      >
                        {razonSocial != null
                          ? razonSocial.length >= 13
                            ? `${razonSocial.substring(0, 13)}...`
                            : razonSocial
                          : "Sin usuario"}
                      </Typography>
                    </div>
                  ) : (
                    <Typography
                      sx={{ fontSize: 20, fontWeight: 600 }}
                      className="ds-blue-font capitalize"
                    >
                      {razonSocial != null ? razonSocial : "Sin usuario"}
                    </Typography>
                  )}

                  <IconButton onClick={(e) => handleClick(e, false)}>
                    <KeyboardArrowDownRounded color={"primary"} />
                  </IconButton>
                  {/* <div
                    className={`${phone ? "d-none" : "ml-1"} cursor-pointer `}
                    onClick={(e) => handleClick(e, false)}
                  >
                    <img
                      className={`${phone && "d-none"}`}
                      src={ProfileIcon}
                      height={"40px"}
                    />
                  </div> */}
                </div>
              </div>
            </div>
            {/* )} */}
          </Toolbar>
        </AppBar>

        {/* -------------------------------------------SIDEBAR------------------------------------------ */}

        <Sidebar
          DrawerStyled={DrawerStyled}
          open={open}
          handleDrawer={handleDrawer}
          isLoading={isLoading}
          DrawerHeader={DrawerHeader}
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
          rol={rol ? rol : 2}
        />

        <div className="d-flex justify-content-center w-100">
          {/* These style properties are needed so that when you minimize the menu, the graphs, tables, etc. render correctly. */}
          <div
            style={{
              width: phone
                ? "100%"
                : open && !phone
                ? "calc(100vw - 235px)"
                : "calc(100vw - 80px)",
            }}
          >
            <Grid container sx={{ marginTop: !tablet ? 8.3 : 7.6 }}>
              <DrawerHeader />

              <Routes>
                {/* All non-existent routes redirect to dashboard */}
                <Route
                  path="*"
                  element={<Navigate to="dashboard/" replace />}
                />
                <Route
                  path="dashboard/"
                  element={<Dashboard socketReload={socketReload} />}
                />
                <Route
                  path="notificaciones/"
                  element={
                    <Historical
                      socketReload={socketReload}
                      isUpdate={isUpdate}
                      NotificationsFn={GetNotifications}
                    />
                  }
                />

                <Route path="productos/" element={<Products />} />
                <Route path="productos/nuevo/" element={<AddProduct />} />
                <Route path="productos/editar/" element={<EditProduct />} />

                <Route path="inventario/" element={<Inventory />} />
                <Route
                  path="inventario/existencias/"
                  element={<InventoryExistance />}
                />
                <Route
                  path="inventario/envios/"
                  element={<InventoryEntries />}
                />
                <Route
                  path="inventario/busqueda/"
                  element={<SearchAddInventory />}
                />
                <Route
                  path="inventario/busqueda/agregar/"
                  element={<AddInventory />}
                />
                <Route
                  path="inventario/busqueda/envio_masivo/"
                  element={<AddMassiveInventory />}
                />
                <Route
                  path="inventario/transferir/"
                  element={<InventoryTransfers />}
                />
                <Route
                  path="inventario/transferencias/"
                  element={<InventoryTransfersList />}
                />

                <Route path="ventas/" element={<Sales />} />
                <Route
                  path="ventas/B2C"
                  element={<SalesB2C socketReload={socketReload} />}
                />
                <Route
                  path="ventas/Odoo"
                  element={<SalesOdoo socketReload={socketReload} />}
                />
                <Route path="ventas/B2C/detalle/" element={<DetailSales />} />
                <Route path="ventas/Odoo/detalle/" element={<DetailSalesOdoo />} />
                <Route path="ventas/B2B/" element={<SalesB2B />} />
                <Route path="ventas/B2B/detalle" element={<DetailSaleB2B />} />
                <Route path="ventas/B2B/negocios/" element={<Business />} />
                <Route
                  path="ventas/B2B/negocios/detalle/"
                  element={<BusinessDetails />}
                />
                <Route
                  path="ventas/B2B/negocios/nuevo/"
                  element={<AddBusiness />}
                />
                <Route
                  path="ventas/B2B/nueva_venta/"
                  element={<RegisterSale />}
                />
                <Route
                  path="ventas/B2B/negocios/agregar_ubicacion/"
                  element={<AddLocation />}
                />

                <Route path="reportes/" element={<Reports />} />
                <Route path="reportes/ventas/" element={<ReportsSales />} />
                <Route
                  path="reportes/inventario/"
                  element={<ReportsInventory />}
                />

                <Route path="usuarios/" element={<Users />} />
                <Route path="usuarios/nuevo/" element={<AddUser />} />
                <Route path="usuarios/editar" element={<EditUser />} />

                <Route path="cuenta/" element={<AccountCards />} />
                <Route path="cuenta/perfil/" element={<EditClient />} />
                <Route path="cuenta/password/" element={<EditPassword />} />
              </Routes>
            </Grid>
          </div>
        </div>
      </Box>

      <Popover
        id={id}
        open={openPO}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            minWidth: notification && 300,
            maxWidth: notification && 350,
          },
        }}
      >
        {notification ? (
          <ModalNotification
            setIsUpdate={setIsUpdate}
            handleClose={handleClose}
            notifications={notifications}
            total={total}
            GetNotifications={GetNotifications}
            loaderNotifications={loaderNotifications}
          />
        ) : (
          <ModalMenuAccount user={"cliente"} handleClose={handleClose} />
        )}
      </Popover>
    </>
  );
};

export default function IntegrationNotistackClient({ variant, setVariant }) {
  return (
    <SnackbarProvider maxSnack={5} variant={`${variant}`}>
      <Layout setVariant={setVariant} variant={variant} />
    </SnackbarProvider>
  );
}
