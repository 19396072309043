import React, { useState } from "react";

import { Box, useMediaQuery, CircularProgress } from "@mui/material";

import { AcceptButton } from "../Buttons/AcceptButton";
import { useMemo } from "react";
import { NotificationsAPI } from "../../api/Notifications";

export const ModalDetailsNotifications = ({
  setOpen,
  notification,
  GetNotifications,
  handleCloseModal,
  setIsUpdate,
}) => {
  const phone = useMediaQuery("(max-width:480px)");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "95%" : "45%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    if (!notification.readed) {
      UpdateNotification();
      handleCloseModal();
    } else {
      handleCloseModal();
      setOpen(false);
    }
  };

  //api
  const notificationsAPI = useMemo(() => new NotificationsAPI(), []);

  const UpdateNotification = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append(
      "idNotification",
      notification.id
    ); /*Donde se va a posicionar la página, campo obligatorio*/

    const response = await notificationsAPI.updateNotificationEP(data);
    if (response.status == 200) {
      setIsUpdate(Math.floor(Date.now() / 10000));
      GetNotifications();
      setOpen(false);
      setIsLoading(false);
    } else {
      console.log(response);
    }
  };

  const types = [
    { id: 0, value: "Entrada de Inventario" },
    { id: 1, value: "Transferencia de Inventario" },
    { id: 2, value: "Estatus de Inventario" },
    { id: 3, value: "Ventas" },
  ];

  return (
    <Box sx={style}>
      <div className="d-flex justify-content-center mb-3 d-flex">
        <p className="ds-blue-font h5 m-0">
          Detalle de Notificación en{" "}
          {types.find((el) => el.id == notification.typeNotification).value}
        </p>
      </div>

      <div className="w-100 ml-3">
        {notification.typeNotification == 3 && (
          <>
            <div>
              <strong>SKU: </strong>
              {notification.sku}
            </div>
            <div>
              <strong>Producto: </strong>
              {notification.article}
            </div>
            <div>
              <strong>Cantidad: </strong>
              {notification.qty}
            </div>
          </>
        )}
        <div>
          <strong>Descripción: </strong>
          {notification.message}
        </div>
        <div>
          <strong>Origen de notificación: </strong>
          {notification.originNotification}
        </div>
        {notification.typeNotification == 3 && (
          <div>
            <strong>Número de guía: </strong>
            {notification.details.trackingNumber == null
              ? "No asignado"
              : notification.details.trackingNumber}
          </div>
        )}
      </div>

      <div className="d-flex justify-content-center mt-3">
        <AcceptButton
          text={
            isLoading ? (
              <CircularProgress sx={{ color: "#ffffff" }} size={20} />
            ) : (
              "Aceptar"
            )
          }
          width={"50%"}
          onClick={handleClose}
        />
      </div>
    </Box>
  );
};
