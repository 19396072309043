import * as React from 'react';
import Box from '@mui/material/Box';

import { IconButton, useMediaQuery } from '@mui/material';
import { Close, VisibilityOutlined } from '@mui/icons-material';

export default function ModalImgClientForm({ image, handleClose }) {
    const phone = useMediaQuery("(max-width:600px)");
    const tablet = useMediaQuery("(max-width:900px)");

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: phone ? "95%" : "50%",
        maxHeight: 700,
        height: "80vh",
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: 1,
    };

    return (
        <Box sx={style}>

            <div className='w-100 d-flex justify-content-end'>
                <IconButton onClick={handleClose}>
                    <Close sx={{ fontSize: 35 }} />
                </IconButton>
            </div>
            <div className='w-100 d-flex justify-content-center'>
                <img src={image} alt="imagen evidencia" style={{ maxHeight: "60vh", maxWidth: "100%", pointerEvents: "none" }} />
            </div>

            <div className='d-flex flex-column align-items-center justify-content-center w-100'>

            </div>
        </Box>

    );
}
