import Box from "@mui/material/Box";
import { IconButton, useMediaQuery } from "@mui/material";
import { Close } from "@mui/icons-material";

export function ModalMap({ handleClose, info, location }) {
  const phone = useMediaQuery("(max-width:600px)");
  const tablet = useMediaQuery("(max-width:900px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "95%" : "50%",
    maxHeight: 700,
    height: "80vh",
    paddingBottom: 1,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Box sx={style}>
      <div className="d-flex justify-content-center align-items-center m-3">
        <p className={`font-weight-bold gray-font m-0 ${phone ? "h5" : "h4"}`}>
          {info}
        </p>
        <IconButton
          onClick={handleClose}
          style={{ position: "absolute", right: 3 }}
        >
          <Close sx={{ fontSize: 35 }} />
        </IconButton>
      </div>
      <iframe
        src={`https://maps.google.com/?q=${location.lat}, ${location.lng}&z=19&t=m&output=embed`}
        title={`${info}location`}
        height="100%"
        width="100%"
        frameborder={0}
      />
    </Box>
  );
}
