import Box from "@mui/material/Box";
import {
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function ModalProducts({ order, handleClose }) {
  const phone = useMediaQuery("(max-width:600px)");
  const tablet = useMediaQuery("(max-width:900px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "95%" : "75%",
    // maxHeight: 700,
    maxHeight: "80vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Box sx={style}>
      <Paper className="px-3 pb-4" sx={{ width: "100%" }}>
        <div className="w-100 d-flex justify-content-center align-items-center m-3">
          <p
            className={`font-weight-bold gray-font ml-2 ${phone ? "h5" : "h4"}`}
          >
            Artículos
          </p>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", right: 3 }}
          >
            <Close sx={{ fontSize: 35 }} />
          </IconButton>
        </div>

        <TableContainer sx={{ maxHeight: "62vh" }}>
          <Table sx={{ minWidth: 750 }} aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Imagen</StyledTableCell>
                <StyledTableCell align="center">SKU</StyledTableCell>
                <StyledTableCell align="center">Producto</StyledTableCell>
                <StyledTableCell align="center" style={{ minWidth: 150 }}>
                  Código de barras
                </StyledTableCell>
                <StyledTableCell align="center">Cantidad</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.map((item, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                    <TableCell align="center" className="p-0">
                      <img
                        src={item.imagen}
                        className={item.imagen && "img-thumbnail"}
                        alt=""
                        style={{ height: 60 }}
                      />
                    </TableCell>
                    <TableCell align="center">{item.sku}</TableCell>
                    <TableCell align="center">
                      {item.articulo || item.descripcion}
                    </TableCell>
                    <TableCell align="center">{item.codigo_barras}</TableCell>
                    <TableCell align="center">{item.quantity}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
