import React, { useState } from "react";

import {
  Grid,
  Paper,
  IconButton,
  Tooltip,
  Modal,
  useMediaQuery,
  Popover,
  Backdrop,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { DeleteForever, EditTwoTone, AccountBox } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import Circle from "@mui/icons-material/Circle";

import { useNavigate } from "react-router-dom";

import ModalContactInfo from "../Modal/ModalContactInfo";
import { ModalDelete } from "../Modal/ModalDelete";
import { ModalSuccess } from "../Modal/ModalSuccess";
import { ModalError } from "../Modal/ModalError";
import { ModalMap } from "../Modal/ModalMap";

import LoaderDS from "../../assets/LoaderDS.svg";
import { useMemo } from "react";
import { HeadFieldManagerAPI } from "../../api/HeadFieldManager";
import { theme } from "../../utils/theme";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: 10,
  minHeight: 220,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  // alignItems: "center"
}));

export const CardPickers = ({ info, type, requestData }) => {
  const phone = useMediaQuery("(max-width:480px)");

  const navigate = useNavigate();

  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMap, setOpenMap] = useState(false);

  const [picker, setPicker] = useState();
  const [openContact, setOpenContact] = useState(false);

  const [openBD, setOpenBD] = useState(false);

  const handleClick = (event, action, info) => {
    setPicker(info);
    switch (action) {
      case "edit":
        navigate("/headFieldManager/pickers/nuevo", {
          state: {
            id: info.id == null ? "" : info.id,
            name: info.nombre == null ? "" : info.nombre,
            lastName: info.apellidos == null ? "" : info.apellidos,
            email: info.email == null ? "" : info.email,
            personalNumber: info.telefono == null ? "" : info.telefono,
            branchId: info.warehouse_id == null ? "" : info.warehouse_id,
            photoFile: info.photo == null ? "" : info.photo,
            photoLoaded: info.photo == null ? false : true,
          },
        });
        break;
      case "cancel":
        setAnchorEl(event.currentTarget);
        setOpenDelete(true);
        break;
      case "verDatos":
        setOpenContact(true);
        break;
    }
  };

  const idPopover = openDelete ? "simple-popover" : undefined;

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    requestData();
  };
  // API
  const hfmAPI = useMemo(() => new HeadFieldManagerAPI(), []);

  const deletePicker = async () => {
    setOpenBD(true);
    let data = new FormData();

    data.append("picker", picker.id);
    data.append("status", false);

    const response = await hfmAPI.desactivatePickerEP(data);
    if (response.status == 200) {
      setOpenBD(false);
      setOpenSuccess(true);
    } else {
      // console.log(response);
      setOpenBD(false);
      setError(error.response.data.msg || "");
      setOpenError(true);
    }
  };

  return (
    <>
      <Grid item xs={6} sm={3} md={3} lg={3} xl={2.4}>
        <Item className="mb-3 hover-card">
          <div>
            <div className="d-flex justify-content-end">
              <Tooltip title={"Dar de baja"} arrow placement="top">
                <div className={"hover-icon"}>
                  <IconButton
                    sx={{ padding: "2px", "&:hover": { color: "red" } }}
                    onClick={(e) => handleClick(e, "cancel", info)}
                    disabled={false}
                  >
                    <DeleteForever />
                  </IconButton>
                </div>
              </Tooltip>
              <Tooltip title={"Editar"} arrow placement="top">
                <div className={"hover-icon"}>
                  <IconButton
                    sx={{ padding: "2px", "&:hover": { color: "green" } }}
                    onClick={(e) => handleClick(e, "edit", info)}
                    disabled={false}
                  >
                    <EditTwoTone />
                  </IconButton>
                </div>
              </Tooltip>

              {/* <Tooltip title={"Ver Contacto"} arrow placement="top">
                <div className={"hover-icon"}>
                  <IconButton
                    sx={{ "&:hover": { color: "blue" } }}
                    onClick={(e) => handleClick(e, "verDatos", info)}
                    disabled={false}
                  >
                    <AccountBox />
                  </IconButton>
                </div>
              </Tooltip> */}
            </div>
            <div className="d-flex flex-column align-items-center">
              {info.photo ? (
                <div
                  className="rounded-circle m-auto cursor-pointer hover-photo"
                  onClick={(e) => handleClick(e, "verDatos", info)}
                  style={{
                    background: `url("${info.photo}") center / cover no-repeat`,
                    height: 100,
                    width: 100,
                  }}
                ></div>
              ) : (
                <AccountCircleIcon
                  className="rounded-circle"
                  sx={{
                    color: theme.palette.neutrals.inactive,
                    fontSize: 100,
                    margin: 0,
                  }}
                />
              )}
              <div>
                <Tooltip
                  title={info.available == true ? "Conectado" : "Desconectado"}
                  placement="top"
                  arrow
                >
                  <Circle
                    className={`m-1 ${
                      info.available == true ? "ds-green-font" : "inactive-font"
                    }`}
                  />
                </Tooltip>
                <Tooltip
                  title={info.is_active == true ? "Picking" : "No picking"}
                  placement="top"
                  arrow
                >
                  <ShoppingBasketIcon
                    className={`m-1 ${
                      info.is_active == true
                        ? " ds-yellow-font"
                        : "inactive-font"
                    }`}
                  />
                </Tooltip>
              </div>
            </div>
            <Tooltip
              title={
                info.apellidos.length > 10
                  ? `${info.nombre} ${info.apellidos}`
                  : ""
              }
              placement="top"
              arrow
            >
              <div>
                <strong>{`${info.nombre} ${
                  info.apellidos.length > 10
                    ? `${info.apellidos.substring(0, 10)} ...`
                    : info.apellidos
                }`}</strong>
              </div>
            </Tooltip>
            <Tooltip
              title={info.warehouse.length > 15 ? info.warehouse : ""}
              placement="top"
              arrow
            >
              <div>
                {`Sucursal: ${info.warehouse.substring(0, 12)} ${
                  info.warehouse.length > 15 ? "..." : ""
                }`}
              </div>
            </Tooltip>
            <Tooltip
              title={
                info.log != null
                  ? info.log.login != null
                    ? info.log.login.substring(0, 19)
                    : ""
                  : ""
              }
              placement="top"
              arrow
            >
              <div>
                {`Inicio turno: ${
                  info.log != null
                    ? info.log.login != null
                      ? `${info.log.login.substring(0, 10)} ...`
                      : ""
                    : ""
                }`}
              </div>
            </Tooltip>
            <Tooltip
              title={
                info.log != null
                  ? info.log.logout
                    ? info.log.logout.substring(0, 19)
                    : ""
                  : ""
              }
              placement="top"
              arrow
            >
              <div>
                {`Fin turno: ${
                  info.log != null
                    ? info.log.logout != null
                      ? `${info.log.logout.substring(0, 10)} ...`
                      : ""
                    : ""
                }`}
              </div>
            </Tooltip>
            <div>
              Ubicación:{" "}
              {info.location ? (
                <span
                  className="cursor-pointer ds-blue-font"
                  onClick={(e) => {
                    setOpenMap(true);
                  }}
                >
                  Ver
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </Item>
      </Grid>
      <Modal open={openContact} onClose={() => console.log("Cerrando")}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalContactInfo
            info={picker}
            handleClose={() => setOpenContact(false)}
            infoType={"picker"}
          />
        </div>
      </Modal>
      <Popover
        id={idPopover}
        open={openDelete}
        anchorEl={anchorEl}
        onClose={() => setOpenDelete(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: !phone && 300 },
        }}
      >
        <ModalDelete
          type={"picker"}
          info={picker}
          auxText={"a"}
          handleClose={() => setOpenDelete(false)}
          deleteObject={deletePicker}
        />
      </Popover>
      <Modal open={openSuccess}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalSuccess
            text={"El picker se dió de baja correctamente"}
            handleClose={handleCloseSuccess}
          />
        </div>
      </Modal>
      <Modal open={openError}>
        <div>
          {" "}
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalError
            text={`No se pudo dar de baja el picker.`}
            handleClose={() => setOpenError(false)}
            error={error}
          />
        </div>
      </Modal>
      <Modal open={openMap}>
        <div>
          {/* Wrapping  modal to another div to solve issue "ref" error from MUI */}
          <ModalMap
            info={`${info.nombre} ${info.apellidos}`}
            handleClose={() => setOpenMap(false)}
            location={info.location}
          />
        </div>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <img src={LoaderDS} alt="" width={200} />
      </Backdrop>
    </>
  );
};
