import { HttpRequest } from "./HttpRequest";
import { getHost } from "../helpers/getHost";

export class TransferAPI {
  constructor() {
    this.http = new HttpRequest();
    this.host = getHost();
    this.token = localStorage.getItem("token");
    this.config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${this.token}`,
      },
    };
  }

  inventoryTransferEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/inventoryTransfer/`,
      data,
      this.config
    );
  }

  transferListEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/transferList/`,
      data,
      this.config
    );
  }

  transfersExcelEP(data) {
    return this.http.post(
      `${this.host}/inventory/ws/transfersExcel/`,
      data,
      this.config
    );
  }
}
