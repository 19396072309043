import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { LoginAPI } from "../api/Login";

export const useAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [openBD, setOpenBD] = useState(false);
  const persistence =
    localStorage.getItem("token") != "null"
      ? localStorage.getItem("token")
      : null;
  const [token, setToken] = useState(persistence);
  const [errors, setErrors] = useState({
    wrongEmail: false,
    wrongPassword: false,
    nonAllowedUser: false,
  });

  const loginAPI = new LoginAPI();

  const canAccess = async (values) => {
    setIsLoading(true);
    let data = new FormData();
    setErrors({
      wrongEmail: false,
      wrongPassword: false,
      nonAllowedUser: false,
    });

    data.append("email", values.email);
    data.append("password", values.password);

    const response = await loginAPI.LoginEP(data);
    if (response.status == 200) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("nombre", response.data.nombre);
      localStorage.setItem("razon_social", response.data.razon_social);
      localStorage.setItem("rol", response.data.rol);
      localStorage.setItem("codigo_cliente", response.data.codigo_cliente);
      localStorage.setItem("id", response.data.id);

      document.cookie = `Authorization=Token ${response.data.token}; path=/`;

      switch (response.data.rol) {
        case 2:
          navigate("/cliente/dashboard/");
          break;
        case 3:
          navigate("/administrador/dashboard/");
          break;
        case 4 /*INVENTARIO*/:
          navigate("/cliente/dashboard/");
          break;
        case 5 /*VENTAS*/:
          navigate("/cliente/dashboard/");
          break;
        case 6 /*SUCURSAL*/:
          navigate("/warehouse/activas/");
          break;
        case 8 /*HEAD FIELD MANAGER*/:
          navigate("/headFieldManager/activas/");
          break;
      }
      setIsLoading(false);
    } else {
      if (response.response.status == 400) {
        setIsLoading(false);
        if (response.response.data.hasOwnProperty("result")) {
          switch (response.response.data.result) {
            //Usuario no tiene privilegios
            case 2:
              //   setNonAllowedUser(true);
              setErrors({ nonAllowedUser: true });
              break;
            //Inicio de sesión declinada
            case 0:
              // setWrongPassword(true);
              setErrors({ wrongPassword: true });
              break;
          }
        } else {
          //Usuario no existe
          // setWrongEmail(true);
          console.log(response.response);
          setErrors({ wrongEmail: true });
          // setCounter(counter + 1);/*Máximo 5 errores al iniciar sesión */
        }
      }
      // if (counter == 4) {
      //   setTimeout(() => {
      //     window.location.reload();
      //   }, 5000);
      // }
    }
  };

  const logoutFunction = async () => {
    // localStorage.removeItem("token");
    // window.location.reload();
    setOpenBD(true);
    let data = new FormData();

    const response = await loginAPI.LogoutEP(data);
    if (response.status == 200) {
      localStorage.removeItem("token");
      setOpenBD(false);
      window.location.reload();
    } else {
      console.log(response);
      setOpenBD(false);
    }
  };

  return {
    token,
    setToken,
    canAccess,
    logoutFunction,
    errors,
    isLoading,
    openBD,
  };
};
