import React, { useEffect, useState, useContext } from "react";
import { FilterContext } from "../../context/FilterContext";

import {
  Typography,
  Box,
  TextField,
  MenuItem,
  IconButton,
  Divider,
  useMediaQuery,
  Popover,
  Autocomplete,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { Close, ErrorSharp } from "@mui/icons-material";

import { AcceptButton } from "../Buttons/AcceptButton";
import { CancelButton } from "../Buttons/CancelButton";
import { useFormik } from "formik";
import { theme } from "../../utils/theme";
import dayjs from "dayjs";

export const ModalFilter = ({
  handleCloseModal,
  dates,
  fields = [],
  view,
  requestData,
  nullable,
  setPage,
}) => {
  const phone = useMediaQuery("(max-width:480px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: phone ? "85%" : "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: 5,
    // maxHeight: phone ? "95%" : "",
    // overflowY: phone ? "auto" : "",
  };

  const today = new Date();
  const [filter, setFilter] = useState(false);
  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const {
    startDate,
    endDate,
    nullableStartDate,
    nullableEndDate,
    fieldList,
    resetFilter,
    handleChangeStart,
    handleChangeEnd,
    handleChangeNullEnd,
    handleChangeNullStart,
    errors,
    canFilter,
  } = useContext(FilterContext);

  const { error, errorNullStart, errorNullEnd } = errors;

  const handleClose = () => {
    handleCloseModal();
  };

  const handleFilter = async () => {
    setPage(0);
    setFilter(() => canFilter());
  };

  useEffect(() => {
    if (filter) {
      requestData();
      handleCloseModal();
      setFilter(false);
    }
  }, [filter]);

  return (
    <div className="p-2">
      {/* Mensaje y botón de cerrar */}

      <div className="d-flex justify-content-between align-items-center">
        <p id="modal-modal-description" className="ds-blue-font h4 m-0">
          Filtrar:
        </p>
        <IconButton onClick={handleClose}>
          <Close fontSize="large" />
        </IconButton>
      </div>

      {/* Inputs de filtros */}

      <div className={`mt-1 ${phone ? "px-1" : "px-4"}}`}>
        {dates ? (
          <div className={`d-flex flex-column mb-2 `}>
            <div
              className={`d-flex align-items-center ds-blue-font`}
              style={{ minWidth: "100px" }}
            >
              <label>Fechas: </label>
            </div>

            <div className={`d-flex flex-column mb-3 `}>
              <DesktopDatePicker
                allowSameDateSelection
                maxDate={dayjs(today)}
                label={"Fecha inicio"}
                format="DD/MM/YYYY"
                value={nullable ? nullableStartDate : dayjs(startDate)}
                onChange={nullable ? handleChangeNullStart : handleChangeStart}
                slotProps={{
                  textField: {
                    //onKeyDown: onKeyDown,
                  },
                }}
              />
              <div className="text-danger xs-font">
                {/* {errorInvalidStart ? "Ingresa un formato válido" : null} */}
                {errorNullStart ? "Elige una fecha inicio" : null}
              </div>
            </div>

            {/* <Divider orientation="vertical" flexItem className="ml-3 mr-3" /> */}

            <div className="d-flex flex-column">
              <DesktopDatePicker
                allowSameDateSelection
                maxDate={dayjs(today)}
                label={"Fecha fin"}
                format="DD/MM/YYYY"
                value={nullable ? nullableEndDate : dayjs(endDate)}
                onChange={nullable ? handleChangeNullEnd : handleChangeEnd}
                slotProps={{
                  textField: {
                    //onKeyDown: onKeyDown,
                  },
                }}
              />

              <div className="text-danger xs-font">
                {error ? "No puede ser menor a la fecha inicio" : null}
                {errorNullEnd ? "Elige una fecha fin" : null}
                {/* {errorInvalidEnd ? "Ingresa un formato válido" : null} */}
              </div>
            </div>
          </div>
        ) : null}

        {fieldList.map((el, i) =>
          fields.includes(el.prop) ? (
            el.type.includes("Estatus") && !view ? null : (
              <div key={i} className={` d-flex mb-2 flex-column`}>
                <div
                  className={`d-flex align-items-center mb-1 ds-blue-font`}
                  style={{ minWidth: "100px" }}
                >
                  <p
                    className="no-wrap h6"
                    htmlFor={el.type}
                  >{`${el.type}:`}</p>
                </div>
                <div className="d-flex align-items-center w-100">
                  <div className="d-flex flex-column w-100">
                    {/* {el.autocomplete == false ? ( */}
                    <TextField
                      select
                      size="small"
                      label={el.type}
                      value={el.value}
                      name={el.type}
                      id={el.type}
                      onChange={(e) => el.handleOnChange(e)}
                      fullWidth
                    >
                      {el.array.map((option, i) => (
                        <MenuItem key={i} value={option[el.prop]}>
                          {option[el.prop]}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/*  ) : (
                      <Autocomplete
                        noOptionsText={"Sin resultados"}
                        disablePortal
                        options={el.array}
                        getOptionLabel={(option) => option[el.prop]}
                        onChange={(e) => {
                          console.log(e.target.value);
                          el.handleOnChange(e);
                        }}
                        value={el.value}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={el.type}
                            size="small"
                            name={el.type}
                            id={el.type}
                          />
                        )}
                      />
                    )} */}
                  </div>
                </div>
              </div>
            )
          ) : null
        )}
      </div>

      <div
        className={`d-flex mt-1 ${
          phone ? "flex-column " : "justify-content-center"
        }`}
      >
        {/* <div className={`${phone && "mb-2"}`}> */}
        <CancelButton
          text={"Limpiar"}
          onClick={resetFilter}
          width={phone ? "100%" : "100%"}
        />
        {/* </div> */}
        <AcceptButton
          text={"Filtrar"}
          type={"text"}
          onClick={handleFilter}
          width={phone ? "100%" : "100%"}
          phone={phone}
        />
      </div>
    </div>
  );
};
