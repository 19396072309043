import React, { createContext, useContext, useMemo, useState } from "react";
import { FilterContext } from "./FilterContext";
import { getDayjsFormatted } from "../helpers/getDateCleaned";
import { AdminAPI } from "../api/Admin";

export const ClientsContext = createContext();

export function ClientsContextProvider(props) {
  //DATA STATES
  //------------------------------------------------

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0); /*Estado p/paginacion*/
  const [total, setTotal] = useState(); /*Total de paginas enviado desde api*/
  const [count, setCount] = useState(); /*Paginacion listas */
  const [excelData, setExcelData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [invisible, setInvisible] = useState(true);

  const { nullableStartDate, nullableEndDate, search } =
    useContext(FilterContext);

  //apis
  const admindAPI = useMemo(() => new AdminAPI(), []);

  const getExcelClientsList = async () => {
    setIsLoadingExcel(true);
    let data = new FormData();
    data.append(
      "page",
      page + 1
    ); /*Donde se va a posicionar la página, campo obligatorio*/
    data.append("excel", true);

    if (nullableStartDate != null) {
      data.append("startDate", getDayjsFormatted(nullableStartDate));
    }
    if (nullableEndDate != null) {
      data.append("endDate", getDayjsFormatted(nullableEndDate));
    }

    if (search != "") {
      data.append("search", search);
    }

    const response = await admindAPI.clientsListEP(data);
    if (response.status == 200) {
      setExcelData(response.data.clients);
      setIsLoadingExcel(false);
    } else {
      console.log(response);
    }
  };

  const getClientsList = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("page", page + 1);

    if (nullableStartDate != null) {
      data.append("startDate", getDayjsFormatted(nullableStartDate));
    }
    if (nullableEndDate != null) {
      data.append("endDate", getDayjsFormatted(nullableEndDate));
    }
    if (search != "") {
      data.append("search", search);
    }

    const response = await admindAPI.clientsListEP(data);
    if (response.status == 200) {
      setData(response.data.clients);
      setTotal(response.data.total);
      setCount(Math.ceil(response.data.total / 10));
      setIsLoading(false);
      if (nullableStartDate != null && nullableEndDate != null) {
        setInvisible(false);
      } else {
        setInvisible(true);
      }
    } else {
      console.log(response);
    }
  };

  return (
    <ClientsContext.Provider
      value={{
        getExcelClientsList,
        getClientsList,
        data,
        total,
        count,
        isLoading,
        isLoadingExcel,
        page,
        setPage,
        setTotal,
        excelData,
      }}
    >
      {props.children}
    </ClientsContext.Provider>
  );
}
